import { roleActionType } from '../reducers/roles.reducer';
import http from '../../services/http';

// const fail = payload => ({
//   type: roleActionType.TEAM_FAILURE,
//   payload,
// });

export const destroyRole = () => {
  return dispatch => {
    dispatch({ type: roleActionType.ROLE_DESTROY });
  };
};

export const fetchRoleList = () => {
  const success = payload => ({
    type: roleActionType.FETCH_ROLE,
    payload,
  });
  return dispatch => {
    dispatch({ type: roleActionType.ROLE_LOADING, payload: true });
    http
      .get('/api/roles', null)
      .then(tokenRes => {
        dispatch(success(tokenRes.data));
      })
      .catch(error => {
        console.error({ error });
        dispatch({ type: roleActionType.ROLE_LOADING, payload: false });
      });
  };
};
