const plagiarismTypes = {
  DELETE_PLAGIARISM: 'DELETE_PLAGIARISM',
  ADD_PLAGIARISM: 'ADD_PLAGIARISM',
  UPDATE_PLAGIARISM: 'UPDATE_PLAGIARISM',
  GET_PLAGIARISM: 'GET_PLAGIARISM',
  PLAGIARISM_DESTROY: 'PLAGIARISM_DESTROY',
};

const initialState = {
  plagiarisms: {},
  plagiarism_history: [],
};

const plagiarismReducer = (state = initialState, action) => {
  switch (action.type) {
    case plagiarismTypes.PLAGIARISM_DESTROY:
      return { ...initialState };

    case plagiarismTypes.GET_PLAGIARISM:
      if (action.payload.post_id.toString() in state.plagiarisms) {
        return {
          ...state,
          plagiarism_history: [
            ...state.plagiarisms[action.payload.post_id.toString()].reverse(),
          ],
        };
      }
      return {
        ...state,
        plagiarism_history: [],
      };
    case plagiarismTypes.ADD_PLAGIARISM:
      const post_id = action.payload.post_id.toString();
      const plagiarism = action.payload.plagiarism;
      if (post_id in state.plagiarisms) {
        return {
          ...state,
          plagiarisms: {
            ...state.plagiarisms,
            [post_id]: [...state.plagiarisms[post_id], ...plagiarism],
          },
        };
      }

      return {
        ...state,
        plagiarisms: {
          ...state.plagiarisms,
          [post_id]: [...plagiarism],
        },
      };
    default:
      return state;
  }
};

export default plagiarismReducer;
export { plagiarismTypes };
