import React, { useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Moment from 'react-moment';
import DeleteMemberModal from './DeleteMemberModal';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import defaultAvatar from '../../styles/images/default-avatar.svg';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import UsageLimitModal from './UsageLimitModal';
import { LinearProgress } from '@material-ui/core';
import { numberToThousands } from '../../utils/common';
import { PencilIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

function TeamMemberList({
  team,
  roles,
  canDelete,
  deleteTeamMember,
  user,
  fetchRoleList,
}) {
  const [openDeleteMemberModal, setDeleteMemberModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [openUsageLimitModal, setOpenUsageLimitModal] = useState(false);
  const [teamUsageLimit, setTeamUsageLimit] = useState({});
  const [teamMemberUsageLimit, setTeamMemberUsageLimit] = useState([]);
  const alert = useAlert();
  const request = useSentData();

  const is_owner = role => {
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].title === 'Owner') {
        return roles[i].id === role;
      }
    }
    return false;
  };

  useEffect(() => {
    getTeamUsageLimit();
    getTeamMemberUsageLimit();
  }, []);

  const changeRole = (team_member_id, e) => {
    request
      .send(
        `/api/team/${team_member_id}/change-role`,
        { role: parseInt(e.target.value) },
        'post'
      )
      .then(response => {
        if (!response.isAxiosError) {
          fetchRoleList();
        } else {
          alert.error(isolateError(response));
        }
      });
  };

  const getTeamUsageLimit = () => {
    request.send(`/api/usage/${team[0].team}`, {}, 'get').then(response => {
      if (!response?.isAxiosError) {
        setTeamUsageLimit(response);
      }
    });
  };

  const getTeamMemberUsageLimit = () => {
    request.send(`/api/team/team-usage-limit`, {}, 'get').then(response => {
      if (!response?.isAxiosError) {
        setTeamMemberUsageLimit(response);
      }
    });
  };

  const displayTeamUsageLimit = (id, member) => {
    if (!teamMemberUsageLimit || member.is_owner || member.is_viewer) {
      return '';
    }
    const memberUsageLimit = teamMemberUsageLimit?.find(
      item => item.team_member.id === id
    );
    let limit = memberUsageLimit?.words_usage_limit;
    if (teamUsageLimit.team_usage_limit) {
      limit = teamUsageLimit?.words_usage_limit;
    }
    const percentage = (100 * memberUsageLimit?.words_usage_per_month) / limit;

    return limit ? (
      <>
        <div className="flex-1">
          <LinearProgress
            variant="determinate"
            value={percentage > 100 ? 100 : percentage}
            style={{ height: '10px', borderRadius: '10px' }}
          />
        </div>
        <div className="ml-3 flex-1">{`${numberToThousands(
          memberUsageLimit?.words_usage_per_month
        )} / ${numberToThousands(limit)}`}</div>
      </>
    ) : (
      'None'
    );
  };

  const onOpenModal = () => setOpenUsageLimitModal(true);
  const onCloseModal = () => setOpenUsageLimitModal(false);

  function renderTableData() {
    return team.map((team_member, index) => {
      const { id, role, member } = team_member;

      return (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full"
                  src={member.preview ? member.preview : defaultAvatar}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {member.full_name}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {member.email}
          </td>
          {user.is_owner || user.is_manager ? (
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <select
                id="role"
                name="role"
                value={role}
                disabled={is_owner(role)}
                onChange={e => changeRole(id, e)}
                className={classNames(
                  !is_owner(role) && 'cursor-pointer',
                  'focus:ring-purple-500 focus:border-purple-500 h-full py-0 pl-0 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md'
                )}
              >
                {member.is_owner
                  ? roles
                      .filter(role => role.title === 'Owner')
                      .map(r => <option value={r.id}>{r.title}</option>)
                  : roles
                      .filter(role => role.title !== 'Owner')
                      .map(r => <option value={r.id}>{r.title}</option>)}

                {/* {roles.filter(role => !role.title !== 'Owner').map(role => <option>{role.title}</option>)} */}
              </select>
            </td>
          ) : (
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {roles
                .filter(r => r.id === role)
                .map(filteredRole => filteredRole.title)}
            </td>
          )}
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Moment fromNow>{member.date_joined}</Moment>
          </td>
          {user.usage_limit_feature ? (
            <td className="px-6 py-4  whitespace-nowrap text-sm font-medium text-gray-900 ">
              <div className="flex items-center">
                {displayTeamUsageLimit(id, member)}
              </div>
            </td>
          ) : null}
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            {canDelete && !is_owner(role) && (
              <Menu as="div" className="static inline-block text-left">
                <div>
                  <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-16 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setSelectedMember(team_member);
                              setDeleteMemberModalOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Remove
                          </button>
                        )}
                      </Menu.Item>
                      {user.usage_limit_feature && !member.is_viewer ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setSelectedMember(team_member);
                                setOpenUsageLimitModal(true);
                              }}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              Set Usage Limit
                            </button>
                          )}
                        </Menu.Item>
                      ) : (
                        ''
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <DeleteMemberModal
        open={openDeleteMemberModal}
        memberId={selectedMember?.id}
        setOpen={() => {
          setDeleteMemberModalOpen(false);
        }}
      />
      {user.is_owner || (user.is_manager && user.usage_limit_feature) ? (
        <div className="mt-3 flex">
          Team usage limit :{' '}
          {user.usage_limit_feature ? (
            <>
              {teamUsageLimit.team_usage_limit ? (
                <>
                  <b className="mx-2">
                    {numberToThousands(teamUsageLimit.words_usage_limit)}
                  </b>{' '}
                  words
                </>
              ) : (
                'No limit'
              )}
              <button
                onClick={() => {
                  setSelectedMember(null);
                  onOpenModal();
                }}
                className="block text-center ml-3 border-none border-transparent text-sm text-black  font-medium rounded-md shadow-sm  bg-transparent focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <PencilIcon className="w-5" />
              </button>
            </>
          ) : user.is_owner ? (
            <span>
              <Link
                className="ml-2 text-sm font-bold text-purple-600 hover:text-purple-500"
                to={
                  user.app_sumo_subscription
                    ? [
                        'bramework_tier1',
                        'bramework_tier2',
                        'bramework_tier3',
                        'bramework_tier4',
                      ].includes(user.app_sumo_subscription.plan.app_sumo_plan)
                      ? '/appsumo-plan'
                      : user.app_sumo_subscription.plan.app_sumo_plan ===
                        'bramework_ltd1'
                      ? '/bramework-ltd-plan'
                      : user.app_sumo_subscription.plan.app_sumo_plan ===
                        'free_user'
                      ? '/account'
                      : '/plans'
                    : '/plans'
                }
              >
                Upgrade to set team usage limit
              </Link>
            </span>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Joined
                    </th>
                    {user.usage_limit_feature ? (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Usage Limit
                      </th>
                    ) : null}
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderTableData()}
                </tbody>
              </table>
              <UsageLimitModal
                openModal={openUsageLimitModal}
                teamId={user.team}
                teamUsageLimit={teamUsageLimit}
                teamMemberUsageLimit={teamMemberUsageLimit}
                memberId={selectedMember?.id}
                member={selectedMember?.member}
                closeModal={onCloseModal}
                getTeamUsageLimit={getTeamUsageLimit}
                getTeamMemberUsageLimit={getTeamMemberUsageLimit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMemberList;
