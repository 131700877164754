const googleAccountActionType = {
  FETCH_GOOGLE_ACCOUNT: 'FETCH_GOOGLE_ACCOUNT',
  SELECTED_GOOGLE_ACCOUNT: 'SELECTED_GOOGLE_ACCOUNT',
  REMOVE_GOOGLE_ACCOUNT: 'REMOVE_GOOGLE_ACCOUNT',
  GOOGLE_ACCOUNT_LOADING: 'GOOGLE_ACCOUNT_LOADING',
  GOOGLE_ACCOUNT_FAILURE: 'GOOGLE_ACCOUNT_FAILURE',
  GOOGLE_ACCOUNT_DESTROY: 'GOOGLE_ACCOUNT_DESTROY',
};
const initialState = {
  googleAccounts: [],
  selectedGoogleAccount: null,
  loading: false,
  error: false,
  errorMsg: null,
};
const GoogleAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case googleAccountActionType.GOOGLE_ACCOUNT_DESTROY:
      return { ...initialState };
    case googleAccountActionType.GOOGLE_ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case googleAccountActionType.GOOGLE_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case googleAccountActionType.FETCH_GOOGLE_ACCOUNT:
      let selectedGoogleAccount = state.selectedGoogleAccount;
      if (selectedGoogleAccount == null) {
        const selectedGoogleAccountName = localStorage.getItem(
          'selectedGoogleAccountName'
        );
        if (selectedGoogleAccountName) {
          selectedGoogleAccount = action.payload.find(
            account => account.name == selectedGoogleAccountName
          );
        }
      }
      return {
        ...state,
        googleAccounts: action.payload,
        selectedGoogleAccount: selectedGoogleAccount,
        loading: false,
        error: false,
        errorMsg: null,
      };
    case googleAccountActionType.SELECTED_GOOGLE_ACCOUNT:
      if (state.googleAccounts.length > 0) {
        const selectedGoogleAccount = state.googleAccounts.find(
          account => account.name === action.payload.value
        );
        localStorage.setItem(
          'selectedGoogleAccountName',
          selectedGoogleAccount.name
        );
        return {
          ...state,
          loading: false,
          selectedGoogleAccount: selectedGoogleAccount,
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default GoogleAccountReducer;
export { googleAccountActionType };
