import React, { Fragment, useState, useImperativeHandle } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import classNames from 'classnames';
import ComponentTip from './ComponentTip';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import plagiarismPanelCollapse from '../store/actions/plagiarism_panel.action';
import { track } from '../services/track';
import {
  EyeIcon,
  ShieldCheckIcon,
  DownloadIcon,
  ShareIcon,
  LoginIcon,
  TrashIcon,
  ExternalLinkIcon,
  ChatAlt2Icon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import AssistantIcon from './icons/assistant-icon';

import PostStatusMenu from './post-builder-navigation/post-status-menu/post-status-menu';

const PostBuilderNavigation = React.forwardRef((props, ref) => {
  const base_url = process.env.REACT_APP_BACKEND_HOST;
  const [isGenerating, setGenerating] = useState(false);
  const versions = useSelector(state => state.post.versions);
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );
  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );
  const checkPlagiarismTriggered = useSelector(
    state => state.post.checkPlagiarismTriggered
  );
  const { state } = useLocation();
  let { id } = useParams();
  if (!id && state.id) {
    id = state.id;
  }

  useImperativeHandle(ref, () => ({
    updateProgressState(state, data) {
      switch (state) {
        case 'generating':
          setGenerating(data);
          break;
        default:
          break;
      }
    },
  }));

  const handleTogglePanel = panelType => () => {
    const panel =
      props.isPanelCollapsed === false && props.sidePanelValue === panelType
        ? true
        : props.isPanelCollapsed === false && props.sidePanelValue !== panelType
        ? false
        : !props.isPanelCollapsed;
    props.panelCollapse(panelType, panel);
  };

  return (
    <nav
      aria-label="Sidebar"
      className="py-6 flex flex-col items-center post-builder-navigation"
    >
      <ComponentTip text="Assistant">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={handleTogglePanel(sidePanelTypes.DEFAULT)}
        >
          <AssistantIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Assistant</span>
        </button>
      </ComponentTip>
      <ComponentTip text="Chat">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={handleTogglePanel(sidePanelTypes.CHAT)}
        >
          <ChatAlt2Icon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Chat</span>
        </button>
      </ComponentTip>
      <ComponentTip text="Preview">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={() => {
            props.navigationBarHandle('preview');
          }}
          disabled={isGenerating}
        >
          <EyeIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Assistant</span>
        </button>
      </ComponentTip>
      <PostStatusMenu
        onSave={status => {
          props.navigationBarHandle('saveAsDraft', status);
        }}
      />
      <ComponentTip text="Plagiarism">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={handleTogglePanel(sidePanelTypes.PLAGIARISM)}
        >
          <ShieldCheckIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Plagiarism</span>
        </button>
      </ComponentTip>
      <ComponentTip text="Fact Check">
        <button
          type="button"
          className={`text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg ${
            !webAccessEnabled &&
            !smartWebNavigatorAccess &&
            !hasTrialSubscription
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
          onClick={
            !webAccessEnabled &&
            !smartWebNavigatorAccess &&
            !hasTrialSubscription
              ? null
              : handleTogglePanel(sidePanelTypes.FACT_CHECKER)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="sr-only">Fact Check</span>
        </button>
      </ComponentTip>
      {props.user.is_owner && props.user.wordpress_integration && (
        <ComponentTip text="Publish">
          <button
            type="button"
            className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
            onClick={() => {
              props.navigationBarHandle('publish');
            }}
          >
            <ExternalLinkIcon className="h-6 w-6" aria-hidden="true" />
            <span className="sr-only">Publish</span>
          </button>
        </ComponentTip>
      )}
      <ComponentTip text="Share">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={() => {
            props.navigationBarHandle('share');
          }}
        >
          <ShareIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Share</span>
        </button>
      </ComponentTip>
      <ComponentTip text="Download">
        <Menu as="div" className="absolut inline-block text-left">
          <div>
            <Menu.Button className="text-gray-300 w-full hover:bg-gray-700 hover:text-white flex items-center rounded-lg">
              <DownloadIcon className="h-6 w-6" aria-hidden="true" />
              <span className="sr-only">Download</span>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-10 origin-top-right absolute  w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        track('Blog post creator', {
                          'Download filetype': 'html',
                        });
                      }}
                      href={`${base_url}/api/export-html/${id}`}
                      download
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      HTML
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        track('Blog post creator', {
                          'Download filetype': 'pdf',
                        });
                      }}
                      href={`${base_url}/api/export-pdf/${id}`}
                      download
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      PDF
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        track('Blog post creator', {
                          'Download filetype': 'docx',
                        });
                      }}
                      href={`${base_url}/api/export-docx/${id}`}
                      download
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      DOCX
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        track('Blog post creator', {
                          'Download filetype': 'txt',
                        });
                      }}
                      href={`${base_url}/api/export-txt/${id}`}
                      download
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      TXT
                    </a>
                  )}
                </Menu.Item>
                {versions && versions.length > 0 && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          track('Blog post creator', {
                            'Download filetype': 'versions',
                          });
                        }}
                        href={`${base_url}/api/export-versions/${id}`}
                        download
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        VERSIONS
                      </a>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </ComponentTip>
      <ComponentTip text="Help">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={() => {
            window.Intercom('show');
          }}
        >
          <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </ComponentTip>
      <ComponentTip text="Delete">
        <button
          type="button"
          className="text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={() => {
            props.navigationBarHandle('delete');
          }}
        >
          <TrashIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Delete</span>
        </button>
      </ComponentTip>
      <ComponentTip text="Exit">
        <button
          type="button"
          className=" rotate-45 text-gray-300 hover:bg-gray-700 hover:text-white flex items-center rounded-lg"
          onClick={() => {
            props.navigationBarHandle('exit');
          }}
        >
          <LoginIcon className="h-6 w-6 rotate-45" aria-hidden="true" />
          <span className="sr-only">Exit</span>
        </button>
      </ComponentTip>
    </nav>
  );
});

function mapStateToProps(state) {
  return {
    isPanelCollapsed: state.panel.isPanelCollapsed,
    sidePanelValue: state.panel.sidePanelValue,
    user: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  { panelCollapse, plagiarismPanelCollapse },
  null,
  { forwardRef: true }
)(PostBuilderNavigation);
