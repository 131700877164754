import { LOCATIONS } from '../../components/analytics-dashboard/LocationSelect';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDomainFromUrl, TONES } from '../../utils/common';
import useDataForSeoApi from '../../services/useDataForSeoApi';
import Loading from '../../components/Loading';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowUpIcon } from '@heroicons/react/solid';
import { ArrowDownIcon } from '@heroicons/react/outline';
const FEATURED_TYPES = {
  featured_snippet: 'Featured Snippet',
  people_also_ask: 'People Also Ask',
  organic: 'Organic',
  video: 'Video',
  people_also_search: 'People Also Search',
  related_searches: 'Related Searches',
};
const RankingKeywords = () => {
  const projectUrl = useSelector(
    state =>
      state.project?.selectedProject?.project_url ||
      state.auth?.user?.company?.domain ||
      ''
  );
  const dataForSeoRequest = useDataForSeoApi();
  const [analyticData, setAnalyticData] = useState([]);
  useEffect(() => {
    if (projectUrl) {
      getListKeywords();
    }
  }, [projectUrl]);
  const getListKeywords = async () => {
    const requestData = [
      {
        target: getDomainFromUrl(projectUrl),
        language_name: LOCATIONS[0].available_languages[0].language_name,
        location_name: LOCATIONS[0].location_name,
        ignore_synonyms: true,
        item_types: ['organic', 'featured_snippet', 'local_pack'],
        historical_sep_mode: 'live',
        order_by: ['ranked_serp_element.serp_item.rank_absolute,asc'],
        include_serp_info: true,
        load_rank_absolute: true,
        limit: 10,
      },
    ];
    const response = await dataForSeoRequest.send(
      '/dataforseo_labs/google/ranked_keywords/live',
      requestData
    );
    const task = response?.tasks ? response?.tasks[0] : null;
    if (!task) {
      return;
    }
    const result = task?.result ? task?.result[0] : null;
    if (!result || !result.items) {
      return;
    }
    const data = result.items.map(item => ({
      keyword: item?.keyword_data?.keyword || '',
      searchVolume: item?.keyword_data?.keyword_info?.search_volume || 0,
      ranking: item?.ranked_serp_element?.serp_item?.rank_absolute || 9999,
      url: item?.ranked_serp_element?.serp_item?.relative_url || '',
      type: FEATURED_TYPES[item?.ranked_serp_element?.serp_item?.type] || '',
      is_down: item?.ranked_serp_element?.serp_item?.rank_changes.is_down,
      is_up: item?.ranked_serp_element?.serp_item?.rank_changes.is_up,
    }));
    setAnalyticData(data);
  };
  const renderRanking = item => {
    let rankStatus = '';
    if (item.is_up) {
      rankStatus = <ArrowUpIcon className="w-3 h-3 text-green-600" />;
    } else if (item.is_down) {
      rankStatus = <ArrowDownIcon className="w-3 h-3 text-red-600" />;
    }
    return (
      <div className="flex gap-2 items-center">
        <span>#{item.ranking}</span>
        <span>{rankStatus}</span>
      </div>
    );
  };
  return (
    <div className="bg-white p-3 rounded-lg shadow-lg flex h-full">
      {analyticData.length > 0 ? (
        <div className="w-full">
          {
            <>
              <div className="text-lg text-left font-bold">
                Ranking Keywords
              </div>
              <div className="h-64 overflow-auto mt-3">
                {dataForSeoRequest.loading ? (
                  <div className="flex w-full h-full items-center justify-center">
                    <div>
                      <Loading />
                      <p>Loading analytic data...</p>
                    </div>
                  </div>
                ) : (
                  <table className="table-auto w-full text-sm">
                    <tbody>
                      {analyticData.map(item => (
                        <tr key={item.keyword} className="text-gray-600">
                          <td className="w-36 text-left p-2">
                            <div className="w-36 truncate">
                              <Tooltip title={item.keyword}>
                                <span>{item.keyword}</span>
                              </Tooltip>
                            </div>
                          </td>
                          <td className="w-8">{renderRanking(item)}</td>
                          <td className="w-8">{item.searchVolume}</td>
                          <td className="w-36 text-xs">{item.type}</td>
                          <td className="text-left">
                            <div className="w-36 truncate pl-2">
                              <Tooltip title={item.url}>
                                <span>{item.url}</span>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          }
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
export default RankingKeywords;
