const TableContainer = props => {
  return (
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {props.children}
      </div>
    </div>
  );
};

export default TableContainer;
