const accessActionTypes = {
  FORCE_CHOOSE_PLAN: 'FORCE_CHOOSE_PLAN',
  RESET_FORCE_CHOOSE_PLAN: 'RESET_FORCE_CHOOSE_PLAN',
  SHOWN_PLAN: 'SHOWN_PLAN',
};

const initialState = {
  forceChoosePlan: false,
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case accessActionTypes.FORCE_CHOOSE_PLAN:
      return {
        forceChoosePlan: true,
      };
    case accessActionTypes.RESET_FORCE_CHOOSE_PLAN:
    case accessActionTypes.SHOWN_PLAN:
      return {
        forceChoosePlan: false,
      };
    default:
      return state;
  }
};

export default accessReducer;
export { accessActionTypes };
