import React from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';

const defaultStatuses = ['Draft', 'Complete', 'Published'];

const Status = React.forwardRef(
  (
    {
      value = '',
      onRemove = () => {},
      isActive = false,
      onIsActiveChange = () => {},
      ...props
    },
    ref
  ) => {
    const isDefault = defaultStatuses.includes(value);

    return (
      <div
        className={cx(
          'flex bg-white gap-x-4 py-2 px-4 rounded-md my-1 justify-between items-center border-2 border-gray-100 shadow-sm',
          isDefault ? '' : 'hover:border-purple-600 cursor-pointer'
        )}
        ref={ref}
        {...props}
      >
        {!isDefault && (
          <Switch
            checked={isActive}
            onChange={onIsActiveChange}
            className={`${
              isActive ? 'bg-purple-700' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                isActive ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white`}
            />
          </Switch>
        )}
        <p className="select-none text-left flex-1">{value}</p>
        {!isDefault && (
          <Tooltip title="Delete" arrow>
            <button
              onClick={event => {
                event.preventDefault();
                onRemove();
              }}
              className="inline-flex ml-3 items-center p-0 border border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
            >
              <TrashIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
            </button>
          </Tooltip>
        )}
      </div>
    );
  }
);

export default Status;
