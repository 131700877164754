import { Fragment, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import defaultAvatar from '../styles/images/default-avatar.svg';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import { authLogout } from '../store/actions/auth.actions';
import { destroyProjects } from '../store/actions/project.action';
import { destroyTeam } from '../store/actions/team.actions';
import { ChevronDownIcon, BellIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAlert } from 'react-alert';

function Header({
  logout,
  destroyProjects,
  destroyTeam,
  name,
  avatar,
  user,
  displaySearch,
  searchText,
  searchPost,
}) {
  const alert = useAlert();

  let userNavigation = [
    { name: 'My Profile', href: '/profile', internal: true },
    { name: 'My Account', href: '/account', internal: true },
  ];
  if (user.is_owner || user.is_manager) {
    userNavigation.push({
      name: 'Team',
      href: '/team',
      internal: true,
    });
  }
  if (user.is_owner) {
    userNavigation.push({
      name: 'Integrations',
      href: '/integrations',
      internal: true,
    });
  }
  userNavigation = [
    ...userNavigation,
    { name: 'Settings', href: '/settings', internal: true },
    {
      name: 'Help',
      onClick: () => {
        window.Intercom('show');
      },
      internal: false,
    },
    { name: 'Sign out', href: '#', internal: null },
  ];

  // Define the WebSocket connection URL
  // const socketUrl = `${process.env.REACT_APP_BACKEND_HOST.replace(
  //   'http',
  //   'ws'
  // )}/ws/notifications/`;

  // Use the useWebSocket hook
  // const { lastMessage, readyState } = useWebSocket(socketUrl);

  // useEffect(() => {
  //   if (readyState === ReadyState.OPEN && lastMessage !== null) {
  //     const message = JSON.parse(lastMessage.data);
  //     console.log('Received notification: ', message);
  //     alert.success(message.message);
  //   }
  // }, [lastMessage, readyState, alert]);

  // const [ws, setWs] = useState(null);

  // useEffect(() => {
  //   // Define the WebSocket connection URL
  //   const socketUrl = `${process.env.REACT_APP_BACKEND_HOST.replace(
  //     'http',
  //     'ws'
  //   )}/ws/notifications/`;

  //   // Create a new WebSocket connection
  //   const newWs = new WebSocket(socketUrl);

  //   // Set the WebSocket connection
  //   setWs(newWs);

  //   console.log('WebSocket connection established'); // Log the WebSocket connection status

  //   // Clean up the WebSocket connection on unmount
  //   return () => {
  //     newWs.close();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (ws !== null) {
  //     // Handle incoming messages
  //     ws.onmessage = (event) => {
  //       const message = JSON.parse(event.data);
  //       console.log('Received notification: ', message);
  //       console.log('Showing alert notification'); // Log the alert notification
  //       alert.show(message.message); // Show alert notification
  //     };
  //   }
  // }, [ws, alert]);

  return (
    <div className="flex-1 px-4 flex justify-between">
      <div className="flex-1 flex">
        {displaySearch && (
          <form className="w-full flex md:ml-0">
            {/* onSubmit={searchPost} */}
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
                value={searchText}
                onChange={e => {
                  e.preventDefault();
                  searchPost(e.target.value);
                }}
                data-hj-allow
              />
            </div>
          </form>
        )}
      </div>
      <div className="ml-4 flex items-center md:ml-6">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 pr-7"
        >
          <span className="sr-only">View notifications</span>
          <BellIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div
          className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-300"
          aria-hidden="true"
        />
        {/* Profile dropdown */}
        <Menu as="div" className="ml-3 relative">
          <div>
            <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
              <img className="h-8 w-8 rounded-full" src={avatar} alt="" />
              <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                <span className="sr-only">Open user menu for </span>
                {name}
              </span>
              <ChevronDownIcon
                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {userNavigation.map(item => (
                <Menu.Item key={item.name}>
                  {({ active }) =>
                    item.internal === false ? (
                      <a
                        {...(!!item.href
                          ? {
                              rel: 'noreferrer',
                              target: '_blank',
                              href: item.href,
                            }
                          : null)}
                        onClick={!!item.onClick ? item.onClick : () => {}}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                        )}
                      >
                        {item.name}
                      </a>
                    ) : item.internal === true ? (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <Link
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                        onClick={() => {
                          destroyProjects();
                          destroyTeam();
                          logout();
                        }}
                      >
                        {item.name}
                      </Link>
                    )
                  }
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const user = state.auth.user || {};
  return {
    name: user.full_name || user.email,
    avatar: user.preview || defaultAvatar,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, {
  logout: authLogout,
  destroyProjects,
  destroyTeam,
})(Header);
