import { layoutActionTypes } from '../reducers/layout.reducer';

export default function layoutView(isListView) {
  let listView = isListView.toString();
  localStorage.setItem('isListView', listView);
  return createAction(listView);
}

function loadListViewState() {
  let isListView = localStorage.getItem('isListView');
  return createAction(isListView);
}

function createAction(isListView) {
  if (isListView === 'true') {
    return {
      type: layoutActionTypes.SHOW_LIST,
    };
  } else {
    return {
      type: layoutActionTypes.SHOW_GRID,
    };
  }
}

export { loadListViewState };
