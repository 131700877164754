import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import menuReducer from './menu.reducer';
import accessReducer from './access.reducer';
import platformReducer from './platform.reducer';
import postBuilder from './post_builder.reducer';
import TeamReducer from './team.reducer';
import rolesReducer from './roles.reducer';
import projectReducer from './project.reducer';
import sidePanelReducer from './side_panel.reducer';
import layoutReducer from './layout.reducer';
import plagiarismReducer from './plagiarism.reducer';
import plagiarismPanelReducer from './plagiarism_panel.reducer';
import generateFirstDraftReducer from './generate-first-draft';
import novaOnBoardingGuideReducer from './nova_onboarding.reducer';
import chatReducer from './chat.reducer';
import factCheckerReducer from './fact_checker.reducer';
import GooglePropertyReducer from './google_analytic_properties.reducer';
import GoogleAccountReducer from './google_analytic_account.reducer';

const RootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  access: accessReducer,
  platform: platformReducer,
  post: postBuilder,
  team: TeamReducer,
  role: rolesReducer,
  project: projectReducer,
  panel: sidePanelReducer,
  layout: layoutReducer,
  plagiarism: plagiarismReducer,
  plagiarismPanel: plagiarismPanelReducer,
  generateFirstDraft: generateFirstDraftReducer,
  novaOnBoardingGuide: novaOnBoardingGuideReducer,
  chat: chatReducer,
  factChecker: factCheckerReducer,
  googleAnalyticProperty: GooglePropertyReducer,
  googleAnalyticAccount: GoogleAccountReducer,
});

export default RootReducer;
