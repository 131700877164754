import React, { useState, useEffect } from 'react';
import { TrashIcon, XIcon } from '@heroicons/react/outline';
import { PencilIcon, PlusSmIcon } from '@heroicons/react/solid';
import LibraryImageModal from '../pages/PostBuilder/LibraryImageModal';

function FeaturedImage({ file, phrase, updateFeaturedImage }) {
  // const [image, setImage] = useState(false);
  const [isLibraryModalVisible, showLibraryModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(file);

  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  function closeLibraryModal() {
    showLibraryModal(false);
  }

  function updateFileDetail() {
    updateFeaturedImage(1, {
      file: currentFile,
    });
  }

  function resetFileDetail() {
    if (file) {
      setCurrentFile(file);
    }
  }

  function updateImage(file) {
    updateFeaturedImage(1, {
      file: file,
    });
    closeLibraryModal();
  }

  return (
    <>
      {isLibraryModalVisible ? (
        <LibraryImageModal
          state={file ? [file] : []}
          type={'HeroImage'}
          phrase={phrase}
          updateState={updateImage}
          opened={isLibraryModalVisible}
          close={closeLibraryModal}
        />
      ) : (
        ''
      )}
      <div className="h-full bg-white p-8 overflow-y-auto">
        <div className="pb-8 space-y-6">
          <div>
            {currentFile?.thumbnail ? (
              <div
                onClick={() => showLibraryModal(true)}
                className="cursor-pointer block rounded-lg overflow-hidden"
              >
                <img
                  src={currentFile.url}
                  alt={
                    currentFile && currentFile.alt_text
                      ? currentFile.alt_text
                      : ''
                  }
                  className="object-cover h-64 w-112"
                />
              </div>
            ) : (
              <div
                onClick={() => showLibraryModal(true)}
                className="cursor-pointer border-4 border-dashed hover:border-purple-300 border-gray-200 rounded-lg h-32 w-64 sm:h-64 sm:w-112 flex"
              >
                <p className="text-sm font-medium text-gray-500 m-auto">
                  Set featured image
                </p>
              </div>
            )}
            <div className="mt-4 flex justify-between">
              <div>
                {currentFile?.id && (
                  <h2 className="text-lg font-medium text-gray-900">
                    <span>File Id: {currentFile?.id}</span>
                  </h2>
                )}
              </div>
              <div>
                {/* <button
                type="button"
                className="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <PencilIcon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Edit</span>
              </button> */}
                <button
                  type="button"
                  className="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500"
                  onClick={() => {
                    updateFeaturedImage(1, {
                      file: null,
                    });
                  }}
                >
                  <TrashIcon className="h-6 w-6" aria-hidden="true" />
                  {/*<span className="sr-only">Delete</span>*/}
                </button>
              </div>
            </div>
          </div>
          {/* <div>
            <h3 className="font-medium text-gray-900">Information</h3>
            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
              <div className="py-3 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Created</dt>
                <dd className="text-gray-900">June 8, 2020</dd>
              </div>
              <div className="py-3 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Dimensions</dt>
                <dd className="text-gray-900">4032 x 3024</dd>
              </div>
              <div className="py-3 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Resolution</dt>
                <dd className="text-gray-900">72 x 72</dd>
              </div>
            </dl>
          </div> */}

          {currentFile && currentFile.id && (
            <div>
              <h3 className="font-medium text-gray-900">Meta</h3>
              <div className="mt-2">
                <label
                  htmlFor="alt-text"
                  className="block text-sm font-medium text-gray-500"
                >
                  Alt text
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="alt-text"
                    id="alt-text"
                    // value={file?.alt_text ? file?.alt_text : ''}
                    value={currentFile.alt_text}
                    onChange={e => {
                      setCurrentFile({
                        ...currentFile,
                        alt_text: e.target.value,
                      });
                    }}
                    className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-500"
                >
                  Title
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={currentFile.title}
                    onChange={e => {
                      setCurrentFile({ ...currentFile, title: e.target.value });
                    }}
                    className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-500"
                >
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    value={currentFile.description}
                    onChange={e => {
                      setCurrentFile({
                        ...currentFile,
                        description: e.target.value,
                      });
                    }}
                    className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
                    // defaultValue={''}
                  />
                </div>
              </div>
              <div className="mt-2 md:flex w-full justify-center border-gray-300  bg-white">
                {/* <div className="buttons-wrapper"> */}
                <div className="px-2">
                  <button
                    className="cursor-pointer w-32 py-1 flex justify-center border-gray-300 shadow-sm text-sm font-medium rounded-md border border-transparent text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={resetFileDetail}
                  >
                    Cancel
                  </button>
                </div>
                <div className="px-2">
                  <button
                    className="cursor-pointer text-white w-32 py-1 flex justify-center shadow-sm text-sm font-medium rounded-md border border-transparent bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={updateFileDetail}
                  >
                    Update
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FeaturedImage;
