import React, { useState } from 'react';
import './ForgotPassword.scss';
import { Link } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import Validator, { confirm, min, required } from '../services/Validator';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import useSentData from '../services/useSentData';
import { isolateError, isolateSuccess } from '../utils/api';
import Loading from '../components/Loading';
import { useAlert } from 'react-alert';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';

// function PasswordForm({ validator }) {
//   const request = useSentData();
//   const [data, setData] = useState({
//     password: '',
//     password_confirm: '',
//   });
//   const [errors, setErrors] = useState({
//     password: false,
//     password_confirm: false,
//   });
//   let { token } = useParams();
//   let history = useHistory();
//   const alert = useAlert();

//   function change(name, val) {
//     const newData = {
//       ...data,
//       [name]: val,
//     };
//     setData(newData);
//     setErrors(validator.validateAll(newData));
//   }

//   return (
//     <form
//       onSubmit={e => {
//         e.preventDefault();
//         if (!request.loading && validator.isValid()) {
//           request
//             .send('/api/reset-password', { ...data, token })
//             .then(response => {
//               if (response && !response.isAxiosError) {
//                 alert.success(
//                   isolateSuccess(response, 'Successfully changed!')
//                 );
//                 history.push('/');
//               } else {
//                 alert.error(isolateError(response));
//               }
//             });
//         }
//       }}
//     >
//       {request.loading && <Loading />}
//       <CustomInput
//         id="password"
//         type="password"
//         label="Password"
//         value={data.password}
//         onChange={val => change('password', val)}
//         className={classNames({
//           invalid: !errors.password,
//           valid: !!errors.password,
//         })}
//       />
//       <CustomInput
//         id="password_confirm"
//         type="password"
//         label="Confirm Password"
//         value={data.password_confirm}
//         onChange={val => change('password_confirm', val)}
//         className={classNames({
//           invalid: !errors.password_confirm,
//           valid: !!errors.password_confirm,
//         })}
//       />
//       <button
//         className="btn btn-red btn-lg btn-block"
//         disabled={!errors.password || !errors.password_confirm}
//       >
//         Confirm
//       </button>
//     </form>
//   );
// }

function ResetPasswordForm({ validator }) {
  const request = useSentData();
  const [data, setData] = useState({
    password: '',
    password_confirm: '',
  });
  const [errors, setErrors] = useState({
    password: false,
    password_confirm: false,
  });
  let { token } = useParams();
  let history = useHistory();
  const alert = useAlert();

  function change(name, val) {
    const newData = {
      ...data,
      [name]: val,
    };
    setData(newData);
    setErrors(validator.validateAll(newData));
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logoIconPurple}
            alt="Bramework"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please create a new password
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                if (!request.loading && validator.isValid()) {
                  request
                    .send('/api/reset-password', { ...data, token })
                    .then(response => {
                      if (response && !response.isAxiosError) {
                        alert.success(
                          isolateSuccess(response, 'Successfully changed!')
                        );
                        history.push('/');
                      } else {
                        alert.error(isolateError(response));
                      }
                    });
                }
              }}
            >
              {request.loading && <Loading />}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={data.password}
                    onChange={e => change('password', e.target.value)}
                    required
                    aria-invalid={errors.password}
                    aria-describedby="password-error"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.password && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-error"
                    >
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password_confirm"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="password_confirm"
                    name="password_confirm"
                    type="password"
                    value={data.password_confirm}
                    onChange={e => change('password_confirm', e.target.value)}
                    required
                    aria-invalid={errors.password_confirm}
                    aria-describedby="password-confirm-error"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.password_confirm && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-confirm-error"
                    >
                      {errors.password_confirm}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link
                    to="/"
                    className="font-medium text-purple-600 hover:text-purple-500"
                  >
                    Back to sign in
                  </Link>
                </div>
              </div>

              <div>
                <button
                  id="submit"
                  disabled={!errors.password || !errors.password_confirm}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    !errors.password || !errors.password_confirm
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default function ResetPassword() {
  const validator = Validator({
    password: [required, min(8)],
    password_confirm: [required, confirm('password')],
  });

  return (
    <ResetPasswordForm validator={validator} />
    // <div className="forgot-password-wrapper auth-wrapper">
    //   <header>
    //     <a className="logo-icon" href="https://www.bramework.com/"></a>
    //     <div className="float-right">
    //       <Link className="btn btn-purple" to="/register">
    //         Sign Up
    //       </Link>
    //     </div>
    //   </header>
    //   <main>
    //     <div className="form">
    //       <h1>Reset Password</h1>
    //       <h2>Please create a new password</h2>
    //       <ResetPasswordForm validator={validator} />
    //       <div className="sign-in-wrapper">
    //         <Link to="/" className="sign-in-button">
    //           Back to Sign In
    //         </Link>
    //       </div>
    //     </div>
    //   </main>
    // </div>
  );
}
