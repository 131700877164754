import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PencilAltIcon,
  NewspaperIcon,
  TemplateIcon,
  KeyIcon,
  SwitchHorizontalIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import { useAlert } from 'react-alert';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import DashboardItem from './Dashboard/DashboardItem';
import StartHere from '../components/StartHere';
import DashboardOverview from '../components/dashboard/dashboard-overview';
import Loading from '../components/Loading';
import DashboardList from '../components/dashboard/dashboard-list';
import DashboardOverviewContainer from '../components/dashboard/dashboard-overview-container';

import noImage from '../styles/images/bramework-no-image.svg';
import titleImage from '../styles/images/bramework-titles-image.svg';
import outlineImage from '../styles/images/bramework-outlines-image.svg';
import rewriteImage from '../styles/images/bramework-rewrites-image.svg';
import summaryImage from '../styles/images/bramework-summary-image.svg';
import store from '../store';
import useSentData from '../services/useSentData';
import { fetchProjects } from '../store/actions/project.action';
import { getProjectPostStatuses } from '../utils/post-status';
import './Dashboard.scss';
import AnalyticDashboard from './AnalyticDashboard/AnalyticDashboard';
import GoogleAnalytics from './AnalyticDashboard/GoogleAnalytics';

const cards = [
  { name: 'Keyword searches', amount: '2000', href: '/keywords' },
  { name: 'Titles', amount: '2500', href: '/titles' },
  { name: 'Outlines', amount: '1000', href: '/keywords' },
  { name: 'Posts', amount: '2537', href: '/posts' },
];

const overview = [
  { key: 'keywords', lable: 'Keyword searches' },
  { key: 'titles', lable: 'Titles' },
  { key: 'outlines', lable: 'Outlines' },
  { key: 'rewrites', lable: 'Rewrites' },
  { key: 'summaries', lable: 'Summaries' },
  { key: 'posts', lable: 'Posts' },
];

function Dashboard(props) {
  const { isListView = false } = props;
  const request = useSentData();
  const history = useHistory();
  const alert = useAlert();
  const { project_id } = useParams();

  const [showAnalyticDashboard, setShowAnalyticDashboard] = useState(() => {
    // Get the initial state from local storage or default to false
    const savedState = localStorage.getItem('showAnalyticDashboard');
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  let [posts, setPosts] = useState({
    recent: [],
    complete: [],
    overview: null,
    loadCompleted: false,
  });

  const [postStatuses, setPostStatuses] = useState([]);
  const state = store.getState();
  const user = state.auth.user;

  var date = new Date(user.date_joined);
  var unixTimeStamp = Math.floor(date.getTime() / 1000);
  if (process.env.REACT_APP_INTERCOM_ID) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      name: user.full_name,
      email: user.email,
      user_id: user.id,
      created_at: unixTimeStamp,
    });
  }
  // window.userGuiding.identify(user.email, {
  //   name: user.full_name,
  //   industry: user.company.industry,
  //   profession: user.profession,
  //   createdAt: user.date_joined,
  // });

  useEffect(() => {
    // Save to local storage whenever showAnalyticDashboard changes
    localStorage.setItem(
      'showAnalyticDashboard',
      JSON.stringify(showAnalyticDashboard)
    );
  }, [showAnalyticDashboard]);

  function updatePosts() {
    setPosts(prevState => ({ ...prevState, loadCompleted: false }));
    request
      .send(`/api/projects/${project_id}/posts/dashboard`, null, 'get')
      .then(data => {
        if (!data.isAxiosError) {
          setPosts({ ...data, loadCompleted: true });
        }
      });
  }
  useEffect(updatePosts, [props.selectedProject]);

  // Define the WebSocket connection URL
  // const socketUrl = `${process.env.REACT_APP_BACKEND_HOST.replace(
  //   'http',
  //   'ws'
  // )}/ws/notifications/`;

  // Use the useWebSocket hook
  // const { lastMessage, readyState } = useWebSocket(socketUrl);

  // useEffect(() => {
  //   if (readyState === ReadyState.OPEN && lastMessage !== null) {
  //     const message = JSON.parse(lastMessage.data);
  //     console.log('Received notification: ', message);
  //     updatePosts();
  //   }
  // }, [lastMessage, readyState]);

  useEffect(() => {
    if (!!project_id) {
      getProjectPostStatuses(request, project_id).then(data => {
        if (!data || !Array.isArray(data)) {
          return;
        }
        const activeStatuses = data.filter(stat => stat.is_active);
        setPostStatuses(activeStatuses);
      });
    }
  }, [project_id]);

  const loadCompleted = posts?.loadCompleted;
  const showListView = !!posts?.recent?.length && isListView && loadCompleted;
  const showGridView = !!posts?.recent?.length && !isListView && loadCompleted;
  const showDashboardOverview = !!posts?.recent?.length && loadCompleted;
  const isLoading = request.loading || !request.success;

  const getPostName = post => {
    if (post.category === 4 || post.category === 5) {
      if (post.category === 4) {
        return post.rewrites[0] ? post.rewrites[0].output.text : '(no title)';
      } else {
        return post.summaries[0] ? post.summaries[0].output.text : '(no title)';
      }
    } else {
      return post.category && post.body && post.body[0].state.text
        ? post.body[0].state.text
        : post.category && post.body && !post.body[0].state.text
        ? '(no title)'
        : post.phrase;
    }
  };

  return (
    <NewWrapper
      header={<NewHeader />}
      x
      pageHeading={
        <PageHeading
          title="🎛️ Dashboard"
          category="dashboard"
          isShowAnalyticDashboard={showAnalyticDashboard}
          setShowAnalyticDashboard={setShowAnalyticDashboard}
        />
      }
    >
      <div className="posts-list drafts cards-wrapper">
        <div className="posts-wrapper">
          {showAnalyticDashboard ? (
            <GoogleAnalytics />
          ) : (
            // <AnalyticDashboard />
            <>
              {(isLoading || !loadCompleted) && <Loading />}
              {!isLoading && loadCompleted && (
                <>
                  {showDashboardOverview && (
                    <DashboardOverviewContainer>
                      {posts.overview &&
                        overview.map((obj, index) => (
                          <DashboardOverview
                            key={`card-${index}`}
                            name={obj.lable}
                            value={posts.overview[obj.key]}
                            renderIcon={() => {
                              switch (obj.key) {
                                case 'posts':
                                  return <PencilAltIcon />;
                                case 'titles':
                                  return <NewspaperIcon />;
                                case 'outlines':
                                  return <TemplateIcon />;
                                case 'rewrites':
                                  return <SwitchHorizontalIcon />;
                                case 'summaries':
                                  return <SparklesIcon />;
                                case 'keywords':
                                default:
                                  return <KeyIcon />;
                              }
                            }}
                            onAction={() => {
                              if (obj.key === 'posts') {
                                history.push(`/project/${project_id}/drafts`);
                              } else {
                                history.push(
                                  `/project/${project_id}/${obj.key}`
                                );
                              }
                            }}
                          />
                        ))}
                    </DashboardOverviewContainer>
                  )}
                  <div>
                    {posts?.recent?.length ? <h4>Recent Activity</h4> : ''}
                    {showGridView &&
                      posts?.recent?.map(post => (
                        <DashboardItem
                          key={post.id}
                          id={post.id}
                          url={post.domain}
                          name={getPostName(post)}
                          thumbnail={
                            post.category &&
                            post.category === 1 &&
                            post.body &&
                            post.body[1].state.file
                              ? post.body[1].state.file.thumbnail
                              : post.category === 2
                              ? titleImage
                              : post.category === 3
                              ? outlineImage
                              : post.category === 4
                              ? rewriteImage
                              : post.category === 5
                              ? summaryImage
                              : noImage
                          }
                          date={
                            post.category ? post.edited_at : post.analysis_at
                          }
                          body={post.body}
                          results={post.analysis_data?.results}
                          difficulty={post.analysis_data?.difficulty}
                          competition={post.analysis_data?.competition}
                          volume={post.analysis_data?.volume}
                          category={post.category}
                          published={post.published}
                          generations={
                            post.category === 2
                              ? post.titles
                              : post.category === 3
                              ? post.outlines
                              : post.category === 4
                              ? post.rewrites
                              : post.summaries
                          }
                          callback={updatePosts}
                          post={post}
                          postStatuses={postStatuses}
                        />
                      ))}
                    {showListView && (
                      <DashboardList
                        items={posts?.recent}
                        onUpdate={updatePosts}
                      />
                    )}
                  </div>
                  {/* <div>
            {posts.complete.length ? <h4>Published</h4> : ''}
            {posts.complete.length
              ? posts.complete.map(post => (
                  <DashboardItem
                    key={post.id}
                    id={post.id}
                    url={post.domain}
                    name={
                      post.body[0].state.text
                        ? post.body[0].state.text
                        : post.phrase
                    }
                    thumbnail={
                      post.category && post.body[1].state.file
                        ? post.body[1].state.file.thumbnail
                        : noImage
                    }
                    date={post.edited_at}
                    body={post.body}
                    results={post.analysis_data.results}
                    difficulty={post.analysis_data.difficulty}
                    competition={post.analysis_data.competition}
                    volume={post.analysis_data.volume}
                    category={post.category}
                    published={post.published}
                    callback={updatePosts}
                  />
                ))
              : ''}
          </div> */}
                  {loadCompleted &&
                  !posts?.recent?.length &&
                  (props.user.is_owner ||
                    props.user.is_manager ||
                    props.user.is_editor) ? (
                    <StartHere selectedProjectId={project_id} />
                  ) : !posts.recent.length && props.user.is_viewer ? (
                    <h1>You have no created posts.</h1>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </NewWrapper>
  );
}
function mapStateToProps(state) {
  return {
    selectedProject: state.project.selectedProject,
    projects: state.project.projects,
    user: state.auth.user,
    isListView: state.layout.isListView,
  };
}

export default connect(mapStateToProps, { fetchProjects })(Dashboard);
