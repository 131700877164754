import React from 'react';

export default function HeroImagePreview({ state }) {
  return (
    <div>
      <img
        className="hero-image-wrapper"
        // src={state.file !== null ? state.file.url : '/'}
        src={state.file && state.file.url ? state.file.url : '/'}
        alt={state.file && state.file.alt_text ? state.file.alt_text : ''}
      />
    </div>
  );
}
