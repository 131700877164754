import useSentData from '../../services/useSentData';
import React, { useState } from 'react';
import { chooseOption, listToOption } from '../../services/api';
import { useAlert } from 'react-alert';
import Validator, {
  required,
  validateURLExist,
} from '../../services/Validator';
import { onIndustryChange } from '../../services/track';
import { isolateError } from '../../utils/api';
import Loading from '../../components/Loading';
import FormInput from '../../components/form/FormInput';
import Select from '../../components/form/FormSelect';
import { connect } from 'react-redux';
import { updateUser } from '../../store/actions/auth.actions';
import useLoadedData from '../../services/useLoadedData';

function CompanyInformationRaw({ company, industries, updateUser, user }) {
  const request = useSentData();
  const [name, setName] = useState(company.name || '');
  const [domain, setDomain] = useState(company.domain || '');
  const [industry, setIndustry] = useState(
    chooseOption(industries, company.industry)
  );
  const [needShowErrors, showErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const alert = useAlert();

  const validator = Validator({
    domain: [validateURLExist],
    industry: [required],
  });
  let validation = validator.validateAll({ domain, industry });

  function submit(e) {
    e.preventDefault();
    if (!request.loading && validator.isValid()) {
      request
        .send('/api/company', {
          name,
          domain,
          industry: (industry || {}).value,
        })
        .then(res => {
          if (!res.isAxiosError) {
            updateUser({ company: res });
            const userIndustry = industries.find(i => i.value === res.industry);
            onIndustryChange({ id: user.id, industry: userIndustry.label });
            alert.success('Successfully changed!');
          } else {
            showErrors(true);
            setServerErrors(res.response.data);
            alert.error(isolateError(res));
          }
        });
    } else {
      showErrors(true);
    }
  }

  return (
    <form onSubmit={submit}>
      <div className="inputs-wrapper ml-0 md:ml-20">
        <div className="ml-0 lg:ml-10">
          <h4>Company information</h4>
          {request.loading && <Loading />}
          <FormInput
            id="name"
            className="profile-input mt-4"
            label="Name"
            value={name}
            onChange={v => setName(v)}
          />
          <FormInput
            id="domain"
            className="profile-input"
            label="Domain or Website URL"
            value={domain}
            onChange={v => {
              if (serverErrors.domain) {
                let newServerErrors = { ...serverErrors };
                delete newServerErrors.domain;
                setServerErrors(newServerErrors);
              }
              return setDomain(v);
            }}
            isValid={
              needShowErrors && (!validation.domain || serverErrors.domain)
            }
          />
          <Select
            id="industry"
            label="Industry *"
            className="profile-select"
            options={industries}
            value={industry}
            onChange={v => {
              if (serverErrors.industry) {
                let newServerErrors = { ...serverErrors };
                delete newServerErrors.industry;
                setServerErrors(newServerErrors);
              }
              return setIndustry(v);
            }}
            isValid={
              needShowErrors && (!validation.industry || serverErrors.industry)
            }
          />
        </div>
      </div>
      <div className="button-wrapper">
        <button
          id="submit"
          className="w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Save
        </button>
      </div>
    </form>
  );
}

function mapStateToProps(state) {
  const user = state.auth.user || {};
  return { company: user.company || {}, user };
}

const CompanyInformationForm = connect(mapStateToProps, { updateUser })(
  CompanyInformationRaw
);

export default function CompanyInformationContainer() {
  const lists = useLoadedData('/api/lists');
  if (lists.loading) {
    return <Loading />;
  } else if (lists.error) {
    return <div className="message error">{isolateError(lists.error)}</div>;
  } else if (lists.data) {
    const industries = listToOption(lists.data.industry_list);
    return <CompanyInformationForm industries={industries} />;
  }
}
