const generateFirstDraftActionTypes = {
  SET_INDUSTRY: 'SET_INDUSTRY',
  SET_PRIMARY_TOPIC: 'SET_PRIMARY_TOPIC',
  SET_ADDITIONAL_KEYWORDS: 'SET_ADDITIONAL_KEYWORDS',
  SET_TITLE: 'SET_TITLE',
  SET_OUTLINE: 'SET_OUTLINE',
  SET_DRAFT: 'SET_DRAFT',
  SET_TONE: 'SET_TONE_DRAFT',
  SET_AUDIENCE: 'SET_AUDIENCE',
  SET_OPTION: 'SET_OPTION',
  SET_OUTLINE_ID: 'SET_OUTLINE_ID',
  SET_INSTRUCTIONS: 'SET_INSTRUCTIONS',
  SET_URL: 'SET_URL',
  SET_BRAND_VOICE: 'SET_BRAND_VOICE',
};

const initialState = {
  primary_topic: '',
  title: '',
  outline: '',
  tone: 'informative',
  draft: null,
  audience: '',
  outline_id: null,
  options: {
    includeTitles: false,
    includeMetaDescriptions: false,
    includeImageAltTexts: false,
    includeBoldText: false,
    includeExternalLinks: false,
    includeInternalLinks: false,
    includeFAQs: false,
    includeSources: false,
    includeTableOfContents: false,
    includeKeyTakeaways: false,
    includeResearchReport: false,
    includeFeaturedImage: false,
    includeBodyImages: false,
  },
  instructions: '',
  url: '',
  brand_voice: '',
};

const generateFirstDraftReducer = (state = initialState, action) => {
  switch (action.type) {
    case generateFirstDraftActionTypes.SET_PRIMARY_TOPIC:
      return { ...state, primary_topic: action.payload };
    case generateFirstDraftActionTypes.SET_TITLE:
      return { ...state, title: action.payload };
    case generateFirstDraftActionTypes.SET_OUTLINE:
      return { ...state, outline: action.payload };
    case generateFirstDraftActionTypes.SET_DRAFT:
      return { ...state, draft: action.payload };
    case generateFirstDraftActionTypes.SET_TONE:
      return { ...state, tone: action.payload };
    case generateFirstDraftActionTypes.SET_AUDIENCE:
      return { ...state, audience: action.payload };
    case generateFirstDraftActionTypes.SET_OUTLINE_ID:
      return { ...state, outline_id: action.payload };
    case generateFirstDraftActionTypes.SET_INSTRUCTIONS:
      return { ...state, instructions: action.payload };
    case generateFirstDraftActionTypes.SET_URL:
      return { ...state, url: action.payload };
    case generateFirstDraftActionTypes.SET_BRAND_VOICE:
      return { ...state, brand_voice: action.payload };
    case generateFirstDraftActionTypes.SET_OPTION:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.option]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default generateFirstDraftReducer;
export { generateFirstDraftActionTypes };
