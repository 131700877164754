import React, { useEffect, useState } from 'react';
import './Login.scss';
import { Link, useHistory } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import Validator, { email, min, required } from '../services/Validator';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { authenticate, updateTmpUser } from '../store/actions/auth.actions';
import { useLocation } from 'react-router-dom';
import { isolateError, isolateSuccess } from '../utils/api';
import Loading from '../components/Loading';
import useSentData from '../services/useSentData';
import { useAlert } from 'react-alert';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import googleIcon from '../styles/images/google-icon.svg';

function LoginFormRaw({
  validator,
  authenticate,
  authError,
  authLoading,
  updateTmpUser,
}) {
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const reactivateRequest = useSentData();
  const checkLoggingInRequest = useSentData();
  let location = useLocation();
  const history = useHistory();
  const alert = useAlert();

  let { from } = location.state || { from: null };

  useEffect(() => {
    if (authError) {
      const isolatedError = isolateError(authError);
      let reactivateButton;
      if (isolatedError === 'User account is disabled.') {
        reactivateButton = (
          <a
            href=""
            className="reactivate-button"
            onClick={e => {
              e.preventDefault();
              reactivateRequest
                .send('/api/reactivate-request', {
                  email: data.email,
                })
                .then(data => {
                  if (!data.isAxiosError) {
                    alert.success(
                      isolateSuccess(data, 'Please check your email.')
                    );
                  } else {
                    alert.error(isolateError(reactivateRequest.error));
                  }
                });
            }}
          >
            Reactivate
          </a>
        );
        alert.error(
          <div>
            {isolatedError}&nbsp;{reactivateButton}
          </div>,
          {
            timeout: null,
          }
        );
      } else {
        alert.error(isolatedError);
      }
    }
  }, [authError]);
  useEffect(() => {
    if (from === 'activate') {
      const type = location.state.type || 'success';
      alert[type](
        location.state.message || 'Your account successfully activated.'
      );
    } else if (from === 'confirm-change-email') {
      alert.success(
        location.state.message ||
          'Your email successfully changed, please login.'
      );
    }
  }, [from]);

  const change = (name, val) => {
    const newData = {
      ...data,
      [name]: val,
    };
    setData(newData);
    setErrors(validator.validateAll(newData));
  };

  const onSubmitLoginForm = async e => {
    e.preventDefault();
    if (validator.isValid()) {
      reactivateRequest.reset();
      try {
        const resCheckLoggingIn = await checkLoggingInRequest.send(
          '/api/check-already-login',
          {
            email: data.email,
            password: data.password,
          }
        );
        const exists = !!resCheckLoggingIn?.exists;
        if (exists) {
          updateTmpUser({
            email: data.email,
            password: data.password,
          });
          return history.push('/one-session');
        } else {
          authenticate(data);
        }
      } catch (e) {
        alert.error(isolateError(e));
      }
    }
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logoIconPurple}
            alt="Bramework"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              className="font-medium text-purple-600 hover:text-purple-500"
              to="/register"
            >
              {/* start your 7-day free trial */}
              Sign up with your email
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmitLoginForm}>
              {(reactivateRequest.loading ||
                authLoading ||
                checkLoggingInRequest.loading) && <Loading />}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={e => change('email', e.target.value)}
                    autoComplete="email"
                    aria-invalid={errors.email}
                    aria-describedby="email-error"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={data.password}
                    onChange={e => change('password', e.target.value)}
                    aria-invalid={errors.password}
                    aria-describedby="password-error"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.password && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-error"
                    >
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-medium text-purple-600 hover:text-purple-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  id="submit"
                  disabled={!errors.email || !errors.password}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    !errors.email || !errors.password
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                >
                  Sign in
                </button>
              </div>
            </form>

            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium border border-gray-300  bg-white text-gray-700 hover:bg-gray-50 cursor-pointer"
                >
                  <img
                    className="mr-4 w-5 h-5"
                    src={googleIcon}
                    alt="Google icon"
                    aria-hidden="true"
                  />
                  Continue with Google
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    authLoading: state.auth.loading,
    authError: state.auth.error,
  };
}

const LoginForm = connect(mapStateToProps, {
  authenticate: authenticate,
  updateTmpUser: updateTmpUser,
})(LoginFormRaw);

export default function Login() {
  const validator = Validator({
    email: [required, email],
    password: [required, min(8)],
  });

  return <LoginForm validator={validator} />;
}
