import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';

import Loading from '../components/Loading';
import TitleItem from './NewPost/TitleItem';
import Tip from '../components/Tip';
import QualityConfirmationModal from './NewPost/QualityConfirmationModal';

import backButton from '../styles/images/back_button.svg';
import { track } from '../services/track';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import './NewPost.scss';
import outlineGeneratorImage from '../styles/images/outline-generator.png';
import { sortListByDate } from '../utils/common';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';

function NoData() {
  return <div className="no-data">No data available at this time.</div>;
}

function Titles({
  data,
  search,
  generatedTitles,
  updateTitles,
  getSelectedTitle,
  user,
  projectId = '',
  postId = '',
  submitPost,
  updateTitlesWithoutAppending = () => {},
}) {
  const [titles, setTitles] = React.useState();
  const request = useSentData();
  const alert = useAlert();

  function showMore() {
    track('Title generator', { Clicked: 'show more' });
    request
      .send(`/api/generate-title-generator`, {
        phrase: search,
        variations: 4,
      })
      .then(response => {
        if (!response.isAxiosError) {
          // setTitles(response.content);
          const newTitles = [...generatedTitles, ...response.content];
          sortListByDate(newTitles, 'created_at');
          setTitles(newTitles);
          updateTitles(response.content);
        } else {
          alert.error(isolateError(response));
        }
      });
  }

  const updateTitlesAndSubmit = async updatedTitles => {
    if (!!postId) {
      const url = `/api/projects/${projectId}/posts/${postId}/title-generator`;
      const method = 'put';
      const updatedData = { ...data, titles: updatedTitles };
      return request
        .send(url, updatedData, method)
        .then(response => {
          if (!response.isAxiosError) {
            setTitles(updatedTitles);
            updateTitlesWithoutAppending(updatedTitles);
          } else {
            alert.error(isolateError(response));
          }
        })
        .catch(err => alert.error(err.message));
    } else {
      setTitles(updatedTitles);
      updateTitlesWithoutAppending(updatedTitles);
    }
  };

  const updateTitleLikeStatus = (generation_id, status) => {
    const data = [...titles];
    const title = data.find(item => item.generation_id === generation_id);
    if (!title) {
      return;
    }
    if (!title.like_status) {
      title.like_status = [{ status }];
    } else {
      title.like_status[0].status = status;
    }
    setTitles(data);
  };

  const onDeleteTitle = (generation_id, index) => {
    return () => {
      const url = `/api/delete-title`;
      const method = 'post';
      const updatedData = { generation_id };
      return request
        .send(url, updatedData, method)
        .then(response => {
          if (!response.isAxiosError) {
            const newTitles = [...titles];
            newTitles.splice(index, 1);
            setTitles(newTitles);
          } else {
            alert.error(isolateError(response));
          }
        })
        .catch(err => alert.error(err.message));
    };
  };

  useEffect(() => {
    setTitles(generatedTitles);
  }, [generatedTitles]);

  return (
    <div className="keywords-component">
      <div className="questions main-card">
        <div>
          {titles && titles.length ? (
            <div>
              <div className="questions-wrapper">
                {titles.map((title, i) => (
                  <TitleItem
                    allowDelete={true}
                    title={title}
                    key={i}
                    getSelectedTitle={getSelectedTitle}
                    user={user}
                    updateTitleLikeStatus={updateTitleLikeStatus}
                    onDelete={onDeleteTitle(title.generation_id)}
                  />
                ))}
              </div>
              {(user.is_owner || user.is_manager || user.is_editor) && (
                <div className="button-wrapper flex justify-center">
                  <button
                    // style={{ width: '155px' }}
                    className="w-200 inline-flex justify-center px-2 py-2 mr-10 md:px-6 md:py-2 border border-purple-600 text-base font-weight-bolder rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    disabled={(!titles && titles.length) || request.loading}
                    onClick={() => {
                      submitPost();
                      track('Title generator', { Clicked: 'save' });
                    }}
                  >
                    Save Titles
                  </button>
                  <button
                    onClick={showMore}
                    className="w-200 inline-flex justify-center items-center px-3 py-2 border border-transparent leading-4 font-weight-bolder rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green"
                  >
                    {request.loading && (
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="mr-2 animate-spin"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                      </svg>
                    )}
                    Show More
                  </button>
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

function Generator({ user }) {
  const request = useSentData();
  const history = useHistory();
  const { id, project_id } = useParams();
  const [selectedTitle, setSelectedTitle] = React.useState('');
  const [data, setData] = React.useState({
    phrase: '',
    analysis_data: {},
    category: 2,
  });
  const [originKey, setOriginKey] = React.useState('');
  const [buttonTitle, setButtonTitle] = useState('Generate');
  const [loadingType, setLoadingType] = useState('');
  const [isQualityModalVisible, showQualityModal] = useState(false);
  const [analysisDataLoaded, setAnalysisDataLoaded] = useState(false);
  const alert = useAlert();
  const isTitlesNotEmpty = data.titles && data.titles.length;
  const isAnalysisDataNotEmpty = !!Object.keys(data.analysis_data).length;

  useEffect(() => {
    if (id) {
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setData(data);
          } else {
            alert.error(isolateError(data));
          }
        });
    }
    // track('Entered title generator');
  }, []);

  useEffect(() => {
    if (analysisDataLoaded) {
      handleShowQualityModal();
    }
  }, [analysisDataLoaded]);

  function closeQualityModal() {
    setAnalysisDataLoaded(false);
    showQualityModal(false);
  }

  function submitRequest(e, val) {
    if (val) {
      if (!originKey) setOriginKey(data.phrase);
      if (originKey === val) setOriginKey('');
      setData({ ...data, phrase: val });
    } else {
      val = data.phrase;
    }

    track('Title generator', { Clicked: 'generate' });

    if (e) {
      e.preventDefault();
    }

    if (!val) return;
    setLoadingType('generator');
    request
      .send(`/api/generate-title-generator`, {
        phrase: val,
        variations: 4,
      })
      .then(response => {
        if (!response.isAxiosError) {
          setData({ ...data, titles: response.content, phrase: val });
        } else {
          alert.error(isolateError(response));
        }
      });
  }

  function getSelectedTitle(title) {
    setSelectedTitle(title);
    getKeywordData();
  }

  function getKeywordData() {
    setLoadingType('keyword');
    if (isAnalysisDataNotEmpty) {
      handleShowQualityModal();
    } else {
      request
        .send('/api/semrush', { keyword_phrase: data.phrase, database: 'us' })
        .then(res => {
          if (!res.isAxiosError) {
            setData({ ...data, analysis_data: res });
            setAnalysisDataLoaded(true);
          } else {
            alert.error(isolateError(res));
          }
        });
    }
  }

  function submitPost() {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/title-generator`;
      method = 'put';
    }
    setLoadingType('save');
    request.send(url, data, method).then(() => {
      history.push(`/project/${project_id}/titles`);
    });
  }

  function handleShowQualityModal() {
    showQualityModal(true);
  }

  const displayMessageLoading = () => {
    switch (loadingType) {
      case 'save':
        return 'We’re saving your titles.';
      case 'generator':
        return 'We’re generating your titles.';
      case 'keyword':
        return 'We’re analyzing your keyword.';
    }
  };

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="new-post-component -mx-8 -my-8">
        {isQualityModalVisible ? (
          <QualityConfirmationModal
            id={id}
            project_id={project_id}
            data={data}
            opened={isQualityModalVisible}
            close={closeQualityModal}
            selectedItem={selectedTitle}
          />
        ) : (
          ''
        )}
        <div>
          {/* <button className="clear-button" onClick={history.goBack}>
            <img src={backButton} alt="" />
          </button> */}
          <div
            className={classNames('inner', {
              'not-empty': isTitlesNotEmpty,
            })}
          >
            <h1 className="relative">
              {/* <img
              className="outline-generator-icon"
              src={outlineGeneratorImage}
              alt="outline generator"
            /> */}
              Title Generator
              {/* <Tip text="Having a catchy title for your blog post will increase your click through rate which means more traffic." /> */}
            </h1>
            <h2>
              {id
                ? ''
                : 'Enter your topic or keyword & we’ll take care of the rest.'}
            </h2>
            <div>
              <form
                onSubmit={submitRequest}
                className="mt-6 sm:flex sm:items-center"
              >
                {' '}
                <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    className="focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-md border-gray-300 rounded-md h-12"
                    placeholder="Enter a topic or keyword phrase that is 3 to 5 words (e.g. how start a blog)"
                    value={data.phrase}
                    disabled={
                      !user.is_owner && !user.is_manager && !user.is_editor
                    }
                    onChange={e =>
                      setData({ ...data, phrase: e.target.value.toLowerCase() })
                    }
                    data-hj-allow
                  />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                  <button
                    id="submit-keyword"
                    disabled={
                      request.loading ||
                      (!user.is_owner && !user.is_manager && !user.is_editor)
                    }
                    // className="w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 md:text-xl"
                    className={classNames(
                      !user.is_owner && !user.is_manager && !user.is_editor
                        ? 'cursor-default bg-gray-300'
                        : 'cursor-pointer bg-purple-600 hover:bg-purple-700 focus:ring-purple-500',
                      'w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-xl'
                    )}
                  >
                    {buttonTitle}
                  </button>
                </div>
              </form>
            </div>
            {request.loading && (
              <div className="loading-wrapper">
                <Loading />
                <span>
                  {displayMessageLoading()} This can take up to 1 minute.
                </span>
              </div>
            )}
          </div>
        </div>
        {isTitlesNotEmpty && !request.loading ? (
          <>
            <ReactTooltip
              place={window.innerWidth < 768 ? 'left' : 'top'}
              class="tooltip-for-templates"
              type="light"
              effect="solid"
              html={true}
              border={false}
            />
            <Titles
              data={data}
              search={data.phrase}
              generatedTitles={data.titles}
              originKey={originKey}
              projectId={project_id}
              postId={id}
              getSelectedTitle={getSelectedTitle}
              user={user}
              submitPost={submitPost}
              updateTitles={titles => {
                const newTitles = [...data.titles, ...titles];
                data.titles = newTitles;
                setData({ ...data });
              }}
              updateTitlesWithoutAppending={titles => {
                data.titles = titles;
                setData(data);
              }}
              setSearch={(e, val) => {
                submitRequest(e, val);
              }}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(Generator);
