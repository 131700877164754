import axios from 'axios';
import store from '../store';
import * as actions from '../store/actions/auth.actions';
import * as access from '../store/actions/access.actions';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST;
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(actions.authLogout());
    }
    if (error.response && error.response.status === 403) {
      store.dispatch(access.forceChoosePlan());
    }
    return Promise.reject(error);
  }
);

export default axios;
