import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';

import UpdatedContentPopup from './post-builder-controls/updated-content-popup';

import { setSelectionBoundingClientRect } from '../store/actions/post_builder.actions';
import './PostBuilderEditor.scss';
import {
  getSentencesFromHtml,
  magicSpacerFormat,
} from '../utils/PostBuilderUtils';

const PostBuilderEditor = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const isPanelCollapsed = useSelector(state => state.panel.isPanelCollapsed);
  const valueRef = useRef();
  const hash = Math.random()
    .toString(36)
    .substring(2, 15);

  function handleOnDblclick({ target }, e) {
    if (target.nodeName === 'IMG') {
      props.showImageMetaModal(true, target);
    }
  }

  useEffect(() => {
    if (!!ref) {
      dispatch(setSelectionBoundingClientRect(null));
    }
  }, [isPanelCollapsed, ref]);

  return (
    <div id={'editorArea_' + hash}>
      <Editor
        ref={ref}
        onInit={(evt, editor) => props.editorLoaded()}
        apiKey="iyq96e2a6c242qzwnbx4tsj0pvx4kv334kouqlcu0ztwlevq"
        value={''}
        inline={false}
        // disabled={editorLoading}
        init={{
          selector: `#editor_${hash}`,
          toolbar_sticky: true,
          // toolbar_sticky_offset: 0.01,
          placeholder: 'Start writing here...',
          // init_instance_callback: function(inst) {
          //
          // },
          // paste_preprocess: function(pl, o) {
          //   // Content string containing the HTML from the clipboard
          //   const parser = new DOMParser();
          //   const doc = parser.parseFromString(o.content, 'text/html');
          //   console.log('===> Doc: ', doc);
          //   const img = doc.getElementsByTagName('img');

          //   for (let index = 0; index < img.length; index++) {
          //     if (img[index].src.includes(';base64')) {
          //       console.log('-------image-------', img);
          //       img[index].remove();
          //     }
          //   }

          //   return doc.body.innerHTML;
          // },
          paste_retain_style_properties: 'all',
          paste_webkit_styles: 'all',
          paste_postprocess: function(plugin, args) {
            const img = args.node.getElementsByTagName('img');
            for (let index = 0; index < img.length; index++) {
              if (img[index].src.includes(';base64')) {
                img[index].remove();
              }
            }
          },
          init_instance_callback: function(editor) {
            editor.shortcuts.add('ctrl+p', 'AI write a paragraph', function() {
              const selection = editor.selection.getContent({
                format: 'text',
              });

              if (selection.length > 19 && selection.length < 301) {
                props.generateText(editor, 'write-paragraph', selection);
              }
            });
            editor.shortcuts.add(
              'ctrl+g',
              'Tell the AI what text to generate',
              function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });

                if (selection.length > 19) {
                  props.generateText(editor, 'ai-command', selection);
                }
              }
            );
            editor.shortcuts.add('ctrl+l', 'AI generate listicle', function() {
              const selection = editor.selection.getContent({
                format: 'text',
              });
              if (selection.length > 19 && selection.length < 301) {
                props.generateText(editor, 'generate-list', selection);
              }
            });
            editor.shortcuts.add('ctrl+s', 'AI generate steps', function() {
              const selection = editor.selection.getContent({
                format: 'text',
              });
              if (selection.length > 19 && selection.length < 301) {
                props.generateText(editor, 'generate-steps', selection);
              }
            });
            editor.shortcuts.add(
              'ctrl+r',
              'Rewrite without changing meaning',
              function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });
                if (selection.length > 29 && selection.length < 4001) {
                  props.generateText(editor, 'rewrite-text', selection);
                }
              }
            );
            editor.shortcuts.add('ctrl+m', 'Reduce to main idea', function() {
              const selection = editor.selection.getContent({
                format: 'text',
              });
              if (selection.length > 29 && selection.length < 4001) {
                props.generateText(editor, 'summarize-text', selection);
              }
            });
            editor.shortcuts.add('ctrl+q', 'Write more text', function() {
              props.generateText(editor, 'write-more');
            });
            editor.shortcuts.add('ctrl+y', 'Magic spacer', function() {
              const selection = editor.selection.getContent({ format: 'html' });
              const sentences = selection
                ? getSentencesFromHtml(selection)
                : [];
              if (sentences.length >= 4) {
                editor.selection.setContent(magicSpacerFormat(selection));
              }
            });
          },
          max_width: 900,
          // paste_as_text: true,
          menubar: false,
          statusbar: false,
          plugins: [
            'paste quickbars lists link emoticons selectall searchreplace autoresize wordcount',
          ],
          content_style: `html { margin: 0; height: 100%} body { margin: 3% 15% 0% 15%; height: 100%; font-size: 16px; margin-bottom: 100px}`,
          toolbar:
            'undo redo | formatselect | fontselect | fontsizeselect | addImage | bullist numlist | ' +
            'alignleft aligncenter alignright | bold italic underline strikethrough blockquote | ' +
            'forecolor backcolor | link unlink | emoticons removeformat selectall | searchreplace',
          quickbars_selection_toolbar: false,
          quickbars_insert_toolbar: false,
          browser_spellcheck: true,
          contextmenu: 'link cut copy paste | transitions',
          block_formats:
            'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6',
          fontsize_formats:
            '8px 9px 10px 11px 12px 14px 16px 18px 20px 24px 32px 36px 48px 72px',
          branding: false,
          setup: editor => {
            editor.ui.registry.addButton('addImage', {
              // text: 'Add Image',
              icon: 'image',
              tooltip: 'Upload or select suggested images',
              onAction: function() {
                // activeTextArea = editor;
                props.showLibraryModal(true);
              },
            });
            editor.ui.registry.addButton('writeParagraph', {
              text: 'Paragraph',
              icon: 'line',
              tooltip: 'AI write a paragraph - Ctrl + P',
              onAction: function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });

                if (selection.length > 9 && selection.length < 101) {
                  props.generateText(editor, 'write-paragraph', selection);
                }
              },
            });
            editor.ui.registry.addButton('generateList', {
              text: 'Listicle',
              icon: 'checklist',
              tooltip: 'AI generate listicle - Ctrl + L',
              onAction: function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });
                if (selection.length > 19 && selection.length < 301) {
                  props.generateText(editor, 'generate-list', selection);
                }
              },
            });
            editor.ui.registry.addButton('generateSteps', {
              text: 'Steps',
              icon: 'spell-check',
              tooltip: 'AI generate steps - Ctrl + S',
              onAction: function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });
                if (selection.length > 19 && selection.length < 301) {
                  props.generateText(editor, 'generate-steps', selection);
                }
              },
            });
            editor.ui.registry.addButton('rewriteText', {
              text: 'Rewrite',
              icon: 'rotate-right',
              tooltip: 'Rewrite without changing meaning - Ctrl + R',
              onAction: function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });
                if (selection.length > 29 && selection.length < 4001) {
                  props.generateText(editor, 'rewrite-text', selection);
                }
              },
            });
            editor.ui.registry.addButton('summarizeText', {
              text: 'Summarize',
              icon: 'vertical-align',
              tooltip: 'Reduce to main idea - Ctrl + M',
              onAction: function() {
                const selection = editor.selection.getContent({
                  format: 'text',
                });
                if (selection.length > 29 && selection.length < 4001) {
                  props.generateText(editor, 'summarize-text', selection);
                }
              },
            });
            // editor.ui.registry.addButton('plagiarismCheck', {
            //   text: 'Plagiarism',
            //   icon: 'checkmark',
            //   tooltip: 'Check text for plagiarism',
            //   onAction: function() {
            //     const selection = editor.selection.getContent({
            //       format: 'text',
            //     });
            //     if (selection.length > 401 && selection.length < 2401) {
            //       props.topOptionBarHandle('plagiarismCheck');
            //     }
            //   },
            // });
            editor.ui.registry.addNestedMenuItem('transitions', {
              icon: 'comment-add',
              text: 'Transition words',
              getSubmenuItems: () => [
                {
                  type: 'nestedmenuitem',
                  text: 'Addition',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'Additionally',
                      onAction: () => editor?.insertContent('Additionally'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Coupled with',
                      onAction: () => editor?.insertContent('Coupled with'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Equally important',
                      onAction: () =>
                        editor?.insertContent('Equally important'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Furthermore',
                      onAction: () => editor?.insertContent('Furthermore'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In addition',
                      onAction: () => editor?.insertContent('In addition'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Likewise',
                      onAction: () => editor?.insertContent('Likewise'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Moreover',
                      onAction: () => editor?.insertContent('Moreover'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Uniquely',
                      onAction: () => editor?.insertContent('Uniquely'),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: 'Oppose',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'As much as',
                      onAction: () => editor?.insertContent('As much as'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Above all',
                      onAction: () => editor?.insertContent('Above all'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Conversely',
                      onAction: () => editor?.insertContent('Conversely'),
                    },
                    {
                      type: 'menuitem',
                      text: 'On the contrary',
                      onAction: () => editor?.insertContent('On the contrary'),
                    },
                    {
                      type: 'menuitem',
                      text: 'On the other hand',
                      onAction: () =>
                        editor?.insertContent('On the other hand'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Despite the fact',
                      onAction: () => editor?.insertContent('Despite the fact'),
                    },
                    {
                      type: 'menuitem',
                      text: 'While',
                      onAction: () => editor?.insertContent('While'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Unlike',
                      onAction: () => editor?.insertContent('Unlike'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Notwithstanding',
                      onAction: () => editor?.insertContent('Notwithstanding'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Nevertheless',
                      onAction: () => editor?.insertContent('Nevertheless'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Nonetheless',
                      onAction: () => editor?.insertContent('Nonetheless'),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: 'Result',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'Accordingly',
                      onAction: () => editor?.insertContent('Accordingly'),
                    },
                    {
                      type: 'menuitem',
                      text: 'As a result',
                      onAction: () => editor?.insertContent('As a result'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Then',
                      onAction: () => editor?.insertContent('Then'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Because the',
                      onAction: () => editor?.insertContent('Because the'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Hence',
                      onAction: () => editor?.insertContent('Hence'),
                    },
                    {
                      type: 'menuitem',
                      text: 'So',
                      onAction: () => editor?.insertContent('So'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Under those circumstances',
                      onAction: () =>
                        editor?.insertContent('Under those circumstances'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Henceforth',
                      onAction: () => editor?.insertContent('Henceforth'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Consequently',
                      onAction: () => editor?.insertContent('Consequently'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Therefore',
                      onAction: () => editor?.insertContent('Therefore'),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: 'Conditional',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'As long as',
                      onAction: () => editor?.insertContent('As long as'),
                    },
                    {
                      type: 'menuitem',
                      text: 'If',
                      onAction: () => editor?.insertContent('If'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Since',
                      onAction: () => editor?.insertContent('Since'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Due to',
                      onAction: () => editor?.insertContent('Due to'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In the event of',
                      onAction: () => editor?.insertContent('In the event of'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Because of',
                      onAction: () => editor?.insertContent('Because of'),
                    },
                    {
                      type: 'menuitem',
                      text: 'I hope that',
                      onAction: () => editor?.insertContent('I hope that'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Provided that',
                      onAction: () => editor?.insertContent('Provided that'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Otherwise',
                      onAction: () => editor?.insertContent('Otherwise'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In case',
                      onAction: () => editor?.insertContent('In case'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Whenever',
                      onAction: () => editor?.insertContent('Whenever'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Unless',
                      onAction: () => editor?.insertContent('Unless'),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: 'Describing time',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'All of a sudden',
                      onAction: () => editor?.insertContent('All of a sudden'),
                    },
                    {
                      type: 'menuitem',
                      text: 'At the present time',
                      onAction: () =>
                        editor?.insertContent('At the present time'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Presently',
                      onAction: () => editor?.insertContent('Presently'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Now',
                      onAction: () => editor?.insertContent('Now'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Occasionally',
                      onAction: () => editor?.insertContent('Occasionally'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Once',
                      onAction: () => editor?.insertContent('Once'),
                    },
                    {
                      type: 'menuitem',
                      text: 'After',
                      onAction: () => editor?.insertContent('After'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In the meantime',
                      onAction: () => editor?.insertContent('In the meantime'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In a moment',
                      onAction: () => editor?.insertContent('In a moment'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Momentarily',
                      onAction: () => editor?.insertContent('Momentarily'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Every so often',
                      onAction: () => editor?.insertContent('Every so often'),
                    },
                  ],
                },
                {
                  type: 'nestedmenuitem',
                  text: 'Conclusion',
                  getSubmenuItems: () => [
                    {
                      type: 'menuitem',
                      text: 'In summary',
                      onAction: () => editor?.insertContent('In summary'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In conclusion',
                      onAction: () => editor?.insertContent('In conclusion'),
                    },
                    {
                      type: 'menuitem',
                      text: 'To conclude',
                      onAction: () => editor?.insertContent('To conclude'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In any event',
                      onAction: () => editor?.insertContent('In any event'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In either case',
                      onAction: () => editor?.insertContent('In either case'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Overall',
                      onAction: () => editor?.insertContent('Overall'),
                    },
                    {
                      type: 'menuitem',
                      text: 'Altogether',
                      onAction: () => editor?.insertContent('Altogether'),
                    },
                    {
                      type: 'menuitem',
                      text: 'In essence',
                      onAction: () => editor?.insertContent('In essence'),
                    },
                    {
                      type: 'menuitem',
                      text: 'To summarize',
                      onAction: () => editor?.insertContent('To summarize'),
                    },
                    {
                      type: 'menuitem',
                      text: 'To sum up',
                      onAction: () => editor?.insertContent('To sum up'),
                    },
                    {
                      type: 'menuitem',
                      text: 'To wrap up',
                      onAction: () => editor?.insertContent('To wrap up'),
                    },
                  ],
                },
              ],
            });
          },
        }}
        onEditorChange={props.handleEditorChange}
        onDblclick={handleOnDblclick}
        onKeyUp={props.handleOnKeyUp}
        onSelectionChange={(data, editor) => {
          const selectedNode = editor.selection.getBoundingClientRect();
          if (!!selectedNode) {
            let top = selectedNode.top;
            const bottom = selectedNode.bottom;
            const left = selectedNode.left;
            const right = selectedNode.right;
            if (valueRef.current?.top === top) {
              top = bottom - 50;
            } else if (valueRef.current?.bottom === bottom) {
              top = top - 50;
            } else {
              valueRef.current = { top, bottom };
            }
            dispatch(
              setSelectionBoundingClientRect({ top, bottom, left, right })
            );
          }
          props.handleSelectionChange(data, editor);
        }}
      />
      <UpdatedContentPopup editorRef={ref} />
    </div>
  );
});

export default PostBuilderEditor;
