import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import AccessDenied from '../pages/AccessDenied';
import { connect } from 'react-redux';
import { shownChoosePlan } from '../store/actions/access.actions';
import { fetchProjects } from '../store/actions/project.action';
import { fetchRoleList } from '../store/actions/roles.actions';
import { track } from '../services/track';
import store from '../store';
import { REJOIN_INVITE } from '../pages/RejoinTeam';

function PrivateRoute({
  isAuthenticated,
  projects,
  roles,
  projectError,
  projectErrorMsg,
  selectedProject,
  projectLoading,
  roleLoading,
  user,
  accessBy,
  component: Component,
  to,
  isFinished,
  path,
  forceChoosePlan,
  shownChoosePlan,
  fetchProjects,
  fetchRoleList,
  ...rest
}) {
  const user_role = !user
    ? 'undefined'
    : user.is_manager
    ? 'manager'
    : user.is_editor
    ? 'editor'
    : user.is_viewer
    ? 'viewer'
    : 'owner';
  return (
    <Route
      to={to}
      path={path}
      render={props => {
        if (!isAuthenticated) {
          return <Redirect to="/" />;
        } else if (sessionStorage.getItem(REJOIN_INVITE)) {
          return <Redirect to="/add-member" />;
        } else if (accessBy && !accessBy?.includes(user_role)) {
          return (
            <ErrorBoundary>
              <AccessDenied />
            </ErrorBoundary>
          );
        } else if (
          forceChoosePlan &&
          ![
            '/plans',
            '/appsumo-plan',
            '/bramework-ltd-plan',
            '/complete-checkout',
          ].includes(path) &&
          !path.includes('/onboarding') &&
          !path.includes('/dashboard') &&
          !path.includes('/generate-first-draft-pro') &&
          !path.includes('/blog-post-improver')
        ) {
          // Inserted logic for redirect based on app_sumo_subscription
          const redirectPath = user.app_sumo_subscription
            ? [
                'bramework_tier1',
                'bramework_tier2',
                'bramework_tier3',
                'bramework_tier4',
              ].includes(user.app_sumo_subscription.plan.app_sumo_plan)
              ? '/appsumo-plan'
              : user.app_sumo_subscription.plan.app_sumo_plan ===
                'bramework_ltd1'
              ? '/bramework-ltd-plan'
              : user.app_sumo_subscription.plan.app_sumo_plan === 'free_user'
              ? '/account'
              : '/plans'
            : '/plans';
          shownChoosePlan();
          track('Entered plans');
          return <Redirect to={redirectPath} />;
        } else if (!isFinished && path !== '/onboarding') {
          // track('Entered onboarding');
          return <Redirect to="/onboarding" />;
        } else if (projects.length === 0 && isAuthenticated) {
          // track('Fetching projects');
          if (!projectLoading && !projectError) {
            fetchProjects();
          }
          if (!roleLoading && roles.length === 0) {
            fetchRoleList();
          }
          return (
            <ErrorBoundary>
              <Component {...rest} />
            </ErrorBoundary>
          );
        } else {
          return (
            <ErrorBoundary>
              <Component {...rest} />
            </ErrorBoundary>
          );
        }
      }}
    />
  );
}

const mapStateToProps = state => {
  let isFinished;
  let forceChoosePlan;
  try {
    // isFinished = !!state.auth.user.company;
    isFinished = !!state.auth.user.is_intro;
    forceChoosePlan =
      isFinished &&
      state.auth?.user?.is_owner &&
      !state.auth?.user?.subscription &&
      !state.auth?.user?.app_sumo_subscription
        ? true
        : state.access.forceChoosePlan;
  } catch {
    isFinished = false;
    forceChoosePlan =
      state.auth?.user?.is_owner &&
      !state.auth?.user?.subscription &&
      !state.auth?.user?.app_sumo_subscription
        ? true
        : false;
  }
  const { isOnBoardingUser } = state.auth;
  if (isOnBoardingUser && forceChoosePlan) {
    sessionStorage.setItem('isOnBoardingUser', 'true');
  }
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    projects: state.project.projects,
    selectedProject: state.project.selectedProject,
    roles: state.role.roles,
    projectLoading: state.project.loading,
    projectError: state.project.error,
    projectErrorMsg: state.project.errorMsg,
    roleLoading: state.role.loading,
    isFinished,
    forceChoosePlan,
  };
};

export default connect(mapStateToProps, {
  shownChoosePlan,
  fetchProjects,
  fetchRoleList,
})(PrivateRoute);

function AuthenticationRoute({
  isAuthenticated,
  accessBy,
  component: Component,
  to,
  path,
  ...rest
}) {
  return (
    <Route
      to={to}
      path={path}
      render={props => {
        if (!isAuthenticated) {
          return <Redirect to="/" />;
        } else {
          return (
            <ErrorBoundary>
              <Component {...rest} />
            </ErrorBoundary>
          );
        }
      }}
    />
  );
}

const mapAuthStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export const Authentication = connect(
  mapAuthStateToProps,
  null
)(AuthenticationRoute);
