import { PostTypes } from '../reducers/post_builder.reducer';

export const addPostTitle = payload => {
  const create = payload => ({
    type: PostTypes.CREATE_TITLE,
    payload,
  });
  const reRender = status => ({
    type: PostTypes.RE_RENDER_EDITOR,
    payload: status,
  });
  return dispatch => {
    // dispatch(reRender(true));
    dispatch(create(payload));
    // setTimeout(() => dispatch(reRender(false)), 0);
  };
};

export const addPostId = payload => {
  const postId = payload => ({
    type: PostTypes.SET_ID,
    payload,
  });

  return dispatch => {
    dispatch(postId(payload));
  };
};

export const addEditorValue = (block, value, id) => ({
  type: PostTypes.ADD_EDITOR_VALUE,
  block,
  value,
  id,
});

export const clearEditorValue = block => ({
  type: PostTypes.CLEAR_EDITOR_VALUE,
  block,
});

export const addPostVersions = payload => {
  const versions = payload => ({
    type: PostTypes.SET_VERSIONS,
    payload,
  });

  return dispatch => {
    dispatch(versions(payload));
  };
};

export const addPostVersionIndex = payload => {
  const versionIndex = payload => ({
    type: PostTypes.SET_VERSION_INDEX,
    payload,
  });

  return dispatch => {
    dispatch(versionIndex(payload));
  };
};

export const addPostVersionId = payload => {
  const versionId = payload => ({
    type: PostTypes.SET_VERSION_ID,
    payload,
  });

  return dispatch => {
    dispatch(versionId(payload));
  };
};

export const addGeneratedPostTitles = payload => {
  const titles = payload => ({
    type: PostTypes.SET_TITLES,
    payload,
  });

  return dispatch => {
    dispatch(titles(payload));
  };
};

export const addRelatedKeywords = payload => {
  const keywords = payload => ({
    type: PostTypes.SET_RELATED_KEYWORDS,
    payload,
  });

  return dispatch => {
    dispatch(keywords(payload));
  };
};

export const addTopQuestions = payload => {
  const questions = payload => ({
    type: PostTypes.SET_TOP_QUESTIONS,
    payload,
  });

  return dispatch => {
    dispatch(questions(payload));
  };
};

export const addGeneratedPostOutlines = payload => {
  const outlines = payload => ({
    type: PostTypes.SET_OUTLINES,
    payload,
  });

  return dispatch => {
    dispatch(outlines(payload));
  };
};

export const addGeneratedParagraph = payload => {
  const paragraphs = payload => ({
    type: PostTypes.SET_PARAGRAPHS,
    payload,
  });

  return dispatch => {
    dispatch(paragraphs(payload));
  };
};

export const addGeneratedCommand = payload => {
  const commands = payload => ({
    type: PostTypes.SET_COMMANDS,
    payload,
  });

  return dispatch => {
    dispatch(commands(payload));
  };
};

export const addGeneratedRephrase = payload => {
  const rephrases = payload => ({
    type: PostTypes.SET_REPHRASE,
    payload,
  });

  return dispatch => {
    dispatch(rephrases(payload));
  };
};

export const addGeneratedSummarize = payload => {
  const summarize = payload => ({
    type: PostTypes.SET_SUMMARIZE,
    payload,
  });

  return dispatch => {
    dispatch(summarize(payload));
  };
};

export const addGeneratedSteps = payload => {
  const steps = payload => ({
    type: PostTypes.SET_STEPS,
    payload,
  });

  return dispatch => {
    dispatch(steps(payload));
  };
};

export const addGeneratedListicle = payload => {
  const listicles = payload => ({
    type: PostTypes.SET_LISTICLE,
    payload,
  });

  return dispatch => {
    dispatch(listicles(payload));
  };
};

export const addTone = payload => {
  const tone = payload => ({
    type: PostTypes.SET_TONE,
    payload,
  });

  return dispatch => {
    dispatch(tone(payload));
  };
};

export const addLanguage = payload => {
  const language = payload => ({
    type: PostTypes.SET_LANGUAGE,
    payload,
  });

  return dispatch => {
    dispatch(language(payload));
  };
};

export const addOutlineType = payload => {
  const outlineType = payload => ({
    type: PostTypes.SET_OUTLINE_TYPE,
    payload,
  });

  return dispatch => {
    dispatch(outlineType(payload));
  };
};

export const addSelectedKeywords = payload => {
  const keywords = payload => ({
    type: PostTypes.SET_SELECTED_KEYWORDS,
    payload,
  });

  return dispatch => {
    dispatch(keywords(payload));
  };
};

export const addSecondaryKeywords = payload => {
  const keywords = payload => ({
    type: PostTypes.SET_SECONDARY_KEYWORDS,
    payload,
  });

  return dispatch => {
    dispatch(keywords(payload));
  };
};

export const addNiche = payload => {
  const niche = payload => ({
    type: PostTypes.SET_NICHE,
    payload,
  });

  return dispatch => {
    dispatch(niche(payload));
  };
};

export const addInstructions = payload => {
  sessionStorage.setItem('postInstructions', payload);
  const instructions = payload => ({
    type: PostTypes.SET_INSTRUCTIONS,
    payload,
  });

  return dispatch => {
    dispatch(instructions(payload));
  };
};

export const loadInstructions = () => {
  const payload = sessionStorage.getItem('postInstructions');
  const instructions = payload => ({
    type: PostTypes.SET_INSTRUCTIONS,
    payload,
  });

  return dispatch => {
    dispatch(instructions(payload));
  };
};

export const toggleSeoAnalyzer = payload => {
  localStorage.setItem('seoAnalyzer', String(!payload));
  return {
    type: PostTypes.SET_SEO_ANALYZER,
    payload: !payload,
  };
};

export const loadSeoAnalyzer = () => {
  let seoAnalyzer = localStorage.getItem('seoAnalyzer');
  const defaultValue = 'true';
  if (!seoAnalyzer) {
    localStorage.setItem('seoAnalyzer', defaultValue);
    seoAnalyzer = defaultValue;
  }
  return {
    type: PostTypes.SET_SEO_ANALYZER,
    payload: seoAnalyzer === 'true',
  };
};

export const setStatus = payload => {
  return {
    type: PostTypes.SET_STATUS,
    payload,
  };
};
export const setSelectionBoundingClientRect = payload => {
  return {
    type: PostTypes.SET_SELECTED_TEXT_NODE,
    payload,
  };
};

export const setUpdatedContentPopup = payload => {
  return {
    type: PostTypes.SET_UPDATED_CONTENT_POPUP,
    payload,
  };
};

export const setWriteMoreButtonOn = payload => {
  return {
    type: PostTypes.SET_AI_WRITEMORE_BUTTON,
    payload,
  };
};

export const setAdditionalKeywordsButtonOn = payload => {
  localStorage.setItem(PostTypes.SET_ADDITIONAL_KEYWORDS_BUTTON, payload + '');
  return {
    type: PostTypes.SET_ADDITIONAL_KEYWORDS_BUTTON,
    payload,
  };
};

export const setInstructionsButton = payload => {
  localStorage.setItem(PostTypes.SET_INSTRUCTIONS_BUTTON, payload + '');
  return {
    type: PostTypes.SET_INSTRUCTIONS_BUTTON,
    payload,
  };
};

export const setCheckPlagiarismTriggered = payload => {
  return {
    type: PostTypes.SET_CHECK_PLAGIARISM_TRIGGERED,
    payload,
  };
};

export const setLength = payload => {
  const tone = payload => ({
    type: PostTypes.SET_LENGTH,
    payload,
  });

  return dispatch => {
    dispatch(tone(payload));
  };
};

export const setHighLightWords = payload => {
  return {
    type: PostTypes.SET_HIGHLIGHT_WORDS,
    payload,
  };
};

export const setAIWritingToolSelected = payload => {
  return {
    type: PostTypes.SET_AI_WRITING_TOOL_SELECTED,
    payload,
  };
};

export const setUseBrandVoice = payload => {
  return {
    type: PostTypes.SET_USE_BRAND_VOICE,
    payload,
  };
};
