import React from 'react';
import './LoadingMessage.scss';

const LoadingMessage = ({ message }) => {
  return (
    <div className="loading-message">
      <div className="loading-dots">
        <div className="loading-dot bg-gray-500"></div>
        <div className="loading-dot bg-gray-500"></div>
        <div className="loading-dot bg-gray-500"></div>
      </div>
      <p className="loading-text text-gray-700">{message}</p>
    </div>
  );
};

export default LoadingMessage;
