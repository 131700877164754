import React from 'react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import {
  loadConversations,
  setChatMessage,
} from '../../store/actions/chat.actions';
import { useHistory } from 'react-router-dom';
import { useDeleteChatConversation } from '../../utils/chatApi';

const AccordionItem = ({ title, content, activeItemId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { deleteChatConversation } = useDeleteChatConversation();

  const handleItemClick = (item, index) => {
    if (title === 'Favorite Prompts') {
      dispatch(setChatMessage(content[index]));
    } else if (title === 'Chat History') {
      history.push(`/chat/${item.id}`);
    } else if (title === 'Suggested Prompts') {
      dispatch(setChatMessage(content[index]));
    }
  };

  const handleDeleteChatItemClick = async selectedChatId => {
    try {
      await deleteChatConversation(selectedChatId);
      dispatch(loadConversations());
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  return (
    <Disclosure defaultOpen={title === 'Suggested Prompts'}>
      {({ open, close }) => (
        <>
          <Disclosure.Button className="flex justify-between items-center w-full text-sm text-gray-100 bg-purple-400 p-2 rounded-t-lg card-header px-2 mt-2">
            <b>{title}</b>
            {open ? (
              <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
            ) : (
              <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="text-sm text-gray-500 mt-2 h-50vh overflow-y-auto">
            {content.map((item, index) => (
              <div
                key={item.id || item}
                className={`content-item hover:bg-gray-500 text-white text-sm p-2 ${
                  title === 'Chat History' && item.id === activeItemId
                    ? 'active'
                    : ''
                }`}
                onClick={() => handleItemClick(item, index)}
              >
                {title === 'Chat History' ? (
                  <div className="content-item-inner">
                    {item.snippet}
                    <TrashIcon
                      aria-hidden="true"
                      className={`h-6 w-6 text-white delete-icon ${
                        item.id === activeItemId ? 'visible' : 'hidden'
                      }`}
                      onClick={() => handleDeleteChatItemClick(item.id)}
                    />
                  </div>
                ) : (
                  item
                )}
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AccordionItem;
