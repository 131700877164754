import LocationSelect, {
  LOCATIONS,
} from '../../components/analytics-dashboard/LocationSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDomainFromUrl, TONES } from '../../utils/common';
import useDataForSeoApi from '../../services/useDataForSeoApi';
import Loading from '../../components/Loading';
import tr from 'react-timeago/lib/language-strings/tr';
import Select from '../../components/form/FormSelect';
import { debounce } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
const SORT_TYPES = [
  {
    label: 'Google Search Volume',
    value: 'keyword_data.keyword_info.search_volume,desc',
  },
  {
    label: 'Ranking',
    value: 'ranked_serp_element.serp_item.rank_absolute,asc',
  },
  {
    label: 'Last Updated',
    value: 'ranked_serp_element.last_updated_time,desc',
  },
];
const FILTER_TYPES = [
  { label: 'Keyword', value: 'keyword' },
  { label: 'Page URL', value: 'url' },
];
const FILTER_SUB_TYPES = [
  { label: 'Include', value: 'like' },
  { label: 'Not Include', value: 'not_like' },
];
const orderByList = [
  'ranked_serp_element.serp_item.rank_absolute,asc',
  'keyword_data.keyword_info.search_volume,desc',
  'ranked_serp_element.last_updated_time,desc',
];
const NewKeywordsToTrack = () => {
  const projectUrl = useSelector(
    state =>
      state.project?.selectedProject?.project_url ||
      state.auth?.user?.company?.domain ||
      ''
  );
  const dataForSeoRequest = useDataForSeoApi();
  const [analyticData, setAnalyticData] = useState([]);
  const [sortType, setSortType] = useState(
    'keyword_data.keyword_info.search_volume,desc'
  );
  const [filterType, setFilterType] = useState('keyword');
  const [filterSubType, setFilterSubType] = useState('like');
  const [filterString, setFilterString] = useState('');
  const [location, setLocation] = useState({
    language_name: LOCATIONS[0].available_languages[0].language_name,
    location_code: LOCATIONS[0].location_code,
    location_name: LOCATIONS[0].location_name,
  });
  useEffect(() => {
    if (projectUrl) {
      getListKeywords();
    }
  }, [projectUrl, location, sortType, filterType, filterSubType, filterString]);
  const getListKeywords = async () => {
    const orderBy = orderByList.filter(item => item !== sortType);
    orderBy.unshift(sortType);
    let filters = [
      'ranked_serp_element.serp_item.rank_changes.is_new',
      '=',
      'true',
    ];
    if (filterString) {
      filters = [filters, 'and'];
      filters.push([
        filterType === 'keyword'
          ? 'keyword_data.keyword'
          : 'ranked_serp_element.serp_item.relative_url',
        filterSubType,
        `%${filterString}%`,
      ]);
    }
    const requestData = [
      {
        target: getDomainFromUrl(projectUrl),
        language_name: location.language_name,
        location_name: location.location_name,
        ignore_synonyms: true,
        item_types: ['organic', 'featured_snippet', 'local_pack'],
        historical_sep_mode: 'live',
        order_by: orderBy,
        filters,
        include_serp_info: true,
        load_rank_absolute: true,
        limit: 100,
      },
    ];
    const response = await dataForSeoRequest.send(
      '/dataforseo_labs/google/ranked_keywords/live',
      requestData
    );
    const task = response?.tasks ? response?.tasks[0] : null;
    if (!task) {
      return;
    }
    const result = task?.result ? task?.result[0] : null;
    if (!result || !result.items) {
      return;
    }
    const data = result.items.map(item => ({
      keyword: item?.keyword_data?.keyword || '',
      searchVolume: item?.keyword_data?.keyword_info?.search_volume || 0,
      ranking: item?.ranked_serp_element?.serp_item?.rank_absolute || 9999,
      url: item?.ranked_serp_element?.serp_item?.relative_url || '',
    }));
    setAnalyticData(data);
  };
  const debouncedSearch = useCallback(
    debounce(query => setFilterString(query), 500),
    []
  );
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl text-purple-700 font-bold">
          New keywords to track
        </h3>
        <LocationSelect location={location} onChangeLocation={setLocation} />
      </div>
      <div className="w-full mt-4">
        {
          <>
            <div className="flex w-full gap-4">
              <div className="w-1/3 bg-purple-200 p-4 rounded-md">
                <div className="text-left">Sort By</div>
                <Select
                  className="w-full md:w-2/3"
                  options={SORT_TYPES}
                  value={SORT_TYPES.find(item => item.value === sortType)}
                  onChange={({ value }) => {
                    setSortType(value);
                  }}
                />
              </div>
              <div className="w-1/3 bg-purple-200 p-4 rounded-md">
                <div className="text-left">Filter By</div>
                <div className="flex flex-col md:flex-row gap-2 items-center">
                  <Select
                    className="w-full md:w-36"
                    options={FILTER_TYPES}
                    value={FILTER_TYPES.find(item => item.value === filterType)}
                    onChange={({ value }) => {
                      setFilterType(value);
                    }}
                  />
                  <Select
                    className="w-full md:w-36"
                    options={FILTER_SUB_TYPES}
                    value={FILTER_SUB_TYPES.find(
                      item => item.value === filterSubType
                    )}
                    onChange={({ value }) => {
                      setFilterSubType(value);
                    }}
                  />
                  <input
                    placeholder="SEO"
                    style={{ height: '48px' }}
                    onChange={e => debouncedSearch(e.target.value)}
                    className="w-36 px-3 mt-2 h-12 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="h-100 overflow-auto mt-3">
              {dataForSeoRequest.loading ? (
                <div className="flex w-full h-full items-center justify-center">
                  <div>
                    <Loading />
                    <p>Loading analytic data...</p>
                  </div>
                </div>
              ) : (
                <table className="table-auto w-full">
                  <tbody>
                    {analyticData.map(item => (
                      <tr key={item.keyword} className="text-gray-600">
                        <td>
                          <div className="w-3 h-3 bg-purple-700 rounded-full mr-4"></div>
                        </td>
                        <td className="w-1/2 text-left p-2">{item.keyword}</td>
                        <td className="p-2 w-32">{item.searchVolume}</td>
                        <td className="p-2 w-32">#{item.ranking}</td>
                        <td className="text-left">
                          <div className="w-100 truncate">
                            <Tooltip title={item.url}>
                              <span>{item.url}</span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        }
      </div>
    </div>
  );
};
export default NewKeywordsToTrack;
