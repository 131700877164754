import React from 'react';
import notFound from '../styles/images/404.jpg';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import './NoMatch.scss';
import { Link } from 'react-router-dom';

const NoMatch = () => (
  // <div className="no-match-wrapper">
  //   <div className="row">
  //     <div className="col-sm-6 text-right">
  //       <h1>404</h1>
  //       <h3>Looks like you got lost</h3>
  //       <p>The page you are looking for doesn't exist or has been removed.</p>
  //       <Link to="/dashboard" className="btn btn-purple">
  //         Back home
  //       </Link>
  //     </div>
  //     <div className="col-sm-6 text-left">
  //       <img src={notFound} alt="" />
  //     </div>
  //   </div>
  // </div>
  <div className="flex flex-col items-center justify-center min-h-screen py-2">
    <img className="mx-auto h-12 w-auto" src={logoIconPurple} alt="Bramework" />
    <h3 className="mt-8 text-sm font-medium text-purple-600">404 ERROR</h3>
    <h1 className="mt-2 text-3xl font-extrabold">Looks like you got lost.</h1>
    <p className="mt-1 text-sm text-gray-500 text-center">
      The page you are looking for doesn't exist or has been removed.
    </p>
    <div className="mt-6">
      <Link
        to="/dashboard"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
      >
        Go back home
      </Link>
    </div>
  </div>
);

export default NoMatch;
