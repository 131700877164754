import React, { useState } from 'react';
import './ForgotPassword.scss';
import { Link } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import Validator, { email, required } from '../services/Validator';
import classNames from 'classnames';
import useSentData from '../services/useSentData';
import { isolateError, isolateSuccess } from '../utils/api';
import Loading from '../components/Loading';
import { useAlert } from 'react-alert';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';

function ForgotPasswordForm({ validator }) {
  const request = useSentData();
  const [data, setData] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({
    email: false,
  });
  const alert = useAlert();
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logoIconPurple}
            alt="Bramework"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              className="font-medium text-purple-600 hover:text-purple-500"
              to="/register"
            >
              {/* start your 7-day free trial */}
              Sign up with your email
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                if (!request.loading && validator.isValid()) {
                  request.send('/api/forgot-password', data).then(data => {
                    if (!data.isAxiosError) {
                      alert.success(
                        isolateSuccess(
                          data,
                          'Successfully sent! Please check your email.'
                        )
                      );
                    } else {
                      alert.error(isolateError(data));
                    }
                  });
                }
              }}
            >
              {request.loading && <Loading />}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={e => {
                      const email = e.target.value;
                      setData({ email });
                      setErrors({
                        email: !!validator.validate('email', { email }),
                      });
                    }}
                    autoComplete="email"
                    aria-invalid={errors.email}
                    aria-describedby="email-error"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link
                    to="/"
                    className="font-medium text-purple-600 hover:text-purple-500"
                  >
                    Back to sign in
                  </Link>
                </div>
              </div>

              <div>
                <button
                  id="submit"
                  disabled={!errors.email}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    errors.email ? 'cursor-pointer' : 'cursor-default'
                  }`}
                >
                  Send reset email
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default function ForgotPassword() {
  const validator = Validator({
    email: [required, email],
  });

  return <ForgotPasswordForm validator={validator} />;
}
