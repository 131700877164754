import React from 'react';
import classNames from 'classnames';
import './FormTextarea.scss';

export default function FormInput({
  id,
  type = 'text',
  label,
  value,
  className,
  onChange,
  onBlur,
  isValid,
  disabled,
  charCount,
  hideCharCount = false,
  textareaClassName = '',
  rows = 6,
  maxLength,
  placeHolder = '',
}) {
  return (
    <div className={classNames(className, 'form-textarea-wrapper')}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        name={id}
        type={type}
        value={value}
        onChange={e => onChange(e.target.value)}
        className={classNames(
          'form-input focus:ring-purple-500 focus:border-purple-500',
          {
            'has-value': !!value,
            'is-valid': isValid,
          },
          textareaClassName
        )}
        onBlur={onBlur}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeHolder}
        data-hj-allow
      />
      {!hideCharCount && (
        <span className={charCount > 400 ? 'char-count-limit' : 'char-count'}>
          {charCount}/400
        </span>
      )}
    </div>
  );
}
