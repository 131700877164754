import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { numberToThousands } from '../../utils/common';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const GATopRankPages = ({ topPagesData, isButtonDisabled }) => {
  let [selectedTab, setSelectedTab] = useState(0);

  const tabs = ['Today', 'Last 7 Days', 'Last 30 Days'];
  const tabDataKeys = [
    'TopPagesToday',
    'TopPagesLast7Days',
    'TopPagesLast30Days',
  ];

  return (
    <div className="w-full">
      <div className="flex items-center space-x-4 mb-2">
        <h2 className="text-lg font-semibold">Top Viewed Pages</h2>
        <button
          className={`rounded px-2 py-1 text-xs font-medium ${
            isButtonDisabled
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'shadow-sm ring-1 ring-inset bg-white text-purple-700 ring-purple-300 hover:bg-purple-50'
          }`}
          // onClick={isButtonDisabled ? null : reviewAnalytics}
          disabled={isButtonDisabled}
        >
          Insights
        </button>
      </div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex p-1 space-x-1 bg-purple-900/20 rounded-xl">
          {tabs.map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-purple-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-purple-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-purple-100 hover:bg-purple-500 hover:text-white'
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-3">
          {tabDataKeys.map((key, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white p-3 shadow',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-purple-400 ring-white ring-opacity-60'
              )}
            >
              <ul className="space-y-2 overflow-auto h-52">
                {topPagesData[key] &&
                  topPagesData[key].map((page, pageIdx) => (
                    <li key={pageIdx} className="flex justify-between">
                      <span>{page.pagePath}</span>
                      <span>{numberToThousands(page.pageViews)}</span>
                    </li>
                  ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default GATopRankPages;
