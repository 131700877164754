import { useState, useEffect } from 'react';
import Http from './http';

export default function(path, params) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setData(null);
    Http.get(path, {
      params,
    })
      .then(res => {
        setData(res.data);
        setError(null);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setData(null);
        setLoading(false);
      });
  }, [path, params]);

  return { data, error, loading };
}
