import cx from 'classnames';
import FormTextarea from '../../components/form/FormTextarea';
import LoadingIcon from '../../components/LoadingIcon';

const TextareaWithGenerate = ({
  value = '',
  loading = false,
  disabled = false,
  id = '',
  onChange = () => {},
  onGenerate = () => {},
  rows = 6,
  placeHolder = '',
}) => {
  return (
    <div className="w-full my-2 relative">
      <FormTextarea
        value={value}
        onChange={onChange}
        id={id}
        hideCharCount
        textareaClassName={'resize-none h-auto'}
        rows={rows}
        placeHolder={placeHolder}
      />
      <button
        className="absolute bottom-6 right-4 text-xs font-semibold rounded-lg bg-gray-100 border border-gray-200 shadow-sm px-4 py-1 hover:bg-gray-200 flex items-center text-gray-500 disabled:cursor-not-allowed"
        disabled={loading || disabled}
        onClick={() => onGenerate()}
      >
        {loading && <LoadingIcon />}
        <span>Generate</span>
      </button>
    </div>
  );
};

export default TextareaWithGenerate;
