const layoutActionTypes = {
  SHOW_GRID: 'SHOW_GRID',
  SHOW_LIST: 'SHOW_LIST',
};

const initialState = {
  isListView: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case layoutActionTypes.SHOW_GRID:
      return {
        isListView: false,
      };
    case layoutActionTypes.SHOW_LIST:
      return {
        isListView: true,
      };
    default:
      return state;
  }
};

export default layoutReducer;
export { layoutActionTypes };
