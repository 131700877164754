import React, { useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Moment from 'react-moment';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import RevokeInviteModal from './RevokeInviteModal';
import ResendInviteModal from './ResendInviteModal';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import defaultAvatar from '../../styles/images/default-avatar.svg';

function InviteMemberList({ invites, fetchInvitation }) {
  const [openRevokeInviteModal, setRevokeInviteModalOpen] = useState(false);
  const [openResendInviteModal, setOpenResendInviteModal] = useState(false);
  const [invite, setInvite] = useState(null);

  function renderTableData() {
    return invites.map((invite_obj, index) => {
      const { created_at, role_name, email } = invite_obj;
      return (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {email}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {role_name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Moment fromNow>{created_at}</Moment>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            {role_name !== 'Owner' && (
              <Menu as="div" className="static inline-block text-left">
                <div>
                  <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-16 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setInvite(invite_obj);
                              setOpenResendInviteModal(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Resend
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setInvite(invite_obj);
                              setRevokeInviteModalOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Revoke
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <RevokeInviteModal
        open={openRevokeInviteModal}
        invite={invite}
        setOpen={() => {
          fetchInvitation();
          setInvite(null);
          setRevokeInviteModalOpen(false);
        }}
      />
      <ResendInviteModal
        open={openResendInviteModal}
        invite={invite}
        setOpen={() => {
          fetchInvitation();
          setInvite(null);
          setOpenResendInviteModal(false);
        }}
      />
      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Invited
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderTableData()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteMemberList;
