import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Select from '../../components/form/FormSelect';
import { XIcon } from '@heroicons/react/outline';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';
import {
  getProjectPostStatuses,
  updatePostStatus,
} from '../../utils/post-status';

function UpdatePostStatusModal({
  projectId,
  postId,
  open,
  setOpen,
  currentStatus = '',
  onUpdated = () => {},
}) {
  const [postStatuses, setPostStatuses] = useState([]);
  const [selectStatus, setSelectStatus] = useState(null);

  const alert = useAlert();
  const request = useSentData();

  const options = useMemo(
    () =>
      postStatuses?.map(status => {
        return {
          value: status.id,
          label: status.status,
        };
      }),
    [postStatuses]
  );

  const submitNewStatus = useCallback(() => {
    updatePostStatus(request, projectId, postId, selectStatus.value)
      .then(res => {
        if (!res.isAxiosError) {
          alert.success('Successfully updated!');
          onUpdated();
        } else {
          alert.error(isolateError(res));
        }
      })
      .catch(err => {
        alert.error(err.message);
      });
  }, [request, projectId, postId, selectStatus]);

  useEffect(() => {
    let isCancelled = false;
    if (!!projectId && !isCancelled) {
      getProjectPostStatuses(request, projectId)
        .then(data => {
          const activeStatuses = data?.filter(stat => stat.is_active);
          setPostStatuses(activeStatuses);
        })
        .catch(err => {
          alert.error(err.message);
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [projectId]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (!!options.length && !!currentStatus) {
        const currentValue = options.find(opt => opt.value === currentStatus);
        setSelectStatus(currentValue);
      } else {
        setSelectStatus(null);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [currentStatus, options]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {`Select the post status`}
                  </Dialog.Title>
                  <div className="mt-6">
                    <Select
                      options={options}
                      className="w-full"
                      label={'Select status'}
                      value={selectStatus}
                      onChange={v => {
                        return setSelectStatus(v);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => submitNewStatus()}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UpdatePostStatusModal;
