// ContentSection.js
import React, { useState } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import CollapsibleSection from './CollapsibleSection';
import CopyableSection from './CopyableSection';

const ContentSection = ({ title, subsections }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAll = async () => {
    try {
      const styledContent = `${title}\n\n${subsections
        .map(
          subsection =>
            `${subsection.title}\n\n${subsection.content.replace(
              /<[^>]+>/g,
              ''
            )}`
        )
        .join('\n\n')}`;
      await navigator.clipboard.writeText(styledContent);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <CollapsibleSection title={title}>
      <div className="mb-4">
        <button
          onClick={handleCopyAll}
          className="flex items-center text-sm text-purple-600 hover:text-purple-800"
        >
          <ClipboardCopyIcon className="w-4 h-4 mr-1" />
          {isCopied ? 'All Copied!' : 'Copy All'}
        </button>
      </div>
      {subsections.map((subsection, index) => (
        <CopyableSection
          key={index}
          title={subsection.title}
          content={subsection.content}
        />
      ))}
    </CollapsibleSection>
  );
};

export default ContentSection;
