import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './PostTemplate.scss';
import Select from '../components/form/FormSelect';
import Tip from '../components/Tip';
import ReactTooltip from 'react-tooltip';
import backButton from '../styles/images/back_button.svg';
import { track } from '../services/track';
import useSentData from '../services/useSentData';
import useLoadedData from '../services/useLoadedData';
import { listToOption } from '../services/api';
import Validator, { required, url } from '../services/Validator';
import FormInput from '../components/form/FormInput';
import { isolateError } from '../utils/api';
import { useAlert } from 'react-alert';

export default function PostTemplate() {
  const lists = useLoadedData('/api/lists');
  let industries = [];
  if (lists.data) {
    industries = listToOption(lists.data.post_industry_list);
  }

  const [industry, setIndustry] = useState();
  const [needShowErrors, showErrors] = useState(false);
  const [domain, setDomain] = useState('');
  const [serverErrors, setServerErrors] = useState({});
  const request = useSentData();
  const history = useHistory();
  const { state } = useLocation();
  const alert = useAlert();
  const types = [
    {
      id: 'How-to',
      tip: 'Inform your reader step-by-step on how to do something.',
    },
    {
      id: 'List',
      tip:
        'Provides to your audience a list with a specific number of reasons, types or ways of doing something.',
    },
    {
      id: 'What',
      tip: 'Answer and explain a specific concept in detail to your audience.',
    },
    {
      id: 'Why',
      tip:
        'Convience your audience by making successful arguments with hard evidence.',
    },
  ];

  const validator = Validator({
    industry: [required],
    // domain: [required, url],
  });
  let validation = validator.validateAll({ industry });

  function save(type) {
    if (validator.isValid()) {
      request
        .send(
          `/api/posts/${state}`,
          { type, industry: industry.value, domain: domain },
          'patch'
        )
        .then(res => {
          if (!res.isAxiosError) {
            history.push({
              pathname: '/post-builder',
              state: { id: state },
            });
            track('Post template type', { Type: type });
          } else {
            showErrors(true);
            setServerErrors(res.response.data);
            alert.error(isolateError(res));
          }
        });
    } else {
      showErrors(true);
    }
  }

  return (
    <div className="post-template-component">
      <ReactTooltip
        place={'top'}
        class="tooltip-for-templates"
        type="light"
        effect="solid"
        html={true}
        border={false}
      />
      <button
        className="clear-button back-button"
        onClick={() => {
          history.push('/dashboard');
        }}
      >
        <img src={backButton} alt="" />
      </button>
      <h1>What type of blog post would you like to create?</h1>
      <div className="inputs-wrapper">
        <Tip text="The website this post will be posted. SEO analyzer will check for internal and outbound links. (Optional)" />
        <FormInput
          id="domain"
          className="account-input"
          label="Blog url: (https://www.mywebsite.com)"
          value={domain}
          onChange={v => {
            if (serverErrors.domain) {
              let newServerErrors = { ...serverErrors };
              delete newServerErrors.domain;
              setServerErrors(newServerErrors);
            }
            return setDomain(v);
          }}
          isValid={
            // needShowErrors && (!validation.domain || serverErrors.domain)
            needShowErrors && serverErrors.domain
          }
        />
        <Select
          id="industry"
          label="Post category *"
          className="account-select extra-space"
          options={industries}
          value={industry}
          onChange={v => {
            if (serverErrors.industry) {
              let newServerErrors = { ...serverErrors };
              delete newServerErrors.industry;
              setServerErrors(newServerErrors);
            }
            return setIndustry(v);
          }}
          isValid={
            needShowErrors && (!validation.industry || serverErrors.industry)
          }
        />
        <div className="request-link" />
        <p>
          <a
            href="https://www.bramework.com/industry-request/"
            target="_blank"
            style={{ fontSize: '13px' }}
          >
            Don't see a category? Click here to request.
          </a>
        </p>
      </div>
      <div className="items-list">
        <ReactTooltip
          place="top"
          class="tooltip-for-templates"
          type="light"
          effect="solid"
          border={false}
        />
        {types.map((t, i) => (
          <button onClick={() => save(t.id)} className="clear-button" key={i}>
            <div className="item" data-tip={t.tip}>
              {t.id}
            </div>
          </button>
        ))}
      </div>
      <div className="controls-wrapper">
        {/* <Link
          to={`/post-builder/${state}`}
          className="clear-button plain-button skip-button"
        >
          Skip
        </Link> */}
        <button
          onClick={() => save()}
          className="clear-button plain-button skip-button"
        >
          Custom
        </button>
      </div>
    </div>
  );
}
