import React, { useRef } from 'react';
import { Disclosure } from '@headlessui/react';

function Accordion({ title, children, defaultOpen }) {
  const accordionRef = useRef();

  return (
    <div className="w-full mt-4">
      <div className="w-full mx-auto bg-white rounded-2xl">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open, close }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full text-md font-medium text-left rounded-lg">
                <b>{title}</b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-black`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="text-sm text-gray-500 mt-2">
                <button
                  className="hidden"
                  ref={accordionRef}
                  onClick={() => close()}
                />
                {children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default Accordion;
