import React from 'react';

export default function ImagePreview({ state }) {
  return (
    <div>
      {state.files &&
        state.files.map((file, i) => (
          <div key={i}>
            <img
              className="image-wrapper"
              src={file && file.url ? file.url : '/'}
              alt={file && file.alt_text ? file.alt_text : ''}
            />
            {/* <img src={URL.createObjectURL(file)} alt="" /> */}
          </div>
        ))}
    </div>
  );
}
