import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { processHTML } from '../../utils/processHTML';
import { Link } from 'react-router-dom';
import './QualityConfirmationModal.scss';
import closemark from '../../styles/images/close-outline.svg';
import checkmark from '../../styles/images/checkmark-outline.svg';
import novaRobotImgSrc from '../../styles/images/generate-first-draft/nova-robot.png';
import stopSignImgSrc from '../../styles/images/generate-first-draft/stop-sign.png';
import {addSecondaryKeywords, setAIWritingToolSelected} from '../../store/actions/post_builder.actions';
import {ADDITION_KEYWORDS_KEY} from '../../components/KeywordsTagInput';
import {useDispatch} from 'react-redux';
import da from 'react-timeago/lib/language-strings/da';
import LoadingIcon from '../../components/LoadingIcon';

export default function QualitytConfirmationModal({
  id,
  project_id,
  data,
  opened,
  close,
  selectedItem,
}) {
  const request = useSentData();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  function updateData() {
    if (data.category === 2 || data.category === 3) {
      let body = [
        {
          type: 'Title',
          state: {
            text: data.category === 2 ? selectedItem : '',
          },
        },
        {
          type: 'HeroImage',
          state: {
            file: null,
          },
        },
        {
          type: 'Body',
          state: {
            id: data.category === 2 ? null : selectedItem.id,
            text: data.category === 2 ? '' : processHTML(selectedItem.html),
          },
        },
      ];
      return { ...data, body: body };
    } else {
      return data;
    }
  }

  function createPost() {
    if (id) {
      if (data.category === 2 || data.category === 3) {
        let url = project_id
          ? `/api/projects/${project_id}/posts`
          : '/api/posts';

        if (data.category === 2) {
          url += `/${id}/title-generator`;
        } else {
          url += `/${id}/outline-generator`;
        }
        const newData = updateData();
        request.send(url, newData, 'put').then(() => goToPostBuilder(data));
      } else {
        request.send(`/api/projects/${project_id}/posts/${id}/keyword-analyzer`, data, 'put')
        .then(() => goToPostBuilder(data));
      }
    } else {
      const newData = updateData();
      request
        .send(
          project_id ? `/api/projects/${project_id}/posts` : '/api/posts',
          newData,
          'post'
        )
        .then(resData => {
          if (!resData.isAxiosError) {
            goToPostBuilder(resData);
          } else {
            alert.error(isolateError(resData));
          }
        });
    }
  }
  
  const goToPostBuilder = async (data) => {
    let res = await request.send('/api/generate-keywords', {
      phrase: data.phrase,
    }).catch();
    const keywords = (res?.content || '');
    await request.send(
          `/api/projects/${project_id}/posts/${data.id}`,
          { topics: keywords ? keywords.split(',') : null},
          'put'
     )
    dispatch(setAIWritingToolSelected('title'));
    history.push({
      pathname: `/project/${project_id}/post-builder/${data.id}`,
      state: {id : data.id},
    });
    if (keywords) {
      const additionKeywords = keywords.split(',').map((item,index) => ({id : new Date().getTime() + index, text : item, selected : false}))
      sessionStorage.setItem(`${ADDITION_KEYWORDS_KEY}_${data.id}`, JSON.stringify(additionKeywords));
      dispatch(addSecondaryKeywords(keywords.split(',')));
    }
  }

  const Content = ({
    renderImg = () => null,
    img,
    motiText,
    qualityText,
    suggestionText,
    renderSuggestionText = () => null,
    suggMore,
    warning,
    cancelBtnLabel,
    func,
  }) => (
    <div className={request.loading && 'animate-pulse'}>
      {img ? <img src={img} alt="checkmark" /> : renderImg()}
      <h1>{motiText}</h1>
      <h3>{qualityText}</h3>
      {suggestionText ? <p>{suggestionText}</p> : renderSuggestionText()}
      {warning && <p className="warning">{warning}</p>}
      {suggMore && <p className="mt-4">{suggMore}</p>}
      <div className="buttons-wrapper">
        <button
          className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={close}
          disabled={request.loading}
        >
          {cancelBtnLabel || 'Cancel'}
        </button>
        <button
          disabled={request.loading}
          className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
          onClick={createPost}
        >
          {request.loading && <LoadingIcon />}
          <span>Continue</span>
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      className="quality-confirmation-modal"
      isOpen={opened}
      ariaHideApp={false}
    >
      <div>
        {data &&
        data.analysis_data &&
        (data.analysis_data.top_questions.length > 0 ||
          data.analysis_data.top_related.length > 0) ? (
          <Content
            renderImg={() => (
              <div className="pl-14">
                <img src={novaRobotImgSrc} alt="Good" />
              </div>
            )}
            motiText="Great!"
            qualityText="Based on this topic, the output Bramework's AI generates should be good quality."
          />
        ) : (
          <div className={request.loading && 'animate-pulse'}>
            <div>
              <img src={stopSignImgSrc} alt="Bad" className="w-48" />
            </div>
            <h1>Wait!</h1>
            <h3>
              Bramework's AI may not be able to suggest keywords or generate
              high quality content for this topic.
            </h3>
            <p>
              Try shortening your topic phrase, or find a keyword that gets more
              searches using the
              <br />
              <Link
                className="text-purple-600"
                to={{
                  pathname: `/project/${project_id}/keyword-analyzer`,
                  state: 'analyzer',
                }}
                onClick={close}
              >
                Keyword Analyzer.
              </Link>
            </p>
            <div className="buttons-wrapper">
              <button
                className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={close}
                disabled={request.loading}
              >
                Back
              </button>
              <button
                disabled={request.loading}
                className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                onClick={createPost}
              >
                {request.loading && <LoadingIcon />}
                <span>Continue</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
