import useSentData from '../services/useSentData';

export const useSendMessage = () => {
  const { error, loading, success, send, reset } = useSentData();

  const sendMessage = async (messages, settings) => {
    try {
      const response = await send('/api/chat', {
        messages: messages.map(message => {
          const { created_at, ...rest } = message;
          return rest;
        }),
        ...settings,
      });
      return response.message;
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Error sending message:', error);
      return null;
    }
  };

  return { error, loading, success, sendMessage, reset };
};

export const useSaveChatMessage = () => {
  const { error, loading, success, send, reset } = useSentData();

  const saveChatMessage = async (chatId, message) => {
    try {
      const response = await send('/api/save-message', {
        chatId,
        message,
      });
      return response;
    } catch (error) {
      console.error('Error saving message:', error);
      alert('Error saving message:', error);
      return null;
    }
  };

  return { error, loading, success, saveChatMessage, reset };
};

export const useGetChatMessages = () => {
  const { error, loading, success, send, reset } = useSentData();

  const getChatMessages = async chatId => {
    try {
      const response = await send('/api/get-messages', { chatId });
      return response;
    } catch (error) {
      console.error('Error retrieving messages:', error);
      alert('Error retrieving messages:', error);
      return [];
    }
  };

  return { error, loading, success, getChatMessages, reset };
};

export const useUpdateChatMessage = () => {
  const { error, loading, success, send, reset } = useSentData();

  const updateChatMessage = async (chatId, messageId, updatedContent) => {
    try {
      const response = await send('/api/update-message', {
        chatId,
        messageId,
        updatedContent,
      });
      return response;
    } catch (error) {
      console.error('Error updating messages:', error);
      alert('Error updating messages:', error);
      return [];
    }
  };

  return { error, loading, success, updateChatMessage, reset };
};

export const useGetChatConversations = () => {
  const { error, loading, success, send, reset } = useSentData();

  const getChatConversations = async () => {
    try {
      const response = await send('/api/get-conversations');
      return response;
    } catch (error) {
      console.error('Error retrieving conversations:', error);
      alert('Error retrieving conversations:', error);
      return [];
    }
  };

  return { error, loading, success, getChatConversations, reset };
};

export const useDeleteChatConversation = () => {
  const { error, loading, success, send, reset } = useSentData();

  const deleteChatConversation = async chatId => {
    try {
      const response = await send('/api/delete-conversation', { chatId });
      return response;
    } catch (error) {
      console.error('Error deleting conversation:', error);
      alert('Error deleting conversation:', error);
      throw error;
    }
  };

  return { error, loading, success, deleteChatConversation, reset };
};

export const useSaveOrUpdateConversation = () => {
  const { error, loading, success, send, reset } = useSentData();

  const saveOrUpdateConversation = async (chatId, lastMessage) => {
    try {
      const response = await send('/api/save-conversation', {
        chatId,
        lastMessage,
      });
      return response;
    } catch (error) {
      console.error('Error saving conversation:', error);
      alert('Error saving conversation:', error);
      throw error;
    }
  };

  return { error, loading, success, saveOrUpdateConversation, reset };
};

export const useSaveFavoritePrompt = () => {
  const { error, loading, success, send, reset } = useSentData();

  const saveFavoritePrompt = async (chatId, prompt) => {
    try {
      const response = await send('/api/save-prompt', {
        chatId,
        prompt,
      });
      return response;
    } catch (error) {
      console.error('Error saving prompt:', error);
      alert('Error saving prompt:', error);
      throw error;
    }
  };

  return { error, loading, success, saveFavoritePrompt, reset };
};

export const useGetFavoritePrompts = () => {
  const { error, loading, success, send, reset } = useSentData();

  const getFavoritePrompts = async () => {
    try {
      const response = await send('/api/get-prompts');
      return response;
    } catch (error) {
      console.error('Error retrieving prompts:', error);
      alert('Error retrieving prompts:', error);
      return [];
    }
  };

  return { error, loading, success, getFavoritePrompts, reset };
};

export const useDeleteFavoritePrompt = () => {
  const { error, loading, success, send, reset } = useSentData();

  const deleteFavoritePrompt = async promptId => {
    try {
      const response = await send('/api/delete-prompt', {
        promptId,
      });
      return response;
    } catch (error) {
      console.error('Error deleting prompt:', error);
      alert('Error deleting prompt:', error);
      throw error;
    }
  };

  return { error, loading, success, deleteFavoritePrompt, reset };
};
