import React, { useEffect, useState, Fragment } from 'react';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import ProjectsList from './NewProject/ProjectsList';
import { connect } from 'react-redux';
import { selectProject, fetchProjects } from '../store/actions/project.action';

function Projects(props) {
  useEffect(() => {
    props.fetchProjects();
  }, []);

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="All Projects" category="project" />}
    >
      {props.loading ? (
        <Loading />
      ) : props.projects.length ? (
        <ProjectsList
          projects={props.projects}
          selectProject={props.selectProject}
          selectedProject={props.selectedProject}
          fetchProjects={props.fetchProjects}
        />
      ) : (
        <div className="posts-list">
          <h1 className="empty-message">You have no projects created.</h1>
        </div>
      )}
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    projects: state.project.projects,
    selectedProject: state.project.selectedProject,
    loading: state.project.loading,
  };
}

export default connect(mapStateToProps, { selectProject, fetchProjects })(
  Projects
);
