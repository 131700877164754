import React from 'react';
import ListParagraphPreview from './ListParagraphPreview';

export default function BodyPreview({ state }) {
  return (
    <div>
      <ListParagraphPreview state={state.text} />
    </div>
  );
}
