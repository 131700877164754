import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import './PostCreate.scss';
import Tip from '../components/Tip';
import ReactTooltip from 'react-tooltip';
import backButton from '../styles/images/back_button.svg';
import { track } from '../services/track';
import useSentData from '../services/useSentData';
import FormInput from '../components/form/FormInput';
import { isolateError } from '../utils/api';
import { useAlert } from 'react-alert';
import { fade } from '@material-ui/core';
import { setAIWritingToolSelected } from '../store/actions/post_builder.actions';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import {ADDITION_KEYWORDS_KEY} from '../components/KeywordsTagInput';

export default function PostCreate() {
  const { project_id } = useParams();
  const [keywords, setKeywords] = useState();
  const [keyword, setKeyword] = useState('');
  const [phrase, setPhrase] = useState();
  const [body, setBody] = useState();
  const [topics, setTopics] = useState([]);
  const request = useSentData();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const alert = useAlert();
  const id = state.id;

  useEffect(() => {
    if (id) {
      request.send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
      .then(data => {
        if (!data.isAxiosError) {
          if (data) {
            setBody(data.body);
          }
          if (data.phrase) {
            setPhrase(data.phrase);
            getAdditionalKeywords(data.phrase);
          }
        } else {
          alert.error(isolateError(data));
        }
      });
    }
  }, []);

  function save(topics) {
    if (topics.length <= 3) {
      request
        .send(
          `/api/projects/${project_id}/posts/${id}`,
          { topics: topics },
          'put'
        )
        .then(res => {
          if (!res.isAxiosError) {
            dispatch(setAIWritingToolSelected('title'));
            history.push({
              pathname: `/project/${project_id}/post-builder/${id}`,
              state: { id: id },
            });
            const additionKeywords = keywords.map(item => ({id : new Date().getTime(), text : item, selected : topics.includes(item)}))
            sessionStorage.setItem(ADDITION_KEYWORDS_KEY, JSON.stringify(additionKeywords));
          } else {
            alert.error(isolateError(res));
          }
        });
    } else {
      const removeTopics = topics.length - 3;
      alert.error(
        'Select up to 3 keywords. Please remove ' + removeTopics + '.'
      );
    }
  }

  const onAddKeyword = e => {
    e.preventDefault();
    if (keyword !== '') {
      let newKeywords = [...keywords];
      let newTopics = [...topics];

      if (!newKeywords.includes(keyword)) {
        newKeywords.push(keyword);
        setKeywords(newKeywords);
        newTopics.push(keyword);
        setTopics(newTopics);
      } else {
        newTopics.push(keyword);
        setTopics(newTopics);
      }

      setKeyword('');
    }
  };
  
  const getAdditionalKeywords = async (phrase) => {
    try {
      const res = await request.send('/api/generate-keywords', {
        phrase
      });
      setKeywords((res.content || '').split(','));
    } catch (e) {
    }
  }

  const LoadingIcon = () => (
    <svg
      width="16"
      height="16"
      fill="currentColor"
      className="mr-2 animate-spin"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
    </svg>
  );

  return (
    <div className="post-create-component">
      <ReactTooltip
        place={'top'}
        class="tooltip-for-templates"
        type="light"
        effect="solid"
        html={true}
        border={false}
      />
      <button
        className="clear-button back-button"
        onClick={() => {
          history.push(`/project/${project_id}/dashboard`);
        }}
      >
        <img src={backButton} alt="" />
      </button>
      <h1>Select the keywords to include in your post.</h1>
      <div className="keyword-wrapper">
        {body && body[0].state.text ? (
          <div>
            {' '}
            <div className="keyword-label">
              Title&nbsp;&nbsp;
              <Tip text="A great title helps readers find your posts, and make them want to click through to read more." />
            </div>
            <div className="keyword-element">{body[0].state.text}</div>
          </div>
        ) : (
          <div>
            {' '}
            <div className="keyword-label">
              Primary keyword&nbsp;&nbsp;
              <Tip text="The main keyword for which you want to rank." />
            </div>
            <div className="keyword-element">{phrase}</div>
          </div>
        )}
      </div>
      <div className="box">
        <div className="block">
          <div className="block-title">
            Select additional keywords
            {topics.length > 0 ? ` - ${topics.length}` : ''}&nbsp;&nbsp;
            <Tip text="Select the keywords that you'd like to include in your post.<br/><br><b>Tip:</b> Select up to 5 keywords." />
          </div>
          <div className="block-box">
            {request.loading ?
              (
                <div className='flex mx-auto items-center text-sm'>
                  <div>Loading additional keywords...</div>
                </div>
              ) 
              : (keywords && keywords.length ? (
              <div className="keyword-ideas__wrapper">
                {keywords.map((keyword, i) => (
                  <div
                    key={i}
                    className={classNames('block-item', {
                      'block-item-selected': topics.includes(keyword),
                    })}
                    onClick={() => {
                      if (topics.includes(keyword)) {
                        let newTopics = [...topics];
                        const index = newTopics.indexOf(keyword);
                        if (index > -1) {
                          newTopics.splice(index, 1);
                          setTopics(newTopics);
                        }
                      } else {
                        let newTopics = [...topics];
                        newTopics.push(keyword);
                        setTopics(newTopics);
                      }
                    }}
                  >
                    {keyword}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                No additional keyword suggestions available for the primary
                keyword you entered.
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6 px-4 w-full justify-center">
          <div className=" font-bold text-sm">
            Add additional keyword&nbsp;&nbsp;
            <Tip text="If you don't see any additional keyword suggestions that you like, you can add them here.<br/><br><b>Tip:</b> Add one at a time. Be sure to add keywords that are related to your primary keyword." />
          </div>
          <div className="mt-0 sm:flex sm:items-center">
            <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
              <FormInput
                id="domain"
                className="account-input"
                value={keyword}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    onAddKeyword(e);
                  }
                }}
                onChange={e => {
                  setKeyword(e.toLowerCase());
                }}
              />
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
              <button
                className="w-full mt-2 text-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 md:text-lg"
                onClick={onAddKeyword}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        {/* <div className="inputs-wrapper">
          <div className="w-3/4 px-4">
            <div className="input-title">
              Add additional keyword&nbsp;&nbsp;
              <Tip text="If you don't see a suggested keyword you'd like, you can add it here.<br/><br><b>Tip:</b> Add one at a time. Be sure to add keywords that are related to your primary keyword." />
            </div>
            <FormInput
              id="domain"
              className="account-input"
              value={keyword}
              onChange={e => {
                setKeyword(e.toLowerCase());
              }}
            />
          </div>
          <div className="right-button">
            <button
              className="btn btn-outline-purple"
              onClick={e => {
                e.preventDefault();
                if (keyword !== '') {
                  let newKeywords = [...keywords];
                  let newTopics = [...topics];

                  if (!newKeywords.includes(keyword)) {
                    newKeywords.push(keyword);
                    setKeywords(newKeywords);
                    newTopics.push(keyword);
                    setTopics(newTopics);
                  } else {
                    newTopics.push(keyword);
                    setTopics(newTopics);
                  }

                  setKeyword('');
                }
              }}
            >
              Add
            </button>
          </div>
        </div> */}
      </div>
      <div className="controls-wrapper">
        <button
          onClick={() => save(topics)}
          type="button"
          className="w-full justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 md:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={request.loading}
        >
          <div className='flex items-center justify-center'>
            {
              request.loading && <LoadingIcon />
            }
            Create
          </div>
        </button>
      </div>
    </div>
  );
}
