import React, { useState, useImperativeHandle, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TimeAgo from 'react-timeago';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import './PostBuilderFooter.scss';
import { numberToThousands } from '../utils/common';
import { isolateError } from '../utils/api';
import useSentData from '../services/useSentData';
import { useAlert } from 'react-alert';
import $ from 'jquery';

const Footer = React.forwardRef((props, ref) => {
  const [autoSaveDate, setAutoSaveDate] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [isGenerating, setGenerating] = useState(false);
  const [remainingWord, setRemainingWord] = useState({});
  const request = useSentData();
  const alert = useAlert();

  useImperativeHandle(ref, () => ({
    updateProgressState: (state, data) => {
      switch (state) {
        case 'autoSaveDate':
          setAutoSaveDate(data);
          break;
        case 'generating':
          setGenerating(data);
          break;
        case 'wordCount':
          setWordCount(data);
          break;
        default:
          break;
      }
    },
  }));

  useEffect(() => {
    getRemainingWord();
    $('#dropdown-word-count').on('show.bs.dropdown', function() {
      getRemainingWord();
    });
  }, []);

  const getRemainingWord = async () => {
    const res = await request.send(`/api/remaining-words`, null, 'get');
    if (!res.isAxiosError) {
      const {
        available_words,
        additional_words,
        plan_ai_word_limit,
        addon_ai_word_limit,
        plagiarism_word_limit,
        additional_plagiarism_words,
        plan_plagiarism_word_limit,
        addon_plagiarism_word_limit,
        used_credit_ai_words,
        credit_ai_words,
      } = res;
      const remainAIWords = available_words + additional_words;
      const totalAIWords = plan_ai_word_limit + addon_ai_word_limit;
      const remainPlagiarismWords =
        plagiarism_word_limit + additional_plagiarism_words;
      const totalPlagiarismWords =
        plan_plagiarism_word_limit + addon_plagiarism_word_limit;
      setRemainingWord({
        remainAIWords,
        totalAIWords,
        remainPlagiarismWords,
        totalPlagiarismWords,
        credit_ai_words,
        used_credit_ai_words,
      });
    } else {
      alert.error(isolateError(res));
    }
  };

  return (
    <div
      className={classNames(
        (props.sidePanelValue === sidePanelTypes.DEFAULT ||
          props.sidePanelValue === sidePanelTypes.CHAT ||
          props.sidePanelValue === sidePanelTypes.FACT_CHECKER) &&
          !props.isPanelCollapsed
          ? 'footer-side-panel-open'
          : props.sidePanelValue === sidePanelTypes.PLAGIARISM &&
            !props.isPanelCollapsed
          ? 'footer-plagiarism-panel-open'
          : 'footer-side-panel-close',
        'fixed h-10 bottom-0 border-t border-gray-300 bg-white flex justify-between items-center pl-4 text-xs md:text-sm pr-24 builder-footer z-20'
      )}
    >
      <div className="text-gray-500">
        {autoSaveDate && !isGenerating ? (
          <>
            Autosaved&nbsp;
            <TimeAgo date={autoSaveDate} minPeriod={15} />
          </>
        ) : (
          ''
        )}
      </div>
      <div>
        <div className="dropdown" id="dropdown-word-count">
          <button
            className="text-gray-500 dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Word Count&nbsp;{wordCount} &nbsp;
          </button>
          <div className="dropdown-menu">
            <div className="dropdown-item">
              {remainingWord.totalAIWords >= 1000000 ? (
                <p>
                  {' '}
                  AI words remaining: <b>Unlimited</b>
                </p>
              ) : (
                <p>
                  {' '}
                  AI words remaining:
                  {props.user.has_trial_subscription ? (
                    <b>
                      {' '}
                      {numberToThousands(remainingWord.remainAIWords)} /{' '}
                      {numberToThousands(3000)}{' '}
                    </b>
                  ) : (
                    <b>
                      {' '}
                      {numberToThousands(remainingWord.remainAIWords)} /{' '}
                      {numberToThousands(remainingWord.totalAIWords)}{' '}
                    </b>
                  )}
                </p>
              )}
              {remainingWord.totalAIWords < 1000000 && (
                <p className="mt-1">
                  {' '}
                  AI words credit used:{' '}
                  <b>
                    {numberToThousands(remainingWord.used_credit_ai_words)} /{' '}
                    {numberToThousands(remainingWord.credit_ai_words)}{' '}
                  </b>
                </p>
              )}
              <p className="mt-1">
                {' '}
                Plagiarism words remaining:{' '}
                {props.user.has_trial_subscription ? (
                  'N/A'
                ) : (
                  <b>
                    {numberToThousands(remainingWord.remainPlagiarismWords)} /{' '}
                    {numberToThousands(remainingWord.totalPlagiarismWords)}{' '}
                  </b>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

// export default Footer;

function mapStateToProps(state) {
  return {
    sidePanelValue: state.panel.sidePanelValue,
    isPanelCollapsed: state.panel.isPanelCollapsed,
    user: state.auth.user || {},
  };
}

export default connect(
  mapStateToProps,
  { panelCollapse: panelCollapse },
  null,
  { forwardRef: true }
)(Footer);
