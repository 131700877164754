import React, { useEffect } from 'react';
import Header from '../components/Header';
import Moment from 'react-moment';
import classNames from 'classnames';
import './PublishHistory.scss';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../components/Wrapper';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import { publishHistoryRequest } from '../store/actions/platform.actions';
import { Link } from 'react-router-dom';

const PublishHistoryItem = ({ data }) => {
  return (
    <>
      <tr className="hover:bg-gray-50">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {data.post_title}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <Moment date={data.edited_at} format="MMM DD, YYYY" />
        </td>
        <td
          className={classNames(
            data.status === 'Published' ? 'text-bw-green' : 'text-red-600',
            'px-6 py-4 whitespace-nowrap text-sm font-medium'
          )}
        >
          {data.status}
        </td>
      </tr>
    </>
  );
};

const PublishHistory = () => {
  const posts = useSelector(state => state.platform.publishHistory);
  const filterPublishHistory = useSelector(
    state => state.platform.filterPublishHistory
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (filterPublishHistory.id) {
      return dispatch(publishHistoryRequest(filterPublishHistory.id));
    }
    !posts.length && dispatch(publishHistoryRequest());
  }, []);

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="Publish History" />}
    >
      <div className="posts-list">
        {posts.length ? (
          <div className="">
            <h3 className="text-lg mt-8 leading-6 font-medium text-gray-900">
              {filterPublishHistory.id && filterPublishHistory.url}
            </h3>
            <div className="flex flex-col">
              <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {posts.map(item => (
                          <PublishHistoryItem key={item.id} data={item} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Link
                to="/integrations"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </Link>
            </div>
          </div>
        ) : (
          <h1 className="empty-message">You have not published any posts.</h1>
        )}
      </div>
    </NewWrapper>
  );
};

export default PublishHistory;
