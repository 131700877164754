import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import RootReducer from './reducers';
import { logger } from 'redux-logger';

let middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, ReduxThunk, logger];
} else {
  middleware = [...middleware, ReduxThunk];
}
const store = createStore(RootReducer, applyMiddleware(...middleware));

export default store;
