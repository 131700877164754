import React from 'react';
import classNames from 'classnames';
import backButton from '../styles/images/back_button.svg';

export default function AlertTemplate({ style, options, message, close }) {
  return (
    <div className={classNames('message', options.type, 'z-20')} style={style}>
      <span>{message}</span>
      <button className="clear-button" onClick={close}>
        <img src={backButton} alt="" />
      </button>
    </div>
  );
}
