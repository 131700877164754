import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  updatePlatform,
  publishHistory,
} from '../../store/actions/platform.actions';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import wordPress from '../../styles/images/wordpress.svg';
import editImg from '../../styles/images/edit.svg';
import deleteImg from '../../styles/images/delete-trash-gray.svg';
import listImg from '../../styles/images/list.svg';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import Moment from 'react-moment';

const PlatformItem = ({ data, deleteItem, setOpenModal }) => {
  const dispatch = useDispatch();

  // console.log('data..............', data);

  return (
    <tr className="hover:bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="inline-flex items-center">
          <img src={wordPress} alt="wp" className="-ml-0.5 h-4 w-4 mr-2" />
          {data.url}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <Moment date={data.created_at} format="MMM. D, YYYY" />
      </td>
      {/* <th>
        <div className="wrapper_btn">
          <div className="btn_item">
            <img
              src={editImg}
              alt="edit"
              onClick={() => {
                dispatch(updatePlatform('wordpress', data));
                setOpenModal(true);
              }}
            />
          </div>
          <div className="btn_item" onClick={deleteItem}>
            <img src={deleteImg} alt="delete" />
          </div>
          <div className="btn_item">
            <Link to={`/publish-history`}>
              <img
                src={listImg}
                alt="list"
                onClick={() => dispatch(publishHistory(data))}
              />
            </Link>
          </div>
        </div>
      </th> */}
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Menu as="div" className="static inline-block text-left">
          <div>
            <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-10 origin-top-right absolute right-16 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {data.username && data.username != null && !data.token && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          dispatch(updatePlatform('wordpress', data));
                          setOpenModal(true);
                        }}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block w-full text-left px-4 py-2 text-sm'
                        )}
                      >
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                )}

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/publish-history`}
                      onClick={() => dispatch(publishHistory(data))}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      History
                    </Link>
                    // <button
                    //   onClick={() => dispatch(publishHistory(data))}
                    //   className={classNames(
                    //     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    //     'block w-full text-left px-4 py-2 text-sm'
                    //   )}
                    // >
                    //   History
                    // </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={deleteItem}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </td>
    </tr>
  );
};

export default PlatformItem;
