import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundary';

function UnAuthRoute({
  isAuthenticated,
  component: Component,
  path,
  exact,
  ...rest
}) {
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        if (!isAuthenticated || ['/connect-wordpress'].includes(path)) {
          return (
            <ErrorBoundary>
              <Component {...rest} />
            </ErrorBoundary>
          );
        } else {
          return <Redirect to="/dashboard" />;
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(UnAuthRoute);
