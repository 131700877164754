import { Tooltip } from '@material-ui/core';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';

const InputWithGenerate = ({
  loading = false,
  disabled = false,
  onGenerate = () => {},
  ...props
}) => {
  return (
    <Tooltip
      title={!!props.value ? <p className="text-base">{props.value}</p> : ''}
      placement="left"
    >
      <div className="w-full my-2 relative">
        <FormInput {...props} inputClassName="rounded-lg" />
        <button
          className="absolute bottom-2 right-4 text-xs font-semibold rounded-lg bg-gray-100 border border-gray-200 shadow-sm px-4 py-1 hover:bg-gray-200 flex items-center text-gray-500 disabled:cursor-not-allowed"
          disabled={loading || disabled}
          onClick={() => onGenerate()}
        >
          {loading && <LoadingIcon />}
          <span>Generate</span>
        </button>
      </div>
    </Tooltip>
  );
};

export default InputWithGenerate;
