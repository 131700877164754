import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import hljs from 'highlight.js';

export const MessageItem = ({ message, id }) => {
  const openInNewTab = props => {
    return (
      <a
        className="text-purple-500 hover:text-purple-700"
        {...props}
        target="_blank"
        rel="noopener noreferrer"
      />
    );
  };

  const renderTable = props => {
    return (
      <div className="w-full overflow-auto">
        <table
          className="min-w-full rounded-lg divide-y divide-gray-200 bg-gray-100 my-4"
          {...props}
        ></table>
      </div>
    );
  };

  const renderTableCell = props => {
    return (
      <td
        className="p-2 whitespace-nowrap text-sm font-medium text-gray-900"
        {...props}
      />
    );
  };

  const renderTableHeader = props => {
    return (
      <th
        className="p-2 text-left text-sm text-gray-800 font-bold uppercase tracking-wider"
        {...props}
      />
    );
  };

  const renderTableRow = props => {
    return <tr className="my-table-row" {...props} />;
  };

  const renderCodeBlock = ({ node, inline, className, children, ...props }) => {
    const content = String(children).replace(/\n$/, '');
    return !inline ? (
      <SyntaxHighlighter
        children={content}
        style={oneDark}
        language={detectCodeLanguage(content)}
        {...props}
      />
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  const renderButton = props => {
    return (
      <button
        className="w-full mt-2 text-center px-6 py-2 border border-transparent text-base font-medium
			rounded-md shadow-sm text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none
			focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 md:text-lg"
        {...props}
      />
    );
  };

  const detectCodeLanguage = code => {
    return hljs.highlightAuto(code).language;
  };

  return (
    <div id={`chat-message-${id}`} className="chat-message-text">
      <ReactMarkdown
        linkTarget="_blank"
        components={{
          a: openInNewTab,
          table: renderTable,
          td: renderTableCell,
          th: renderTableHeader,
          tr: renderTableRow,
          code: renderCodeBlock,
          button: renderButton,
        }}
        children={message}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  );
};
