import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDomainFromUrl } from '../../utils/common';
import useDataForSeoApi from '../../services/useDataForSeoApi';
import { LOCATIONS } from '../../components/analytics-dashboard/LocationSelect';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
const TopRankingPages = () => {
  const projectUrl = useSelector(
    state =>
      state.project?.selectedProject?.project_url ||
      state.auth?.user?.company?.domain ||
      ''
  );
  const dataForSeoRequest = useDataForSeoApi();
  const [analyticData, setAnalyticData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    if (projectUrl) {
      getTopRankingPage();
    }
  }, [projectUrl]);
  const getTopRankingPage = async () => {
    const requestData = [
      {
        target: getDomainFromUrl(projectUrl),
        location_code: LOCATIONS[0].location_code,
        language_code: LOCATIONS[0].available_languages[0].language_code,
        historical_serp_mode: 'live',
        limit: 3,
      },
    ];
    const response = await dataForSeoRequest.send(
      '/dataforseo_labs/google/relevant_pages/live',
      requestData
    );
    const task = response?.tasks ? response?.tasks[0] : null;
    if (!task) {
      return;
    }
    const result = task?.result ? task?.result[0] : null;
    if (!result || !result.items) {
      return;
    }
    const data = result.items.map(item => ({
      page: item.page_address,
      totalInTop3:
        item?.metrics?.organic?.pos_1 + item?.metrics?.organic?.pos_2_3,
      totalInTop10:
        item?.metrics?.organic?.pos_1 +
        item?.metrics?.organic?.pos_2_3 +
        item?.metrics?.organic?.pos_4_10,
    }));
    const chartData = {
      labels: data.map(item => item.page),
      datasets: [
        {
          label: 'Keywords in top 3',
          data: data.map(item => item.totalInTop3),
          backgroundColor: '#a878ec',
          borderColor: '#ac82f3',
          borderWidth: 2,
        },
        {
          label: 'Keywords in top 10',
          data: data.map(item => item.totalInTop10),
          backgroundColor: '#7433de',
          borderColor: '#6a2bd7',
          borderWidth: 2,
        },
      ],
    };
    setAnalyticData(chartData);
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        title: {
          display: false,
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        title: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Top ranking page',
      },
    },
    indexAxis: 'y',
    barPercentage: 0.6,
    categoryPercentage: 0.8,
  };
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      {analyticData.labels.length > 0 ? (
        <Bar type="bar" data={analyticData} options={options} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
export default TopRankingPages;
