import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import http from '../services/http';
import { isolateSuccess } from '../utils/api';
import Loading from '../components/Loading';

export default function Reactivate() {
  const [error, setError] = useState(false);
  let { token } = useParams();
  let history = useHistory();

  useEffect(() => {
    http
      .post('/api/reactivate', {
        token,
      })
      .then(response => {
        const data = response && response.data;
        // we set `state.from` to `activate` as behaviour is the same
        history.push({
          pathname: '/',
          state: { from: 'activate', message: isolateSuccess(data, null) },
        });
      })
      .catch(e => {
        setError(true);
      });
  }, []);

  // TODO we have very basic layout in this page may be we should update it
  if (!error) {
    return (
      <div className="justify-center pt-96">
        <Loading />
        <div className="font-bold text-center w-full">Loading your info...</div>
      </div>
    );
  } else {
    return <div>Error</div>;
  }
}
