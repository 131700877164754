import React, { useRef, useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { titleCase } from 'title-case';
import classNames from 'classnames';
import './PostBuilder.scss';
import PostBuilderControls from '../components/PostBuilderControls';
import PostBuilderEditor from '../components/PostBuilderEditor';
import PostBuilderSidePanel from '../components/PostBuilderSidePanel';
import { updateUser } from '../store/actions/auth.actions';
import PostBuilderWrapper from '../components/PostBuilderWrapper';
import PostBuilderFooter from '../components/PostBuilderFooter';
import PostBuilderNavigation from '../components/PostBuilderNavigation';
import {
  PostBuilderSidePanelLoader,
  PostBuilderControlsLoader,
  PostBuilderLoader,
} from '../components/PostBuilderComponentLoader';

function MainPostBuilder({
  user,
  updateUser,
  sidePanelValue,
  isPanelCollapsed,
}) {
  const editor = useRef();
  const childElem = useRef();
  const seoRef = useRef();
  const progressRef = useRef();
  const rightPanelRef = useRef();
  const builderLoaderRef = useRef();
  const mainRef = useRef();

  function editorLoaded() {
    childElem?.current?.displayComp(true);
    seoRef?.current?.displayComp(true);
    // builderLoaderRef?.current?.updateLoader(false);
  }

  function upldateLaoderFnc(state) {
    builderLoaderRef?.current?.updateLoader(state);
  }

  function titleCaseHeadings(headings) {
    let caseHeadings = [];
    if (headings && headings.length > 0) {
      caseHeadings = headings.map(heading => titleCase(heading));
    } else {
      caseHeadings.push('no suggestions available');
    }
    return caseHeadings;
  }

  function handleEditorChange(content, editor) {
    if (childElem?.current?.handleEditorChange) {
      childElem.current.handleEditorChange(content, editor);
    }
  }

  function handleSelectionChange(data, editor) {
    if (childElem?.current?.handleEditorChange) {
      childElem.current.handleSelectionChange(data, editor);
    }
    if (seoRef?.current?.handleSelectionChange) {
      seoRef.current.handleSelectionChange();
    }
  }

  function handleOnKeyUp(content, editor) {
    if (childElem && childElem.current && childElem.current.handleOnKeyUp) {
      childElem.current.handleOnKeyUp(content, editor);
    }
  }

  function generateText(editor, type, selection) {
    if (childElem && childElem.current && childElem.current.generateText) {
      childElem.current.generateText(editor, type, selection);
    }
  }

  function showLibraryModal(status) {
    if (childElem?.current?.showLibraryModal) {
      childElem.current.showLibraryModal(status);
    }
  }

  function showImageMetaModal(status, target) {
    if (childElem?.current?.showImageMetaModal) {
      childElem.current.showImageMetaModal(status, target);
    }
  }

  function updateSeoTool(state_type, data) {
    if (seoRef && seoRef.current && seoRef.current.updateSeoTool) {
      seoRef.current.updateSeoTool(state_type, data);
    }
  }

  function updateProgressStateFn(state_type, data) {
    if (
      progressRef &&
      progressRef.current &&
      progressRef.current.updateProgressState
    ) {
      progressRef.current.updateProgressState(state_type, data);
    }
  }

  function updateFeaturedImage(i, state) {
    if (childElem?.current?.updateFeaturedImage) {
      childElem.current.updateFeaturedImage(i, state);
    }
  }

  function doneWithPostFnc() {
    if (childElem && childElem.current && childElem.current.doneWithPostFnc) {
      childElem.current.doneWithPostFnc();
    }
  }

  function topOptionBarHandle(selected_opt, param) {
    if (!childElem?.current?.topOptionBarHandle) {
      return;
    }
    childElem.current.topOptionBarHandle(selected_opt, param);
  }
  return (
    <PostBuilderWrapper
      navigationBarHandle={topOptionBarHandle}
      navigation={
        <PostBuilderNavigation
          ref={rightPanelRef}
          navigationBarHandle={topOptionBarHandle}
        />
      }
    >
      <div className="flex-1 relative z-0 flex overflow-hidden">
        <PostBuilderLoader ref={builderLoaderRef} />
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white"
          ref={mainRef}
        >
          {/* Start main area*/}

          <div className="absolute inset-0 bg-white">
            <div className="">
              {/* <PostBuilderControls
                ref={childElem}
                editorRef={editor}
                updateProgressState={updateProgressState}
                updateSeoTool={updateSeoTool}
                editorLoaded={editorLoaded}
              /> */}
              <PostBuilderControlsLoader
                mainRef={mainRef}
                ref={childElem}
                editorRef={editor}
                updateProgressState={updateProgressStateFn}
                updateSeoTool={updateSeoTool}
                editorLoaded={editorLoaded}
                updateLoader={upldateLaoderFnc}
              />
            </div>
            <PostBuilderEditor
              ref={editor}
              handleEditorChange={handleEditorChange}
              handleOnKeyUp={handleOnKeyUp}
              generateText={generateText}
              handleSelectionChange={handleSelectionChange}
              showLibraryModal={showLibraryModal}
              showImageMetaModal={showImageMetaModal}
              editorLoaded={editorLoaded}
              topOptionBarHandle={topOptionBarHandle}
            />
            {/* Main content */}
            {/* <div className="h-full border-2 border-gray-200 border-dashed rounded-lg" /> */}
          </div>
          <PostBuilderFooter ref={progressRef} />
          {/* End main area */}
        </main>
        <aside
          //   className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-white overflow-y-auto"
          className={classNames(
            sidePanelValue > 0 && !isPanelCollapsed ? 'w-full md:w-128' : 'w-0',
            'relative md:flex md:flex-col flex-shrink-0 border-l border-gray-200 overflow-y-auto ease-in-out duration-500'
          )}
        >
          {/* Start secondary column (hidden on smaller screens) */}
          {/* <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8 bg-blue-300"> */}
          <div className="absolute inset-0">
            <PostBuilderSidePanelLoader
              ref={seoRef}
              generateText={generateText}
              editorRef={editor}
              progressRef={progressRef}
              updateFeaturedImage={updateFeaturedImage}
              topOptionBarHandle={topOptionBarHandle}
              showImageMetaModal
              changePostLoader={upldateLaoderFnc}
            />
            {/* <div className="h-full border-2 border-gray-200 border-dashed rounded-lg" /> */}
          </div>
          {/* End secondary column */}
        </aside>
      </div>
    </PostBuilderWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user || {},
    sidePanelValue: state.panel.sidePanelValue,
    isPanelCollapsed: state.panel.isPanelCollapsed,
  };
}

export default connect(mapStateToProps, { updateUser })(MainPostBuilder);
