import React, { useState, useEffect } from 'react';
import 'bootstrap';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

export default function LibraryImageItem({
  image,
  isSelected,
  handleSelectImage,
}) {
  const { url, thumbnail, owner, owner_username } = image;
  const [imageSpans, setImageSpans] = useState(0);

  return (
    <div className="image-list">
      <div
        className={classNames('img', {
          loading: image.loading,
          selected: isSelected,
        })}
        style={{ backgroundImage: `url(${thumbnail})` }}
        onClick={() => {
          handleSelectImage(image);
        }}
      >
        <div className="actions">
          <a role="button" className="check-mark-icn">
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              width="26px"
              height="26px"
            >
              <path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
            </svg>
          </a>
          <a href={url} target="_blank" className="preview-icn">
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <path d="M 19.980469 2.9902344 A 1.0001 1.0001 0 0 0 19.869141 3 L 15 3 A 1.0001 1.0001 0 1 0 15 5 L 17.585938 5 L 8.2929688 14.292969 A 1.0001 1.0001 0 1 0 9.7070312 15.707031 L 19 6.4140625 L 19 9 A 1.0001 1.0001 0 1 0 21 9 L 21 4.1269531 A 1.0001 1.0001 0 0 0 19.980469 2.9902344 z M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 13 A 1.0001 1.0001 0 1 0 19 13 L 19 19 L 5 19 L 5 5 L 11 5 A 1.0001 1.0001 0 1 0 11 3 L 5 3 z" />
            </svg>
          </a>
          {isNaN(owner) ? (
            <p className="name">
              <a
                href={`https://unsplash.com/@${owner_username}?utm_source=bramework&utm_medium=referral`}
                target="_blank"
                rel="noreferrer"
              >
                {owner}
              </a>
            </p>
          ) : (
            <></>
          )}
        </div>
        <CircularProgress />
        <svg
          className="check"
          fill="#24cf33"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 26 26"
          width="26px"
          height="26px"
        >
          <path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
        </svg>
      </div>
    </div>
  );
}
