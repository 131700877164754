import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';

import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import SemrushFullLogo from '../styles/images/logo-semrush-full.svg';
import { updateUser } from '../store/actions/auth.actions';

const BUTTON_STATUS = {
  INITIAL: 'initial',
  LOADING: 'loading',
  NEW: 'new',
  CONNECTED: 'connected',
  EXPIRED: 'expired',
};

function SEMRushConnect(props) {
  const request = useSentData();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [buttonStatus, setButtonStatus] = useState(BUTTON_STATUS.INITIAL);

  useEffect(() => {
    getSemrushToken();
  }, []);

  const getSemrushToken = () => {
    let url = `/api/semrush/get-info`;
    let method = 'get';
    request.send(url, null, method).then(response => {
      if (!response.isAxiosError) {
        const rep_data = response;
        if (!rep_data) {
          setButtonStatus(BUTTON_STATUS.NEW);
          dispatch(updateUser({ isSemrushConnected: false }));
        } else if (rep_data.is_active === true) {
          setButtonStatus(BUTTON_STATUS.CONNECTED);
          dispatch(updateUser({ isSemrushConnected: true }));
        } else {
          setButtonStatus(BUTTON_STATUS.EXPIRED);
          dispatch(updateUser({ isSemrushConnected: false }));
        }
      } else {
        alert.error(isolateError(response));
      }
    });
  };

  const disconnectSemrush = () => {
    let url = `/api/semrush/disconnet`;
    let method = 'delete';
    request.send(url, null, method).then(response => {
      if (!response.isAxiosError) {
        alert.success('Disconnected successfully');
        setButtonStatus(BUTTON_STATUS.NEW);
        dispatch(updateUser({ isSemrushConnected: false }));
      } else {
        alert.error(isolateError(response));
      }
    });
  };

  const saveTokeAPI = code => {
    let url = `/api/semrush/get-token`;
    let method = 'post';
    const data = { code: code };
    request.send(url, data, method).then(response => {
      if (!response.isAxiosError) {
        alert.success('Integrated successfully');
        setButtonStatus(BUTTON_STATUS.CONNECTED);
        dispatch(updateUser({ isSemrushConnected: true }));
      } else {
        alert.error(isolateError(response));
      }
    });
  };
  const openPopUp = () => {
    var win = window.open(
      'https://oauth.semrush.com/oauth2/authorize?ref=4922222611&client_id=bramework&redirect_uri=/oauth2/bramework/success&response_type=code&scope=user.id%20domains.info%20projects.info%20positiontracking.info',
      'SEMRush',
      'width = 600, height = 400'
    );

    const eventHandler = event => {
      if (event.data?.type == 'semrush:oauth:success') {
        const url = new URL(event.data.url);
        const code = url.searchParams.get('code');
        saveTokeAPI(code);
        setTimeout(() => {
          win.close();
        }, 1000);
      }
      if (event.data?.type == 'semrush:oauth:denied') {
        setTimeout(() => {
          win.close();
        }, 1000);
      }
      window.removeEventListener('message', eventHandler);
    };

    window.addEventListener('message', eventHandler, false);
  };

  const isBtnInactive =
    buttonStatus === BUTTON_STATUS.LOADING ||
    buttonStatus === BUTTON_STATUS.INITIAL ||
    buttonStatus === BUTTON_STATUS.EXPIRED ||
    buttonStatus === BUTTON_STATUS.NEW;
  const showSemrushLogo =
    !!buttonStatus &&
    buttonStatus !== BUTTON_STATUS.LOADING &&
    buttonStatus !== BUTTON_STATUS.INITIAL;

  return (
    <button
      disabled={
        buttonStatus === BUTTON_STATUS.LOADING ||
        buttonStatus === BUTTON_STATUS.INITIAL
      }
      className={cx(
        'cursor-pointer mt-6 mb-4 w-full flex items-center justify-center h-10 border border-transparent text-base text-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-52 sm:text-sm',
        `${
          isBtnInactive
            ? 'bg-gray-400 hover:bg-gray-600'
            : 'bg-purple-600 hover:bg-purple-700'
        }`
      )}
      onClick={() => {
        buttonStatus === BUTTON_STATUS.CONNECTED
          ? disconnectSemrush()
          : openPopUp();
      }}
    >
      {showSemrushLogo && <img src={SemrushFullLogo} className="h-8" />}
      <p className="pr-2">
        {buttonStatus === BUTTON_STATUS.INITIAL ||
        buttonStatus === BUTTON_STATUS.LOADING
          ? 'Loading...'
          : buttonStatus === BUTTON_STATUS.EXPIRED
          ? 're-connect'
          : buttonStatus === BUTTON_STATUS.CONNECTED
          ? 'disconnect'
          : 'connect'}
      </p>
    </button>
  );
}

export default SEMRushConnect;
