import React from 'react';
import './PostItem.scss';
import Tip from '../../components/Tip';
import { formatNumber } from '../../utils/common';

export default function PostItem({ id, title, link, emt }) {
  return (
    <div className="post-item-component">
      <div className="order">{id + 1}.</div>
      <div className="main">
        <div className="post-title">
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </div>
        <div className="post-link">{title}</div>
      </div>
      <div className="emt-wrapper">
        <div className="emt-title">
          EMT&nbsp;
          <Tip text="The estimated monthly traffic this blog post gets organically from Google for this particular keyword. Having a higher EMT does not always result to higher Google organic ranking.<br/><br/><b>Tip:</b> Know how your competition is doing and how much traffic you can expect if your blog post is optimized." />
        </div>
        <div className="emt-value">{emt ? formatNumber(emt, 1) : 'n/a'}</div>
      </div>
    </div>
  );
}
