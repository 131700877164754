import React, { useState, useEffect } from 'react';
import './Wrapper.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import collapse from '../store/actions/menu.action';
import { useLocation, useHistory } from 'react-router-dom';
import AddDomainModal from '../pages/PostBuilder/ComingSoonModal';

function Wrapper({ children, isCollapsed, collapse }) {
  let { pathname } = useLocation();
  let history = useHistory();
  let [toggle, setToggle] = useState(false);
  const [isAddDomainModalVisible, showAddDomainModal] = useState(false);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {}, [user.company]);

  function closeAddDomainModal() {
    showAddDomainModal(false);
  }

  function addDomain() {
    history.push(`/account`);
    showAddDomainModal(false);
  }

  return (
    <div
      className={classNames('dashboard-wrapper', {
        collapsed: isCollapsed,
        toggle: toggle,
      })}
    >
      <div className="side-menu">
        <div>
          <Link to="/dashboard" className="logo"></Link>
          <i
            className={classNames('toggle-control', {
              collapsed: isCollapsed,
            })}
            onClick={() => {
              collapse(!isCollapsed);
              setToggle(!toggle);
            }}
          ></i>
        </div>
        <AddDomainModal
          name="Please add domain name."
          detail="To view your website performance, click 'OK' to go your account and add domain."
          opened={isAddDomainModalVisible}
          close={closeAddDomainModal}
          action={addDomain}
        />
        <ul>
          <li
            className={classNames({
              active: pathname === '/dashboard',
            })}
          >
            <Link className="dashboard" to="/dashboard">
              Dashboard
            </Link>
          </li>
          <li
            className={classNames({
              active: pathname === '/drafts',
            })}
          >
            <Link className="drafts" to="/drafts">
              AI Writing Assistant
            </Link>
          </li>
          <li
            className={classNames({
              active: pathname === '/titles',
            })}
          >
            <Link className="titles" to="/titles">
              AI Blog Titles
            </Link>
          </li>
          <li
            className={classNames({
              active: pathname === '/outlines',
            })}
          >
            <Link className="outlines" to="/outlines">
              AI Blog Outlines
            </Link>
          </li>
          <li
            className={classNames({
              active: pathname === '/keywords',
            })}
          >
            <Link className="keywords" to="/keywords">
              Keyword Research
            </Link>
          </li>
          <li
            className={classNames({
              active: pathname === '/performance',
            })}
          >
            <Link
              className="performance"
              onClick={() => {
                if (
                  user.company.domain === null ||
                  user.company.domain === ''
                ) {
                  showAddDomainModal(true);
                } else {
                  history.push(`/performance`);
                }
              }}
            >
              Performance
            </Link>
          </li>
          <li>
            <a
              className="news"
              href="https://www.bramework.com/cbxchangelog/release-notes/"
              target="_blank"
              rel="noreferrer"
            >
              What's New
            </a>
          </li>
          <li>
            <a
              className="affiliates"
              href="https://bramework.firstpromoter.com/"
              target="_blank"
              rel="noreferrer"
            >
              Affilate Program
            </a>
          </li>
          <li>
            <a
              className="tutorials"
              href="https://www.bramework.com/tutorials/"
              target="_blank"
              rel="noreferrer"
            >
              Tutorials
            </a>
          </li>
        </ul>
      </div>
      <div className="content">
        <div className="body">{children}</div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isCollapsed: state.menu.isCollapsed,
  };
}

export default connect(mapStateToProps, { collapse: collapse })(Wrapper);
