import useSentData from '../../services/useSentData';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { authLogout } from '../../store/actions/auth.actions';
import { track } from '../../services/track';
import './DeactivateConfirmationModal.scss';

function DeactivateConfirmationModal({ opened, close, logout }) {
  const request = useSentData();

  function deactivate() {
    request.send('/api/deactivate', null, 'get').then(() => {
      track('Deactivate');
      logout();
    });
  }

  return (
    <Modal
      className="deactivate-confirmation-modal"
      isOpen={opened}
      ariaHideApp={false}
    >
      <div>
        <h1>Are you sure you want to deactivate your account?</h1>
        <h3>
          Account will be permanently disabled and you will be forced to logout.
        </h3>
        <div className="buttons-wrapper">
          <button className="btn btn-white" onClick={close}>
            Cancel
          </button>
          <button className="btn btn-red" onClick={deactivate}>
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, { logout: authLogout })(
  DeactivateConfirmationModal
);
