import { ChartBarIcon, KeyIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import NewKeywordsToTrack from './NewKeywordsToTrack';
import NewKeywordsOverTime from './NewKeywordsOverTime';
import TopRankingPages from './TopRankingPages';
import RankingKeywords from './RankingKeywords';
const RankedPageTab = () => {
  return <div>Ranked Page</div>;
};
const NewKeywordTab = () => {
  return (
    <>
      <NewKeywordsToTrack />
      <div className="flex flex-col md:flex-row mt-4 gap-3 items-stretch justify-items-stretch">
        <div className="flex-1">
          <NewKeywordsOverTime />
        </div>
        <div className="flex-1">
          <RankingKeywords />
        </div>
        <div className="flex-1">
          <TopRankingPages />
        </div>
      </div>
    </>
  );
};
const DASHBOARD_TABS = [
  {
    id: 'newKeyword',
    text: 'New Keyword',
    icon: <KeyIcon className="w-6 h-6" />,
    component: <NewKeywordTab />,
  },
  {
    id: 'rankedPage',
    text: 'Ranked Page',
    icon: <ChartBarIcon className="w-6 h-6" />,
    component: <RankedPageTab />,
  },
];
const AnalyticDashboard = () => {
  const [activeDashboard, setActiveDashboard] = useState('newKeyword');
  return (
    <div className="mt-3 flex flex-col gap-4 px-3">
      <div className="flex justify-between">
        <div className="flex gap-3">
          {DASHBOARD_TABS.map(item => (
            <div
              onClick={() => setActiveDashboard(item.id)}
              className={`w-1/2 ${
                activeDashboard === item.id
                  ? 'text-purple-500  border-purple-400 '
                  : ''
              } md:w-36 p-4 flex flex-col bg-white cursor-pointer justify-center items-center gap-3 rounded-lg shadow-lg border hover:border-2 hover:bg-purple-100 hover:border-purple-800`}
            >
              {item.icon}
              <div className="">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full px-2 text-center ">
        {DASHBOARD_TABS.find(item => item.id === activeDashboard).component}
      </div>
    </div>
  );
};
export default AnalyticDashboard;
