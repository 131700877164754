import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { track } from '../../services/track';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import * as moment from 'moment';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';

export default function TitleItem({
  title,
  getSelectedTitle,
  user,
  allowDelete = false,
  onDelete = () => {},
  updateTitleLikeStatus,
}) {
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setDeleteBtnClicked(false);
    }

    return () => {
      isCancelled = true;
    };
  }, [title]);

  const updateLikeTitle = status => {
    const method = 'post';
    if (title?.like_status && title?.like_status[0]?.status === status) {
      status = null;
    }
    const url = status ? `/api/like-title` : '/api/like-title-remove';
    const updatedData = { generation_id: title.generation_id, status };
    updateTitleLikeStatus(title.generation_id, status);
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickCopy = title => {
    return () => {
      navigator.clipboard.writeText(title.output);
      alert.success('Successfully copied to clipboard!');
    };
  };

  return (
    <div className="question-item">
      <div className="flex justify-between mb-5 items-center">
        <span className="text-xs text-gray-400">
          {moment
            .duration(
              moment(title.created_at).diff(moment(), 'seconds'),
              'seconds'
            )
            .humanize(true)}
        </span>
        <div className="flex items-center">
          {(user.is_owner || user.is_manager || user.is_editor) && (
            <div>
              <button
                className="inline-flex items-center px-2.5 py-1.5 border border-purple-700 text-xs font-medium rounded-full text-purple-700 bg-purple-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={() => {
                  getSelectedTitle(title.output);
                  track('Title generator', { Clicked: 'write post' });
                }}
              >
                Write Post
              </button>
            </div>
          )}
          {allowDelete && (
            <div>
              <Tooltip title="Like" arrow>
                <button
                  onClick={() => updateLikeTitle(1)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                >
                  <ThumbUpIcon
                    className={`h-4 w-4 hover:text-green-500 ${
                      title.like_status && title.like_status[0]?.status === 1
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="DisLike" arrow>
                <button
                  onClick={() => updateLikeTitle(2)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100  border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <ThumbDownIcon
                    className={`h-4 w-4 hover:text-red-500 ${
                      title.like_status && title.like_status[0]?.status === 2
                        ? 'text-red-500'
                        : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Copy" arrow>
                <button
                  onClick={onClickCopy(title)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                >
                  <DocumentDuplicateIcon
                    className={`h-4 w-4 text-gray-500`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  onClick={() => {
                    onDelete();
                    setDeleteBtnClicked(true);
                  }}
                  disabled={deleteBtnClicked}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <TrashIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <p className="flex-1">{title.output}</p>
    </div>
  );
}
