import { factCheckerActionTypes } from '../reducers/fact_checker.reducer';
import http from '../../services/http';
import { sortListByDate } from '../../utils/common';
import { track } from '../../services/track';

const setFacts = payload => ({
  type: factCheckerActionTypes.SET_FACTS,
  payload,
});

const setLoading = payload => ({
  type: factCheckerActionTypes.SET_LOADING,
  payload,
});

const factCheck = claim => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      track('Fact check', { claim });
      const projectId = getState().project.selectedProject.id;
      const postId = getState().post.id;
      const response = await http.post(
        `/api/projects/${projectId}/posts/${postId}/fact-check`,
        {
          claim,
        }
      );
      if (response.status === 200 && response.data) {
        const data = [...response.data.generations];
        sortListByDate(data, 'created_at');
        dispatch(setFacts(data));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export { setFacts, factCheck };
