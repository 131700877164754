import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { getPlagiarisms } from '../store/actions/plagiarisms.actions';

const PlagiarismHistory = props => {
  let { id } = useParams();
  useEffect(() => {
    props.getPlagiarisms(id);
  }, []);
  if (props.plagiarism_history.length === 0) {
    return (
      <h3 className="plagiarism-modal-subtext">No plagiarism history found</h3>
    );
  }
  return props.plagiarism_history.map((data, index) => (
    <div className="plagiarism-results" key={`${index}-plagiarism-${id}`}>
      <p className="plagiarism-modal-title">{data.title}</p>
      <p className="plagiarism-modal-subtext">{data.textmatched}</p>
      <span className="plagiarism-modal-link">
        Source:{' '}
        <a
          href={data.url}
          target="_blank"
          rel="noreferrer"
          alt={data.textmatched}
        >
          {data.url}
        </a>
      </span>
      <br />
      <span className="plagiarism-modal-matched-word">
        Words Matched: {data.wordsmatched}
      </span>
      <span className="plagiarism-modal-percentage-word">
        Percentage Matched: {data.percentmatched}%
      </span>
    </div>
  ));
};
function mapPlagiarismHistoryStateToProps(state) {
  return {
    plagiarism_history: state.plagiarism.plagiarism_history,
  };
}

export default connect(
  mapPlagiarismHistoryStateToProps,
  { getPlagiarisms },
  null
)(PlagiarismHistory);
