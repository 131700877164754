import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import FormInput from '../../components/form/FormInput';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';

const UsageLimitModal = ({
  openModal,
  closeModal,
  memberId,
  member,
  teamId,
  teamUsageLimit,
  teamMemberUsageLimit,
  getTeamUsageLimit,
  getTeamMemberUsageLimit,
}) => {
  const [usageLimit, setUsageLimit] = useState(teamUsageLimit);
  const alert = useAlert();
  const request = useSentData();

  useEffect(() => {
    if (memberId && !teamUsageLimit.team_usage_limit) {
      const memberLimit = teamMemberUsageLimit.find(
        item => item.team_member.id === memberId
      );
      setUsageLimit(memberLimit?.words_usage_limit);
    } else {
      setUsageLimit(teamUsageLimit?.words_usage_limit);
    }
  }, [memberId, teamMemberUsageLimit, teamMemberUsageLimit]);

  const onChangeUsageLimit = value => {
    setUsageLimit(value);
  };

  const setTeamMemberUsageLimit = (teamUsageLimit, isShowMessage = true) => {
    request
      .send(
        `/api/usage/${teamId}`,
        {
          team_usage_limit: !!teamUsageLimit,
          words_usage_limit: teamUsageLimit ? usageLimit : 0,
        },
        'patch'
      )
      .then(response => {
        if (!response.isAxiosError) {
          if (isShowMessage) {
            alert.success('Updated team member usage limit!');
          }
          getTeamUsageLimit();
        } else {
          alert.error(isolateError(response));
        }
      });
    closeModal();
  };

  const setIndividualUsageLimit = teamUsageLimit => {
    request
      .send(
        `/api/team/${memberId}/usage-limit`,
        {
          usage_limit: !!teamUsageLimit,
          words_usage_limit: teamUsageLimit ? usageLimit : 0,
        },
        'post'
      )
      .then(response => {
        if (!response.isAxiosError) {
          alert.success('Updated team member usage limit!');
          getTeamMemberUsageLimit();
          if (teamUsageLimit) {
            setTeamMemberUsageLimit(false, false);
          }
        } else {
          alert.error(isolateError(response));
        }
      });

    closeModal();
  };

  const onClickUnset = () => {
    if (memberId) {
      setIndividualUsageLimit(false);
    } else {
      setTeamMemberUsageLimit(false);
    }
  };

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        static
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => closeModal()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                Set {memberId ? <b>{member?.full_name}</b> : 'team'} usage limit
                <div className="text-xs text-red-500 mb-3">
                  Note:{' '}
                  {memberId
                    ? 'Setting member limit will disable team limit.'
                    : 'Setting team limit will disable member limit.'}
                </div>
                <FormInput
                  type="number"
                  onChange={onChangeUsageLimit}
                  value={usageLimit}
                />
              </div>
              <div className="flex mt-4 justify-end">
                <button
                  className="mr-3 cursor-pointer px-6 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-100 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClickUnset}
                >
                  No limit
                </button>
                <button
                  className="mr-3 cursor-pointer px-6 py-2 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className=" cursor-pointer px-6 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-100 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={
                    memberId ? setIndividualUsageLimit : setTeamMemberUsageLimit
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UsageLimitModal;
