import React, { useEffect, useState } from 'react';
import http from '../services/http';
import './Account.scss';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import Select from '../components/form/FormSelect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateUser } from '../store/actions/auth.actions';
import FormPassword from '../components/form/FormPassword';
import Loading from '../components/Loading';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import DeactivateConfirmationModal from './Account/DeactivateConfirmationModal';
import CancelSubscriptionModal from './Account/CancelSubscriptionModal';
import Moment from 'react-moment';
import classNames from 'classnames';
import { numberToThousands } from '../utils/common';
import { useAlert } from 'react-alert';
import { ExclamationIcon } from '@heroicons/react/solid';
import WordUsageHistorical from './Account/WordUsageHistorical';

const APPSUMO_PLANS = [
  'bramework_tier1',
  'bramework_tier2',
  'bramework_tier3',
  'bramework_tier4',
];

function AddonsDetail({ addon, addAddons }) {
  const alert = useAlert();
  const [selectedItem, setSelectedItem] = useState(null);
  const request = useSentData();

  const purchaseAddon = addon_id => {
    addAddons(addon_id);
  };

  // TODO we have very basic layout in this page may be we should update it
  return (
    <div className="mb-8">
      <label
        htmlFor="words"
        className="block text-sm font-semibold text-gray-900"
      >
        Purchase{' '}
        {addon.isAIWords
          ? 'additional AI words'
          : addon.isFirstDrafts
          ? 'First Draft PRO credits'
          : 'additional plagiarism words'}
      </label>
      <Select
        id="words"
        className="account-select mb-6"
        options={addon.options}
        // value={AI_WORDS[0]}
        onChange={setSelectedItem}
      />
      <button
        onClick={e => purchaseAddon(selectedItem.id)}
        className={`justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green sm:mt-0 sm:w-auto sm:text-sm disabled:opacity-50 ${
          !selectedItem ? 'cursor-not-allowed' : ''
        }`}
        disabled={!selectedItem}
      >
        Purchase
      </button>
    </div>
  );
}

function PasswordForm() {
  const request = useSentData();
  const [current, setCurrent] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const alert = useAlert();

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (!request.loading) {
          request
            .send('/api/change-password', {
              old_password: current,
              password: newPassword,
              password_confirm: confirm,
            })
            .then(res => {
              if (!res.isAxiosError) {
                alert.success(res.message || 'Successfully changed!');
              } else {
                alert.error(isolateError(res));
              }
            });
        }
      }}
    >
      <h4>Password reset</h4>
      {request.loading && <Loading />}
      <FormPassword
        id="current"
        className="account-input"
        label="Current password *"
        value={current}
        onChange={v => setCurrent(v)}
      />
      <FormPassword
        id="newPassword"
        className="account-input"
        label="New Password *"
        value={newPassword}
        onChange={v => setNewPassword(v)}
      />
      <FormPassword
        id="confirm"
        className="account-input"
        label="Confirm new password *"
        value={confirm}
        onChange={v => setConfirm(v)}
      />
      <div className="button-wrapper">
        <button
          id="submit"
          className="w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Save
        </button>
      </div>
    </form>
  );
}

function Billing({ billing, app_sumo_subscription, updateUser }) {
  const request = useSentData();

  function openCheckout() {
    window.Chargebee.getInstance().openCheckout({
      hostedPage: function() {
        return request.send('/api/billing/manage');
      },
      close: function() {
        request.send('/api/billing/source').then(data => {
          if (!data.isAxiosError) {
            updateUser({ billing: data });
          }
        });
      },
    });
  }

  if (
    app_sumo_subscription &&
    app_sumo_subscription.plan &&
    app_sumo_subscription.is_active
  ) {
    return null;
  }

  return (
    <div className="form-card billing-card">
      <div className="inline-block">
        <h4>Billing</h4>
        {billing.full_name ? (
          <div className="clearfix">
            <div className="float-left">
              <p>Billing information</p>
              <div className="instructions">
                {billing.address || billing.location || billing.country ? (
                  <div>
                    <div>{billing.address}</div>
                    <div>{billing.location}</div>
                    <div>{billing.country}</div>
                  </div>
                ) : (
                  <div className="info-text">
                    No billing information provided.
                  </div>
                )}
              </div>
            </div>
            <div className="float-left">
              <p>Payment card</p>
              <div className="instructions">
                <div>
                  {billing.full_name} - {billing.card_type} -{' '}
                  {billing.card_last4}
                </div>
                <div className="info-text">Expiration {billing.expiration}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="instructions">
            <div className="info-text">
              No payment source added. Please click the button below to add
              billing information and credit card details for future pruchases.
            </div>
          </div>
        )}
        <button
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={openCheckout}
        >
          Edit My Billing Information
        </button>
        <div className="my-invoices">
          <p>My invoices</p>
          <Link
            to="/invoices"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            View My Invoices
          </Link>
        </div>
      </div>
    </div>
  );
}

function mapBillingStateToProps(state) {
  const user = state.auth.user || {};
  return {
    billing: user.billing || {},
    app_sumo_subscription: user.app_sumo_subscription || {},
  };
}

const BillingForm = connect(mapBillingStateToProps, { updateUser })(Billing);

// NEW Logic for user plan details and addon option purchase.
function Subscription({
  subscription,
  app_sumo_subscription,
  user,
  updateUser,
  remainWords,
}) {
  const hasValidAppSumoPlan =
    user?.app_sumo_subscription?.plan?.app_sumo_plan &&
    (APPSUMO_PLANS.includes(user.app_sumo_subscription.plan.app_sumo_plan) ||
      user.app_sumo_subscription.plan.app_sumo_plan === 'bramework_ltd1');
  const alert = useAlert();
  const request = useSentData();
  const [openCancelSubscriptionModal, setCancelSubscriptionModal] = useState(
    false
  );

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = () => {
    http.get(`/api/users/${user.id}`).then(userRes => {
      const data = userRes.data;
      if (!data.isAxiosError) {
        const user = data;
        updateUser(user);
      }
    });
  };

  const cancelSubscription = () => {
    request.send(`/api/cancel-subscription`, null, 'get').then(res => {
      if (!res.isAxiosError) {
        getSubscription();
        setCancelSubscriptionModal(false);
      } else {
        alert.error(isolateError(res));
      }
    });
  };

  const resumeSubscription = () => {
    request
      .send(`/api/remove-scheduled-cancellation`, null, 'get')
      .then(res => {
        if (!res.isAxiosError) {
          getSubscription();
        } else {
          alert.error(isolateError(res));
        }
      });
  };

  return (
    <div>
      <CancelSubscriptionModal
        open={openCancelSubscriptionModal}
        setOpen={setCancelSubscriptionModal}
        cancelSubscription={cancelSubscription}
        hasTrial={user.has_trial_subscription}
        loading={request.loading}
      />
      <div id="plan">
        {/* Plan content goes here */}
        <h4>Plan</h4>
        {/* ... (keep the existing plan content) ... */}
        <div>
          {app_sumo_subscription &&
          app_sumo_subscription.plan &&
          app_sumo_subscription.is_active ? (
            <div>
              <div className="clearfix inline-block">
                <div className="float-left">
                  {(() => {
                    const plan = app_sumo_subscription.plan.app_sumo_plan;
                    switch (plan) {
                      case 'bramework_tier1':
                        return <b>AppSumo Tier 1 </b>;
                      case 'bramework_tier2':
                        return <b>AppSumo Tier 2 </b>;
                      case 'bramework_tier3':
                        return <b>AppSumo Tier 3 </b>;
                      case 'bramework_tier4':
                        return <b>AppSumo Tier 4 </b>;
                      case 'free_user':
                        return <b>{app_sumo_subscription.plan.name}</b>;
                      case 'bramework_ltd1':
                        return <b>{app_sumo_subscription.plan.name}</b>;
                      default:
                        return <b>{app_sumo_subscription.plan.name}</b>;
                    }
                  })()}
                  {/* <span>${app_sumo_subscription.plan.price}</span> */}
                </div>
                <div className="float-left nex-billing">
                  Next cycle on:{' '}
                  <Moment
                    date={app_sumo_subscription.next_billing}
                    format="MMM. D, YYYY"
                  />
                </div>
              </div>
              <div className="instructions">
                <p className="mb-4">
                  Keyword Analyzer: <b>Unlimited</b>
                </p>
                {/* {app_sumo_subscription.plan.allowed_words >= 1000000 ? (
              <p>
                Words available: <b>Unlimited</b>
              </p>
            ) : ( */}
                {app_sumo_subscription.plan.allowed_words >= 0 && (
                  <p className="mb-4">
                    AI words remaining:{' '}
                    <b>
                      {numberToThousands(user.available_words)} /{' '}
                      {numberToThousands(
                        app_sumo_subscription.plan.allowed_words
                      )}
                    </b>
                  </p>
                )}
                {
                  <p className="mb-4">
                    AI words credit used:{' '}
                    <b>
                      {numberToThousands(remainWords?.used_credit_ai_words)} /{' '}
                      {numberToThousands(remainWords?.credit_ai_words)}
                    </b>
                  </p>
                }
                {app_sumo_subscription.plan.plagiarism_word_limit >= 0 && (
                  <p className="mb-4">
                    Plagiarism words remaining:{' '}
                    <b>
                      {numberToThousands(user.plagiarism_word_limit)} /{' '}
                      {numberToThousands(
                        app_sumo_subscription.plan.plagiarism_word_limit
                      )}
                    </b>
                  </p>
                )}
                {user.addon_allowed_words >= 0 && (
                  <p className="mb-4">
                    AI addon words remaining:{' '}
                    <b>
                      {numberToThousands(user.additional_words)} /{' '}
                      {numberToThousands(user.addon_allowed_words)}
                    </b>
                  </p>
                )}
                {user.addon_plagiarism >= 0 && (
                  <p className="mb-4">
                    Plagiarism addon words remaining:{' '}
                    <b>
                      {numberToThousands(user.additional_plagiarism_words)} /{' '}
                      {numberToThousands(user.addon_plagiarism)}
                    </b>
                  </p>
                )}
                {user.addon_first_draft_credits >= 0 && user.first_draft && (
                  <p className="mb-4">
                    First Draft PRO credits remaining:{' '}
                    <b>
                      {numberToThousands(user.first_draft_credits)} /{' '}
                      {numberToThousands(user.addon_first_draft_credits)}
                    </b>
                  </p>
                )}
                {/* )} */}
              </div>
            </div>
          ) : subscription &&
            subscription.plan &&
            subscription.is_active === true &&
            !user.has_trial_subscription ? (
            <div>
              {subscription && ['non_renewing'].includes(subscription.status) && (
                <div
                  class="bg-red-50 mb-3 border-t-4 border-red-400 rounded-b text-red-700 px-4 py-3 shadow-sm"
                  role="alert"
                >
                  <div class="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-7 w-7 text-red-500 mr-4"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <p class="font-bold">
                        Your plan will be canceled at the end of the billing
                        period
                      </p>
                      <p class="text-sm">
                        If you want to continue your plan, please click the
                        Resume Plan button below.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="clearfix">
                <div className="float-left">
                  <b>{subscription.plan.name}</b>
                  <span>
                    {subscription.plan.name === 'Flex' &&
                    subscription.plan.price === 0 ? (
                      'pay-as-you-go'
                    ) : (
                      <>
                        ${subscription.plan.price / 100}/
                        {subscription.plan.period_unit}
                      </>
                    )}
                  </span>
                </div>
                <div className="float-left nex-billing">
                  {subscription &&
                  ['non_renewing'].includes(subscription.status)
                    ? 'Will be canceled on: '
                    : subscription.plan.name === 'Flex' &&
                      subscription.plan.price === 0
                    ? 'Next cycle on: '
                    : 'Next billing on: '}
                  <Moment
                    date={subscription.date_expires}
                    format="MMM. D, YYYY"
                  />
                </div>
              </div>
              <div className="instructions mt-3 inline-block md:inline">
                <p className="md:pt-10 mb-4">
                  Keyword Analyzer:{' '}
                  <b>
                    {subscription.plan.name === 'Flex' &&
                    subscription.plan.price === 0
                      ? 'Max 5 per day - 60 per month'
                      : 'Unlimited'}
                  </b>
                </p>
                <p className="mb-4">
                  Status:{' '}
                  <b>
                    {subscription.status
                      ? subscription.status.replace('_', ' ').toUpperCase()
                      : '--'}
                  </b>
                </p>
                {subscription.plan.allowed_words >= 1000000 ? (
                  <p className="mb-4">
                    AI words remaining: <b>Unlimited</b>
                  </p>
                ) : (
                  <>
                    <p className="mb-4">
                      AI words remaining:{' '}
                      <b>
                        {numberToThousands(user.available_words)} /{' '}
                        {numberToThousands(subscription.plan.allowed_words)}
                      </b>
                    </p>
                    <p className="mb-4">
                      AI words credit used:{' '}
                      <b>
                        {numberToThousands(remainWords?.used_credit_ai_words)} /{' '}
                        {numberToThousands(remainWords?.credit_ai_words)}
                      </b>
                    </p>
                  </>
                )}
                {user.plagiarism_word_limit >= 0 && (
                  <p className="mb-4">
                    Plagiarism words remaining:{' '}
                    <b>
                      {numberToThousands(user.plagiarism_word_limit)} /{' '}
                      {numberToThousands(
                        subscription.plan.plagiarism_word_limit
                      )}
                    </b>
                  </p>
                )}
                {user.addon_allowed_words >= 0 && (
                  <p className="mb-4">
                    AI addon words remaining:{' '}
                    <b>
                      {numberToThousands(user.additional_words)} /{' '}
                      {numberToThousands(user.addon_allowed_words)}
                    </b>
                  </p>
                )}
                {user.addon_plagiarism >= 0 && (
                  <p className="mb-4">
                    Plagiarism addon words remaining:{' '}
                    <b>
                      {numberToThousands(user.additional_plagiarism_words)} /{' '}
                      {numberToThousands(user.addon_plagiarism)}
                    </b>
                  </p>
                )}
                {user.addon_first_draft_credits >= 0 && user.first_draft && (
                  <p className="mb-8">
                    First Draft PRO credits remaining:{' '}
                    <b>
                      {numberToThousands(user.first_draft_credits)} /{' '}
                      {numberToThousands(user.addon_first_draft_credits)}
                    </b>
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="instructions">
              {subscription && subscription.is_active === true ? (
                <div className="mb-4">
                  <div className="clearfix">
                    <div className="nex-billing">
                      Start billing on:{' '}
                      <b>
                        <Moment
                          date={subscription.date_expires}
                          format="MMM. D, YYYY"
                        />
                      </b>
                    </div>
                  </div>
                </div>
              ) : null}
              <p className="mb-4">
                Keyword Analyzer: <b>Max 5 per day - 60 per month</b>
              </p>
              <p className="mb-4">
                Words available:{' '}
                <b>{numberToThousands(user.available_words)}</b> - Trial
                allowance: <b>{numberToThousands(3000)}</b>
              </p>
              {user.addon_first_draft_credits >= 0 && user.first_draft && (
                <p className="mb-4">
                  First Draft PRO credits remaining:{' '}
                  <b>
                    {numberToThousands(user.first_draft_credits)} / {1}
                  </b>
                </p>
              )}
              <div className="info-text">
                You are currently in trial period, please subscribe.
                {/* You are currently in the free trial period. */}
              </div>
            </div>
          )}
          {app_sumo_subscription &&
          app_sumo_subscription.plan &&
          app_sumo_subscription.is_active ? null : (
            <>
              <Link
                className="w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green sm:mt-0 sm:w-auto sm:text-sm"
                to="/plans"
              >
                Explore Plan Options & Add-ons
              </Link>
              {subscription &&
              ['active', 'in_trial'].includes(subscription.status) ? (
                <Link
                  className="w-full ml-0 md:ml-4 mt-4 mt-md-0 inline-flex justify-center px-8 py-2 rounded-md border border-transparent shadow-sm bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setCancelSubscriptionModal(true);
                  }}
                >
                  {`Cancel ${
                    subscription.status === 'in_trial' ? 'Free Trial' : 'Plan'
                  }`}
                </Link>
              ) : (
                subscription &&
                ['non_renewing'].includes(subscription.status) && (
                  <Link
                    className="w-full ml-4 inline-flex justify-center px-8 py-2 border border-gray-300 text-base font-medium rounded-md shadow-sm bg-white text-gray-700 hover:text-gray-500 focus:outline-none  focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      resumeSubscription();
                    }}
                  >
                    {request.loading && (
                      <svg
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="mr-2 mt-1 animate-spin"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                      </svg>
                    )}
                    Resume Plan
                  </Link>
                )
              )}
              {/* <AddonsDetail addAddons={addAddons} /> */}
            </>
          )}
          <div className="mt-6">
            {hasValidAppSumoPlan && (
              <Link
                to={
                  APPSUMO_PLANS.includes(
                    user.app_sumo_subscription.plan.app_sumo_plan
                  )
                    ? '/appsumo-plan'
                    : user.app_sumo_subscription.plan.app_sumo_plan ===
                      'bramework_ltd1'
                    ? '/bramework-ltd-plan'
                    : '/account'
                }
                className="w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green sm:mt-0 sm:w-auto sm:text-sm"
              >
                {APPSUMO_PLANS.includes(
                  user.app_sumo_subscription.plan.app_sumo_plan
                )
                  ? 'View AppSumo Tiers & Add-ons'
                  : user.app_sumo_subscription.plan.app_sumo_plan ===
                    'bramework_ltd1'
                  ? 'View Bramework LTD Plan'
                  : 'View Account Details'}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapSubscriptionStateToProps(state) {
  const user = state.auth.user || {};
  return {
    subscription: user.subscription || {},
    app_sumo_subscription: user.app_sumo_subscription || {},
    user: user,
  };
}

const SubscriptionForm = connect(mapSubscriptionStateToProps, { updateUser })(
  Subscription
);

function DeactivateAccount() {
  const [isModalVisible, showModal] = useState(false);

  return (
    <div>
      <DeactivateConfirmationModal
        opened={isModalVisible}
        close={() => showModal(false)}
      />
      <h4>Deactivate my account</h4>
      <div className="instructions">
        <div className="info-text">
          By deactivating your account, you’ll no longer be able to access any
          of your post or log into Bramework.
          <br></br>
          <br></br>
          Email us{' '}
          <a
            href="https://www.bramework.com/support/"
            target="_blank"
            className="text-purple-500 hover:text-purple-700"
            rel="noreferrer"
          >
            support@bramework.com
          </a>
           to deactivate your account.
        </div>
      </div>
      {/* <button
        className="btn btn-white btn-sm"
        onClick={() => {
          showModal(true);
        }}
      >
        Deactivate account
      </button> */}
    </div>
  );
}

function Account({ user }) {
  const request = useSentData();
  const [remainWords, setRemainWords] = useState({});

  useEffect(() => {
    getRemainingWords();
  }, []);

  const getRemainingWords = () => {
    request.send(`/api/remaining-words`, null, 'get').then(res => {
      if (!res.isAxiosError) {
        setRemainWords(res);
      }
    });
  };

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="My Account" />}
    >
      <div className="account-wrapper mt-8">
        <div className="cards-wrapper">
          <div className="form-card">
            <PasswordForm />
          </div>
          {user?.is_owner && (user.subscription || user.app_sumo_subscription) && (
            <>
              <div className="form-card subscription-card">
                <SubscriptionForm remainWords={remainWords} />
              </div>
              <div className="form-card">
                <WordUsageHistorical remainWords={remainWords} />
              </div>
              <BillingForm />
            </>
          )}
          <div className="form-card">
            <DeactivateAccount />
          </div>
        </div>
      </div>
    </NewWrapper>
  );
}

function mapAccountStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
export default connect(mapAccountStateToProps, null)(Account);
