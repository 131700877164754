import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authLogout, setIsOnBoardingUser } from '../store/actions/auth.actions';
import { destroyProjects } from '../store/actions/project.action';
import { destroyTeam } from '../store/actions/team.actions';
import Select from '../components/form/FormSelect';
import FormInput from '../components/form/FormInput';
import panelCollapse, {
  setSidePanelOpenTab,
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import { sidePanelTabs } from '../store/reducers/side_panel.reducer';
import {
  saveOnBoardingInfo,
  saveOnBoardingInfoToGoogleSheet,
} from '../services/onBoaringService';

const MONTHLY_TRAFFICS = [
  {
    label: 'Less than 500 visits per month',
    value: 'Less than 500 visits per month',
  },
  {
    label: '500 - 1,000 visits per month',
    value: '500 - 1,000 visits per month',
  },
  {
    label: '1000 - 5,000 visits per month',
    value: '1000 - 5,000 visits per month',
  },
  {
    label: '5000 - 10,000 visits per month',
    value: '5000 - 10,000 visits per month',
  },
  {
    label: 'Over 10,000 visits per month',
    value: 'Over 10,000 visits per month',
  },
];

const MONTHLY_REVENUES = [
  {
    label: 'Less than $500 a month (USD)',
    value: 'Less than $500 a month (USD)',
  },
  {
    label: '$500 - $1,000 a month (USD)',
    value: '$500 - $1,000 a month (USD)',
  },
  {
    label: '$1,000 - $5,000 a month (USD)',
    value: '$1000 - $5,000 a month (USD)',
  },
  {
    label: '$5,000 - $10,000 a month (USD)',
    value: '$5,000 - $10,000 a month (USD)',
  },
  { label: 'Over $10,000 a month (USD)', value: 'Over $10,000 a month (USD)' },
];

const INTERESTED_IN_FREE_BLOGS = [
  { label: 'Yes, please!', value: true },
  { label: "No thanks, I'll grow on my own.", value: false },
];

export const BloggerOnBoarding = () => {
  const [traffic, setTraffic] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [interestedFreeBlog, setInterestedFreeBlog] = useState(null);
  const [blogUrl, setBlogUrl] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showGetBlog, setShowGetBlog] = useState(false);
  const { selectedProject } = useSelector(state => state.project);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickLogout = () => {
    dispatch(authLogout());
    dispatch(destroyProjects());
    dispatch(destroyTeam());
  };

  const onContinueWithoutFreeBlog = () => {
    setSubmitted(true);
    if (!traffic?.value || !revenue?.value || interestedFreeBlog == null) {
      return;
    }
    if (interestedFreeBlog?.value) {
      setSubmitted(false);
      setShowGetBlog(true);
    } else {
      goToNewPost();
    }
  };

  const onContinueWithFreeBlog = () => {
    setSubmitted(true);
    if (!blogUrl) {
      return;
    }
    goToNewPost();
  };

  const goToNewPost = () => {
    sessionStorage.removeItem('isOnBoardingUser');
    dispatch(panelCollapse(sidePanelTypes.DEFAULT, false));
    dispatch(setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS));
    dispatch(setIsOnBoardingUser(false));
    saveOnBoardingInfo({
      'Monthly traffic': traffic.value,
      'Monthly revenue': revenue.value,
      'Interested in a free blog audit': interestedFreeBlog.value
        ? 'Yes'
        : 'No',
      'Blog URL': blogUrl,
    });
    saveOnBoardingInfoToGoogleSheet();
    history.replace(`/project/${selectedProject.id}/dashboard`, {
      isOnBoardingUser: true,
    });
  };

  return (
    <div className="onboarding-component">
      <button className="logout" onClick={onClickLogout}>
        Log out
      </button>
      <div className="inner">
        {showGetBlog ? (
          <>
            <h1>Get your free blog audit!</h1>
            <h3 className="text-center">
              Please enter in the URL of your blog and our team will reach out!
            </h3>
            <div className="inputs-wrapper">
              <FormInput
                label=""
                inputProps={{ placeholder: 'www.yourwebsite.com' }}
                value={blogUrl}
                isValid={submitted && !blogUrl}
                onChange={setBlogUrl}
              />
            </div>
          </>
        ) : (
          <>
            <h1>What stage are you in your blogging journey?</h1>
            <div className="inputs-wrapper">
              <Select
                label="Monthly traffic:"
                className="account-select"
                options={MONTHLY_TRAFFICS}
                value={traffic}
                isValid={submitted && !traffic}
                onChange={setTraffic}
              />
              <Select
                label="Monthly revenue from your blog:"
                className="account-select"
                options={MONTHLY_REVENUES}
                value={revenue}
                isValid={submitted && !revenue}
                onChange={setRevenue}
              />
              <Select
                label="Interested in a free blog audit from our team?"
                className="account-select"
                options={INTERESTED_IN_FREE_BLOGS}
                value={interestedFreeBlog}
                isValid={submitted && interestedFreeBlog == null}
                onChange={setInterestedFreeBlog}
              />
            </div>
          </>
        )}
        <div className="button-wrapper clearfix">
          <button
            onClick={
              showGetBlog ? onContinueWithFreeBlog : onContinueWithoutFreeBlog
            }
            className="inline-flex items-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 float-right"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
