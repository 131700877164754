import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSentData from '../../services/useSentData';
import { track } from '../../services/track';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';
import {
  addGeneratedPostTitles,
  addPostTitle,
} from '../../store/actions/post_builder.actions';
import { useParams } from 'react-router-dom';
import { sortListByDate } from '../../utils/common';
import TabItems from './TabItems';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import {
  setNovaOnBoardingCheckList,
  setNovaOnBoardingStep,
} from '../../store/actions/nova_onboarding.action';

export default function TitlesTab() {
  const request = useSentData();
  let { id, project_id } = useParams();
  const [titles, setTitles] = useState([]);
  const [titlesLoading, setTitlesLoading] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const generatedPostTitles = useSelector(state => state.post.generatedTitles);
  const instructions = useSelector(state => state.post.instructions);
  const instructionButtonOn = useSelector(
    state => state.post.instructionButtonOn
  );
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const tone = useSelector(state => state.post.tone);
  const postId = useSelector(state => state.post.id);
  if (!id && postId) {
    id = postId;
  }

  useEffect(() => {
    sortListByDate(generatedPostTitles, 'created_at');
    setTitles(generatedPostTitles);
  }, [generatedPostTitles]);

  function generateTitles() {
    setTitlesLoading(true);
    track('Blog post creator', { Clicked: 'generate titles' });
    request
      .send(`/api/projects/${project_id}/posts/${postId}/generate-title`, {
        tone: tone.value,
        instructions: instructionButtonOn ? instructions : '',
      })
      .then(response => {
        setTitlesLoading(false);
        if (!response.isAxiosError) {
          if (response.success) {
            sortListByDate(response.content, 'created_at');
            response.content.forEach(item => {
              if (
                titles.findIndex(
                  title => title.generation_id === item.generation_id
                ) < 0
              ) {
                item.isNewItem = true;
              }
            });
            dispatch(addGeneratedPostTitles(response.content));
            document.getElementsByClassName('tab-content')[0]?.scrollTo(0, 0);
            setTimeout(() => {
              dispatch(
                addGeneratedPostTitles(
                  response.content.map(item => ({ ...item, isNewItem: false }))
                )
              );
            }, 4000);
          } else {
            alert.error(response.message);
          }
        } else {
          alert.error(isolateError(response));
        }
      });
  }

  function savesTitles(data) {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'put';
    if (id) {
      url += `/${id}`;
      request.send(url, { titles: data }, method).then(response => {
        if (!response.isAxiosError) {
          dispatch(addGeneratedPostTitles(response.titles));
        } else {
          alert.error(isolateError(response));
        }
      });
    } else {
      alert.error('Post id does not exist');
    }
  }

  const updateLikeTitle = (status, title) => {
    const method = 'post';
    if (title?.like_status && title?.like_status[0].status === status) {
      status = null;
    }
    const url = status ? `/api/like-title` : '/api/like-title-remove';
    const updatedData = { generation_id: title.generation_id, status };
    if (title.like_status) {
      title.like_status[0].status = status;
    } else {
      title.like_status = [{ status }];
    }
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickAddTitle = title => {
    dispatch(addPostTitle(title.output));
    dispatch(
      setNovaOnBoardingCheckList(novaOnBoardingCheckLists.GENERATE_A_TITLE)
    );
    if (stepNovaOnBoardingGuide === novaOnBoardingSteps.WRITE_A_FIRST_DRAFT) {
      dispatch(setNovaOnBoardingStep(novaOnBoardingSteps.GENERATE_AN_OUTLINE));
    }
  };

  const onDeleteTitle = (generation_id, index) => {
    const url = `/api/delete-title`;
    const method = 'post';
    const updatedData = { generation_id };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newTitles = [...titles];
          newTitles.splice(index, 1);
          setTitles(newTitles);
          dispatch(addGeneratedPostTitles(newTitles));
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickCopy = title => {
    navigator.clipboard.writeText(title.output);
    alert.success('Successfully copied to clipboard!');
  };

  return (
    <div className="tool-tab">
      <div className="overflow-y-auto tab-content">
        <div className="flow-root mt-4">
          <TabItems
            dataList={titles}
            onClickCopy={title => onClickCopy(title)}
            onClickDelete={(title, index) =>
              onDeleteTitle(title.generation_id, index)
            }
            onClickLike={title => updateLikeTitle(1, title)}
            onClickDislike={title => updateLikeTitle(2, title)}
            onClickAdd={title => onClickAddTitle(title)}
          >
            {title => (
              <p className="text-sm font-medium text-gray-900">
                {title.output}
              </p>
            )}
          </TabItems>
        </div>
      </div>
      <div className="mt-6 mb-6 btn-generate">
        <button
          className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700 hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          disabled={titlesLoading}
          onClick={() => {
            generateTitles();
          }}
        >
          {' '}
          {titlesLoading && (
            <svg
              width="16"
              height="16"
              fill="currentColor"
              className="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
            </svg>
          )}
          {titles.length > 0 ? 'Generate more' : 'Generate'}
        </button>
      </div>
    </div>
  );
}
