import React from 'react';
import Modal from 'react-modal';
import checkmark from '../../styles/images/checkmark-outline.svg';
import close from '../../styles/images/close-outline.svg';
import './PlagiarismModal.scss';
import cancelIcon from '../../styles/images/cancel-black.svg';

const Content = ({ img, text, func }) => (
  <>
    <img src={img} alt="checkmark" />
    <h3 className="plagiarism-modal-title">{text} found!</h3>
    <p className="plagiarism-modal-subtext">Powered by Copyscape</p>
    <button className="btn btn-white plagiarism-modal-btn" onClick={func}>
      Ok
    </button>
  </>
);

const PlagiarismModal = ({
  plagiarismData,
  isOpenModal,
  closeModal,
  addPlagiarism,
}) => {
  return (
    <Modal
      className="plagiarism-modal"
      isOpen={isOpenModal !== ''}
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      <img
        src={cancelIcon}
        alt="cancel button"
        className="cancel-button"
        onClick={closeModal}
      />
      {isOpenModal === 'no' ? (
        <Content img={checkmark} text="No plagiarism" func={closeModal} />
      ) : (
        <Content img={close} text="Plagiarism" func={closeModal} />
      )}
      {plagiarismData.length > 0
        ? plagiarismData.map((data, index) => (
            <div className="plagiarism-results">
              <p className="plagiarism-modal-title">{data.title}</p>
              <p className="plagiarism-modal-subtext">{data.textmatched}</p>
              <span className="plagiarism-modal-link">
                Source:{' '}
                <a
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                  alt={data.textmatched}
                >
                  {data.url}
                </a>
              </span>
              <br />
              <span className="plagiarism-modal-matched-word">
                Words Matched: {data.wordsmatched}
              </span>
              <span className="plagiarism-modal-percentage-word">
                Percentage Matched: {data.percentmatched}%
              </span>
            </div>
          ))
        : null}
    </Modal>
  );
};

export default PlagiarismModal;
