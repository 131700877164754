import { menuActionTypes } from '../reducers/menu.reducer';

export default function collapse(isCollapsed) {
  let collapsed = isCollapsed.toString();
  localStorage.setItem('isCollapsed', collapsed);
  return createAction(collapsed);
}

function loadCollapseState() {
  let isCollapsed = localStorage.getItem('isCollapsed');
  return createAction(isCollapsed);
}

function createAction(isCollapsed) {
  if (isCollapsed === 'true') {
    return {
      type: menuActionTypes.HIDE_MENU,
    };
  } else {
    return {
      type: menuActionTypes.SHOW_MENU,
    };
  }
}

export { loadCollapseState };