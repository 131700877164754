import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, PlusSmIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import Tooltip from '@material-ui/core/Tooltip';

import useSentData from '../../services/useSentData';
import CustomStatuses from '../../components/settings/custom-statuses/custom-statuses';
import AddProjectMemberModal from './ProjectMemberModal';
import { createProject, editProject } from '../../store/actions/project.action';
import { isolateError } from '../../utils/api';
import { validateURLExist } from '../../services/Validator';
import defaultAvatar from '../../styles/images/default-avatar.svg';
import LoadingIcon from '../../components/LoadingIcon';

function CreateProjectSlideOver(props) {
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [projectUrl, setProjectUrl] = useState('');
  const [brandVoice, setBrandVoice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingBrandVoice, setIsGeneratingBrandVoice] = useState(false);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [brandVoiceCount, setBrandVoiceCount] = useState(0);
  const alert = useAlert();
  const request = useSentData();

  useEffect(() => {
    if (props.open === false) {
      resetFields();
    }
  }, [props.open]);

  useEffect(() => {
    if (props.project) {
      setFieldsFromProject(props.project);
    } else {
      resetFields();
    }
  }, [props.project]);

  const resetFields = () => {
    setDescription('');
    setProjectName('');
    setProjectUrl('');
    setBrandVoice('');
    setErrorMessage('');
    setDescriptionCount(0);
    setBrandVoiceCount(0);
  };

  const setFieldsFromProject = project => {
    setDescription(project.description || '');
    setProjectName(project.name || '');
    setProjectUrl(project.project_url || '');
    setBrandVoice(project.brand_voice || '');
    setDescriptionCount((project.description || '').length);
    setBrandVoiceCount((project.brand_voice || '').length);
  };

  const handleFieldChange = (setter, maxLength, countSetter) => event => {
    const value = event.target.value.slice(0, maxLength);
    setter(value);
    if (countSetter) {
      countSetter(value.length);
    }
  };

  const isValidUrl = url => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    url = url.trim();
    try {
      new URL(url);
      return urlRegex.test(url);
    } catch (error) {
      return false;
    }
  };

  const handleGenerateBrandVoice = () => {
    if (!projectUrl) {
      setErrorMessage('Project URL is required to generate brand voice');
      return;
    }
    if (!projectUrl || !isValidUrl(projectUrl)) {
      setErrorMessage(
        'Invalid project URL format! Example: https://www.example.com'
      );
      return;
    }

    setIsGeneratingBrandVoice(true);
    request
      .send('/api/generate-brand-voice', { websiteUrl: projectUrl })
      .then(response => {
        if (!response.isAxiosError) {
          setBrandVoice(response.content.text);
        } else {
          alert.error(isolateError(response));
        }
      })
      .finally(() => setIsGeneratingBrandVoice(false));
  };

  const removeMember = userId => {
    request
      .send(
        `/api/projects/${props.project.id}/remove-member`,
        { userId },
        'post'
      )
      .then(() => {
        props.fetchProjects(() => {
          alert.success('Member removed from project', {
            position: 'top center',
          });
          props.setOpen(false);
        });
      })
      .catch(error => {
        alert.error(isolateError(error), { position: 'top center' });
      });
  };

  const saveProject = () => {
    if (!projectName) {
      setErrorMessage('Project name is required');
      return;
    }
    if (projectUrl && validateURLExist(projectUrl)) {
      setErrorMessage('Invalid project URL!');
      return;
    }
    if (projectName && projectName.length > 3) {
      const projectData = {
        name: projectName,
        description,
        project_url: projectUrl,
        brand_voice: brandVoice,
      };

      const endpoint = props.project
        ? `/api/projects/${props.project.id}`
        : '/api/projects';
      const method = props.project ? 'put' : 'post';

      request.send(endpoint, projectData, method).then(data => {
        if (!data.isAxiosError) {
          props.project ? props.editProject(data) : props.createProject(data);
          props.setOpen(false);
        } else {
          alert.error(isolateError(data));
        }
      });
    }
  };

  const renderField = (
    label,
    id,
    value,
    onChange,
    type = 'text',
    extraProps = {},
    charCount = null
  ) => (
    <div>
      <div className="flex justify-between items-center">
        <label htmlFor={id} className="block text-sm font-medium text-gray-900">
          {label}
        </label>
        {charCount !== null && (
          <span className="text-sm text-gray-500">
            {charCount}/{extraProps.maxLength}
          </span>
        )}
      </div>
      <div className="mt-1">
        {type === 'textarea' ? (
          <textarea
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
            {...extraProps}
          />
        ) : (
          <input
            type={type}
            name={id}
            id={id}
            value={value}
            onChange={onChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
            {...extraProps}
          />
        )}
      </div>
    </div>
  );

  const renderMembers = () =>
    props.project && (
      <div>
        <h3 className="text-sm font-medium text-gray-900">Team Members</h3>
        <div className="mt-2 flex space-x-2">
          {/* Render project owner */}
          <MemberAvatar member={props.project.user} />

          {/* Render other members */}
          {props.project.members.map(person => (
            <MemberAvatar
              key={person.member.email}
              member={person.member}
              onRemove={() => removeMember(person.member.id)}
            />
          ))}

          {/* Add member button */}
          <button
            onClick={() => {
              props.setProjectIdSelect(props.project?.id);
              props.setOpenAddProjectMemberModal(true);
              props.setOpen(false);
            }}
            type="button"
            className="flex-shrink-0 bg-white inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <span className="sr-only">Add team member</span>
            <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    );

  const MemberAvatar = ({ member, onRemove }) => (
    <div className="relative inline-block rounded-full hover:opacity-75">
      <Tooltip title={member.email} arrow>
        <img
          className="inline-block h-10 w-10 rounded-full"
          src={member.preview || defaultAvatar}
          alt={member.full_name}
        />
      </Tooltip>
      {onRemove && (
        <Tooltip title="remove" arrow>
          <XCircleIcon
            onClick={onRemove}
            className="h-6 w-6 text-red-600 absolute ring-white -top-2.5 -right-2.5 cursor-pointer"
            aria-hidden="true"
          />
        </Tooltip>
      )}
    </div>
  );

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={props.setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <form className="h-full flex flex-col bg-white shadow-xl">
                  <div className="flex-1 flex flex-col overflow-y-auto">
                    {/* Sticky header */}
                    <div className="sticky top-0 z-10">
                      <div className="py-6 px-4 bg-purple-700 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {props.project ? 'Edit Project' : 'New Project'}
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="bg-purple-700 rounded-md text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => props.setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-purple-300">
                            Get started by filling in the information below to
                            create or update your project.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Scrollable content */}
                    <div className="flex-1 px-4 sm:px-6">
                      <div className="space-y-6 pt-6 pb-5">
                        {errorMessage && (
                          <p className="text-red-600">{errorMessage}</p>
                        )}
                        {renderField(
                          'Project name *',
                          'project-name',
                          projectName,
                          handleFieldChange(setProjectName),
                          'text',
                          {
                            minLength: 3,
                            maxLength: 100,
                            placeholder: 'Enter your project name',
                          }
                        )}
                        {renderField(
                          'Project URL',
                          'project-url',
                          projectUrl,
                          handleFieldChange(setProjectUrl),
                          'text',
                          { placeholder: 'https://www.example.com' }
                        )}
                        {renderField(
                          'Description',
                          'description',
                          description,
                          handleFieldChange(
                            setDescription,
                            500,
                            setDescriptionCount
                          ),
                          'textarea',
                          {
                            rows: 4,
                            minLength: 3,
                            maxLength: 500,
                            placeholder: 'Describe your project here...',
                          },
                          descriptionCount
                        )}
                        {renderField(
                          'Brand Voice',
                          'brand-voice',
                          brandVoice,
                          handleFieldChange(
                            setBrandVoice,
                            2000,
                            setBrandVoiceCount
                          ),
                          'textarea',
                          {
                            rows: 6,
                            maxLength: 2000,
                            placeholder:
                              'Describe your brand voice here or generate it from your website...',
                          },
                          brandVoiceCount
                        )}
                        <div className="mt-2">
                          <button
                            type="button"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            onClick={handleGenerateBrandVoice}
                            disabled={isGeneratingBrandVoice}
                          >
                            {isGeneratingBrandVoice ? (
                              <>
                                <LoadingIcon />
                                <span>Generating...</span>
                              </>
                            ) : (
                              'Generate Brand Voice'
                            )}
                          </button>
                        </div>
                        {renderMembers()}
                        {props.project && (
                          <>
                            <div className="border-b-2 border-gray-100" />
                            <CustomStatuses projectId={props.project.id} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Footer buttons */}
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      onClick={() => props.setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      onClick={saveProject}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default connect(null, { createProject, editProject })(
  CreateProjectSlideOver
);
