import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import DashboardItem from './Dashboard/DashboardItem';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import titleImage from '../styles/images/bramework-titles-image.svg';
import TitlesList from './Dashboard/TitlesList';
import Pagination from '../components/Pagination';
import { useParams } from 'react-router-dom';
import { escapeRegExp } from '../utils/common';
import { connect } from 'react-redux';
import GridPagination from '../components/grid/grid-pagination';

function Titles({ isListView }) {
  const request = useSentData();
  const { project_id } = useParams();
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState();
  const [gridView, setGridView] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  function updatePosts(page = 1) {
    request
      .send(`/api/projects/${project_id}/posts/titles`, null, 'get', {
        page: page,
      })
      .then(data => {
        if (!data.isAxiosError) {
          setPosts(data);
        }
      });
  }

  function searchPost(text) {
    setSearchText(text);
    if (text.length > 0) {
      const search = new RegExp(escapeRegExp(text), 'i');
      var results = posts?.results?.filter(function(post) {
        return search.test(post.phrase);
      });
      setFilteredPosts(results);
    } else {
      setFilteredPosts(null);
      setPosts(posts);
    }
  }

  const onToggleSelectItem = (id, checked) => {
    let items = [...selectedItems];
    if (checked) {
      items.push(id);
    } else {
      items = items.filter(item => item !== id);
    }
    setSelectedItems(items);
  };

  const onToggleSelectAllItems = checked => {
    let items = filteredPosts?.map(item => item.id);
    if (checked) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  const onBulkDelete = () => {
    const newPosts = { ...posts };
    newPosts.results = newPosts.results.filter(
      item => selectedItems.indexOf(item.id) < 0
    );
    setPosts(newPosts);
    setSelectedItems([]);
  };

  useEffect(updatePosts, []);

  return (
    <NewWrapper
      header={
        <NewHeader
          displaySearch={true}
          searchText={searchText}
          searchPost={searchPost}
          isBulkSelect={isBulkSelect}
          setIsBulkSelect={setIsBulkSelect}
          selectedItems={selectedItems}
        />
      }
      pageHeading={
        <PageHeading
          title="🎯 Generate Catchy Titles"
          category="title"
          gridView={gridView}
          setGridView={setGridView}
          selectedProjectId={project_id}
          isBulkSelect={isBulkSelect}
          setIsBulkSelect={setIsBulkSelect}
          selectedItems={selectedItems}
          onBulkDelete={onBulkDelete}
        />
      }
    >
      {!isListView && (
        <div className="posts-list drafts cards-wrapper">
          {request.loading || !request.success ? (
            <Loading />
          ) : posts?.results?.length ? (
            <div className="posts-wrapper">
              <h4>Titles</h4>
              {(filteredPosts ?? posts?.results).map(post => (
                <DashboardItem
                  key={post.id}
                  id={post.id}
                  url={post.domain}
                  name={post.phrase}
                  thumbnail={titleImage}
                  date={post.edited_at}
                  body={post.body}
                  results={post.analysis_data.results}
                  difficulty={post.analysis_data.difficulty}
                  competition={post.analysis_data.competition}
                  volume={post.analysis_data.volume}
                  category={post.category}
                  published={post.published}
                  generations={post.titles}
                  callback={updatePosts}
                  post={post}
                  isSelected={
                    selectedItems.findIndex(item => item === post.id) >= 0
                  }
                  onToggleSelectItem={onToggleSelectItem}
                  isBulkSelect={isBulkSelect}
                />
              ))}
              <GridPagination
                startIndex={(posts.current_page - 1) * posts.page_size + 1}
                endIndex={
                  posts.current_page * posts.page_size < posts.count
                    ? posts.current_page * posts.page_size
                    : posts.count
                }
                total={posts.count}
                isPreviousDisabled={!posts.previous_page}
                isNextDisabled={!posts.next_page}
                onPrevious={() => updatePosts(posts.previous_page)}
                onNext={() => updatePosts(posts.next_page)}
              />
            </div>
          ) : (
            <h1 className="empty-message">You have no generated titles.</h1>
          )}
        </div>
      )}
      {isListView && (
        <div>
          {request.loading || !request.success ? (
            <Loading />
          ) : posts?.results?.length ? (
            <div>
              <h3 className="text-lg mt-8 leading-6 font-medium text-gray-900">
                Titles
              </h3>
              <TitlesList
                posts={filteredPosts ?? posts?.results}
                callback={updatePosts}
                response={posts}
                selectedItems={selectedItems}
                onToggleSelectItem={onToggleSelectItem}
                onToggleSelectAllItems={onToggleSelectAllItems}
                isBulkSelect={isBulkSelect}
              />
            </div>
          ) : (
            <div className="posts-list drafts cards-wrapper">
              <h1 className="empty-message">You have no generated titles.</h1>
            </div>
          )}
        </div>
      )}
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    isListView: state.layout.isListView,
  };
}

export default connect(mapStateToProps, null)(Titles);
