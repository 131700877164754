/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function RegionFormSelect({
  selectedRegion,
  regions,
  setSelectedRegion,
}) {
  // const [selected, setSelected] = useState(
  //   selectedRegion ? selectedRegion : regions[0]
  // );

  // useEffect(() => {
  //   setSelectedRegion(selected.value);
  // }, [selected]);
  return (
    <Listbox value={selectedRegion} onChange={setSelectedRegion}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change published status
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex rounded-md divide-x divide-gray-300">
              <div className="relative z-0 inline-flex rounded-md divide-x divide-gray-300">
                <div className="relative inline-flex items-center py-3 pl-1 pr-3 border border-transparent rounded-l-md text-gray-500">
                  {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                  <p className="ml-2.5 text-sm font-medium">
                    {selectedRegion?.label}
                  </p>
                </div>
                <Listbox.Button className="relative inline-flex items-center p-2 rounded-l-none rounded-r-md text-sm font-medium text-white focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                  <span className="sr-only">Change database region</span>
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right h-88 absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-scroll bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {regions.map(option => (
                  <Listbox.Option
                    key={option.label}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-purple-500' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {option.label}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? 'text-white' : 'text-purple-500'
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
