const teamActionType = {
  FETCH_TEAM: 'FETCH_TEAM',
  REMOVE_TEAM: 'REMOVE_TEAM',
  TEAM_REQUEST: 'TEAM_REQUEST',
  TEAM_FAILURE: 'TEAM_FAILURE',
  TEAM_DESTROY: 'TEAM_DESTROY',
};

const initialState = {
  team: [],
  loading: false,
  error: false,
  errorMsg: null,
};

const TeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case teamActionType.TEAM_DESTROY:
      return { ...initialState };
    case teamActionType.FETCH_TEAM:
      return {
        ...state,
        team: action.payload,
        loading: false,
        error: false,
        errorMsg: null,
      };
    case teamActionType.REMOVE_TEAM:
      return {
        ...state,
        team: state.team.filter(team => team.id !== action.payload),
        loading: false,
        error: false,
        errorMsg: null,
      };
    case teamActionType.TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teamActionType.TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default TeamReducer;
export { teamActionType };
