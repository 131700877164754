import { sidePanelActionTypes } from '../reducers/side_panel.reducer';

export const sidePanelTypes = {
  DEFAULT: 1,
  PLAGIARISM: 2,
  CHAT: 3,
  FACT_CHECKER: 4,
};
export default function panelCollapse(sidePanelValue, isPanelCollapsed) {
  let isPanelCollapsedStr = isPanelCollapsed.toString();
  localStorage.setItem('sidePanelValue', sidePanelValue);
  localStorage.setItem('isPanelCollapsed', isPanelCollapsedStr);
  return createAction(sidePanelValue, isPanelCollapsed);
}

function loadPanelCollapseState() {
  let sidePanelValue = localStorage.getItem('sidePanelValue');
  let isPanelCollapsed = localStorage.getItem('isPanelCollapsed');
  return createAction(
    sidePanelValue,
    isPanelCollapsed == 'true' ? true : false
  );
}

function setSidePanelOpenTab(openTab) {
  return dispatch => {
    dispatch({ type: sidePanelActionTypes.SET_SIDE_PANEL_OPEN_TAB, openTab });
  };
}

function createAction(sidePanelValue, isPanelCollapsed) {
  if (!isPanelCollapsed) {
    switch (sidePanelValue) {
      case sidePanelTypes.DEFAULT:
        return {
          type: sidePanelActionTypes.SHOW_SIDE_PANEL,
        };
      case sidePanelTypes.PLAGIARISM:
        return {
          type: sidePanelActionTypes.SHOW_PLAGIARISM_PANEL,
        };
      case sidePanelTypes.CHAT:
        return {
          type: sidePanelActionTypes.SHOW_CHAT_PANEL,
        };
      case sidePanelTypes.FACT_CHECKER:
        return {
          type: sidePanelActionTypes.SHOW_FACT_CHECKER_PANEL,
        };
      default:
        return {
          type: sidePanelActionTypes.SHOW_SIDE_PANEL,
        };
    }
  } else {
    return {
      type: sidePanelActionTypes.HIDE_SIDE_PANEL,
    };
  }
}

export { loadPanelCollapseState, setSidePanelOpenTab };
