const roleActionType = {
  FETCH_ROLE: 'FETCH_ROLE',
  ROLE_LOADING: 'ROLE_LOADING',
  ROLE_DESTROY: 'ROLE_DESTROY',
};

const initialState = {
  roles: [],
  loading: false,
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleActionType.ROLE_DESTROY:
      return { ...initialState };
    case roleActionType.ROLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case roleActionType.FETCH_ROLE:
      return {
        ...state,
        roles: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default rolesReducer;
export { roleActionType };
