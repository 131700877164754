import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { track } from '../../services/track';
import { addGeneratedPostOutlines } from '../../store/actions/post_builder.actions';
import { updateUser } from '../../store/actions/auth.actions';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { processHTML } from '../../utils/processHTML';
import { sortListByDate } from '../../utils/common';
import TabItems from './TabItems';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import {
  setNovaOnBoardingCheckList,
  setNovaOnBoardingStep,
} from '../../store/actions/nova_onboarding.action';

function OutlinesTab({ editorRef, updateUser }) {
  const request = useSentData();
  let { id, project_id } = useParams();
  const [outlines, setOutlines] = useState([]);
  const [outlinesLoading, setOutlinesLoading] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const generatedPostOutlines = useSelector(
    state => state.post.generatedOutlines
  );
  const postId = useSelector(state => state.post.id);
  if (!id && postId) {
    id = postId;
  }
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const outlineType = useSelector(state => state.post.outlineType);
  const tone = useSelector(state => state.post.tone);
  const instructions = useSelector(state => state.post.instructions);
  const additionalKeywordsButtonOn = useSelector(
    state => state.post.additionalKeywordsButtonOn
  );
  const instructionButtonOn = useSelector(
    state => state.post.instructionButtonOn
  );
  const user = useSelector(state => state.auth.user);
  const detailedOutline = useSelector(
    state => state.auth.user.detailed_outline
  );
  const selectedKeywords = useSelector(state => state.post.selectedKeywords);

  useEffect(() => {
    sortListByDate(generatedPostOutlines, 'created_at');
    setOutlines(generatedPostOutlines);
  }, [generatedPostOutlines]);

  function generateOutlines() {
    setOutlinesLoading(true);
    track('Blog post creator', { Clicked: 'generate outline' });
    let endpoint = 'generate-outline-standard';
    if (user?.faq_style_outline && detailedOutline) {
      endpoint = 'generate-outline-detailed';
    }
    request
      .send(`/api/projects/${project_id}/posts/${postId}/${endpoint}`, {
        tone: tone.value,
        instructions: instructionButtonOn ? instructions : '',
        additional_keywords: {
          selected: additionalKeywordsButtonOn,
          keywords: additionalKeywordsButtonOn
            ? selectedKeywords.split(',')
            : [],
        },
        outlineType: outlineType.value,
      })
      .then(response => {
        setOutlinesLoading(false);
        if (!response.isAxiosError) {
          if (response.success && response.content && response.content.length) {
            sortListByDate(response.content, 'created_at');
            response.content[0].isNewItem = true;
            dispatch(addGeneratedPostOutlines(response.content));
            document.getElementsByClassName('tab-content')[0]?.scrollTo(0, 0);
            setTimeout(() => {
              dispatch(
                addGeneratedPostOutlines(
                  response.content.map(item => ({ ...item, isNewItem: false }))
                )
              );
            }, 4000);
          } else {
            alert.error(response.message);
          }
        } else {
          alert.error(isolateError(response));
        }
      });
  }

  function savesOutlines(data) {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'put';
    if (id) {
      url += `/${id}`;
      request.send(url, { outlines: data }, method).then(response => {
        if (!response.isAxiosError) {
          dispatch(addGeneratedPostOutlines(response.outlines));
        } else {
          alert.error(isolateError(response));
        }
      });
    } else {
      alert.error('Post id does not exist');
    }
  }

  function switchOutline() {
    request
      .send(
        `/api/users/${user.id}`,
        {
          detailed_outline: !detailedOutline,
        },
        'patch'
      )
      .then(res => {
        if (!res.isAxiosError) {
          const updateData = {
            detailed_outline: res.detailed_outline,
          };
          updateUser(updateData);
        } else {
          alert.error(isolateError(res));
        }
      });
  }

  const updateLikeOutline = (status, outline) => {
    const method = 'post';
    if (outline?.like_status && outline?.like_status[0]?.status === status) {
      status = null;
    }
    const url = status ? `/api/like-outline` : '/api/like-outline-remove';
    const updatedData = { generation_id: outline.generation_id, status };
    if (outline.like_status) {
      outline.like_status[0].status = status;
    } else {
      outline.like_status = [{ status }];
    }
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onDeleteOutline = (generation_id, index) => {
    const url = `/api/delete-outline`;
    const method = 'post';
    const updatedData = { generation_id };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newOutlines = [...outlines];
          newOutlines.splice(index, 1);
          setOutlines(newOutlines);
          dispatch(addGeneratedPostOutlines(newOutlines));
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickCopy = outline => {
    navigator.clipboard.writeText(outline.output.text);
    alert.success('Successfully copied to clipboard!');
  };

  const onClickAdd = outline => {
    if (editorRef?.current?.editor) {
      if (
        outline.output.html.startsWith('<p>') &&
        outline.output.html.endsWith('</p>')
      ) {
        editorRef?.current?.editor?.insertContent(
          processHTML(outline.output.html)
        );
      } else {
        editorRef?.current?.editor?.insertContent(outline.output.html);
      }
      dispatch(
        setNovaOnBoardingCheckList(novaOnBoardingCheckLists.GENERATE_AN_OUTLINE)
      );
      if (
        stepNovaOnBoardingGuide === novaOnBoardingSteps.WRITE_A_FIRST_DRAFT ||
        stepNovaOnBoardingGuide === novaOnBoardingSteps.GENERATE_AN_OUTLINE
      ) {
        dispatch(
          setNovaOnBoardingStep(novaOnBoardingSteps.COMPLETE_CHECK_LIST)
        );
      }
    }
  };

  return (
    <div className="tool-tab">
      <div className="overflow-y-auto tab-content">
        <div className="flow-root divide-y divide-gray-200">
          <div className="my-6 w-70 sm:w-116">
            <Switch.Group
              as="div"
              className="flex items-center justify-between"
            >
              <span className="flex-grow flex flex-col">
                <Switch.Label
                  as="span"
                  className="font-semibold text-gray-900"
                  passive
                >
                  FAQ style outline{' '}
                  {!user.faq_style_outline && (
                    <Link
                      className="ml-2 text-xs font-medium text-purple-600 hover:text-purple-500"
                      to={
                        user.app_sumo_subscription
                          ? [
                              'bramework_tier1',
                              'bramework_tier2',
                              'bramework_tier3',
                              'bramework_tier4',
                            ].includes(
                              user.app_sumo_subscription.plan.app_sumo_plan
                            )
                            ? '/appsumo-plan'
                            : user.app_sumo_subscription.plan.app_sumo_plan ===
                              'bramework_ltd1'
                            ? '/bramework-ltd-plan'
                            : user.app_sumo_subscription.plan.app_sumo_plan ===
                              'free_user'
                            ? '/account'
                            : '/plans'
                          : '/plans'
                      }
                    >
                      Upgrade to access
                    </Link>
                  )}
                </Switch.Label>
                <Switch.Description as="span" className="text-xs text-gray-500">
                  Introduction and conclusion with headers as questions and
                  answers.
                </Switch.Description>
              </span>{' '}
              <Switch
                checked={
                  !user?.faq_style_outline ? false : detailedOutline ?? false
                }
                disabled={!user?.faq_style_outline}
                onChange={switchOutline}
                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    user?.faq_style_outline && detailedOutline
                      ? 'bg-purple-600'
                      : 'bg-gray-200',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                  )}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    user?.faq_style_outline && detailedOutline
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <TabItems
            dataList={outlines}
            onClickCopy={onClickCopy}
            onClickDelete={(title, index) =>
              onDeleteOutline(title.generation_id, index)
            }
            onClickLike={title => updateLikeOutline(1, title)}
            onClickDislike={title => updateLikeOutline(2, title)}
            onClickAdd={onClickAdd}
          >
            {outline => (
              <div
                className="w-80 sm:w-116 text-sm font-medium text-gray-900"
                dangerouslySetInnerHTML={{ __html: outline.output.html }}
              />
            )}
          </TabItems>
        </div>
      </div>
      <div className="mt-6 mb-6 btn-generate">
        <button
          className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700 hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={() => generateOutlines()}
          disabled={outlinesLoading}
        >
          {outlinesLoading && (
            <svg
              width="16"
              height="16"
              fill="currentColor"
              className="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
            </svg>
          )}
          {outlines.length > 0 ? 'Generate more' : 'Generate'}
        </button>
      </div>
    </div>
  );
}

export default connect(null, { updateUser })(OutlinesTab);
