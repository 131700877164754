const TablePagination = ({
  startIndex = 1,
  endIndex = 10,
  total = 20,
  isPreviousDisabled = false,
  isNextDisabled = false,
  onPrevious = () => {},
  onNext = () => {},
}) => {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{startIndex}</span> to{' '}
          <span className="font-medium">{endIndex}</span> of{' '}
          <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <p
          className={`relative inline-flex cursor-pointer select-none items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            isPreviousDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => {
            if (!isPreviousDisabled) onPrevious();
          }}
        >
          Previous
        </p>
        <p
          className={`relative ml-3 inline-flex cursor-pointer select-none items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
          } `}
          onClick={() => {
            if (!isNextDisabled) onNext();
          }}
        >
          Next
        </p>
      </div>
    </nav>
  );
};

export default TablePagination;
