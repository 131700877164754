import { teamActionType } from '../reducers/team.reducer';
import http from '../../services/http';

export const destroyTeam = () => {
  return { type: teamActionType.TEAM_DESTROY };
};

const fail = payload => ({
  type: teamActionType.TEAM_FAILURE,
  payload,
});

export const startLoading = () => ({
  type: teamActionType.TEAM_REQUEST,
});

export const deleteTeamMember = member_id => {
  const success = payload => ({
    type: teamActionType.REMOVE_TEAM,
    payload,
  });
  return dispatch => {
    http
      .delete(`/api/team/${member_id}`, null)
      .then(tokenRes => dispatch(success(member_id)))
      .catch(error => dispatch(fail(error)));
  };
};

export const fetchTeamList = () => {
  const success = payload => ({
    type: teamActionType.FETCH_TEAM,
    payload,
  });
  return dispatch => {
    http
      .get('/api/team', null)
      .then(tokenRes => dispatch(success(tokenRes.data)))
      .catch(err => dispatch(fail(err)));
  };
};
