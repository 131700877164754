export default function(rules) {
  return {
    validate: function(name, data) {
      if (!rules || !name) {
        return true;
      }

      if (!data) {
        return false;
      }

      const rule = rules[name];
      if (!rule) {
        return true;
      }

      this.errors[name] = [];
      for (let validate of rule) {
        const error = validate(data[name], data, name);
        if (error) {
          this.errors[name].push(error);
        }
      }

      if (this.errors[name].length === 0) {
        delete this.errors[name];
        return true;
      } else {
        return false;
      }
    },
    validateAll: function(data) {
      let result = {};
      for (let rule in rules) {
        if (rules.hasOwnProperty(rule)) {
          result[rule] = this.validate(rule, data);
        }
      }
      return result;
    },
    errors: {},
    isValid: function() {
      return Object.entries(this.errors).length === 0;
    },
  };
}

function required(value) {
  if (!value) {
    return `Field is required.`;
  }
}

function seted(value) {
  if (!value) {
    return `Field must be set.`;
  }
}

function email(value) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = re.test(String(value).toLowerCase());
  if (!result) {
    return `Field must be a valid email.`;
  }
}

function full_name(value) {
  // eslint-disable-next-line no-useless-escape
  const re = /^[A-Za-z\s-]+$/;
  const result = re.test(String(value).toLowerCase());
  if (!result) {
    return `Only letters are allowed in full name field.`;
  }
}

function url(value) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const result = re.test(String(value).toLowerCase());
  if (!result) {
    return `Field must be a valid url.`;
  }
}

function validateURLExist(value) {
  // eslint-disable-next-line no-useless-escape
  if (value) {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const result = re.test(String(value).toLowerCase());
    if (!result) {
      return `Field must be a valid url.`;
    }
  }
}

function phone(value) {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\. ]?[0-9]{3}[-\s\. ]?[0-9]{3}[-\s\. ]?[0-9]{2,6}$/im;
  const result = re.test(String(value).toLowerCase());
  if (!result) {
    return `Field must be a valid phone.`;
  }
}

function min(num) {
  return function(value) {
    if (!value || value.length < num) {
      return `Field must be at least ${num} characters.`;
    }
  };
}

function max(num) {
  return function(value) {
    if (!value || value.length > num) {
      return `Field must be more then ${num} characters.`;
    }
  };
}

function confirm(name) {
  return function(value, data) {
    if (!data || value !== data[name]) {
      return 'The confirmation does not match.';
    }
  };
}

export {
  required,
  email,
  min,
  max,
  confirm,
  seted,
  phone,
  url,
  full_name,
  validateURLExist,
};
