import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';

export default function AccessDenied() {
  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={
        <PageHeading title="Access Denied" category="Access Denied" />
      }
    >
      <div className="posts-list drafts cards-wrapper">
        <div className="mt-3">
          <p className=" text-xl">
            You don't have permission to access that Page.
          </p>
        </div>
      </div>
    </NewWrapper>
  );
}
