import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chat from '../pages/Chat';
import {
  loadConversations,
  loadFavoritePrompts,
  setActiveChatId,
  setChatMessage,
  setConnectToTheWeb,
  setLookInTheEditor,
  setMessages,
  setPanelMode,
} from '../store/actions/chat.actions';
import {
  PlusIcon,
  SparklesIcon,
  XIcon,
  StarIcon,
  ClockIcon,
  CogIcon,
} from '@heroicons/react/outline';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import Tooltip from '@material-ui/core/Tooltip';
import { Popover, Switch } from '@headlessui/react';

const ChatMemo = React.memo(Chat);

const ChatPanel = React.forwardRef((props, ref) => {
  const { sidePanelValue, isPanelCollapsed } = useSelector(
    state => state.panel
  );
  const dispatch = useDispatch();
  const connectToTheWeb = useSelector(state => state.chat.connectToTheWeb);
  const conversations = useSelector(state => state.chat.conversations);
  const favoritePrompts = useSelector(state => state.chat.favoritePrompts);
  const lookInTheEditor = useSelector(state => state.chat.lookInTheEditor);
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );
  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );

  const suggestions = [
    'Conduct in-depth research on [keyword] and provide a concise, detailed report',
    'Could you analyze this keyword [keyword]',
    'What are some related keywords for [keyword]',
    'Could you suggest keywords for [keyword]',
    'Write a blog outline for me about [keyword]',
    "Can you check if my blog post on [keyword] is SEO optimized? Here's the post: [URL]",
    'Which websites are the main competitors of this site [URL]',
    'What keywords are driving traffic to this site [URL]',
    'Extract the LSI keywords from this post: [URL]',
    'Get the highest-ranking search results for [keyword]',
    'What questions do people ask about [keyword]',
    'Please fact-check this information: [Information]',
    'Create a blog post from this YouTube Video: [URL]',
    'Can you extract the main points from this YouTube Video: [URL]',
    // 'What topics should I blog about today? My website is [URL]',
    // 'Is the topic [keyword] good for me to blog about?',
    // 'Should I write about [keyword] in my blog post?',
    // 'Tell me the keywords I should use in my blog post about [keyword]',
    // 'Write a content brief for me about [keyword] my website is [URL]',
    // 'Please check if my content is optimized for SEO, my keyword is [keyword], my blog post is [URL]',
    // 'How many people visited my website today?',
    // 'How many times did people visit my website this week?',
    // 'How many times did people visit my website this month?',
    // 'What are the top 10 search words people use to find my blog?',
    // 'Which 10 articles on my blog are the most popular?',
    // 'Which 5 articles should I share more to get more readers?',
    // 'Which 3 articles need some changes or updates?',
  ];

  useEffect(() => {
    dispatch(loadFavoritePrompts());
    dispatch(loadConversations());
    dispatch(setChatMessage(''));
    dispatch(setActiveChatId(''));
    dispatch(setMessages([]));
    dispatch(setPanelMode(true));

    return () => {
      dispatch(setPanelMode(false));
    };
  }, []);

  const handleNewChatClick = () => {
    dispatch(setActiveChatId(''));
    dispatch(setChatMessage(''));
    dispatch(setMessages([]));
  };

  return (
    <div className="" id="side-panel">
      <div className="w-full fixed h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
        <div>
          <div className="flex items-center md:items-start justify-between px-6 pt-6">
            <div className="text-lg font-medium text-gray-900">Chat</div>
            <div className="md:absolute sm:ml-112 h-1 sm:h-4 flex items-center">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-500"
                onClick={() => {
                  const panel =
                    isPanelCollapsed === false &&
                    sidePanelValue === sidePanelTypes.PLAGIARISM
                      ? false
                      : !isPanelCollapsed;
                  dispatch(panelCollapse(sidePanelTypes.CHAT, panel));
                }}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="flex mt-5 gap-4 border-b bordẻ-gray-200 px-4">
            <Tooltip
              title="New Chat"
              arrow
              onClick={() => handleNewChatClick()}
            >
              <button className="py-1 px-2 select-none h-full rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <PlusIcon className="h-4 w-4 cursor-pointer" />
              </button>
            </Tooltip>
            <Popover className="relative">
              <Popover.Button className="rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <Tooltip title="Suggested Prompts" arrow>
                  <div className="py-1 px-2 select-none h-full">
                    <SparklesIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-list overflow-y-auto">
                  {suggestions.map(item => (
                    <div
                      onClick={() => dispatch(setChatMessage(item))}
                      className="p-2 hover:bg-gray-300 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <Tooltip title="Favorite Prompts" arrow>
                  <div className="py-1 px-2 select-none h-full">
                    <StarIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-list overflow-y-auto ">
                  {favoritePrompts.map(item => (
                    <div
                      onClick={() => dispatch(setChatMessage(item.content))}
                      className="p-2 hover:bg-gray-300 cursor-pointer"
                    >
                      {item.content}
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <Tooltip title="Chat History" arrow>
                  <div className="py-1 px-2 select-none h-full">
                    <ClockIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>
              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-list overflow-y-auto">
                  {conversations.map(item => (
                    <div
                      onClick={() => dispatch(setActiveChatId(item.id))}
                      className="p-4 hover:bg-gray-300 cursor-pointer"
                    >
                      {item.snippet}
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <Tooltip title="Settings" arrow>
                  <div className="py-1 px-2 select-none h-full">
                    <CogIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-list overflow-y-auto ">
                  <div className="flex gap-4 p-4 justify-between">
                    <span>Smart Web Navigator</span>
                    <Tooltip
                      title="Connect to the web, SEO, keywords, extracts, analyze and more"
                      arrow
                    >
                      <Switch
                        checked={connectToTheWeb}
                        // disabled={!webAccessEnabled || !smartWebNavigatorAccess}
                        disabled={
                          !webAccessEnabled &&
                          !smartWebNavigatorAccess &&
                          !hasTrialSubscription
                        }
                        onChange={value => dispatch(setConnectToTheWeb(value))}
                        className={`${
                          connectToTheWeb ? 'bg-purple-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <div
                          className={`${
                            connectToTheWeb ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                    </Tooltip>
                  </div>
                  <div className="flex gap-4 p-4 justify-between">
                    <span>Look in the editor</span>
                    <Tooltip title="Look in the editor" arrow>
                      <Switch
                        checked={lookInTheEditor}
                        onChange={value => dispatch(setLookInTheEditor(value))}
                        className={`${
                          lookInTheEditor ? 'bg-purple-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <div
                          className={`${
                            lookInTheEditor ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                    </Tooltip>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
          <ChatMemo editorRef={props.editorRef} />
        </div>
      </div>
    </div>
  );
});

export default ChatPanel;
