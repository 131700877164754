import React, { useEffect, useState } from 'react';
import { LinkIcon } from '@heroicons/react/solid';
import NewWrapper from '../../components/NewWrapper';
import NewHeader from '../../components/NewHeader';
import PageHeading from '../../components/PageHeading';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import useSentData from '../../services/useSentData';
import Select from '../../components/form/FormSelect';
import { useSelector } from 'react-redux';
import {
  destroyAccounts,
  fetchAccounts,
  selectAccount,
} from '../../store/actions/googe_analytics_account.actions';
import {
  fetchProperties,
  selectProperties,
  destroyProperties,
} from '../../store/actions/google_analytics_properties.action';
import GoogleAuthRedirect from '../../components/integrationsPlatform/GoogleAuthRedirect';
// import IntegrationsPlatform from '../components/integrationsPlatform/IntegrationsPlatform';
import AnalyticsStat from './AnalyticsStats';
import { numberToThousands } from '../../utils/common';
import TopViewedPages from './TopViewedPages';
import TopRankedPages from './TopRankedPages';
import Loading from '../../components/Loading';

function GoogleAnalyticsDisplay({
  user,
  googleAnalyticProperty,
  googleAnalyticAccount,
  fetchAccounts,
  selectAccount,
  selectProperties,
  fetchProperties,
}) {
  const projectUrl = useSelector(
    state =>
      state.project?.selectedProject?.project_url ||
      state.auth?.user?.company?.domain ||
      ''
  );
  const [analyticsData, setAnalyticsData] = useState(null);
  const [topRankedPagesData, setTopRankedPagesData] = useState(null);
  const [isLoadedData, setIsLoadedData] = useState(false);
  const request = useSentData();

  const isButtonDisabled =
    user?.app_sumo_subscription &&
    !user?.blog_booster_enabled &&
    !user?.blog_booster_access;

  const fetchTopRankedPages = () => {
    setIsLoadedData(true);
    const data = {
      siteUrl: projectUrl,
    };
    request
      .send(`/api/google-search-console/report`, data, 'post')
      .then(response => {
        setTopRankedPagesData(response);
        setIsLoadedData(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoadedData(false);
      });
  };

  useEffect(() => {
    if (googleAnalyticProperty?.selectedGoogleProperties) {
      fetchTopRankedPages();
    }
  }, [googleAnalyticProperty?.selectedGoogleProperties]);

  const fetchReport = () => {
    setIsLoadedData(true);
    const data = {
      propertyID: googleAnalyticProperty?.selectedGoogleProperties?.name,
      metrics: ['newUsers'],
      dimensions: ['region'],
    };
    request
      .send(`/api/google-analytic/report`, data, 'post')
      .then(data => {
        setAnalyticsData(data);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoadedData(false);
      });
  };

  useEffect(() => {
    if (user?.has_google_analytics) {
      fetchAccounts();
    }
  }, []);

  useEffect(() => {
    if (
      user?.has_google_analytics &&
      googleAnalyticAccount?.selectedGoogleAccount
    ) {
      fetchProperties(googleAnalyticAccount?.selectedGoogleAccount?.name);
    }
  }, [googleAnalyticAccount]);

  useEffect(() => {
    if (
      user?.has_google_analytics &&
      googleAnalyticAccount?.selectedGoogleAccount &&
      googleAnalyticProperty?.selectedGoogleProperties
    ) {
      // fetchProperties(googleAnalyticAccount?.selectedGoogleAccount);
      fetchReport();
    }
  }, [googleAnalyticProperty?.selectedGoogleProperties]);

  const reviewAnalytics = () => {
    const data = {
      analyticsData: analyticsData,
    };
    request
      .send(`/api/review-analytics`, data, 'post')
      .then(response => {
        console.log('Review Analytics Response:', response);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 mt-4 px-3">
        {isLoadedData ? (
          <Loading /> // Replace this with your actual loading component
        ) : user.has_google_analytics ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="col-span-1 md:col-span-2">
                <Select
                  id={'google-account'}
                  className="w-full"
                  options={googleAnalyticAccount.googleAccounts.map(acc => {
                    return { value: acc.name, label: acc.displayName };
                  })}
                  value={googleAnalyticAccount.googleAccounts
                    .map(acc => {
                      return { value: acc.name, label: acc.displayName };
                    })
                    .find(
                      item =>
                        item.value ===
                        googleAnalyticAccount?.selectedGoogleAccount?.name
                    )}
                  onChange={v => selectAccount(v)}
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <Select
                  id={'google-property'}
                  className="w-full"
                  options={googleAnalyticProperty.googleProperties.map(acc => {
                    return { value: acc.name, label: acc.displayName };
                  })}
                  value={googleAnalyticProperty.googleProperties
                    .map(acc => {
                      return { value: acc.name, label: acc.displayName };
                    })
                    .find(
                      item =>
                        item.value ===
                        googleAnalyticProperty?.selectedGoogleProperties?.name
                    )}
                  onChange={v => selectProperties(v)}
                />
              </div>
            </div>
            {analyticsData ? (
              <div className="md:grid md:grid-cols-2 md:gap-4 w-full max-w-none my-4">
                <div>
                  {/* This should take 1/2 of the area left side */}
                  <div className="md:col-span-2">
                    <div className="flex items-center space-x-4 mb-2">
                      <h2 className="text-lg font-semibold">Page Views</h2>
                      <button
                        className={`rounded px-2 py-1 text-xs font-medium ${
                          isButtonDisabled
                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            : 'shadow-sm ring-1 ring-inset bg-white text-purple-700 ring-purple-300 hover:bg-purple-50'
                        }`}
                        onClick={isButtonDisabled ? null : reviewAnalytics}
                        disabled={isButtonDisabled}
                      >
                        Insights
                      </button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <AnalyticsStat
                        title="Today"
                        value={numberToThousands(
                          analyticsData.screenPageViewsToday,
                          ','
                        )}
                      />
                      <AnalyticsStat
                        title="Last 7 Days"
                        value={numberToThousands(
                          analyticsData.screenPageViewsLast7Days,
                          ','
                        )}
                      />
                      <AnalyticsStat
                        title="Last 30 Days"
                        value={numberToThousands(
                          analyticsData.screenPageViewsLast30Days,
                          ','
                        )}
                      />
                    </div>
                  </div>
                  <div className="md:col-span-2 mt-8">
                    <h2 className="text-lg font-semibold mb-2">
                      User Sessions
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <AnalyticsStat
                        title="Today"
                        value={numberToThousands(
                          analyticsData.sessionsToday,
                          ','
                        )}
                      />
                      <AnalyticsStat
                        title="Last 7 Days"
                        value={numberToThousands(
                          analyticsData.sessionsLast7Days,
                          ','
                        )}
                      />
                      <AnalyticsStat
                        title="Last 30 Days"
                        value={numberToThousands(
                          analyticsData.sessionsLast30Days,
                          ','
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {/* This should take 1/2 of the area right side */}
                  {analyticsData && (
                    <TopViewedPages
                      topPagesData={analyticsData.top_pages_data}
                      isButtonDisabled={isButtonDisabled}
                    />
                  )}
                </div>
              </div>
            ) : (
              <p className="col-span-1 md:col-span-2">No data available</p>
            )}
            {topRankedPagesData ? (
              <TopRankedPages
                topRankedPagesData={topRankedPagesData}
                isButtonDisabled={isButtonDisabled}
              />
            ) : (
              <p>No data available</p>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    googleAnalyticProperty: state.googleAnalyticProperty,
    googleAnalyticAccount: state.googleAnalyticAccount,
    user: state.auth.user,
  };
}

const GoogleAnalyticsComponent = connect(mapStateToProps, {
  fetchAccounts,
  selectAccount,
  selectProperties,
  fetchProperties,
})(GoogleAnalyticsDisplay);

function GoogleAnalytics({ user }) {
  if (user?.has_google_analytics) {
    return <GoogleAnalyticsComponent />;
  }
  return (
    <div className="px-4 py-4">
      {/* <p className="mb-4">
        📈 Gain valuable insights on your website traffic, views, clicks, and
        more by connecting to Google Analytics 4 and Search Console. Use these
        insights to boost your blog's traffic.
      </p> */}
      {/* <GoogleAuthRedirect /> */}
      <h2 className="text-xl font-semibold mb-2">Coming Soon!</h2>
      <p className="mb-4">
        📈 Elevate your blogging game to unprecedented heights! Our upcoming
        feature integration with Google Analytics 4 and Search Console opens the
        door to a wealth of insights. Dive deep into your website's traffic,
        views, clicks, and more, empowering you to amplify your blog's reach
        like never before.
      </p>
      <p className="mb-4">
        Identify untapped opportunities with crystal-clear visibility into
        content gaps, paving the way for your next breakthrough article. Plus,
        breathe new life into your existing posts, pinpointing exactly which
        ones to update for a significant traffic boost.
      </p>
      <p className="mb-4">
        Stay tuned for this game-changing tool that promises to revolutionize
        your blogging strategy!
      </p>
      <button
        className="bg-gray-500 text-white py-2 px-4 rounded cursor-not-allowed"
        disabled
      >
        Connect Google Analytics & Search Console
      </button>
    </div>
  );
}

function mapStateToProps1(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps1, null)(GoogleAnalytics);
