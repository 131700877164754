import React, { useEffect, useState } from 'react';
import './CheckoutComplete.scss';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import { useHistory, useLocation } from 'react-router-dom';
import useSentData from '../services/useSentData';
import { setIsOnBoardingUser, updateUser } from '../store/actions/auth.actions';
import { getQueryVariable } from '../utils/common';
import { track, onPlanChange } from '../services/track';
import { connect, useDispatch, useSelector } from 'react-redux';
import panelCollapse, {
  setSidePanelOpenTab,
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import { sidePanelTabs } from '../store/reducers/side_panel.reducer';
import { useAlert } from 'react-alert';
import {
  ONBOARDING_INFO_STORAGE_NAME,
  saveOnBoardingInfoToGoogleSheet,
} from '../services/onBoaringService';
import http from '../services/http';
import btnPlay from '../styles/images/btn-play.svg';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';
import rankHigherIcon from '../styles/images/rank-higher-icon.svg';
import writeAssistantIcon from '../styles/images/write-assistant-icon.svg';
import keywordResearchIcon from '../styles/images/keyword-research-icon.svg';

function trackingListener() {
  var capterra_vkey = 'd49efd46a71888f44634fcc42b4ff374',
    capterra_vid = '2209259',
    ct = document.createElement('img');
  ct.src =
    'https://ct.capterra.com/capterra_tracker.gif?vid=' +
    capterra_vid +
    '&vkey=' +
    capterra_vkey;
  document.body.appendChild(ct);
}

function CheckoutComplete({ updateUser }) {
  const [data, setData] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const request = useSentData();
  const alert = useAlert();
  const dispatch = useDispatch();
  const state = getQueryVariable(location, 'state');
  const hosted_page_id = getQueryVariable(location, 'id');
  const { selectedProject } = useSelector(state => state.project);
  const { user } = useSelector(state => state.auth);
  const isOnBoardingUser = sessionStorage.getItem('isOnBoardingUser');
  const [modalIsOpen, setIsOpen] = useState(false);
  const goToNewPost = () => {
    if (isOnBoardingUser) {
      if (!selectedProject) {
        alert.info('Please wait,we are creating a new project for you!');
        return;
      }
      // Commented for now as we are not using onboarding flow and directly redirecting to dashboard
      // const onBoardingUserInfo = JSON.parse(
      //   localStorage.getItem(ONBOARDING_INFO_STORAGE_NAME) || ''
      // );
      // const userProfession = onBoardingUserInfo?.Profession;
      // if (userProfession === 'Blogger') {
      //   history.push('/blogger-onboarding');
      // } else {
      //   sessionStorage.removeItem('isOnBoardingUser');
      //   history.push(`/project/${selectedProject.id}/dashboard`, {
      //     isOnBoardingUser: true,
      //   });
      //   dispatch(panelCollapse(sidePanelTypes.DEFAULT, false));
      //   dispatch(setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS));
      //   dispatch(setIsOnBoardingUser(false));
      //   saveOnBoardingInfoToGoogleSheet();
      // }

      // Copied from above for now as we are not using onboarding flow and directly redirecting to dashboard
      sessionStorage.removeItem('isOnBoardingUser');
      history.push(`/project/${selectedProject.id}/dashboard`, {
        isOnBoardingUser: true,
      });
      dispatch(panelCollapse(sidePanelTypes.DEFAULT, false));
      dispatch(setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS));
      dispatch(setIsOnBoardingUser(false));
      saveOnBoardingInfoToGoogleSheet();
    } else {
      history.push('/');
    }
  };

  function loadSubscription() {
    if (state === 'succeeded') {
      request
        .send('/api/billing/subscription', { hosted_page_id })
        .then(data => {
          if (!data.isAxiosError) {
            http.get(`/api/users/${user.id}`).then(res => {
              localStorage.setItem('user', JSON.stringify(res.data));
              dispatch(updateUser(res.data));
            });
            setData(data);
            track('Plan change', {
              Status:
                data.subscription?.status &&
                data.subscription?.status !== null &&
                data.subscription?.status === 'active'
                  ? 'Subscription'
                  : 'Free Trial',
            });
            onPlanChange({
              id: data.id,
              subscription: data.subscription,
            });
          }
        });
    }
  }

  useEffect(() => {
    loadSubscription();
    return () => {
      sessionStorage.removeItem('isOnBoardingUser');
      dispatch(setIsOnBoardingUser(false));
    };
  }, []);

  const NovaAIAssistantGuide = () => {
    const handlePlayVideo = () => {
      track('Play video', {
        url: 'https://youtu.be/L5GC3RnjEeA',
        title:
          'How Nova AI Assistant Transforms My Blogging Workflow | LIVE DEMO',
      });
      setIsOpen(true);
    };

    return isOnBoardingUser ? (
      <div>
        <h3 className="text-purple-500 text-2xl font-bold">
          👀 Step by Step: Writing Perfect Blog Posts with Bramework | MUST
          WATCH
        </h3>
        <p className="text-sm">
          This video will guide you through the entire process, from a blank
          page to a fully written blog post.
        </p>
        <div className="mt-4 text-center blank-page-to-blog-post">
          <img
            src={btnPlay}
            onClick={handlePlayVideo}
            alt="btn-play"
            className="btn-play"
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <NewWrapper
      header={<NewHeader />}
      // pageHeading={<PageHeading title="Checkout Complete" />}
    >
      <div className="posts-list cards-wrapper">
        <div className="status-message">
          {data && data.subscription ? (
            data.subscription.status === 'in_trial' ? (
              <>
                <h2>
                  🥳 Congratulations on starting your 7-day trial!
                  <br />
                  After the trial period, you'll start your{' '}
                  {data.subscription.plan.price !== 0 &&
                  data.subscription.plan.name !== 'Flex' ? (
                    <span>
                      <b>{data.subscription.plan.name}</b> plan for
                      <b>
                        {' '}
                        ${data.subscription.plan.price / 100}/
                        {data.subscription.plan.period_unit}
                      </b>
                    </span>
                  ) : (
                    <span>Flex pay-as-you-go plan.</span>
                  )}
                </h2>
                {/* <NovaAIAssistantGuide /> */}
                <OnboardingOptions />
                <p
                  onClick={goToNewPost}
                  className="text-purple-500 hover:text-purple-700 cursor-pointer"
                >
                  Skip to Dashboard
                </p>
              </>
            ) : (
              <>
                <h2>
                  🥳 Congratulations on successfully purchasing
                  <br />
                  {data.subscription.plan.price !== 0 &&
                  data.subscription.plan.name !== 'Flex' ? (
                    <span>
                      the<b> {data.subscription.plan.name}</b> plan for
                      <b>
                        {' '}
                        ${data.price / 100}/{data.subscription.plan.period_unit}
                      </b>
                    </span>
                  ) : (
                    <span>the Flex pay-as-you-go plan.</span>
                  )}
                </h2>
                {/* <NovaAIAssistantGuide /> */}
                <OnboardingOptions />
                {/* <button onClick={goToNewPost} className="btn btn-purple">
                  Go to Dashboard
                </button> */}
                <p
                  onClick={goToNewPost}
                  className="text-purple-500 hover:text-purple-700 cursor-pointer"
                >
                  Skip to Dashboard
                </p>
              </>
            )
          ) : (
            state !== 'succeeded' && (
              <h2>
                Something went wrong while processing your payment. Please try
                again later.
              </h2>
            )
          )}
        </div>
        <div className="support">
          Didn't receive purchased product?
          <br />
          Please contact our support team by email{' '}
          <a
            href="https://www.bramework.com/support/"
            target="_blank"
            className="text-purple-500 hover:text-purple-700"
            rel="noreferrer"
          >
            support@bramework.com
          </a>
        </div>
      </div>
      <Modal
        className={'command-modal'}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
      >
        <iframe
          src="https://www.youtube.com/embed/L5GC3RnjEeA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div className="btn-close" onClick={() => setIsOpen(false)}>
          <XIcon className="w-6 h-6 md:w-10 md:h-10 text-white" />
        </div>
      </Modal>
    </NewWrapper>
  );
}

function OnboardingOptions() {
  const history = useHistory();
  const { selectedProject } = useSelector(state => state.project);

  const handleCardClick = (route, page) => {
    track('User onboarding action', { Clicked: page });
    if (selectedProject) {
      history.push(`/project/${selectedProject.id}/${route}`, {
        isOnBoardingUser: true,
        page,
      });
    }
  };

  return (
    <div className="pl-8 pr-8 pb-8 rounded-lg text-gray-700">
      <h2 className="text-xl md:text-2xl font-bold text-center mb-4">
        Let's get started 🚀
      </h2>
      <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4">
        {/* Card 1 */}
        <div
          onClick={() =>
            handleCardClick('generate-first-draft-pro', 'first draft pro')
          }
          className="flex flex-col items-center p-4 border border-purple-300 hover:border-purple-700 rounded-lg m-0.5 shadow-2xl transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-green-100 text-green-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Popular
          </span>
          <img
            className="w-20 h-20 mb-3"
            src={rankHigherIcon}
            alt="Rank Higher"
          />
          <h3 className="mb-2 font-semibold">First Draft PRO</h3>
          <p className="text-sm text-center">
            Generate a well-researched, factual blog post designed to rank
            highly with SEO optimization.
          </p>
        </div>
        {/* Card 2 */}
        <div
          onClick={() => handleCardClick('blog-post-improver', 'post improver')}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-green-100 text-green-700 text-xs font-bold px-3 py-1.5 rounded-md">
            New
          </span>
          <img
            className="w-20 h-20 mb-3"
            src={keywordResearchIcon}
            alt="Write Assistant"
          />
          <h3 className="mb-2 font-semibold">Blog Booster</h3>
          <p className="text-sm text-center">
            Improve your existing blog posts with our AI-powered suggestions to
            increase your chances of ranking higher.
          </p>
        </div>
        {/* Card 3 */}
        <div
          onClick={() => handleCardClick('keyword-analyzer', 'post')}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <img
            className="w-20 h-20 mb-3"
            src={writeAssistantIcon}
            alt="Write Assistant"
          />
          <h3 className="mb-2 font-semibold">Writing Assistant</h3>
          <p className="text-sm text-center">
            Simplify your SEO blog creation with a guided approach, from
            devising titles to crafting compelling content effortlessly.
          </p>
        </div>
        {/* Card 4 */}
        {/* <div
          onClick={() => handleCardClick('keyword-analyzer', 'analyzer')}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <img
            className="w-20 h-20 mb-3"
            src={keywordResearchIcon}
            alt="Keyword Research"
          />
          <h3 className="mb-2 font-semibold">Keyword Research</h3>
          <p className="text-sm text-center">
            Utilize our Keyword Analyzer to uncover trending phrases and
            questions, driving traffic to your site.
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default connect(null, { updateUser })(CheckoutComplete);
