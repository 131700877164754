import { useState } from 'react';
import Http from './http';
export default function useDataForSeoApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  function send(path, data, method = 'post', params, headers) {
    setLoading(true);
    setError(null);
    return Http({
      method: method,
      url: `https://api.dataforseo.com/v3${path}`,
      data: data,
      params: params,
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_DATA_FOR_SEO_API_KEY}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    })
      .then(response => {
        setLoading(false);
        setError(null);
        setSuccess(true);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        setError(error);
        setSuccess(false);
        return error;
      });
  }
  function reset() {
    setLoading(false);
    setError(null);
    setSuccess(null);
  }
  return { error, loading, success, send, reset };
}
