export function volumeComplexity(value) {
  let title;
  let color;
  if (value < 50) {
    title = 'low';
    color = 'red';
  } else if (value >= 50 && value < 250) {
    title = 'low';
    color = 'orange';
  } else if (value >= 250 && value < 1000) {
    title = 'medium';
    color = 'orange';
  } else if (value >= 1000 && value < 50000) {
    title = 'medium';
    color = 'green';
  } else if (value >= 50000 && value < 250000) {
    title = 'high';
    color = 'green';
  } else if (value >= 250000 && value < 500000) {
    title = 'high';
    color = 'orange';
  } else {
    title = 'high';
    color = 'red';
  }
  return { title, color };
}

export function competitionComplexity(value) {
  let title;
  let color;
  if (value < 35) {
    title = 'low';
    color = 'green';
  } else if (value >= 35 && value < 79) {
    title = 'medium';
    color = 'orange';
  } else {
    title = 'high';
    color = 'red';
  }
  return { title, color };
}

export function difficultyComplexity(value) {
  let title;
  let color;
  if (value < 35) {
    title = 'low';
    color = 'green';
  } else if (value >= 35 && value < 79) {
    title = 'medium';
    color = 'orange';
  } else {
    title = 'high';
    color = 'red';
  }
  return { title, color };
}

export function resultsComplexity(value) {
  let title;
  let color;
  if (value < 100000) {
    title = 'low';
    color = 'green';
  } else if (value >= 100000 && value < 1000000) {
    title = 'medium';
    color = 'orange';
  } else {
    title = 'high';
    color = 'red';
  }
  return { title, color };
}
