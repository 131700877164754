import { accessActionTypes } from '../reducers/access.reducer';

export function forceChoosePlan() {
  return { type: accessActionTypes.FORCE_CHOOSE_PLAN };
}

export function resetForceChoosePlan() {
  return { type: accessActionTypes.RESET_FORCE_CHOOSE_PLAN };
}

export function shownChoosePlan() {
  return { type: accessActionTypes.SHOWN_PLAN };
}
