const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);
const flattened = arr => [].concat(...arr);
const filterFalse = arrayLineObj => arrayLineObj.filter(item => !!item);

function getLines(input) {
  return [...input.matchAll(/<p>([^<]*?)<\/p>/g)].map(line => {
    return line[1];
  });
}

function mapLine(line) {
  return [
    {
      tagName: 'p',
      content: line,
    },
  ];
}

function processIntroductionAndAnswer(arrayLineObj) {
  return pipe(
    filterFalse,
    arrayLineObj =>
      arrayLineObj.map(lineObj => {
        const regex = new RegExp(
          /^\s*\b(?:[Ii]ntroduction|[Aa]nswer[s]?)\s*:\s*/
        );

        return {
          ...lineObj,
          content: lineObj.content.replace(regex, ''),
        };
      }),
    flattened
  )(arrayLineObj);
}

function processQuestionAndSection(arrayLineObj) {
  return pipe(
    filterFalse,
    arrayLineObj =>
      arrayLineObj.map(lineObj => {
        const regex = new RegExp(/^\s*\b(?:[Qq]uestion|[Ss]ection)\s*\d+:\s*/);

        return {
          ...lineObj,
          ...(regex.test(lineObj.content) && {
            tagName: 'h2',
          }),
          content: lineObj.content.replace(regex, ''),
        };
      }),
    flattened
  )(arrayLineObj);
}

function processStepAndTip(arrayLineObj) {
  return pipe(
    filterFalse,
    arrayLineObj =>
      arrayLineObj.map(lineObj => {
        const regex = new RegExp(/^\s*\b(?:[Ss]tep|[Tt]ip|)?\s*\d+(:|-|\.)\s*/);

        return {
          ...lineObj,
          ...(regex.test(lineObj.content) && {
            tagName: 'h2',
          }),
        };
      }),
    flattened
  )(arrayLineObj);
}

function processStepHeader(arrayLineObj) {
  console.log('arrayLineObj', arrayLineObj);
  return pipe(
    filterFalse,
    arrayLineObj =>
      arrayLineObj.map(lineObj => {
        if (!lineObj?.content) {
          return false;
        }

        const regex = new RegExp(/^\s*\b\d+(?:-|\.)(?:.+?):\s*/);
        const headerMatch = regex.exec(lineObj.content);

        return [
          headerMatch && {
            ...lineObj,
            tagName: 'h2',
            content: headerMatch[0],
          },
          headerMatch && {
            ...lineObj,
            tagName: 'p',
            content: lineObj.content.replace(regex, ''),
          },
          !headerMatch && lineObj,
        ];
      }),
    flattened
  )(arrayLineObj);
}

function processConclusion(arrayLineObj) {
  return pipe(
    filterFalse,
    arrayLineObj =>
      arrayLineObj.map(lineObj => {
        if (!lineObj?.content) {
          return false;
        }

        const regex = new RegExp(/^\s*\b(?:[Cc]onclusion)\s*:?\s*/);

        return [
          regex.test(lineObj.content) && {
            ...lineObj,
            tagName: 'h2',
            content: 'Conclusion',
          },
          {
            ...lineObj,
            content: lineObj.content.replace(regex, ''),
          },
        ];
      }),
    flattened
  )(arrayLineObj);
}

function toHtml(lineObj) {
  return `<${lineObj.tagName}>${lineObj.content}</${lineObj.tagName}>`;
}

function processLine(line) {
  return pipe(
    mapLine,
    processIntroductionAndAnswer,
    processQuestionAndSection,
    processStepAndTip,
    processStepHeader,
    processConclusion
  )(line);
}

export function processHTML(input) {
  if (!input) return '';

  const lines = getLines(input);

  return pipe(
    lines => lines.map(line => processLine(line)),
    flattened,
    filterFalse,
    arrayLineObj => arrayLineObj.map(lineObj => toHtml(lineObj)),
    arrayLineObj => arrayLineObj.join('\n')
  )(lines);
}
