const PrimaryButton = props => {
  const { children, className, ...rest } = props;
  return (
    <button
      {...rest}
      className={`w-auto inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm disabled:opacity-30 ${className}`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
