import React from 'react';
import './Tip.scss';

export default function Tip({ text, icon }) {
  return (
    <div className="tip-component" data-tip={text}>
      {icon ? icon : '?'}
    </div>
  );
}
