import { Drawer } from '@material-ui/core';
import { XIcon } from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import { isolateError } from '../../utils/api';
import { useSelector } from 'react-redux';
import useSentData from '../../services/useSentData';
import { Keywords } from '../../pages/NewPost';
import SemrushFullBackLogo from '../../styles/images/logo-semrush-full-black.svg';
import ReactTooltip from 'react-tooltip';

const KeywordAnalyzerModal = ({ isOpen, onClose, phrase }) => {
  const postId = useSelector(state => state.post.id);
  const user = useSelector(state => state.auth.user);
  const [data, setData] = React.useState({
    phrase: '',
    analysis_data: {},
  });
  const request = useSentData();

  useEffect(() => {
    if (phrase) {
      request
        .send('/api/semrush', {
          keyword_phrase: phrase,
          database: 'us',
        })
        .then(res => {
          if (!res.isAxiosError) {
            setData({ ...data, analysis_data: res });
          } else {
            alert.error(isolateError(res));
          }
        });
    }
  }, [phrase]);

  const isNotEmpty = !!Object.keys(data.analysis_data).length;

  return (
    <Drawer
      anchor="bottom"
      PaperProps={{
        elevation: 8,
        sx: {
          borderRadius: '30px 30px 0 0',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <div style={{ height: '90vh' }} className="p-10 new-post-component">
        <div className="w-full flex justify-end">
          <XIcon
            className="h-8 w-8 text-gray-500 cursor-pointer"
            onClick={onClose}
            aria-hidden="true"
          />
        </div>
        <div className="flex w-full flex-col">
          <h1>Keyword Analyzer</h1>
          <div className="flex items-center justify-center gap-x-">
            <p className="powered">Powered by</p>
            <img src={SemrushFullBackLogo} className="h-12" />
          </div>
          {isNotEmpty && !request.loading && (
            <>
              <ReactTooltip
                place={window.innerWidth < 768 ? 'left' : 'top'}
                class="tooltip-for-templates"
                type="light"
                effect="solid"
                html={true}
                border={false}
              />
              <Keywords
                search={phrase}
                keywords={data.analysis_data}
                user={user}
                originKey={phrase}
              />
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default KeywordAnalyzerModal;
