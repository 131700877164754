import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useRef,
  Fragment,
} from 'react';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import { titleCase } from 'title-case';
import useSentData from '../services/useSentData';
import Block from '../pages/PostBuilder/Block';
import { isolateError } from '../utils/api';
import PostBuilderUtils, {
  getSentencesFromHtml,
  getSeoData,
  magicSpacerFormat,
} from '../utils/PostBuilderUtils';
import {
  validBlogURL,
  removeTags,
  removeLineBreak,
  sortListByDate,
} from '../utils/common';
import { track } from '../services/track';
import { updateUser } from '../store/actions/auth.actions';
import { addPlagiarism } from '../store/actions/plagiarisms.actions';
import DeletePostModal from '../pages/Dashboard/DeletePostModal';
import SharePostModal from '../pages/PostBuilder/SharePostModal';
import ChangeURLModal from '../pages/PostBuilder/ChangeURLModal';
import LibraryImageModal from '../pages/PostBuilder/LibraryImageModal';
import ImageMetaModal from '../pages/PostBuilder/ImageMetaModal';
import PlatformModal from '../pages/PostBuilder/PlatformModal';
import PlagiarismModal from '../pages/PostBuilder/PlagiarismModal';
import { convertToPlain } from '../utils/common';
import PostTitle from './PostTitle';
import PlagiarismHistory from '../components/PlagiarismHistory';
import {
  addPostTitle,
  addPostId,
  addPostVersions,
  addPostVersionIndex,
  addEditorValue,
  addPostVersionId,
  addGeneratedPostTitles,
  addGeneratedPostOutlines,
  addRelatedKeywords,
  addTopQuestions,
  addSelectedKeywords,
  addInstructions,
  loadSeoAnalyzer,
  setUpdatedContentPopup,
  setCheckPlagiarismTriggered,
  setHighLightWords,
  addGeneratedParagraph,
  addGeneratedRephrase,
  addGeneratedSummarize,
  addGeneratedSteps,
  addGeneratedListicle,
  addGeneratedCommand,
  setAdditionalKeywordsButtonOn,
  setInstructionsButton,
  addSecondaryKeywords,
} from '../store/actions/post_builder.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useId } from 'react-id-generator';
import $ from 'jquery';
import Tooltip from '@material-ui/core/Tooltip';
import {
  RefreshIcon,
  ClipboardListIcon,
  MenuAlt2Icon,
  PuzzleIcon,
  SparklesIcon,
  ShieldCheckIcon,
  ChevronDownIcon,
  DotsVerticalIcon,
  ChipIcon,
} from '@heroicons/react/outline';

import Modal from 'react-modal';
import Control from './post-builder-controls/control';
import WriteMoreButton from '../components/post-builder-controls/write-more-button';
import { typeWriter } from './post-builder-controls/utils';
import { setStatus } from '../store/actions/post_builder.actions';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../store/reducers/nova_onboarding.reducer';
import { setNovaOnBoardingCheckList } from '../store/actions/nova_onboarding.action';
import { PostTypes } from '../store/reducers/post_builder.reducer';
import { ADDITION_KEYWORDS_KEY } from './KeywordsTagInput';
import { factCheck, setFacts } from '../store/actions/fact-checker.actions';

const PlagiarismConfirmationModal = ({ isOpenModal, closeModal }) => {
  return (
    <Modal
      className="plagiarism-modal plagiarism-confirm-modal"
      isOpen={isOpenModal}
      ariaHideApp={false}
      onRequestClose={() => closeModal(false)}
    >
      <p className="plagiarism-modal-title">
        Are you sure you want plagiarism check?
      </p>
      <button
        className="btn btn-red plagiarism-modal-btn"
        onClick={() => closeModal(false)}
      >
        Cancel
      </button>
      <button
        className="btn btn-white plagiarism-modal-btn"
        onClick={() => closeModal(true)}
      >
        Proceed
      </button>
      {isOpenModal && <PlagiarismHistory />}
    </Modal>
  );
};

const PostBuilderControls = React.forwardRef((props, ref) => {
  const base_url = process.env.REACT_APP_BACKEND_HOST;
  const { state } = useLocation();
  const history = useHistory();
  let { id, project_id } = useParams();
  if (!id && state.id) {
    id = state.id;
  }
  const [blocks, setBlocks] = useState(
    PostBuilderUtils.getBlocksByTemplate('standard')
  );
  const [data, setData] = useState({});
  const [saveAPICalling, setSaveAPICalling] = useState(false);
  const [fetchAPICalling, setFetchAPICalling] = useState(false);
  // const [relatedKeywords, setRelatedKeywords] = useState();
  // const [topQuestions, setTopQuestions] = useState();
  const [displayContentError, setDisplayContentError] = useState(false);
  const [topics, setTopics] = useState();
  const [isDeleteModalVisible, showDeleteModal] = useState(false);
  const [targetWordCount, setTargetWordCount] = useState('loading...');
  const [wordCount, setWordCount] = useState(0);
  const [isExitModalVisible, showExitModal] = useState(false);
  const [isComingSoonModalVisible, showComingSoonModal] = useState(false);
  const [isShareModalVisible, showShareModal] = useState(false);
  const [isChangeURLModalVisible, showChangeURLModal] = useState(false);
  const [isBlocksLoaded, setBlocksLoaded] = useState(false);
  const [heroImage, setHeroImage] = useState(null);
  const [bodyImages, setBodyImages] = useState({});
  const [seoData, setSeoData] = useState();
  const request = useSentData();
  const alert = useAlert();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModelOpen, setConfirmModelOpen] = useState(false);
  const [isPlagiarismFound, setPlagiarismFound] = useState('');
  const [phrase, setPhrase] = useState();
  const [postURL, setPostURL] = useState();
  const [category, setCategory] = useState();
  const [step, setStep] = useState();
  const [isAutoSave, setAutoSave] = useState(false);
  const [isGenerating, setGenerating] = useState(false);
  const [autoSaveDate, setAutoSaveDate] = useState();
  const [isEditorFocus, setEditorFocus] = useState(false);

  const versions = useSelector(state => state.post.versions);
  const postTitle = useSelector(state => state.post.title);
  const generatedPostTitles = useSelector(state => state.post.generatedTitles);
  const relatedKeywords = useSelector(state => state.post.relatedKeywords);
  const topQuestions = useSelector(state => state.post.topQuestions);
  const editorValue = useSelector(state => state.post.editorValue);
  const versionIndex = useSelector(state => state.post.versionIndex);
  const versionId = useSelector(state => state.post.versionId);
  const postId = useSelector(state => state.post.id);
  const additionalKeywordsButtonOn = useSelector(
    state => state.post.additionalKeywordsButtonOn
  );
  const instructionButtonOn = useSelector(
    state => state.post.instructionButtonOn
  );
  const seoAnalyzer = useSelector(state => state.post.seoAnalyzer);
  const projects = useSelector(state => state.project.projects);
  const userDomain = useSelector(state => state.auth.user?.company?.domain);
  const projectDomain =
    projects.find(item => String(item.id) === project_id)?.project_url || '';

  const currentVersionIndex = versions.findIndex(function(version) {
    return version.id === versionId;
  });
  const [htmlId] = useId();
  const [aiPower, setAIPower] = useState();
  const [editorLoading, setEditorLoading] = useState(false);
  const [editorLoadingText, setEditorLoadingText] = useState(false);
  const [editorLoadingParagraph, setEditorLoadingParagraph] = useState(false);
  const [editorLoadingCommand, setEditorLoadingCommand] = useState(false);
  const [editorLoadingList, setEditorLoadingList] = useState(false);
  const [editorLoadingSteps, setEditorLoadingSteps] = useState(false);
  const [editorLoadingSummarize, setEditorLoadingSummarize] = useState(false);
  const [editorLoadingPlagiarism, setEditorLoadingPlagiarism] = useState(false);
  const [editorLoadingRewrite, setEditorLoadingRewrite] = useState(false);
  const [editorLoadingOutline, setEditorLoadingOutline] = useState(false);
  const [isTextHighlighted, setTextHighlighted] = useState(false);
  const [isModalVisible, showModal] = useState(false);
  const [focusTextarea, setFocusTextarea] = useState(false);
  const [writeMoreButton, setWriteMoreButton] = useState(false);
  const [redoGenerateTextButton, setRedoGenerateTextButton] = useState(false);
  const [generateListButton, setGenerateListButton] = useState(false);
  const [generateStepsButton, setGenerateStepsButton] = useState(false);
  const [writeParagraphButton, setWriteParagraphButton] = useState(false);
  const [rewriteTextButton, setRewriteTextButton] = useState(false);
  const [summarizeTextButton, setSummarizeTextButton] = useState(false);
  const [commandTextButton, setCommandTextButton] = useState(false);
  const [minTextCharLimitReached, setMinTextCharLimitReached] = useState(false);
  const [paragraphCharLimitReached, setParagraphCharLimitReached] = useState(
    false
  );
  const [plagiarismData, setPlagiarismData] = useState([]);
  const [listCharLimitReached, setListCharLimitReached] = useState(false);
  const [stepCharLimitReached, setStepCharLimitReached] = useState(false);
  const [rewriteCharLimitReached, setRewriteCharLimitReached] = useState(false);
  const [plagiarismCharLimitReached, setPlagiarismCharLimitReached] = useState(
    false
  );
  const [summarizeCharLimitReached, setSummarizeCharLimitReached] = useState(
    false
  );
  const [commandCharLimitReached, setCommandCharLimitReached] = useState(false);
  const [generateOutlineButton, setGenerateOutlineButton] = useState(false);
  const [selectionCharCount, setSelectionCharCount] = useState(0);

  // image
  const [isLibraryModalVisible, showLibraryModal] = useState(false);
  const [isImageMetaModalVisible, showImageMetaModal] = useState(false);
  const [imageMeta, setImageMeta] = useState({ target: null, file: {} });

  const [saveText, setSaveText] = useState(false);
  const [textData, setTextData] = useState('');
  const [saveOutline, setSaveOutline] = useState(false);
  const [apiRespBody, setApiRespBody] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [aiControlsTop, setAiControlsTop] = useState(0);
  const [aiControlsLeft, setAiControlsLeft] = useState(0);

  const tone = useSelector(state => state.post.tone);
  const length = useSelector(state => state.post.length);
  const instructions = useSelector(state => state.post.instructions);
  const selectedKeywords = useSelector(state => state.post.selectedKeywords);
  const useBrandVoice = useSelector(state => state.post.useBrandVoice);
  const selectionBoundingClientRect = useSelector(
    state => state.post.selectionBoundingClientRect
  );
  const checkPlagiarismTriggered = useSelector(
    state => state.post.checkPlagiarismTriggered
  );
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );

  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );

  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );

  const hasAppSumoSubscription = useSelector(
    state => state.auth?.user?.app_sumo_subscription
  );

  const factCheckLoading = useSelector(state => state.factChecker.loading);

  const dispatch = useDispatch();

  const BLOCK_TYPE = 'Body';

  function getBodyIndexFromBlocks() {
    if (blocks && Array.isArray(blocks)) {
      return blocks.findIndex(block => block.type === BLOCK_TYPE);
    }
    return -1;
  }

  useImperativeHandle(ref, () => ({
    preview: () => {
      submitPost(
        {
          body: blocks,
          analysis_data: {
            ...data.analysis_data,
            top_related: relatedKeywords,
            top_questions: topQuestions,
          },
        },
        'preview'
      );
    },
    showLibraryModalFnc: status => {
      showLibraryModal(status);
    },
    showImageMetaModalFnc: (status, target) => {
      setImageMeta({
        target,
        file: {
          alt_text: target.alt,
          title: target.title,
          description: target.longdesc,
        },
      });
      showImageMetaModal(status);
    },
    updateFeaturedImageFnc: (i, state) => {
      updateBlockState(i, state);
    },
    saveAsDraft: status => {
      submitPost(
        {
          body: blocks,
          published: false,
          analysis_data: {
            ...data.analysis_data,
            top_related: relatedKeywords,
            top_questions: topQuestions,
          },
        },
        '',
        status
      );
    },
    plagiarismCheck: () => {
      plagiarismCheckFn();
    },
    publish: () => {
      if (validateTitle()) {
        setModalOpen(true);
      } else {
        alert.error('Post title required');
      }
    },
    share: () => {
      showShareModal(true);
    },
    postURL: () => {
      showChangeURLModal(true);
    },
    download: (download_format = 'html') => {},
    exit: () => {
      showExitModal(true);
      submitPost(
        {
          body: blocks,
          analysis_data: {
            ...data.analysis_data,
            top_related: relatedKeywords,
            top_questions: topQuestions,
          },
        },
        'exit'
      );
    },
    delete: () => {
      showDeleteModal(true);
    },

    handleEditorChange: (content, editor) => {
      const bodyIndex = getBodyIndexFromBlocks();
      updateBlockState(bodyIndex, {
        ...blocks[bodyIndex].state,
        text: content,
        id: versionId,
      });
      setDisplayContentError(false);
      if (!editorValue[BLOCK_TYPE]) {
        dispatch(addEditorValue(BLOCK_TYPE, content, htmlId));
      }
    },
    handleSelectionChange: (data, editor) => {
      const content = editor.selection.getContent({ format: 'text' });
      setSelectionCharCount(content.length);
      dispatch(setHighLightWords(content));
      if (content.length > 0 && minTextCharLimitReached) {
        setTextHighlighted(true);
      } else if (content.length <= 0 && minTextCharLimitReached) {
        setTextHighlighted(false);
      }
      setParagraphCharLimitReached(content.length > 9 && content.length < 101);
      setCommandCharLimitReached(content.length > 19);
      if (content.length > 19 && content.length < 301) {
        setListCharLimitReached(true);
        setStepCharLimitReached(true);
      } else {
        setListCharLimitReached(false);
        setStepCharLimitReached(false);
      }
      if (content.length > 29 && content.length < 4001) {
        setRewriteCharLimitReached(true);
        setSummarizeCharLimitReached(true);
      } else {
        setRewriteCharLimitReached(false);
        setSummarizeCharLimitReached(false);
      }
      if (content.length > 401 && content.length < 2401) {
        setPlagiarismCharLimitReached(true);
      } else {
        setPlagiarismCharLimitReached(false);
      }
    },
    handleOnKeyUp: (data, editor) => {
      if (versionId) {
        if (editor !== null && editor.getContent() !== null) {
          const newVersions = versions;
          let objIndex = newVersions.findIndex(obj => obj.id === versionId);
          newVersions[objIndex].text = editor.getContent();
          dispatch(addPostVersions(newVersions));
        }
      } else if (versionIndex !== null && versionIndex >= 0) {
        if (editor !== null && editor.getContent() !== null) {
          const newVersions = versions;
          newVersions[versionIndex] = editor.getContent();
          dispatch(addPostVersions(newVersions));
        }
      }

      if ((editor !== null && editor.getContent() !== null) || postTitle) {
        const content = editor.getContent();
        if (
          editorValue[BLOCK_TYPE] &&
          editorValue[BLOCK_TYPE].id === htmlId &&
          editorValue[BLOCK_TYPE].value
        ) {
          const minCharCount = 48;
          const textCharCount = convertToPlain(editorValue[BLOCK_TYPE].value)
            .length;
          let contentCharCount = convertToPlain(content).length;
          const powerValue = Math.round(
            (-(textCharCount + minCharCount - contentCharCount) / minCharCount +
              1) *
              100
          );
          if (powerValue < -10) {
            setAIPower(100);
          } else {
            setAIPower(powerValue);
          }
          if (
            contentCharCount > textCharCount + minCharCount ||
            powerValue < -10
          ) {
            setMinTextCharLimitReached(true);
          } else {
            setMinTextCharLimitReached(false);
          }
        }
      }
    },

    generateText: (editor, action, selection = '') => {
      if (!postTitle && action === 'write-paragraph') {
        alert.error('Post title required');
      } else {
        generateTextFn(editor, action, selection);
      }
    },
  }));

  useEffect(() => {
    if (fetchAPICalling === true) {
      return;
    }
    if (id) {
      setFetchAPICalling(true);
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            if (data) {
              dispatch(addPostId(data.id));
              setData(data);
              setDataLoaded(true);
              // setRelatedKeywords(data.analysis_data.top_related);
              // setTopQuestions(data.analysis_data.top_questions);
              dispatch(addRelatedKeywords(data.analysis_data.top_related));
              dispatch(addTopQuestions(data.analysis_data.top_questions));
              props.updateSeoTool(
                'relatedKeywords',
                data.analysis_data.top_related
              );
              props.updateSeoTool(
                'topQuestions',
                data.analysis_data.top_questions
              );
              props.updateSeoTool('setHistories', data.histories);
              props.updateSeoTool('topPosts', data.analysis_data.top_posts);
            }
            if (data.phrase) {
              setPhrase(data.phrase);
              props.updateSeoTool('phrase', data.phrase);
            }
            if (data.topics) {
              setTopics(data.topics);
              props.updateSeoTool('topics', data.topics);
            }
            if (data.topics) {
              const additionKeywords = (data.topics || []).map(
                (item, index) => ({
                  id: new Date().getTime() + index,
                  text: item,
                  selected: selectedKeywords.indexOf(item) >= 0,
                })
              );
              sessionStorage.setItem(
                `${ADDITION_KEYWORDS_KEY}_${id}`,
                JSON.stringify(additionKeywords)
              );
              dispatch(addSecondaryKeywords(data.topics || []));
            }
            if (data.category) {
              setCategory(data.category);
            }
            if (data.step) {
              setStep(data.step);
            }
            if (data.domain) {
              setPostURL(data.domain);
            }
            if (data.facts) {
              sortListByDate(data.facts, 'created_at');
              dispatch(setFacts(data.facts));
            }
            if (data.body) {
              setApiRespBody(data.body);
              if (
                props.editorRef &&
                props.editorRef.current &&
                props.editorRef.current.editor &&
                !props.editorRef?.current?.editor.getContent({ format: 'text' })
              ) {
                const content = { ...data };
                setTimeout(() => {
                  // const bodyIndex = getBodyIndexFromBlocks();
                  setEditorContent(content.body[2].state.text);
                  props.updateLoader(false);
                }, 1500);
              }
              setBlocks(data.body);
              props.updateProgressState('blocks', data.body);
              props.updateSeoTool('setBlocks', data.body);
            } else {
              setApiRespBody(data.body);
              setBlocks(PostBuilderUtils.getBlocksByTemplate('standard'));
              props.updateProgressState(
                'blocks',
                PostBuilderUtils.getBlocksByTemplate('standard')
              );
              props.updateSeoTool('setBlocks', data.body);
              if (
                props.editorRef &&
                props.editorRef.current &&
                props.editorRef.current.editor &&
                !props.editorRef?.current?.editor.getContent({ format: 'text' })
              ) {
                const content = { ...data };
                setTimeout(() => {
                  // const bodyIndex = getBodyIndexFromBlocks();
                  // setEditorContent(content.body[2].state.text);
                  props.updateLoader(false);
                }, 1500);
              }
            }
            if (data.titles) {
              dispatch(addGeneratedPostTitles(data.titles));
            }
            if (data.outlines) {
              dispatch(addGeneratedPostOutlines(data.outlines));
            }
            if (data.paragraphs) {
              dispatch(addGeneratedParagraph(data.paragraphs));
            }
            if (data.commands) {
              dispatch(addGeneratedCommand(data.commands));
            }
            if (data.rewrites) {
              dispatch(addGeneratedRephrase(data.rewrites));
            }
            if (data.summaries) {
              dispatch(addGeneratedSummarize(data.summaries));
            }
            if (data.steps) {
              dispatch(addGeneratedSteps(data.steps));
            }
            if (data.listicles) {
              dispatch(addGeneratedListicle(data.listicles));
            }
            if (data.versions) {
              dispatch(addPostVersions(data.versions));
              if (data.body) {
                if (data.body[2].type === 'Body') {
                  // Default selected version old setup
                  const cleanVersions = data.versions.map((version, i) =>
                    removeLineBreak(
                      removeTags(
                        version === Object(version) && version.body
                          ? version.body
                          : version === Object(version) && version.id
                          ? ''
                          : version
                      )
                    )
                  );
                  const indexOfVersions = cleanVersions.indexOf(
                    removeLineBreak(removeTags(data.body[2].state.text))
                  );
                  if (indexOfVersions >= 0) {
                    dispatch(addPostVersionIndex(indexOfVersions));
                  }
                  // Default selected version new setup that uses versionId
                  if (data.body[2].state.id) {
                    dispatch(addPostVersionId(data.body[2].state.id));
                  }
                }
              }
            }
            dispatch(setStatus(data.status));

            setBlocksLoaded(true);
          } else {
            alert.error(isolateError(data));
          }
          setFetchAPICalling(false);
        });
      // track('Entered blog post creator');
    }
  }, [data.category]);

  useEffect(() => {
    const newBlocks = [...blocks];
    newBlocks[0].state.text = postTitle;
    setBlocks(newBlocks);
    props.updateProgressState('blocks', newBlocks);
    updateWordCount();
  }, [postTitle]);

  useEffect(() => {
    getSeo();
  }, [blocks]);

  useEffect(() => {
    getSeo();
  }, [seoAnalyzer]);

  useEffect(() => {
    if (displayContentError) {
      alert.error(
        'The post is too large to save automatically. Please remove the large media content (png, jpg, gif etc...) from it.'
      );
    }
  }, [displayContentError]);

  useEffect(() => {
    dispatch(loadSeoAnalyzer());
    const additionalKeywordsButtonLocalStorage = localStorage.getItem(
      PostTypes.SET_ADDITIONAL_KEYWORDS_BUTTON
    );
    const instructButtonLocalStorage = localStorage.getItem(
      PostTypes.SET_INSTRUCTIONS_BUTTON
    );
    dispatch(
      setAdditionalKeywordsButtonOn(
        additionalKeywordsButtonLocalStorage == null ||
          additionalKeywordsButtonLocalStorage === 'true'
      )
    );
    dispatch(
      setInstructionsButton(
        instructButtonLocalStorage == null ||
          instructButtonLocalStorage === 'true'
      )
    );
  }, []);

  function generateTextFn(editor, action, selection = '') {
    if (action === 'write-more') {
      if (!editorLoading && !postTitle) {
        alert.error('Post title required');
        return;
      }
    }
    let lookback = '';
    if (action === 'generate-list') {
      setEditorLoadingList(true);
    } else if (action === 'generate-steps') {
      setEditorLoadingSteps(true);
    } else if (action === 'write-paragraph') {
      setEditorLoadingParagraph(true);
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    } else if (action === 'rewrite-text') {
      setEditorLoadingRewrite(true);
    } else if (action === 'summarize-text') {
      setEditorLoadingSummarize(true);
    } else if (action === 'generate-outline') {
      setEditorLoadingOutline(true);
    } else if (action === 'ai-command') {
      setEditorLoadingCommand(true);
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    } else {
      setEditorLoadingText(true);
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    }
    if (action === 'generate-outline') {
      setSaveOutline(false);
    } else {
      setSaveText(false);
    }
    setEditorLoading(true);
    setGenerating(true);
    props.updateProgressState('generating', true);
    setMinTextCharLimitReached(false);
    track('Blog post creator', {
      Clicked: action,
    });
    request
      .send(`/api/projects/${project_id}/posts/${postId}/${action}`, {
        selection: selection,
        lookback: lookback,
        tone: tone.value,
        instructions: instructionButtonOn ? instructions : '',
        use_brand_voice: useBrandVoice,
        additional_keywords: {
          selected: additionalKeywordsButtonOn,
          keywords: additionalKeywordsButtonOn
            ? selectedKeywords.split(',')
            : [],
        },
        length,
      })
      .then(response => {
        setEditorLoading(false);
        setGenerating(false);
        props.updateProgressState('generating', false);
        setEditorLoadingList(false);
        setEditorLoadingSteps(false);
        setEditorLoadingParagraph(false);
        setEditorLoadingRewrite(false);
        setEditorLoadingSummarize(false);
        setEditorLoadingText(false);
        setEditorLoadingOutline(false);
        setEditorLoadingCommand(false);
        if (!response.isAxiosError) {
          if (response.success) {
            if (editor !== null && editor.getContent() !== null) {
              if (
                action === 'generate-list' ||
                action === 'generate-steps' ||
                action === 'write-paragraph' ||
                action === 'ai-command'
              ) {
                editor.selection.collapse();
                editor.insertContent('<p></p>');
              }
            }

            setAIPower(0);
            setTextData(response.content.output.html);
            if (action === 'generate-outline') {
              setSaveOutline(true);
            } else {
              setSaveText(true);
            }
            if (action === 'generate-outline') {
              // setEditorContent(response.content);
              // window.userGuiding.identify(props.user.email, {
              //   hasOutline: true,
              // });
            } else if (
              action === 'rewrite-text' ||
              action === 'summarize-text'
            ) {
              dispatch(
                setUpdatedContentPopup({
                  opened: true,
                  content: response.content.output.html,
                })
              );
            } else {
              const text = response.content.output.html;
              const undoLevel =
                editor.undoManager.data[editor.undoManager.data.length - 1];
              let index = 0;
              const onInsert = val => {
                editor.insertContent(val);
                editor.undoManager.clear();
                setTimeout(() => {
                  const done = typeWriter(index, text, onInsert, onIndexChange);
                  if (done) {
                    editor.undoManager.data.push(undoLevel);
                    editor.undoManager.add();
                  }
                }, 1);
              };
              const onIndexChange = newIndex => {
                index = newIndex;
              };
              typeWriter(index, text, onInsert, onIndexChange);
            }
            if (action === 'write-paragraph') {
              // window.userGuiding.identify(props.user.email, {
              //   hasParagraph: true,
              // });
            }
          } else {
            alert.error(response.message);
            // track('Received inappropriate outline', {
            //   Action: action,
            //   Keyword: phrase,
            //   Topics: topics,
            // });
          }
        } else {
          alert.error(isolateError(response));
        }
      });
  }

  function setEditorContent(text) {
    if (props?.editorRef?.current?.editor?.setContent) {
      props.editorRef.current.editor.setContent(text);
    }
  }

  function closeLibraryModal() {
    showLibraryModal(false);
  }

  function closeImageMetaModal() {
    showImageMetaModal(false);
  }

  function updateImage(file) {
    if (file) {
      const images = file.map(image => {
        return `<br><img src="${image.url}" alt="${image.alt_text ??
          ''}" title="${image.title ?? ''}" longdesc="${image.description ??
          ''}" style="max-width:80%;"/><br>`;
      });
      props.editorRef.current.editor.insertContent(images.toString());
    }
    closeLibraryModal();
  }

  function updateImageMeta({ alt_text, title, description }) {
    imageMeta.target.alt = alt_text;
    imageMeta.target.title = title;
    imageMeta.target.longdesc = description;
    closeImageMetaModal();
  }

  function getWordCount() {
    request
      .send(`/api/projects/${project_id}/posts/${id}/wordcount`, null, 'get')
      .then(data => {
        if (!data.isAxiosError) {
          if (data) {
            setTargetWordCount(data.word_count !== 0 ? data.word_count : 'n/a');
            props.updateProgressState(
              'targetWordCount',
              data.word_count !== 0 ? data.word_count : 'n/a'
            );
          }
        } else {
          alert.error(isolateError(data));
        }
      });
  }

  function updateWordCount() {
    if (
      props?.editorRef?.current?.editor?.plugins?.wordcount?.body?.getWordCount
    ) {
      const titleCount = postTitle ? postTitle.split(' ').length : 0;
      const count =
        titleCount +
        props?.editorRef?.current?.editor?.plugins?.wordcount?.body?.getWordCount();
      props.updateProgressState('wordCount', count);
      return count;
    }
  }

  function getSeo() {
    const worldCount = updateWordCount();
    const params = {
      phrase,
      body: blocks,
      worldCount,
      projectDomain,
      userDomain,
      seoAnalyzer,
    };
    const data = getSeoData(params);
    setWordCount(worldCount);
    setSeoData(data);
    props.updateSeoTool('seoData', data);
    props.updateProgressState('seoData', data);
    setAutoSave(false);
  }

  function isPlagiarismConfirmed(confirm) {
    setConfirmModelOpen(false);
    if (confirm) {
      const selected_text = props.editorRef.current.editor.selection.getContent(
        {
          format: 'html',
        }
      );
      if (!selected_text) {
        return false;
      }

      setEditorLoadingPlagiarism(true);
      setEditorLoading(true);
      request
        .send(
          `/api/projects/${project_id}/posts/${id}/plagiarism`,
          { selected_text: selected_text },
          'post'
        )
        .then(resp => {
          setEditorLoadingPlagiarism(false);
          setEditorLoading(false);
          if (!resp.isAxiosError) {
            if (resp) {
              setBlocks(resp.body);
              // props.updateProgressState('blocks', resp.body);
              // setBlocksLoaded(true);
              props.addPlagiarism(id, resp.api_response);
              setPlagiarismData(resp.api_response);
              setPlagiarismFound(resp.plagiarism_found ? 'yes' : 'no');
              track('Blog post creator', {
                Plagiarism: resp.plagiarism_found ? true : false,
              });
              if (resp.plagiarism_found) {
                const body = resp.body.filter(data => data.type === 'Body');
                if (body.length > 0) {
                  props.editorRef.current.editor.selection.setContent(
                    resp.body_txt
                  );
                }
              }
            }
          } else {
            alert.error(isolateError(resp));
          }
        });
    }
  }

  function plagiarismCheckFn() {
    setConfirmModelOpen(true);
    // const selected_text = props.editorRef.current.editor.selection.getContent({
    //   format: 'html',
    // });
    // if (!selected_text) {
    //   return false;
    // }
    // setEditorLoadingPlagiarism(true);
    // setEditorLoading(true);
    // request
    //   .send(
    //     `/api/projects/${project_id}/posts/${id}/plagiarism`,
    //     { selected_text: selected_text },
    //     'post'
    //   )
    //   .then(resp => {
    //     setEditorLoadingPlagiarism(false);
    //     setEditorLoading(false);
    //     if (!resp.isAxiosError) {
    //       if (resp) {
    //         setBlocks(resp.body);
    //         // props.updateProgressState('blocks', resp.body);
    //         // setBlocksLoaded(true);
    //         setPlagiarismData(resp.api_response);
    //         setPlagiarismFound(resp.plagiarism_found ? 'yes' : 'no');
    //         track('Blog post creator', {
    //           Plagiarism: resp.plagiarism_found ? true : false,
    //         });
    //         if (resp.plagiarism_found) {
    //           const body = resp.body.filter(data => data.type === 'Body');
    //           if (body.length > 0) {
    //             props.editorRef.current.editor.selection.setContent(
    //               resp.body_txt
    //             );
    //           }
    //         }
    //       }
    //     } else {
    //       alert.error(isolateError(resp));
    //     }
    //   });
  }

  function generatingPost(loading) {
    setGenerating(loading);
    props.updateProgressState('generating', loading);
  }

  function submitPost(data, action, status) {
    let url = `/api/projects/${project_id}/posts`;
    if (saveAPICalling === true) {
      return;
    }
    if (id) {
      url += `/${id}`;
      let method = 'put';
      let checkPlagiat = data['checkPlagiat'];
      setSaveAPICalling(true);
      request.send(url, data, method).then(data => {
        if (!data.isAxiosError) {
          dispatch(addPostId(data.id));
          if (action === 'auto-save') {
            setData(data);
            setBlocks(data.body);
            props.updateProgressState('blocks', data.body);
            props.updateSeoTool('setBlocks', data.body);
            setSaveAPICalling(false);
            return;
          }
          if (action === 'preview') {
            dispatch(addPostTitle(''));
            history.push(`/project/${project_id}/post-preview/${id}`);
          } else if (action === 'exit') {
            dispatch(addPostTitle(''));
            dispatch(addPostVersionIndex(null));
            dispatch(addPostId(null));
            dispatch(addPostVersionId(null));
            dispatch(addPostVersions([]));
            dispatch(addGeneratedPostTitles([]));
            dispatch(addGeneratedPostOutlines([]));
            dispatch(addSelectedKeywords(''));
            dispatch(addInstructions(''));
            showExitModal(false);
            history.push(`/project/${project_id}/drafts`);
          } else if (
            action === 'published' ||
            action === 'suggestions' ||
            action === 'is-public' ||
            action === 'save-url'
          ) {
            if (action === 'published') {
              // alert.success(`Post is published`);
              // track('Blog post creator', { Published: true });
            } else if (action === 'is-public') {
              track('Blog post creator', {
                Public: data.is_public,
              });
            } else if (action === 'save-url') {
              alert.success(`Post url saved`);
              showChangeURLModal(false);
            } else {
              alert.success(
                `Suggestions ${data.is_suggestions ? 'on' : 'off'}`
              );
              track('Blog post creator', {
                Suggestions: data.is_suggestions,
              });
            }
            setData(data);
          } else {
            alert.success(`Saved as ${status}`);
          }
          if (checkPlagiat) {
            plagiarismCheckFn();
          }
        } else {
          alert.error(isolateError(data));
        }
        setSaveAPICalling(false);
      });
    } else {
      alert.error('Post id does not exist');
    }
  }

  function titleCaseHeadings(headings) {
    let caseHeadings = [];
    if (headings && headings.length > 0) {
      caseHeadings = headings.map(heading => titleCase(heading));
    } else {
      caseHeadings.push('no suggestions available');
    }
    return caseHeadings;
  }

  function validateTitle() {
    return blocks[0].state.text;
  }

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  function updateImageBlockState(key, file, data, action, i) {
    const newBlocks = [...blocks];
    if (key === '1') {
      newBlocks[key].state.file = file;
      setHeroImage(null);
    } else {
      newBlocks[key].state.files[i] = file;
      setBodyImages({});
    }
    //setBlocks(newBlocks);
    submitPost({ ...data, body: newBlocks }, action);
  }

  function getImageFromBlocks(i, blocks) {
    if (blocks[i].type === 'HeroImage') {
      setHeroImage(blocks[i].state.file);
    }
    if (blocks[i].type === 'Image') {
      const newBodyImages = { ...bodyImages };
      newBodyImages[i] = blocks[i].state.files;
      setBodyImages(newBodyImages);
    }
  }

  function updateBlockState(i, state) {
    const newBlocks = [...blocks];
    newBlocks[i].state = state;
    setBlocks(newBlocks);
    // props.updateProgressState('blocks', newBlocks);
    getImageFromBlocks(i, newBlocks);
  }

  function removeBlockFromTemplate(i) {
    const newBlocks = [...blocks];
    newBlocks.splice(i, 1);
    setBlocks(newBlocks);
    props.updateProgressState('blocks', newBlocks);
  }

  function moveBlockUp(i) {
    if (i > 2) {
      const newBlocks = [...blocks];
      var f = newBlocks.splice(i, 1)[0];
      newBlocks.splice(i - 1, 0, f);
      setBlocks(newBlocks);
      props.updateProgressState('blocks', newBlocks);
    }
  }

  function moveBlockDown(i) {
    const newBlocks = [...blocks];
    var f = newBlocks.splice(i, 1)[0];
    newBlocks.splice(i + 1, 0, f);
    setBlocks(newBlocks);
    props.updateProgressState('blocks', newBlocks);
  }

  function closeDeleteModal(deleted) {
    if (deleted === true) {
      history.push(`/project/${project_id}/drafts`);
      dispatch(addPostTitle(''));
      dispatch(addPostId(null));
      dispatch(addPostVersionIndex(null));
      dispatch(addPostVersionId(null));
      dispatch(addPostVersions([]));
      dispatch(addGeneratedPostTitles([]));
      dispatch(addGeneratedPostOutlines([]));
      dispatch(addSelectedKeywords(''));
      dispatch(addInstructions(''));
    }
    showDeleteModal(false);
  }

  function closeShareModal() {
    showShareModal(false);
  }

  function sharePost() {
    submitPost({ body: blocks, is_public: !data.is_public }, 'is-public');
  }

  function closeChangeURLModal() {
    showChangeURLModal(false);
  }

  function changeURL(url) {
    submitPost({ body: blocks, domain: url }, 'save-url');
  }

  function closeComingSoonModal() {
    showComingSoonModal(false);
  }

  function scrollListener(e) {
    const scrollTop = e.target.documentElement.scrollTop;
    if (scrollTop > 90 && window.innerWidth >= 576) {
      e.target.body.classList.add('y-scrolling');
    } else {
      e.target.body.classList.remove('y-scrolling');
    }
  }

  function _is_initial_data(data) {
    let is_empty = true;
    data.forEach(element => {
      if (element['type'] === 'Title') {
        if (
          'state' in element &&
          'text' in element['state'] &&
          element['state']['text'] == ''
        ) {
        } else {
          is_empty = false;
        }
      } else if (element['type'] === 'HeroImage') {
        if (
          'state' in element &&
          'file' in element['state'] &&
          element['state']['file'] == null
        ) {
        } else {
          is_empty = false;
        }
      } else if (element['type'] === 'Body') {
        if (
          'state' in element &&
          'text' in element['state'] &&
          element['state']['text'] == ''
        ) {
        } else {
          is_empty = false;
        }
      }
    });
    return is_empty;
  }

  const savePostListener = useCallback(() => {
    if (data.body !== blocks && !isEditorFocus && dataLoaded) {
      if (!_is_initial_data(blocks) || apiRespBody == null) {
        setAutoSave(true);
        setAutoSaveDate(Date.now());
        props.updateProgressState('autoSaveDate', Date.now());
        if (
          blocks &&
          blocks.length === 3 &&
          blocks[2].type === 'Body' &&
          blocks[2].state.text
        ) {
          if (blocks[2].state.text.length > 0) {
            const blob = new Blob([blocks[2].state.text], {
              type: 'text/plain',
            });
            if (blob.size / 1000 > 300) {
              setDisplayContentError(true);
              props.updateProgressState('autoSaveDate', null);
              return;
            }
          }
        }
        submitPost(
          {
            body: blocks,
            step: 2,
          },
          'auto-save'
        );
      }
    }
  }, [data, blocks, isEditorFocus]);

  useEffect(() => {
    const interval = setInterval(savePostListener, 2500);
    return () => clearInterval(interval);
  }, [data, blocks, isEditorFocus]);

  // useEffect(() => {
  //   console.log('blocks', props.editorRef?.current);
  //   if (
  //     props.editorRef &&
  //     props.editorRef.current &&
  //     props.editorRef.current.editor
  //   ) {
  //     console.log(
  //       props.editorRef?.current?.editor.setContent(blocks[2].state.text)
  //     );
  //   }
  // }, [blocks]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  // Write more on button action
  useEffect(() => {
    if (writeMoreButton === true) {
      generateTextFn(props.editorRef.current.editor, 'write-more');
      setWriteMoreButton(false);
    }
  }, [writeMoreButton]);

  // Save generated outline
  useEffect(() => {
    if (saveOutline) {
      const newVersions = versions;
      const newId = Date.now();
      newVersions.push({ text: textData, id: newId });
      dispatch(addPostVersions(newVersions));
      dispatch(addPostVersionId(newId));
      dispatch(addEditorValue(BLOCK_TYPE, textData, htmlId));
      const bodyIndex = getBodyIndexFromBlocks();
      updateBlockState(bodyIndex, {
        ...blocks[bodyIndex].state,
        text: textData,
        id: newId,
      });
      setSaveOutline(false);
    }
  }, [saveOutline]);

  // Save generated text in version
  useEffect(() => {
    if (saveText && props?.editorRef?.current?.editor?.getContent) {
      if (
        props?.editorRef?.current?.editor &&
        props?.editorRef?.current?.editor?.getContent()
      ) {
        const newVersions = versions;
        const content = props.editorRef.current.editor.getContent();
        dispatch(addEditorValue(BLOCK_TYPE, content, htmlId));
        if (versionId) {
          let objIndex = newVersions.findIndex(obj => obj.id === versionId);
          newVersions[objIndex].text = content;
          dispatch(addPostVersions(newVersions));
        } else if (versionIndex !== null && versionIndex >= 0) {
          newVersions[versionIndex] = content;
          dispatch(addPostVersions(newVersions));
        }
      }
      setSaveText(false);
    }
  }, [saveText]);

  useEffect(() => {
    const selection = props.editorRef.current.editor.selection.getContent({
      format: 'text',
    });

    if (!selectionBoundingClientRect || selection === '') {
      // Keep menu open in these loading cases
      if (!editorLoadingCommand) {
        setShowMoreMenu(false);
      }
    } else {
      // Not update the toolbar placement on these cases
      if (!editorLoadingParagraph && !editorLoadingCommand) {
        const top = Math.abs(
          Math.round(selectionBoundingClientRect?.top + 120)
        );
        const left = Math.round(selectionBoundingClientRect?.left);
        setAiControlsTop(top);
        setAiControlsLeft(left);
      }
    }
  }, [
    selectionBoundingClientRect,
    props.editorRef,
    editorLoadingParagraph,
    editorLoadingCommand,
  ]);

  const checkSpacerDisabled = () => {
    const selection = props?.editorRef?.current?.editor?.selection?.getContent({
      format: 'html',
    });
    const sentences = selection ? getSentencesFromHtml(selection) : [];
    return editorLoading || sentences.length < 4;
  };

  const magicSpacer = () => {
    if (
      props.editorRef &&
      props.editorRef.current &&
      props.editorRef.current.editor &&
      props.editorRef.current.editor.getContent()
    ) {
      const selection = props.editorRef.current.editor.selection.getContent({
        format: 'html',
      });
      const sentences = selection ? getSentencesFromHtml(selection) : [];
      if (sentences.length >= 4) {
        props.editorRef.current.editor.selection.setContent(
          magicSpacerFormat(selection)
        );
      }
    }
  };
  const renderAIControls = () => {
    const selection = props.editorRef.current.editor.selection.getContent({
      format: 'text',
    });
    if (
      !selectionBoundingClientRect ||
      (selection === '' && !editorLoadingParagraph && !editorLoadingCommand)
    )
      return null;

    const editorSelectionNodeName = props?.editorRef?.current?.editor?.selection.getNode()
      .nodeName;

    return (
      <div
        className={classNames(
          'absolute hidden md:flex items-center justify-center z-20 border-gray-200 border bg-white rounded-md'
        )}
        style={{
          top: aiControlsTop,
          left: aiControlsLeft,
        }}
        onClick={e => e.stopPropagation()}
      >
        {/* Character count */}
        <Tooltip title="Number of selected characters" arrow>
          <div className="py-1 px-2 select-none h-full">
            <p className="text-gray-700 bg-gray-200 m-0 px-1 rounded-md text-sm">
              {selectionCharCount}
            </p>
          </div>
        </Tooltip>
        {/* Paragraph */}
        <Control
          label="Paragraph"
          tooltip={`AI write a paragraph - Highlight 10 to 100 char.`}
          loading={editorLoadingParagraph}
          disabled={editorLoading || !paragraphCharLimitReached}
          showLabel
          renderIcon={() => (
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <path
                d="M10.6253 9.67285H5.66699C5.37658 9.67285 5.13574 9.43202 5.13574 9.1416C5.13574 8.85118 5.37658 8.61035 5.66699 8.61035H10.6253C10.9157 8.61035 11.1566 8.85118 11.1566 9.1416C11.1566 9.43202 10.9157 9.67285 10.6253 9.67285Z"
                fill="#883AE1"
              />
              <path
                d="M8.76949 12.5063H5.66699C5.37658 12.5063 5.13574 12.2655 5.13574 11.9751C5.13574 11.6847 5.37658 11.4438 5.66699 11.4438H8.76949C9.05991 11.4438 9.30074 11.6847 9.30074 11.9751C9.30074 12.2655 9.05991 12.5063 8.76949 12.5063Z"
                fill="#883AE1"
              />
              <path
                d="M9.91699 5.28109H7.08366C6.40366 5.28109 5.13574 5.28109 5.13574 3.33317C5.13574 1.38525 6.40366 1.38525 7.08366 1.38525H9.91699C10.597 1.38525 11.8649 1.38525 11.8649 3.33317C11.8649 4.01317 11.8649 5.28109 9.91699 5.28109ZM7.08366 2.44775C6.38241 2.44775 6.19824 2.44775 6.19824 3.33317C6.19824 4.21859 6.38241 4.21859 7.08366 4.21859H9.91699C10.8024 4.21859 10.8024 4.03442 10.8024 3.33317C10.8024 2.44775 10.6182 2.44775 9.91699 2.44775H7.08366Z"
                fill="#883AE1"
              />
              <path
                d="M10.625 16.6144H6.375C2.39417 16.6144 1.59375 14.7869 1.59375 11.8331V7.58314C1.59375 4.35314 2.7625 2.97189 5.63833 2.82314C5.92167 2.80898 6.18375 3.02856 6.19792 3.32606C6.21208 3.62356 5.98542 3.86439 5.695 3.87856C3.68333 3.99189 2.65625 4.59398 2.65625 7.58314V11.8331C2.65625 14.454 3.17333 15.5519 6.375 15.5519H10.625C13.8267 15.5519 14.3437 14.454 14.3437 11.8331V7.58314C14.3437 4.59398 13.3167 3.99189 11.305 3.87856C11.0146 3.86439 10.7879 3.60939 10.8021 3.31898C10.8162 3.02856 11.0713 2.80189 11.3617 2.81606C14.2375 2.97189 15.4062 4.35314 15.4062 7.57606V11.8261C15.4062 14.7869 14.6058 16.6144 10.625 16.6144Z"
                fill="#883AE1"
              />
            </svg>
          )}
          onClick={() => {
            if (postTitle) {
              if (!editorLoading && paragraphCharLimitReached) {
                setWriteParagraphButton(true);
                if (
                  props.editorRef &&
                  props.editorRef.current &&
                  props.editorRef.current.editor &&
                  props.editorRef.current.editor.getContent()
                ) {
                  const selection = props.editorRef.current.editor.selection.getContent(
                    {
                      format: 'text',
                    }
                  );
                  generateTextFn(
                    props.editorRef.current.editor,
                    'write-paragraph',
                    selection
                  );
                  setWriteParagraphButton(false);
                  if (
                    stepNovaOnBoardingGuide ===
                    novaOnBoardingSteps.COMPLETE_CHECK_LIST
                  ) {
                    dispatch(
                      setNovaOnBoardingCheckList(
                        novaOnBoardingCheckLists.GENERATE_PARAGRAPH_FOR_EACH_HEADER
                      )
                    );
                  }
                }
              }
            } else {
              alert.error('Post title required');
            }
          }}
        />
        <Control
          label="Command"
          tooltip={`Tell the AI what text to generate - Highlight at least 20 char.`}
          loading={editorLoadingCommand}
          disabled={editorLoading || !commandCharLimitReached}
          showLabel
          renderIcon={() => (
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <path
                d="M8.47884 16.6144C4.28551 16.6144 0.864258 13.2003 0.864258 8.99984C0.864258 4.79942 4.28551 1.38525 8.47884 1.38525C12.6722 1.38525 16.0934 4.79942 16.0934 8.99984C16.0934 13.2003 12.6793 16.6144 8.47884 16.6144ZM8.47884 2.44775C4.86634 2.44775 1.92676 5.38734 1.92676 8.99984C1.92676 12.6123 4.86634 15.5519 8.47884 15.5519C12.0913 15.5519 15.0309 12.6123 15.0309 8.99984C15.0309 5.38734 12.0913 2.44775 8.47884 2.44775Z"
                fill="#7429C6"
              />
              <path
                d="M8.49975 14.1355C6.97683 14.1355 5.546 13.3917 4.67475 12.138C4.50475 11.8971 4.5685 11.5642 4.80933 11.4013C5.05017 11.2313 5.38308 11.2951 5.546 11.5359C6.21892 12.4992 7.32392 13.0801 8.49975 13.0801C9.67558 13.0801 10.7806 12.5063 11.4535 11.5359C11.6235 11.2951 11.9493 11.2384 12.1902 11.4013C12.431 11.5713 12.4877 11.8971 12.3248 12.138C11.4535 13.3917 10.0227 14.1355 8.49975 14.1355Z"
                fill="#7429C6"
              />
            </svg>
          )}
          onClick={e => {
            e.stopPropagation();
            if (!editorLoading && commandCharLimitReached) {
              setCommandTextButton(true);
              if (
                props.editorRef &&
                props.editorRef.current &&
                props.editorRef.current.editor &&
                props.editorRef.current.editor.getContent()
              ) {
                const selection = props.editorRef.current.editor.selection.getContent(
                  {
                    format: 'text',
                  }
                );
                generateTextFn(
                  props.editorRef.current.editor,
                  'ai-command',
                  selection
                );
                setCommandTextButton(false);
                if (
                  stepNovaOnBoardingGuide ===
                  novaOnBoardingSteps.COMPLETE_CHECK_LIST
                ) {
                  dispatch(
                    setNovaOnBoardingCheckList(
                      novaOnBoardingCheckLists.EDIT_WITH_REPHRASE_OR_COMMAND
                    )
                  );
                }
              }
            }
          }}
        />
        <Control
          label="Rewrite"
          tooltip={`Rewrite without changing meaning - Highlight 30 to 4000 char.`}
          loading={editorLoadingRewrite}
          disabled={editorLoading || !rewriteCharLimitReached}
          showLabel
          renderIcon={() => (
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <path
                d="M14.4645 9.15583C14.1741 9.15583 13.9332 8.91499 13.9332 8.62458V6.27295C13.9332 5.39462 13.2178 4.6792 12.3395 4.6792H2.53613C2.24572 4.6792 2.00488 4.43837 2.00488 4.14795C2.00488 3.85753 2.24572 3.6167 2.53613 3.6167H12.3395C13.8057 3.6167 14.9957 4.8067 14.9957 6.27295V8.62458C14.9957 8.92208 14.7549 9.15583 14.4645 9.15583Z"
                fill="#7429C6"
              />
              <path
                d="M4.77447 6.92463C4.63989 6.92463 4.5053 6.87502 4.39905 6.76877L2.16072 4.53046C2.06155 4.43129 2.00488 4.29669 2.00488 4.15502C2.00488 4.01335 2.06155 3.87879 2.16072 3.77963L4.39905 1.54127C4.60447 1.33585 4.94447 1.33585 5.14988 1.54127C5.3553 1.74669 5.3553 2.08673 5.14988 2.29214L3.28699 4.15502L5.14988 6.01794C5.3553 6.22336 5.3553 6.56336 5.14988 6.76877C5.04363 6.86794 4.90905 6.92463 4.77447 6.92463Z"
                fill="#7429C6"
              />
              <path
                d="M14.4645 14.3761H4.66113C3.19488 14.3761 2.00488 13.1861 2.00488 11.7198V9.36816C2.00488 9.07775 2.24572 8.83691 2.53613 8.83691C2.82655 8.83691 3.06738 9.07775 3.06738 9.36816V11.7198C3.06738 12.5982 3.7828 13.3136 4.66113 13.3136H14.4645C14.7549 13.3136 14.9957 13.5544 14.9957 13.8448C14.9957 14.1353 14.7549 14.3761 14.4645 14.3761Z"
                fill="#7429C6"
              />
              <path
                d="M12.2258 16.6145C12.0912 16.6145 11.9566 16.565 11.8504 16.4587C11.6449 16.2533 11.6449 15.9133 11.8504 15.7078L13.7133 13.845L11.8504 11.982C11.6449 11.7766 11.6449 11.4366 11.8504 11.2312C12.0558 11.0258 12.3958 11.0258 12.6012 11.2312L14.8395 13.4695C14.9387 13.5687 14.9954 13.7033 14.9954 13.845C14.9954 13.9866 14.9387 14.1212 14.8395 14.2204L12.6012 16.4587C12.502 16.565 12.3674 16.6145 12.2258 16.6145Z"
                fill="#7429C6"
              />
            </svg>
          )}
          onClick={() => {
            if (!editorLoading && rewriteCharLimitReached) {
              setRewriteTextButton(true);
              if (
                props.editorRef &&
                props.editorRef.current &&
                props.editorRef.current.editor &&
                props.editorRef.current.editor.getContent()
              ) {
                const selection = props.editorRef.current.editor.selection.getContent(
                  {
                    format: 'text',
                  }
                );
                generateTextFn(
                  props.editorRef.current.editor,
                  'rewrite-text',
                  selection
                );
                setRewriteTextButton(false);
              }
            }
          }}
        />
        {/* H2 */}
        <Control
          renderIcon={() => <p className="text-sm leading-4">H2</p>}
          focused={editorSelectionNodeName === 'H2'}
          onClick={() => {
            const h2Button = props?.editorRef?.current?.editor?.ui?.registry?.getAll()
              .buttons.h2;
            if (!!h2Button) {
              h2Button.onAction();
            }
          }}
        />
        {/* H3 */}
        <Control
          renderIcon={() => <p className="text-sm leading-4">H3</p>}
          focused={editorSelectionNodeName === 'H3'}
          onClick={() => {
            const h3Button = props?.editorRef?.current?.editor?.ui?.registry?.getAll()
              .buttons.h3;
            if (!!h3Button) {
              h3Button.onAction();
            }
          }}
        />
        {/* H4 */}
        <Control
          renderIcon={() => <p className="text-sm leading-4">H4</p>}
          focused={editorSelectionNodeName === 'H4'}
          onClick={() => {
            const h4Button = props?.editorRef?.current?.editor?.ui?.registry?.getAll()
              .buttons.h4;
            if (!!h4Button) {
              h4Button.onAction();
            }
          }}
        />
        <Menu>
          <Menu.Button>
            <div
              onClick={() => setShowMoreMenu(preState => !preState)}
              className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
            >
              <DotsVerticalIcon
                className="hidden flex-shrink-0 h-5 w-5 text-gray-400 lg:block"
                aria-hidden="true"
              />
              <span className="text-xs text-gray-400">More</span>
            </div>
          </Menu.Button>
          <Transition
            show={showMoreMenu}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`origin-top-right absolute right-0 ${
                aiControlsTop - props.mainRef.current.scrollTop < 220
                  ? 'top-12'
                  : 'bottom-12'
              } mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              static
            >
              <Menu.Item>
                <Control
                  label="Listicle"
                  tooltip={`AI generate listicle - Highlight 20 to 300 char.`}
                  loading={editorLoadingList}
                  disabled={editorLoading || !listCharLimitReached}
                  showLabel
                  onClick={() => {
                    if (!editorLoading && listCharLimitReached) {
                      setGenerateListButton(true);
                      if (
                        props.editorRef &&
                        props.editorRef.current &&
                        props.editorRef.current.editor &&
                        props.editorRef.current.editor.getContent()
                      ) {
                        const selection = props.editorRef.current.editor.selection.getContent(
                          {
                            format: 'text',
                          }
                        );
                        generateTextFn(
                          props.editorRef.current.editor,
                          'generate-list',
                          selection
                        );
                        setGenerateListButton(false);
                      }
                    }
                  }}
                  renderIcon={() => (
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M12.4805 7.31396H8.76172C8.4713 7.31396 8.23047 7.07313 8.23047 6.78271C8.23047 6.4923 8.4713 6.25146 8.76172 6.25146H12.4805C12.7709 6.25146 13.0117 6.4923 13.0117 6.78271C13.0117 7.07313 12.778 7.31396 12.4805 7.31396Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M5.04315 7.85272C4.90857 7.85272 4.77398 7.80314 4.66773 7.69689L4.13648 7.16564C3.93107 6.96022 3.93107 6.62022 4.13648 6.41481C4.3419 6.20939 4.6819 6.20939 4.88732 6.41481L5.04315 6.57064L6.26148 5.3523C6.4669 5.14689 6.8069 5.14689 7.01232 5.3523C7.21773 5.55772 7.21773 5.89772 7.01232 6.10314L5.41857 7.69689C5.3194 7.79605 5.18482 7.85272 5.04315 7.85272Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M12.4805 12.2725H8.76172C8.4713 12.2725 8.23047 12.0316 8.23047 11.7412C8.23047 11.4508 8.4713 11.21 8.76172 11.21H12.4805C12.7709 11.21 13.0117 11.4508 13.0117 11.7412C13.0117 12.0316 12.778 12.2725 12.4805 12.2725Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M5.04315 12.8107C4.90857 12.8107 4.77398 12.7611 4.66773 12.6549L4.13648 12.1236C3.93107 11.9182 3.93107 11.5782 4.13648 11.3728C4.3419 11.1674 4.6819 11.1674 4.88732 11.3728L5.04315 11.5286L6.26148 10.3103C6.4669 10.1049 6.8069 10.1049 7.01232 10.3103C7.21773 10.5157 7.21773 10.8557 7.01232 11.0611L5.41857 12.6549C5.3194 12.7541 5.18482 12.8107 5.04315 12.8107Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M10.6253 16.6144H6.37533C2.52908 16.6144 0.885742 14.9711 0.885742 11.1248V6.87484C0.885742 3.02859 2.52908 1.38525 6.37533 1.38525H10.6253C14.4716 1.38525 16.1149 3.02859 16.1149 6.87484V11.1248C16.1149 14.9711 14.4716 16.6144 10.6253 16.6144ZM6.37533 2.44775C3.10991 2.44775 1.94824 3.60942 1.94824 6.87484V11.1248C1.94824 14.3903 3.10991 15.5519 6.37533 15.5519H10.6253C13.8907 15.5519 15.0524 14.3903 15.0524 11.1248V6.87484C15.0524 3.60942 13.8907 2.44775 10.6253 2.44775H6.37533Z"
                        fill="#7429C6"
                      />
                    </svg>
                  )}
                />
              </Menu.Item>
              <Menu.Item>
                <Control
                  label="Summarize"
                  tooltip={`Summarize to main idea - Highlight 30 to 4000 char.`}
                  loading={editorLoadingSummarize}
                  disabled={editorLoading || !summarizeCharLimitReached}
                  showLabel
                  renderIcon={() => (
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M3.54171 15.9915C3.01754 15.9915 2.50046 15.7931 2.10379 15.3965C1.72129 15.014 1.50879 14.504 1.50879 13.9585C1.50879 13.4131 1.72129 12.9031 2.10379 12.5206L11.3121 3.31229C12.1055 2.51895 13.3946 2.51895 14.188 3.31229C14.5705 3.69479 14.783 4.20478 14.783 4.7502C14.783 5.29562 14.5705 5.80562 14.188 6.18812L4.97962 15.3965C4.58296 15.7931 4.06587 15.9915 3.54171 15.9915ZM12.75 3.77979C12.5021 3.77979 12.2542 3.87187 12.063 4.06312L2.85462 13.2715C2.67046 13.4556 2.57129 13.6965 2.57129 13.9585C2.57129 14.2206 2.67046 14.4615 2.85462 14.6456C3.23004 15.021 3.85337 15.021 4.22879 14.6456L13.4371 5.43728C13.6213 5.25312 13.7205 5.01228 13.7205 4.7502C13.7205 4.48812 13.6213 4.24729 13.4371 4.06312C13.2459 3.87187 12.998 3.77979 12.75 3.77979Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M12.757 7.39911C12.6224 7.39911 12.4879 7.34953 12.3816 7.24328L10.2566 5.11827C10.0512 4.91286 10.0512 4.57286 10.2566 4.36744C10.462 4.16202 10.802 4.16202 11.0074 4.36744L13.1324 6.49244C13.3379 6.69786 13.3379 7.03786 13.1324 7.24328C13.0262 7.34953 12.8916 7.39911 12.757 7.39911Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M7.083 4.39567C7.04759 4.39567 7.01925 4.38858 6.98384 4.3815L6.0205 4.09817L5.05717 4.3815C4.92967 4.41692 4.79509 4.3815 4.71009 4.28942C4.618 4.19733 4.58259 4.06983 4.618 3.94233L4.90134 2.979L4.618 2.01567C4.58259 1.88817 4.618 1.75358 4.71009 1.66858C4.80217 1.5765 4.93675 1.54108 5.05717 1.5765L6.0205 1.85983L6.98384 1.5765C7.11134 1.54108 7.23884 1.5765 7.33092 1.66858C7.423 1.76067 7.45842 1.89525 7.423 2.01567L7.13967 2.979L7.423 3.94233C7.45842 4.06983 7.423 4.20442 7.33092 4.28942C7.26717 4.36025 7.17509 4.39567 7.083 4.39567ZM6.0205 3.37567C6.05592 3.37567 6.08425 3.38275 6.11967 3.38983L6.55884 3.51733L6.43134 3.07817C6.41009 3.01442 6.41009 2.94358 6.43134 2.87983L6.55884 2.44067L6.11967 2.56817C6.05592 2.58942 5.98509 2.58942 5.92134 2.56817L5.48217 2.44067L5.60967 2.87983C5.63092 2.94358 5.63092 3.01442 5.60967 3.07817L5.48217 3.51733L5.92134 3.38983C5.95675 3.38275 5.98509 3.37567 6.0205 3.37567Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M4.25 8.64567C4.21458 8.64567 4.18625 8.63858 4.15083 8.6315L3.1875 8.34817L2.22416 8.6315C2.09666 8.66692 1.96916 8.6315 1.87708 8.53942C1.785 8.44733 1.74958 8.31275 1.785 8.19233L2.06833 7.229L1.785 6.26567C1.74958 6.13817 1.785 6.00358 1.87708 5.91858C1.96916 5.83358 2.10375 5.79108 2.22416 5.8265L3.1875 6.10983L4.15083 5.8265C4.27125 5.79108 4.40583 5.8265 4.49791 5.91858C4.59 6.01067 4.62541 6.14525 4.59 6.26567L4.30666 7.229L4.59 8.19233C4.62541 8.31983 4.59 8.45442 4.49791 8.53942C4.43416 8.61025 4.34208 8.64567 4.25 8.64567ZM3.1875 7.62567C3.22291 7.62567 3.25125 7.63275 3.28666 7.63983L3.72583 7.76733L3.59833 7.32817C3.57708 7.26442 3.57708 7.19358 3.59833 7.12983L3.72583 6.69067L3.28666 6.81817C3.22291 6.83942 3.15208 6.83942 3.08833 6.81817L2.64916 6.69067L2.77666 7.12983C2.79791 7.19358 2.79791 7.26442 2.77666 7.32817L2.64916 7.76733L3.08833 7.63983C3.12375 7.63275 3.15208 7.62567 3.1875 7.62567Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M14.875 12.1877C14.8396 12.1877 14.8112 12.1806 14.7758 12.1735L13.8125 11.8902L12.8492 12.1735C12.7217 12.2089 12.5942 12.1735 12.5021 12.0814C12.41 11.9893 12.3746 11.8547 12.41 11.7343L12.6933 10.771L12.41 9.80766C12.3746 9.68016 12.41 9.54557 12.5021 9.46057C12.5942 9.37557 12.7287 9.33308 12.8492 9.36849L13.8125 9.65182L14.7758 9.36849C14.8962 9.33308 15.0308 9.36849 15.1229 9.46057C15.215 9.55266 15.2504 9.68724 15.215 9.80766L14.9317 10.771L15.215 11.7343C15.2504 11.8618 15.215 11.9964 15.1229 12.0814C15.0592 12.1522 14.9671 12.1877 14.875 12.1877ZM13.8125 11.1677C13.8479 11.1677 13.8762 11.1747 13.9117 11.1818L14.3508 11.3093L14.2233 10.8702C14.2021 10.8064 14.2021 10.7356 14.2233 10.6718L14.3508 10.2327L13.9117 10.3602C13.8479 10.3814 13.7771 10.3814 13.7133 10.3602L13.2742 10.2327L13.4017 10.6718C13.4229 10.7356 13.4229 10.8064 13.4017 10.8702L13.2742 11.3093L13.7133 11.1818C13.7487 11.1747 13.7771 11.1677 13.8125 11.1677Z"
                        fill="#7429C6"
                      />
                    </svg>
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    if (!editorLoading && summarizeCharLimitReached) {
                      setSummarizeTextButton(true);
                      if (
                        props.editorRef &&
                        props.editorRef.current &&
                        props.editorRef.current.editor &&
                        props.editorRef.current.editor.getContent()
                      ) {
                        const selection = props.editorRef.current.editor.selection.getContent(
                          {
                            format: 'text',
                          }
                        );
                        generateTextFn(
                          props.editorRef.current.editor,
                          'summarize-text',
                          selection
                        );
                        setSummarizeTextButton(false);
                      }
                    }
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Control
                  label="Steps"
                  tooltip={`AI generate steps - Highlight 20 to 300 char.`}
                  loading={editorLoadingSteps}
                  disabled={editorLoading || !stepCharLimitReached}
                  showLabel
                  renderIcon={() => (
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M13.4587 12.3646C13.1682 12.3646 12.9274 12.1237 12.9274 11.8333V5.10417C12.9274 4.61542 12.5307 4.21875 12.042 4.21875H8.85449C8.56408 4.21875 8.32324 3.97792 8.32324 3.6875C8.32324 3.39708 8.56408 3.15625 8.85449 3.15625H12.042C13.1187 3.15625 13.9899 4.0275 13.9899 5.10417V11.8333C13.9899 12.1237 13.7491 12.3646 13.4587 12.3646Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M10.625 5.98941C10.5046 5.98941 10.3842 5.94691 10.285 5.869L8.16001 4.09816C8.0396 3.999 7.96875 3.85024 7.96875 3.68733C7.96875 3.52441 8.0396 3.38274 8.16001 3.27649L10.285 1.50566C10.5117 1.31441 10.8446 1.34983 11.0358 1.5765C11.2271 1.80316 11.1917 2.13608 10.965 2.32733L9.32874 3.68733L10.965 5.04733C11.1917 5.23858 11.22 5.57149 11.0358 5.79816C10.9296 5.92566 10.7737 5.98941 10.625 5.98941Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M3.54199 12.3646C3.25158 12.3646 3.01074 12.1237 3.01074 11.8333V6.875C3.01074 6.58458 3.25158 6.34375 3.54199 6.34375C3.83241 6.34375 4.07324 6.58458 4.07324 6.875V11.8333C4.07324 12.1237 3.83241 12.3646 3.54199 12.3646Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M3.71908 7.05192C2.15366 7.05192 0.885742 5.784 0.885742 4.21859C0.885742 2.65317 2.15366 1.38525 3.71908 1.38525C5.28449 1.38525 6.55241 2.65317 6.55241 4.21859C6.55241 5.784 5.28449 7.05192 3.71908 7.05192ZM3.71908 2.44775C2.74158 2.44775 1.94824 3.24109 1.94824 4.21859C1.94824 5.19609 2.74158 5.98942 3.71908 5.98942C4.69658 5.98942 5.48991 5.19609 5.48991 4.21859C5.48991 3.24109 4.69658 2.44775 3.71908 2.44775Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M3.54199 16.6147C2.07574 16.6147 0.885742 15.4247 0.885742 13.9585C0.885742 12.4922 2.07574 11.3022 3.54199 11.3022C5.00824 11.3022 6.19824 12.4922 6.19824 13.9585C6.19824 15.4247 5.00824 16.6147 3.54199 16.6147ZM3.54199 12.3647C2.66366 12.3647 1.94824 13.0802 1.94824 13.9585C1.94824 14.8368 2.66366 15.5522 3.54199 15.5522C4.42033 15.5522 5.13574 14.8368 5.13574 13.9585C5.13574 13.0802 4.42033 12.3647 3.54199 12.3647Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M13.458 16.6147C11.9918 16.6147 10.8018 15.4247 10.8018 13.9585C10.8018 12.4922 11.9918 11.3022 13.458 11.3022C14.9243 11.3022 16.1143 12.4922 16.1143 13.9585C16.1143 15.4247 14.9243 16.6147 13.458 16.6147ZM13.458 12.3647C12.5797 12.3647 11.8643 13.0802 11.8643 13.9585C11.8643 14.8368 12.5797 15.5522 13.458 15.5522C14.3363 15.5522 15.0518 14.8368 15.0518 13.9585C15.0518 13.0802 14.3363 12.3647 13.458 12.3647Z"
                        fill="#7429C6"
                      />
                    </svg>
                  )}
                  onClick={() => {
                    if (!editorLoading && stepCharLimitReached) {
                      setGenerateStepsButton(true);
                      if (
                        props.editorRef &&
                        props.editorRef.current &&
                        props.editorRef.current.editor &&
                        props.editorRef.current.editor.getContent()
                      ) {
                        const selection = props.editorRef.current.editor.selection.getContent(
                          {
                            format: 'text',
                          }
                        );
                        generateTextFn(
                          props.editorRef.current.editor,
                          'generate-steps',
                          selection
                        );
                        setGenerateStepsButton(false);
                      }
                    }
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Control
                  label="Plagiarism"
                  tooltip="Plagiarism checker. Highlight 200 to 4,000 char."
                  disabled={
                    editorLoading ||
                    selection.length < 200 ||
                    selection.length > 4000 ||
                    checkPlagiarismTriggered
                  }
                  showLabel
                  loading={checkPlagiarismTriggered}
                  renderIcon={() => (
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                    >
                      <path
                        d="M8.49987 16.6217C7.72779 16.6217 6.96279 16.395 6.3607 15.9488L3.31487 13.675C2.50737 13.0729 1.87695 11.8121 1.87695 10.8134V5.54335C1.87695 4.45252 2.67737 3.29085 3.70445 2.90835L7.23904 1.58377C7.94029 1.32169 9.04529 1.32169 9.74654 1.58377L13.2811 2.90835C14.3082 3.29085 15.1086 4.45252 15.1086 5.54335V10.8063C15.1086 11.8121 14.4782 13.0659 13.6707 13.6679L10.6249 15.9417C10.037 16.395 9.27195 16.6217 8.49987 16.6217ZM7.61445 2.58252L4.07987 3.9071C3.47779 4.13377 2.94654 4.89877 2.94654 5.55044V10.8134C2.94654 11.4863 3.42112 12.4284 3.95237 12.825L6.9982 15.0988C7.81279 15.7079 9.18695 15.7079 10.0086 15.0988L13.0545 12.825C13.5928 12.4213 14.0603 11.4863 14.0603 10.8134V5.54335C14.0603 4.89877 13.529 4.13377 12.927 3.90002L9.39237 2.57544C8.9107 2.40544 8.08904 2.40544 7.61445 2.58252Z"
                        fill="#7429C6"
                      />
                      <path
                        d="M7.55075 10.5797C7.41617 10.5797 7.28159 10.5301 7.17534 10.4239L6.03492 9.28346C5.82951 9.07804 5.82951 8.73804 6.03492 8.53262C6.24034 8.32721 6.58034 8.32721 6.78575 8.53262L7.55075 9.29762L10.2212 6.62721C10.4266 6.42179 10.7666 6.42179 10.972 6.62721C11.1774 6.83262 11.1774 7.17262 10.972 7.37804L7.92617 10.4239C7.81992 10.5301 7.68534 10.5797 7.55075 10.5797Z"
                        fill="#7429C6"
                      />
                    </svg>
                  )}
                  onClick={() => {
                    dispatch(setCheckPlagiarismTriggered(true));
                    dispatch(panelCollapse(sidePanelTypes.PLAGIARISM, false));
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Control
                  label="Fact check"
                  tooltip="Fact checker. Highlight 20 to 1000 char."
                  disabled={
                    (!webAccessEnabled &&
                      !smartWebNavigatorAccess &&
                      !hasTrialSubscription) ||
                    editorLoading ||
                    factCheckLoading ||
                    selection.length < 20 ||
                    selection.length > 1000
                  }
                  loading={factCheckLoading}
                  showLabel
                  renderIcon={() => (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      style={{ width: '18px' }}
                      className="mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                  onClick={() => {
                    dispatch(factCheck(selection));
                    dispatch(panelCollapse(sidePanelTypes.FACT_CHECKER, false));
                  }}
                />
              </Menu.Item>
              <Menu.Item>
                <Control
                  label="Spacer"
                  tooltip="Magic spacer. Highlight at least 4 sentences"
                  disabled={checkSpacerDisabled()}
                  showLabel
                  renderIcon={() => (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 18 18"
                      version="1.1"
                      className="mr-1"
                    >
                      <g id="surface1">
                        <path
                          fill="#7429C6"
                          d="M 0.757812 3.355469 L 17.242188 3.355469 C 17.457031 3.355469 17.621094 3.191406 17.621094 2.996094 C 17.621094 2.800781 17.457031 2.621094 17.242188 2.621094 L 0.757812 2.621094 C 0.542969 2.621094 0.378906 2.78125 0.378906 2.996094 C 0.378906 3.210938 0.542969 3.355469 0.757812 3.355469 Z M 11.25 7.835938 C 11.394531 7.835938 11.519531 7.765625 11.589844 7.640625 C 11.664062 7.511719 11.628906 7.351562 11.554688 7.246094 L 9.304688 4.25 C 9.160156 4.070312 8.839844 4.070312 8.695312 4.25 L 6.445312 7.246094 C 6.371094 7.351562 6.355469 7.511719 6.410156 7.640625 C 6.480469 7.765625 6.605469 7.835938 6.75 7.835938 L 8.621094 7.835938 L 8.621094 10.078125 L 6.75 10.078125 C 6.605469 10.078125 6.480469 10.167969 6.410156 10.292969 C 6.355469 10.417969 6.371094 10.5625 6.445312 10.6875 L 8.695312 13.660156 C 8.765625 13.769531 8.890625 13.824219 9 13.824219 C 9.109375 13.824219 9.234375 13.769531 9.304688 13.660156 L 11.554688 10.6875 C 11.628906 10.5625 11.644531 10.417969 11.589844 10.292969 C 11.519531 10.167969 11.394531 10.078125 11.25 10.078125 L 9.378906 10.078125 L 9.378906 7.835938 Z M 9 12.820312 L 7.507812 10.828125 L 10.492188 10.828125 Z M 9 5.113281 L 10.492188 7.101562 L 7.507812 7.101562 Z M 17.242188 14.558594 L 0.757812 14.558594 C 0.542969 14.558594 0.378906 14.738281 0.378906 14.933594 C 0.378906 15.132812 0.542969 15.3125 0.757812 15.3125 L 17.242188 15.3125 C 17.457031 15.3125 17.621094 15.148438 17.621094 14.933594 C 17.621094 14.71875 17.457031 14.558594 17.242188 14.558594 Z M 17.242188 14.558594 "
                        />
                      </g>
                    </svg>
                  )}
                  onClick={magicSpacer}
                />
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  // WriteMoreButton should only show when there is no selected text
  const renderWriteMoreButton = () => {
    const selection = props.editorRef.current.editor.selection.getContent({
      format: 'text',
    });
    if (!selectionBoundingClientRect || !!selection) return null;

    return (
      <WriteMoreButton
        mainRef={props.mainRef}
        editorLoading={editorLoading}
        editorLoadingText={editorLoadingText}
        aiPower={aiPower}
        isTextHighlighted={isTextHighlighted}
        setWriteMoreButton={setWriteMoreButton}
      />
    );
  };

  return (
    <>
      <PostTitle
        phrase={phrase}
        state={blocks[0].state}
        updateState={state => {
          updateBlockState(0, state);
        }}
      />
      {renderWriteMoreButton()}
      {renderAIControls()}
      <div
        className={classNames(
          props.sidePanelValue === 1 && !props.isPanelCollapsed
            ? 'pr-148 sm:pl-88 xl:pl-24'
            : props.sidePanelValue === 2 && !props.isPanelCollapsed
            ? 'pr-148 sm:pl-88 xl:pl-24'
            : 'pr-16',
          'hidden md:flex w-full py-1 justify-center fixed top-9 z-20 border-gray-300 border-b bg-white overflow-x-auto'
        )}
      />
      <div className="hidden">
        {isLibraryModalVisible ? (
          <LibraryImageModal
            state={[]}
            type={'Image'}
            phrase={phrase}
            updateState={updateImage}
            opened={isLibraryModalVisible}
            close={closeLibraryModal}
          />
        ) : (
          ''
        )}
        {isImageMetaModalVisible ? (
          <ImageMetaModal
            file={imageMeta.file}
            opened={isImageMetaModalVisible}
            updateImageMeta={updateImageMeta}
            close={closeImageMetaModal}
          />
        ) : (
          ''
        )}
        {/* <ComingSoonModal
          name="AI Content Creator Coming Soon!"
          opened={isComingSoonModalVisible}
          close={closeComingSoonModal}
        /> */}
        <DeletePostModal
          id={id}
          name={blocks[0].state.text ?? ''}
          projectId={project_id}
          category="post"
          open={isDeleteModalVisible}
          setOpen={closeDeleteModal}
        />
        <SharePostModal
          id={id}
          name="post"
          opened={isShareModalVisible}
          close={closeShareModal}
          sharePost={sharePost}
          isPublic={data.is_public}
        />
        <ChangeURLModal
          opened={isChangeURLModalVisible}
          close={closeChangeURLModal}
          changeURL={changeURL}
          setPostURL={setPostURL}
          postURL={postURL}
        />
        {isModalOpen && (
          <PlatformModal
            post_id={id}
            openModal={isModalOpen}
            closeModal={success => {
              if (success === true) {
                submitPost({ body: blocks, published: true }, 'auto-save');
              }
              setModalOpen(false);
            }}
          />
        )}
        <PlagiarismModal
          isOpenModal={isPlagiarismFound}
          plagiarismData={plagiarismData}
          closeModal={() => setPlagiarismFound('')}
        />
        <PlagiarismConfirmationModal
          isOpenModal={isConfirmModelOpen}
          closeModal={isPlagiarismConfirmed}
        />
      </div>
    </>
  );
});

function mapStateToProps(state) {
  return {
    user: state.auth.user || {},
    // isPanelCollapsed: state.panel.isPanelCollapsed,
    sidePanelValue: state.panel.sidePanelValue,
    isPanelCollapsed: state.panel.isPanelCollapsed,
  };
}

export default connect(mapStateToProps, { updateUser, addPlagiarism }, null, {
  forwardRef: true,
})(PostBuilderControls);
