import { Dialog, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import 'bootstrap';
import classNames from 'classnames';
import { Fragment } from 'react';
import { track } from '../../services/track';

export default function SharePostModal({
  id,
  name,
  opened,
  close,
  sharePost,
  isPublic,
}) {
  function handleOnChange() {
    sharePost(!isPublic);
    track('Blog post creator', {
      Public: !isPublic,
    });
  }

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="p-5 text-center">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <br></br>
                <br></br>

                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={isPublic}
                    onChange={handleOnChange}
                    className={classNames(
                      isPublic ? 'bg-purple-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isPublic ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3">
                    <span className="text-lg font-semibold text-gray-900">
                      Make this post public
                    </span>
                  </Switch.Label>
                </Switch.Group>
                <br></br>
                {isPublic && (
                  <div>
                    <p className="mb-4 text-left">
                      Share this link with your friends or clients.
                    </p>
                    <a
                      className="text-purple-600"
                      href={`${process.env.REACT_APP_BACKEND_HOST}/api/share-post/${id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${process.env.REACT_APP_BACKEND_HOST}/api/share-post/${id}`}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
