import TableHeaderCell from './table-header-cell';
import { Checkbox } from '@material-ui/core';
import { useState } from 'react';

const Table = ({
  renderData = () => null,
  headers = [],
  isSelection,
  onToggleSelectAll,
  dataLength,
  selectedItemsLength,
}) => {
  const onToggle = e => {
    onToggleSelectAll(e.target.checked);
  };

  const isAllSelected = () => dataLength === selectedItemsLength;
  const isIndeterminate = () =>
    dataLength > selectedItemsLength && selectedItemsLength > 0;

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {isSelection && (
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <Checkbox
                indeterminate={isIndeterminate()}
                checked={isAllSelected()}
                onChange={onToggle}
                className="custom-checkbox"
              />
            </th>
          )}
          {headers.map(h => (
            <TableHeaderCell key={h} name={h} />
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {renderData()}
      </tbody>
    </table>
  );
};

export default Table;
