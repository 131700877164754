import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import {
  PlusIcon,
  ViewListIcon,
  ViewGridIcon,
  LightningBoltIcon,
  TrashIcon,
  ChipIcon,
  LightBulbIcon,
  ChartSquareBarIcon,
  DesktopComputerIcon,
} from '@heroicons/react/outline';
import layoutView from '../store/actions/layout.actions';
import CreateProjectSlideOver from '../pages/NewProject/CreateProjectSlideOver';
import SemrushLogo from '../styles/images/logo-semrush.svg';
import { Checkbox } from '@material-ui/core';
import { set } from 'react-ga';
// import { TrashIcon } from '@heroicons/react/solid';
import useSentData from '../services/useSentData';
import { getCategory } from './dashboard/_utils';
import DeletePostModal from '../pages/Dashboard/DeletePostModal';

function PageHeading({
  category,
  title,
  gridView,
  setGridView = () => {},
  selectedProjectId,
  isListView,
  isShowAnalyticDashboard,
  setShowAnalyticDashboard = () => {},
  layoutView,
  user,
  beta,
  showSemrushBadge = false,
  isBulkSelect,
  setIsBulkSelect = () => null,
  onBulkDelete = () => null,
  selectedItems = [],
}) {
  const { project_id } = useParams();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, showDeleteModal] = useState(false);
  const request = useSentData();
  const hasBetaAccess = !!user?.beta_access;
  const isButtonDisabled =
    user?.app_sumo_subscription &&
    !user?.blog_booster_enabled &&
    !user?.blog_booster_access;

  const onChangeBulkSelect = e => setIsBulkSelect(e.target.checked);

  const onClickBulkDelete = () => {
    request
      .send(
        `/api/projects/${project_id}/delete-blogs`,
        {
          postIds: selectedItems,
        },
        'post'
      )
      .then(data => {
        if (!data.isAxiosError) {
          onBulkDelete();
          showDeleteModal(false);
        }
      });
  };

  return (
    <>
      <CreateProjectSlideOver
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
      />
      <DeletePostModal
        projectId={project_id}
        category={category}
        open={openDeleteModal}
        isBulkSelect={isBulkSelect}
        setOpen={() => showDeleteModal(false)}
        onBulkDelete={onClickBulkDelete}
      />
      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
              {beta && (
                <span className="mx-3 inline-flex items-center px-4 py-0.5 rounded-full text-base font-medium bg-purple-100 text-purple-800">
                  Beta
                </span>
              )}
              {showSemrushBadge && (
                <span className="inline-flex items-center">
                  <img src={SemrushLogo} className="h-8" alt="Semrush logo" />
                </span>
              )}
            </h2>
          </div>
          {(category === 'post' ||
            category === 'title' ||
            category === 'outline' ||
            category === 'rewrite' ||
            category === 'summary' ||
            category === 'keyword') && (
            <>
              {isBulkSelect && selectedItems.length ? (
                <button
                  onClick={() => {}}
                  className="inline-flex mx-3 items-center p-0 border border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <TrashIcon
                    onClick={() => showDeleteModal(true)}
                    className="h-7 w-7 text-red-500"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                ''
              )}
              <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium">
                <Checkbox
                  checked={isBulkSelect}
                  color="primary"
                  onChange={onChangeBulkSelect}
                  className={'custom-checkbox '}
                />
                <span className="ml-2 text-gray-700">Bulk Select</span>
              </button>
            </>
          )}
          <div className="mt-4 flex md:mt-0 md:ml-4">
            {category === 'dashboard' && (
              <Tooltip
                title={`${
                  isShowAnalyticDashboard ? 'Overview' : 'Analytics'
                } Dashboard`}
                placement="bottom"
                arrow
              >
                <button
                  type="button"
                  onClick={() => {
                    setShowAnalyticDashboard(!isShowAnalyticDashboard);
                  }}
                  className="inline-flex items-center px-4 mr-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <span className="sr-only">Open options</span>
                  {isShowAnalyticDashboard ? (
                    <DesktopComputerIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChartSquareBarIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Tooltip>
            )}
            {(category === 'post' ||
              category === 'title' ||
              category === 'outline' ||
              category === 'keyword' ||
              category === 'rewrite' ||
              category === 'summary' ||
              category === 'dashboard') &&
              !isShowAnalyticDashboard && (
                <Tooltip
                  title="Grid or list view toggle"
                  placement="bottom"
                  arrow
                >
                  <button
                    type="button"
                    onClick={() => {
                      layoutView(!isListView);
                      setGridView(!gridView);
                    }}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <span className="sr-only">Open options</span>
                    {!isListView ? (
                      <ViewListIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ViewGridIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </Tooltip>
              )}
            {category === 'post' &&
              (user.is_owner || user.is_manager || user.is_editor) && (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  to={{
                    pathname: `/project/${selectedProjectId ||
                      project_id}/generate-first-draft-pro`,
                  }}
                >
                  <ChipIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  First Draft PRO
                </Link>
              )}

            {category === 'post' &&
              (user.is_owner || user.is_manager || user.is_editor) && (
                <Link
                  className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-purple-700 bg-purple-100 hover:bg-purple-300 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 focus:outline-none`}
                  to={{
                    pathname: `/project/${selectedProjectId ||
                      project_id}/blog-post-improver`,
                  }}
                >
                  <LightBulbIcon
                    className="-ml-1 mr-3 h-5 w-5"
                    aria-hidden="true"
                  />
                  Blog Booster
                </Link>
              )}
            {category === 'post' &&
              (user.is_owner || user.is_manager || user.is_editor) && (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-purple-300 rounded-md shadow-sm text-sm font-medium text-purple-700 bg-white hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: `/project/${selectedProjectId ||
                      project_id}/generate-first-draft`,
                  }}
                >
                  <LightningBoltIcon
                    className="-ml-1 mr-3 h-5 w-5"
                    aria-hidden="true"
                  />
                  Quick Draft
                </Link>
              )}
            {(user.is_owner || user.is_manager || user.is_editor) &&
              (category === 'keyword' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: selectedProjectId
                      ? `/project/${selectedProjectId}/keyword-analyzer`
                      : '/keyword-analyzer',
                    state: { page: 'analyzer' },
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Search
                </Link>
              ) : category === 'post' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: selectedProjectId
                      ? `/project/${selectedProjectId}/keyword-analyzer`
                      : '/keyword-analyzer',
                    state: { page: 'post' },
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Post
                </Link>
              ) : category === 'title' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: `/project/${selectedProjectId}/title-generator`,
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Titles
                </Link>
              ) : category === 'outline' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: `/project/${selectedProjectId}/outline-generator`,
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Outlines
                </Link>
              ) : category === 'rewrite' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: `/project/${selectedProjectId}/rewrite-generator`,
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Rewrite
                </Link>
              ) : category === 'summary' ? (
                <Link
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  to={{
                    pathname: `/project/${selectedProjectId}/summary-generator`,
                  }}
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Summary
                </Link>
              ) : category === 'project' &&
                (user.is_owner || user.is_manager) ? (
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  type="button"
                >
                  <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                  New Project
                </button>
              ) : (
                <></>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isListView: state.layout.isListView,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, { layoutView: layoutView })(
  PageHeading
);
