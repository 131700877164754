const FormLabel = ({
  className = '',
  label = '',
  optional = false,
  ...props
}) => {
  return (
    <label
      {...props}
      htmlFor={label
        .toLowerCase()
        .split(' ')
        .join('-')}
      className={`block w-full sm:w-116 font-semibold text-gray-900 ${className}`}
    >
      <span>{label}</span>
      {optional ? (
        <span className="ml-2 font-normal italic text-xs text-grey-200">
          (Optional)
        </span>
      ) : (
        <span className="ml-2 text-red-600">*</span>
      )}
    </label>
  );
};

export default FormLabel;
