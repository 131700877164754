import {
  TONES,
  OUTLINE_TYPES,
  NICHES,
  LANGUAGES,
  LENGTHS,
} from '../../utils/common';

const PostTypes = {
  CREATE_TITLE: 'CREATE_TITLE',
  RE_RENDER_EDITOR: 'RE_RENDER_EDITOR',
  ADD_EDITOR_VALUE: 'ADD_EDITOR_VALUE',
  CLEAR_EDITOR_VALUE: 'CLEAR_EDITOR_VALUE',
  SET_ID: 'SET_ID',
  SET_VERSIONS: 'SET_VERSIONS',
  SET_VERSION_INDEX: 'SET_VERSION_INDEX',
  SET_VERSION_ID: 'SET_VERSION_ID',
  SET_TITLES: 'SET_TITLES',
  SET_OUTLINES: 'SET_OUTLINES',
  SET_PARAGRAPHS: 'SET_PARAGRAPHS',
  SET_COMMANDS: 'SET_COMMANDS',
  SET_REPHRASE: 'SET_REPHRASE',
  SET_SUMMARIZE: 'SET_SUMMARIZE',
  SET_STEPS: 'SET_STEPS',
  SET_LISTICLE: 'SET_LISTICLE',
  SET_RELATED_KEYWORDS: 'SET_RELATED_KEYWORDS',
  SET_TOP_QUESTIONS: 'SET_TOP_QUESTIONS',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_TONE: 'SET_TONE',
  SET_NICHE: 'SET_NICHE',
  SET_OUTLINE_TYPE: 'SET_OUTLINE_TYPE',
  SET_SELECTED_KEYWORDS: 'SET_SELECTED_KEYWORDS',
  SET_SECONDARY_KEYWORDS: 'SET_SECONDARY_KEYWORDS',
  SET_INSTRUCTIONS: 'SET_INSTRUCTIONS',
  SET_SEO_ANALYZER: 'SET_SEO_ANALYZER',
  SET_STATUS: 'SET_STATUS',
  SET_SELECTED_TEXT_NODE: 'SET_SELECTED_TEXT_NODE',
  SET_UPDATED_CONTENT_POPUP: 'SET_UPDATED_CONTENT_POPUP',
  SET_AI_WRITEMORE_BUTTON: 'SET_AI_WRITEMORE_BUTTON',
  SET_ADDITIONAL_KEYWORDS_BUTTON: 'SET_ADDITIONAL_KEYWORDS_BUTTON',
  SET_INSTRUCTIONS_BUTTON: 'SET_INSTRUCTIONS_BUTTON',
  SET_CHECK_PLAGIARISM_TRIGGERED: 'SET_CHECK_PLAGIARISM_TRIGGERED',
  SET_LENGTH: 'SET_LENGTH',
  SET_HIGHLIGHT_WORDS: 'SET_HIGHLIGHT_WORDS',
  SET_PARAGRAPH_TAB: 'SET_PARAGRAPH_TAB',
  SET_AI_WRITING_TOOL_SELECTED: 'SET_AI_WRITING_TOOL_SELECTED',
  SET_USE_BRAND_VOICE: 'SET_USE_BRAND_VOICE',
};

const initialState = {
  title: '',
  reRender: false,
  editorValue: {},
  id: null,
  versions: [],
  versionIndex: null,
  versionId: null,
  generatedTitles: [],
  generatedOutlines: [],
  generatedParagraphs: [],
  generatedCommands: [],
  generatedRephrases: [],
  generatedSummarizes: [],
  generatedSteps: [],
  generatedListicles: [],
  relatedKeywords: [],
  topQuestions: [],
  language: LANGUAGES[5],
  tone: TONES[9],
  niche: NICHES[3],
  paragraphs: NICHES[3],
  outlineType: OUTLINE_TYPES[2],
  selectedKeywords: '',
  secondaryKeywords: [],
  instructions: '',
  seoAnalyzer: false,
  status: '',
  selectionBoundingClientRect: null,
  updatedContentPopup: {
    opened: false,
    content: '',
  },
  aiWriteMoreButtonOn: true,
  additionalKeywordsButtonOn: true,
  instructionButtonOn: true,
  checkPlagiarismTriggered: false,
  length: LENGTHS[1].value,
  highlightWords: '',
  aiWritingToolSelected: 'title',
  useBrandVoice: false,
};

const postBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case PostTypes.CREATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case PostTypes.SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case PostTypes.SET_RELATED_KEYWORDS:
      return {
        ...state,
        relatedKeywords: action.payload,
      };
    case PostTypes.SET_TOP_QUESTIONS:
      return {
        ...state,
        topQuestions: action.payload,
      };
    case PostTypes.SET_TITLES:
      return {
        ...state,
        generatedTitles: action.payload,
      };
    case PostTypes.SET_OUTLINES:
      return {
        ...state,
        generatedOutlines: action.payload,
      };
    case PostTypes.SET_PARAGRAPHS:
      return {
        ...state,
        generatedParagraphs: action.payload,
      };
    case PostTypes.SET_COMMANDS:
      return {
        ...state,
        generatedCommands: action.payload,
      };
    case PostTypes.SET_REPHRASE:
      return {
        ...state,
        generatedRephrases: action.payload,
      };
    case PostTypes.SET_SUMMARIZE:
      return {
        ...state,
        generatedSummarizes: action.payload,
      };
    case PostTypes.SET_STEPS:
      return {
        ...state,
        generatedSteps: action.payload,
      };
    case PostTypes.SET_LISTICLE:
      return {
        ...state,
        generatedListicles: action.payload,
      };
    case PostTypes.SET_VERSIONS:
      return {
        ...state,
        versions: action.payload,
      };
    case PostTypes.SET_VERSION_INDEX:
      return {
        ...state,
        versionIndex: action.payload,
      };
    case PostTypes.SET_VERSION_ID:
      return {
        ...state,
        versionId: action.payload,
      };
    case PostTypes.RE_RENDER_EDITOR:
      return {
        ...state,
        reRender: action.payload,
      };
    case PostTypes.ADD_EDITOR_VALUE:
      return {
        ...state,
        editorValue: {
          ...state.editorValue,
          [action.block]: {
            id: action.id,
            value: action.value,
          },
        },
      };
    case PostTypes.CLEAR_EDITOR_VALUE:
      return {
        ...state,
        editorValue: {},
      };
    case PostTypes.SET_TONE:
      return {
        ...state,
        tone: action.payload,
      };
    case PostTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case PostTypes.SET_OUTLINE_TYPE:
      return {
        ...state,
        outlineType: action.payload,
      };
    case PostTypes.SET_SELECTED_KEYWORDS:
      return {
        ...state,
        selectedKeywords: action.payload,
      };
    case PostTypes.SET_SECONDARY_KEYWORDS:
      return {
        ...state,
        secondaryKeywords: action.payload,
      };
    case PostTypes.SET_NICHE:
      return {
        ...state,
        niche: action.payload,
      };
    case PostTypes.SET_INSTRUCTIONS:
      return {
        ...state,
        instructions: action.payload,
      };
    case PostTypes.SET_SEO_ANALYZER:
      return {
        ...state,
        seoAnalyzer: action.payload,
      };
    case PostTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case PostTypes.SET_SELECTED_TEXT_NODE:
      return {
        ...state,
        selectionBoundingClientRect: action.payload,
      };
    case PostTypes.SET_UPDATED_CONTENT_POPUP:
      return {
        ...state,
        updatedContentPopup: {
          ...state.updatedContentPopup,
          ...action.payload,
        },
      };
    case PostTypes.SET_AI_WRITEMORE_BUTTON:
      return {
        ...state,
        aiWriteMoreButtonOn: action.payload,
      };
    case PostTypes.SET_ADDITIONAL_KEYWORDS_BUTTON:
      return {
        ...state,
        additionalKeywordsButtonOn: action.payload,
      };
    case PostTypes.SET_INSTRUCTIONS_BUTTON:
      return {
        ...state,
        instructionButtonOn: action.payload,
      };
    case PostTypes.SET_CHECK_PLAGIARISM_TRIGGERED:
      return {
        ...state,
        checkPlagiarismTriggered: action.payload,
      };
    case PostTypes.SET_LENGTH:
      return {
        ...state,
        length: action.payload,
      };
    case PostTypes.SET_HIGHLIGHT_WORDS:
      return {
        ...state,
        highlightWords: action.payload,
      };
    case PostTypes.SET_AI_WRITING_TOOL_SELECTED:
      return {
        ...state,
        aiWritingToolSelected: action.payload,
      };
    case PostTypes.SET_USE_BRAND_VOICE:
      return {
        ...state,
        useBrandVoice: action.payload,
      };
    default:
      return state;
  }
};

export default postBuilderReducer;
export { PostTypes };
