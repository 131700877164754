import React, { useEffect, useState } from 'react';
import './Login.scss';
import { Link } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import Validator, { email, min, required } from '../services/Validator';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { authenticate } from '../store/actions/auth.actions';
import { useLocation } from 'react-router-dom';
import { isolateError, isolateSuccess } from '../utils/api';
import Loading from '../components/Loading';
import useSentData from '../services/useSentData';
import { useAlert } from 'react-alert';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import wordpress from '../styles/images/wordpress.svg';
import { PlusIcon } from '@heroicons/react/outline';
import googleIcon from '../styles/images/google-icon.svg';
import http from '../services/http';
import decodeToken from '../services/decodeToken';
import store from '../store';

function LoginFormRaw({ validator, authenticate, authError, authLoading }) {
  const [authorized, setAuthorized] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const state = store.getState();
  const user = state.auth.user;
  const reactivateRequest = useSentData();
  let location = useLocation();
  const alert = useAlert();

  let { from } = location.state || { from: null };

  const authorized_wordpress = () => {
    const params = new URLSearchParams(location.search);
    return http
      .get(`/api/authorize-wordpress`, {
        params: {
          domain: params.get('domain'),
          user: params.get('user'),
          token: params.get('token'),
        },
      })
      .then(userRes => {
        const user = userRes.data;
        if (user.msg) {
          setAuthorized(true);
          alert.success(
            'WordPress successfully connected. Continue to Bramework!'
          );
        } else {
          alert.error('Unable to authorized. Please try again later!');
        }
      })
      .catch(error => {
        alert.error(isolateError(error));
      });
  };

  useEffect(() => {
    if (authError) {
      const isolatedError = isolateError(authError);
      let reactivateButton;
      if (isolatedError === 'User account is disabled.') {
        reactivateButton = (
          <a
            href=""
            className="reactivate-button"
            onClick={e => {
              e.preventDefault();
              reactivateRequest
                .send('/api/reactivate-request', {
                  email: data.email,
                })
                .then(data => {
                  if (!data.isAxiosError) {
                    alert.success(
                      isolateSuccess(data, 'Please check your email.')
                    );
                  } else {
                    alert.error(isolateError(reactivateRequest.error));
                  }
                });
            }}
          >
            Reactivate
          </a>
        );
        alert.error(
          <div>
            {isolatedError}&nbsp;{reactivateButton}
          </div>,
          {
            timeout: null,
          }
        );
      } else {
        alert.error(isolatedError);
      }
    }
  }, [authError]);
  useEffect(() => {
    if (from === 'activate') {
      const type = location.state.type || 'success';
      alert[type](
        location.state.message || 'Your account successfully activated.'
      );
    } else if (from === 'confirm-change-email') {
      alert.success(
        location.state.message ||
          'Your email successfully changed, please login.'
      );
    }
  }, [from]);

  function change(name, val) {
    const newData = {
      ...data,
      [name]: val,
    };
    setData(newData);
    setErrors(validator.validateAll(newData));
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex mx-auto justify-between w-60">
            <img className="h-12" src={logoIconPurple} alt="Bramework" />
            <PlusIcon
              className="h-8 w-8 text-gray-400 mt-2"
              aria-hidden="true"
            />
            <img className="h-12" src={wordpress} alt="AppSumo" />
          </div>

          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Authorize and Connect
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {authorized ? (
              <p>
                You've authorized the connection.{' '}
                {/* <Link
                  to="/integrations"
                  className="font-medium text-purple-600 hover:text-purple-500"
                >
                  Continue with bramework
                </Link> */}
                <Link
                  to="/integrations"
                  className={`w-full flex justify-center mt-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50`}
                >
                  Continue to Bramework
                </Link>
              </p>
            ) : user ? (
              <div>
                <p>Click the button below to authorize the connection.</p>
                <button
                  onClick={() => authorized_wordpress()}
                  id="submit"
                  className={`w-full flex justify-center mt-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50`}
                >
                  Authorize
                </button>
              </div>
            ) : (
              <form
                className="space-y-6"
                onSubmit={e => {
                  e.preventDefault();
                  if (validator.isValid()) {
                    reactivateRequest.reset();
                    authenticate(data);
                  }
                }}
              >
                {(reactivateRequest.loading || authLoading) && <Loading />}
                {true && (
                  <>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value={data.email}
                          onChange={e => change('email', e.target.value)}
                          autoComplete="email"
                          aria-invalid={errors.email}
                          aria-describedby="email-error"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                        {errors.email && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          value={data.password}
                          onChange={e => change('password', e.target.value)}
                          aria-invalid={errors.password}
                          aria-describedby="password-error"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                        {errors.password && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="password-error"
                          >
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div>
                  <button
                    id="submit"
                    disabled={!errors.email || !errors.password}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                      !errors.email || !errors.password
                        ? 'cursor-default'
                        : 'cursor-pointer'
                    }`}
                  >
                    Login
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    authLoading: state.auth.loading,
    authError: state.auth.error,
    user: state.auth.user,
  };
}

const LoginForm = connect(mapStateToProps, { authenticate: authenticate })(
  LoginFormRaw
);

export default function Login() {
  const validator = Validator({
    email: [required, email],
    password: [required, min(8)],
  });

  return <LoginForm validator={validator} />;
}
