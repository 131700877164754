const TableDataCell = ({ className = '', ...props }) => {
  const { children } = props;
  return (
    <td
      className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${className}`}
      {...props}
    >
      {children}
    </td>
  );
};

export default TableDataCell;
