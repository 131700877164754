export const REGIONS = [
  { label: 'United States', value: 'us' },
  { label: 'United Kingdom', value: 'uk' },
  { label: 'Canada', value: 'ca' },
  { label: 'Russia', value: 'ru' },
  { label: 'Germany', value: 'de' },
  { label: 'France', value: 'fr' },
  { label: 'Spain', value: 'es' },
  { label: 'Italy', value: 'it' },
  { label: 'Brazil', value: 'br' },
  { label: 'Australia', value: 'au' },
  { label: 'Argentina', value: 'ar' },
  { label: 'Belgium', value: 'be' },
  { label: 'Switzerland', value: 'ch' },
  { label: 'Denmark', value: 'dk' },
  { label: 'Finland', value: 'fi' },
  { label: 'Hong Kong', value: 'hk' },
  { label: 'Ireland', value: 'ie' },
  { label: 'Israel', value: 'il' },
  { label: 'Mexico', value: 'mx' },
  { label: 'Netherlands', value: 'nl' },
  { label: 'Norway', value: 'no' },
  { label: 'Poland', value: 'pl' },
  { label: 'Sweden', value: 'se' },
  { label: 'Singapore', value: 'sg' },
  { label: 'Turkey', value: 'tr' },
  { label: 'Japan', value: 'jp' },
  { label: 'India', value: 'in' },
  { label: 'Hungary', value: 'hu' },
  { label: 'Afghanistan', value: 'af' },
  { label: 'Albania', value: 'al' },
  { label: 'Algeria', value: 'dz' },
  { label: 'Angola', value: 'ao' },
  { label: 'Armenia', value: 'am' },
  { label: 'Austria', value: 'at' },
  { label: 'Azerbaijan', value: 'az' },
  { label: 'Bahrain', value: 'bh' },
  { label: 'Bangladesh', value: 'bd' },
  { label: 'Belarus', value: 'by' },
  { label: 'Belize', value: 'bz' },
  { label: 'Bolivia', value: 'bo' },
  { label: 'Bosnia and Herzegovina', value: 'ba' },
  { label: 'Botswana', value: 'bw' },
  { label: 'Brunei', value: 'bn' },
  { label: 'Bulgaria', value: 'bg' },
  { label: 'Cabo Verde', value: 'cv' },
  { label: 'Cambodia', value: 'kh' },
  { label: 'Cameroon', value: 'cm' },
  { label: 'Chile', value: 'cl' },
  { label: 'Colombia', value: 'co' },
  { label: 'Costa Rica', value: 'cr' },
  { label: 'Croatia', value: 'hr' },
  { label: 'Cyprus', value: 'cy' },
  { label: 'Czech Republic', value: 'cz' },
  { label: 'Congo', value: 'cd' },
  { label: 'Dominican Republic', value: 'do' },
  { label: 'Ecuador', value: 'ec' },
  { label: 'Egypt', value: 'eg' },
  { label: 'El Salvador', value: 'sv' },
  { label: 'Estonia', value: 'ee' },
  { label: 'Ethiopia', value: 'et' },
  { label: 'Georgia', value: 'ge' },
  { label: 'Ghana', value: 'gh' },
  { label: 'Greece', value: 'gr' },
  { label: 'Guatemala', value: 'gt' },
  { label: 'Guyana', value: 'gy' },
  { label: 'Haiti', value: 'ht' },
  { label: 'Honduras', value: 'hn' },
  { label: 'Iceland', value: 'is' },
  { label: 'Indonesia', value: 'id' },
  { label: 'Jamaica', value: 'jm' },
  { label: 'Jordan', value: 'jo' },
  { label: 'Kazakhstan', value: 'kz' },
  { label: 'Kuwait', value: 'kw' },
  { label: 'Latvia', value: 'lv' },
  { label: 'Lebanon', value: 'lb' },
  { label: 'Lithuania', value: 'lt' },
  { label: 'Luxembourg', value: 'lu' },
  { label: 'Madagascar', value: 'mg' },
  { label: 'Malaysia', value: 'my' },
  { label: 'Malta', value: 'mt' },
  { label: 'Mauritius', value: 'mu' },
  { label: 'Moldova', value: 'md' },
  { label: 'Mongolia', value: 'mn' },
  { label: 'Montenegro', value: 'me' },
  { label: 'Morocco', value: 'ma' },
  { label: 'Mozambique', value: 'mz' },
  { label: 'Namibia', value: 'na' },
  { label: 'Nepal', value: 'np' },
  { label: 'New Zealand', value: 'nz' },
  { label: 'Nicaragua', value: 'ni' },
  { label: 'Nigeria', value: 'ng' },
  { label: 'Oman', value: 'om' },
  { label: 'Paraguay', value: 'py' },
  { label: 'Peru', value: 'pe' },
  { label: 'Philippines', value: 'ph' },
  { label: 'Portugal', value: 'pt' },
  { label: 'Pakistan', value: 'pk' },
  { label: 'Romania', value: 'ro' },
  { label: 'Saudi Arabia', value: 'sa' },
  { label: 'Senegal', value: 'sn' },
  { label: 'Serbia', value: 'rs' },
  { label: 'Slovakia', value: 'sk' },
  { label: 'Slovenia', value: 'si' },
  { label: 'South Africa', value: 'za' },
  { label: 'South Korea', value: 'kr' },
  { label: 'Sri Lanka', value: 'lk' },
  { label: 'Thailand', value: 'th' },
  { label: 'Bahamas', value: 'bs' },
  { label: 'Trinidad and Tobago', value: 'tt' },
  { label: 'Tunisia', value: 'tn' },
  { label: 'Ukraine', value: 'ua' },
  { label: 'United Arab Emirates', value: 'ae' },
  { label: 'Uruguay', value: 'uy' },
  { label: 'Venezuela', value: 've' },
  { label: 'Vietnam', value: 'vn' },
  { label: 'Zambia', value: 'zm' },
  { label: 'Zimbabwe', value: 'zw' },
  { label: 'Libya', value: 'ly' },
];

export default REGIONS.sort((a, b) => a.label.localeCompare(b.label));
