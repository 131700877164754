import { googleAccountActionType } from '../reducers/google_analytic_account.reducer';
import http from '../../services/http';
export const destroyAccounts = () => {
  return dispatch => {
    dispatch({ type: googleAccountActionType.GOOGLE_ACCOUNT_DESTROY });
  };
};
export const fetchAccounts = successCallback => {
  const success = payload => ({
    type: googleAccountActionType.FETCH_GOOGLE_ACCOUNT,
    payload: payload,
  });
  return dispatch => {
    dispatch({ type: googleAccountActionType.GOOGLE_ACCOUNT_LOADING });
    http
      .get(`/api/google-analytic/account`)
      .then(data => {
        console.log(data);
        dispatch(success(data.data.accounts));
        if (successCallback) {
          successCallback(data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: googleAccountActionType.GOOGLE_ACCOUNT_FAILURE,
          payload: error,
        });
      });
  };
};
export const selectAccount = payload => {
  return {
    type: googleAccountActionType.SELECTED_GOOGLE_ACCOUNT,
    payload: payload,
  };
};
export const loadingAccounts = payload => {
  return {
    type: googleAccountActionType.GOOGLE_ACCOUNT_LOADING,
  };
};
