const projectActionTypes = {
  FETCH_PROJECTS: 'FETCH_PROJECTS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  ADD_PROJECT: 'ADD_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  SELECTED_PROJECT: 'SELECTED_PROJECT',
  PROJECT_FAILURE: 'PROJECT_FAILURE',
  PROJECT_LOADING: 'PROJECT_LOADING',
  PROJECT_DESTROY: 'PROJECT_DESTROY',
};

const initialState = {
  projects: [],
  selectedProject: null,
  loading: false,
  error: false,
  errorMsg: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectActionTypes.PROJECT_DESTROY:
      return { ...initialState };
    case projectActionTypes.PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case projectActionTypes.PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case projectActionTypes.FETCH_PROJECTS:
      let selectedProject = state.selectedProject;
      if (selectedProject == null) {
        const selectedProjectId = localStorage.getItem('selectedProject');
        selectedProject = action.payload.find(
          project => project.is_default === true
        );
        if (selectedProjectId) {
          const selectedProj = action.payload.find(
            project => project.id == selectedProjectId
          );
          selectedProject = selectedProj ? selectedProj : selectedProject;
        }
      }
      return {
        ...state,
        projects: action.payload,
        selectedProject: selectedProject,
        loading: false,
        error: false,
        errorMsg: null,
      };
    case projectActionTypes.ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        loading: false,
        error: false,
        errorMsg: null,
      };
    case projectActionTypes.UPDATE_PROJECT:
      return {
        ...state,
        loading: false,
        projects: state.projects.map(project => {
          if (project.id === action.payload.id) {
            return action.payload;
          }
          return project;
        }),
        error: false,
        errorMsg: null,
      };
    case projectActionTypes.DELETE_PROJECT:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: null,
        projects: state.projects.filter(
          project => project.id !== action.payload
        ),
        selectedProject:
          state.selectedProject.id == action.payload
            ? state.projects.find(project => project.is_default === true)
            : state.selectedProject,
      };
    case projectActionTypes.SELECTED_PROJECT:
      if (state.projects.length > 0) {
        const selectedProject = state.projects.find(
          project => project.id === action.payload
        );
        localStorage.setItem('selectedProject', selectedProject.id);
        return {
          ...state,
          loading: false,
          selectedProject: selectedProject,
        };
      }

      return {
        ...state,
      };
    default:
      return state;
  }
};

export default projectReducer;
export { projectActionTypes };
