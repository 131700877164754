const AssistantIcon = props => {
  return (
    <svg
      width="64"
      height="61"
      viewBox="0 0 64 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_154_1315)">
        <path
          d="M50.1332 6.69141H13.7695C6.98165 6.69141 1.35742 12.2187 1.35742 19.1035V19.3944C2.13318 18.9096 3.00592 18.6187 3.97562 18.6187C4.75137 18.6187 5.52712 18.8126 6.20591 19.1035C6.20591 14.9338 9.59983 11.5399 13.7695 11.5399H50.1332C54.3029 11.5399 57.6968 14.9338 57.6968 19.1035V19.2975C58.3756 18.9096 59.2483 18.7156 60.121 18.7156C60.9938 18.7156 61.8665 18.9096 62.5453 19.2975V19.1035C62.5453 12.3157 57.018 6.69141 50.1332 6.69141ZM57.6968 43.4429V48.1944C57.6968 52.3641 54.3029 55.7581 50.1332 55.7581H13.7695C9.59983 55.7581 6.20591 52.3641 6.20591 48.1944V43.5399C5.52712 43.8308 4.75137 44.0247 3.97562 44.0247C3.00592 44.0247 2.03621 43.7338 1.35742 43.249V48.0975C1.35742 54.8853 6.88468 60.5096 13.7695 60.5096H50.1332C56.921 60.5096 62.5453 54.9823 62.5453 48.0975V43.346C61.8665 43.7338 60.9938 43.9278 60.121 43.9278C59.2483 43.9278 58.4725 43.9278 57.6968 43.4429ZM60.121 20.655C59.1513 20.655 58.2786 21.1399 57.6968 21.8187V41.0187C58.2786 41.6975 59.1513 42.1823 60.121 42.1823C61.0907 42.1823 61.9635 41.6975 62.5453 41.0187V21.8187C61.9635 21.0429 61.0907 20.655 60.121 20.655ZM4.07256 20.655C2.61802 20.655 1.4544 21.8187 1.4544 23.3702V40.7278C2.03622 41.6005 3.00589 42.1823 4.07256 42.1823C4.94529 42.1823 5.72106 41.7944 6.30288 41.3096V21.6247C5.72106 20.946 4.94529 20.655 4.07256 20.655Z"
          fill="white"
        />
        <path
          d="M28.0244 26.3766C28.0244 29.9644 25.1153 32.7766 21.5274 32.7766C17.9395 32.7766 15.1274 29.8675 15.1274 26.3766C15.1274 22.8857 18.0365 19.9766 21.5274 19.9766C25.0183 19.9766 28.0244 22.8857 28.0244 26.3766Z"
          fill="white"
        />
        <path
          d="M48.873 26.3766C48.873 29.9644 45.964 32.7766 42.4731 32.7766C38.8852 32.7766 35.9761 29.8675 35.9761 26.3766C35.9761 22.8857 38.8852 19.9766 42.4731 19.9766C45.964 19.9766 48.873 22.8857 48.873 26.3766Z"
          fill="white"
        />
        <path
          d="M43.6363 2.81212C43.6363 4.36363 42.4726 5.52727 40.9211 5.52727H22.9817C21.5272 5.52727 20.2666 4.36363 20.2666 2.81212V2.71515C20.2666 1.2606 21.4302 0 22.9817 0H40.9211C42.4726 0.0969697 43.6363 1.35757 43.6363 2.81212Z"
          fill="white"
        />
        <path
          d="M44.0245 41.793V44.1203C40.0488 45.3809 35.9761 46.6415 31.9033 47.999C27.9276 46.7384 23.9518 45.4778 19.9761 44.1203V41.793C23.8549 42.2778 27.7336 42.8596 31.7094 43.3445C35.7821 42.8596 39.8549 42.2778 44.0245 41.793Z"
          fill="white"
        />
        <path
          d="M6.30307 21.529V41.2138C5.72126 41.7956 4.94548 42.0867 4.07275 42.0867C2.90912 42.0867 1.93944 41.5047 1.45459 40.632V22.1108C2.03641 21.2381 3.00609 20.6562 4.07275 20.6562C4.94548 20.6562 5.72126 20.9472 6.30307 21.529Z"
          fill="white"
        />
        <path
          d="M7.75758 20.9459V41.8914L7.3697 42.2793C6.30304 43.3459 4.84848 43.8308 3.29696 43.5399C1.93939 43.3459 0.872733 42.3762 0.0969756 41.2126L0 41.1156V41.0187V21.7217L0.193922 21.3338C0.969679 19.9762 2.52119 19.1035 4.07271 19.1035C5.23635 19.1035 6.4 19.5884 7.27273 20.4611L7.75758 20.9459Z"
          fill="white"
        />
        <path
          d="M62.5453 21.82V41.02C61.9634 41.6988 61.0907 42.1835 60.121 42.1835C59.1513 42.1835 58.2786 41.6988 57.6968 41.02V21.82C58.2786 21.1412 59.1513 20.6562 60.121 20.6562C61.0907 20.6562 61.9634 21.0442 62.5453 21.82Z"
          fill="white"
        />
        <path
          d="M64.0003 21.2368V41.5035L63.7094 41.8914C62.8366 42.9581 61.576 43.6369 60.1215 43.6369C58.7639 43.6369 57.4064 43.055 56.5336 41.8914L56.2427 41.5035V21.2368L56.5336 20.849C57.4064 19.7823 58.6669 19.1035 60.1215 19.1035C61.576 19.1035 62.8366 19.6853 63.7094 20.849L64.0003 21.2368Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_154_1315">
          <rect width="64" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssistantIcon;
