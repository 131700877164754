import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useSentData from '../../services/useSentData';
import { isolateError, isolateSuccess } from '../../utils/api';
import { XIcon } from '@heroicons/react/outline';
import Validator, {
  email as validateEmail,
  required,
} from '../../services/Validator';
import Loading from '../../components/Loading';
import FormInput from '../../components/form/FormInput';
import { useAlert } from 'react-alert';

export default function MovePostModal({ opened, close, id }) {
  const request = useSentData();
  const [email, setEmail] = useState('');
  const [needShowErrors, showErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const alert = useAlert();
  const validator = Validator({
    email: [required, validateEmail],
  });
  let validation = validator.validateAll({ email });

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => close(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Change email
                  </Dialog.Title>
                  <div className="mt-6">
                    <form
                      className="EmailChangeModalView"
                      onSubmit={e => {
                        e.preventDefault();
                        if (!request.loading && validator.isValid()) {
                          request
                            .send(`/api/change-email`, {
                              email: email,
                            })
                            .then(data => {
                              if (!data.isAxiosError) {
                                alert.success(
                                  isolateSuccess(
                                    data,
                                    'Please check your email.'
                                  )
                                );
                                close();
                              } else {
                                showErrors(true);
                                setServerErrors(data.response.data);
                                alert.error(isolateError(data));
                              }
                            });
                        } else {
                          showErrors(true);
                        }
                      }}
                    >
                      {request.loading && <Loading />}
                      <div className="form-inner">
                        <div className="inputs-wrapper">
                          <FormInput
                            id="email"
                            type="email"
                            className="profile-input"
                            label="New Email"
                            value={email}
                            onChange={v => {
                              if (serverErrors.email) {
                                let newServerErrors = { ...serverErrors };
                                delete newServerErrors.email;
                                setServerErrors(newServerErrors);
                              }
                              setEmail(v);
                            }}
                            isValid={
                              needShowErrors &&
                              (!validation.email || serverErrors.email)
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="button-wrapper">
                        <button className="btn btn-purple">SAVE</button>
                      </div> */}
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => close(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
