export const semrushStatuses = {
  connected: 'connected',
  disconnected: 'disconnected',
};

export const getSemrushStatus = async request => {
  let url = `/api/semrush/get-info`;
  let method = 'get';
  const res = await request.send(url, null, method);
  if (!!res && !!res?.is_active) {
    return semrushStatuses.connected;
  }

  return semrushStatuses.disconnected;
};

export const saveSemrushTokenAPI = async (request, code) => {
  let url = `/api/semrush/get-token`;
  let method = 'post';
  const data = { code };
  await request.send(url, data, method);
  return;
};
