import { googlePropertiesActionType } from '../reducers/google_analytic_properties.reducer';
import http from '../../services/http';
export const destroyProperties = () => {
  return dispatch => {
    dispatch({ type: googlePropertiesActionType.GOOGLE_PROPERTIES_DESTROY });
  };
};
export const fetchProperties = (accountId, successCallback) => {
  const success = payload => ({
    type: googlePropertiesActionType.FETCH_GOOGLE_PROPERTIES,
    payload: payload,
  });
  return dispatch => {
    dispatch({ type: googlePropertiesActionType.GOOGLE_PROPERTIES_LOADING });
    http
      .get(`/api/google-analytic/properties?accountId=${accountId}`)
      .then(data => {
        dispatch(success(data.data?.properties));
        if (successCallback) {
          successCallback(data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: googlePropertiesActionType.GOOGLE_PROPERTIES_FAILURE,
          payload: error,
        });
      });
  };
};
export const selectProperties = payload => {
  return {
    type: googlePropertiesActionType.SELECTED_GOOGLE_PROPERTIES,
    payload: payload,
  };
};
export const loadingProperties = payload => {
  return {
    type: googlePropertiesActionType.GOOGLE_PROPERTIES_LOADING,
  };
};
