import React from 'react';
import Moment from 'react-moment';

function RemovedMemberList({ removedMembers, roles, sendRejoinLink }) {

  const onClickSendRejoinLink = (id) => () => {
      sendRejoinLink(id)
  }

  function renderTableData() {
    return removedMembers.map((user, index) => {
      const { can_rejoin, member, id , role} = user;
      return (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {member?.full_name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {member?.email}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {roles
                .filter(r => r.id === role)
                .map(filteredRole => filteredRole.title)}
          </td>
           <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Moment fromNow>{member?.date_joined}</Moment>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {can_rejoin && (
              <div onClick={onClickSendRejoinLink(id)} className='cursor-pointer text-purple-600 underline'>Send rejoin link</div>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                     <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                       <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      JOINED
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rejoin
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderTableData()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemovedMemberList;
