// CopyableSection.js
import React, { useState } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/solid';

const CopyableSection = ({ title, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      const styledContent = `${title}\n\n${content.replace(/<[^>]+>/g, '')}`;
      await navigator.clipboard.writeText(styledContent);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-md font-semibold">{title}</h3>
        <button
          onClick={handleCopy}
          className="flex items-center text-sm text-purple-600 hover:text-purple-800"
        >
          <ClipboardCopyIcon className="w-4 h-4 mr-1" />
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
      </div>
      <div
        className="p-3 bg-gray-50 rounded hover:bg-gray-100 transition-colors duration-200"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default CopyableSection;
