const factCheckerActionTypes = {
  SET_FACTS: 'SET_FACTS',
  SET_LOADING: 'SET_LOADING',
};

const initialState = {
  facts: [],
  loading: false,
};

const FactCheckerReducer = (state = initialState, action) => {
  switch (action.type) {
    case factCheckerActionTypes.SET_FACTS:
      return { ...state, facts: action.payload };
    case factCheckerActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default FactCheckerReducer;
export { factCheckerActionTypes };
