import React from 'react';
import '../../pages/Chat.scss';

const ChatEmptyState = ({ isPanelMode }) => {
  const emptyStateCards = [
    {
      title: 'Welcome!',
      text: 'Chat with Nova your friendly AI-powered assistant.',
    },
    {
      title: ' Suggested Prompt',
      text: 'Tell me more about this keyword [keyword]',
    },
    {
      title: 'Get help!',
      text: 'Contact us at support@bramework.com',
    },
  ];

  return (
    <div
      className={`${
        isPanelMode ? 'flex flex-col mt-8 gap-4 ml-32' : 'chat-empty-state'
      }`}
    >
      {emptyStateCards.map((card, index) => (
        <div
          key={index}
          className={`chat-empty-state-card ${isPanelMode && 'panel'}`}
        >
          <h3>{card.title}</h3>
          <p>{card.text}</p>
        </div>
      ))}
    </div>
  );
};

export default ChatEmptyState;
