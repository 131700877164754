import { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
export const LOCATIONS = [
  {
    location_code: 2840,
    location_name: 'United States',
    location_code_parent: null,
    country_iso_code: 'US',
    location_type: 'Country',
    available_languages: [
      {
        available_sources: ['google', 'bing', 'amazon'],
        language_name: 'English',
        language_code: 'en',
        keywords: 972041923,
        serps: 159334353,
      },
      {
        available_sources: ['google'],
        language_name: 'Spanish',
        language_code: 'es',
        keywords: 124581315,
        serps: 4724638,
      },
    ],
  },
  {
    location_code: 2124,
    location_name: 'Canada',
    location_code_parent: null,
    country_iso_code: 'CA',
    location_type: 'Country',
    available_languages: [
      {
        available_sources: ['google'],
        language_name: 'English',
        language_code: 'en',
        keywords: 259702875,
        serps: 14339568,
      },
      {
        available_sources: ['google'],
        language_name: 'French',
        language_code: 'fr',
        keywords: 140163829,
        serps: 6637120,
      },
    ],
  },
  {
    location_code: 2826,
    location_name: 'United Kingdom',
    location_code_parent: null,
    country_iso_code: 'GB',
    location_type: 'Country',
    available_languages: [
      {
        available_sources: ['google'],
        language_name: 'English',
        language_code: 'en',
        keywords: 372764317,
        serps: 30274950,
      },
    ],
  },
];
const LocationSelect = ({ onChangeLocation, location }) => {
  const [selectedLocation, setSelectedLocation] = useState(location);
  const handleSetLocation = item => {
    const newLocation = {
      language_name: item
        ? item.available_languages[0].language_name
        : undefined,
      location_code: item?.location_code,
      location_name: item?.location_name,
    };
    setSelectedLocation(newLocation);
    onChangeLocation(newLocation);
  };
  return (
    <div className="flex items-center">
      <div className="flex bg-white">
        {LOCATIONS.map(item => (
          <button
            className={`inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium ${
              selectedLocation.location_code === item.location_code
                ? 'text-purple-600 font-bold'
                : 'text-gray-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
            key={item.location_code}
            onClick={() => handleSetLocation(item)}
          >
            {item.country_iso_code}
          </button>
        ))}
        <button
          className={`inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium ${
            !selectedLocation.location_code
              ? 'text-purple-600 font-bold'
              : 'text-gray-700'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
          onClick={() => handleSetLocation()}
        >
          Global
        </button>
      </div>
    </div>
  );
};
export default LocationSelect;
