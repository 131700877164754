import fa from 'react-timeago/lib/language-strings/fa';

const authActionTypes = {
  AUTH_CHECK: 'AUTH_CHECK',
  AUTH_LOGOUT: 'AUTH_LOGOUT',

  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_TMP_USER: 'UPDATE_TMP_USER',
  SET_IS_ONBOARDING_USER: 'SET_IS_ONBOARDING_USER',
};

const defaultUser = undefined;

const initialState = {
  isAuthenticated: false,
  user: defaultUser,
  loading: false,
  error: null,
  tmpUser: null,
  isOnBoardingUser: false,
};

const authReducer = (
  state = initialState,
  {
    type,
    user = null,
    error = null,
    isAuthenticated,
    tmpUser = null,
    isOnBoardingUser,
  }
) => {
  switch (type) {
    case authActionTypes.AUTH_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: true,
        error: null,
      };
    case authActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: user,
        loading: false,
        error: null,
      };
    case authActionTypes.AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: error,
      };
    case authActionTypes.AUTH_CHECK:
      return {
        ...state,
        isAuthenticated: isAuthenticated,
        user: user,
        loading: false,
        error: null,
      };
    case authActionTypes.UPDATE_USER:
      return {
        ...state,
        user,
      };
    case authActionTypes.UPDATE_TMP_USER: {
      return { ...state, tmpUser };
    }
    case authActionTypes.SET_IS_ONBOARDING_USER: {
      return { ...state, isOnBoardingUser };
    }
    case authActionTypes.AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
export { authActionTypes };
