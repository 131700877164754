const DashboardOverviewContainer = props => {
  return (
    <div>
      <h4>Overview</h4>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 sm:px-4">
        {props.children}
      </div>
    </div>
  );
};

export default DashboardOverviewContainer;
