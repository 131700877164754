import React, { useEffect, useState } from 'react';
import './Register.scss';
import { Link, useLocation } from 'react-router-dom';
import Validator, {
  email,
  full_name,
  required,
  confirm,
} from '../services/Validator';
import classNames from 'classnames';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import Loading from '../components/Loading';
import { onSignIn, track } from '../services/track';
import { useAlert } from 'react-alert';
import http from '../services/http';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import appSumoTacoWhite from '../styles/images/as-taco-only.svg';
import googleIcon from '../styles/images/google-icon.svg';
import { PlusIcon } from '@heroicons/react/outline';
import JaniaHeadshot from '../styles/images/jania-headshot.jpg';

function RegisterForm({ validator }) {
  const auth = useSentData();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const invoice = new URLSearchParams(search).get('invoice');
  const query = new URLSearchParams(search);
  const invite = query.get('invite');
  const [data, setData] = useState({
    full_name: '',
    email: email ? email : '',
    password: '',
    password_confirm: '',
    confirm: false,
    invite: invite || '',
  });
  const [errors, setErrors] = useState({
    full_name: false,
    email: false,
    password: false,
    password_confirm: false,
    confirm: false,
  });
  const alert = useAlert();
  useEffect(() => {
    if (invite) {
      setData({ ...data, invite });
    }
  }, [invite]);

  function change(name, val) {
    const newData = {
      ...data,
      [name]: val,
    };
    setData(newData);
    setErrors(validator.validateAll(newData));
  }

  return (
    <div className="flex min-h-full register-wrapper">
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 w-full lg:w-1/2">
        {!!!invoice ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={logoIconPurple}
              alt="Bramework"
            />
            <h2 className="mt-6 text-center text-xl lg:text-2xl font-extrabold text-gray-900">
              {/* Start your free 7-day trial and write blog posts 5X faster! */}
              Start writing high-quality long-form blog posts in a few clicks!
            </h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              No credit card required.
            </p> */}
          </div>
        ) : (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex mx-auto justify-between w-60">
              <img className="h-12" src={logoIconPurple} alt="Bramework" />
              <PlusIcon
                className="h-8 w-8 text-gray-400 mt-2"
                aria-hidden="true"
              />
              <img className="h-12" src={appSumoTacoWhite} alt="AppSumo" />
            </div>

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create a New Account
            </h2>
          </div>
        )}
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                if (validator.isValid()) {
                  auth
                    .send('/api/registration', { ...data, invoice: invoice })
                    .then(data => {
                      if (!data.isAxiosError) {
                        const token = data.token;
                        http.defaults.headers.common.Authorization = `JWT ${token}`;
                        const user = data.user;
                        saveAuth(token, user);
                        window.location.href = '/';
                        window.fpr('referral', {
                          email: user.email,
                        });
                        var date = new Date(user.date_joined);
                        var unixTimeStamp = Math.floor(date.getTime() / 1000);
                        if (process.env.REACT_APP_INTERCOM_ID) {
                          window.Intercom('boot', {
                            app_id: process.env.REACT_APP_INTERCOM_ID,
                            name: user.full_name, // Full name
                            email: user.email, // Email address
                            user_id: user.id, // User id
                            created_at: unixTimeStamp, // Signup date as a Unix timestamp
                            sumo_ling: !!!invoice ? false : true,
                          });
                        }
                        window.profitwell('user_email', user.email);
                        if (
                          window &&
                          window.mixpanel &&
                          window.mixpanel.identify &&
                          window.mixpanel.people &&
                          window.mixpanel.people.set
                        ) {
                          window.mixpanel.alias(user.email);
                          track('Sign up');
                        }
                      } else {
                        alert.error(isolateError(data));
                      }
                    });
                }
              }}
            >
              {auth.loading && <Loading />}
              <input type="hidden" name="invite" value={invite} />
              <div>
                <label
                  htmlFor="full_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full name
                </label>
                <div className="mt-1">
                  <input
                    id="full_name"
                    name="full_name"
                    type="text"
                    autoComplete="full_name"
                    value={data.full_name}
                    onChange={e => change('full_name', e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    disabled={!!email}
                    value={data.email}
                    onChange={e => change('email', e.target.value)}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={data.password}
                    onChange={e => change('password', e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password_confirm"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="password_confirm"
                    name="password_confirm"
                    type="password"
                    value={data.password_confirm}
                    onChange={e => change('password_confirm', e.target.value)}
                    required
                    aria-invalid={errors.password_confirm}
                    aria-describedby="password-confirm-error"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.password_confirm && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-confirm-error"
                    >
                      {errors.password_confirm}
                    </p>
                  )}
                </div>
              </div>

              {!!!invoice && (
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    Already have an account?&nbsp;
                    <Link
                      className="font-medium text-purple-600 hover:text-purple-500"
                      to="/"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              )}

              <div>
                <button
                  id="submit"
                  disabled={
                    !errors.email ||
                    !errors.password ||
                    !errors.full_name ||
                    !errors.password_confirm
                  }
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    !errors.full_name ||
                    !errors.email ||
                    !errors.password ||
                    !errors.password_confirm
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                >
                  Get started
                </button>
              </div>
            </form>

            <div className="mt-6">
              {!!!(invoice || invite || true) && (
                <>
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">Or</span>
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium border border-gray-300  bg-white text-gray-700 hover:bg-gray-50 cursor-pointer"
                    >
                      <img
                        className="mr-4 w-5 h-5"
                        src={googleIcon}
                        alt="Google icon"
                        aria-hidden="true"
                      />
                      Continue with Google
                    </button>
                  </div>
                </>
              )}

              <div className="mt-6">
                <p className="text-center text-xs text-gray-500">
                  Bramework will never sell any personal information to third
                  parties By signing up, I agree to Bramework's&nbsp;
                  <a
                    className="font-medium text-purple-600 hover:text-purple-500"
                    href="https://www.bramework.com/terms-of-use/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    className="font-medium text-purple-600 hover:text-purple-500"
                    href="https://www.bramework.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden min-h-full lg:flex flex-col md:w-1/2 items-center justify-center bg-purple-600">
        <div className="w-60 h-60 avatar rounded-full"></div>
        <div className="w-3/4 xl:w-1/2 mt-5 text-center">
          <div className="text-white mt-4 text-xl 2xl:text-2xl">
            "The time it used to take me to write one blog, I can now create 3-5
            blog posts using Bramework."
          </div>
          <div className="text-white text-xl 2xl:text-2xl">- Jania</div>
          <div className="text-white text-xl 2xl:text-2xl mt-36 font-bold">
            Join our mission to help 10,000 bloggers earn $10,000+ a month!
          </div>
        </div>
      </div>
    </div>
  );
}

function Register() {
  const validator = Validator({
    full_name: [required, full_name],
    email: [required, email],
    password: [required],
    password_confirm: [required, confirm('password')],
    // confirm: [required],
  });

  return <RegisterForm validator={validator} />;
}
function saveAuth(token, user) {
  onSignIn(user);
  localStorage.setItem('access_token', token);
  localStorage.setItem('user', JSON.stringify(user));
}
export default Register;
