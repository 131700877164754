import React, { useState, useEffect } from 'react';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import { numberToThousands } from '../../utils/common';
import Select from '../../components/form/FormSelect';

function AddonSection({ user, updateUser }) {
  const [addons, setAddons] = useState([]);
  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    getAddons();
  }, []);

  const getAddons = async () => {
    const response = await request.send('/api/addons', null, 'get');
    if (!response.isAxiosError) {
      const aiWords = [],
        plagiarismWords = [],
        firstDrafts = [];
      response.forEach(addon => {
        if (addon.pricing_model !== 'flat_fee') return;
        const item = {
          ...addon,
          label: `${
            addon.allowed_words
              ? numberToThousands(addon.allowed_words) + ' AI words'
              : addon.plagiarism_word_limit
              ? numberToThousands(addon.plagiarism_word_limit) +
                ' plagiarism words'
              : numberToThousands(addon.first_draft_credits) +
                (addon.first_draft_credits > 1
                  ? ' First Draft credits'
                  : ' First Draft credit')
          } - $${(addon.unit_price / 100).toLocaleString()}`,
          value: addon.id,
        };
        if (addon.plagiarism_word_limit > 0) {
          plagiarismWords.push(item);
        } else if (addon.allowed_words) {
          aiWords.push(item);
        } else if (addon.first_draft_credits && user.first_draft) {
          firstDrafts.push(item);
        }
      });
      aiWords.sort((a, b) => a.allowed_words - b.allowed_words);
      plagiarismWords.sort(
        (a, b) => a.plagiarism_word_limit - b.plagiarism_word_limit
      );
      firstDrafts.sort((a, b) => a.first_draft_credits - b.first_draft_credits);
      setAddons([
        {
          title: 'Purchase additional AI words',
          isAIWords: true,
          options: aiWords,
        },
        {
          title: 'Purchase additional plagiarism words',
          isAIWords: false,
          options: plagiarismWords,
        },
        {
          title: 'Purchase First Draft PRO credits',
          isFirstDrafts: true,
          options: firstDrafts,
        },
      ]);
    } else {
      alert.error('Failed to fetch add-ons');
    }
  };

  const addAddon = async selectedOption => {
    if (!selectedOption) return;
    const addon_id = selectedOption.value;
    const quantity = 1;
    try {
      await request.send('/api/add-addon', { addon_id, quantity }, 'post');
      window.Chargebee.getInstance().openCheckout({
        hostedPage: () =>
          request.send('/api/add-addon', { addon_id, quantity }),
        success: () => {
          getAddons();
          alert.success('Add-on purchased successfully!');
        },
      });
    } catch (error) {
      alert.error('Failed to add add-on. Please try again.');
    }
  };

  return (
    <div className="mt-10">
      <h2 className="text-2xl font-bold mb-6 text-center">
        Additional Add-ons
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {addons.map((addon, index) => (
          <div
            key={index}
            className="bg-white shadow overflow sm:rounded-lg p-6"
          >
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              {addon.title}
            </h3>
            <Select
              id={`addon-${index}`}
              className="account-select mb-6"
              options={addon.options}
              onChange={addAddon}
              placeholder="Select an option"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddonSection;
