const googlePropertiesActionType = {
  FETCH_GOOGLE_PROPERTIES: 'FETCH_GOOGLE_PROPERTIES',
  SELECTED_GOOGLE_PROPERTIES: 'SELECTED_GOOGLE_PROPERTIES',
  REMOVE_GOOGLE_PROPERTIES: 'REMOVE_GOOGLE_PROPERTIES',
  GOOGLE_PROPERTIES_LOADING: 'GOOGLE_PROPERTIES_LOADING',
  GOOGLE_PROPERTIES_FAILURE: 'GOOGLE_PROPERTIES_FAILURE',
  GOOGLE_PROPERTIES_DESTROY: 'GOOGLE_PROPERTIES_DESTROY',
};
const initialState = {
  googleProperties: [],
  selectedGoogleProperties: null,
  loading: false,
  error: false,
  errorMsg: null,
};
const GooglePropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case googlePropertiesActionType.GOOGLE_PROPERTIES_DESTROY:
      return { ...initialState };
    case googlePropertiesActionType.GOOGLE_PROPERTIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case googlePropertiesActionType.GOOGLE_PROPERTIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case googlePropertiesActionType.FETCH_GOOGLE_PROPERTIES:
      let selectedGoogleProperties = state.selectedGoogleProperties;
      if (selectedGoogleProperties == null) {
        const selectedGooglePropertiesName = localStorage.getItem(
          'selectedGooglePropertiesName'
        );
        if (selectedGooglePropertiesName) {
          selectedGoogleProperties = action.payload.find(
            property => property.name == selectedGooglePropertiesName
          );
        }
      }
      return {
        ...state,
        googleProperties: action.payload,
        selectedGoogleProperties: selectedGoogleProperties,
        loading: false,
        error: false,
        errorMsg: null,
      };
    case googlePropertiesActionType.SELECTED_GOOGLE_PROPERTIES:
      if (state.googleProperties.length > 0) {
        const selectedGoogleProperties = state.googleProperties.find(
          property => property.name === action.payload.value
        );
        localStorage.setItem(
          'selectedGooglePropertiesName',
          selectedGoogleProperties.name
        );
        return {
          ...state,
          loading: false,
          selectedGoogleProperties: selectedGoogleProperties,
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default GooglePropertyReducer;
export { googlePropertiesActionType };
