import RegionFormSelect from './RegionFormSelect';
import cx from 'classnames';

import { REGIONS } from '../../utils/regions';

const InputAndRegionForm = ({
  inputType = 'text',
  inputPlaceholder = '',
  inputValue = '',
  inputDisabled = false,
  regionValue = REGIONS[0],
  buttonLabel = 'Submit',
  buttonDisabled = false,
  onInputChange = () => {},
  onRegionChange = () => {},
  onSubmit = () => {},
}) => {
  return (
    <form
      className="mt-6 sm:flex sm:items-center"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
        <input
          type={inputType}
          className="focus:ring-purple-500 focus:border-purple-500 block w-full pr-32 sm:text-md border-gray-300 rounded-md h-12"
          placeholder={inputPlaceholder}
          value={inputValue}
          disabled={inputDisabled}
          onChange={onInputChange}
          data-hj-allow
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          {REGIONS.length > 0 && (
            <RegionFormSelect
              regions={REGIONS}
              selectedRegion={regionValue}
              setSelectedRegion={onRegionChange}
            />
          )}
        </div>
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
        <button
          disabled={buttonDisabled}
          type="submit"
          className={cx(
            buttonDisabled
              ? 'cursor-default bg-gray-300'
              : 'cursor-pointer bg-purple-600 hover:bg-purple-700 focus:ring-purple-500',
            'w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-xl'
          )}
        >
          {buttonLabel}
        </button>
      </div>
    </form>
  );
};

export default InputAndRegionForm;
