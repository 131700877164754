import React, { useRef, useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import PostBuilderControls from './PostBuilderControls';
import PostBuilderSidePanel from './PostBuilderSidePanel';
import PostBuilderPlagiarismPanel from './PostBuilderPlagiarismPanel';
import Loading from '../components/Loading';

export const PostBuilderControlsLoader = React.forwardRef((props, ref) => {
  const [displayControls, setDisplayControls] = useState(false);
  const childElem = useRef();
  function updateFeaturedImage(i, state) {
    if (childElem?.current?.updateFeaturedImageFnc) {
      childElem.current.updateFeaturedImageFnc(i, state);
    }
  }

  function doneWithPostFnc() {
    if (childElem && childElem.current && childElem.current.doneWithPostFnc) {
      childElem.current.doneWithPostFnc();
    }
  }

  function handleEditorChange(content, editor) {
    if (childElem?.current?.handleEditorChange) {
      childElem.current.handleEditorChange(content, editor);
    }
  }

  function handleSelectionChange(data, editor) {
    if (childElem?.current?.handleEditorChange) {
      childElem.current.handleSelectionChange(data, editor);
    }
  }

  function handleOnKeyUp(content, editor) {
    if (childElem && childElem.current && childElem.current.handleOnKeyUp) {
      childElem.current.handleOnKeyUp(content, editor);
    }
  }

  function generateText(editor, type, selection) {
    if (childElem && childElem.current && childElem.current.generateText) {
      childElem.current.generateText(editor, type, selection);
    }
  }

  function showLibraryModal(status) {
    if (childElem?.current?.showLibraryModalFnc) {
      childElem.current.showLibraryModalFnc(status);
    }
  }

  function showImageMetaModal(status, target) {
    if (childElem?.current?.showImageMetaModalFnc) {
      childElem.current.showImageMetaModalFnc(status, target);
    }
  }
  function topOptionBarHandle(selected_opt, param) {
    if (!childElem?.current?.[selected_opt]) {
      return;
    }

    switch (selected_opt) {
      case 'preview':
        childElem.current.preview();
        break;
      case 'saveAsDraft':
        childElem.current.saveAsDraft(param);
        break;
      case 'plagiarismCheck':
        childElem.current.plagiarismCheck();
        break;
      case 'publish':
        childElem.current.publish();
        break;
      case 'share':
        childElem.current.share();
        break;
      case 'postURL':
        childElem.current.postURL();
        break;
      case 'downloadhtml':
        childElem.current.download('html');
        break;
      case 'downloadpdf':
        childElem.current.download('pdf');
        break;
      case 'downloaddocx':
        childElem.current.download('docx');
        break;
      case 'downloadtxt':
        childElem.current.download('txt');
        break;
      case 'downloadversions':
        childElem.current.download('versions');
        break;
      case 'exit':
        childElem.current.exit();
        break;
      case 'delete':
        childElem.current.delete();
        break;
      default:
        break;
    }
  }

  useImperativeHandle(ref, () => ({
    displayComp: data => {
      setDisplayControls(data);
    },
    topOptionBarHandle: (selected_opt, param) => {
      topOptionBarHandle(selected_opt, param);
    },
    showImageMetaModal: (status, target) => {
      showImageMetaModal(status, target);
    },
    showLibraryModal: status => {
      showLibraryModal(status);
    },
    generateText: (editor, type, selection) => {
      generateText(editor, type, selection);
    },
    handleOnKeyUp: (content, editor) => {
      handleOnKeyUp(content, editor);
    },
    handleSelectionChange: (data, editor) => {
      handleSelectionChange(data, editor);
    },
    handleEditorChange: (content, editor) => {
      handleEditorChange(content, editor);
    },
    doneWithPostFnc: () => {
      doneWithPostFnc();
    },
    updateFeaturedImage: (i, state) => {
      updateFeaturedImage(i, state);
    },
  }));

  if (displayControls === false) {
    return null;
  } else {
    return (
      <PostBuilderControls
        mainRef={props.mainRef}
        ref={childElem}
        editorRef={props.editorRef}
        updateProgressState={props.updateProgressState}
        updateSeoTool={props.updateSeoTool}
        editorLoaded={props.editorLoaded}
        updateLoader={props.updateLoader}
      />
    );
  }
});

export const PostBuilderSidePanelLoader = React.forwardRef((props, ref) => {
  const [displayControls, setDisplayControls] = useState(false);
  const seoRef = useRef();

  function updateSeoToolFnc(state_type, data) {
    if (seoRef && seoRef.current && seoRef.current.updateState) {
      seoRef.current.updateState(state_type, data);
    }
  }
  function handleSelectionChangeFnc() {
    if (seoRef && seoRef.current && seoRef.current.handleSelectionChange) {
      seoRef.current.handleSelectionChange();
    }
  }
  useImperativeHandle(ref, () => ({
    displayComp: data => {
      setDisplayControls(data);
    },
    updateSeoTool: (state_type, data) => {
      updateSeoToolFnc(state_type, data);
    },
    handleSelectionChange: () => {
      handleSelectionChangeFnc();
    },
  }));

  if (displayControls === false) {
    return null;
  } else {
    return (
      <PostBuilderSidePanel
        ref={seoRef}
        generateText={props.generateText}
        editorRef={props.editorRef}
        progressRef={props.progressRef}
        updateFeaturedImage={props.updateFeaturedImage}
        changePostLoader={props.changePostLoader}
      />
      // <PostBuilderPlagiarismPanel
      //   generateText={props.generateText}
      //   editorRef={props.editorRef}
      // />
    );
  }
});

export const PostBuilderLoader = React.forwardRef((props, ref) => {
  const [displayLoader, setDisplayLoader] = useState(true);

  useImperativeHandle(ref, () => ({
    updateLoader: data => {
      setDisplayLoader(data);
    },
  }));

  if (displayLoader === false) {
    return null;
  }
  return (
    <div
      className="justify-center pt-96"
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: '1',
        background: '#fff',
        opacity: '.8',
      }}
    >
      <Loading />
      <div className="font-bold text-center w-full">
        Loading post builder...
      </div>
    </div>
  );
});
