import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import './NovaOnBoardingGuide.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingLocalStoreNames,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import novaBot from '../../styles/images/nova-onboarding.svg';
import novaGuideComplete from '../../styles/images/nova-onboarding-compplete.gif';
import Modal from 'react-modal';
import { ArrowUpIcon, CheckCircleIcon, XIcon } from '@heroicons/react/solid';
import { CheckCircleIcon as CheckCircleIcon2 } from '@heroicons/react/outline';
import classname from 'classnames';
import { novaOnBoardingGuideCompleted } from '../../store/actions/nova_onboarding.action';
import panelCollapse, {
  setSidePanelOpenTab,
} from '../../store/actions/side_panel.action';
import { sidePanelTabs } from '../../store/reducers/side_panel.reducer';

function NovaContent({ step, checkList, onClosePopup }) {
  const [modalType, setModalType] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  const checkListContent = [
    {
      id: novaOnBoardingCheckLists.GENERATE_A_TITLE,
      text: 'Generate a title',
      isDone: checkList.includes(novaOnBoardingCheckLists.GENERATE_A_TITLE),
      videoGuide: 'https://www.youtube.com/embed/L5GC3RnjEeA',
    },
    {
      id: novaOnBoardingCheckLists.GENERATE_AN_OUTLINE,
      text: 'Generate an intro & outline',
      isDone: checkList.includes(novaOnBoardingCheckLists.GENERATE_AN_OUTLINE),
    },
    {
      id: novaOnBoardingCheckLists.GENERATE_PARAGRAPH_FOR_EACH_HEADER,
      text: 'Generate paragraphs for each header',
      isDone: checkList.includes(
        novaOnBoardingCheckLists.GENERATE_PARAGRAPH_FOR_EACH_HEADER
      ),
      videoGuide: 'https://www.youtube.com/embed/cBQ5T8wrr2c',
    },
    {
      id: novaOnBoardingCheckLists.INCLUDE_AN_IMAGE,
      text: 'Include an image',
      isDone: checkList.includes(novaOnBoardingCheckLists.INCLUDE_AN_IMAGE),
      videoGuide: '',
    },
    {
      id: novaOnBoardingCheckLists.EDIT_WITH_REPHRASE_OR_COMMAND,
      text: 'Edit with rewrite or command',
      isDone: checkList.includes(
        novaOnBoardingCheckLists.EDIT_WITH_REPHRASE_OR_COMMAND
      ),
      videoGuide: 'https://www.youtube.com/embed/2ozo7EQqp-o',
    },
  ];

  const onClickViewVideoGuide = item => {
    return () => {
      if (item.id === novaOnBoardingCheckLists.INCLUDE_AN_IMAGE) {
        const addImageBtn = document.querySelector(
          '[aria-label="Upload or select suggested images"]'
        );
        if (addImageBtn) {
          addImageBtn.classList.add('button-highlight');
          setTimeout(() => {
            addImageBtn.click();
            addImageBtn.classList.remove('button-highlight');
          }, 2000);
        }
        return;
      }
      if (item.videoGuide) {
        setVideoUrl(item.videoGuide);
        setModalType(item.id);
      }
    };
  };

  const renderContent = () => {
    if (step === novaOnBoardingSteps.WRITE_A_FIRST_DRAFT) {
      return (
        <>
          <div className="font-bold">
            Hi, I’m Nova! Let’s write a blog post...
          </div>
          <div className="text-sm">
            Use the drop-down menu to tell the AI to generate a title, outline,
            paragraph, list, steps, rewrite, and give the AI specific commands
            of what to write!
          </div>
          <div className="p-2 mt-2 text-xs bg-gray-200 rounded">
            <b>Tip:</b> Watch this video of
            <span
              onClick={onClickViewVideoGuide(checkListContent[0])}
              className="cursor-pointer text-purple-500 font-bold"
            >
              {' '}
              how to go from a blank page to a fully written blog post
            </span>{' '}
            using Bramework
          </div>
        </>
      );
    } else if (step === novaOnBoardingSteps.GENERATE_AN_OUTLINE) {
      return (
        <div className="font-bold">
          Now use the drop-down to generate an intro & outline.
        </div>
      );
    } else if (step === novaOnBoardingSteps.COMPLETE_CHECK_LIST) {
      return (
        <>
          <div className="font-bold">
            Complete these and get <br />
            <span className="text-purple-700"> 1,000 words credits</span>
          </div>
          {checkListContent.map(item => (
            <div
              key={item.id}
              onClick={onClickViewVideoGuide(item)}
              className={classname(
                'flex mt-2 rounded p-1 text-xs shadow shadow-md-gray items-center gap-1',
                {
                  'bg-gray-200': item.isDone,
                }
              )}
            >
              {item.isDone ? (
                <CheckCircleIcon className="w-4 h-4 text-purple-700" />
              ) : (
                <CheckCircleIcon2 className="w-4 h-4 text-purple-700 " />
              )}
              <p
                className={classname({
                  'line-through text-gray-500': item.isDone,
                  'underline text-purple-700 cursor-pointer': !item.isDone,
                })}
              >
                {item.text}
              </p>
              {!item.isDone && (
                <ArrowUpIcon
                  className="w-3 h-3 text-purple-700"
                  style={{ rotate: '45deg' }}
                />
              )}
            </div>
          ))}
        </>
      );
    } else if (step === novaOnBoardingSteps.NOVA_GUIDE_COMPLETED) {
      return (
        <>
          <div className="font-bold">
            You did it! We’re adding 1,000 word credits to your account. Keep on
            blogging!
          </div>
          <div className="btn-close" onClick={onClosePopup}>
            <XIcon className="w-6 h-6 md:w-10 md:h-10 text-white" />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {renderContent()}
      <Modal
        className={'guide-video-modal'}
        isOpen={!!modalType}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setModalType('')}
      >
        <iframe
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div className="btn-close" onClick={() => setModalType('')}>
          <XIcon className="w-6 h-6 md:w-10 md:h-10 text-white" />
        </div>
      </Modal>
    </>
  );
}

function NovaOnBoardingGuide() {
  const { step, novaCheckList } = useSelector(
    state => state.novaOnBoardingGuide
  );
  const [isShowBackground, setIsShowBackground] = useState(false);
  const [isShowNova, setIsShowNova] = useState(false);
  const [isShowGuide, setIsShowGuide] = useState(false);
  const novaBackgroundRef = useRef();
  const novaBotRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    handleStepChange();
  }, [step]);

  const handleStepChange = () => {
    switch (step) {
      case novaOnBoardingSteps.WRITE_A_FIRST_DRAFT:
      case novaOnBoardingSteps.GENERATE_AN_OUTLINE:
        dispatch(setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS));
        dispatch(panelCollapse(1, false));
        const sidePanel = document.getElementById('side-panel');
        setTimeout(
          () => {
            setIsShowNova(true);
            setIsShowGuide(true);
            drawBackground();
          },
          sidePanel?.getBoundingClientRect() ? 0 : 3000
        );
        break;
      case novaOnBoardingSteps.COMPLETE_CHECK_LIST:
        novaBotRef.current.style.left = '100px';
        novaBotRef.current.style.top = 'unset';
        novaBotRef.current.style.bottom = '70px';
        setIsShowNova(true);
        setIsShowGuide(true);
        break;
      case novaOnBoardingSteps.NOVA_GUIDE_COMPLETED:
        setIsShowNova(true);
        setIsShowGuide(true);
        drawBackground();
        break;
    }
  };

  const drawBackground = () => {
    setIsShowBackground(true);
    const canvas = novaBackgroundRef.current;
    const sidePanel = document.getElementById('side-panel');
    const { x, width } = sidePanel?.getBoundingClientRect() || {};
    const ctx = canvas?.getContext('2d');
    const cw = window.innerWidth;
    const ch = window.innerHeight;
    canvas.width = cw;
    canvas.height = ch;
    ctx.save();
    ctx.globalAlpha = 0.7;
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, cw, ch);
    ctx.save();
    ctx.beginPath();
    if (step === novaOnBoardingSteps.WRITE_A_FIRST_DRAFT) {
      ctx.clearRect(x, 0, width, 250);
      novaBotRef.current.style.left = x - 600 + 'px';
      novaBotRef.current.style.top = '20px';
    } else if (step === novaOnBoardingSteps.GENERATE_AN_OUTLINE) {
      novaBotRef.current.style.left = x - 600 + 'px';
      novaBotRef.current.style.top = '150px';
      ctx.clearRect(x, 120, width, 400);
    } else if (step === novaOnBoardingSteps.NOVA_GUIDE_COMPLETED) {
      novaBotRef.current.style.left = '50%';
      novaBotRef.current.style.top = '50%';
      novaBotRef.current.style.marginLeft = '-280px';
      novaBotRef.current.style.marginTop = '-300px';
      novaBotRef.current.style.marginTop = '-300px';
      novaBotRef.current.style.background = `url(${novaGuideComplete})`;
      novaBotRef.current.style.height = '520px';
    }
    ctx.clip();
    ctx.restore();
  };

  const onClickShowGuide = () => {
    setIsShowGuide(prevState => !prevState);
  };

  const onFinishGuide = () => {
    setIsShowBackground(false);
    setIsShowNova(false);
    setIsShowGuide(false);
    dispatch(novaOnBoardingGuideCompleted());
  };

  const onClickCloseGuide = () => {
    if (step !== novaOnBoardingSteps.NOVA_GUIDE_COMPLETED) {
      setIsShowBackground(false);
      setIsShowNova(false);
    }
  };

  return (
    <div className="nova-onboarding">
      <div
        ref={novaBotRef}
        className={classname('fixed', {
          'z-50 flex gap-3': isShowNova,
          hidden: !isShowNova,
          'items-center': step !== novaOnBoardingSteps.COMPLETE_CHECK_LIST,
          'items-end': step === novaOnBoardingSteps.COMPLETE_CHECK_LIST,
        })}
      >
        <img
          onClick={onClickShowGuide}
          className={classname('nova-bot-img cursor-pointer', {
            small: step === novaOnBoardingSteps.COMPLETE_CHECK_LIST,
          })}
          alt="Nova Bot"
          src={novaBot}
        />
        <div
          className={classname('guide-content bg-white p-4 rounded-md ', {
            hidden: !isShowGuide,
            small: step === novaOnBoardingSteps.COMPLETE_CHECK_LIST,
          })}
        >
          <NovaContent
            step={step}
            onClosePopup={onFinishGuide}
            checkList={novaCheckList}
          />
        </div>
      </div>
      <canvas
        onClick={onClickCloseGuide}
        ref={novaBackgroundRef}
        className={classname('fixed', {
          'z-40 w-full h-full': isShowBackground,
          hidden: !isShowBackground,
        })}
      ></canvas>
    </div>
  );
}

export default NovaOnBoardingGuide;
