import { projectActionTypes } from '../reducers/project.reducer';
import http from '../../services/http';

export const destroyProjects = () => {
  return dispatch => {
    dispatch({ type: projectActionTypes.PROJECT_DESTROY });
  };
};

export const fetchProjects = successCallback => {
  const success = payload => ({
    type: projectActionTypes.FETCH_PROJECTS,
    payload: payload,
  });
  return dispatch => {
    dispatch({ type: projectActionTypes.PROJECT_LOADING });
    http
      .get(`/api/projects`)
      .then(data => {
        dispatch(success(data.data));
        if (successCallback) {
          successCallback(data.data);
        }
      })
      .catch(error => {
        dispatch({
          type: projectActionTypes.PROJECT_FAILURE,
          payload: error,
        });
      });
  };
};

export const createProject = payload => {
  return {
    type: projectActionTypes.ADD_PROJECT,
    payload: payload,
  };
};

export const editProject = payload => {
  return {
    type: projectActionTypes.UPDATE_PROJECT,
    payload: payload,
  };
};

export const selectProject = payload => {
  return {
    type: projectActionTypes.SELECTED_PROJECT,
    payload: payload,
  };
};

export const deleteProject = payload => {
  return {
    type: projectActionTypes.DELETE_PROJECT,
    payload: payload,
  };
};

export const loadingProject = payload => {
  return {
    type: projectActionTypes.PROJECT_LOADING,
  };
};
