const novaOnBoardingActionTypes = {
  SET_STEP: 'SET_STEP',
  SET_CHECK_LIST_DONE: 'SET_CHECK_LIST_DONE',
  LOAD_NOVA_GUIDE_DATA: 'LOAD_NOVA_GUIDE_DATA',
  NOVA_ONBOARDING_GUIDE_COMPLETE: 'NOVA_ONBOARDING_GUIDE_COMPLETE',
};

const novaOnBoardingSteps = {
  WRITE_A_FIRST_DRAFT: 'WRITE_A_FIRST_DRAFT',
  GENERATE_AN_OUTLINE: 'GENERATE_AN_OUTLINE',
  COMPLETE_CHECK_LIST: 'COMPLETE_CHECK_LIST',
  NOVA_GUIDE_COMPLETED: 'NOVA_GUIDE_COMPLETED',
};

const novaOnBoardingCheckLists = {
  GENERATE_A_TITLE: 'generated title',
  GENERATE_AN_OUTLINE: 'generated outline',
  GENERATE_PARAGRAPH_FOR_EACH_HEADER: 'generated paragraph',
  INCLUDE_AN_IMAGE: 'added image',
  EDIT_WITH_REPHRASE_OR_COMMAND: 'generated rephrase or command',
};

const novaOnBoardingLocalStoreNames = {
  NOVA_GUIDE_STEP: 'NOVA_GUIDE_STEP',
  NOVA_GUIDE_CHECK_LIST: 'NOVA_GUIDE_CHECK_LIST',
};

const initialState = {
  step: '',
  novaCheckList: [],
};

const novaOnBoardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case novaOnBoardingActionTypes.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case novaOnBoardingActionTypes.SET_CHECK_LIST_DONE:
      return {
        ...state,
        novaCheckList: action.payload,
      };
    case novaOnBoardingActionTypes.LOAD_NOVA_GUIDE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case novaOnBoardingActionTypes.NOVA_ONBOARDING_GUIDE_COMPLETE:
      return initialState;
    default:
      return state;
  }
};

export default novaOnBoardingReducer;
export {
  novaOnBoardingActionTypes,
  novaOnBoardingSteps,
  novaOnBoardingCheckLists,
  novaOnBoardingLocalStoreNames,
};
