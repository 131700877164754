import React from 'react';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import IntegrationsPlatform from '../components/integrationsPlatform/IntegrationsPlatform';

function Integrations() {
  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="Integrations" category="integration" />}
    >
      <div className="mt-8">
        <IntegrationsPlatform />
      </div>
    </NewWrapper>
  );
}

export default Integrations;
