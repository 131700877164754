import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { numberToThousands } from '../../utils/common';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TopRankedPages = ({ topRankedPagesData, isButtonDisabled }) => {
  let [selectedTab, setSelectedTab] = useState(0);
  const tabs = ['Today', 'Last 7 Days', 'Last 30 Days'];
  const tabKeys = ['Today', 'Last7Days', 'Last30Days'];

  return (
    <div className="w-full">
      {/* <h2 className="text-lg font-semibold mb-4">Top Ranked Pages</h2> */}
      <div className="flex items-center space-x-4 mb-2">
        <h2 className="text-lg font-semibold">Top Viewed Pages</h2>
        <button
          className={`rounded px-2 py-1 text-xs font-medium ${
            isButtonDisabled
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'shadow-sm ring-1 ring-inset bg-white text-purple-700 ring-purple-300 hover:bg-purple-50'
          }`}
          // onClick={isButtonDisabled ? null : reviewAnalytics}
          disabled={isButtonDisabled}
        >
          Insights
        </button>
      </div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex p-1 space-x-1 bg-purple-900/20 rounded-xl">
          {tabs.map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                  'focus:outline-none',
                  selected
                    ? 'bg-white shadow text-purple-700'
                    : 'text-purple-700 hover:bg-white/[0.12] hover:text-purple-700'
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white p-3',
                'focus:outline-none'
              )}
            >
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="text-left">Page</th>
                      <th className="text-left">Keyword</th>
                      {/* <th className="text-left">Position</th> */}
                      <th className="text-left">Clicks</th>
                      <th className="text-left">Impressions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {topRankedPagesData[tabKeys[idx]] &&
                      topRankedPagesData[tabKeys[idx]].map((page, index) => (
                        <tr key={index}>
                          <td className="text-left">
                            <a
                              href={page.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-purple-600 hover:text-purple-900"
                            >
                              {new URL(page.url).pathname}
                            </a>
                          </td>
                          <td className="text-left">{page.keyword}</td>
                          {/* <td className="text-left">{numberToThousands(Math.round(page.position))}</td> */}
                          <td className="text-left">
                            {numberToThousands(page.clicks)}
                          </td>
                          <td className="text-left">
                            {numberToThousands(page.impressions)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TopRankedPages;
