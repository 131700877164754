import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { track } from '../../services/track';

import HighLightTip from './HighLightTip';
import TabItems from './TabItems';
import { processHTML } from '../../utils/processHTML';
import { sortListByDate } from '../../utils/common';
import ReactTooltip from 'react-tooltip';
import Tip from '../ComponentTip';
import { addGeneratedSummarize } from '../../store/actions/post_builder.actions';

function SummarizeTab({ aiGenerate, editorRef }) {
  const request = useSentData();
  const [summarize, setSummarize] = useState([]);
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const highLightWords = useSelector(state => state.post.highlightWords);
  const generatedSummarize = useSelector(
    state => state.post.generatedSummarizes
  );

  const minCharacter = 30;
  const maxCharacter = 4000;

  useEffect(() => {
    sortListByDate(generatedSummarize, 'created_at');
    setSummarize(generatedSummarize);
  }, [generatedSummarize]);

  const generateSummarize = async () => {
    track('Blog post creator', { Clicked: 'generate summarize' });
    setSummarizeLoading(true);
    const data = await aiGenerate('summarize-text');
    summarize.push(data);
    setSummarizeLoading(false);
    sortListByDate(summarize, 'created_at');
    setSummarize([...summarize]);
    document.getElementsByClassName('tab-content')[0]?.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(
        addGeneratedSummarize(
          summarize.map(item => ({ ...item, isNewItem: false }))
        )
      );
    }, 4000);
  };

  const updateLikeSummarize = (status, summarize) => {
    const method = 'post';
    if (
      summarize?.like_status &&
      summarize?.like_status[0]?.status === status
    ) {
      status = null;
    }
    const url = status ? `/api/like-summary` : '/api/like-summary-remove';
    const updatedData = { generation_id: summarize.generation_id, status };
    if (summarize.like_status) {
      summarize.like_status[0].status = status;
    } else {
      summarize.like_status = [{ status }];
    }

    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onDeleteSummarize = ({ generation_id }, index) => {
    const url = `/api/delete-summary`;
    const method = 'post';
    const updatedData = { generation_id };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newSummarize = [...summarize];
          newSummarize.splice(index, 1);
          setSummarize(newSummarize);
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickAdd = summarize => {
    if (editorRef?.current?.editor) {
      if (
        summarize.output.html.startsWith('<p>') &&
        summarize.output.html.endsWith('</p>')
      ) {
        editorRef?.current?.editor?.insertContent(
          processHTML(summarize.output.html)
        );
      } else {
        editorRef?.current?.editor?.insertContent(summarize.output.html);
      }
    }
  };

  const onClickCopy = summarize => {
    navigator.clipboard.writeText(summarize.output.text);
    alert.success('Successfully copied to clipboard!');
  };

  return (
    <div className="tool-tab">
      <div className="overflow-y-auto tab-content">
        <div className="flow-root divide-y divide-gray-200">
          <HighLightTip
            minCharacter={minCharacter}
            maxCharacter={maxCharacter}
            type={'summarize'}
          />
          <div className="mt-4" />
          <TabItems
            dataList={summarize}
            onClickCopy={onClickCopy}
            onClickAdd={onClickAdd}
            onClickLike={summarize => updateLikeSummarize(1, summarize)}
            onClickDislike={summarize => updateLikeSummarize(2, summarize)}
            onClickDelete={onDeleteSummarize}
          >
            {summarize => (
              <div
                className="w-80 sm:w-116 text-sm font-medium text-gray-900"
                dangerouslySetInnerHTML={{ __html: summarize.output.html }}
              />
            )}
          </TabItems>
        </div>
      </div>
      <div className="mt-6 mb-6 btn-generate">
        <ReactTooltip
          id={'paragraph-tab'}
          type="dark"
          effect="float"
          border={false}
        />
        <Tip
          data-for="paragraph-tab"
          text={
            minCharacter > highLightWords.length ||
            highLightWords.length > maxCharacter
              ? 'Highlight 30-4000 characters.'
              : ''
          }
        >
          <button
            className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700
          hover:bg-purple-500 focus:summarize-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            onClick={generateSummarize}
            disabled={
              minCharacter > highLightWords.length ||
              highLightWords.length > maxCharacter ||
              summarizeLoading
            }
          >
            {summarizeLoading && (
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
            )}
            {summarize.length > 0 ? 'Generate more' : 'Generate'}
          </button>
        </Tip>
      </div>
    </div>
  );
}

export default SummarizeTab;
