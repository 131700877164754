import { useDispatch, useSelector } from 'react-redux';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import './ComponentTip.scss';
import ReactTooltip from 'react-tooltip';
import { isMobile } from '../utils/common';
import React, { useEffect } from 'react';
import NovaOnBoardingGuide from './nova-onboarding-guide/NovaOnBoardingGuide';
import { loadNovaOnBoardingData } from '../store/actions/nova_onboarding.action';

export default function PostBuilderWrapper({
  navigationBarHandle,
  children,
  navigation,
}) {
  const isPanelCollapsed = useSelector(state => state.panel.isPanelCollapsed);
  const isSmScreenWidth = window.screen.width <= 640;
  const isMobileAndSmScreen = isMobile.any() && isSmScreenWidth;
  const showSidebar =
    !isMobileAndSmScreen || (isMobileAndSmScreen && isPanelCollapsed);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNovaOnBoardingData());
  }, []);

  return (
    <>
      <div className="h-full flex">
        <NovaOnBoardingGuide />
        <ReactTooltip
          place={'right'}
          class="tooltip-for-templates custom-tooltip-for-templates"
          type="light"
          effect="solid"
          html={true}
          border={false}
        />
        {/* Static sidebar for desktop */}
        {showSidebar && (
          <div className="h-full flex flex-shrink w-14 md:w-20">
            <div className="flex flex-col w-20">
              <div className="flex-1 flex flex-col min-h-0 overflow-y-auto bg-gray-800">
                <div className="flex-1">
                  <div className="bg-gray-900 py-4 flex items-center justify-center cursor-pointer">
                    <img
                      className="h-6 md:h-8 w-auto"
                      src={logoIconPurple}
                      alt="Bramework"
                      onClick={() => {
                        navigationBarHandle('exit');
                      }}
                    />
                  </div>
                  {navigation}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Content div */}
          {children}
        </div>
      </div>
    </>
  );
}
