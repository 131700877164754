import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../components/form/FormSelect';
import { track } from '../../services/track';
import {
  exportWpPost,
  getPlatformUser,
  loadWpLists,
  removeFormStatuses,
} from '../../store/actions/platform.actions';

const PlatformModal = ({ openModal, closeModal, post_id }) => {
  const platform = useSelector(state => state.platform);
  const platformListUser = useSelector(
    state => state.platform.platformListUser
  );
  const wpLists = useSelector(state => state.platform.wpLists);
  const [platformName, setPlatformName] = useState('');
  const [author, setAuthor] = useState(null);
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState({});
  const dispatch = useDispatch();
  const alert = useAlert();
  const statuses = ['publish', 'draft'];

  const publishPost = () => {
    const data = {
      post_id: post_id,
      gateway_id: platformName,
      post_status: status,
      author: author,
      category: category,
    };
    dispatch(exportWpPost(data));
  };

  useEffect(() => {
    !platformListUser.length && dispatch(getPlatformUser());
    return () => dispatch(removeFormStatuses());
  }, []);

  useEffect(() => {
    if (platform.formErrors.message) {
      closeModal();
      alert.error(platform.formErrors.message);
      track('Blog post creator', {
        Published: false,
        'Error Message': platform.formErrors.message,
      });
    }
    if (platform.fail && !platform.formErrors.message) {
      closeModal();
      alert.error('Error');
      track('Blog post creator', {
        Published: false,
        'Error Message': 'Error',
      });
    }
    if (platform.success) {
      closeModal(platform.success);
      alert.success(`Post published successful!`);
      track('Blog post creator', { Published: true });
    }
  }, [platform.formErrors, platform.fail, platform.success]);

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => closeModal()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <h4 className="mb-7 text-lg font-bold">Select a Website</h4>
              <Select
                className="settings-select mb-3"
                label="Website"
                menuPortalTarget={document.body}
                options={platformListUser.map(item => ({
                  label: `WordPress: ${item.url}`,
                  value: item.id,
                }))}
                onChange={v => setPlatformName(v.value)}
              />
              {!platform.selectPlatformSecondStep ? (
                <div>
                  {platformListUser.length > 0 ? (
                    ''
                  ) : (
                    <p className="mb-7">
                      You have not connected any websites.
                      <br />
                      To connect, click{' '}
                      <a
                        href="https://www.bramework.com/bramework-wordpress-plugin/"
                        className="text-purple-500 hover:text-purple-700 link-about cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here to install our WordPress plugin.
                      </a>
                    </p>
                  )}
                </div>
              ) : (
                <div className="form-wordpress">
                  <Select
                    className="settings-select mb-3"
                    label="Author"
                    menuPortalTarget={document.body}
                    options={wpLists.authors.map(item => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={v => setAuthor(v.value)}
                  />
                  <Select
                    className="settings-select mb-3"
                    label="Category"
                    menuPortalTarget={document.body}
                    options={wpLists.categories.map(item => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={v => setCategory(v.value)}
                  />
                  <Select
                    className="settings-select mb-3"
                    label="Publish status"
                    menuPortalTarget={document.body}
                    options={statuses.map(item => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={v => setStatus(v.value)}
                  />
                </div>
              )}
              <div className="btn-wrapper">
                <button
                  className="ml-auto flex items-center cursor-pointer px-6 py-3 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green"
                  id="submit"
                  onClick={e => {
                    if (!platform.selectPlatformSecondStep)
                      return dispatch(loadWpLists(platformName));
                    if (platform.submitting || platform.fail)
                      return e.preventDefault();
                    publishPost();
                  }}
                >
                  {platform.submitting && (
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                    </svg>
                  )}
                  Publish
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PlatformModal;
