const chatActionTye = {
  SET_CHAT_MESSAGE: 'SET_CHAT_MESSAGE',
  SET_FAVORITE_PROMPT: 'SET_FAVORITE_PROMPT',
  SET_CONVERSATIONS: 'SET_CONVERSATIONS',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_PANEL_MODE: 'SET_PANEL_MODE',
  SET_ACTIVE_CHAT_ID: 'SET_ACTIVE_CHAT_ID',
  SET_CONNECT_TO_THE_WEB: 'SET_CONNECT_TO_THE_WEB',
  SET_LOOK_IN_THE_EDITOR: 'SET_LOOK_IN_THE_EDITOR',
  SET_LOADING: 'SET_LOADING',
  SET_SCROLL_BOTTOM_BUTTON: 'SET_SCROLL_BOTTOM_BUTTON',
};

const initialState = {
  chatMessage: '',
  messages: [],
  loading: false,
  favoritePrompts: [],
  conversations: [],
  isPanelMode: false,
  activeChatId: '',
  connectToTheWeb: false,
  lookInTheEditor: false,
  showScrollBottomButton: false,
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case chatActionTye.SET_CHAT_MESSAGE:
      return { ...state, chatMessage: action.payload };
    case chatActionTye.SET_MESSAGES:
      return { ...state, messages: action.payload };
    case chatActionTye.SET_FAVORITE_PROMPT:
      return { ...state, favoritePrompts: action.payload };
    case chatActionTye.SET_CONVERSATIONS:
      return { ...state, conversations: action.payload };
    case chatActionTye.SET_PANEL_MODE:
      return { ...state, isPanelMode: action.payload };
    case chatActionTye.SET_ACTIVE_CHAT_ID:
      return { ...state, activeChatId: action.payload };
    case chatActionTye.SET_CONNECT_TO_THE_WEB:
      return { ...state, connectToTheWeb: action.payload };
    case chatActionTye.SET_LOOK_IN_THE_EDITOR:
      return { ...state, lookInTheEditor: action.payload };
    case chatActionTye.SET_LOADING:
      return { ...state, loading: action.payload };
    case chatActionTye.SET_SCROLL_BOTTOM_BUTTON:
      return { ...state, showScrollBottomButton: action.payload };
    default:
      return state;
  }
};

export default ChatReducer;
export { chatActionTye };
