import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../pages/Chat.scss';
import {
  useDeleteFavoritePrompt,
  useGetChatMessages,
  useSaveFavoritePrompt,
  useUpdateChatMessage,
} from '../../utils/chatApi';
import {
  setActiveChatId,
  setMessages,
  setShowScrollBottomButton,
} from '../../store/actions/chat.actions';
import ChatEmptyState from './ChatEmptyState';
import ChatMessage from './ChatMessage';
import { useParams } from 'react-router-dom';
import LoadingMessage from './LoadingMessage';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';

const ChatMessages = ({ editorRef, handleScrollToBottom, chatId }) => {
  const messages = useSelector(state => state.chat.messages);
  const isPanelMode = useSelector(state => state.chat.isPanelMode);
  const isLoading = useSelector(state => state.chat.loading);

  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const { updateChatMessage } = useUpdateChatMessage();
  const { saveFavoritePrompt } = useSaveFavoritePrompt();
  const { deleteFavoritePrompt } = useDeleteFavoritePrompt();

  const dispatch = useDispatch();

  const loadingMessages = [
    '🔍 Searching the vast universe of knowledge... Hang tight!',
    '🚀 Launching a mission to retrieve the info you seek. Be right back!',
    '🧙‍♂️ Summoning the wisdom of ancient scholars. One moment, please!',
    "🤖 I'm a speedy AI, but even I need a moment to gather the facts. Hold on!",
    "🕵️‍♀️ On a top-secret info hunt. I'll report back with the goods shortly!",
    '🎵 Cue the Jeopardy theme song! Your answer is on its way.',
    '🌟 Fun fact: The average person blinks about 15-20 times per minute...',
    '🦉 Wise old owls are helping me find the answers. Hoot hoot, almost there!',
    "🎉 It's a research party, and you're invited! I'll be back in a flash...",
  ];

  useEffect(() => {
    if (!isLoading) return;

    const interval = setInterval(() => {
      setLoadingMessageIndex(
        prevIndex => (prevIndex + 1) % loadingMessages.length
      );
    }, 30000);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    handleScrollToBottom();
  }, [messages]);

  const handleEditMessage = async (messageId, newText) => {
    await updateChatMessage(chatId, messageId, newText);
    const updatedMessages = messages.map(message =>
      message.id === messageId ? { ...message, content: newText } : message
    );
    dispatch(setMessages(updatedMessages));
  };

  const handleCopyMessage = message => {
    navigator.clipboard.writeText(message.content);
  };

  const handleFavoritePrompt = async (message, isFavorite) => {
    if (isFavorite) {
      await saveFavoritePrompt(chatId, message);
    } else {
      await deleteFavoritePrompt(message.id);
    }
  };

  const handleInsertMessage = message => {
    editorRef?.current?.editor?.insertContent(message);
  };

  return (
    <>
      {messages && messages.length ? (
        messages.map(message => (
          <ChatMessage
            key={message.id}
            chatId={chatId}
            message={message}
            onEdit={handleEditMessage}
            onRate={handleFavoritePrompt}
            onCopy={handleCopyMessage}
            onInsert={handleInsertMessage}
          />
        ))
      ) : (
        <ChatEmptyState isPanelMode={isPanelMode} />
      )}
      {isLoading && (
        <LoadingMessage message={loadingMessages[loadingMessageIndex]} />
      )}
    </>
  );
};

export default React.memo(
  ChatMessages,
  (prevProps, nextProps) => prevProps.messages === nextProps.messages
);
