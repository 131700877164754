import useSentData from '../services/useSentData';
import React, { useState } from 'react';
import { isolateError } from '../utils/api';
import FormInput from '../components/form/FormInput';
import Header from '../components/Header';
import './Settings.scss';
import Select from '../components/form/FormSelect';
import useLoadedData from '../services/useLoadedData';
import { chooseOption, timezonesToOption } from '../services/api';
import { connect } from 'react-redux';
import { updateUser } from '../store/actions/auth.actions';
import Validator, { required } from '../services/Validator';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import Loading from '../components/Loading';
import { useAlert } from 'react-alert';
import REGIONS from '../utils/regions';

function SettingsRaw({ settings, timezones, updateUser }) {
  const request = useSentData();
  const [timeZone, setTimeZone] = useState(
    chooseOption(timezones, settings.timezone)
  );
  const [database, setDatabase] = useState(
    settings?.database ? chooseOption(REGIONS, settings.database) : REGIONS[112]
  );
  const [width, setWidth] = useState(settings.img_width || '');
  const [height, setHeight] = useState(settings.img_height || '');
  const [needShowErrors, showErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const alert = useAlert();

  const validator = Validator({
    timeZone: [required],
    database: [required],
  });
  let validation = validator.validateAll({ timeZone, database });

  const validatorDb = Validator({
    database: [required],
    timeZone: [required],
  });
  let validationDb = validatorDb.validateAll({ database, timeZone });

  function submit(e) {
    e.preventDefault();
    if (validator.isValid()) {
      request
        .send('/api/settings', {
          timezone: (timeZone || {}).value,
          database: (database || {}).value,
          img_width: width === '' ? null : width,
          img_height: height === '' ? null : height,
        })
        .then(res => {
          if (!res.isAxiosError) {
            alert.success('Successfully changed!');
            updateUser({ settings: res });
          } else {
            alert.error(isolateError(res));
            showErrors(true);
            setServerErrors(res.response.data);
          }
        });
    } else {
      showErrors(true);
    }
  }

  function submitDatabase(e) {
    e.preventDefault();
    if (validatorDb.isValid()) {
      request
        .send('/api/settings', {
          timezone: (timeZone || {}).value,
          database: (database || {}).value,
          img_width: width === '' ? null : width,
          img_height: height === '' ? null : height,
        })
        .then(res => {
          if (!res.isAxiosError) {
            alert.success('Successfully changed!');
            updateUser({ settings: res });
          } else {
            alert.error(isolateError(res));
            showErrors(true);
            setServerErrors(res.response.data);
          }
        });
    } else {
      showErrors(true);
    }
  }

  return (
    <>
      <div className="form-card">
        <form onSubmit={submit}>
          {request.loading && <Loading />}
          <h4>Time zone</h4>
          <Select
            id="timeZone"
            className="settings-select"
            options={timezones}
            value={timeZone}
            onChange={v => {
              if (serverErrors.timezone) {
                let newServerErrors = { ...serverErrors };
                delete newServerErrors.timezone;
                setServerErrors(newServerErrors);
              }
              setTimeZone(v);
            }}
            isValid={
              needShowErrors && (!validation.timeZone || serverErrors.timezone)
            }
          />
          <div className="button-wrapper">
            <button
              className="mt-4 w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              id="submit"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div className="form-card">
        <form onSubmit={submitDatabase}>
          {request.loading && <Loading />}
          <h4>Database region</h4>
          <Select
            id="database"
            className="settings-select"
            options={REGIONS}
            value={database}
            onChange={v => {
              if (serverErrors.database) {
                let newServerErrors = { ...serverErrors };
                delete newServerErrors.database;
                setServerErrors(newServerErrors);
              }
              setDatabase(v);
            }}
            isValid={
              needShowErrors &&
              (!validationDb.database || serverErrors.database)
            }
          />
          <div className="button-wrapper">
            <button
              className="mt-4 w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              id="submitForm"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {/* <div className="form-card">
        <form onSubmit={submit}>
          {request.loading && <Loading />}
          <h4>Featured image size</h4>
          <div className="subtitle">
            Your blog site featured image default size. Check your website for
            these dimensions.
            <br />
            If no dimension is set we will set the default to 1280px by 960px.
          </div>
          <div className="input-wrapper">
            <FormInput
              id="width"
              type="number"
              className="settings-input"
              label="Width"
              value={width}
              onChange={v => {
                if (serverErrors.img_width) {
                  let newServerErrors = { ...serverErrors };
                  delete newServerErrors.img_width;
                  setServerErrors(newServerErrors);
                }
                setWidth(v);
              }}
            />
            <FormInput
              id="height"
              type="number"
              className="settings-input"
              label="Height"
              value={height}
              onChange={v => {
                if (serverErrors.img_height) {
                  let newServerErrors = { ...serverErrors };
                  delete newServerErrors.img_height;
                  setServerErrors(newServerErrors);
                }
                setHeight(v);
              }}
            />
          </div>
          <div className="button-wrapper">
            <button className="btn btn-purple" id="submit">
              SAVE
            </button>
          </div>
        </form>
      </div> */}
    </>
  );
}

function mapStateToProps(state) {
  const user = state.auth.user || {};
  return { settings: user.settings || {} };
}

const SettingsForm = connect(mapStateToProps, { updateUser })(SettingsRaw);

export default function Settings() {
  const lists = useLoadedData('/api/lists');

  let inner;
  if (lists.loading) {
    inner = <Loading />;
  } else if (lists.error) {
    inner = <div className="message error">{isolateError(lists.error)}</div>;
  } else if (lists.data) {
    const timezones = timezonesToOption(lists.data.timezones);
    inner = <SettingsForm timezones={timezones} />;
  }

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="Settings" />}
    >
      <div className="settings-wrapper mt-8">
        <div className="cards-wrapper">
          {inner}
          {/* <IntegrationsPlatform /> */}
        </div>
      </div>
    </NewWrapper>
  );
}
