import { PlatformTypes } from '../reducers/platform.reducer';
import http from '../../services/http';

const submit = () => ({
  type: PlatformTypes.SUBMITTING_ADD_PLATFORM,
});

const fail = payload => ({
  type: PlatformTypes.FAIL_ADD_PLATFORM,
  payload,
});

export const getPlatformList = () => {
  const success = payload => ({
    type: PlatformTypes.PLATFORM_LIST,
    payload,
  });
  return dispatch => {
    http
      .get('/api/platforms', null)
      .then(tokenRes => dispatch(success(tokenRes.data)));
  };
};

export const getPlatformUser = (options = '') => {
  const success = payload => ({
    type: PlatformTypes.PLATFORM_USER,
    payload,
  });
  return dispatch => {
    http
      .get(`/api/gateways${options}`, null)
      .then(tokenRes => dispatch(success(tokenRes.data)));
  };
};

export const deletePlatformUser = id => {
  const success = () => ({
    type: PlatformTypes.PLATFORM_USER,
  });
  return dispatch => {
    http.delete(`/api/gateways/${id}`, null).then(() => dispatch(success()));
  };
};

export const addPlatformUser = (data, method, id) => {
  const success = payload => ({
    type: PlatformTypes.SUCCESS_ADD_PLATFORM,
    payload,
  });
  return dispatch => {
    if (method === 'put') {
      dispatch(submit());
      return http
        .put(`/api/gateways/${id}`, data)
        .then(tokenRes => {
          dispatch(success(tokenRes.data));
          dispatch(getPlatformUser());
        })
        .catch(error => {
          if (error.response && error.response.data)
            dispatch(fail(error.response.data));
        });
    }
    if (method === 'delete') {
      return http
        .delete(`/api/gateways/${id}`, null)
        .then(() => dispatch(getPlatformUser()));
    }
    dispatch(submit());
    http
      .post('/api/gateways', data)
      .then(tokenRes => dispatch(success(tokenRes.data)))
      .catch(error => {
        if (error.response && error.response.data)
          dispatch(fail(error.response.data));
      });
  };
};

export const removeFormStatuses = () => ({
  type: PlatformTypes.REMOVE_FORM_STATUS,
});

export const updatePlatform = (platform, data) => ({
  type: PlatformTypes.UPDATE_PLATFORM,
  data,
  platform,
});

export const publishHistoryRequest = (gateway_id = '') => {
  const success = payload => ({
    type: PlatformTypes.PUBLISH_HISTORY,
    payload,
  });
  return dispatch => {
    http
      .get(
        `/api/publish-history${gateway_id ? `?gateway_id=${gateway_id}` : ''}`,
        null
      )
      .then(tokenRes => dispatch(success(tokenRes.data)));
  };
};

export const publishHistory = payload => ({
  type: PlatformTypes.FILTER_PUBLISH_HISTORY,
  payload,
});

export const loadWpLists = id => {
  const success = payload => ({
    type: PlatformTypes.LOAD_WP_LISTS,
    payload,
  });
  return dispatch => {
    dispatch(submit());
    http
      .get(`/api/load-wp-lists/${id}`, null)
      .then(res => dispatch(success(res.data)))
      .catch(error => dispatch(fail()));
  };
};

export const exportWpPost = data => {
  const success = () => ({
    type: PlatformTypes.PUBLISH_SUCCESS,
  });
  return dispatch => {
    dispatch(submit());
    http
      .post(`/api/export-wp-post`, data)
      .then(() => dispatch(success()))
      .catch(error => {
        if (error.response && error.response.data)
          dispatch(fail(error.response.data));
      });
  };
};
