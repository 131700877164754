import * as moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DocumentDuplicateIcon,
  PlusSmIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import useSentData from '../../services/useSentData';

export default function TabItems({
  dataList = [],
  children,
  onClickAdd = () => {},
  onClickLike = () => {},
  onClickDelete = () => {},
  onClickCopy = () => {},
  onClickDislike = () => {},
}) {
  const request = useSentData();

  return (
    <ul role="list" className="flex-1 divide-y divide-gray-200">
      {dataList &&
        dataList.map((data, i) => (
          <li key={data.generation_id} className="py-4 list-item">
            <div className="flex justify-between items-center">
              <span className="text-xs text-gray-400 mb-3">
                {moment
                  .duration(
                    moment(data.created_at).diff(moment(), 'seconds'),
                    'seconds'
                  )
                  .humanize(true)}
              </span>
              <div className="flex items-center mb-3">
                <div>
                  <Tooltip title="Insert in editor" arrow>
                    <button
                      disabled={request.loading}
                      onClick={() => onClickAdd(data)}
                      className="inline-flex items-center mr-3 p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <PlusSmIcon
                        className="h-4 w-4 text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Like" arrow>
                    <button
                      disabled={request.loading}
                      onClick={() => onClickLike(data)}
                      className="inline-flex mr-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                    >
                      <ThumbUpIcon
                        className={`h-4 w-4 hover:text-green-500 ${
                          data.like_status && data.like_status[0]?.status === 1
                            ? 'text-green-500'
                            : 'text-gray-500'
                        }`}
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="DisLike" arrow>
                    <button
                      disabled={request.loading}
                      onClick={() => onClickDislike(data)}
                      className="inline-flex mr-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                    >
                      <ThumbDownIcon
                        className={`h-4 w-4 hover:text-red-500 ${
                          data.like_status && data.like_status[0]?.status === 2
                            ? 'text-red-500'
                            : 'text-gray-500'
                        }`}
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Copy" arrow>
                    <button
                      onClick={() => onClickCopy(data)}
                      className="inline-flex mr-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                    >
                      <DocumentDuplicateIcon
                        className={`h-4 w-4 text-gray-500`}
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Delete" arrow>
                    <button
                      disabled={request.loading}
                      onClick={() => onClickDelete(data, i)}
                      className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <TrashIcon
                        className="h-4 w-4 text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={`mt-2 flex  ${data.isNewItem ? 'rainbow' : ''}`}>
              {children(data)}
            </div>
          </li>
        ))}
    </ul>
  );
}
