import React from 'react';
import TitlePreview from './TitlePreview';
import HeroImagePreview from './HeroImagePreview';
import IntroductionPreview from './IntroductionPreview';
import BonusPreview from './BonusPreview';
import ImagePreview from './ImagePreview';
import HowToPreview from './HowToPreview';
import ListPreview from './ListPreview';
import WhatPreview from './WhatPreview';
import WhyPreview from './WhyPreview';
import FAQPreview from './FAQPreview';
import ConclusionPreview from './ConclusionPreview';
import ParagraphPreview from './ParagraphPreview';
import Heading2Preview from './Heading2Preview';
import Heading3Preview from './Heading3Preview';
import Heading4Preview from './Heading4Preview';
import BodyPreview from './BodyPreview';

export default function Block({ block }) {
  let inner;
  if (block.type === 'Title') {
    return <TitlePreview state={block.state} />;
  } else if (block.type === 'HeroImage') {
    inner = <HeroImagePreview state={block.state} />;
  } else if (block.type === 'Introduction') {
    inner = <IntroductionPreview state={block.state} />;
  } else if (block.type === 'Bonus') {
    inner = <BonusPreview state={block.state} />;
  } else if (block.type === 'Image') {
    inner = <ImagePreview state={block.state} />;
  } else if (block.type === 'HowTo') {
    inner = <HowToPreview state={block.state} />;
  } else if (block.type === 'List') {
    inner = <ListPreview state={block.state} />;
  } else if (block.type === 'What') {
    inner = <WhatPreview state={block.state} />;
  } else if (block.type === 'Why') {
    inner = <WhyPreview state={block.state} />;
  } else if (block.type === 'FAQ') {
    inner = <FAQPreview state={block.state} />;
  } else if (block.type === 'Conclusion') {
    inner = <ConclusionPreview state={block.state} />;
  } else if (block.type === 'Paragraph') {
    inner = <ParagraphPreview state={block.state} />;
  } else if (block.type === 'Heading2') {
    inner = <Heading2Preview state={block.state} />;
  } else if (block.type === 'Heading3') {
    inner = <Heading3Preview state={block.state} />;
  } else if (block.type === 'Heading4') {
    inner = <Heading4Preview state={block.state} />;
  } else if (block.type === 'Body') {
    inner = <BodyPreview state={block.state} />;
  }

  return <>{inner}</>;
}
