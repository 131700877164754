const plagiarismPanelActionTypes = {
  SHOW_PLAGIARISM_PANEL: 'SHOW_PLAGIARISM_PANEL',
  HIDE_PLAGIARISM_PANEL: 'HIDE_PLAGIARISM_PANEL',
};

const initialState = {
  isPlagiarismPanelCollapsed: false,
};

const plagiarismPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case plagiarismPanelActionTypes.SHOW_PLAGIARISM_PANEL:
      return {
        isPlagiarismPanelCollapsed: false,
      };
    case plagiarismPanelActionTypes.HIDE_PLAGIARISM_PANEL:
      return {
        isPlagiarismPanelCollapsed: true,
      };
    default:
      return state;
  }
};

export default plagiarismPanelReducer;
export { plagiarismPanelActionTypes };
