import useSentData from '../services/useSentData';
import React, { useState } from 'react';
import { isolateError } from '../utils/api';
import FormInput from '../components/form/FormInput';
import './Profile.scss';
import Select from '../components/form/FormSelect';
import defaultAvatar from '../styles/images/default-avatar.svg';
import useLoadedData from '../services/useLoadedData';
import { chooseOption, countryToOption, listToOption } from '../services/api';
import { connect } from 'react-redux';
import { updateUser } from '../store/actions/auth.actions';
import Validator, { required } from '../services/Validator';
import Dropzone from 'react-dropzone';
import NewEmailChangeModalView from './Profile/NewEmailChangeModalView';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import { onProfessionChange } from '../services/track';
import Loading from '../components/Loading';
import { useAlert } from 'react-alert';
import CompanyInformation from './Profile/CompanyInformation';

function ProfileForm({
  id,
  profile,
  countries,
  states,
  professions,
  updateUser,
}) {
  const request = useSentData();
  const [isModalOpened, openModal] = useState(false);
  const [full_name, setFullName] = useState(profile.full_name || '');
  const [city, setCity] = useState(profile.city || '');
  const [state, setState] = useState(chooseOption(states, profile.state));
  const [country, setCountry] = useState(
    chooseOption(countries, profile.country)
  );
  const [profession, setProfession] = useState(
    chooseOption(professions, profile.profession)
  );
  const [file, setFile] = useState(null);

  const [needShowErrors, showErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const alert = useAlert();

  const preview = profile.preview || defaultAvatar;
  const validator = Validator({
    full_name: [required],
    profession: [required],
  });
  let validation = validator.validateAll({ full_name, profession });

  const isUS = country && country.value === 'US';

  function save(avatarData) {
    const processedName = full_name === '' ? null : full_name;
    const processedCity = city === '' ? null : city;

    const data = {
      full_name: processedName,
      city: processedCity,
      country: (country || {}).value,
      state: isUS ? (state || {}).value : null,
      profession: (profession || {}).value,
    };

    if (avatarData) {
      data.avatar = avatarData.id;
    }

    request.send(`/api/users/${id}`, data, 'put').then(res => {
      if (!res.isAxiosError) {
        const updateData = {
          full_name: res.full_name,
          city: res.city,
          state: res.state,
          country: res.country,
          profession: res.profession,
          preview: res.preview,
          avatar: res.avatar,
        };

        updateUser(updateData);
        const userProfession = professions.find(
          p => p.value === profession.value
        );
        onProfessionChange({ id: res.id, profession: userProfession.label });
        setFile(null);
        alert.success('Successfully changed!');
      } else {
        alert.error(isolateError(res));
        showErrors(true);
        setServerErrors(res.response.data);
      }
    });
  }

  function submit(e) {
    e.preventDefault();
    if (validator.isValid()) {
      if (file) {
        const data = new FormData();
        data.append('file', file);
        data.append('type', 'avatar');
        request.send('/api/files', data, 'POST').then(res => {
          if (!res.isAxiosError) {
            save(res);
          } else {
            showErrors(true);
            setServerErrors(res.response.data);
            alert.error(isolateError(res));
          }
        });
      } else {
        save();
      }
    } else {
      showErrors(true);
    }
  }

  return (
    <>
      <NewEmailChangeModalView
        id={id}
        opened={isModalOpened}
        close={() => openModal(false)}
      />
      <form onSubmit={submit}>
        {request.loading && <Loading />}
        <div className="form-inner">
          <div className="avatar-wrapper">
            <Dropzone
              multiple={false}
              accept="image/*"
              onDrop={file => {
                setFile(file[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img
                      src={file ? URL.createObjectURL(file) : preview}
                      alt=""
                      className="avatar cursor-pointer"
                    />
                  </div>
                  {file ? (
                    <div className="control-wrapper">
                      <button
                        className="text-xs text-red-600 mt-1"
                        onClick={e => {
                          e.preventDefault();
                          setFile(null);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="avatar-label">Update Avatar</div>
                  )}
                </>
              )}
            </Dropzone>
          </div>
          <div className="inputs-wrapper">
            <FormInput
              id="full_name"
              className="profile-input"
              label="Name *"
              value={full_name}
              onChange={v => {
                if (serverErrors.full_name) {
                  let newServerErrors = { ...serverErrors };
                  delete newServerErrors.full_name;
                  setServerErrors(newServerErrors);
                }
                return setFullName(v);
              }}
              isValid={
                needShowErrors &&
                (!validation.full_name || serverErrors.full_name)
              }
            />
            <div className="email-change-wrapper">
              <div className="email-change-label">Email *</div>
              <div
                className="email-change-element"
                onClick={() => {
                  openModal(true);
                }}
              >
                {profile.email}
              </div>
            </div>
            <Select
              id="profession"
              label="Profession *"
              className="profile-select"
              options={professions}
              value={profession}
              onChange={v => {
                if (serverErrors.profession) {
                  let newServerErrors = { ...serverErrors };
                  delete newServerErrors.profession;
                  setServerErrors(newServerErrors);
                }
                setProfession(v);
              }}
              isValid={
                needShowErrors &&
                (!validation.profession || serverErrors.profession)
              }
            />
            <div className="country-wrapper">
              <Select
                id="country"
                label="Country"
                className="profile-select country-select"
                options={countries}
                value={country}
                onChange={v => {
                  if (serverErrors.country) {
                    let newServerErrors = { ...serverErrors };
                    delete newServerErrors.country;
                    setServerErrors(newServerErrors);
                  }
                  setCountry(v);
                }}
              />
              <Select
                id="state"
                label="State"
                className="profile-select"
                options={states}
                value={isUS ? state : null}
                onChange={v => setState(v)}
                disabled={!isUS}
              />
            </div>
            <FormInput
              id="city"
              className="profile-input"
              label="City"
              value={city}
              onChange={v => setCity(v)}
            />
          </div>
        </div>
        <div className="button-wrapper">
          <button
            id="submit"
            className="w-full inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

function Profile({ user, updateUser }) {
  const lists = useLoadedData('/api/lists');

  let inner;
  if (lists.loading) {
    inner = <Loading />;
  } else if (lists.error) {
    inner = <div className="message error">{isolateError(lists.error)}</div>;
  } else if (lists.data) {
    const states = countryToOption(lists.data.state_list);
    const countries = countryToOption(lists.data.country_list);
    const professions = listToOption(lists.data.profession_list);
    inner = (
      <ProfileForm
        id={user.id}
        profile={user}
        states={states}
        countries={countries}
        professions={professions}
        updateUser={updateUser}
      />
    );
  }

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="My Profile" />}
    >
      <div className="profile-wrapper mt-8">
        <div className="cards-wrapper">
          <div className="form-card">{inner}</div>
          <div className="form-card">
            <CompanyInformation />
          </div>
        </div>
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user || {} };
}

export default connect(mapStateToProps, { updateUser })(Profile);
