import React from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@material-ui/core';

import { setUseBrandVoice } from '../../store/actions/post_builder.actions';

const BrandVoiceSwitch = () => {
  const dispatch = useDispatch();
  const { project_id } = useParams();
  const useBrandVoice = useSelector(state => state.post.useBrandVoice);
  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;

  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;
  const effectiveUseBrandVoice = isDisabled ? false : useBrandVoice;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values.";

  const descriptionText = isUncategorizedProject
    ? 'Create a project folder to use the brand voice feature.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings.'
    : 'Apply your brand voice to generated content.';

  return (
    <div className="px-5 mb-8">
      <Switch.Group as="div" className="flex items-center justify-between">
        <span className="flex-grow flex flex-col">
          <Switch.Label
            as="span"
            className="font-semibold text-gray-900 flex items-center"
            passive
          >
            Use Brand Voice
            <Tooltip title={tooltipText}>
              <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-500" />
            </Tooltip>
          </Switch.Label>
          <Switch.Description as="span" className="text-xs text-gray-500">
            {descriptionText}
          </Switch.Description>
        </span>{' '}
        <Switch
          checked={effectiveUseBrandVoice}
          onChange={value => {
            if (!isDisabled) {
              dispatch(setUseBrandVoice(value));
            }
          }}
          disabled={isDisabled}
          className={cx(
            'group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            isDisabled && 'opacity-50 cursor-not-allowed'
          )}
        >
          <span
            aria-hidden="true"
            className={cx(
              effectiveUseBrandVoice ? 'bg-purple-600' : 'bg-gray-200',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
            )}
          />
          <span
            aria-hidden="true"
            className={cx(
              effectiveUseBrandVoice ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default BrandVoiceSwitch;
