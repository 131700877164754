import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusSmIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/outline';
import Tooltip from '@material-ui/core/Tooltip';
import { useAlert } from 'react-alert';
import FeaturedImage from './FeaturedImage';
import { getNewSeoProgress } from '../utils/PostBuilderUtils';
import { Switch } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSeoAnalyzer } from '../store/actions/post_builder.actions';

export default function SeoTab({
  data,
  phrase,
  blocks,
  updateFeaturedImage,
  editorRef,
}) {
  const [openSeoAnalyzer, setOpenSeoAnalyzer] = useState(true);
  const [openFeaturedImage, setOpenFeaturedImage] = useState(true);
  const [openSources, setOpenSources] = useState(true);
  const [openFAQs, setOpenFAQs] = useState(true);
  const [openResearchReport, setOpenResearchReport] = useState(true);
  const [openTitleSuggestions, setOpenTitleSuggestions] = useState(true);
  const [
    openImageAltTextSuggestions,
    setOpenImageAltTextSuggestions,
  ] = useState(true);
  const [
    openMetaDescriptionSuggestions,
    setOpenMetaDescriptionSuggestions,
  ] = useState(true);
  const [seoData, setSeoData] = useState({
    image_alt_text_attr: 0,
    internal_links: 0,
    keyphrase_in_introduction: 0,
    keyphrase_in_subhead: 0,
    keyword_density: 0,
    keyphrase_in_title: 0,
    external_links: 0,
    seo_title_width: 0,
    word_total: 0,
  });
  const seoAnalyzer = useSelector(state => state.post.seoAnalyzer);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (data !== undefined) setSeoData(data);
  });

  function getColorDot(data) {
    switch (data) {
      case 0:
        return 'bg-gray-300';
      case 1:
        return 'bg-green-500';
      case 2:
        return 'bg-yellow-500';
      case 3:
        return 'bg-red-600';
      default:
        return 'bg-gray-300';
    }
  }

  function onToggleSeoAnalyzer() {
    dispatch(toggleSeoAnalyzer(seoAnalyzer));
  }

  const { seoProgress, seoColor } = getNewSeoProgress(data);

  const analysis = [
    {
      name: 'Keyword in title',
      bgColorClass: getColorDot(seoData.keyphrase_in_title),
      description:
        'Use the primary keyword in your title to make sure your post gets indexed by Google so it shows up when people search for it.',
    },
    {
      name: 'Keyword in introduction',
      bgColorClass: getColorDot(seoData.keyphrase_in_introduction),
      description:
        "It's best to have your primary keyword in the first sentence of your article, or at least in the first paragraph. This will increase the chance of your post being found when people search for it.",
    },
    {
      name: 'Keyword in headings',
      bgColorClass: getColorDot(seoData.keyphrase_in_subhead),
      description: 'Use your primary keyword in 40-75% of subheadings.',
    },
    {
      name: 'Keyword density',
      bgColorClass: getColorDot(seoData.keyword_density),
      description:
        'The number of times your primary keyword occurs in your post, compared to the total number of words in that post.',
    },
    {
      name: 'Title length',
      bgColorClass: getColorDot(seoData.seo_title_width),
      description:
        'To avoid cut off, your titles should be between 55 and 70 characters or less than 40 characters.',
    },
    {
      name: 'Image alt text',
      bgColorClass: getColorDot(seoData.image_alt_text_attr),
      description:
        'Describes the image and the function of the image in the post.',
    },
    {
      name: 'Internal links',
      bgColorClass: getColorDot(seoData.internal_links),
      description:
        'Add a minimum of 3 internal links to relevant blog posts on your site. Input your website URL in your project folder; otherwise, the URL in your account profile will be used.',
    },
    {
      name: 'Outbound links',
      bgColorClass: getColorDot(seoData.external_links),
      description:
        "Include at least 3 outbound links to relevant blog posts on your site. Add your website URL to your project folder; otherwise, your account profile's URL will be used to identify outbound links.",
    },
  ];

  const onClickCopy = text => () => {
    navigator.clipboard.writeText(text);
    alert.success('Successfully copied to clipboard!');
  };

  const CopyButton = ({ text }) => (
    <Tooltip title="Copy" arrow>
      <button
        onClick={onClickCopy(text)}
        className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
      >
        <ClipboardCopyIcon
          className="h-4 w-4 text-gray-700"
          aria-hidden="true"
        />
      </button>
    </Tooltip>
  );

  return (
    <div className="overflow-y-auto">
      <div className="flow-root mt-8">
        <div className="w-80 sm:w-128">
          <div className="mx-5 flex shadow-sm rounded-md mb-4">
            <div
              className={classNames(
                seoColor,
                'flex-shrink-0 flex items-center justify-center w-16 text-white font-medium rounded-l-md'
              )}
            >
              {seoProgress}
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-semibold">SEO Score</p>
                <p className="text-gray-500">See analyzer for tips...</p>
              </div>
              <div className="mr-3">
                <Switch
                  checked={seoAnalyzer}
                  onChange={onToggleSeoAnalyzer}
                  className={classNames(
                    seoAnalyzer ? 'bg-purple-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      seoAnalyzer ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
          {openSeoAnalyzer ? (
            <div className="mb-4">
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenSeoAnalyzer(false)}
              >
                SEO Analyzer
                <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
              </div>
              <ul role="list" className="flex-1 divide-y divide-gray-200">
                {analysis.map((data, i) => (
                  <li key={i}>
                    <div className="items-center justify-between px-4 py-4 sm:px-6">
                      <span className="flex items-center space-x-3">
                        <span
                          className={classNames(
                            data.bgColorClass,
                            'w-2.5 h-2.5 flex-shrink-0 rounded-full'
                          )}
                          aria-hidden="true"
                        />
                        {/* <span className="font-medium truncate text-sm leading-6">
                          {data.name}
                        </span> */}
                        <div className="flex-1 text-sm leading-6">
                          <p className="text-gray-900 font-medium">
                            {data.name}
                          </p>
                          <p className="text-gray-500">{data.description}</p>
                        </div>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenSeoAnalyzer(true)}
            >
              SEO Analyzer
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
          {openFeaturedImage ? (
            <div className="mb-4">
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenFeaturedImage(false)}
              >
                Featured Image
                <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
              </div>
              <FeaturedImage
                file={blocks[1]?.state?.file}
                phrase={phrase}
                updateFeaturedImage={updateFeaturedImage}
              />
            </div>
          ) : (
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenFeaturedImage(true)}
            >
              Featured Image
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
          {blocks[2]?.state?.faqs &&
            blocks[2]?.state?.faqs.length > 0 &&
            (openFAQs ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenFAQs(false)}
                >
                  FAQs
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <ul role="list" className="flex-1 divide-y divide-gray-200">
                  {blocks[2]?.state?.faqs.map((faq, i) => (
                    <li key={i} className="py-4 mr-5">
                      <div className="flex items-center space-x-4">
                        <div className="min-w-0 text-sm">
                          <div className="flex">
                            <p className="mx-5 w-80 sm:w-100 text-gray-900">
                              {faq.question}
                            </p>
                            <div className="flex items-center gap-4">
                              <Tooltip title="Insert in editor" arrow>
                                <button
                                  onClick={() => {
                                    if (editorRef?.current?.editor) {
                                      editorRef?.current?.editor?.insertContent(
                                        faq.question + '<br><br>' + faq.answer
                                      );
                                    }
                                  }}
                                  className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                  <PlusSmIcon
                                    className="h-4 w-4 text-gray-700"
                                    aria-hidden="true"
                                  />
                                </button>
                              </Tooltip>
                              <CopyButton
                                text={faq.question + ' ' + faq.answer}
                              />
                            </div>
                          </div>
                          <p className="mx-5 w-80 sm:w-100 font-medium text-gray-500 pt-1">
                            {faq.answer}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenFAQs(true)}
              >
                FAQs
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))}
          {blocks[2]?.state?.sources &&
            blocks[2]?.state?.sources.length > 0 &&
            (openSources ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenSources(false)}
                >
                  Sources
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <ul role="list" className="flex-1 divide-y divide-gray-200">
                  {blocks[2]?.state?.sources.map((source, i) => (
                    <li key={i} className="py-4">
                      <p className="mx-5 w-80 sm:w-112 text-sm font-medium text-gray-900 overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {i + 1}.
                        <a
                          href={source}
                          target="_blank"
                          className="text-purple-500 hover:text-purple-700 mx-2 text-sm w-40 font-medium"
                          rel="noopener noreferrer"
                        >
                          {source}
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenSources(true)}
              >
                Sources
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))}
          {blocks[2]?.state?.title_suggestions &&
            blocks[2]?.state?.title_suggestions.length > 0 &&
            (openTitleSuggestions ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenTitleSuggestions(false)}
                >
                  Title Suggestions
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <ul role="list" className="flex-1 divide-y divide-gray-200">
                  {blocks[2]?.state?.title_suggestions.map((title, i) => (
                    <li key={i} className="py-4 mr-5">
                      <div className="flex items-center space-x-4">
                        <div className="flex min-w-0">
                          <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900">
                            {i + 1}. {title}
                          </p>
                        </div>
                        <div className="flex items-center gap-4">
                          <Tooltip title="Insert in editor" arrow>
                            <button
                              onClick={() => {
                                if (editorRef?.current?.editor) {
                                  editorRef?.current?.editor?.insertContent(
                                    title
                                  );
                                }
                              }}
                              className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            >
                              <PlusSmIcon
                                className="h-4 w-4 text-gray-700"
                                aria-hidden="true"
                              />
                            </button>
                          </Tooltip>
                          <CopyButton text={title} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenTitleSuggestions(true)}
              >
                Title Suggestions
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))}
          {blocks[2]?.state?.meta_description_suggestions &&
            blocks[2]?.state?.meta_description_suggestions.length > 0 &&
            (openMetaDescriptionSuggestions ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenMetaDescriptionSuggestions(false)}
                >
                  Meta Description Suggestions
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <ul role="list" className="flex-1 divide-y divide-gray-200">
                  {blocks[2]?.state?.meta_description_suggestions.map(
                    (desc, i) => (
                      <li key={i} className="py-4 mr-5">
                        <div className="flex items-center space-x-4">
                          <div className="flex min-w-0">
                            <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900">
                              {i + 1}. {desc}
                            </p>
                          </div>
                          <div className="flex items-center gap-4">
                            <Tooltip title="Insert in editor" arrow>
                              <button
                                onClick={() => {
                                  if (editorRef?.current?.editor) {
                                    editorRef?.current?.editor?.insertContent(
                                      desc
                                    );
                                  }
                                }}
                                className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                              >
                                <PlusSmIcon
                                  className="h-4 w-4 text-gray-700"
                                  aria-hidden="true"
                                />
                              </button>
                            </Tooltip>
                            <CopyButton text={desc} />
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenMetaDescriptionSuggestions(true)}
              >
                Meta Description Suggestions
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))}
          {blocks[2]?.state?.image_alt_text_suggestions &&
            blocks[2]?.state?.image_alt_text_suggestions.length > 0 &&
            (openImageAltTextSuggestions ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenImageAltTextSuggestions(false)}
                >
                  Image Alt Text Suggestions
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <ul role="list" className="flex-1 divide-y divide-gray-200">
                  {blocks[2]?.state?.image_alt_text_suggestions.map(
                    (suggestion, i) => (
                      <li key={i} className="py-4 mr-5">
                        <div className="flex items-center space-x-4">
                          <div className="min-w-0 text-sm">
                            <div className="flex">
                              <p className="mx-5 w-80 sm:w-100 text-gray-900">
                                {suggestion.section_heading}
                              </p>
                              <div className="flex items-center gap-4">
                                <Tooltip title="Insert in editor" arrow>
                                  <button
                                    onClick={() => {
                                      if (editorRef?.current?.editor) {
                                        editorRef?.current?.editor?.insertContent(
                                          suggestion.image_alt_text
                                        );
                                      }
                                    }}
                                    className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                  >
                                    <PlusSmIcon
                                      className="h-4 w-4 text-gray-700"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Tooltip>
                                <CopyButton text={suggestion.image_alt_text} />
                              </div>
                            </div>
                            <p className="mx-5 w-80 sm:w-100 font-medium text-gray-500 pt-1">
                              {suggestion.image_alt_text}
                            </p>
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenImageAltTextSuggestions(true)}
              >
                Image Alt Text Suggestions
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))}
          {/* {blocks[2]?.state?.research_report &&
            (openResearchReport ? (
              <div className="mb-4">
                <div
                  className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                  role="alert"
                  onClick={() => setOpenResearchReport(false)}
                >
                  Research Report
                  <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <div
                  className="mx-5 w-80 sm:w-100 text-gray-900"
                  dangerouslySetInnerHTML={{
                    __html: blocks[2]?.state?.research_report,
                  }}
                />
              </div>
            ) : (
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenResearchReport(true)}
              >
                Research Report
                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
}
