import React, { useState, useEffect, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSentData from '../services/useSentData';
import { useAlert } from 'react-alert';
import { isolateError } from '../utils/api';
import { track } from '../services/track';
import classNames from 'classnames';
import {
  XIcon,
  CheckIcon,
  ExclamationIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import {
  addPlagiarism,
  getPlagiarisms,
  destroyPlagiarism,
} from '../store/actions/plagiarisms.actions';
import { connect } from 'react-redux';
import { setCheckPlagiarismTriggered } from '../store/actions/post_builder.actions';
import Tip from './ComponentTip';
import ReactTooltip from 'react-tooltip';

const PlagiarismPanel = React.forwardRef((props, ref) => {
  const request = useSentData();
  const alert = useAlert();
  const dispatch = useDispatch();
  let { project_id, id } = useParams();
  const postId = useSelector(state => state.post.id);
  const checkPlagiarismTriggered = useSelector(
    state => state.post.checkPlagiarismTriggered
  );
  const { isPanelCollapsed } = useSelector(state => state.panel);
  const [isPlagiarismFound, setPlagiarismFound] = useState(null);
  const [editorLoadingPlagiarism, setEditorLoadingPlagiarism] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [selectionCharCount, setSelectionCharCount] = useState(0);
  const highLightWords = useSelector(state => state.post.highlightWords);
  const minCharacter = 200;
  const maxCharacter = 4000;

  const history = props.plagiarism_history.length > 0 ? true : false;
  const editor = props.editorRef?.current?.editor;

  let pid = id ?? postId;

  useImperativeHandle(ref, () => ({
    handleSelectionChangeFnc: () => {
      if (
        props.user.has_trial_subscription != null &&
        props.user.has_trial_subscription === true
      ) {
        setActiveBtn(false);
        return;
      }
      const selected_text = props.editorRef?.current?.editor?.selection?.getContent(
        {
          format: 'text',
        }
      );
      setSelectionCharCount(selected_text.length);
      if (!selected_text) {
        setActiveBtn(false);
        return;
      }
      if (selected_text.length < 200 || selected_text.length > 4000) {
        setActiveBtn(false);
      } else {
        setActiveBtn(true);
      }
    },
  }));

  function plagiarismCheck() {
    if (
      props.user.has_trial_subscription != null &&
      props.user.has_trial_subscription === true
    ) {
      alert.error(
        'Plagiarism checker is not available during the trial period.'
      );
      return;
    }
    const selected_text = props.editorRef?.current?.editor?.selection?.getContent(
      {
        format: 'text',
      }
    );
    if (!selected_text) {
      dispatch(setCheckPlagiarismTriggered(false));
      return false;
    }

    if (selected_text.length < 200 || selected_text.length > 4000) {
      alert.error(
        'Highlight between 200 to 4,000 characters. This is approximately 50 to 1,000 words.'
      );
      dispatch(setCheckPlagiarismTriggered(false));
      return false;
    }
    setEditorLoadingPlagiarism(true);
    // setEditorLoading(true);
    request
      .send(
        `/api/projects/${project_id}/posts/${pid}/plagiarism`,
        { selected_text: selected_text },
        'post'
      )
      .then(resp => {
        setEditorLoadingPlagiarism(false);
        // setEditorLoading(false);
        if (!resp.isAxiosError) {
          if (resp) {
            // setBlocks(resp.body);
            props.addPlagiarism(pid, resp.api_response);
            props.getPlagiarisms(pid);
            setPlagiarismFound(resp.plagiarism_found ? true : false);
            track('Blog post creator', {
              Plagiarism: resp.plagiarism_found ? true : false,
            });
            if (resp.plagiarism_found) {
              const body = resp.body.filter(data => data.type === 'Body');
              if (body.length > 0) {
                props.editorRef.current.editor.selection.setContent(
                  resp.body_txt
                );
              }
            }
          }
        } else {
          alert.error(isolateError(resp));
        }
        dispatch(setCheckPlagiarismTriggered(false));
      })
      .catch(err => {
        alert.error(isolateError(err.message));
        dispatch(setCheckPlagiarismTriggered(false));
      });
  }

  useEffect(() => {
    if (pid) {
      props.getPlagiarisms(pid);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (
      !!editor &&
      checkPlagiarismTriggered &&
      !props.isPanelCollapsed &&
      !isCancelled
    ) {
      plagiarismCheck();
    }

    return () => {
      isCancelled = true;
    };
  }, [editor, checkPlagiarismTriggered, props.isPanelCollapsed]);

  function renderList() {
    return (
      <div className="overflow-y-auto">
        <div className="flow-root mt-8">
          <div className="w-88 sm:w-128">
            <div className="mx-5 bg-white font-semibold flex justify-between text-gray-900">
              {history ? 'Results history' : 'Plagiarism results'}
              {history && (
                <button
                  type="button"
                  onClick={() => props.destroyPlagiarism()}
                  className="inline-flex mb-8 items-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Clear
                </button>
              )}
            </div>
            {props.plagiarism_history.length > 0 && (
              <ul role="list" className="flex-1 divide-y divide-gray-200">
                {props.plagiarism_history.map((item, i) => (
                  <li key={i} className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="min-w-0">
                        <p className="mx-5 mb-3 w-80 sm:w-116 text-sm font-medium text-gray-900">
                          {item.title}
                        </p>
                        <p className="mx-5 mb-3 w-80 sm:w-116 text-sm text-gray-500">
                          {item.textmatched}
                        </p>
                        <p className="mx-5 mb-3 w-80 sm:w-116 text-sm text-gray-700">
                          Source:{' '}
                          <a
                            href={item.url}
                            target="_blank"
                            className="text-purple-500 hover:text-purple-700 link-about cursor-pointer"
                            rel="noreferrer"
                          >
                            {item.url}
                          </a>
                        </p>
                        <div className="mx-5 ">
                          <p className="float-left w-2/5 text-sm text-gray-700">
                            Words matched: {item.wordsmatched}
                          </p>
                          <p className="float-right w-2/5 text-sm text-right text-gray-700">
                            Percentage: {item.percentmatched}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {isPlagiarismFound === false && (
              <p className="mx-5 my-5 w-116 text-sm font-medium text-gray-700">
                No plagiarism found
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={isPanelCollapsed ? '' : 'side-bar-open'}>
      <div
        className="fixed w-max h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
        style={{ height: '94vh' }}
      >
        <div className="p-6">
          <div className="flex flex-col">
            <div className="text-sm sm:text-lg font-medium text-gray-900">
              Plagiarism Checker{' '}
              <i className="text-xs text-gray-400 mt-1 mr-2">
                Powered by Copyscape
              </i>
              <p className="text-sm text-gray-700 mt-4 mr-10 sm:mr-0">
                To check for plagiarism, please highlight 200 to 4,000
                characters.
              </p>
              <p className="text-sm text-gray-700 mt-2 mr-8 sm:mr-0">
                This is approximately 50 to 1,000 words.
              </p>
              <p className="text-sm text-gray-700 mt-2 mr-8 sm:mr-0">
                Each run will reduce your monthly plagiarism check allowance.
              </p>
              {props.user.has_trial_subscription != null &&
                props.user.has_trial_subscription === true && (
                  <p className="text-sm italic font-semibold text-gray-700 mt-2 mr-8 sm:mr-0">
                    Not available during trial period.
                  </p>
                )}
            </div>
            {!editorLoadingPlagiarism && (
              <div className="pr-6">
                {isPlagiarismFound !== null && !isPlagiarismFound ? (
                  <div className="mt-8 flex flex-col gap-y-2 items-center justify-center">
                    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-10 w-10 text-bw-green"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="text-lg leading-6 font-medium text-gray-900">
                      No plagiarism found
                    </p>
                  </div>
                ) : isPlagiarismFound !== null && isPlagiarismFound ? (
                  <div className="mt-8 flex flex-col gap-y-2 items-center justify-center">
                    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                      <ExclamationIcon
                        className="h-10 w-10 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="text-lg leading-6 font-medium text-gray-900">
                      Plagiarism found
                    </p>
                  </div>
                ) : null}
              </div>
            )}
            <div className="md:absolute sm:ml-112 h-1 sm:h-4 flex items-center py-2 md:py-0">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-500"
                onClick={() => {
                  const panel =
                    props.isPanelCollapsed === false &&
                    props.sidePanelValue === sidePanelTypes.DEFAULT
                      ? false
                      : !props.isPanelCollapsed;
                  props.panelCollapse(sidePanelTypes.PLAGIARISM, panel);
                  setPlagiarismFound(null);
                }}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200">
          <div className="px-6">
            <nav
              className="-mb-px flex space-x-6"
              x-descriptions="Tab component"
            ></nav>
          </div>
        </div>
        {renderList()}
      </div>
      <div className="mt-6 mb-6 btn-generate">
        <ReactTooltip
          id={'plagiarism-tab'}
          place={'bottom'}
          type="dark"
          effect="float"
          border={false}
        />
        <Tip
          data-for={'plagiarism-tab'}
          text={
            minCharacter > highLightWords.length ||
            highLightWords.length > maxCharacter
              ? 'Highlight 200-4000 characters.'
              : ''
          }
        >
          <button
            className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700
          hover:bg-purple-500 focus:listicle-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            onClick={() => dispatch(setCheckPlagiarismTriggered(true))}
            disabled={
              minCharacter > highLightWords.length ||
              highLightWords.length > maxCharacter ||
              editorLoadingPlagiarism
            }
          >
            {editorLoadingPlagiarism && (
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
            )}
            Check for plagiarism
          </button>
        </Tip>
      </div>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    isPanelCollapsed: state.panel.isPanelCollapsed,
    sidePanelValue: state.panel.sidePanelValue,
    plagiarism_history: state.plagiarism.plagiarism_history,
    user: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  { panelCollapse, addPlagiarism, getPlagiarisms, destroyPlagiarism },
  null,
  {
    forwardRef: true,
  }
)(PlagiarismPanel);
