import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import NewWrapper from '../NewWrapper';
import NewHeader from '../NewHeader';
import PageHeading from '../PageHeading';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useSentData from '../../services/useSentData';
import { updateUser } from '../../store/actions/auth.actions';
import { useLocation } from 'react-router-dom';
import { GlobeIcon } from '@heroicons/react/outline';

export const getGoogleUrl = () => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
  const options = {
    redirect_uri: process.env.REACT_APP_VITE_GOOGLE_OAUTH_REDIRECT,
    client_id: process.env.REACT_APP_VITE_GOOGLE_OAUTH_CLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/analytics.readonly',
      'https://www.googleapis.com/auth/webmasters.readonly',
    ].join(' '),
  };
  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

function GoogleAuthRedirect() {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(0);
  const request = useSentData();
  const alert = useAlert();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  const { has_google_analytics, has_google_search_console } = useSelector(
    state => state.auth.user
  );
  const isConnected = has_google_analytics && has_google_search_console;

  useEffect(() => {
    if (code) {
      fetchAccounts();
    }
  }, [code]);

  function fetchAccounts() {
    const data = {
      code: code,
    };
    request
      .send(`/api/google-analytic/generate-token`, data, 'post')
      .then(data => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
        dispatch(
          updateUser({
            has_google_analytics: true,
            has_google_search_console: true,
          })
        );
      });
  }

  return (
    <div>
      {!code && (
        <a
          className={`cursor-pointer mb-2 w-full px-4 py-3 inline-flex justify-center border border-transparent text-base font-medium rounded-md shadow-sm text-white ${
            isConnected
              ? 'bg-blue-600 hover:bg-blue-700'
              : 'bg-gray-400 hover:bg-gray-600'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm`}
          href={getGoogleUrl()}
          role="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          <GlobeIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          {isConnected
            ? 'Disconnect Google Account'
            : 'Connect Google Analytics & Search Console'}
        </a>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isListView: state.layout.isListView,
  };
}

export default connect(mapStateToProps, null)(GoogleAuthRedirect);
