import React from 'react';
import ListParagraphPreview from './ListParagraphPreview';

export default function ListPreview({ state }) {
  const items = state.items;

  function renderList() {
    return items.map((item, i) => {
      return (
        <div key={i}>
          {state.title !== '' ? <h3>{item.title}</h3> : <h2>{item.title}</h2>}
          <ListParagraphPreview state={item.text} />
        </div>
      );
    });
  }

  return (
    <div>
      <h2>{state.title}</h2>
      {renderList()}
    </div>
  );
}
