import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import useSentData from '../services/useSentData';
import {isolateError} from '../utils/api';
import {useAlert} from 'react-alert';
import http from '../services/http';
import {authActionTypes} from '../store/reducers/auth.reducer';
import {forceChoosePlan, resetForceChoosePlan} from '../store/actions/access.actions';

export const REJOIN_INVITE = 'rejoinInvite';

function RejoinTeam() {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const history = useHistory();
  const invite = query.get('invite') || sessionStorage.getItem(REJOIN_INVITE);
  const {isAuthenticated, user} = useSelector(state => state.auth);
  const request = useSentData();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (invite && isAuthenticated) {
      sessionStorage.setItem(REJOIN_INVITE, invite)
    } else if (invite && !isAuthenticated) {
      sessionStorage.setItem(REJOIN_INVITE, invite)
      history.replace('/')
    } else {
      history.replace('/')
    }
  }, [])
  
  const onClickAccept = async () => {
    setLoading(true)
    const response = await request.send(`/api/rejoin-team-member`, {
      invite,
      email: user.email
    }, 'post')
    if (!response.isAxiosError) {
      alert.success('Rejoin team successfully!');
      await getNewUserInfo();
    } else {
      alert.error(isolateError(response));
    }
    history.replace('/');
    sessionStorage.removeItem(REJOIN_INVITE);
  }
  
  const getNewUserInfo = () => {
    return http.get(`/api/users/${user.id}`).then(userRes => {
      const newUser = userRes.data;
      if (!newUser.has_access) {
        dispatch(forceChoosePlan());
      } else {
        dispatch(resetForceChoosePlan());
      }
      localStorage.setItem('user', JSON.stringify(newUser));
      dispatch({type: authActionTypes.AUTH_SUCCESS, user : newUser});
      setLoading(false)
    });
  }
  
  return (
    <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 w-11/12 md:w-1/3 mx-auto bg-gray-50'>
      <img className="h-12" src={logoIconPurple} alt="Bramework" />
      <h1 className='text-3xl font-bold text-center mt-3'>Rejoin Team</h1>
      <div className='bg-white rounded-lg p-4 md:p-10 mt-10'>
        <p className='mb-4'>Please click button below to rejoin the team.</p>
        <button disabled={loading} onClick={onClickAccept} className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50'>
          Accept
        </button>
      </div>
    </div>
  );
}

export default RejoinTeam;