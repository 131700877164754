import React from 'react';
import ListParagraphPreview from './ListParagraphPreview';

export default function BonusPreview({ state }) {
  return (
    <div>
      <h2>{state.title}</h2>
      <ListParagraphPreview state={state.text} />
    </div>
  );
}
