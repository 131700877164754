import {
  novaOnBoardingActionTypes,
  novaOnBoardingLocalStoreNames,
  novaOnBoardingSteps,
} from '../reducers/nova_onboarding.reducer';
import { track } from '../../services/track';

function setNovaOnBoardingStep(step) {
  localStorage.setItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_STEP, step);
  return {
    type: novaOnBoardingActionTypes.SET_STEP,
    payload: step,
  };
}

function loadNovaOnBoardingData() {
  const step =
    localStorage.getItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_STEP) || '';
  let novaCheckList = JSON.parse(
    localStorage.getItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST)
  );
  if (!novaCheckList || !Array.isArray(novaCheckList)) {
    novaCheckList = [];
    localStorage.setItem(
      novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST,
      '[]'
    );
  }
  return {
    type: novaOnBoardingActionTypes.LOAD_NOVA_GUIDE_DATA,
    payload: { step, novaCheckList },
  };
}

function novaOnBoardingGuideCompleted() {
  localStorage.removeItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_STEP);
  localStorage.removeItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST);
  return {
    type: novaOnBoardingActionTypes.NOVA_ONBOARDING_GUIDE_COMPLETE,
  };
}

function setNovaOnBoardingCheckList(item) {
  return dispatch => {
    let checkList = JSON.parse(
      localStorage.getItem(novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST)
    );
    if (!checkList || !Array.isArray(checkList)) {
      checkList = [];
    }
    checkList.push(item);
    track(`Onboarding checklist ${item}`);
    const checklistSet = new Set(checkList);
    if (checklistSet.size === 5) {
      dispatch(setNovaOnBoardingStep(novaOnBoardingSteps.NOVA_GUIDE_COMPLETED));
      track(`Completed 5-step onboarding checklist`);
      if (process.env.REACT_APP_INTERCOM_ID) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID,
          checklist_completed: true,
        });
      }
    }
    localStorage.setItem(
      novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST,
      JSON.stringify(Array.from(checklistSet))
    );
    dispatch({
      type: novaOnBoardingActionTypes.SET_CHECK_LIST_DONE,
      payload: Array.from(checklistSet),
    });
  };
}

export {
  setNovaOnBoardingCheckList,
  setNovaOnBoardingStep,
  loadNovaOnBoardingData,
  novaOnBoardingGuideCompleted,
};
