export const typeWriter = (
  index = 0,
  text = '',
  onInsert = () => {},
  onIndexChange = () => {}
) => {
  let done = false;
  try {
    if (index < text.length) {
      let val = '';
      if (text.charAt(index) === '<') {
        const endTagIndex = text.indexOf('>', index + 1);
        val = text.substring(index, endTagIndex + 1);
        onIndexChange(endTagIndex + 1);
      } else {
        const endWordIndex = text.indexOf(' ', index + 1);
        if (endWordIndex > -1) {
          val = text.substring(index, endWordIndex + 1);
          onIndexChange(endWordIndex + 1);
        } else {
          val = text.charAt(index);
          onIndexChange(index + 1);
        }
      }
      onInsert(val);
      done = false;
    } else {
      done = true;
    }
    return done;
  } catch (err) {
    console.error(err);
  }
};
