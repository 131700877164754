const PlatformTypes = {
  PLATFORM_LIST: 'PLATFORM_LIST',
  PLATFORM_USER: 'PLATFORM_USER',
  ADD_PLATFORM: 'ADD_PLATFORM',
  SUCCESS_ADD_PLATFORM: 'SUCCESS_ADD_PLATFORM',
  FAIL_ADD_PLATFORM: 'FAIL_ADD_PLATFORM',
  SUBMITTING_ADD_PLATFORM: 'SUBMITTING_ADD_PLATFORM',
  REMOVE_FORM_STATUS: 'REMOVE_FORM_STATUS',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
  PUBLISH_HISTORY: 'PUBLISH_HISTORY',
  FILTER_PUBLISH_HISTORY: 'FILTER_PUBLISH_HISTORY',
  LOAD_WP_LISTS: 'LOAD_WP_LISTS',
  PUBLISH_SUCCESS: 'PUBLISH_SUCCESS',
};

const initialState = {
  platformList: [],
  platformListUser: [],
  success: false,
  submitting: false,
  fail: false,
  formErrors: {},
  defaultPlatform: '',
  updatePlatformData: null,
  publishHistory: [],
  filterPublishHistory: {},
  wpLists: {
    authors: [],
    categories: [],
  },
  selectPlatformSecondStep: false,
};

const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case PlatformTypes.PLATFORM_LIST:
      return {
        ...state,
        platformList: action.payload,
      };
    case PlatformTypes.PLATFORM_USER:
      return {
        ...state,
        platformListUser: action.payload,
      };
    case PlatformTypes.SUBMITTING_ADD_PLATFORM:
      return {
        ...state,
        success: false,
        submitting: true,
        fail: false,
      };
    case PlatformTypes.FAIL_ADD_PLATFORM:
      return {
        ...state,
        success: false,
        submitting: false,
        fail: true,
        formErrors: action.payload ? action.payload : {},
      };
    case PlatformTypes.SUCCESS_ADD_PLATFORM:
      return {
        ...state,
        success: true,
        submitting: false,
        fail: false,
        formErrors: {},
        platformListUser: [...state.platformListUser, action.payload],
      };
    case PlatformTypes.REMOVE_FORM_STATUS:
      return {
        ...state,
        success: false,
        submitting: false,
        fail: false,
        formErrors: {},
        defaultPlatform: '',
        updatePlatformData: null,
        selectPlatformSecondStep: false,
      };
    case PlatformTypes.UPDATE_PLATFORM:
      return {
        ...state,
        defaultPlatform: action.platform,
        updatePlatformData: action.data,
      };
    case PlatformTypes.PUBLISH_HISTORY:
      return {
        ...state,
        publishHistory: action.payload,
      };
    case PlatformTypes.FILTER_PUBLISH_HISTORY:
      return {
        ...state,
        filterPublishHistory: action.payload,
      };
    case PlatformTypes.LOAD_WP_LISTS:
      return {
        ...state,
        wpLists: action.payload,
        submitting: false,
        fail: false,
        selectPlatformSecondStep: true,
      };
    case PlatformTypes.PUBLISH_SUCCESS:
      return {
        ...state,
        success: true,
        submitting: false,
      };
    default:
      return state;
  }
};

export default platformReducer;
export { PlatformTypes };
