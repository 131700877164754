import React, { useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import CreateProjectSlideOver from './CreateProjectSlideOver';
import DeleteProjectModal from './DeleteProjectModal';
import { DotsVerticalIcon, CheckIcon } from '@heroicons/react/solid';
import AddProjectMemberModal from './ProjectMemberModal';

function ProjectsList({
  projects,
  selectedProject,
  selectProject,
  user,
  fetchProjects,
}) {
  const history = useHistory();
  const [openCreateProjectModal, setCreateProjectModalOpen] = useState(false);
  const [openDeleteProjectModal, setDeleteProjectModalOpen] = useState(false);
  const [projectSelect, setProjectSelect] = useState(null);
  const [projectIdSelect, setProjectIdSelect] = useState(null);
  const [openAddProjectMemberModal, setOpenAddProjectMemberModal] = useState(
    false
  );

  function showDashboard(id) {
    selectProject(id);
    history.push(`project/${id}/dashboard`);
  }

  function renderTableData() {
    return projects.map((project, index) => {
      const { id, name, items, created_at, is_default, members } = project;
      return (
        <tr key={index} className="cursor-pointer hover:bg-gray-50">
          <td
            className="flex mt-1 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={() => {
              showDashboard(id);
            }}
          >
            {selectedProject.id === id && (
              <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
            )}
            {name}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={() => {
              showDashboard(id);
            }}
          >
            {items}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={() => {
              showDashboard(id);
            }}
          >
            {!is_default && members.length + 1}
          </td>
          <td
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            onClick={() => {
              showDashboard(id);
            }}
          >
            {!is_default && <Moment fromNow>{created_at}</Moment>}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            {!is_default && (user.is_owner || user.is_manager) && (
              <Menu as="div" className="static inline-block text-left">
                <div>
                  <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-16 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setProjectSelect(project);
                              setCreateProjectModalOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setProjectSelect(project);
                              setDeleteProjectModalOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <CreateProjectSlideOver
        open={openCreateProjectModal}
        project={projectSelect}
        setProjectIdSelect={setProjectIdSelect}
        fetchProjects={fetchProjects}
        setOpenAddProjectMemberModal={setOpenAddProjectMemberModal}
        setOpen={() => {
          setProjectSelect(null);
          setCreateProjectModalOpen(false);
        }}
      />
      <AddProjectMemberModal
        open={openAddProjectMemberModal}
        projectId={projectIdSelect}
        fetchProjects={fetchProjects}
        setOpen={() => {
          setProjectIdSelect(null);
          setOpenAddProjectMemberModal(false);
        }}
      />
      <DeleteProjectModal
        open={openDeleteProjectModal}
        projectId={projectSelect ? projectSelect.id : null}
        setOpen={() => {
          setProjectSelect(null);
          setDeleteProjectModalOpen(false);
        }}
      />
      <div className="flex flex-col mt-4">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Items
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Members
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderTableData()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(ProjectsList);
