import { plagiarismPanelActionTypes } from '../reducers/plagiarism_panel.reducer';

export default function plagiarismPanelCollapse(isPlagiarismPanelCollapsed) {
  let plagiarismPanelCollapsed = isPlagiarismPanelCollapsed.toString();
  localStorage.setItem('isPlagiarismPanelCollapsed', plagiarismPanelCollapsed);
  return createAction(plagiarismPanelCollapsed);
}

function loadPlagiarismPanelCollapseState() {
  let isPlagiarismPanelCollapsed = localStorage.getItem(
    'isPlagiarismPanelCollapsed'
  );
  return createAction(isPlagiarismPanelCollapsed);
}

function createAction(isPlagiarismPanelCollapsed) {
  if (isPlagiarismPanelCollapsed === 'true') {
    return {
      type: plagiarismPanelActionTypes.HIDE_PLAGIARISM_PANEL,
    };
  } else {
    return {
      type: plagiarismPanelActionTypes.SHOW_PLAGIARISM_PANEL,
    };
  }
}

export { loadPlagiarismPanelCollapseState };
