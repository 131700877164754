import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import Select from '../../components/form/FormSelect';
import { track } from '../../services/track';

function CancelSubscriptionModal({
  open,
  setOpen,
  cancelSubscription,
  hasTrial,
  loading,
}) {
  const [reason, setReason] = useState();
  const [comment, setComment] = useState();
  const options = [
    { value: 1, label: "Writing isn't a priority for me right now." },
    { value: 2, label: "Couldn't figure out how to use it." },
    { value: 3, label: "The AI didn't work well for my niche." },
    { value: 4, label: "Didn't get enough value for the price." },
    { value: 5, label: 'Other' },
  ];

  const handleCancelPlan = () => {
    if (reason) {
      track('Cancelled plan', {
        Reason: reason.label,
        Comment: comment,
      });
      cancelSubscription();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {`Cancel ${hasTrial ? 'Free Trial' : 'Plan'}`}
                  </Dialog.Title>
                  <div className="mt-6">
                    <Select
                      options={options}
                      className="w-full"
                      label={`Please select the reason why you want to cancel your ${
                        hasTrial ? 'free trial' : 'plan'
                      }. *`}
                      value={reason}
                      onChange={v => {
                        return setReason(v);
                      }}
                      isValid={!reason}
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Add your comment {reason?.value === 5 && '*'}
                    </label>
                    {reason?.value === 3 ? (
                      <div className="text-xs italic mt-2">
                        We appreciate your feedback! If you don't mind, could
                        you briefly share your niche or area of expertise? This
                        will help us train future AI models to better serve your
                        specific needs. Thank you for helping us improve!
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="mt-2">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={''}
                        onChange={v => {
                          return setComment(v.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* <p className="text-sm text-gray-500">
                      {`Are you sure you want to cancel your ${
                        hasTrial ? 'free trial' : 'plan'
                      }?`}
                    </p> */}
                    <br></br>
                    <p className="text-sm text-gray-900">
                      {hasTrial
                        ? 'Your free-trial will end immediately.'
                        : 'Your plan will end at the end of the billing cycle.'}
                    </p>
                    <br></br>
                    <p className="text-sm font-medium text-red-500">
                      {`Once your ${
                        hasTrial ? 'free trial' : 'plan'
                      } ends, you will no longer have access to content in your account.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={!reason || (reason?.value === 5 && !comment)}
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-20 ${
                    !reason
                      ? 'cursor-default bg-red-300'
                      : 'cursor-pointer bg-red-600 hover:bg-red-700'
                  }`}
                  onClick={handleCancelPlan}
                >
                  {loading && (
                    <svg
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="mr-2 mt-1 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                    </svg>
                  )}
                  {`Cancel ${hasTrial ? 'free trial' : 'plan'}`}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  {`Continue ${hasTrial ? 'free trial' : 'plan'}`}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default CancelSubscriptionModal;
