import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDomainFromUrl } from '../../utils/common';
import useDataForSeoApi from '../../services/useDataForSeoApi';
import { LOCATIONS } from '../../components/analytics-dashboard/LocationSelect';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
const NewKeywordsOverTime = () => {
  const projectUrl = useSelector(
    state =>
      state.project?.selectedProject?.project_url ||
      state.auth?.user?.company?.domain ||
      ''
  );
  const dataForSeoRequest = useDataForSeoApi();
  const [analyticData, setAnalyticData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    if (projectUrl) {
      getListKeywordOverTime();
    }
  }, [projectUrl]);
  const getListKeywordOverTime = async () => {
    const requestData = [
      {
        target: getDomainFromUrl(projectUrl),
        location_code: LOCATIONS[0].location_code,
        language_code: LOCATIONS[0].available_languages[0].language_code,
      },
    ];
    const response = await dataForSeoRequest.send(
      '/dataforseo_labs/google/historical_rank_overview/live',
      requestData
    );
    const task = response?.tasks ? response?.tasks[0] : null;
    if (!task) {
      return;
    }
    const result = task?.result ? task?.result[0] : null;
    if (!result || !result.items) {
      return;
    }
    const data = result.items.map(item => ({
      year: item?.year,
      month: item?.month,
      total:
        (item?.metrics?.organic?.is_new || 0) +
        (item?.metrics?.paid?.is_new || 0),
    }));
    const sortedData = sortDataByMonth(data);
    const chartData = {
      labels: sortedData.map(item => item.monthName),
      datasets: [
        {
          label: 'New Keywords',
          data: sortedData.map(item => item.total),
          backgroundColor: '#885bd5',
          borderColor: '#7c3aed',
          borderWidth: 1,
          borderRadius: 10,
        },
      ],
    };
    setAnalyticData(chartData);
  };
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        title: {
          display: false,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'New Keywords Over Time',
      },
    },
    indexAxis: 'x',
    barPercentage: 0.2,
    categoryPercentage: 0.8,
  };
  const sortDataByMonth = data => {
    const sortedData = [...data].sort((a, b) => a.month - b.month);
    return sortedData.map(item => {
      const monthName = moment.monthsShort(item.month - 1);
      return { ...item, monthName };
    });
  };
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg h-full">
      {analyticData.labels.length > 0 ? (
        <Bar type="bar" data={analyticData} options={options} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
export default NewKeywordsOverTime;
