import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import DashboardItem from './Dashboard/DashboardItem';
import GridPagination from '../components/grid/grid-pagination';
import Loading from '../components/Loading';
import DraftsList from './Dashboard/DraftsList';
import Pagination from '../components/Pagination';

import { getProjectPostStatuses } from '../utils/post-status';
import useSentData from '../services/useSentData';
import { escapeRegExp } from '../utils/common';
import noImage from '../styles/images/bramework-no-image.svg';
import './Dashboard.scss';

function Drafts({ isListView }) {
  const request = useSentData();
  const alert = useAlert();
  const { project_id } = useParams();
  const [posts, setPosts] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [postStatuses, setPostStatuses] = useState([]);
  const [isLoadedData, setIsLoadedData] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  function updatePosts(page = 1) {
    request
      .send(
        project_id ? `/api/projects/${project_id}/posts/drafts` : '/',
        null,
        'get',
        { page: page }
      )
      .then(data => {
        if (!data.isAxiosError) {
          setPosts(data);
          setFilteredPosts(data.results);
          setIsLoadedData(true);
        }
      });
  }

  // Define the WebSocket connection URL
  // const socketUrl = `${process.env.REACT_APP_BACKEND_HOST.replace(
  //   'http',
  //   'ws'
  // )}/ws/notifications/`;

  // Use the useWebSocket hook
  // const { lastMessage, readyState } = useWebSocket(socketUrl);

  // useEffect(() => {
  //   if (readyState === ReadyState.OPEN && lastMessage !== null) {
  //     const message = JSON.parse(lastMessage.data);
  //     console.log('Received notification: ', message);
  //     updatePosts();
  //   }
  // }, [lastMessage, readyState]);

  const searchPost = useCallback(
    text => {
      setSearchText(text);
      if (text.length > 0) {
        const search = new RegExp(escapeRegExp(text), 'i');
        if (!!posts?.results) {
          const results = posts.results.filter(post => {
            return search.test(post.phrase);
          });
          setFilteredPosts(results);
        }
      } else {
        setFilteredPosts(posts.results);
        setPosts(posts);
      }
    },
    [posts]
  );

  const onToggleSelectItem = (id, checked) => {
    let items = [...selectedItems];
    if (checked) {
      items.push(id);
    } else {
      items = items.filter(item => item !== id);
    }
    setSelectedItems(items);
  };

  const onToggleSelectAllItems = checked => {
    let items = filteredPosts?.map(item => item.id);
    if (checked) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  const onBulkDelete = () => {
    const newPosts = { ...posts };
    newPosts.results = newPosts.results.filter(
      item => selectedItems.indexOf(item.id) < 0
    );
    setPosts(newPosts);
    setSelectedItems([]);
    setFilteredPosts(newPosts.results);
  };

  useEffect(updatePosts, []);

  useEffect(() => {
    if (!!project_id) {
      getProjectPostStatuses(request, project_id)
        .then(data => {
          const activeStatuses = data?.filter(stat => stat.is_active);
          setPostStatuses(activeStatuses);
        })
        .catch(err => {
          alert.error(err.message);
        });
    }
  }, [project_id]);

  const isLoading = request.loading || !request.success;

  return (
    <NewWrapper
      header={
        <NewHeader
          displaySearch={true}
          searchText={searchText}
          searchPost={searchPost}
        />
      }
      pageHeading={
        <PageHeading
          title="📚 Long-Form Writing Assistant"
          category="post"
          gridView={gridView}
          selectedProjectId={project_id}
          setGridView={setGridView}
          isBulkSelect={isBulkSelect}
          setIsBulkSelect={setIsBulkSelect}
          selectedItems={selectedItems}
          onBulkDelete={onBulkDelete}
        />
      }
    >
      {(isLoading || !isLoadedData) && <Loading />}
      {!isListView && isLoadedData && !isLoading && (
        <div className="posts-list drafts cards-wrapper">
          {posts?.results?.length ? (
            <div className="posts-wrapper">
              <h4>Posts</h4>
              {filteredPosts.map(post => (
                <DashboardItem
                  key={post.id}
                  id={post.id}
                  url={post.domain}
                  name={
                    post.category && post.body && post.body[0].state.text
                      ? post.body[0].state.text
                      : post.category && post.body && !post.body[0].state.text
                      ? '(no title)'
                      : post.phrase
                  }
                  thumbnail={
                    post.category && post.body && post.body[1].state.file
                      ? post.body[1].state.file.thumbnail
                      : noImage
                  }
                  date={post.edited_at}
                  body={post.body}
                  results={post.analysis_data.results}
                  difficulty={post.analysis_data.difficulty}
                  competition={post.analysis_data.competition}
                  volume={post.analysis_data.volume}
                  category={post.category}
                  published={post.published}
                  callback={updatePosts}
                  post={post}
                  postStatuses={postStatuses}
                  isSelected={
                    selectedItems.findIndex(item => item === post.id) >= 0
                  }
                  onToggleSelectItem={onToggleSelectItem}
                  isBulkSelect={isBulkSelect}
                />
              ))}
              <GridPagination
                startIndex={(posts.current_page - 1) * posts.page_size + 1}
                endIndex={
                  posts.current_page * posts.page_size < posts.count
                    ? posts.current_page * posts.page_size
                    : posts.count
                }
                total={posts.count}
                isPreviousDisabled={!posts.previous_page}
                isNextDisabled={!posts.next_page}
                onPrevious={() => updatePosts(posts.previous_page)}
                onNext={() => updatePosts(posts.next_page)}
              />
            </div>
          ) : (
            <h1 className="empty-message">You have no post created.</h1>
          )}
        </div>
      )}
      {isListView && isLoadedData && !isLoading && (
        <div>
          {posts?.results?.length ? (
            <div>
              <h3 className="text-lg mt-8 leading-6 font-medium text-gray-900">
                Posts
              </h3>
              <DraftsList
                posts={filteredPosts ?? posts?.results}
                callback={updatePosts}
                response={posts}
                postStatuses={postStatuses}
                selectedItems={selectedItems}
                onToggleSelectItem={onToggleSelectItem}
                onToggleSelectAllItems={onToggleSelectAllItems}
                isBulkSelect={isBulkSelect}
              />
            </div>
          ) : (
            <div className="posts-list drafts cards-wrapper">
              <h1 className="empty-message">You have no post created.</h1>
            </div>
          )}
        </div>
      )}
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    isListView: state.layout.isListView,
  };
}

export default connect(mapStateToProps, null)(Drafts);
