import './GenerateFirstDraft.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';

import FormSelect from '../../components/form/FormSelect';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import FormTextarea from '../../components/form/FormTextarea';
import ResetIcon from './ResetIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import TextareaWithGenerate from './TextareaWithGenerate';
import InputWithGenerate from './InputWithGenerate';
import DeleteModal from './DeleteModal';
import useSentData from '../../services/useSentData';
import Loading from '../../components/Loading';
import PreviewBlock from '../PostPreview/PreviewBlock';
import { numberToThousands } from '../../utils/common';
import { ExclamationIcon } from '@heroicons/react/solid';

import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
  setOption,
} from '../../store/actions/generate-first-draft';
import {
  LABELS,
  getId,
  OUTLINE_PLACEHOLDER_2,
  INSTRUCTIONS_PLACEHOLDER,
  getWordsCount,
  INSTRUCTION_TEMPLATES,
} from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { isolateError } from '../../utils/api';
import { Tooltip } from '@material-ui/core';
import { TONES } from '../../utils/common';
import Select from '../../components/form/FormSelect';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { addSecondaryKeywords } from '../../store/actions/post_builder.actions';
import store from '../../store';

// Main component
const GenerateFirstDraftPro = () => {
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const options = useSelector(state => state.generateFirstDraft.options);
  const dispatch = useDispatch();
  const history = useHistory();
  const state = store.getState();
  const user = state.auth.user;
  const { project_id } = useParams();
  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;
  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values. It ensures that your content sounds like it's coming from your brand.";

  const alert = useAlert();
  const request = useSentData();

  const [instructionsText, setInstructionsText] = useState('');
  const handleTemplateInsert = template => {
    const newText = instructionsText
      ? `${instructionsText}\n\n${INSTRUCTION_TEMPLATES[template]}`
      : INSTRUCTION_TEMPLATES[template];
    setInstructionsText(newText);
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.instructions,
        newText
      )
    );
  };

  const [trashed, setTrashed] = useState(false);
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bodyState, setBodyState] = useState({});

  const MAX_SECTIONS = 40;

  const countSections = outline => {
    // Check if outline is empty or undefined
    if (!outline || outline.trim() === '') {
      return 'Your outline has no headings. Click "Generate" to create an outline.';
    }

    // Sections are separated by newlines
    const sections = outline.split('\n');
    const numSections = sections.length;

    // Choose singular or plural form based on number of headings
    const sectionWord = numSections === 1 ? 'heading' : 'headings';

    if (numSections > MAX_SECTIONS) {
      const numToRemove = numSections - MAX_SECTIONS;
      return (
        <p>
          Your outline has {numSections} {sectionWord}.{' '}
          <span style={{ color: 'red' }}>
            Please remove {numToRemove} to limit to a maximum of {MAX_SECTIONS}.
          </span>
        </p>
      );
    } else {
      return `Your outline has ${numSections} ${sectionWord}.`;
    }
  };

  const createIsDisabled = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields).filter(
      ([key]) =>
        key !== 'audience' &&
        key !== 'instructions' &&
        key !== 'url' &&
        key !== 'brand_voice'
    );
    const fieldsValues = entriesOfRequiredFields.map(([key, value]) => value);
    const fieldsInvalid = !!fieldsValues.filter(item => !item).length;
    return loading.draft || fieldsInvalid;
  }, [loading.draft, fields]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const resetFields = () => {
    track('First Draft PRO generator', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
    // Reset the options checkboxes
    const listOfOptions = Object.keys(options);
    listOfOptions.forEach(option => {
      dispatch(setOption(option, false));
    });
    resetDraft();
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
  };

  const handleOptionChange = event => {
    const { name, checked } = event.target;
    dispatch(setOption(name, checked));
  };

  const handleGenerateTitle = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.title] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/first-draft-pro-title-generator', {
        phrase: primaryTopic,
        variations: 1,
        tone,
      });
      track('First Draft PRO generator', { Clicked: 'generate title' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.title,
            content[0].output
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.title] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleGenerateOutline = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic || !title) {
      alert.error(`${LABELS.primary_topic} and ${LABELS.title} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-outlines', {
        phrase: primaryTopic,
        title,
      });
      track('First Draft PRO generator', { Clicked: 'generate outline' });
      const { content, message, success, outline_id } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline,
            content?.text ?? ''
          )
        );
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline_id,
            outline_id
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleCreateFirstDraft = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const audience = fields[GENERATE_FIRST_DRAFT_FIELDS.audience];
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    const outlineId = fields[GENERATE_FIRST_DRAFT_FIELDS.outline_id];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const instructions = fields[GENERATE_FIRST_DRAFT_FIELDS.instructions];
    const brandVoice = fields[GENERATE_FIRST_DRAFT_FIELDS.brand_voice];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic || !outline || !title) {
      alert.error(
        `${LABELS.primary_topic}, ${LABELS.title}, and ${LABELS.outline} required!`
      );
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-draft-pro', {
        phrase: primaryTopic,
        audience,
        outline,
        tone,
        options,
        outline_id: outlineId,
        project_id,
        title,
        instructions,
        brand_voice: brandVoice,
      });
      track('First Draft PRO generator', {
        Clicked: 'generate first draft pro',
        Keyword: primaryTopic,
        Tone: tone,
        Audience: audience,
        Outline: outline,
        Options: options,
        Title: title,
        BrandVoice: brandVoice,
        Instructions: instructions,
      });
      const { message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(setFirstDraft(true));
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id]);

  const handleGoToDashboard = () => {
    track('First Draft PRO generator', { Clicked: 'go to dashboard' });
    resetFields();
    resetDraft();
    history.push(`/project/${project_id}/dashboard`);
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline
      .split('\n')
      .map(item => item.trim())
      .filter(item => item);
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 105 + totalSubHeading * 89;
    return wordCount;
  };

  const getEstimatedTime = () => {
    const timeInSeconds = getWordCount() * 0.175;
    const minutes = Math.floor(timeInSeconds / 60);
    return `It will take approximately ${minutes} minutes to generate the draft.`;
  };

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen">
      <div className="flex-1 hidden lg:flex flex-col items-center justify-center bg-white">
        {/* TODO: Add lottie animation */}
        {!!draft ? (
          <Fragment>
            <img
              src={NovaRobot}
              alt="nova-robot"
              className="md:ml-10 lg:mt-5 lg:ml-24 mb-8 w-2/4"
            />
            <h1 className="font-bold text-gray-700 lg:text-md xl:text-lg mx-8">
              Your Blog Post is Being Generated
            </h1>
            <p className="text-gray-600 mx-8">
              We will notify you via email once it is ready.
              <br />
              {getEstimatedTime()}
              <br />
              Click 'Go to Dashboard' to see your post's progress.
              <br />
              Or, you can start a new draft by clicking the 'Reset' button
              above.
            </p>
            <div
              id="alert-additional-content-1"
              className="p-4 my-4 text-white rounded-lg bg-purple-600 mx-8"
              role="alert"
            >
              <div className="flex">
                <div className="flex mr-2">
                  <InformationCircleIcon className="w-5 h-5" />
                </div>
                <div className="text-sm">
                  <b>😀 Remember:</b> AI writers are not meant to generate
                  publish-ready content. We recommend you add your own
                  experiences, opinions, and edits so your article is valuable
                  to the reader and ranks better in Google. Google's latest
                  update emphasizes experience in content.
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="hidden lg:flex flex-col items-center">
            {loading.outline ? (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 md:mt-10 lg:mt-5 lg:ml-24 mb-8 w-2/4 animate-pulse"
                />
                <h1 className="font-bold text-gray-700 lg:text-lg xl:text-xl text-center mt-8 animate-pulse">
                  Pause and Flash a Smile
                </h1>
                <p className="text-gray-600 lg:text-md xl:text-lg text-center mt-8 animate-pulse px-4">
                  Nova is conducting in-depth web research to create your
                  outline.
                  <br />
                  Estimated time: 5 minutes
                  <br />
                  DO NOT refresh or leave this page.
                </p>
              </Fragment>
            ) : loading.draft ? (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 md:mt-10 lg:mt-5 lg:ml-24 mb-8 w-2/4 animate-pulse"
                />
                <h1 className="font-bold text-gray-700 lg:text-lg xl:text-xl text-center mt-8 animate-pulse">
                  Take a Deep Breath and Grin
                </h1>
                <p className="text-gray-600 lg:text-md xl:text-lg text-center mt-8 animate-pulse px-4">
                  Nova is writing the first draft of your blog post.
                  <br />
                  {getEstimatedTime()}
                  <br />
                  DO NOT refresh or leave this page.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 lg:mt-5 lg:ml-24 mb-8 w-2/4"
                />
                <div className="text-gray-700 lg:text-md xl:text-lg mx-8">
                  <p>
                    <b>Step 1: </b>Enter your topic or keyword, target audience,
                    and select the tone.
                  </p>
                  <p>
                    <b>Step 2: </b>Click 'Generate' to create an outline.
                    Customize as needed, follow the format, remove irrelevant
                    headings, and keep within a 30-heading limit.
                  </p>
                  <p>
                    <b>Step 3:</b> Click 'Generate First Draft'.
                  </p>
                  <br />
                  <p>
                    <b>
                      Check out some posts written by First Draft PRO that ranks
                    </b>
                  </p>
                  <ul className="list-disc list-inside">
                    <li>
                      <a
                        href="https://www.bramework.com/is-blogging-still-relevant/"
                        target="_blank"
                        rel="noreferrer"
                        className="click-for-help"
                      >
                        Is Blogging Still Relevant? The Answer May Surprise You!
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.bramework.com/what-is-the-point-of-blogging/"
                        target="_blank"
                        rel="noreferrer"
                        className="click-for-help"
                      >
                        From Hobby to Hustle: How Blogging Can Turn Your Life
                        Around
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.handytaxguy.com/are-twitch-donations-taxable/"
                        target="_blank"
                        rel="noreferrer"
                        className="click-for-help"
                      >
                        Are Twitch Donations Taxable? Streamers, Don't Get
                        Burned!
                      </a>
                    </li>
                  </ul>
                  <br />
                  <p>
                    <span
                      className="click-for-help"
                      onClick={() => {
                        if (process.env.REACT_APP_INTERCOM_ID) {
                          window.Intercom('show');
                        }
                      }}
                    >
                      👉 Click here for help!
                    </span>
                  </p>
                </div>
                <div
                  id="alert-additional-content-1"
                  className="p-4 my-4 text-white rounded-lg bg-purple-600 mx-8"
                  role="alert"
                >
                  <div className="flex">
                    <div className="flex mr-2">
                      <InformationCircleIcon className="w-5 h-5" />
                    </div>
                    <div className="text-sm">
                      <b>✋ Important:</b> AI writers are not meant to generate
                      publish-ready content. We recommend you add your own
                      experiences, opinions, and edits so your article is
                      valuable to the reader and ranks better in Google.
                      Google's latest update emphasizes experience in content.
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
      <div className="flex-1">
        <div className="px-5 lg:py-5 xl:px-10 h-full flex flex-col justify-center items-center bg-gray-100">
          <div className="max-h-full w-full bg-white rounded-lg shadow-lg flex flex-col py-6">
            {/* Top actions */}
            <div className="flex justify-between items-center px-8">
              <div>
                <div>
                  {/* User who signed up but does not have a free trial, no paid subscription, and not appsumo */}
                  {/* {user.has_trial_subscription != null &&
                    user.has_trial_subscription === false &&
                    !(
                      (user.has_subscription != null &&
                        user.has_subscription === true) ||
                      (user.app_sumo_subscription != null &&
                        user.app_sumo_subscription === true) ||
                      user.has_team_member
                    ) && (
                      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 shadow-sm mb-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationIcon
                              className="h-5 w-5 text-yellow-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-yellow-700">
                              <Link
                                className="font-semibold text-yellow-700 hover:text-yellow-600 cursor-pointer"
                                to="/plans"
                              >
                                Start your 7-Day Trial to get a FREE Credit to
                                try First Draft PRO
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    )} */}
                </div>
                <div>
                  {/* User has a free trial, no paid subscription, and not appsumo */}
                  {/* {user.has_trial_subscription != null &&
                    user.has_trial_subscription === true &&
                    user.has_subscription != null &&
                    user.has_subscription === true && (
                      <div className="bg-green-50 border-l-4 border--400 p-4 shadow-sm mb-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon
                              className="h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-green-700">
                              <span
                                className="font-semibold text-green-700 hover:text-green-600 cursor-pointer"
                                onClick={() => {
                                  if (process.env.REACT_APP_INTERCOM_ID) {
                                    window.Intercom('show');
                                  }
                                }}
                              >
                                While on 7-Day Trial click here to contact us
                                for a FREE Credit to try First Draft PRO
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )} */}
                </div>
                <h1>
                  <span className="font-semibold text-lg text-gray-900">
                    First Draft PRO
                  </span>
                </h1>
                <p className="text-sm text-gray-700 ">
                  All fields with (*) are required
                </p>
                <div>
                  {/* User with paid subscription, or team member and not appsumo */}
                  {/* {user.has_trial_subscription != null &&
                    user.has_trial_subscription === false &&
                    ((user.has_subscription != null &&
                      user.has_subscription === true) ||
                      (user.app_sumo_subscription != null &&
                        user.app_sumo_subscription === true) ||
                      user.has_team_member) && (
                      <p className="text-sm click-for-help mt-0.5">
                        <Link className="clear-button font-bold" to="/account">
                          Click here to purchase more credits
                        </Link>
                      </p>
                    )} */}
                </div>
              </div>
              <div className="flex flex-row justify-end">
                <Link
                  className="w-full sm:w-auto inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-bw-green hover:bg-bw-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bw-green sm:mt-0 sm:text-sm mr-2"
                  to={
                    user.app_sumo_subscription
                      ? [
                          'bramework_tier1',
                          'bramework_tier2',
                          'bramework_tier3',
                          'bramework_tier4',
                        ].includes(
                          user.app_sumo_subscription.plan.app_sumo_plan
                        )
                        ? '/appsumo-plan'
                        : user.app_sumo_subscription.plan.app_sumo_plan ===
                          'bramework_ltd1'
                        ? '/bramework-ltd-plan'
                        : user.app_sumo_subscription.plan.app_sumo_plan ===
                          'free_user'
                        ? '/account'
                        : '/plans'
                      : '/plans'
                  }
                  onClick={() =>
                    track('First Draft PRO generator', { Clicked: 'purchase' })
                  }
                >
                  {(user.subscription === undefined ||
                    user.subscription === null) &&
                  !user.has_subscription &&
                  !user.app_sumo_subscription
                    ? 'Try First Draft PRO for $1'
                    : 'Purchase more credits'}
                </Link>
                {allowReset && (
                  <button
                    className="w-full sm:w-auto inline-flex justify-center px-8 py-2 text-xs text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-md items-center gap-x-1"
                    onClick={() => resetFields()}
                  >
                    <span>Reset</span>
                    <ResetIcon />
                  </button>
                )}
              </div>
            </div>
            {/* main form */}
            <div className="mt-4 overflow-y-auto bg-white overflow-x-visible px-8">
              <FormContainer>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id={getId(LABELS.brand_voice)}
                    className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                    checked={
                      fields[GENERATE_FIRST_DRAFT_FIELDS.brand_voice] &&
                      !isDisabled
                    }
                    onChange={e => {
                      if (!isDisabled) {
                        dispatch(
                          setGenerateFirstDraftField(
                            GENERATE_FIRST_DRAFT_FIELDS.brand_voice,
                            e.target.checked
                          )
                        );
                      }
                    }}
                    disabled={isDisabled}
                  />
                  <label
                    htmlFor={getId(LABELS.brand_voice)}
                    className={`ml-2 block ${
                      isDisabled ? 'text-gray-400' : 'text-gray-900'
                    }`}
                  >
                    {LABELS.brand_voice}
                  </label>
                  <Tooltip title={tooltipText}>
                    <span className="ml-2 text-gray-500 hover:text-gray-700 cursor-pointer">
                      <InformationCircleIcon className="h-5 w-5" />
                    </span>
                  </Tooltip>
                </div>
                {isDisabled && (
                  <p className="mt-1 text-sm text-gray-500">
                    {isUncategorizedProject
                      ? 'Create a project folder to use the brand voice feature.'
                      : 'Add or generate a brand voice in your project settings.'}
                  </p>
                )}
              </FormContainer>
              <form className="" onClick={e => e.preventDefault()}>
                <FormContainer>
                  <FormLabel label={LABELS.primary_topic} />
                  <FormInput
                    id={getId(LABELS.primary_topic)}
                    className="w-full md:w-2/3"
                    inputClassName="rounded-lg"
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                          val
                        )
                      );
                    }}
                    inputProps={{ placeHolder: 'how to start a blog' }}
                  />
                </FormContainer>
                <FormContainer>
                  <span className="block w-full sm:w-116 font-semibold text-gray-900">
                    {LABELS.audience}
                  </span>
                  <FormInput
                    id={getId(LABELS.audience)}
                    className="w-full md:w-2/3"
                    inputClassName="rounded-lg"
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.audience]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.audience,
                          val
                        )
                      );
                    }}
                    inputProps={{
                      placeHolder:
                        'passionate bloggers wanting to blog full-time',
                    }}
                  />
                </FormContainer>
                <FormContainer>
                  <span className="block w-full sm:w-116 font-semibold text-gray-900">
                    {LABELS.tone}
                  </span>
                  <Select
                    id={getId(LABELS.tone)}
                    className="w-full md:w-2/3"
                    options={TONES}
                    value={TONES.find(
                      item =>
                        item.value === fields[GENERATE_FIRST_DRAFT_FIELDS.tone]
                    )}
                    onChange={({ value }) => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.tone,
                          value
                        )
                      );
                    }}
                  />
                </FormContainer>
                <FormContainer>
                  <FormLabel label={LABELS.title} />
                  <InputWithGenerate
                    id={getId(LABELS.title)}
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.title]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.title,
                          val
                        )
                      );
                    }}
                    onGenerate={handleGenerateTitle}
                    loading={loading[GENERATE_FIRST_DRAFT_FIELDS.title]}
                    disabled={loading.draft}
                    inputProps={{
                      placeHolder:
                        'How to Start a Blog: The Ultimate Beginner’s Guide',
                    }}
                  />
                </FormContainer>
                <FormContainer>
                  <FormLabel label={LABELS.outline} />
                  <TextareaWithGenerate
                    id={getId(LABELS.outline)}
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.outline,
                          val
                        )
                      );
                    }}
                    onGenerate={handleGenerateOutline}
                    loading={loading[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                    rows={8}
                    disabled={loading.draft}
                    placeHolder={OUTLINE_PLACEHOLDER_2}
                  />
                  <p className="text-sm text-gray-700">
                    {countSections(fields[GENERATE_FIRST_DRAFT_FIELDS.outline])}
                  </p>
                </FormContainer>
                <FormContainer>
                  <div className="flex justify-between mb-2">
                    <span className="block w-full sm:w-116 font-semibold text-gray-900">
                      {LABELS.instructions}
                    </span>
                    <div className="w-24 text-sm font-normal text-gray-500 text-right">
                      {fields[GENERATE_FIRST_DRAFT_FIELDS.instructions].length}{' '}
                      / 4000
                    </div>
                  </div>
                  <p className="-mt-1 mb-2 text-xs text-gray-500 font-normal">
                    Enter additional information the AI should include when
                    writing.
                  </p>
                  <div className="mb-2 flex flex-wrap gap-2">
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('internalLinks')}
                    >
                      Internal Links
                    </button>
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('statistics')}
                    >
                      Statistics
                    </button>
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('companyInfo')}
                    >
                      Company Info
                    </button>
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('keyPoints')}
                    >
                      Key Points
                    </button>
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('exampleScenarios')}
                    >
                      Examples
                    </button>
                    <button
                      className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                      onClick={() => handleTemplateInsert('callToAction')}
                    >
                      Call to Action
                    </button>
                  </div>
                  <FormTextarea
                    value={instructionsText}
                    onChange={val => {
                      setInstructionsText(val);
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.instructions,
                          val
                        )
                      );
                    }}
                    id={getId(LABELS.instructions)}
                    maxLength={4000}
                    rows={10}
                    placeHolder={INSTRUCTIONS_PLACEHOLDER}
                    hideCharCount
                  />
                </FormContainer>
              </form>
              <div className="mb-6">
                <span className="block w-full font-semibold text-gray-900 mb-4">
                  Check what to include
                </span>
                <div className="flex flex-wrap justify-start items-center gap-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeTitles}
                      onChange={handleOptionChange}
                      name="includeTitles"
                    />
                    <span className="ml-2 text-gray-700">
                      3 Title Suggestions
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeMetaDescriptions}
                      onChange={handleOptionChange}
                      name="includeMetaDescriptions"
                    />
                    <span className="ml-2 text-gray-700">
                      3 Meta Descriptions Suggestions
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeImageAltTexts}
                      onChange={handleOptionChange}
                      name="includeImageAltTexts"
                    />
                    <span className="ml-2 text-gray-700">
                      Image Alt Text Suggestions
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeBoldText}
                      onChange={handleOptionChange}
                      name="includeBoldText"
                    />
                    <span className="ml-2 text-gray-700">
                      Bold Important Text
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeExternalLinks}
                      onChange={handleOptionChange}
                      name="includeExternalLinks"
                    />
                    <span className="ml-2 text-gray-700">Outbound Links</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeFAQs}
                      onChange={handleOptionChange}
                      name="includeFAQs"
                    />
                    <span className="ml-2 text-gray-700">FAQs</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeSources}
                      onChange={handleOptionChange}
                      name="includeSources"
                    />
                    <span className="ml-2 text-gray-700">Cite Sources</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeTableOfContents}
                      onChange={handleOptionChange}
                      name="includeTableOfContents"
                    />
                    <span className="ml-2 text-gray-700">
                      Table of Contents
                    </span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeKeyTakeaways}
                      onChange={handleOptionChange}
                      name="includeKeyTakeaways"
                    />
                    <span className="ml-2 text-gray-700">Key Takeaways</span>
                  </label>
                  {/* <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeFeaturedImage}
                      onChange={handleOptionChange}
                      name="includeFeaturedImage"
                    />
                    <span className="ml-2 text-gray-700">Featured Image</span>
                  </label> */}
                  {/* <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                      checked={options.includeResearchReport}
                      onChange={handleOptionChange}
                      name="includeResearchReport"
                    />
                    <span className="ml-2 text-gray-700">Research Report</span>
                  </label> */}
                  {/* <label className="flex items-center">
                  <input
                    type="checkbox"
                    disabled={true}
                    className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                    checked={options.includeFeaturedImage}
                    onChange={handleOptionChange}
                    name="includeFeaturedImage"
                  />
                  <span className="ml-2 text-gray-700">
                    Featured Image
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    disabled={true}
                    className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                    checked={options.includeBodyImages}
                    onChange={handleOptionChange}
                    name="includeBodyImages"
                  />
                  <span className="ml-2 text-gray-700">
                    Body Images
                  </span>
                </label> */}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-start gap-x-4 pt-2 pb-0 px-8">
              <button
                className="text-md bg-white border border-purple-500 hover:bg-purple-100 rounded-lg flex-1 py-3 text-purple-500 font-semibold disabled:cursor-not-allowed"
                onClick={handleGoToDashboard}
                disabled={loading.draft}
              >
                Go to Dashboard
              </button>
              <div className="flex-1 flex flex-col">
                <button
                  className="text-md bg-purple-600 hover:bg-purple-700 border border-purple-600 rounded-lg py-3 text-white font-semibold flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={createIsDisabled}
                  onClick={handleCreateFirstDraft}
                >
                  {loading.draft && <LoadingIcon />}
                  <span>Generate First Draft</span>
                </button>
                {fields[GENERATE_FIRST_DRAFT_FIELDS.outline] ? (
                  <caption className="mt-2 text-xs text-gray-400">
                    (AI will generate around {numberToThousands(getWordCount())}{' '}
                    words)
                  </caption>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateFirstDraftPro;
