import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PencilIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  DocumentDuplicateIcon,
  CheckIcon,
  XIcon,
  PlusIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import defaultAvatar from '../../styles/images/default-avatar.svg';
import novaIcon from '../../styles/images/nova-onboarding.svg';
import '../../pages/Chat.scss';
import { useAlert } from 'react-alert';
import {
  useDeleteFavoritePrompt,
  useSaveFavoritePrompt,
} from '../../utils/chatApi';
import { useHistory, useParams } from 'react-router-dom';
import { loadFavoritePrompts } from '../../store/actions/chat.actions';
import { StarIcon } from '@heroicons/react/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { MessageItem } from './MessageItem';
import Tooltip from '@material-ui/core/Tooltip';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { processHTML } from '../../utils/processHTML';
import {
  addSecondaryKeywords,
  setAIWritingToolSelected,
} from '../../store/actions/post_builder.actions';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { Dialog, Transition } from '@headlessui/react';
import FormInput from '../../components/form/FormInput';
import Loading from '../../components/Loading';
import LoadingIcon from '../../components/LoadingIcon';

const ChatMessage = ({ message, onEdit, onRate, onInsert, chatId }) => {
  const { saveFavoritePrompt } = useSaveFavoritePrompt();
  const { deleteFavoritePrompt } = useDeleteFavoritePrompt();

  const user = useSelector(state => state.auth.user);
  const favoritePrompts = useSelector(state => state.chat.favoritePrompts);
  const isPanelMode = useSelector(state => state.chat.isPanelMode);
  const projectId = useSelector(state => state.project.selectedProject?.id);
  const alert = useAlert();
  const request = useSentData();
  const history = useHistory();
  const { role, content, id } = message;
  const [isFavorite, setIsFavorite] = useState(false);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(content);
  const [openModal, setModal] = useState(false);
  const [primaryKeyword, setPrimaryKeyword] = useState('');

  const userImage = user?.preview || defaultAvatar;
  const botImage = novaIcon;

  useEffect(() => {
    setIsFavorite(
      favoritePrompts.findIndex(item => item.id === message.id) >= 0
    );
  }, [favoritePrompts]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = () => {
    onEdit(message.id, editedText);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(content);
  };

  const handleFavorite = async () => {
    if (isFavorite) {
      await deleteFavoritePrompt(message.id);
    } else {
      await saveFavoritePrompt(chatId, message);
    }
    dispatch(loadFavoritePrompts());
    setIsFavorite(!isFavorite);
  };

  const handleRate = rating => {
    onRate(message, rating);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    alert.success('Successfully copied to clipboard!');
  };

  const handleWritePost = () => {
    setModal(true);
  };

  const createNewPost = async () => {
    const body = [
      {
        type: 'Title',
        state: {
          text: '',
        },
      },
      {
        type: 'HeroImage',
        state: {
          file: null,
        },
      },
      {
        type: 'Body',
        state: {
          id: null,
          text: document.getElementById(`chat-message-${id}`).innerHTML,
        },
      },
    ];

    let analysis_data = {
      volume: null,
      competition: null,
      results: null,
      difficulty: null,
      suggested_keywords: [],
      trends: null,
      top_questions: [],
      top_related: [],
      top_posts: [],
      database: 'us',
    };
    const semrushRes = await request.send('/api/semrush', {
      keyword_phrase: primaryKeyword,
      database: 'us',
    });

    if (!semrushRes.isAxiosError) {
      analysis_data = semrushRes;
    }

    const resCreatePost = await request.send(
      `/api/projects/${projectId}/posts`,
      {
        phrase: primaryKeyword,
        analysis_data,
      }
    );

    const { id: newPostId } = resCreatePost;
    if (!newPostId) {
      return;
    }

    const resData = await request.send(
      `/api/projects/${projectId}/posts/${newPostId}`,
      {
        body,
        category: 1,
      },
      'put'
    );
    if (!resData.isAxiosError) {
      goToPostBuilder(resData);
    } else {
      alert.error(isolateError(resData));
    }
  };

  const handleToggleModal = () => {
    setModal(prevState => !prevState);
  };

  const goToPostBuilder = async data => {
    let res = await request
      .send('/api/generate-keywords', {
        phrase: data.phrase,
      })
      .catch();
    const keywords = res?.content || '';
    await request.send(
      `/api/projects/${projectId}/posts/${data.id}`,
      { topics: keywords ? keywords.split(',') : null },
      'put'
    );
    history.push({
      pathname: `/project/${projectId}/post-builder/${data.id}`,
      state: { id: data.id },
    });
    if (keywords) {
      const additionKeywords = keywords.split(',').map((item, index) => ({
        id: new Date().getTime() + index,
        text: item,
        selected: false,
      }));
      sessionStorage.setItem(
        `${ADDITION_KEYWORDS_KEY}_${data.id}`,
        JSON.stringify(additionKeywords)
      );
      dispatch(addSecondaryKeywords(keywords.split(',')));
    }
  };

  return (
    <div
      className={`chat-message ${
        role === 'user' ? 'chat-message-user' : 'chat-message-bot'
      }`}
    >
      <img
        src={role === 'user' ? userImage : botImage}
        alt="Avatar"
        className="chat-avatar"
      />
      <div className="chat-message-content">
        {isEditing ? (
          <textarea
            className="chat-message-edit block shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
            value={editedText}
            onChange={e => setEditedText(e.target.value)}
          />
        ) : (
          <MessageItem message={content} id={id} />
        )}
        <div className="chat-message-meta">
          <div className="chat-message-time">{message.createdAt}</div>
          <div className="chat-message-actions">
            {role === 'user' ? (
              <>
                {isEditing ? (
                  <>
                    <CheckIcon
                      className="chat-action-save h-6 w-6"
                      onClick={handleSaveEdit}
                    >
                      Save
                    </CheckIcon>
                    <XIcon
                      className="chat-action-cancel h-6 w-6"
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </XIcon>
                  </>
                ) : (
                  <>
                    {isFavorite ? (
                      <Tooltip title="Remove from favorites" arrow>
                        <span>
                          <StarIconSolid
                            className={`chat-action-icon h-4 w-4 favorite`}
                            onClick={handleFavorite}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add to favorites" arrow>
                        <span>
                          <StarIcon
                            className={`chat-action-icon h-4 w-4`}
                            onClick={handleFavorite}
                          />
                        </span>
                      </Tooltip>
                    )}
                    <Tooltip title="Edit message" arrow>
                      <span>
                        <PencilIcon
                          className="chat-action-icon h-4 w-4"
                          onClick={handleEdit}
                        />
                      </span>
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <>
                {isPanelMode ? (
                  <Tooltip title="Insert in editor" arrow>
                    <span>
                      <PlusIcon
                        className="chat-action-icon h-4 w-4"
                        onClick={() =>
                          onInsert(
                            document.getElementById(`chat-message-${id}`)
                              .innerHTML
                          )
                        }
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Write post" arrow>
                    <span>
                      <DocumentTextIcon
                        className="chat-action-icon h-4 w-4"
                        onClick={() => handleWritePost()}
                      />
                    </span>
                  </Tooltip>
                )}
                <Tooltip title="Like" arrow>
                  <span>
                    <ThumbUpIcon
                      className="chat-action-icon h-4 w-4"
                      onClick={() => handleRate('up')}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="DisLike" arrow>
                  <span>
                    <ThumbDownIcon
                      className="chat-action-icon h-4 w-4"
                      onClick={() => handleRate('down')}
                    />
                  </span>
                </Tooltip>

                <Tooltip title="Copy" arrow>
                  <span>
                    <DocumentDuplicateIcon
                      className="chat-action-icon h-4 w-4"
                      onClick={handleCopy}
                    />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          static
          onClose={handleToggleModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={() => setModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <h3 className="text-lg text-center my-3 font-bold">
                  Topic or Keyword
                </h3>
                <input
                  value={primaryKeyword}
                  placeholder="Enter your post topic or keyword phrase"
                  onChange={e =>
                    setPrimaryKeyword(e.target.value.toLowerCase())
                  }
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      createNewPost();
                    }
                  }}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                />
                <button
                  disabled={!primaryKeyword || request.loading}
                  onClick={() => createNewPost()}
                  className="w-full flex items-center justify-center mt-3 py-2 px-4 border border-transparent rounded-md shadow-sm
                 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                >
                  {request.loading && <LoadingIcon />}
                  <span className="ml-2">Create</span>
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ChatMessage;
