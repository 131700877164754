import React from 'react';
import Validator, {
  url as validateURL,
  required,
} from '../../services/Validator';
import Modal from 'react-modal';
import FormInput from '../../components/form/FormInput';
import '../Profile/EmailChangeModalView.scss';
import cancelIcon from '../../styles/images/cancel-black.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '500px',
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function ChangeURLModal({
  opened,
  close,
  postURL,
  changeURL,
  setPostURL,
}) {
  const validator = Validator({
    postURL: [required, validateURL],
  });
  let validation = validator.validateAll({ postURL });

  return (
    <Modal isOpen={opened} style={customStyles} ariaHideApp={false}>
      <button
        className="EmailChangeModalView-button clear-button"
        onClick={e => {
          e.preventDefault();
          close();
        }}
      >
        <img src={cancelIcon} alt="" className="cancel-button" />
      </button>
      <form
        className="EmailChangeModalView"
        onSubmit={e => {
          e.preventDefault();
          if (validator.isValid()) {
            changeURL(postURL);
          }
        }}
      >
        <h2>Change Post URL</h2>
        <div className="form-inner">
          <div className="inputs-wrapper">
            <FormInput
              id="url"
              type="url"
              className="profile-input"
              label="Example: https://www.mywebsite.com/my-blog-post/"
              value={postURL}
              onChange={v => {
                setPostURL(v);
              }}
              isValid={!validation.postURL}
            />
          </div>
        </div>
        <div className="button-wrapper">
          <button className="btn btn-red">SAVE</button>
        </div>
      </form>
    </Modal>
  );
}
