const AnalyticsStat = ({ title, value }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4 md:mb-0 md:mr-4 last:mr-0">
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-sm md:text-base lg:text-lg font-medium text-gray-500 truncate">
        {title}
      </dt>
      <dd className="mt-1 text-sm md:text-base lg:text-2xl font-semibold text-gray-900">
        {value}
      </dd>
    </div>
  </div>
);

export default AnalyticsStat;
