import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ArrowSmRightIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
  PresentationChartLineIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';

import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import InputAndRegionForm from '../components/form/input-and-region-form';
import TablePagination from '../components/table/table-pagination';
import Table from '../components/table/table';
import TableContainer from '../components/table/table-container';
import TableDataCell from '../components/table/table-data-cell';
import TableDataRow from '../components/table/table-data-row';

import { formatNumber } from '../utils/common';
import { track } from '../services/track';
import { isolateError } from '../utils/api';
import { REGIONS } from '../utils/regions';
import SemrushBadge from '../components/semrush-badge/semrush-badge';
import { chooseOption } from '../services/api';

import redArrowDown from '../styles/images/red-arrow-down.svg';
import greenArrowUp from '../styles/images/green-arrow-up.svg';
import grayArrowRight from '../styles/images/gray-arrow-right.svg';
import './Performance.scss';

const performanceTableHeaders = {
  keyword: 'Keyword',
  postUrl: 'Post Url',
  position: 'Position',
  difference: 'Difference',
  volume: 'Volume',
  results: 'Results',
};
const uncategorizedProject = 'Uncategorized';
const usDatabaseRegion = REGIONS.find(reg => reg.value === 'us');
const keyKeywords = 'performance-keywords';
const keyTitle = 'performance-title';
const keyRegion = 'performance-region';

export default function Performance() {
  const request = useSentData();
  const alert = useAlert();
  let { id, project_id } = useParams();
  const [keywords, setKeywords] = useState([]);
  const [title, setTitle] = useState({});

  const [inputUrl, setInputUrl] = useState('');
  const [inputRegion, setInputRegion] = useState(usDatabaseRegion);
  const [dataOffset, setDataOffset] = useState(0);
  const isSemrushConnected = useSelector(
    state => state.auth.user.isSemrushConnected
  );
  const selectedProject = useSelector(state => state.project.selectedProject);
  const databaseRegionFromUserSettings = useSelector(
    state => state.auth.user.settings?.database
  );

  const loadPerformanceData = useCallback(
    offset => {
      if (!inputUrl || !inputRegion) {
        return;
      }
      request
        .send('/api/semrush/performance', null, 'get', {
          url: inputUrl,
          database: inputRegion.value,
          offset,
        })
        .then(data => {
          if (!data.isAxiosError) {
            if (!data[1].length) {
              setDataOffset(0);
            }
            setKeywords(data[1]);
            localStorage.setItem(keyKeywords, JSON.stringify(data[1]));
            setTitle(data[0]);
            localStorage.setItem(keyTitle, JSON.stringify(data[0]));
            track('Website keyword ranking', {
              Domain: data[0].domain,
              'Project id': project_id,
            });
          } else {
            alert.error(isolateError(data));
          }
        });
    },
    [inputUrl, inputRegion]
  );

  function renderTableData() {
    return keywords.map((kw, index) => {
      const {
        keyword,
        position,
        previous_position,
        position_difference,
        search_volume,
        url,
        number_of_results,
      } = kw;
      return (
        <TableDataRow key={index}>
          <TableDataCell>{keyword}</TableDataCell>
          <TableDataCell className="text-purple-600 cursor-pointer">
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </TableDataCell>
          <TableDataCell className="inline-flex whitespace-nowrap">
            {previous_position}
            <ArrowSmRightIcon className="w-6 h-6 mr-2 ml-2 text-gray-400" />
            {position}
          </TableDataCell>
          <TableDataCell className="justify-center">
            <div className="flex">
              {position_difference > 0 ? (
                <>
                  <ArrowSmUpIcon className="w-6 h-6 mr-2 ml-2 text-green-600" />
                  {position_difference}
                </>
              ) : position_difference < 0 ? (
                <>
                  <ArrowSmDownIcon className="w-6 h-6 mr-2 ml-2 text-red-600" />
                  {Math.abs(position_difference)}
                </>
              ) : (
                '0'
              )}
            </div>
          </TableDataCell>
          <TableDataCell>
            {search_volume ? formatNumber(search_volume, 1) : 'n/a'}
          </TableDataCell>
          <TableDataCell>
            {number_of_results ? formatNumber(number_of_results, 1) : 'n/a'}
          </TableDataCell>
        </TableDataRow>
      );
    });
  }

  useEffect(() => {
    const storedKeywordsJson = localStorage.getItem(keyKeywords);
    const storedTitleJson = localStorage.getItem(keyTitle);
    const storedRegionJson = localStorage.getItem(keyRegion);
    if (!!storedKeywordsJson) {
      setKeywords(JSON.parse(storedKeywordsJson));
    }
    if (!!storedTitleJson) {
      setTitle(JSON.parse(storedTitleJson));
    }
    if (!!storedRegionJson) {
      setInputRegion(JSON.parse(storedRegionJson));
    }
  }, []);

  useEffect(() => {
    if (!!inputRegion) {
      localStorage.setItem(keyRegion, JSON.stringify(inputRegion));
    }
  }, [inputRegion]);

  useEffect(() => {
    let isCancelled = false;
    if (!!title && !isCancelled) {
      setInputUrl(title.url);
    }

    return () => {
      isCancelled = true;
    };
  }, [title]);

  useEffect(() => {
    let isCancelled = false;

    const storedRegionJson = localStorage.getItem(keyRegion);
    if (!!selectedProject && !isCancelled && !storedRegionJson) {
      if (selectedProject.name === uncategorizedProject) {
        setInputRegion(
          !!databaseRegionFromUserSettings
            ? chooseOption(REGIONS, databaseRegionFromUserSettings)
            : usDatabaseRegion
        );
      } else {
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [selectedProject, databaseRegionFromUserSettings]);

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={
        <PageHeading
          title="📊 Website Keyword Ranking"
          showSemrushBadge={isSemrushConnected}
        />
      }
    >
      <div>
        {request.loading ? (
          <Loading />
        ) : (
          <div>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              This page provides your website top ranked keywords on Google.
              Data is available once the keyword ranking position is in the top
              100.
            </p>
            {/* <div className="rounded-md bg-yellow-50 p-4 mt-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Attention needed
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      This feature will be phased out due to our switch to a new
                      keyword data provider.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <InputAndRegionForm
              inputType="text"
              inputPlaceholder="Enter domain, subdomain or URL"
              inputValue={inputUrl}
              onInputChange={e => setInputUrl(e.target.value?.trim())}
              regionValue={inputRegion}
              onRegionChange={setInputRegion}
              buttonLabel="Search"
              buttonDisabled={!inputUrl || !inputRegion}
              onSubmit={() => {
                loadPerformanceData();
                // TODO: Submit and refresh the table
              }}
            />
            <div className="flex flex-col">
              <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <TableContainer>
                  <Table
                    headers={Object.values(performanceTableHeaders)}
                    renderData={renderTableData}
                  />
                  {isSemrushConnected && !!keywords.length && (
                    <TablePagination
                      startIndex={dataOffset + 1}
                      endIndex={
                        !!keywords.length ? keywords.length + dataOffset : 0
                      }
                      total={keywords.length + dataOffset}
                      isPreviousDisabled={dataOffset === 0}
                      isNextDisabled={
                        !inputUrl || !inputRegion || keywords.length < 10
                      }
                      onPrevious={() => {
                        const newOffset = dataOffset - keywords.length;
                        setDataOffset(newOffset);
                        loadPerformanceData(newOffset);
                      }}
                      onNext={() => {
                        const newOffset = dataOffset + keywords.length;
                        setDataOffset(newOffset);
                        loadPerformanceData(newOffset);
                      }}
                    />
                  )}
                </TableContainer>
                {!isSemrushConnected && !!keywords.length && (
                  <div className="ml-6">
                    <SemrushBadge resourceName={'Top Organic Keywords'} />
                  </div>
                )}
              </div>
            </div>
            {/* Empty state when no result found */}
            {!keywords.length && (
              <div className="flex flex-col items-center my-8">
                <div className="flex flex-col items-center justify-center gap-y-2">
                  <PresentationChartLineIcon className="w-8" />
                  <h1 className="font-bold text-center">
                    We have no data to show.
                  </h1>
                  <p className="text-center">
                    Try entering a different domain, subdomain or URL.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </NewWrapper>
  );
}
