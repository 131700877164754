import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';
import Select from '../../components/form/FormSelect';
import moment from 'moment';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { numberToThousands } from '../../utils/common';
import './WordUsageHistorical.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function WordUsageHistorical({ remainWords }) {
  const request = useSentData();
  const alert = useAlert();
  const [chartData, setChartData] = useState({ datasets: [] });
  const [timeOptionSelected, setTimeOptionSelected] = useState({
    value: '7-d',
    label: 'Last 7 days',
  });
  const [aiWordHistory, setAIWordHistory] = useState([]);
  const [plagiarismWordHistory, setPlagiarismWordHistory] = useState([]);
  const [worldUsage, setWorldUsage] = useState({});
  const [team, setTeam] = useState([]);
  const [memberSelected, setMemberSelected] = useState({});
  const { user } = useSelector(state => state.auth);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const timeOptions = [
    {
      value: '7-d',
      label: 'Last 7 days',
    },
    {
      value: '30-d',
      label: 'Last 30 days',
    },
    {
      value: '3-M',
      label: 'Last 3 months',
    },
    {
      value: '6-M',
      label: 'Last 6 months',
    },
    {
      value: '1-y',
      label: 'Last 1 year',
    },
  ];

  useEffect(() => {
    getTeam();
    getAIWordHistory();
    getPlagiarismWordHistory();
  }, []);

  useEffect(() => {
    getRemainingWord();
  }, [remainWords]);

  useEffect(() => {
    loadChartData();
  }, [aiWordHistory, plagiarismWordHistory]);

  useEffect(() => {
    getAIWordHistory();
    getPlagiarismWordHistory();
  }, [timeOptionSelected, memberSelected]);

  const loadChartData = async () => {
    const aiWordData = getDisplayDataByTime(aiWordHistory, 'token');
    const plagiarismWorData = getDisplayDataByTime(
      plagiarismWordHistory,
      'words'
    );

    const data = {
      datasets: [
        {
          label: 'AI Word',
          data: aiWordData,
          backgroundColor: '#24cecf',
        },
        {
          label: 'Plagiarism Word',
          data: plagiarismWorData,
          backgroundColor: '#ffbe0b',
        },
      ],
    };

    setChartData(data);
  };

  const getAIWordHistory = async () => {
    const { amount, unit } = getTimeAmountAndUnit();
    const params = {
      user: memberSelected.value,
      from_date: moment()
        .subtract(amount, unit)
        .format('yyyy-MM-DD'),
      to_date: moment()
        .add(1, 'd')
        .format('yyyy-MM-DD'),
    };
    const res = await request.send('/api/ai-history', null, 'get', params);
    if (!res.isAxiosError) {
      setAIWordHistory(res);
    } else {
      alert.error(isolateError(res));
    }
  };

  const getPlagiarismWordHistory = async () => {
    const { amount, unit } = getTimeAmountAndUnit();
    const params = {
      user: memberSelected.value,
      from_date: moment()
        .subtract(amount, unit)
        .format('yyyy-MM-DD'),
      to_date: moment()
        .add(1, 'd')
        .format('yyyy-MM-DD'),
    };
    const res = await request.send(
      '/api/plagiarism-history',
      null,
      'get',
      params
    );
    if (!res.isAxiosError) {
      setPlagiarismWordHistory(res);
    } else {
      alert.error(isolateError(res));
      return [];
    }
  };

  const getRemainingWord = () => {
    if (remainWords) {
      const {
        available_words,
        additional_words,
        plan_ai_word_limit,
        addon_ai_word_limit,
        plagiarism_word_limit,
        additional_plagiarism_words,
        plan_plagiarism_word_limit,
        addon_plagiarism_word_limit,
      } = remainWords;

      const totalAIWords = user.has_trial_subscription
        ? 3000
        : plan_ai_word_limit + addon_ai_word_limit;
      const usageAIWords = totalAIWords - (available_words + additional_words);
      const totalPlagiarismWords =
        plan_plagiarism_word_limit + addon_plagiarism_word_limit;
      const usagePlagiarismWords =
        totalPlagiarismWords -
        (plagiarism_word_limit + additional_plagiarism_words);

      const totalAIPercent = +((100 * usageAIWords) / totalAIWords);
      const totalPlagiarismPercent = +(
        (100 * usagePlagiarismWords) /
        totalPlagiarismWords
      );

      setWorldUsage({
        usageAIWords,
        totalAIWords,
        usagePlagiarismWords,
        totalPlagiarismWords,
        totalAIPercent,
        totalPlagiarismPercent,
      });
    }
  };

  const getTeam = async () => {
    const res = await request.send(`api/team`, null, 'get');
    if (!res.isAxiosError) {
      const members = res.map(item => ({
        value: item.member.id,
        label: item.member.email,
      }));
      setTeam([{ value: 'all', label: 'All' }, ...members]);
      setMemberSelected(members[0]);
    } else {
      alert.error(isolateError(res));
    }
  };

  const getDisplayDataByTime = (data, key) => {
    const { amount, unit } = getTimeAmountAndUnit();
    const startDate = moment().subtract(amount, unit);
    const labels = generateLabel(startDate, moment(), unit);
    return labels.map(label => {
      const format = unit === 'y' ? 'MM YYYY' : 'DD MMM';
      return {
        x: moment(label).format(format),
        y: data
          .filter(
            item =>
              moment(item.created).format(format) ===
              moment(label).format(format)
          )
          .reduce((total, item) => (total += item[key]), 0),
      };
    });
  };

  const generateLabel = (startDate, endDate, unit) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    const type = unit === 'y' ? 'months' : 'days';
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type));
    }
    return range;
  };

  const onChangeTimeOption = value => {
    setTimeOptionSelected(value);
  };

  const getTimeAmountAndUnit = () => {
    const amount = +timeOptionSelected.value.split('-')[0];
    const unit = timeOptionSelected.value.split('-')[1];
    return { amount, unit };
  };

  return !user.is_viewer ? (
    <div className="mb-10 word-usage-historical" style={{ width: '75%' }}>
      <h4>Usage History </h4>
      <div className="flex">
        <Select
          className="flex-1"
          options={timeOptions}
          value={timeOptionSelected}
          label="Date"
          onChange={onChangeTimeOption}
        />
        {user.is_owner || user.is_manager ? (
          <Select
            className="flex-1 ml-3"
            options={team}
            value={memberSelected}
            label="Member"
            onChange={setMemberSelected}
          />
        ) : (
          ''
        )}
      </div>
      <Bar type="bar" options={chartOptions} data={chartData} />
      <h4 className="mt-10">Monthly Usage Limits</h4>
      <div className="flex justify-between">
        <b>AI words</b>
        <div>
          {numberToThousands(worldUsage?.usageAIWords)} of{' '}
          {worldUsage?.totalAIWords >= 1000000
            ? 'Unlimited'
            : numberToThousands(worldUsage?.totalAIWords)}{' '}
          words used
        </div>
      </div>
      <LinearProgress
        className="mb-10"
        variant="determinate"
        value={worldUsage?.totalAIPercent || 0}
        style={{ height: '10px', borderRadius: '10px' }}
      />
      {!user.has_trial_subscription ? (
        <>
          <div className="flex justify-between">
            <b>Plagiarism words</b>
            <div>
              {numberToThousands(worldUsage?.usagePlagiarismWords)} of{' '}
              {numberToThousands(worldUsage?.totalPlagiarismWords)} words used
            </div>
          </div>
          <LinearProgress
            className="mb-3"
            variant="determinate"
            value={worldUsage?.totalPlagiarismPercent || 0}
            style={{ height: '10px', borderRadius: '10px' }}
          />
        </>
      ) : null}
    </div>
  ) : (
    ''
  );
}

export default WordUsageHistorical;
