import { useCallback, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Status from './status/status';
import PrimaryButton from '../../buttons/PrimaryButton';
import {
  projectPostStatusProp,
  getProjectPostStatuses,
  createProjectPostStatus,
  deleteProjectPostStatus,
  updateProjectPostStatus,
  updateProjectPostStatusesOrder,
} from '../../../utils/post-status';
import useSentData from '../../../services/useSentData';

const CustomStatuses = ({ projectId = '' }) => {
  const [inputValue, setInputValue] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const request = useSentData();
  const user = useSelector(state => state.auth.user);

  const addStatus = useCallback(async () => {
    const existOrders = statuses.map(stat => stat.order);
    existOrders.sort();
    const order = existOrders[existOrders.length - 1] + 1;
    const res = await createProjectPostStatus(
      request,
      projectId,
      inputValue,
      order
    );
    if (!res.isAxiosError) {
      setStatuses(pre => [...pre, res]);
      setInputValue('');
    } else {
      const messages = res?.response?.data || [];
      if (!!messages.length) {
        messages.forEach(msg => alert.error(msg));
      }
    }
  }, [request, projectId, inputValue, statuses]);

  const removeStatus = useCallback(
    async statusId => {
      setLoading(true);
      const res = await deleteProjectPostStatus(request, projectId, statusId);
      if (!res.isAxiosError) {
        setStatuses(preState => {
          const draft = [...preState];
          draft.splice(
            statuses.findIndex(stat => stat.id === statusId),
            1
          );
          return draft;
        });
      } else {
        const messages = res?.response?.data || [];
        if (!!messages.length) {
          messages.forEach(msg => alert.error(msg));
        }
      }
      setLoading(false);
    },
    [statuses, request, projectId]
  );

  const changeStatusIsActive = async (status, isActive) => {
    setLoading(true);
    const res = await updateProjectPostStatus(request, projectId, status.id, {
      ...status,
      [projectPostStatusProp.is_active]: isActive,
    });
    if (!res.isAxiosError) {
      setStatuses(preState => {
        const draft = [...preState];
        const index = draft.findIndex(ele => ele.id === status.id);
        draft[index][projectPostStatusProp.is_active] = isActive;
        return draft;
      });
    } else {
      const messages = res?.response?.data || [];
      if (!!messages.length) {
        messages.forEach(msg => alert.error(msg));
      }
    }
    setLoading(false);
  };

  const onDragEnd = useCallback(
    async droppedItem => {
      if (!droppedItem.destination || !user?.custom_post_status) return;
      const updatedList = [...statuses];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      const reorderedStatuses = updatedList.map((item, index) => ({
        ...item,
        order: index,
      }));
      try {
        setLoading(true);
        setStatuses(reorderedStatuses);
        await updateProjectPostStatusesOrder(
          request,
          projectId,
          reorderedStatuses
        );
      } catch (err) {
        setStatuses(updatedList);
        alert.error(err.message);
      }
      setLoading(false);
    },
    [statuses]
  );

  useEffect(() => {
    if (!!projectId) {
      setLoading(true);
      getProjectPostStatuses(request, projectId)
        .then(data => {
          setStatuses(data);
        })
        .catch(err => {
          alert.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [projectId]);

  return (
    <div>
      <h4>
        Post Statuses{' '}
        {!user?.custom_post_status && (
          <span>
            <Link
              className="ml-2 text-xs font-medium text-purple-600 hover:text-purple-500"
              to={
                user.app_sumo_subscription
                  ? [
                      'bramework_tier1',
                      'bramework_tier2',
                      'bramework_tier3',
                      'bramework_tier4',
                    ].includes(user.app_sumo_subscription.plan.app_sumo_plan)
                    ? '/appsumo-plan'
                    : user.app_sumo_subscription.plan.app_sumo_plan ===
                      'bramework_ltd1'
                    ? '/bramework-ltd-plan'
                    : user.app_sumo_subscription.plan.app_sumo_plan ===
                      'free_user'
                    ? '/account'
                    : '/plans'
                  : '/plans'
              }
            >
              Upgrade to create custom post status
            </Link>
          </span>
        )}{' '}
        {loading && (
          <svg
            width="16"
            height="16"
            fill="currentColor"
            class="mr-2 animate-spin inline-block"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
          </svg>
        )}
      </h4>
      {user?.custom_post_status && (
        <div className="flex items-end gap-x-2 my-4">
          <input
            type="text"
            value={inputValue}
            className="block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
            onChange={e => setInputValue(e.target.value)}
            placeholder="Enter a status"
          />
          <PrimaryButton
            onClick={e => {
              e.preventDefault();
              addStatus();
            }}
          >
            Add
          </PrimaryButton>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list-container">
          {provided => (
            <div
              className="my-4 flex flex-col"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {statuses.map((stat, index) => (
                <Draggable
                  key={stat.id}
                  isDragDisabled={!user?.custom_post_status}
                  draggableId={stat.id.toString()}
                  index={index}
                >
                  {provided => (
                    <Status
                      value={stat.status}
                      onRemove={() => removeStatus(stat.id)}
                      isActive={stat.is_active}
                      onIsActiveChange={val => changeStatusIsActive(stat, val)}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    />
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomStatuses;
