import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { saveSemrushTokenAPI } from '../../utils/semrush';
import useSentData from '../../services/useSentData';
import { updateUser } from '../../store/actions/auth.actions';

import SemrushFullLogo from '../../styles/images/logo-semrush-full.svg';
import SemrushLogo from '../../styles/images/logo-semrush.svg';

const SemrushBadge = ({ resourceName = '' }) => {
  const history = useHistory();
  const request = useSentData();
  const isSemrushConnected = useSelector(
    state => state.auth.user.isSemrushConnected
  );
  const dispatch = useDispatch();

  const openPopUp = () => {
    var win = window.open(
      'https://oauth.semrush.com/oauth2/authorize?ref=4922222611&client_id=bramework&redirect_uri=/oauth2/bramework/success&response_type=code&scope=user.id%20domains.info%20projects.info%20positiontracking.info',
      'SEMRush',
      'width = 600, height = 400'
    );

    const eventHandler = async event => {
      if (event.data?.type == 'semrush:oauth:success') {
        const url = new URL(event.data.url);
        const code = url.searchParams.get('code');
        try {
          await saveSemrushTokenAPI(request, code);
        } catch (err) {
          console.error(err);
        }
        dispatch(updateUser({ isSemrushConnected: true }));

        setTimeout(() => {
          win.close();
        }, 500);
      }
      if (event.data?.type == 'semrush:oauth:denied') {
        setTimeout(() => {
          win.close();
        }, 500);
      }
      window.removeEventListener('message', eventHandler);
    };

    window.addEventListener('message', eventHandler, false);
  };

  return (
    <Fragment>
      {!isSemrushConnected && (
        <div className="flex items-center">
          <img src={SemrushLogo} className="h-8" />
          <p>
            <span
              className="text-purple-600 hover:text-purple-700 underline cursor-pointer"
              onClick={() => {
                openPopUp();
              }}
            >
              Connect your Semrush account
            </span>{' '}
            to get more <i>{resourceName}</i>
          </p>
        </div>
      )}
      {isSemrushConnected && (
        <div
          className="flex items-center bg-purple-600 rounded-md px-4 cursor-pointer"
          onClick={() => {
            history.push('/integrations');
          }}
        >
          <img src={SemrushFullLogo} className="h-8" />
          <p className="text-white text-sm font-medium">connected</p>
        </div>
      )}
    </Fragment>
  );
};

export default SemrushBadge;
