import { generateFirstDraftActionTypes } from '../reducers/generate-first-draft';

export const GENERATE_FIRST_DRAFT_FIELDS = {
  primary_topic: 'primary_topic',
  title: 'title',
  outline: 'outline',
  tone: 'tone',
  audience: 'audience',
  outline_id: 'outline_id',
  instructions: 'instructions',
  url: 'url',
  brand_voice: 'brand_voice',
};

const SET_GENERATE_FIRST_DRAFT_ACTIONS = {
  [GENERATE_FIRST_DRAFT_FIELDS.primary_topic]:
    generateFirstDraftActionTypes.SET_PRIMARY_TOPIC,
  [GENERATE_FIRST_DRAFT_FIELDS.title]: generateFirstDraftActionTypes.SET_TITLE,
  [GENERATE_FIRST_DRAFT_FIELDS.outline]:
    generateFirstDraftActionTypes.SET_OUTLINE,
  [GENERATE_FIRST_DRAFT_FIELDS.tone]: generateFirstDraftActionTypes.SET_TONE,
  [GENERATE_FIRST_DRAFT_FIELDS.audience]:
    generateFirstDraftActionTypes.SET_AUDIENCE,
  [GENERATE_FIRST_DRAFT_FIELDS.outline_id]:
    generateFirstDraftActionTypes.SET_OUTLINE_ID,
  [GENERATE_FIRST_DRAFT_FIELDS.instructions]:
    generateFirstDraftActionTypes.SET_INSTRUCTIONS,
  [GENERATE_FIRST_DRAFT_FIELDS.url]: generateFirstDraftActionTypes.SET_URL,
  [GENERATE_FIRST_DRAFT_FIELDS.brand_voice]:
    generateFirstDraftActionTypes.SET_BRAND_VOICE,
};

export const setOption = (option, value) => {
  return {
    type: generateFirstDraftActionTypes.SET_OPTION,
    payload: { option, value },
  };
};

export const setGenerateFirstDraftField = (field, value) => {
  return {
    type: SET_GENERATE_FIRST_DRAFT_ACTIONS[field],
    payload: value,
  };
};

export const setFirstDraft = value => {
  return {
    type: generateFirstDraftActionTypes.SET_DRAFT,
    payload: value,
  };
};

export const setBrandVoice = value => {
  return {
    type: generateFirstDraftActionTypes.SET_BRAND_VOICE,
    payload: value,
  };
};
