import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import './PostPreview.scss';
import PreviewBlock from './PostPreview/PreviewBlock';
import Loading from '../components/Loading';
import { track } from '../services/track';
import { connect } from 'react-redux';
import DeletePostModal from './Dashboard/DeletePostModal';

import {
  addPostTitle,
  addPostId,
  addPostVersions,
  addPostVersionIndex,
  addPostVersionId,
  addGeneratedPostTitles,
  addGeneratedPostOutlines,
  addSelectedKeywords,
  addInstructions,
} from '../store/actions/post_builder.actions';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  LogoutIcon,
  PencilIcon,
  DownloadIcon,
  TrashIcon,
} from '@heroicons/react/outline';

function PostPreview({ user }) {
  const { id, project_id } = useParams();
  const history = useHistory();
  const [blocks, setBlocks] = useState([]);
  const [isModalVisible, showModal] = useState(false);
  const versions = useSelector(state => state.post.versions);
  const base_url = process.env.REACT_APP_BACKEND_HOST;
  const dispatch = useDispatch();
  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    if (id) {
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setBlocks(data.body || []);
          } else {
            alert.error(isolateError(data));
          }
        });
      // track('Entered blog post preview');
    }

    window.addEventListener('scroll', event => {
      const scrollTop = event.target.documentElement.scrollTop;
      if (scrollTop > 140) {
        event.target.body.classList.add('y-scrolling');
      } else {
        event.target.body.classList.remove('y-scrolling');
      }
    });
  }, []);

  function submitPost(data) {
    let url = `/api/projects/${project_id}/posts`;

    if (id) {
      url += `/${id}`;
      let method = 'put';

      request.send(url, data, method).then(data => {
        if (!data.isAxiosError) {
        } else {
          alert.error(isolateError(data));
        }
      });
    } else {
      alert.error('Post id does not exist!');
    }
  }

  function closeModal(deleted) {
    if (deleted === true) {
      history.push(`/project/${project_id}/drafts`);
      dispatch(addPostTitle(''));
      dispatch(addPostId(null));
      dispatch(addPostVersionIndex(null));
      dispatch(addPostVersionId(null));
      dispatch(addPostVersions([]));
      dispatch(addGeneratedPostTitles([]));
      dispatch(addGeneratedPostOutlines([]));
      dispatch(addSelectedKeywords(''));
      dispatch(addInstructions(''));
    }
    showModal(false);
  }

  function actionButtonGroup() {
    return (
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        {(user.is_owner || user.is_manager || user.is_editor) && (
          <>
            <span className="hidden sm:block">
              <Link
                to={`/project/${project_id}/post-builder/${id}`}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <PencilIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                Edit
              </Link>
            </span>

            <span className="hidden sm:block ml-3">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={() => {
                  showModal(true);
                }}
              >
                <TrashIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                Delete
              </button>
            </span>
          </>
        )}

        <span className="hidden sm:block ml-3">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            onClick={() => {
              dispatch(addPostTitle(''));
              dispatch(addPostId(null));
              dispatch(addPostVersionIndex(null));
              dispatch(addPostVersionId(null));
              dispatch(addPostVersions([]));
              dispatch(addGeneratedPostTitles([]));
              dispatch(addGeneratedPostOutlines([]));
              dispatch(addSelectedKeywords(''));
              dispatch(addInstructions(''));
              history.push(`/project/${project_id}/drafts`);
            }}
          >
            <LogoutIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            Exit
          </button>
        </span>

        {/* Download Dropdown */}
        {(user.is_owner || user.is_manager || user.is_editor) && (
          <span className="sm:ml-3">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                  <DownloadIcon
                    className="-ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  Download
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            track('Blog post preview', { Download: 'html' });
                          }}
                          href={`${base_url}/api/export-html/${id}`}
                          download
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          HTML
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            track('Blog post preview', {
                              'Download filetype': 'pdf',
                            });
                          }}
                          href={`${base_url}/api/export-pdf/${id}`}
                          download
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          PDF
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            track('Blog post preview', {
                              'Download filetype': 'docx',
                            });
                          }}
                          href={`${base_url}/api/export-docx/${id}`}
                          download
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          DOCX
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            track('Blog post preview', {
                              'Download filetype': 'txt',
                            });
                          }}
                          href={`${base_url}/api/export-txt/${id}`}
                          download
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          TXT
                        </a>
                      )}
                    </Menu.Item>
                    {versions && versions.length > 0 && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              track('Blog post preview', {
                                'Download filetype': 'versions',
                              });
                            }}
                            href={`${base_url}/api/export-versions/${id}`}
                            download
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            VERSIONS
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </span>
        )}

        {/* More Dropdown */}
        <Menu as="span" className="ml-3 relative sm:hidden">
          <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            More
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {(user.is_owner || user.is_manager || user.is_editor) && (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 my-custom-link-class'
                        )}
                        to={`/project/${project_id}/post-builder/${id}`}
                      >
                        Edit
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                        onClick={() => {
                          showModal(true);
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                    onClick={() => {
                      dispatch(addPostTitle(''));
                      dispatch(addPostId(null));
                      dispatch(addPostVersionIndex(null));
                      dispatch(addPostVersionId(null));
                      dispatch(addPostVersions([]));
                      dispatch(addGeneratedPostTitles([]));
                      dispatch(addGeneratedPostOutlines([]));
                      dispatch(addSelectedKeywords(''));
                      dispatch(addInstructions(''));
                      history.push(`/project/${project_id}/drafts`);
                    }}
                  >
                    Exit
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  }

  return (
    <div className="post-preview-component">
      <DeletePostModal
        id={id}
        name="post"
        projectId={project_id}
        category="post"
        open={isModalVisible}
        setOpen={closeModal}
      />
      <div className="preview-header-wrapper">
        <h1 className="preview-header">Preview</h1>
      </div>
      <div className="header-wrapper">{actionButtonGroup()}</div>
      {request.loading && <Loading />}
      <div className="preview-post-wrapper">
        <div className="post-content-wrapper">
          {blocks.map((block, i) => (
            <PreviewBlock block={block} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(PostPreview);
