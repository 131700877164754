import './DashboardItem.scss';
import classNames from 'classnames';
import Moment from 'react-moment';
import { formatNumber, truncateParagraph } from '../../utils/common';
import { useHistory, useParams } from 'react-router-dom';
import {
  competitionComplexity,
  difficultyComplexity,
  resultsComplexity,
  volumeComplexity,
} from '../../utils/keywords';
import { getPostProgress } from '../../utils/PostBuilderUtils';
import store from '../../store';
import { getDatabaseRegion } from '../../components/keywords/utils';
import DashboardItemMenu from '../../components/dashboard/dashboard-item-menu';
import { categories } from '../../components/dashboard/_utils';
import { Checkbox } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

function Tile({ title, value, color }) {
  return (
    <div className={classNames('tile-data', color)}>
      <div className="tile-title">{title}</div>
      <div className="tile-value">{formatNumber(value, 1)}</div>
    </div>
  );
}

export default function DashboardItem({
  id,
  name,
  date,
  url,
  thumbnail,
  body,
  results,
  difficulty,
  competition,
  volume,
  category,
  published,
  generations,
  callback,
  post,
  postStatuses = [],
  isSelected,
  onToggleSelectItem,
  isBulkSelect,
}) {
  const state = store.getState();
  const user = state.auth.user;
  let history = useHistory();
  const { project_id } = useParams();

  // Check if "keyword", will update with enum after merge another branch
  const showRegion = post.category === 0;
  const region = getDatabaseRegion(post?.analysis_data?.database);

  function editPost() {
    if (category === 1) {
      if (user.is_owner || user.is_manager || user.is_editor) {
        history.push(`/project/${project_id}/post-builder/${id}`);
      } else {
        history.push(`/project/${project_id}/post-preview/${id}`);
      }
    } else if (category === 2) {
      history.push(`/project/${project_id}/title-generator/${id}`);
    } else if (category === 3) {
      history.push(`/project/${project_id}/outline-generator/${id}`);
    } else if (category === 4) {
      history.push(`/project/${project_id}/rewrite-generator/${id}`);
    } else if (category === 5) {
      history.push(`/project/${project_id}/summary-generator/${id}`);
    } else {
      history.push(`/project/${project_id}/keyword-analyzer/${id}`, {
        page: 'analyzer',
      });
    }
  }

  let itemProgress;
  let itemColor;
  if (body !== null) {
    const { progress, color } = getPostProgress(body);
    itemProgress = progress;
    itemColor = color;
  }

  const renderStatusSection = () => {
    let status;
    switch (category) {
      case categories.post:
        const postStatus = postStatuses.find(val => val.id === post.status);
        status = post.first_draft_completed
          ? postStatus?.status || 'No status'
          : 'Writing First Draft';
        break;
      case categories.titles:
        status = `${generations.length} titles`;
        break;
      case categories.outlines:
        status = `${generations.length} outlines`;
        break;
      case categories.rewrites:
        status = `${generations?.length || 0} rewrites`;
        break;
      case categories.summaries:
        status = `${generations?.length || 0} summaries`;
        break;
      case categories.keyword:
      default:
        status = 'Keywords';
    }

    return (
      <div
        className={classNames('progress-title', {
          [`progress-title-draft`]: status !== 'Published',
        })}
      >
        {status}
      </div>
    );
  };

  const onChangeSelected = e => {
    onToggleSelectItem(id, e.target.checked);
  };

  const rowClass =
    post.first_draft_completed && category === 1 ? 'enabled' : 'disabled';
  const disabled = !post.first_draft_completed && category === 1;
  const cursorStyle = disabled ? 'not-allowed' : 'auto';

  return (
    <div
      className={classNames('dashboard-item-component', rowClass)}
      style={{ cursor: cursorStyle }}
    >
      <div className={isSelected ? 'item-selected' : ''}>
        {isBulkSelect && (
          <Checkbox
            checked={isSelected}
            onChange={onChangeSelected}
            className="custom-checkbox dashboard-item-checkbox"
          />
        )}
        {category ? (
          <img
            className={`no-image ${
              disabled ? 'opacity-50 pointer-events-none' : ''
            }`}
            src={thumbnail}
            alt=""
            onClick={disabled ? null : editPost}
          />
        ) : (
          <div className="tiles" onClick={disabled ? null : editPost}>
            <Tile
              title="Volume"
              value={volume}
              color={volumeComplexity(volume).color}
            />
            <Tile
              title="Competition"
              value={competition}
              color={competitionComplexity(competition).color}
            />
            <Tile
              title="Difficulty"
              value={difficulty}
              color={difficultyComplexity(difficulty).color}
            />
            <Tile
              title="Results"
              value={results}
              color={resultsComplexity(results).color}
            />
          </div>
        )}
        <div className="info-wrapper">
          <div className="info-data">
            <div>
              <Tooltip title={truncateParagraph(name, 30)} arrow>
                <div className="name">{name}</div>
              </Tooltip>
              <div className="date">
                {`Last  ${category ? 'edit' : 'search'} `}
                <Moment fromNow>{date}</Moment>
              </div>
            </div>
            <div className="dropdown">
              <button
                className="clear-button"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
              {/* <div className="dropdown-menu menu-wrapper">
              {category === 1 ? (
                <div>
                  <button
                    onClick={() => {
                      history.push(`/post-preview/${id}`);
                    }}
                  >
                    Preview
                  </button>
                  <button
                    onClick={() => {
                      history.push(`/keyword-analyzer/${id}`);
                    }}
                  >
                    Analyzer
                  </button>
                </div>
              ) : (
                <div />
              )}
              <button onClick={() => showModal(true)}>Delete</button>
            </div> */}

              {disabled ? (
                <div class="inline-flex items-center justify-center">
                  <div class="loader animate-spin rounded-full h-6 w-6 border-t-2 border-transparent border-b-2 border-gray-400"></div>
                </div>
              ) : (
                <DashboardItemMenu
                  category={category}
                  name={name}
                  id={id}
                  status={post.status}
                  callback={callback}
                />
              )}
            </div>
          </div>
          <div className="progress-data flex items-center">
            {renderStatusSection()}
            {showRegion && (
              <p className="progress-title progress-title-draft">
                {region?.label}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
