import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import './IntegrationsPlatform.scss';
import {
  getPlatformUser,
  getPlatformList,
} from '../../store/actions/platform.actions';
import AddPlatform from './AddPlatform';
import DeletePlatformModal from './DeletePlatformModal';
import PlatformItem from './PlatformItem';
import { LinkIcon } from '@heroicons/react/solid';
import SEMRushConnect from '../SEMRushIntegration';
import GoogleAuthRedirect from './GoogleAuthRedirect';

const IntegrationsPlatform = ({ user }) => {
  const dispatch = useDispatch();
  const platformListUser = useSelector(
    state => state.platform.platformListUser
  );
  const auth = useSelector(state => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [removingItem, setRemovingItem] = useState({});

  useEffect(() => {
    dispatch(getPlatformUser());
    dispatch(getPlatformList());
  }, []);

  return (
    <div className="posts-list">
      {user.is_owner ? (
        <>
          <div className="justify-content-between add-platform align-items-center">
            <div className="">
              {/* <div
                className="cursor-pointer mb-2 w-full inline-flex justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenModal(true)}
              >
                <LinkIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Connect WordPress
              </div> */}
              <a
                className="cursor-pointer mb-4 w-full inline-flex justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
                href="https://www.bramework.com/bramework-wordpress-plugin/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Connect WordPress
              </a>
            </div>
            <SEMRushConnect />
            {/* <GoogleAuthRedirect /> */}
          </div>
          {platformListUser.length ? (
            <div className="flex flex-col">
              <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Connected to
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {platformListUser.map((item, i) => (
                          <PlatformItem
                            key={i}
                            data={item}
                            setOpenModalDelete={setOpenModalDelete}
                            setOpenModal={setOpenModal}
                            deleteItem={() => {
                              setOpenModalDelete(true);
                              setRemovingItem(item);
                            }}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="empty-message">You have no connected websites.</h1>
          )}
          {openModal && (
            <AddPlatform
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
              userId={auth.user.id}
            />
          )}
          <DeletePlatformModal
            isOpenModalDelete={openModalDelete}
            closeModalDelete={() => {
              setRemovingItem({});
              setOpenModalDelete(false);
            }}
            setOpenModalDelete={setOpenModalDelete}
            removingItem={removingItem}
          />
        </>
      ) : (
        <h1 className="empty-message">
          You are not authorized to view this page.
        </h1>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(IntegrationsPlatform);
