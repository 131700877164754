import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './Plan.scss';
import backButton from '../../styles/images/back_button.svg';
import { updateUser, authLogout } from '../../store/actions/auth.actions';
import { destroyProjects } from '../../store/actions/project.action';
import { destroyTeam } from '../../store/actions/team.actions';
import NewWrapper from '../../components/NewWrapper';
import NewHeader from '../../components/NewHeader';
import AddonSection from './AddonSection';
import AddonPlans from './AddonPlans';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAlert } from 'react-alert';
import http from '../../services/http';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PLAN_INCLUDES = [
  'Unlimited Keyword Searches',
  'Plagiarism checker (20,000 words per month)',
  'Copyright free images (by Unsplash)',
  'WordPress integration',
  'Team Management',
  'Export blogs (PDF, HTML, TXT, DOCX)',
  'Quick Draft',
  'Nova Chat',
  'Title generator',
  'Outline generator',
  'Paragraph generator',
  'Write and save AI Commands',
  'Rewrite & Summarize',
  'Steps & Listicles',
  'Ability to create expanded FAQ-style outlines',
  'Personalized blog post status settings',
  'Website Keyword Ranking Tool',
  'Email & live chat support',
];

const PLAN_DETAILS = [
  {
    id: 'bramework_tier1',
    name: 'AppSumo Tier 1',
    words: '40,000',
    users: '2',
  },
  {
    id: 'bramework_tier2',
    name: 'AppSumo Tier 2',
    words: '100,000',
    users: '5',
  },
  {
    id: 'bramework_tier3',
    name: 'AppSumo Tier 3',
    words: '200,000',
    users: '10',
  },
  {
    id: 'bramework_tier4',
    name: 'AppSumo Tier 4',
    words: '500,000',
    users: '20',
  },
];

const APPSUMO_LINK = 'https://appsumo.com/products/bramework/';

function AppSumoPlan({
  user,
  updateUser,
  authLogout,
  destroyProjects,
  destroyTeam,
}) {
  const [isShowAllFeatures, setShowAllFeatures] = useState(true);
  const [localUser, setLocalUser] = useState(user);
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    checkStripeStatus();
  }, []);

  useEffect(() => {
    setLocalUser(user);
  }, [user]);

  const checkStripeStatus = () => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    if (sessionId) {
      alert.success(
        'Your payment was successful! Your subscription has been updated.'
      );
      refreshUserData();
    }
  };

  const refreshUserData = () => {
    http.get(`/api/users/${user.id}`).then(userRes => {
      const data = userRes.data;
      if (!data.isAxiosError) {
        dispatch(updateUser(data));
        setLocalUser(data);
      }
    });
  };

  function logoutHandler() {
    destroyProjects();
    destroyTeam();
    authLogout();
  }

  function onToggleShowAllFeatures() {
    setShowAllFeatures(prevState => !prevState);
  }

  function renderPlanDescription(plan) {
    return (
      <>
        <div className="flex items-center gap-x-2 my-3">
          <div>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.37288 9.00107L7.52157 11.1498L11.819 6.85238M15.7583 9.00107C15.7583 12.9567 12.5516 16.1634 8.59592 16.1634C4.64028 16.1634 1.43359 12.9567 1.43359 9.00107C1.43359 5.04543 4.64028 1.83875 8.59592 1.83875C12.5516 1.83875 15.7583 5.04543 15.7583 9.00107Z"
                stroke="#883AE1"
                strokeWidth="1.40104"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="plan-description">
            {plan.words} AI-generated words per month
          </div>
        </div>
        <div className="flex items-center gap-x-2 my-3">
          <div>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.37288 9.00107L7.52157 11.1498L11.819 6.85238M15.7583 9.00107C15.7583 12.9567 12.5516 16.1634 8.59592 16.1634C4.64028 16.1634 1.43359 12.9567 1.43359 9.00107C1.43359 5.04543 4.64028 1.83875 8.59592 1.83875C12.5516 1.83875 15.7583 5.04543 15.7583 9.00107Z"
                stroke="#883AE1"
                strokeWidth="1.40104"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="plan-description">{plan.users} users</div>
        </div>
      </>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <NewWrapper header={<NewHeader />}>
        <div className="plans-wrapper has-account-access mb-6">
          {/* <button
        className="logout float-right text-purple-500 hover:text-purple-700 underline"
        onClick={logoutHandler}
      >
        Log out
      </button>
      <Link className="clear-button" to="/account">
        <img src={backButton} alt="" />
      </Link> */}
          <h1 className="plan-title">Your AppSumo Plan</h1>
          <div className="plans-list">
            {PLAN_DETAILS.map(plan => (
              <div className="plan" key={plan.id}>
                <div className="plan-badge">{plan.name}</div>
                <div className="my-3">{renderPlanDescription(plan)}</div>
                <div className="btn-wrapper">
                  {user.app_sumo_subscription?.plan?.app_sumo_plan ===
                  plan.id ? (
                    <button className="w-full cursor-default flex justify-center py-2 px-4 border border-purple-600 rounded-lg shadow-sm text-md font-medium text-purple-600 bg-white">
                      Current
                    </button>
                  ) : (
                    <a
                      href={APPSUMO_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-md font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500"
                    >
                      {PLAN_DETAILS.findIndex(p => p.id === plan.id) >
                      PLAN_DETAILS.findIndex(
                        p =>
                          p.id ===
                          user.app_sumo_subscription?.plan?.app_sumo_plan
                      )
                        ? 'Upgrade'
                        : 'Downgrade'}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          <AddonSection user={user} updateUser={updateUser} />
          <AddonPlans user={localUser} />
          <div className="py-3 rounded-xl mx-auto mt-10 border border-gray-200 w-11/12 md:w-1/3 flex flex-col items-center">
            <button
              onClick={onToggleShowAllFeatures}
              className="flex items-center mt-3 p-2 rounded-md border border-gray-200 hover:bg-gray-200"
            >
              {isShowAllFeatures ? 'Hide' : 'View'} all the features
              <span
                className={classNames(
                  isShowAllFeatures && 'rotate-180',
                  'ml-2'
                )}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00008 3.33398L8.00008 12.6673M8.00008 12.6673L12.6667 8.00065M8.00008 12.6673L3.33342 8.00065"
                    stroke="#3C4149"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
            <div
              className={classNames(
                isShowAllFeatures && 'show',
                'mt-3 all-appsumo-features px-4 md:px-0'
              )}
            >
              <h3 className="font-bold">All AppSumo Plans Include:</h3>
              {PLAN_INCLUDES.map((item, index) => (
                <div key={index} className="flex items-center gap-x-3">
                  <div>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.37288 9.00107L7.52157 11.1498L11.819 6.85238M15.7583 9.00107C15.7583 12.9567 12.5516 16.1634 8.59592 16.1634C4.64028 16.1634 1.43359 12.9567 1.43359 9.00107C1.43359 5.04543 4.64028 1.83875 8.59592 1.83875C12.5516 1.83875 15.7583 5.04543 15.7583 9.00107Z"
                        stroke="#883AE1"
                        strokeWidth="1.40104"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </NewWrapper>
    </Elements>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user || {},
  };
}

export default connect(mapStateToProps, {
  updateUser,
  authLogout,
  destroyProjects,
  destroyTeam,
})(AppSumoPlan);
