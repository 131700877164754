import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addPostTitle } from '../store/actions/post_builder.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../store/reducers/nova_onboarding.reducer';
import {
  setNovaOnBoardingCheckList,
  setNovaOnBoardingStep,
} from '../store/actions/nova_onboarding.action';
import { sidePanelTypes } from '../store/actions/side_panel.action';

const PostTitle = React.forwardRef(props => {
  const dispatch = useDispatch();
  const postTitle = useSelector(state => state.post.title);
  const isPanelCollapsed = useSelector(state => state.panel.isPanelCollapsed);
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );

  useEffect(() => {
    props.state.text && !postTitle && dispatch(addPostTitle(props.state.text));
    onPostTitleChange();
  }, [props.state.text]);

  useEffect(() => {
    setTimeout(() => {
      onPostTitleChange();
    }, 500);
  }, [isPanelCollapsed]);

  function onPostTitleChange() {
    const postTitleElement = document.getElementById('post-title');
    const iframe = document.getElementsByClassName('tox-edit-area__iframe');
    if (!iframe.length) {
      return;
    }
    const editor = iframe[0].contentWindow.document.getElementById('tinymce');
    const postTitleElementReact = postTitleElement.getBoundingClientRect();
    const iframeRect = iframe[0].getBoundingClientRect();
    const editorRect = editor.getBoundingClientRect();
    const paddingTop =
      postTitleElementReact.bottom - (iframeRect.top + editorRect.top);
    editor.style.paddingTop = `${paddingTop > 0 ? paddingTop : 0}px`;
  }

  return (
    <div
      id="post-title"
      role="textbox"
      contentEditable
      suppressContentEditableWarning={true}
      className={classNames(
        props.sidePanelValue === sidePanelTypes.DEFAULT &&
          !props.isPanelCollapsed
          ? 'font-extrabold text-lg top-20 xl:text-2xl 2xl:text-2xl xl:top-14 2xl:top-12'
          : props.sidePanelValue === sidePanelTypes.PLAGIARISM &&
            !props.isPanelCollapsed
          ? 'font-extrabold text-lg top-20 xl:text-2xl 2xl:text-2xl xl:top-14 2xl:top-12'
          : 'font-extrabold text-xl top-16 xl:text-2xl 2xl:text-3xl xl:top-10 2xl:top-7',
        'titlearea absolute z-10 outline-none'
      )}
      onInput={onPostTitleChange}
      onBlur={e => {
        props.updateState({ text: e.currentTarget.textContent });
        dispatch(addPostTitle(e.currentTarget.textContent));
        dispatch(
          setNovaOnBoardingCheckList(novaOnBoardingCheckLists.GENERATE_A_TITLE)
        );
        if (
          stepNovaOnBoardingGuide === novaOnBoardingSteps.WRITE_A_FIRST_DRAFT
        ) {
          dispatch(
            setNovaOnBoardingStep(novaOnBoardingSteps.GENERATE_AN_OUTLINE)
          );
        }
        setTimeout(() => {
          onPostTitleChange();
        }, 100);
      }}
    >
      {props.state.text}
    </div>
  );
});

function mapStateToProps(state) {
  return {
    sidePanelValue: state.panel.sidePanelValue,
    isPanelCollapsed: state.panel.isPanelCollapsed,
  };
}

export default connect(mapStateToProps, null)(PostTitle);
