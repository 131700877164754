import { GoogleSpreadsheet } from 'google-spreadsheet';
import { bloggerOnBoarding } from './track';

// Config variables
export const ONBOARDING_INFO_STORAGE_NAME = 'ONBOARDING_INFO';
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const SHEET_ID = process.env.REACT_APP_SHEET_ID;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export const saveOnBoardingInfo = userInfo => {
  let data = localStorage.getItem(ONBOARDING_INFO_STORAGE_NAME);
  if (data) {
    data = { ...JSON.parse(data), ...userInfo };
  } else {
    data = { ...userInfo };
  }
  localStorage.setItem(ONBOARDING_INFO_STORAGE_NAME, JSON.stringify(data));
};

export const saveOnBoardingInfoToGoogleSheet = async () => {
  try {
    const data = JSON.parse(
      localStorage.getItem(ONBOARDING_INFO_STORAGE_NAME) || ''
    );
    if (!data) {
      return;
    }
    data['How did you hear about Bramework'] =
      data['How did you hear about Bramework?'];
    data['Why did you sign up for Bramework'] =
      data['Why did you sign up for Bramework?'];
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    await doc.loadInfo();
    const sheet = doc.sheetsById[SHEET_ID];
    await sheet.addRow(data);
    bloggerOnBoarding(data.email, {
      'Monthly traffic': data['Monthly traffic'],
      'Monthly revenue': data['Monthly revenue'],
      'Interested in a free blog audit':
        data['Interested in a free blog audit'],
      'Blog URL': data['Blog URL'],
    });
    localStorage.removeItem(ONBOARDING_INFO_STORAGE_NAME);
  } catch (e) {
    console.error('Error: ', e);
  }
};
