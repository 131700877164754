import React from 'react';
import { formatNumber } from '../../utils/common';

export default function KeywordItem({ keyword, onclick }) {
  const data = keyword;

  function getValue(val) {
    return val == null ? <span>No results</span> : formatNumber(val);
  }

  return (
    <div
      className={`keyword-ideas__item ${onclick ? 'cursor-pointer' : ''}`}
      onClick={e => onclick && onclick(e, data.keyword)}
    >
      <p className="title">{data.keyword}</p>
      <div className="keyword-value">
        <div className="keyword-value__item">
          <span>Volume:&nbsp;</span>
          {getValue(data.search_volume)}
        </div>
        <div className="keyword-value__item">
          <span>Competition:&nbsp;</span>
          {getValue(data.competition)}
        </div>
        <div className="keyword-value__item">
          <span>Difficulty:&nbsp;</span>
          {getValue(data.difficulty)}
        </div>
        <div className="keyword-value__item">
          <span>Results:&nbsp;</span>
          {getValue(data.number_of_results)}
        </div>
      </div>
    </div>
  );
}
