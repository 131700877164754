import React, { useState } from 'react';
import { Diff, Hunk, parseDiff } from 'react-diff-view';
import 'react-diff-view/style/index.css';

const ComparisonView = ({
  comparisonData,
  showOnlyChanges,
  onToggleShowOnlyChanges,
}) => {
  const [expandedSections, setExpandedSections] = useState({});

  if (!comparisonData || comparisonData.length === 0) {
    return <div>No comparison data available</div>;
  }

  const toggleSection = index => {
    setExpandedSections(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="comparison-view">
      <div className="comparison-controls">
        <label className="show-changes-label">
          <input
            type="checkbox"
            checked={showOnlyChanges}
            onChange={onToggleShowOnlyChanges}
          />
          <span className="checkbox-text">Show only changes</span>
        </label>
      </div>
      {comparisonData.map((section, index) => {
        const diff = `--- a/section${index}\n+++ b/section${index}\n@@ -1,1 +1,1 @@\n-${section.original}\n+${section.improved}`;
        const file = {
          oldPath: `section${index}`,
          newPath: `section${index}`,
          hunks: parseDiff(diff)[0].hunks,
        };

        return (
          <div key={index} className="comparison-section">
            <h3
              onClick={() => toggleSection(index)}
              style={{ cursor: 'pointer' }}
            >
              Section {index + 1} {expandedSections[index] ? '▼' : '▶'}
            </h3>
            {expandedSections[index] && (
              <Diff
                viewType="split"
                diffType="chars"
                hunks={file.hunks}
                widgets={
                  showOnlyChanges
                    ? {
                        renderToken: token =>
                          token.type !== 'normal' ? token.value : null,
                      }
                    : undefined
                }
              >
                {hunks =>
                  hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)
                }
              </Diff>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ComparisonView;
