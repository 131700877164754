import { sidePanelTypes } from '../actions/side_panel.action';

const sidePanelActionTypes = {
  SHOW_SIDE_PANEL: 'SHOW_SIDE_PANEL',
  SHOW_PLAGIARISM_PANEL: 'SHOW_PLAGIARISM_PANEL',
  SHOW_CHAT_PANEL: 'SHOW_CHAT_PANEL',
  SHOW_FACT_CHECKER_PANEL: 'SHOW_FACT_CHECKER_PANEL',
  HIDE_SIDE_PANEL: 'HIDE_SIDE_PANEL',
  SET_SIDE_PANEL_OPEN_TAB: 'SET_SIDE_PANEL_OPEN_TAB',
};

const sidePanelTabs = {
  INSTRUCT: 0,
  AI_WRITING_TOOLS: 1,
  KEYWORDS: 3,
  SEO: 4,
  HISTORY: 5,
};

const initialState = {
  isPanelCollapsed: true,
  sidePanelValue: 1, //1: show assistant, 2: show plagiarism, 3: show chat
  openTab: 0,
};

const sidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case sidePanelActionTypes.SHOW_SIDE_PANEL:
      return {
        ...state,
        sidePanelValue: sidePanelTypes.DEFAULT,
        isPanelCollapsed: false,
      };
    case sidePanelActionTypes.SHOW_PLAGIARISM_PANEL:
      return {
        ...state,
        sidePanelValue: sidePanelTypes.PLAGIARISM,
        isPanelCollapsed: false,
      };
    case sidePanelActionTypes.SHOW_CHAT_PANEL:
      return {
        ...state,
        sidePanelValue: sidePanelTypes.CHAT,
        isPanelCollapsed: false,
      };
    case sidePanelActionTypes.SHOW_FACT_CHECKER_PANEL:
      return {
        ...state,
        sidePanelValue: sidePanelTypes.FACT_CHECKER,
        isPanelCollapsed: false,
      };
    case sidePanelActionTypes.HIDE_SIDE_PANEL:
      return {
        ...state,
        isPanelCollapsed: true,
      };
    case sidePanelActionTypes.SET_SIDE_PANEL_OPEN_TAB:
      return {
        ...state,
        openTab: action.openTab,
      };
    default:
      return state;
  }
};

export default sidePanelReducer;
export { sidePanelActionTypes, sidePanelTabs };
