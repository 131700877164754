import React from 'react';

export default function HighLightTip({
  minCharacter,
  maxCharacter,
  type,
  customMessage,
}) {
  return (
    <div className="font-medium text-sm mt-4 font-weight-bolder">
      💡{' '}
      {customMessage ? (
        <span>{customMessage}</span>
      ) : (
        <>
          <span className="bg-purple-200 text-purple-700 rounded p-1 font-weight-bold">
            Highlight {minCharacter}-{maxCharacter} characters
          </span>{' '}
          that you want the AI to {type}.
        </>
      )}
    </div>
  );
}
