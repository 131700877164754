import { Fragment, useCallback } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getCategory, categories } from './_utils';
import DashboardItemMenu from './dashboard-item-menu';

const DashboardList = ({ items = [], onUpdate = () => {}, ...props }) => {
  const user = useSelector(state => state.auth.user);
  const history = useHistory();
  const { project_id } = useParams();

  const edit = useCallback(
    item => {
      const { category, id } = item;
      switch (category) {
        case categories.keyword: {
          return history.push(`/project/${project_id}/keyword-analyzer/${id}`, {
            page: 'analyzer',
          });
        }
        case categories.post: {
          if (user.is_owner || user.is_manager || user.is_editor) {
            return history.push(`/project/${project_id}/post-builder/${id}`);
          } else {
            return history.push(`/project/${project_id}/post-preview/${id}`);
          }
        }
        case categories.titles: {
          return history.push(`/project/${project_id}/title-generator/${id}`);
        }
        case categories.outlines: {
          return history.push(`/project/${project_id}/outline-generator/${id}`);
        }
        case categories.rewrites: {
          return history.push(`/project/${project_id}/rewrite-generator/${id}`);
        }
        case categories.summaries: {
          return history.push(`/project/${project_id}/summary-generator/${id}`);
        }
      }
    },
    [user, project_id]
  );

  const renderTableData = () => {
    return (
      <Fragment>
        {items.map((item, index) => {
          const keyword =
            item.category && item.body && item.body[0].state.text
              ? item.body[0].state.text
              : item.phrase;

          const category = getCategory(item.category);

          const rowClass = item.first_draft_completed
            ? 'cursor-pointer hover:bg-gray-50'
            : 'opacity-50 cursor-not-allowed';

          return (
            <tr
              key={item.id}
              className={rowClass}
              onClick={() => item.first_draft_completed && edit(item)}
            >
              {/* Category */}
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {category}
              </td>
              {/* Keyword */}
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {keyword}
              </td>
              {/* Updated at */}
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {`Last  ${item.category ? 'edit' : 'search'} `}
                <Moment fromNow>
                  {item.category ? item.edited_at : item.analysis_at}
                </Moment>
              </td>

              <td
                className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                onClick={e => e.stopPropagation()}
              >
                {!item.first_draft_completed ? (
                  <div class="inline-flex items-center justify-center">
                    <div class="loader animate-spin rounded-full h-6 w-6 border-t-2 border-transparent border-b-2 border-gray-400"></div>
                  </div>
                ) : (
                  <DashboardItemMenu
                    category={item.category}
                    name={item.name}
                    id={item.id}
                    status={item.status}
                    callback={onUpdate}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* Category */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category
                  </th>
                  {/* Keyword */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Topic
                  </th>
                  {/* Updated at */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Updated at
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {renderTableData()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardList;
