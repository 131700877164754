import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { useAlert } from 'react-alert';

import { PencilIcon } from '@heroicons/react/outline';
import { Tooltip } from '@material-ui/core';
import { setWriteMoreButtonOn } from '../../store/actions/post_builder.actions';

const storeKey = 'aiWriteMoreButtonOn';

const WriteMoreButton = ({ mainRef = null, ...props }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);

  const selectionBoundingClientRect = useSelector(
    state => state.post.selectionBoundingClientRect
  );
  const postTitle = useSelector(state => state.post.title);
  const aiWriteMoreButtonOn = useSelector(
    state => state.post.aiWriteMoreButtonOn
  );

  useEffect(() => {
    const stored = localStorage.getItem(storeKey);
    if (!!stored) {
      dispatch(setWriteMoreButtonOn(stored === 'true'));
      localStorage.removeItem(storeKey);
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem(storeKey, aiWriteMoreButtonOn);
  }, [aiWriteMoreButtonOn]);

  useEffect(() => {
    if (!!selectionBoundingClientRect) {
      setTop(Math.abs(Math.round(selectionBoundingClientRect?.top + 230)));
      setLeft(Math.round(selectionBoundingClientRect?.left));
    }
  }, [selectionBoundingClientRect]);

  if (!aiWriteMoreButtonOn) return null;

  return (
    <div
      className="z-10 absolute"
      style={{
        top,
        left,
      }}
    >
      <Tooltip title="AI write more" arrow open={openTooltip}>
        <button
          type="button"
          onPointerOver={() => setOpenTooltip(true)}
          onPointerLeave={() => setOpenTooltip(false)}
          disabled={props.editorLoading}
          className={cx(
            props.editorLoading ? 'cursor-default' : '',
            'bg-bw-green hover:bg-bw-darkGreen focus:ring-bw-green text-white focus:ring-2 focus:ring-offset-2 mb-2 inline-flex items-center border border-transparent rounded-full shadow-md focus:outline-none p-2 opacity-30 hover:opacity-100'
          )}
          onClick={() => {
            setOpenTooltip(false);
            if (!props.editorLoading) {
              if (postTitle) {
                props.setWriteMoreButton(true);
              } else {
                alert.error('Post title required');
              }
            }
          }}
        >
          {props.editorLoadingText ? (
            <svg
              fill="currentColor"
              className="animate-spin w-4"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
            </svg>
          ) : (
            <PencilIcon className="w-4" aria-hidden="true" />
          )}
        </button>
      </Tooltip>
    </div>
  );
};

export default WriteMoreButton;
