import './GenerateFirstDraft.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';

import FormSelect from '../../components/form/FormSelect';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import ResetIcon from './ResetIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import TextareaWithGenerate from './TextareaWithGenerate';
import InputWithGenerate from './InputWithGenerate';
import DeleteModal from './DeleteModal';
import useSentData from '../../services/useSentData';
import Loading from '../../components/Loading';
import PreviewBlock from '../PostPreview/PreviewBlock';
import { numberToThousands } from '../../utils/common';

import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
} from '../../store/actions/generate-first-draft';
import { LABELS, getId, OUTLINE_PLACEHOLDER, getWordsCount } from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { isolateError } from '../../utils/api';
import { Tooltip } from '@material-ui/core';
import { TONES } from '../../utils/common';
import Select from '../../components/form/FormSelect';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { addSecondaryKeywords } from '../../store/actions/post_builder.actions';

// Main component
const GenerateFirstDraft = () => {
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const dispatch = useDispatch();
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();

  const [trashed, setTrashed] = useState(false);
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bodyState, setBodyState] = useState({});

  const createIsDisabled = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields).filter(
      ([key]) =>
        key !== 'audience' &&
        key !== 'outline_id' &&
        key !== 'instructions' &&
        key !== 'url' &&
        key !== 'brand_voice'
    );
    const fieldsValues = entriesOfRequiredFields.map(e => {
      const [, v] = e;
      return v;
    });
    const fieldsInvalid = !!fieldsValues.filter(item => !item).length;
    return loading.draft || fieldsInvalid;
  }, [loading.draft, fields]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const draftWordsCount = useMemo(() => {
    if (!draft) return 0;
    const titleText =
      draft.body.find(b => b.type === 'Title')?.state?.text ?? '';
    const bodyText = draft.body.find(b => b.type === 'Body')?.state?.text ?? '';
    const words = getWordsCount(titleText) + getWordsCount(bodyText);
    track('First draft generator', {
      Words: words,
    });

    return words;
  }, [draft]);

  const resetFields = () => {
    track('First draft generator', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
  };

  const handleGenerateTitle = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.title] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-title-generator', {
        phrase: primaryTopic,
        variations: 1,
        tone,
      });
      track('First draft generator', { Clicked: 'generate title' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.title,
            content[0].output
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.title] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleGenerateOutline = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic || !title) {
      alert.error(`${LABELS.primary_topic} and ${LABELS.title} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-outline-sections', {
        phrase: primaryTopic,
        title,
        tone,
      });
      track('First draft generator', { Clicked: 'generate outline' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline,
            content?.text ?? ''
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleCreateFirstDraft = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic || !title || !outline) {
      alert.error(
        `${LABELS.primary_topic}, ${LABELS.title} and ${LABELS.outline} required!`
      );
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-draft', {
        phrase: primaryTopic,
        title,
        outline,
        tone,
      });
      track('First draft generator', {
        Clicked: 'generate first draft',
        Keyword: primaryTopic,
        Tone: tone,
        Title: title,
        Outline: outline,
      });
      const { content, message, success } = res;

      if (!content) {
        throw new Error(message);
      }

      const bodyState = {
        id: null,
        text: content.output.html,
        generation_id: content.generation_id,
        like_status: content.like_status,
        created_at: content.created_at,
      };

      const postBody = [
        { type: 'Title', state: { text: title } },
        { type: 'HeroImage', state: { file: null } },
        { type: 'Body', state: bodyState },
      ];

      setBodyState(bodyState);

      let analysis_data = {
        volume: null,
        competition: null,
        results: null,
        difficulty: null,
        suggested_keywords: [],
        trends: null,
        top_questions: [],
        top_related: [],
        top_posts: [],
        database: 'us',
      };
      const semrushRes = await request.send('/api/semrush', {
        keyword_phrase: primaryTopic,
        database: 'us',
      });

      if (!semrushRes.isAxiosError) {
        analysis_data = semrushRes;
      }

      const resCreatePost = await request.send(
        `/api/projects/${project_id}/posts`,
        {
          phrase: primaryTopic,
          analysis_data,
        }
      );

      const { id: newPostId } = resCreatePost;
      if (!newPostId) {
        alert.error('Failed');
        return;
      }

      const generateKeywordsResponse = await request
        .send('/api/generate-keywords', {
          phrase: primaryTopic,
        })
        .catch();
      const keywordStrings = generateKeywordsResponse?.content ?? '';
      const topics = keywordStrings ? keywordStrings.split(',') : null;

      if (topics) {
        const additionKeywords = topics.map((topic, index) => ({
          id: new Date().getTime() + index,
          text: topic,
          selected: false,
        }));
        sessionStorage.setItem(
          `${ADDITION_KEYWORDS_KEY}_${newPostId}`,
          JSON.stringify(additionKeywords)
        );
        dispatch(addSecondaryKeywords(topics));
      }

      // Save the post with generated draft content
      await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        {
          body: postBody,
          category: 1,
          titles: title.split(','),
          topics,
        },
        'put'
      );

      const resPost = await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        null,
        'get'
      );

      if (success) {
        alert.success(message);
        dispatch(setFirstDraft({ id: newPostId, body: resPost?.body }));
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id]);

  const handleGoToDashboard = () => {
    resetFields();
    resetDraft();
    history.push(`/project/${project_id}/dashboard`);
  };

  const handleGoToEditor = draftId => {
    resetFields();
    resetDraft();
    track('First draft generator', { Clicked: 'go to editor' });
    history.push(`/project/${project_id}/post-builder/${draftId}`);
  };

  const handleDeleteDraftCtaClicked = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteDraft = useCallback(async () => {
    setLoading(state => {
      const draft = { ...state };
      draft.delete = true;
      return draft;
    });
    track('First draft generator', { Clicked: 'delete draft' });
    try {
      await request.send(
        `/api/projects/${project_id}/posts/${draft.id}`,
        null,
        'delete'
      );
      alert.success('Deleted');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.delete = false;
        return draft;
      });
      setShowDeleteModal(false);
      setTrashed(pre => !pre);
      dispatch(setFirstDraft(null));
    }
  }, [draft, alert, project_id, request]);

  const updateLikeFirstDraft = status => {
    const method = 'post';
    const newBodyState = { ...bodyState };
    if (newBodyState.like_status) {
      newBodyState.like_status[0].status = status;
    } else {
      newBodyState.like_status = [{ status }];
    }
    track('First draft generator', { Clicked: status });
    setBodyState(newBodyState);
    const url = status
      ? `/api/like-first-draft`
      : '/api/like-first-draft-remove';
    const updatedData = { generation_id: bodyState.generation_id, status };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline.split('\n');
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 150 + totalSubHeading * 115;
    return numberToThousands(wordCount);
  };

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen">
      <div className="flex-1 hidden lg:flex flex-col items-center justify-center bg-white">
        {/* TODO: Add lottie animation */}
        {!!draft ? (
          <Fragment>
            <DeleteModal
              show={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onDelete={handleDeleteDraft}
              loading={loading.delete}
              feature="Quick Draft content"
            />
            <div className="p-4 pt-0 md:px-16 max-h-full overflow-y-scroll">
              {/* Top actions */}
              <div className="sticky top-0 py-8 bg-white">
                <div className="flex flex-row-reverse mb-1">
                  <p className="text-sm italic text-gray-400">Saved</p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <button
                      className="text-xs bg-purple-200 hover:bg-purple-300 border border-purple-500 rounded-lg py-1 px-2 text-purple-500 font-semibold cursor-pointer"
                      onClick={() => handleGoToEditor(draft.id)}
                    >
                      Go to Editor
                    </button>
                    <Tooltip title="Like" arrow>
                      <button
                        onClick={() => updateLikeFirstDraft(1)}
                        className="inline-flex ml-3 items-center p-1 border bg-gray-200 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                      >
                        <ThumbUpIcon
                          className={`h-4 w-4 hover:text-green-500 ${
                            bodyState.like_status &&
                            bodyState.like_status[0]?.status === 1
                              ? 'text-green-500'
                              : 'text-gray-500'
                          }`}
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                    <Tooltip title="DisLike" arrow>
                      <button
                        onClick={() => updateLikeFirstDraft(2)}
                        className="inline-flex ml-3 items-center p-1 border bg-gray-200  border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                      >
                        <ThumbDownIcon
                          className={`h-4 w-4 hover:text-red-500 ${
                            bodyState.like_status &&
                            bodyState.like_status[0]?.status === 2
                              ? 'text-red-500'
                              : 'text-gray-500'
                          }`}
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                    <p className="text-sm text-gray-400 ml-3">{`Words: ${draftWordsCount}`}</p>
                  </div>
                  <div>
                    <TrashIcon
                      onClick={handleDeleteDraftCtaClicked}
                      className={cx('w-8 rounded-full p-1 cursor-pointer', {
                        'bg-red-200 text-red-400': !trashed,
                        'bg-red-400 text-white': trashed,
                      })}
                    />
                  </div>
                </div>
              </div>
              {/* Render the generated draft */}
              <div className="preview">
                {draft?.body.map((block, i) => (
                  <PreviewBlock block={block} key={i} />
                ))}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="hidden lg:flex flex-col items-center">
            {loading.draft ? (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 md:mt-10 lg:mt-5 lg:ml-24 mb-8 w-2/4 animate-pulse"
                />
                <h1 className="font-bold text-gray-700 lg:text-md xl:text-lg text-center mt-8 animate-pulse">
                  Take a moment to pause and smile while
                  <br />
                  Bramework writes a Quick Draft of your blog post.
                  <br />
                  <br />
                  Estimated time: 1-2 minutes
                  <br />
                  DO NOT refresh or leave this page.
                </h1>
              </Fragment>
            ) : (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 lg:mt-5 lg:ml-24 mb-8 w-2/4"
                />
                <div className="text-gray-700 lg:text-md xl:text-lg mx-8">
                  <b>Step 1: </b>Input your topic or keyword and select the
                  tone.
                  <br />
                  <b>Step 2: </b>Click 'Generate' to create a title.
                  <br />
                  <b>Step 3: </b>Click 'Generate' to create an outline then
                  modify the outline as desired, adhering to the format.
                  <br />
                  <b>Step 4: </b>Click 'Generate Quick Draft'.
                  <br />
                </div>
                <div
                  id="alert-additional-content-1"
                  className="p-4 my-4 text-white rounded-lg bg-purple-600 mx-8"
                  role="alert"
                >
                  <div className="flex">
                    <div className="flex mr-2">
                      <InformationCircleIcon className="w-5 h-5" />
                    </div>
                    <div className="text-sm">
                      <b>✋ Important:</b> AI writers are not meant to generate
                      publish-ready content. We recommend you add your own
                      experiences, opinions, and edits so your article is
                      valuable to the reader and ranks better in Google.
                      Google's latest update emphasizes experience in content.
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
      <div className="flex-1">
        <div className="px-5 lg:py-5 xl:px-10 h-full flex flex-col justify-center items-center bg-gray-100">
          <div className="max-h-full w-full bg-white rounded-lg shadow-lg flex flex-col py-6">
            {/* Top actions */}
            <div className="flex justify-between items-center px-8">
              <div>
                <h1>
                  <span className="font-semibold text-lg text-gray-900">
                    Quick Draft
                  </span>
                </h1>
                <p className="text-sm text-gray-700">
                  All fields with (*) are required
                </p>
              </div>
              {allowReset && (
                <button
                  className="text-xs px-2 py-1 h-auto text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-md flex items-center gap-x-1"
                  onClick={() => resetFields()}
                >
                  <span>Reset</span>
                  <ResetIcon />
                </button>
              )}
            </div>
            {/* main form */}
            <form
              className="mt-4 overflow-y-auto bg-white overflow-x-visible px-8"
              onClick={e => e.preventDefault()}
            >
              <FormContainer>
                <FormLabel label={LABELS.primary_topic} />
                <FormInput
                  id={getId(LABELS.primary_topic)}
                  className="w-full md:w-2/3"
                  inputClassName="rounded-lg"
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                        val
                      )
                    );
                  }}
                  inputProps={{ placeHolder: 'how to start a blog' }}
                />
              </FormContainer>
              <FormContainer>
                <span className="block w-full sm:w-116 font-semibold text-gray-900">
                  {LABELS.tone}
                </span>
                <Select
                  id={getId(LABELS.tone)}
                  className="w-full md:w-2/3"
                  options={TONES}
                  value={TONES.find(
                    item =>
                      item.value === fields[GENERATE_FIRST_DRAFT_FIELDS.tone]
                  )}
                  onChange={({ value }) => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.tone,
                        value
                      )
                    );
                  }}
                />
              </FormContainer>
              <FormContainer>
                <FormLabel label={LABELS.title} />
                <InputWithGenerate
                  id={getId(LABELS.title)}
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.title]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.title,
                        val
                      )
                    );
                  }}
                  onGenerate={handleGenerateTitle}
                  loading={loading[GENERATE_FIRST_DRAFT_FIELDS.title]}
                  disabled={loading.draft}
                  inputProps={{
                    placeHolder:
                      'How to Start a Blog: The Ultimate Beginner’s Guide',
                  }}
                />
              </FormContainer>
              <FormContainer>
                <FormLabel label={LABELS.outline} />
                <TextareaWithGenerate
                  id={getId(LABELS.outline)}
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.outline,
                        val
                      )
                    );
                  }}
                  onGenerate={handleGenerateOutline}
                  loading={loading[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                  rows={8}
                  disabled={loading.draft}
                  placeHolder={OUTLINE_PLACEHOLDER}
                />
              </FormContainer>
            </form>
            <div className="flex justify-between items-start gap-x-4 pt-2 pb-0 px-8">
              <button
                className="text-md bg-white border border-purple-500 hover:bg-purple-100 rounded-lg flex-1 py-3 text-purple-500 font-semibold disabled:cursor-not-allowed"
                onClick={handleGoToDashboard}
                disabled={loading.draft}
              >
                Go to Dashboard
              </button>
              <div className="flex-1 flex flex-col">
                <button
                  className="text-md bg-purple-600 hover:bg-purple-700 border border-purple-600 rounded-lg py-3 text-white font-semibold flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={createIsDisabled}
                  onClick={handleCreateFirstDraft}
                >
                  {loading.draft && <LoadingIcon />}
                  <span>Generate Quick Draft</span>
                </button>
                {fields[GENERATE_FIRST_DRAFT_FIELDS.outline] ? (
                  <caption className="mt-2 text-xs text-gray-400">
                    (AI will generate around {getWordCount()} words)
                  </caption>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateFirstDraft;
