export const categories = {
  keyword: 0,
  post: 1,
  titles: 2,
  outlines: 3,
  rewrites: 4,
  summaries: 5,
};

export const getCategory = cat => {
  for (let key in categories) {
    if (cat === categories[key]) {
      return key;
    }
  }
  return 0;
};
