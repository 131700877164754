import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import Select from '../components/form/FormSelect';
import AiWriteMoreSwitch from './instructions-tab/ai-write-more-switch';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';

import PrimaryKeywordInput from './instructions-tab/primary-keyword-input';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import {
  addNiche,
  addLanguage,
  addTone,
  addSelectedKeywords,
  addOutlineType,
  addInstructions,
  setLength,
  loadInstructions,
  setWriteMoreButtonOn,
  setAdditionalKeywordsButtonOn,
  setInstructionsButton,
} from '../store/actions/post_builder.actions';
import {
  TONES,
  OUTLINE_TYPES,
  NICHES,
  LANGUAGES,
  LENGTHS,
} from '../utils/common';
import { RadioGroup, Switch } from '@headlessui/react';
import classNames from 'classnames';
import cx from 'classnames';
import KeywordsTagInput from './KeywordsTagInput';
import BrandVoiceSwitch from './instructions-tab/brand-voice-switch';

function InstructionsTab({
  phrase = '',
  onPhraseChange = () => {},
  changePostLoader = () => {},
}) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const request = useSentData();
  let { id, project_id } = useParams();

  const isOsWindows = window.navigator.userAgent.indexOf('Window') !== -1;
  const ctrlLabel = isOsWindows ? 'Ctrl' : 'Control';

  const postId = useSelector(state => state.post.id);
  if (!id && postId) {
    id = postId;
  }
  const language = useSelector(state => state.post.language);
  const niche = useSelector(state => state.post.niche);
  const tone = useSelector(state => state.post.tone);
  const length = useSelector(state => state.post.length);

  const outlineType = useSelector(state => state.post.outlineType);
  const selectedKeywords = useSelector(state => state.post.selectedKeywords);
  const instructions = useSelector(state => state.post.instructions);
  const additionalKeywordsButtonOn = useSelector(
    state => state.post.additionalKeywordsButtonOn
  );
  const instructionButtonOn = useSelector(
    state => state.post.instructionButtonOn
  );
  const [keywordChanged, setKeywordChanged] = useState(false);
  const [openShortcuts, setOpenShortcuts] = useState(true);

  function saveSelectedKeywords(data) {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'put';
    if (id) {
      url += `/${id}`;
      request.send(url, { topics: data }, method).then(response => {
        if (!response.isAxiosError) {
          // dispatch(addSelectedKeywords(response.topics));
        } else {
          alert.error(isolateError(response));
        }
      });
    } else {
      alert.error('Post id does not exist');
    }
  }

  useEffect(() => {
    dispatch(loadInstructions());
  }, []);

  return (
    <div className="overflow-y-auto">
      <div className="flow-root mt-8">
        <div className="w-80 sm:w-128">
          <BrandVoiceSwitch />
          <PrimaryKeywordInput
            phrase={phrase}
            onPhraseChange={onPhraseChange}
            changePostLoader={changePostLoader}
          />
          <AiWriteMoreSwitch />
          <div className="mb-8 px-5 flex justify-between items-center">
            <label
              htmlFor="length"
              className="block w-min sm:w-116 font-semibold text-gray-900"
            >
              Output length
              <p className="text-xs text-gray-500 font-normal">
                Controls how long or short the generated text will be.
              </p>
            </label>
            <RadioGroup
              className="flex items-center justify-start ml-5 mt-2"
              value={length}
              onChange={v => dispatch(setLength(v))}
            >
              {LENGTHS.map(({ label, value }) => (
                <RadioGroup.Option
                  value={value}
                  className="cursor-pointer focus:outline-none ml-2"
                  key={'radio-length-' + label}
                >
                  {({ checked }) => (
                    <Tooltip
                      title={(() => {
                        switch (label) {
                          case 'S':
                            return 'Up to 50 words';
                          case 'M':
                            return 'Up to 125 words';
                          case 'L':
                            return 'Up to 250 words';
                          default:
                            return '';
                        }
                      })()}
                      arrow
                    >
                      <span
                        className={classNames(
                          'px-3 py-1 rounded-xl border-2',
                          { 'bg-purple-100 border-purple-400': checked },
                          { 'bg-purple-50 border-transparent': !checked }
                        )}
                      >
                        {label}
                      </span>
                    </Tooltip>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className="mb-8">
            <label
              htmlFor="tone"
              className="block mx-5 w-min sm:w-116 font-semibold text-gray-900"
            >
              Select tone
            </label>
            <Select
              id="tones"
              className="mx-5 w-full sm:w-116"
              options={TONES}
              // label={'Select tone'}
              value={tone}
              onChange={v => {
                dispatch(addTone(v));
              }}
            />
          </div>
          {/* <div className="mb-8">
            <label
              htmlFor="outlines"
              className="block text-sm mx-5 w-116 font-medium text-gray-900"
            >
              Choose outline type
              <p className="mt-0.5 text-xs text-gray-500">
                Select the structure for your blog post outline.
              </p>
            </label>
            <Select
              id="tones"
              className="mx-5 w-116"
              options={OUTLINE_TYPES}
              // label={'Choose outline type'}
              value={outlineType}
              onChange={v => {
                dispatch(addOutlineType(v));
              }}
            />
          </div> */}
          <div className="mb-8 pr-5">
            <div className="flex justify-between">
              <label
                htmlFor="keywords"
                className="block mx-5 w-full sm:w-116 font-semibold text-gray-900"
              >
                <div className="flex items-center">
                  <div>Secondary keywords</div>
                  <div className="ml-3 text-sm font-normal text-gray-500">
                    {selectedKeywords.split(',')?.filter(item => item)
                      ?.length ?? 0}{' '}
                    / 3
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-gray-500 font-normal">
                  Enter and select up to 3 keywords the AI should include when
                  writing.
                </p>
              </label>
              <Switch
                checked={additionalKeywordsButtonOn}
                onChange={value => {
                  dispatch(setAdditionalKeywordsButtonOn(value));
                }}
                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span
                  aria-hidden="true"
                  className={cx(
                    additionalKeywordsButtonOn
                      ? 'bg-purple-600'
                      : 'bg-gray-200',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                  )}
                />
                <span
                  aria-hidden="true"
                  className={cx(
                    additionalKeywordsButtonOn
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>

            <div className="mt-2 px-5">
              <KeywordsTagInput projectId={project_id} postId={id} />
            </div>
          </div>
          <div className="pr-5">
            <div className="flex justify-between">
              <label
                htmlFor="comment"
                className="block mx-5 w-full md:w-116 font-semibold text-gray-900"
              >
                <div className="flex items-center">
                  <span>Instructions</span>
                  <div className="ml-3 text-sm font-normal text-gray-500">
                    {instructions?.length || 0} / 2000
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-gray-500 font-normal">
                  Enter additional information the AI should include when
                  writing.
                </p>
              </label>
              <Switch
                checked={instructionButtonOn}
                onChange={value => {
                  dispatch(setInstructionsButton(value));
                }}
                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span
                  aria-hidden="true"
                  className={cx(
                    instructionButtonOn ? 'bg-purple-600' : 'bg-gray-200',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                  )}
                />
                <span
                  aria-hidden="true"
                  className={cx(
                    instructionButtonOn ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>

            <div className="mt-1">
              <textarea
                rows={4}
                maxLength={2000}
                name="comment"
                id="comment"
                className="shadow-sm focus:ring-purple-500 focus:border-purple-500 block mx-5 w-full md:w-116 sm:text-sm border-gray-300 rounded-md"
                defaultValue={instructions}
                placeholder={
                  'Include: The #1 position on Google search results gets 33% of all search traffic...'
                }
                onChange={v => {
                  dispatch(addInstructions(v.target.value));
                }}
                data-hj-allow
              />
            </div>
          </div>
          <div className="mt-4">
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenShortcuts(preState => !preState)}
            >
              <p>Cheatsheet</p>
              {openShortcuts ? (
                <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
              )}
            </div>
            {openShortcuts && (
              <div className="pt-2 pb-10 px-5">
                <p className="text-sm text-gray-500 font-normal mb-5">
                  If you enter three pluses (+++) in the long-form editor, it
                  tells Bramework to skip any content before the pluses. This
                  prevents the AI from generating duplicate content when using
                  the Write More button.
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Write More - Set cursor in editor. {ctrlLabel} + Q
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Write Paragraph - Highlight 10 to 100 characters. {ctrlLabel}{' '}
                  + P
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Listicle - Highlight 20 to 300 characters. {ctrlLabel} + L
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Steps - Highlight 20 to 300 characters. {ctrlLabel} + S
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Summary - Highlight 30 to 4000 characters. {ctrlLabel} + M
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Rewrite - Highlight 30 to 4000 characters. {ctrlLabel} + R
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Command - Highlight at least 20 characters. {ctrlLabel} + G
                </p>
                <p className="text-sm text-gray-500 font-normal mb-2">
                  Magic Spacer - Highlight at least 4 sentences. {ctrlLabel} + Y
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructionsTab;
