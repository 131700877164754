import React from 'react';
import './TrendItem.scss';
import { formatNumber } from '../../utils/common';

export default function Trend({ volume, trend }) {
  let color;
  if (trend.trend > 80) {
    color = '#24CF33';
  } else if (trend.trend > 60) {
    color = '#FB5607';
  } else {
    color = '#FF006E';
  }

  const value = (volume * trend.trend) / 100;
  const tip = `Monthly Volume<div class="trend-tip-value">${formatNumber(
    value,
    1
  )}</div>`;

  return (
    <div className="trend-component">
      <div className="column-value">
        <div
          data-tip={tip}
          style={{
            height: `${trend.trend}%`,
            background: color,
          }}
        />
      </div>
      <div className="column-name">{trend.month}</div>
    </div>
  );
}
