// BlogPostImprover.js
import './BlogPostImprover.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  ThumbUpIcon,
  ThumbDownIcon,
  TrashIcon,
  EyeIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';

import ComparisonView from './ComparisonView';
import ImprovementSummary from './ImprovementSummary';
// import FormSelect from '../../components/form/FormSelect';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import ResetIcon from './ResetIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
// import TextareaWithGenerate from './TextareaWithGenerate';
// import InputWithGenerate from './InputWithGenerate';
import DeleteModal from './DeleteModal';
import useSentData from '../../services/useSentData';
import Loading from '../../components/Loading';
import PreviewBlock from '../PostPreview/PreviewBlock';
import { numberToThousands } from '../../utils/common';

import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
} from '../../store/actions/generate-first-draft';
import { LABELS, getId, OUTLINE_PLACEHOLDER, getWordsCount } from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { isolateError } from '../../utils/api';
import { Tooltip } from '@material-ui/core';
import { TONES } from '../../utils/common';
import Select from '../../components/form/FormSelect';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { addSecondaryKeywords } from '../../store/actions/post_builder.actions';
import CollapsibleSection from './CollapsibleSection';
import CopyableSection from './CopyableSection';
import ContentSection from './ContentSection';
import store from '../../store';

// Main component
const BlogPostImprover = () => {
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const dispatch = useDispatch();
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();
  const state = store.getState();
  const user = state.auth.user;

  const [trashed, setTrashed] = useState(false);
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bodyState, setBodyState] = useState({});
  const [analysis, setAnalysis] = useState(null);
  const [activeTab, setActiveTab] = useState('summary');
  const [showModal, setShowModal] = useState(false);

  const [comparisonData, setComparisonData] = useState([]);
  const [improvementSummary, setImprovementSummary] = useState(null);
  const [showOnlyChanges, setShowOnlyChanges] = useState(false);

  const createIsDisabled = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields).filter(
      ([key]) =>
        key !== 'title' &&
        key !== 'outline' &&
        key !== 'tone' &&
        key !== 'audience' &&
        key !== 'outline_id' &&
        key !== 'instructions' &&
        key !== 'brand_voice'
    );
    const fieldsValues = entriesOfRequiredFields.map(e => {
      const [, v] = e;
      return v;
    });
    const fieldsInvalid = !!fieldsValues.filter(item => !item).length;
    return loading.draft || fieldsInvalid;
  }, [loading.draft, fields]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const draftWordsCount = useMemo(() => {
    if (!draft) return 0;
    const titleText =
      draft.body.find(b => b.type === 'Title')?.state?.text ?? '';
    const bodyText = draft.body.find(b => b.type === 'Body')?.state?.text ?? '';
    const words = getWordsCount(titleText) + getWordsCount(bodyText);
    track('Blog post improver', {
      Words: words,
    });

    return words;
  }, [draft]);

  const resetFields = () => {
    track('Blog post improver', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
    localStorage.removeItem('blogImproverData');
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
    localStorage.removeItem('blogImproverData');
  };

  const isValidUrl = url => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    url = url.trim();
    try {
      new URL(url);
      return urlRegex.test(url);
    } catch (error) {
      return false;
    }
  };

  const handleCreateImprovePost = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const url = fields[GENERATE_FIRST_DRAFT_FIELDS.url];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }
    if (!url || !isValidUrl(url)) {
      alert.error(`Valid ${LABELS.url} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/improve-blog-post', {
        phrase: primaryTopic,
        url,
      });
      track('blog post improver', {
        Clicked: 'improve blog post',
        Keyword: primaryTopic,
        URL: url,
      });
      const { content, message, success } = res;

      if (!content) {
        throw new Error(message);
      }

      const bodyState = {
        id: null,
        text: content.output.html,
        generation_id: content.generation_id,
        like_status: content.like_status,
        created_at: content.created_at,
      };

      const postBody = [
        {
          type: 'Title',
          state: { text: content.output.original_post_html.title ?? '' },
        },
        { type: 'HeroImage', state: { file: null } },
        { type: 'Body', state: bodyState },
      ];

      setBodyState(bodyState);

      let analysis_data = {
        volume: null,
        competition: null,
        results: null,
        difficulty: null,
        suggested_keywords: [],
        trends: null,
        top_questions: [],
        top_related: [],
        top_posts: [],
        database: 'us',
      };
      const semrushRes = await request.send('/api/semrush', {
        keyword_phrase: primaryTopic,
        database: 'us',
      });

      if (!semrushRes.isAxiosError) {
        analysis_data = semrushRes;
      }

      const resCreatePost = await request.send(
        `/api/projects/${project_id}/posts`,
        {
          phrase: primaryTopic,
          analysis_data,
        }
      );

      const { id: newPostId } = resCreatePost;
      if (!newPostId) {
        alert.error('Failed');
        return;
      }

      const generateKeywordsResponse = await request
        .send('/api/generate-keywords', {
          phrase: primaryTopic,
        })
        .catch();
      const keywordStrings = generateKeywordsResponse?.content ?? '';
      const topics = keywordStrings ? keywordStrings.split(',') : null;

      if (topics) {
        const additionKeywords = topics.map((topic, index) => ({
          id: new Date().getTime() + index,
          text: topic,
          selected: false,
        }));
        sessionStorage.setItem(
          `${ADDITION_KEYWORDS_KEY}_${newPostId}`,
          JSON.stringify(additionKeywords)
        );
        dispatch(addSecondaryKeywords(topics));
      }

      // Save the post with generated draft content
      await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        {
          body: postBody,
          category: 1,
          titles: '',
          topics,
        },
        'put'
      );

      const resPost = await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        null,
        'get'
      );

      if (success) {
        alert.success(message);
        dispatch(setFirstDraft({ id: newPostId, body: resPost?.body }));
        setAnalysis(content.output);
        // setComparisonData(content.output.comparison_data || []);
        // console.log('Setting comparison data:', content.output.comparison_data);
        // setImprovementSummary(content.output.improvement_summary);
        // setShowModal(true);

        // Store data in local storage
        const storageData = {
          project_id,
          newPostId,
          analysis: content.output,
          comparisonData: content.output.comparison_data || [],
          improvementSummary: content.output.improvement_summary,
          draft: { id: newPostId, body: resPost?.body },
          primaryTopic: fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic],
          blogPostUrl: fields[GENERATE_FIRST_DRAFT_FIELDS.url],
        };
        localStorage.setItem('blogImproverData', JSON.stringify(storageData));
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id]);

  const handleAcceptChange = (sectionIndex, changeIndex) => {
    setComparisonData(prevData => {
      const newData = [...prevData];
      const section = newData[sectionIndex];
      const change = section.changes[changeIndex];

      if (change.type === 'insert') {
        section.original =
          section.original.slice(0, change.lineNumber) +
          change.content +
          section.original.slice(change.lineNumber);
      } else if (change.type === 'delete') {
        section.original =
          section.original.slice(0, change.lineNumber) +
          section.original.slice(change.lineNumber + change.content.length);
      }

      section.changes.splice(changeIndex, 1);
      return newData;
    });
  };

  const handleRejectChange = (sectionIndex, changeIndex) => {
    setComparisonData(prevData => {
      const newData = [...prevData];
      newData[sectionIndex].changes.splice(changeIndex, 1);
      return newData;
    });
  };

  const handleMergeContent = () => {
    const mergedContent = comparisonData
      .map(section => section.original)
      .join('\n\n');
    // Update the draft with the merged content
    dispatch(
      setFirstDraft({
        ...draft,
        body: [
          ...draft.body.slice(0, 2),
          {
            type: 'Body',
            state: {
              ...draft.body[2].state,
              text: mergedContent,
            },
          },
        ],
      })
    );
    setShowModal(false);
  };

  const toggleModal = () => {
    if (analysis) {
      setShowModal(prev => !prev);
    }
  };

  const handleGoToDashboard = () => {
    resetFields();
    resetDraft();
    setShowModal(false);
    setAnalysis(null);
    history.push(`/project/${project_id}/dashboard`);
  };

  const handleGoToEditor = draftId => {
    resetFields();
    resetDraft();
    track('Blog post improver', { Clicked: 'go to editor' });
    history.push(`/project/${project_id}/post-builder/${draftId}`);
  };

  const handleDeleteDraftCtaClicked = () => {
    setShowDeleteModal(true);
    setShowModal(false);
  };

  const handleDeleteDraft = useCallback(async () => {
    setLoading(state => {
      const draft = { ...state };
      draft.delete = true;
      return draft;
    });
    track('Blog post improver', { Clicked: 'delete draft' });
    try {
      await request.send(
        `/api/projects/${project_id}/posts/${draft.id}`,
        null,
        'delete'
      );
      alert.success('Deleted');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.delete = false;
        return draft;
      });
      setShowDeleteModal(false);
      setTrashed(pre => !pre);
      dispatch(setFirstDraft(null));
      localStorage.removeItem('blogImproverData');
    }
  }, [draft, alert, project_id, request]);

  const updateLikeBlogBooster = status => {
    const method = 'post';
    const newBodyState = { ...bodyState };
    if (newBodyState.like_status) {
      newBodyState.like_status[0].status = status;
    } else {
      newBodyState.like_status = [{ status }];
    }
    track('Blog post improver', { Clicked: status === 1 ? 'like' : 'dislike' });
    setBodyState(newBodyState);
    const url = status
      ? `/api/like-blog-booster`
      : '/api/like-blog-booster-remove';
    const updatedData = { generation_id: bodyState.generation_id, status };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          // Handle success if needed
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline.split('\n');
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 150 + totalSubHeading * 115;
    return numberToThousands(wordCount);
  };

  const parseHtmlContent = htmlString => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const sections = [];
    let currentSection = null;

    doc.body.childNodes.forEach(node => {
      if (node.nodeName === 'H2') {
        if (currentSection) {
          sections.push(currentSection);
        }
        currentSection = {
          title: node.textContent,
          content: '',
          subsections: [],
        };
      } else if (node.nodeName === 'H3' && currentSection) {
        currentSection.subsections.push({
          title: node.textContent,
          content: '',
        });
      } else if (currentSection) {
        if (currentSection.subsections.length > 0) {
          currentSection.subsections[
            currentSection.subsections.length - 1
          ].content += node.outerHTML || node.textContent;
        } else {
          currentSection.content += node.outerHTML || node.textContent;
        }
      }
    });

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  };
  const renderImprovedContent = () => {
    if (!analysis) return null;

    const parsedSections = parseHtmlContent(analysis.html);
    return (
      <div className="content-gaps">
        {parsedSections.map((section, index) => (
          <ContentSection
            key={index}
            title={section.title}
            subsections={
              section.subsections.length > 0
                ? section.subsections
                : [{ title: '', content: section.content }]
            }
          />
        ))}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'summary':
        return <ImprovementSummary summary={improvementSummary} />;
      case 'content-gaps':
        return (
          <div className="content-gaps">
            {/* <h2>Content Gaps</h2> */}
            <div dangerouslySetInnerHTML={{ __html: analysis.html }} />
          </div>
        );
      case 'comparison':
        return (
          <ComparisonView
            comparisonData={comparisonData}
            showOnlyChanges={showOnlyChanges}
            onToggleShowOnlyChanges={() => setShowOnlyChanges(!showOnlyChanges)}
            onAcceptChange={handleAcceptChange}
            onRejectChange={handleRejectChange}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
      setShowModal(false);
      setAnalysis(null);
    };
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('blogImproverData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAnalysis(parsedData.analysis);

      // Format comparison data if it exists
      if (parsedData.comparisonData) {
        const formattedComparisonData = parsedData.comparisonData.map(
          section => ({
            original: section.original,
            improved: section.improved,
            changes: section.changes.map(change => ({
              type: change.type,
              content: change.improved || change.original,
              lineNumber: 0, // You might need to calculate this based on your data structure
            })),
          })
        );
        setComparisonData(formattedComparisonData);
      } else {
        setComparisonData([]);
      }

      setImprovementSummary(parsedData.improvementSummary);
      dispatch(setFirstDraft(parsedData.draft));

      // Set primary topic and blog post URL
      if (parsedData.primaryTopic) {
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
            parsedData.primaryTopic
          )
        );
      }
      if (parsedData.blogPostUrl) {
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.url,
            parsedData.blogPostUrl
          )
        );
      }
    }
  }, [dispatch]);

  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen blog-post-improver">
      {/* Left side with Nova robot image */}
      <div className="w-full lg:w-1/2 hidden lg:flex flex-col items-center justify-center bg-white left-side-container">
        {!!draft ? (
          <Fragment>
            <DeleteModal
              show={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onDelete={handleDeleteDraft}
              loading={loading.delete}
              feature="Blog Booster content"
            />
            <div className="p-4 pt-0 md:px-16 max-h-full overflow-y-scroll">
              {/* Top actions */}
              <div className="sticky top-0 py-8 bg-white">
                <div className="flex flex-row-reverse mb-1">
                  <p className="text-sm italic text-gray-400">Saved</p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <button
                      className="text-xs bg-purple-200 hover:bg-purple-300 border border-purple-500 rounded-lg py-1 px-2 text-purple-500 font-semibold cursor-pointer"
                      onClick={() => handleGoToEditor(draft.id)}
                    >
                      Go to Editor
                    </button>
                    {/* <Tooltip title="View Analysis" arrow>
                      <button
                        onClick={toggleModal}
                        className="text-xs bg-purple-200 hover:bg-purple-300 border border-purple-500 rounded-lg py-1 px-2 text-purple-500 font-semibold cursor-pointer"
                      >
                        <EyeIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </Tooltip> */}
                    <Tooltip title="Like" arrow>
                      <button
                        onClick={() => updateLikeBlogBooster(1)}
                        className="inline-flex ml-3 items-center p-1 border bg-gray-200 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                      >
                        <ThumbUpIcon
                          className={`h-4 w-4 hover:text-green-500 ${
                            bodyState.like_status &&
                            bodyState.like_status[0]?.status === 1
                              ? 'text-green-500'
                              : 'text-gray-500'
                          }`}
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                    <Tooltip title="DisLike" arrow>
                      <button
                        onClick={() => updateLikeBlogBooster(2)}
                        className="inline-flex ml-3 items-center p-1 border bg-gray-200  border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                      >
                        <ThumbDownIcon
                          className={`h-4 w-4 hover:text-red-500 ${
                            bodyState.like_status &&
                            bodyState.like_status[0]?.status === 2
                              ? 'text-red-500'
                              : 'text-gray-500'
                          }`}
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                  </div>
                  <div>
                    <TrashIcon
                      onClick={handleDeleteDraftCtaClicked}
                      className={cx('w-8 rounded-full p-1 cursor-pointer', {
                        'bg-red-200 text-red-400': !trashed,
                        'bg-red-400 text-white': trashed,
                      })}
                    />
                  </div>
                </div>
              </div>
              {/* Render the generated draft */}
              <div className="preview px-4 md:px-8 lg:px-12">
                <div className="collapsible-sections-container">
                  {renderImprovedContent()}
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="hidden lg:flex flex-col items-center">
            {loading.draft ? (
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className="text-center">
                  <img
                    src={NovaRobot}
                    alt="nova-robot"
                    className="w-1/2 max-w-xs mx-auto mb-8 animate-pulse"
                  />
                  <h1 className="font-bold text-gray-700 text-center animate-pulse px-4 max-w-md mx-auto">
                    <p className="mb-4">
                      Take a moment to pause and smile while
                      <br />
                      Bramework conducts research to improve your post.
                    </p>
                    <p className="mb-4">Estimated time: 3-4 minutes</p>
                    <p>DO NOT refresh or leave this page.</p>
                  </h1>
                </div>
              </div>
            ) : (
              <Fragment>
                <img
                  src={NovaRobot}
                  alt="nova-robot"
                  className="md:ml-10 lg:mt-5 lg:ml-24 mb-8 w-2/4"
                />
                <div className="text-gray-700 lg:text-md xl:text-lg mx-8">
                  🚀 Improve your blog posts with our blog improver tool.
                  Receive actionable insights to enhance your writing and
                  increase your organic traffic.
                  <br />
                  <br />
                  <b>Step 1: </b>Input your topic and blog post URL.
                  <br />
                  <b>Step 2: </b>Click 'Improve My Post'.
                  <br />
                </div>
                <div
                  id="alert-additional-content-1"
                  className="p-4 my-4 text-white rounded-lg bg-purple-600 mx-8"
                  role="alert"
                >
                  <div className="flex">
                    <div className="flex mr-2">
                      <InformationCircleIcon className="w-5 h-5" />
                    </div>
                    <div className="text-sm">
                      <b>✋ Important:</b> AI writers are not meant to generate
                      publish-ready content. We recommend you add your own
                      experiences, opinions, and edits so your article is
                      valuable to the reader and ranks better in Google.
                      Google's latest update emphasizes experience in content.
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
      {/* Right side with form */}
      <div className="w-full lg:w-1/2">
        <div className="px-5 lg:py-5 xl:px-10 h-full flex flex-col justify-center items-center bg-gray-100">
          {user.app_sumo_subscription &&
            !user.blog_booster_enabled &&
            !user.blog_booster_access && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      Blog Booster is not enabled for your account.
                      <Link
                        to="/appsumo-plan"
                        className="font-medium underline text-yellow-700 hover:text-yellow-600"
                      >
                        Click here to purchase Blog Booster as an Add-on
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          <div className="max-h-full w-full bg-white rounded-lg shadow-lg flex flex-col py-6">
            {/* Top actions */}
            <div className="flex justify-between items-center px-8">
              <div>
                <h1>
                  <span className="font-semibold text-lg text-gray-900">
                    Blog Booster
                  </span>
                </h1>
                <p className="text-sm text-gray-700">
                  All fields with (*) are required
                </p>
              </div>
              {allowReset && (
                <button
                  className="text-xs px-2 py-1 h-auto text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-md flex items-center gap-x-1"
                  onClick={() => resetFields()}
                >
                  <span>Reset</span>
                  <ResetIcon />
                </button>
              )}
            </div>
            {/* main form */}
            <form
              className="mt-4 overflow-y-auto bg-white overflow-x-visible px-8"
              onClick={e => e.preventDefault()}
            >
              <FormContainer>
                <FormLabel label={LABELS.primary_topic} />
                <FormInput
                  id={getId(LABELS.primary_topic)}
                  className="w-full md:w-2/3"
                  inputClassName="rounded-lg"
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                        val
                      )
                    );
                  }}
                  inputProps={{ placeHolder: 'how to start a blog' }}
                />
              </FormContainer>
              <FormContainer>
                <FormLabel label={LABELS.url} />
                <FormInput
                  id={getId(LABELS.primary_topic)}
                  className="w-full md:w-2/3"
                  inputClassName="rounded-lg"
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.url]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.url,
                        val
                      )
                    );
                  }}
                  inputProps={{
                    placeHolder: 'https://www.mywebsite.com/my-blog-post',
                  }}
                />
              </FormContainer>
            </form>
            <div className="flex justify-between items-start gap-x-4 pt-2 pb-0 px-8">
              <button
                className="text-md bg-white border border-purple-500 hover:bg-purple-100 rounded-lg flex-1 py-3 text-purple-500 font-semibold disabled:cursor-not-allowed"
                onClick={handleGoToDashboard}
                disabled={loading.draft}
              >
                Go to Dashboard
              </button>
              <div className="flex-1 flex flex-col">
                <button
                  className="text-md bg-purple-600 hover:bg-purple-700 border border-purple-600 rounded-lg py-3 text-white font-semibold flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={
                    createIsDisabled ||
                    (user.app_sumo_subscription &&
                      !user.blog_booster_enabled &&
                      !user.blog_booster_access)
                  }
                  onClick={handleCreateImprovePost}
                >
                  {loading.draft && <LoadingIcon />}
                  <span>Improve My Post</span>
                </button>

                {/* {fields[GENERATE_FIRST_DRAFT_FIELDS.outline] ? (
                  <caption className="mt-2 text-xs text-gray-400">
                    (AI will generate around {getWordCount()} words)
                  </caption>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for displaying results */}
      {showModal && analysis !== null && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Blog Post Improvement Analysis</h2>
            <div className="tab-buttons">
              <button
                className={activeTab === 'summary' ? 'active' : ''}
                onClick={() => setActiveTab('summary')}
              >
                Improvement Summary
              </button>
              <button
                className={activeTab === 'content-gaps' ? 'active' : ''}
                onClick={() => setActiveTab('content-gaps')}
              >
                Content Gaps
              </button>
              <button
                className={activeTab === 'comparison' ? 'active' : ''}
                onClick={() => setActiveTab('comparison')}
              >
                Side-by-Side Comparison
              </button>
            </div>
            <div className="tab-content">{renderTabContent()}</div>
            <button className="merge-button" onClick={handleMergeContent}>
              Merge Changes
            </button>
            <button className="close-button" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPostImprover;
