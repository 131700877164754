import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AddPlatform.scss';
import Select from '../form/FormSelect';
import FormInput from '../form/FormInput';
import FormPassword from '../form/FormPassword';
import Modal from 'react-modal';
import {
  addPlatformUser,
  getPlatformList,
  removeFormStatuses,
} from '../../store/actions/platform.actions';
import cancelIcon from '../../styles/images/cancel-black.svg';
import { useAlert } from 'react-alert';

const AddPlatform = ({ openModal, closeModal, userId }) => {
  const platform = useSelector(state => state.platform);
  const platformList = useSelector(state => state.platform.platformList);
  const updatePlatformData = useSelector(
    state => state.platform.updatePlatformData
  );
  const [url, setUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [platformId, setPlatformId] = useState('');
  const [platformName, setPlatformName] = useState('');
  const alert = useAlert();
  const dispatch = useDispatch();

  const WordPeres = () => {
    const data = {
      user: userId,
      url: url,
      platform: platformId,
      username: userName,
      password: password,
    };
    if (updatePlatformData !== null)
      return dispatch(addPlatformUser(data, 'put', updatePlatformData.id));
    dispatch(addPlatformUser(data));
  };

  const handleChange = func => {
    Object.keys(platform.formErrors).length !== 0 &&
      dispatch(removeFormStatuses());
    func();
  };

  useEffect(() => {
    if (platform.success) {
      closeModal();
      alert.success(`Platform successful connected!`);
    }
  }, [platform.success]);

  useEffect(() => {
    const item = platformList.find(
      item => item.title === platform.defaultPlatform
    );
    if (updatePlatformData !== null) {
      setPlatformName(platform.defaultPlatform);
      setPlatformId(item ? item.id : '');
      setUrl(updatePlatformData.url);
      setUserName(updatePlatformData.username);
      setPassword(updatePlatformData.password);
    }
  }, [updatePlatformData]);

  useEffect(() => {
    !platformList.length && dispatch(getPlatformList());
    return () => dispatch(removeFormStatuses());
  }, []);

  return (
    <Modal
      className="modal-wordpress"
      isOpen={openModal}
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      <img
        src={cancelIcon}
        alt="cancel button"
        className="cancel-button"
        onClick={closeModal}
      />
      <Select
        className="settings-select mb-3"
        label="Choose a platform for integrations - 🥳 Plugin Coming Soon!"
        menuPortalTarget={document.body}
        options={platformList.map(item => ({
          label: item.title,
          value: item.id,
        }))}
        onChange={v => {
          setPlatformId(v.value);
          setPlatformName(v.label);
        }}
      />
      {platformName.toLowerCase() === 'wordpress' && (
        <>
          <div className="form-wordpress">
            <div className="title">
              Add your WordPress information - 🥳 Plugin Coming Soon!
            </div>
            {Object.keys(platform.formErrors).length !== 0 && (
              <p className="text-danger">Invalid data</p>
            )}
            <a
              href="https://www.bramework.com/how-to-connect-wordpress-blog/"
              target="_blank"
              className="text-purple-500 hover:text-purple-700 link-about cursor-pointer"
              rel="noreferrer"
            >
              Learn how to connect WordPress Blog
            </a>
            <FormInput
              id="url"
              className={`account-input ${
                platform.formErrors.url ? 'error-input' : 0
              }`}
              label="Website Url (https://www.yourwebsite.com)"
              value={url}
              onChange={v => handleChange(() => setUrl(v))}
            />
            <FormInput
              id="user-name"
              className={`account-input ${
                platform.formErrors.username ? 'error-input' : 0
              }`}
              label="Username"
              value={userName}
              onChange={v => handleChange(() => setUserName(v))}
            />
            <FormPassword
              id="password"
              type="password"
              className={`account-input ${
                platform.formErrors.password ? 'error-input' : 0
              }`}
              label="Password"
              value={password}
              onChange={v => handleChange(() => setPassword(v))}
            />
          </div>
          <div className="btn-wrapper">
            <div
              className="w-full cursor-pointer inline-flex justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              id="submit"
              onClick={e => {
                if (platform.submitting || platform.fail)
                  return e.preventDefault();
                WordPeres();
              }}
            >
              Save
              {platform.submitting && (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddPlatform;
