import $ from 'jquery';
import { convertToPlain } from './common';

export default {
  getBlocksByTemplate(templateName) {
    if (templateName === 'how-to') {
      return [
        this.blockTypes.Title(),
        this.blockTypes.HeroImage(),
        this.blockTypes.Introduction(),
        this.blockTypes.Image(),
        this.blockTypes.HowTo(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.Conclusion(),
      ];
    } else if (templateName === 'list') {
      return [
        this.blockTypes.Title(),
        this.blockTypes.HeroImage(),
        this.blockTypes.Introduction(),
        this.blockTypes.Image(),
        this.blockTypes.List(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.Conclusion(),
      ];
    } else if (templateName === 'what') {
      return [
        this.blockTypes.Title(),
        this.blockTypes.HeroImage(),
        this.blockTypes.Introduction(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.Conclusion(),
      ];
    } else if (templateName === 'why') {
      return [
        this.blockTypes.Title(),
        this.blockTypes.HeroImage(),
        this.blockTypes.Introduction(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.FAQ(),
        this.blockTypes.Image(),
        this.blockTypes.Conclusion(),
      ];
    } else if (templateName === 'standard') {
      return [
        this.blockTypes.Title(),
        this.blockTypes.HeroImage(),
        this.blockTypes.Body(),
      ];
    } else {
      return [this.blockTypes.Title(), this.blockTypes.HeroImage()];
    }
  },
  getBlockByType(typeName) {
    try {
      return this.blockTypes[typeName]();
    } catch (e) {
      return null;
    }
  },
  blockTypes: {
    Title: () => ({
      type: 'Title',
      state: {
        text: '',
      },
    }),
    HeroImage: () => ({
      type: 'HeroImage',
      state: {
        file: null,
      },
    }),
    Introduction: () => ({
      type: 'Introduction',
      state: {
        text: '',
      },
    }),
    Bonus: () => ({
      type: 'Bonus',
      state: {
        title: '',
        text: '',
      },
    }),
    Image: () => ({
      type: 'Image',
      state: {
        files: [],
      },
    }),
    HowTo: () => ({
      type: 'HowTo',
      state: {
        title: '',
        items: [{ title: '', text: '' }],
      },
    }),
    List: () => ({
      type: 'List',
      state: {
        title: '',
        items: [{ title: '', text: '' }],
      },
    }),
    What: () => ({
      type: 'What',
      state: {
        title: '',
        text: '',
      },
    }),
    Why: () => ({
      type: 'Why',
      state: {
        title: '',
        text: '',
      },
    }),
    FAQ: () => ({
      type: 'FAQ',
      state: {
        title: '',
        text: '',
      },
    }),
    Conclusion: () => ({
      type: 'Conclusion',
      state: {
        title: '',
        text: '',
      },
    }),
    Paragraph: () => ({
      type: 'Paragraph',
      state: {
        text: '',
      },
    }),
    Heading2: () => ({
      type: 'Heading2',
      state: {
        text: '',
      },
    }),
    Heading3: () => ({
      type: 'Heading3',
      state: {
        text: '',
      },
    }),
    Heading4: () => ({
      type: 'Heading4',
      state: {
        text: '',
      },
    }),
    Body: () => ({
      type: 'Body',
      state: {
        text: '',
      },
    }),
  },
  getContentBeforeCursor(editor) {
    if (!editor) {
      return '';
    }

    // Get the current selection range
    const rng = editor.selection.getRng();

    // Function to extract content from the start to the current cursor position
    const extractContent = root => {
      let content = '';
      let reachedCursor = false;

      const walkNodes = node => {
        if (node.nodeType === Node.TEXT_NODE) {
          // Check if this text node is at or past the cursor
          if (rng.comparePoint(node, 0) < 0) {
            content += node.textContent;
          } else {
            reachedCursor = true;
            return;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          for (let i = 0; i < node.childNodes.length; i++) {
            if (reachedCursor) break;
            walkNodes(node.childNodes[i]);
          }
        }
      };

      walkNodes(root);

      return content;
    };

    // Extract content up to the cursor position from the editor's body
    let contentUpToCursor = extractContent(editor.getBody());

    // Optionally, convert HTML to plain text if needed
    let plainContent = convertToPlain(contentUpToCursor);

    // Truncate to the last 10000 characters if necessary
    plainContent = plainContent.substring(
      Math.max(plainContent.length - 10000, 0),
      plainContent.length
    );

    return plainContent;
  },
};

export function getPostProgress(blocks) {
  const totalBlocks = blocks.length;
  let actualFilledBlocks = 0;
  let progress = 0;
  let color;
  blocks.map(block => {
    if (
      block.type === 'Title' ||
      block.type === 'Heading2' ||
      block.type === 'Heading3' ||
      block.type === 'Heading4'
    ) {
      if (block.state.text.length >= 10) {
        actualFilledBlocks += 1;
      }
    }
    if (block.type === 'Introduction' || block.type === 'Paragraph') {
      if (block.state.text.length >= 100) {
        actualFilledBlocks += 1;
      }
    }
    if (block.type === 'Body') {
      if (block.state.text) {
        if (block.state.text.length >= 100 && block.state.text.length < 1000) {
          actualFilledBlocks += 0.3;
        }
        if (block.state.text.length >= 1000 && block.state.text.length < 3000) {
          actualFilledBlocks += 0.7;
        }
        if (block.state.text.length >= 3000) {
          actualFilledBlocks += 1;
        }
      }
    }
    if (
      block.type === 'Bonus' ||
      block.type === 'Conclusion' ||
      block.type === 'FAQ' ||
      block.type === 'What' ||
      block.type === 'Why'
    ) {
      if (block.state.title.length >= 10 && block.state.text.length >= 100) {
        actualFilledBlocks += 1;
      }
    }
    if (block.type === 'List' || block.type === 'HowTo') {
      if (
        (block.state.title.length >= 10 ||
          block.state.items[0].title.length >= 5) &&
        block.state.items[0].text.length >= 100
      ) {
        actualFilledBlocks += 1;
      }
    }
    if (block.type === 'HeroImage') {
      if (block.state.file !== null) {
        actualFilledBlocks += 1;
      }
    }
    if (block.type === 'Image') {
      if (block.state.files.length > 0) {
        actualFilledBlocks += 1;
      }
    }
  });
  progress = Math.round((actualFilledBlocks / totalBlocks) * 100);

  if (progress > 85) {
    color = 'green';
  } else if (progress >= 30 && progress <= 85) {
    color = 'yellow';
  } else if (progress > 0 && progress <= 29) {
    color = 'red';
  } else {
    color = '';
  }

  return { progress, color };
}

export function getSeoProgress(seoData) {
  let seoProgress = 0;
  let seoColor;
  if (seoData) {
    let data = Object.assign({}, seoData);
    delete data.word_total;
    let itemLists = Object.values(data);
    for (const item of itemLists) {
      switch (item) {
        case 1:
          seoProgress += Math.round(100 / itemLists.length);
          break;
        case 2:
          seoProgress += Math.round(100 / itemLists.length / 2);
      }
    }
  }
  if (seoProgress > 100) {
    seoProgress = 100;
  }
  if (seoProgress > 85) {
    seoColor = 'green';
  } else if (seoProgress >= 30 && seoProgress <= 85) {
    seoColor = 'yellow';
  } else if (seoProgress > 0 && seoProgress <= 29) {
    seoColor = 'red';
  } else {
    seoColor = '';
  }
  return { seoProgress, seoColor };
}

export function getNewSeoProgress(seoData) {
  let seoProgress = 0;
  let seoColor;
  if (seoData) {
    let data = Object.assign({}, seoData);
    let itemLists = Object.values(data);
    for (const item of itemLists) {
      switch (item) {
        case 1:
          seoProgress += Math.round(100 / itemLists.length);
          break;
        case 2:
          seoProgress += Math.round(100 / itemLists.length / 2);
      }
    }
  }
  if (seoProgress > 100) {
    seoProgress = 100;
  }
  if (seoProgress > 85) {
    seoColor = 'bg-green-500';
  } else if (seoProgress >= 30 && seoProgress <= 85) {
    seoColor = 'bg-yellow-500';
  } else if (seoProgress > 0 && seoProgress <= 29) {
    seoColor = 'bg-red-600';
  } else {
    seoColor = 'bg-gray-300';
  }
  return { seoProgress, seoColor };
}

export function magicSpacerFormat(htmlString) {
  const transitionWords = [
    'Above all',
    'Accordingly',
    'Additionally',
    'After all',
    'After all is said and done',
    'Also',
    'As a result',
    'As an example',
    'As long as',
    'As much as',
    'As soon as',
    'Because of',
    'Because the',
    'Before',
    'Consequently',
    'Conversely',
    'Correspondingly',
    'Coupled with',
    'Despite',
    'Despite the fact',
    'Due to',
    'Equally important',
    'Even if',
    'Even though',
    'Finally',
    'First',
    'Firstly',
    'For example',
    'For instance',
    'For one thing',
    'For this reason',
    'For those reasons',
    'For those who',
    'Fourth',
    'Furthermore',
    'Hence',
    'Henceforth',
    'However',
    'I hope that',
    'If',
    'If and when',
    'If so',
    'In addition',
    'In addition to',
    'In any case',
    'In any event',
    'In a nutshell',
    'In case',
    'In conclusion',
    'In contrast',
    'In fact',
    'In other words',
    'In particular',
    'In spite of',
    'In summary',
    'In the end',
    'In the event of',
    'In the first place',
    'In the meantime',
    'In the second place',
    'In the same way',
    'In the third place',
    'In this case',
    'In this respect',
    'In this way',
    'In turn',
    'Indeed',
    'Instead',
    'Instead of',
    'Inversely',
    'It follows that',
    'Likewise',
    'Meanwhile',
    'Moreover',
    'Next',
    'Nevertheless',
    'Nonetheless',
    'Now',
    'Now that',
    'Notwithstanding',
    'On the contrary',
    'On the other hand',
    'On the other side',
    'On the whole',
    'Once',
    'Only',
    'Otherwise',
    'Provided that',
    'Second',
    'Secondly',
    'Similarly',
    'Since',
    'So',
    'Then',
    'Therefore',
    'Third',
    'Thirdly',
    'Though',
    'Thus',
    'Till',
    'To begin with',
    'To conclude',
    'To illustrate',
    'To sum up',
    'To summarize',
    'To that end',
    'To this end',
    'Together with',
    'Under those circumstances',
    'Unless',
    'Until',
    'Up to',
    'Uniquely',
    'Unlike',
    'Upon',
    'When',
    'Whereas',
    'While',
    'Whenever',
    'While',
    'Yet',
  ];

  if (typeof htmlString === 'string' && !htmlString.startsWith('<')) {
    htmlString = '<p>' + htmlString + '</p>';
  }

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString.replace('&nbsp;', '');

  const paragraphs = separateParagraphsByTransitionWords(
    tempDiv.querySelectorAll('p'),
    transitionWords
  );

  for (const paragraph of paragraphs) {
    const textContent = paragraph.textContent;
    if (!textContent) {
      continue;
    }
    const sentences = getSentencesFromHtml(paragraph.innerHTML);
    const totalSentences = sentences.length;

    let newParagraphs = [];

    if (totalSentences < 4) {
      continue;
    }

    if (totalSentences % 2 === 0 && totalSentences >= 4) {
      for (let i = 0; i < totalSentences; i += 2) {
        const newParagraph = sentences.slice(i, i + 2).join(' ');
        newParagraphs.push(newParagraph.trim());
      }
    } else if (totalSentences === 5) {
      const firstParagraph = sentences.slice(0, 3).join(' ');
      newParagraphs.push(firstParagraph.trim());

      const secondParagraph = sentences.slice(3, 5).join(' ');
      newParagraphs.push(secondParagraph.trim());
    } else if (totalSentences % 2 === 1 && totalSentences > 5) {
      for (let i = 0; i < totalSentences - 1; i += 2) {
        const newParagraph = sentences.slice(i, i + 2).join(' ');
        newParagraphs.push(newParagraph.trim());
      }

      const lastSentence = sentences[totalSentences - 1].trim();
      const lastParagraph = document.createElement('p');
      lastParagraph.innerHTML = lastSentence;
      paragraph.parentNode.insertBefore(lastParagraph, paragraph.nextSibling);
    }

    if (newParagraphs.length > 0) {
      const parent = paragraph.parentNode;
      let prevSibling = paragraph.previousElementSibling;

      for (const newParagraph of newParagraphs) {
        const newParagraphElement = document.createElement('p');
        newParagraphElement.innerHTML = newParagraph;

        if (prevSibling) {
          parent.insertBefore(newParagraphElement, prevSibling.nextSibling);
        } else {
          parent.insertBefore(newParagraphElement, paragraph);
        }

        prevSibling = newParagraphElement;
      }

      parent.removeChild(paragraph);
    }
  }
  return tempDiv.innerHTML;
}

export function getSeoData({
  phrase,
  body,
  worldCount,
  projectDomain,
  userDomain,
  seoAnalyzer,
}) {
  return {
    image_alt_text_attr: seoAnalyzer ? getSeoImageAltTextAttr(phrase, body) : 0,
    keyphrase_in_introduction: seoAnalyzer
      ? getKeyPhraseInIntroduction(phrase, body)
      : 0,
    keyphrase_in_subhead: seoAnalyzer ? getKeyPhraseInSubHead(phrase, body) : 0,
    keyphrase_in_title: seoAnalyzer ? getKeyPhraseInTitle(phrase, body) : 0,
    keyword_density: seoAnalyzer
      ? getKeyWordDensity(phrase, body, worldCount)
      : 0,
    seo_title_width: seoAnalyzer ? getSeoTitleWidth(phrase, body) : 0,
    ...getSeoLinks(body, projectDomain, userDomain, seoAnalyzer),
  };
}

export function getSentencesFromHtml(htmlString) {
  const SENTENCE_SEPARATOR = '__SENTENCE_SEPARATOR__';
  const modifiedString = htmlString.replace(
    /([.!?])\s+(?=[^<]*[A-Z])/g,
    `$1${SENTENCE_SEPARATOR}`
  );
  const sentences = modifiedString.split(SENTENCE_SEPARATOR);
  const result = [];
  let currentSentence = '';
  for (const sentence of sentences) {
    currentSentence += sentence;
    if (!/^[A-Za-z<]/.test(sentence)) {
      continue;
    }
    result.push(currentSentence.trim());
    currentSentence = '';
  }
  if (currentSentence) {
    result.push(currentSentence.trim());
  }
  return result;
}

function separateParagraphsByTransitionWords(pElements, transitionWords) {
  const parent = pElements[0].parentNode;
  for (let i = 0; i < pElements.length; i++) {
    const newParagraphs = [];
    let currentParagraph = pElements[i];
    let currentContent = '';
    const sentences = getSentencesFromHtml(currentParagraph.innerHTML);
    for (let j = 0; j < sentences.length; j++) {
      const sentence = sentences[j].trim();
      const firstWord = sentence.split(' ')[0] || '';
      const isIncludeTransitionWord = transitionWords.find(item =>
        firstWord.startsWith(item)
      );
      if (isIncludeTransitionWord && currentContent) {
        newParagraphs.push(currentContent);
        currentContent = sentence;
      } else {
        currentContent = [currentContent, sentence]
          .filter(item => item)
          .join(' ');
      }
    }
    if (currentContent) {
      newParagraphs.push(currentContent);
    }
    for (let i = 0; i < newParagraphs.length; i++) {
      if (i === 0) {
        currentParagraph.innerHTML = newParagraphs[0];
      } else {
        const newParagraphElement = document.createElement('p');
        newParagraphElement.innerHTML = newParagraphs[i];
        $(newParagraphElement).insertAfter($(currentParagraph));
        currentParagraph = newParagraphElement;
      }
    }
  }
  return parent.querySelectorAll('p');
}

function getKeyPhraseInSubHead(phrase, body) {
  const bodyText = getBodyText(body, 'Body');
  const subHeadings = getSubHeading(bodyText);
  const count = subHeadings.filter(item => {
    return item.toLowerCase().includes(phrase.toLowerCase());
  }).length;
  const percentage = +((100 * count) / subHeadings.length).toFixed(2);
  if (bodyText <= 500 || percentage === 0) {
    return 0;
  } else if (40 <= percentage && percentage <= 75) {
    return 1;
  } else if (percentage > 75) {
    return 2;
  } else if (0 < percentage && percentage < 40) {
    return 3;
  }
}

function getKeyPhraseInTitle(phrase, body) {
  const title = getBodyText(body, 'Title');
  return title
    ? title.toLowerCase().includes(phrase?.toLowerCase())
      ? 1
      : 2
    : 0;
}

function getKeyWordDensity(phrase, body, worldCount) {
  const bodyText = getBodyText(body, 'Body');
  const count = getCountPhraseInBody(bodyText, phrase);
  const percentage = +((100 * count) / worldCount).toFixed(2);
  if (percentage === 0 || bodyText.length < 500) {
    return 0;
  } else if (1.5 <= percentage && percentage <= 2.5) {
    return 2;
  } else if (
    (1 <= percentage && percentage < 1.5) ||
    (2.5 < percentage && percentage <= 3)
  ) {
    return 3;
  } else {
    return 1;
  }
}

function getSeoTitleWidth(phrase, body) {
  const title = getBodyText(body, 'Title');
  const titleLength = title.length;
  if (
    (20 <= titleLength && titleLength <= 40) ||
    (55 <= titleLength && titleLength <= 70)
  ) {
    return 1;
  } else if (20 < titleLength && titleLength > 70) {
    return 2;
  } else if (40 < titleLength && titleLength < 55) {
    return 3;
  } else {
    return 0;
  }
}

function getKeyPhraseInIntroduction(phrase, body) {
  const bodyText = getBodyText(body, 'Body');
  const bodyTextLength = bodyText.length;
  if (bodyTextLength < 500) {
    return 0;
  }
  const lines = getLines(bodyText);
  const firstLine = lines[0] || '';
  const firstSentence = firstLine.split('.')[0] || '';
  const isKeyPhraseInFirstLine = firstLine
    .toLowerCase()
    .includes(phrase.toLowerCase());
  const isKeyPhraseInFirstSentence = firstSentence
    .toLowerCase()
    .includes(phrase.toLowerCase());
  return isKeyPhraseInFirstSentence ? 1 : isKeyPhraseInFirstLine ? 2 : 3;
}

function getSeoImageAltTextAttr(phrase, body) {
  const bodyText = getBodyText(body, 'Body');
  const heroImage = getHeroImage(body);
  const images = getImages(bodyText);
  if (!images.length && !heroImage) {
    return 0;
  }
  let count = images.filter(item => {
    const altText = item.replace(/<img.*?alt="(.*?)"[^\>]+>/g, '$1');
    return !!altText;
  }).length;
  count += heroImage.alt_text ? 1 : 0;
  const total = heroImage ? images.length + 1 : images.length;
  const percentage = +((100 * count) / total).toFixed(2);
  if (percentage === 0) {
    return 0;
  } else if (percentage === 100) {
    return 1;
  } else if (0 < percentage && percentage < 49) {
    return 2;
  } else if (50 <= percentage && percentage <= 99) {
    return 3;
  }
}

function getSeoLinks(body, projectDomain, userDomain, seoAnalyzer) {
  const result = {
    internal_links: 0,
    external_links: 0,
  };
  if (!seoAnalyzer || (!projectDomain && !userDomain)) {
    return result;
  }
  const bodyText = getBodyText(body, 'Body');
  const links = getAllLinks(bodyText);
  const mainUrl = projectDomain || userDomain;
  const countInternalLinks = links.filter(
    item => mainUrl && item.includes(mainUrl)
  ).length;
  const countExternalLinks = links.length - countInternalLinks;

  if (countInternalLinks === 0) {
    result.internal_links = 0;
  } else if (countInternalLinks === 1) {
    result.internal_links = 3;
  } else if (countInternalLinks === 2) {
    result.internal_links = 2;
  } else if (countInternalLinks >= 3) {
    result.internal_links = 1;
  }

  if (countExternalLinks === 0) {
    result.external_links = 0;
  } else if (countExternalLinks === 1) {
    result.external_links = 3;
  } else if (countExternalLinks === 2) {
    result.external_links = 2;
  } else if (countExternalLinks >= 3) {
    result.external_links = 1;
  }
  return result;
}

function getBodyText(body, type) {
  const bodyText = body?.find(item => item.type === type);
  if (!bodyText || !bodyText?.state?.text) {
    return '';
  }
  return bodyText.state.text;
}

function getHeroImage(body) {
  const bodyText = body?.find(item => item.type === 'HeroImage');
  if (!bodyText || !bodyText?.state?.file) {
    return '';
  }
  return bodyText.state.file;
}

function getLines(input) {
  const div = document.createElement('div');
  div.innerHTML = input;
  const pTags = div.getElementsByTagName('p');
  return [...pTags].map(line => line.innerText);
}

function getImages(input) {
  return [...input.matchAll(/<img [^>]*src="[^"]*"[^>]*>/gm)].map(
    item => item[0]
  );
}

function getSubHeading(input) {
  return [...input.matchAll(/(<.+\/h[2-6]>)/gm)].map(item => item[0]);
}

function getCountPhraseInBody(input, phrase) {
  return [
    ...input?.toLowerCase().matchAll(new RegExp(phrase?.toLowerCase(), 'g')),
  ].length;
}

function getAllLinks(input) {
  return [...input?.matchAll(/<a[^>]*href=["']([^"']*)["']/g)].map(
    item => item[1]
  );
}
