import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import './FormSelect.scss';

const SelectStyles = {
  control: (provided, state) => {
    const background = state.isDisabled ? '#ECEBED' : '#fff';
    const border = state.isFocused ? '2px solid #7C3AED' : '1px solid #ecebed';
    return {
      ...provided,
      '&:hover': {
        borderColor: '#ecebed',
      },
      border: border,
      background: background,
      boxShadow: '0 7px 64px 0 rgba(0, 0, 0, 0.07)',
      height: '48px',
    };
  },
  indicatorSeparator: provided => {
    return {
      display: 'none',
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      color: '#3F3356',
      fontSize: '15px',
      lineHeight: '20px',
      fontFamily: 'Roboto, sans-serif',
    };
  },
  menu: provided => {
    return {
      ...provided,
      background: '#fff',
      boxShadow: '0 7px 64px 0 rgba(0, 0, 0, 0.07)',
    };
  },
  option: (provided, state) => {
    return {
      '&:hover': {
        color: state.data.disabled ? '#aaa' : '#FFF',
        background: state.data.disabled ? '#fff' : '#7C3AED',
      },
      ':active': {
        color: '#3F3356',
      },
      borderLeft: state.data.disabled ? '6px solid #7C3AED' : 'none',
      fontFamily: 'Roboto, sans-serif',
      height: '36px',
      color: state.data.disabled ? '#aaa' : '#3F3356',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      fontSize: state.isSelected ? '17px' : '15px',
      lineHeight: '36px',
      paddingLeft: '16px',
    };
  },
  dropdownIndicator: provided => {
    return {
      ...provided,
      '&:hover': {
        color: '#D0C9D6',
      },
      color: '#D0C9D6',
    };
  },
};

const InvalidStyles = {
  ...SelectStyles,
  control: (provided, state) => {
    const background = state.isDisabled ? '#ECEBED' : '#fff';
    return {
      ...provided,
      '&:hover': {
        borderColor: '#fdc2a6',
      },
      border: '1px solid #fdc2a6',
      background: background,
      boxShadow: '0 7px 64px 0 rgba(0, 0, 0, 0.07)',
      height: '48px',
    };
  },
};

export default function FormSelect({
  id,
  label,
  value,
  className,
  options,
  onChange,
  disabled,
  isValid,
  selectClassName,
}) {
  return (
    <div className={classNames(className, 'form-select-wrapper')}>
      <label htmlFor={id}>{label}</label>
      <Select
        selecctProps={{ className: selectClassName }}
        id={id}
        name={id}
        value={value}
        styles={isValid ? InvalidStyles : SelectStyles}
        options={options}
        onChange={val => onChange(val)}
        isOptionDisabled={option => option.disabled}
        isDisabled={disabled}
        isSearchable={false}
      />
    </div>
  );
}
