const ResetIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0625 4.51758H8.07083C8.55291 4.51758 8.9375 4.90756 8.9375 5.38423V6.34299"
        stroke="#6D7888"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.97792 3.60742L4.0625 4.51745L4.97792 5.43287"
        stroke="#6D7888"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.9375 8.48362H4.92917C4.44709 8.48362 4.0625 8.09363 4.0625 7.61696V6.6582"
        stroke="#6D7888"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.02148 9.39381L8.9369 8.48378L8.02148 7.56836"
        stroke="#6D7888"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.50065 11.9173C9.49219 11.9173 11.9173 9.49219 11.9173 6.50065C11.9173 3.50911 9.49219 1.08398 6.50065 1.08398C3.50911 1.08398 1.08398 3.50911 1.08398 6.50065C1.08398 9.49219 3.50911 11.9173 6.50065 11.9173Z"
        stroke="#6D7888"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ResetIcon;
