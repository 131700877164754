const DashboardOverview = ({
  key = '',
  name = '',
  value = '',
  onAction = () => {},
  renderIcon = () => null,
}) => {
  return (
    <div
      key={key}
      className="bg-white overflow-hidden shadow rounded-lg select-none"
    >
      <div className="p-5">
        <div className="flex items-center gap-x-5">
          <div className="text-purple-600 bg-purple-50 rounded-md p-3">
            <div className="w-6">{renderIcon()}</div>
          </div>
          <div className="flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {name}
              </dt>
              <dd>
                <div className="text-lg font-medium text-gray-900">{value}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-5 py-3">
        <div className="text-sm cursor-pointer select-none">
          <p
            className="font-medium text-purple-600 hover:text-purple-700"
            onClick={onAction}
          >
            View all
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
