import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import http from '../services/http';
import Loading from '../components/Loading';
import { isolateSuccess, isolateError } from '../utils/api';
import { updateUser } from '../store/actions/auth.actions';
import { connect } from 'react-redux';

function Activate({ updateUser }) {
  const [error, setError] = useState(false);
  let { token } = useParams();
  let history = useHistory();

  useEffect(() => {
    http
      .post('/api/activate', {
        token,
      })
      .then(response => {
        const data = response && response.data;
        updateUser({ is_confirmed: true });
        history.push({
          pathname: '/',
          state: { from: 'activate', message: isolateSuccess(data, null) },
        });
      })
      .catch(e => {
        history.push({
          pathname: '/',
          state: {
            from: 'activate',
            message: isolateError(e, null),
            type: 'error',
          },
        });
      });
  }, []);

  // TODO we have very basic layout in this page may be we should update it
  if (!error) {
    return (
      <div className="justify-center pt-96">
        <Loading />
        <div className="font-bold text-center w-full">Loading your info...</div>
      </div>
    );
  } else {
    return <div>Error</div>;
  }
}

export default connect(null, { updateUser })(Activate);
