import { useCallback, useEffect, useState } from 'react';
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import FormInput from '../form/FormInput';
import LoadingIcon from '../LoadingIcon';
import useSentData from '../../services/useSentData';
import {
  addRelatedKeywords,
  addTopQuestions,
} from '../../store/actions/post_builder.actions';

const PrimaryKeywordInput = ({
  phrase,
  onPhraseChange = () => {},
  changePostLoader = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingPhrase, setEditingPhrase] = useState(phrase);
  const request = useSentData();
  const { project_id, id } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  const updatePhrase = useCallback(async () => {
    if (!editingPhrase.trim()) {
      return alert.error("Primary keyword can't be empty");
    }
    setLoading(true);
    // Get keywords analytics
    const resAnalytics = await request.send(`/api/semrush`, {
      database: 'us',
      keyword_phrase: editingPhrase.trim(),
    });

    // Save the post with updated phrase and analytics
    changePostLoader(true);
    await request.send(
      `/api/projects/${project_id}/posts/${id}`,
      {
        phrase: editingPhrase.trim(),
        analysis_data: resAnalytics,
      },
      'put'
    );
    onPhraseChange(editingPhrase, resAnalytics);
    dispatch(addRelatedKeywords(resAnalytics.top_related));
    dispatch(addTopQuestions(resAnalytics.top_questions));
    changePostLoader(false);
    setEditing(false);
    setLoading(false);
  }, [editingPhrase, request, project_id, id, changePostLoader]);

  useEffect(() => {
    setEditingPhrase(phrase);
  }, [phrase]);

  return (
    <div className="w-full mt-0 mb-8 relative px-5">
      <label className="font-semibold text-gray-900" htmlFor="primary-keyword">
        Primary keyword
      </label>
      <FormInput
        id="primary-keyword"
        value={editing ? editingPhrase : phrase}
        onChange={value => {
          if (editing) {
            setEditingPhrase(value);
          }
        }}
        inputClassName="rounded-lg"
        disabled={loading || !editing}
      />
      <div
        className="absolute bottom-3 right-8 disabled:cursor-not-allowed"
        disabled={loading}
        onClick={() => {}}
      >
        {loading ? (
          <div className="pb-1">
            <LoadingIcon />
          </div>
        ) : editing ? (
          <div className="flex items-center gap-x-1">
            <CheckCircleIcon
              className="text-green-400 w-6 cursor-pointer hover:text-green-600"
              onClick={updatePhrase}
            />
            <XCircleIcon
              className="text-red-400 w-6 cursor-pointer hover:text-red-600"
              onClick={() => setEditing(false)}
            />
          </div>
        ) : (
          <PencilAltIcon
            className="w-6 text-gray-400 cursor-pointer hover:text-gray-600"
            onClick={() => setEditing(true)}
          />
        )}
      </div>
    </div>
  );
};

export default PrimaryKeywordInput;
