import { plagiarismTypes } from '../reducers/plagiarism.reducer';
import http from '../../services/http';

// const fail = payload => ({
//   type: roleActionType.TEAM_FAILURE,
//   payload,
// });

export const destroyPlagiarism = () => {
  return dispatch => {
    dispatch({ type: plagiarismTypes.PLAGIARISM_DESTROY });
  };
};

export const addPlagiarism = (post_id, result) => {
  return dispatch => {
    dispatch({
      type: plagiarismTypes.ADD_PLAGIARISM,
      payload: { post_id: post_id, plagiarism: result },
    });
  };
};

export const getPlagiarisms = post_id => {
  return dispatch => {
    dispatch({
      type: plagiarismTypes.GET_PLAGIARISM,
      payload: { post_id: post_id },
    });
  };
};
