import React, { useState, Fragment, useEffect } from 'react';
import { fetchProjects } from '../store/actions/project.action';
import { fetchRoleList } from '../store/actions/roles.actions';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/Loading';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function PreDashboard(props) {
  const history = useHistory();
  useEffect(() => {
    if (props.isAuthenticated) {
      if (props.forceChoosePlan) {
        history.push('/plans');
      }

      if (props.roles.length === 0 && !props.roleLoading) {
        props.fetchRoleList();
      }
      if (props.projects.length === 0 && !props.projectLoading) {
        props.fetchProjects();
      }
      if (props.selectedProject != null && props.roles.length > 0) {
        history.push(`/project/${props.selectedProject.id}/dashboard`);
      }
    }
  }, [props]);
  return (
    <div className="justify-center pt-96">
      <Loading />
      <div className="font-bold text-center w-full">Loading your info...</div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.auth.user || {},
    isAuthenticated: state.auth.isAuthenticated,
    projects: state.project.projects,
    selectedProject: state.project.selectedProject,
    projectLoading: state.project.loading,
    projectError: state.project.error,
    projectErrorMsg: state.project.errorMsg,
    roles: state.role.roles,
    roleLoading: state.role.loading,
    forceChoosePlan: state.access.forceChoosePlan,
  };
}

export default connect(mapStateToProps, { fetchProjects, fetchRoleList })(
  PreDashboard
);
