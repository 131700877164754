import React, { Fragment, useEffect, useRef, useState } from 'react';
import './NewPost.scss';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import backButton from '../styles/images/back_button.svg';
import { track } from '../services/track';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import QualityConfirmationModal from './NewPost/QualityConfirmationModal';
import { sortListByDate, TONES } from '../utils/common';
import { getId, LABELS } from './GenerateFirstDraft/utils';
import Select from '../components/form/FormSelect';
import FormContainer from './GenerateFirstDraft/FormContainer';
import FormLabel from './GenerateFirstDraft/FormLabel';
import FormTextarea from '../components/form/FormTextarea';
import LoadingIcon from '../components/LoadingIcon';
import RewriteItem from './NewPost/RewriteItem';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import { useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@material-ui/core';

function NoData() {
  return <div className="no-data">No data available at this time.</div>;
}

function Rewrites({ getSelectedRewrite, user, rewrites, setRewrites }) {
  const request = useSentData();
  const alert = useAlert();

  const updateRewriteLikeStatus = (generation_id, status) => {
    const data = [...rewrites];
    const rewrite = data.find(item => item.generation_id === generation_id);
    if (!rewrite) {
      return;
    }
    if (!rewrite.like_status) {
      rewrite.like_status = [{ status }];
    } else {
      rewrite.like_status[0].status = status;
    }
    setRewrites(data);
  };

  const onDeleteRewrite = (generation_id, index) => {
    return () => {
      const url = `/api/delete-rewrite`;
      const method = 'post';
      const updatedData = { generation_id };
      return request
        .send(url, updatedData, method)
        .then(response => {
          if (!response.isAxiosError) {
            const newRewrites = [...rewrites];
            newRewrites.splice(index, 1);
            setRewrites(newRewrites);
          } else {
            alert.error(isolateError(response));
          }
        })
        .catch(err => alert.error(err.message));
    };
  };

  return (
    <div className="keywords-component">
      <div className="questions main-card">
        <div>
          {rewrites && rewrites.length ? (
            <div>
              <div className="questions-wrapper">
                {rewrites.map((rewrite, i) => (
                  <RewriteItem
                    allowDelete={true}
                    rewrite={rewrite}
                    key={i}
                    getSelectedRewrite={getSelectedRewrite}
                    updateRewriteLikeStatus={updateRewriteLikeStatus}
                    user={user}
                    onDelete={onDeleteRewrite(rewrite.generation_id, i)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

function Generator({ user }) {
  const request = useSentData();
  const history = useHistory();
  const { id, project_id } = useParams();
  const [selectedRewrite, setSelectedRewrite] = React.useState();
  const [tone, setTone] = useState('informative');
  const [article, setArticle] = useState('');
  const [instructions, setInstructions] = useState('');
  const [rewrites, setRewrites] = useState([]);
  const [useBrandVoice, setUseBrandVoice] = useState(false);

  const [loadingType, setLoadingType] = useState('');
  const [isQualityModalVisible, showQualityModal] = useState(false);
  const [analysisDataLoaded, setAnalysisDataLoaded] = useState(false);
  const alert = useAlert();
  const isNotEmpty = rewrites && rewrites.length;

  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;
  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values.";

  useEffect(() => {
    if (id) {
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setRewrites(data.rewrites);
          } else {
            alert.error(isolateError(data));
          }
        });
    }
  }, []);

  useEffect(() => {
    if (analysisDataLoaded) {
      handleShowQualityModal();
    }
  }, [analysisDataLoaded]);

  function closeQualityModal() {
    showQualityModal(false);
  }

  function submitRequest() {
    track('Rewrite generator', { Clicked: 'generate' });
    setLoadingType('generator');
    request
      .send(`/api/rewrite-generator`, {
        original_text: article,
        tone,
        instructions,
        use_brand_voice: useBrandVoice,
        project_id: project_id,
      })
      .then(response => {
        if (!response.isAxiosError) {
          setRewrites([...response.content, ...rewrites]);
        } else {
          alert.error(isolateError(response));
        }
      })
      .finally(() => setLoadingType(''));
  }

  function getSelectedRewrite(rewrite) {}

  function submitPost() {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/rewrite-generator`;
      method = 'put';
    }
    setLoadingType('saveRewrites');
    request
      .send(
        url,
        {
          category: 4,
          rewrites,
        },
        method
      )
      .then(() => {
        history.push(`/project/${project_id}/rewrites`);
      });
  }

  const displayMessageLoading = () => {
    switch (loadingType) {
      case 'save':
        return 'We’re saving your outlines.';
      case 'generator':
        return 'We’re generating your outlines.';
      case 'keyword':
        return 'We’re analyzing your keyword.';
    }
  };

  function handleShowQualityModal() {
    showQualityModal(true);
  }

  const handleUseBrandVoiceChange = checked => {
    if (!isDisabled) {
      setUseBrandVoice(checked);
    }
  };

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="new-post-component -mx-8 -my-8" style={{ padding: 0 }}>
        {isQualityModalVisible ? (
          <QualityConfirmationModal
            id={id}
            project_id={project_id}
            opened={isQualityModalVisible}
            close={closeQualityModal}
            selectedItem={selectedRewrite}
          />
        ) : (
          ''
        )}
        <div>
          <div>
            <div className="flex w-full flex-col md:flex-row">
              <div className="w-full md:w-2/3 p-4 h-screen overflow-auto">
                <div className="flex justify-between items-center">
                  <div>
                    {/* <button className="clear-button" onClick={history.goBack}>
                    <img src={backButton} alt="" />
                  </button> */}
                  </div>
                  <h1 className="text-lg text-center">Rewriter</h1>
                  <div></div>
                </div>
                <div className="flex flex-col justify-center">
                  {isNotEmpty && loadingType !== 'generator' ? (
                    <>
                      <ReactTooltip
                        place={window.innerWidth < 768 ? 'left' : 'top'}
                        class="tooltip-for-templates"
                        type="light"
                        effect="solid"
                        html={true}
                        border={false}
                      />
                      <Rewrites
                        rewrites={rewrites}
                        setRewrites={setRewrites}
                        projectId={project_id}
                        postId={id}
                        submitPost={submitPost}
                        user={user}
                      />
                    </>
                  ) : (
                    <div className="flex flex-col items-center">
                      <img
                        src={NovaRobot}
                        alt="nova-robot"
                        className={`md:ml-10 md:mt-10 lg:mt-5 lg:ml-24 mb-8 w-1/3 ${loadingType ===
                          'generator' && 'animate-pulse'}`}
                      />
                      {loadingType === 'generator' ? (
                        <h3 className="font-bold text-gray-400 lg:text-md xl:text-lg text-center mt-8 animate-pulse">
                          Take a moment to pause and smile while
                          <br />
                          Bramework rewrite your article.
                        </h3>
                      ) : (
                        <h3 className="font-bold text-gray-400 lg:text-md xl:text-lg text-center mt-8">
                          Enter an article, desired tone, and specific
                          instructions for rewriting your article.
                        </h3>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/3 bg-white">
                <div className="h-screen w-full p-4 flex flex-col items-center justify-between">
                  <div className="w-full flex flex-col">
                    <FormContainer>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="use-brand-voice"
                          className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                          checked={useBrandVoice && !isDisabled}
                          onChange={e => {
                            if (!isDisabled) {
                              handleUseBrandVoiceChange(e.target.checked);
                            }
                          }}
                          disabled={isDisabled}
                        />
                        <label
                          htmlFor="use-brand-voice"
                          className={`ml-2 block ${
                            isDisabled ? 'text-gray-400' : 'text-gray-900'
                          }`}
                        >
                          Use Brand Voice
                        </label>
                        <Tooltip title={tooltipText}>
                          <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-500" />
                        </Tooltip>
                      </div>
                      {isDisabled && (
                        <p className="mt-1 text-sm text-gray-500">
                          {isUncategorizedProject
                            ? 'Create a project folder to use the brand voice feature.'
                            : 'Add or generate a brand voice in your project settings.'}
                        </p>
                      )}
                    </FormContainer>
                    <FormContainer>
                      <div className="flex justify-between mb-2">
                        <FormLabel label="Article" />
                        <div className="w-24 text-sm font-normal text-gray-500 text-right">
                          {article.length} / 4000
                        </div>
                      </div>
                      <FormTextarea
                        value={article}
                        onChange={setArticle}
                        id={id}
                        hideCharCount
                        maxLength={4000}
                        textareaClassName={'resize-none h-auto'}
                        rows={10}
                        placeHolder="Enter the article you wish to rewrite."
                      />
                    </FormContainer>
                    <FormContainer>
                      <FormLabel label="Tone" />
                      <Select
                        id={getId(LABELS.tone)}
                        className="w-full md:w-2/3"
                        options={TONES}
                        value={TONES.find(item => item.value === tone)}
                        onChange={setTone}
                      />
                    </FormContainer>
                    <FormContainer>
                      <div className="flex justify-between mb-2">
                        <FormLabel label="Instructions" optional />
                        <div className="w-24 text-sm font-normal text-gray-500 text-right">
                          {instructions.length} / 2000
                        </div>
                      </div>
                      <FormTextarea
                        value={instructions}
                        onChange={setInstructions}
                        id={id}
                        hideCharCount
                        maxLength={2000}
                        textareaClassName={'resize-none h-auto'}
                        rows={10}
                        placeHolder="Please maintain the original meaning but alter the sentence structure and vocabulary to avoid plagiarism."
                      />
                    </FormContainer>
                  </div>
                  <div className="w-full mb-2 sticky bottom-2 xl:bottom-6">
                    <button
                      onClick={() => submitRequest()}
                      className="text-md w-full bg-purple-600 hover:bg-purple-700 border border-purple-600 rounded-lg py-2 xl:py-3 text-white font-semibold flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        request.loading ||
                        !article ||
                        article.length < 30 ||
                        loadingType === 'generator'
                      }
                    >
                      {loadingType === 'generator' && <LoadingIcon />}
                      <span>Generate</span>
                    </button>
                    <button
                      onClick={() => submitPost()}
                      className="text-md w-full mt-3 bg-purple-200 hover:bg-purple-400 border border-purple-600 rounded-lg py-2 xl:py-3 text-purple-500 font-semibold flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        request.loading ||
                        (rewrites && !rewrites.length) ||
                        loadingType === 'saveRewrites'
                      }
                    >
                      {loadingType === 'saveRewrites' && <LoadingIcon />}
                      <span>Save Rewrites</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(Generator);
