import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/outline';
import { addPostTitle } from '../store/actions/post_builder.actions';
import { truncateString } from '../utils/common';
import RestorePostHistoryModal from '../pages/PostBuilder/RestorePostHistoryModal';
import TimeAgo from 'react-timeago';
import Tooltip from '@material-ui/core/Tooltip';

function HistoryTab({ editorRef, histories, updateFeaturedImage }) {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [post, setPost] = useState();
  function restorePostHistory(restore) {
    if (editorRef?.current?.editor && restore && post) {
      editorRef?.current?.editor?.insertContent(post[2].state.text);
      dispatch(addPostTitle(post[0].state.text));
      setPost();
    }
    setModalOpen(false);
  }

  return (
    <div className="overflow-y-auto">
      <RestorePostHistoryModal
        open={isModalOpen}
        restorePostHistory={restorePostHistory}
      />
      <div className="flow-root mt-4">
        {histories.length ? (
          <ul role="list" className="divide-y divide-gray-200">
            {histories.map((post, i) => (
              <li key={i} className="py-4">
                <div className="items-center space-x-4">
                  <div className="min-w-0">
                    <p className="mx-5 mb-3 w-80 sm:w-116 font-semibold text-gray-900">
                      {post?.body?.[0]?.state?.text}
                    </p>
                    <div className="flex">
                      <p className="mx-5 w-80 sm:w-96 text-xs text-gray-500 pt-1">
                        <TimeAgo date={post?.edited_at} minPeriod={15} />
                      </p>
                      <div className="mx-10">
                        <Tooltip title="Insert in editor" arrow>
                          <button
                            onClick={() => {
                              setPost(post?.body);
                              updateFeaturedImage(1, {
                                file: post?.body?.[1]?.state?.file,
                              });
                              setModalOpen(true);
                            }}
                            className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                          >
                            <PlusSmIcon
                              className="h-4 w-4 text-gray-700"
                              aria-hidden="true"
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    {post?.body?.[2]?.state?.text && (
                      <div
                        className="mt-3 mx-5 w-60 sm:w-104 text-sm font-medium text-gray-900"
                        dangerouslySetInnerHTML={{
                          __html: truncateString(
                            post?.body?.[2]?.state?.text,
                            200
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="py-4 px-5 w-60 sm:w-116">
            <p>No history available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default HistoryTab;
