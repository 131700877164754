import React from 'react';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import '../pages/NoMatch.scss';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to the console
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    this.setState({ error, errorInfo });

    // You can also log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2">
          <img
            className="mx-auto h-12 w-auto"
            src={logoIconPurple}
            alt="Bramework"
          />
          <h3 className="mt-8 text-sm font-medium text-purple-600">
            500 ERROR
          </h3>
          <h1 className="mt-2 text-3xl font-extrabold">Something went wrong</h1>
          <p className="mt-1 text-sm text-gray-500 text-center">
            Please try again later.
          </p>
          {process.env.NODE_ENV === 'development' && (
            <details className="mt-4 text-sm text-gray-500">
              <summary>Error Details</summary>
              <pre>{this.state.error && this.state.error.toString()}</pre>
              <pre>
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </pre>
            </details>
          )}
          <div className="mt-6">
            <Link
              to="/dashboard"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Go back home
            </Link>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
