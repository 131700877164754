import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import { track } from '../../services/track';
import * as moment from 'moment';
import { useAlert } from 'react-alert';
import useSentData from '../../services/useSentData';
import { isolateError } from '../../utils/api';

export default function RewriteItem({
  rewrite,
  getSelectedRewrite,
  user,
  allowDelete = false,
  onDelete = () => {},
  updateRewriteLikeStatus,
}) {
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setDeleteBtnClicked(false);
    }

    return () => {
      isCancelled = true;
    };
  }, [rewrite]);

  const updateLikeOutline = status => {
    const method = 'post';
    if (rewrite.like_status && rewrite?.like_status[0]?.status === status) {
      status = null;
    }
    const url = status ? `/api/like-rewrite` : '/api/like-rewrite-remove';
    const updatedData = { generation_id: rewrite.generation_id, status };
    updateRewriteLikeStatus(rewrite.generation_id, status);
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickCopy = rewrite => {
    return () => {
      navigator.clipboard.writeText(rewrite.output.text);
      alert.success('Successfully copied to clipboard!');
    };
  };

  return (
    <div className="question-item">
      <div className="flex justify-between mb-5 items-center">
        <span className="text-xs text-gray-400">
          {moment
            .duration(
              moment(rewrite.created_at).diff(moment(), 'seconds'),
              'seconds'
            )
            .humanize(true)}
        </span>
        <div className="flex items-center">
          {/*{(user.is_owner || user.is_manager || user.is_editor) && (*/}
          {/*  <div>*/}
          {/*    <button*/}
          {/*      className="inline-flex items-center px-2.5 py-1.5 border border-purple-700 text-xs font-medium rounded-full text-purple-700 bg-purple-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"*/}
          {/*      onClick={() => {*/}
          {/*        getSelectedRewrite(rewrite.output);*/}
          {/*        track('Rewrite generator', { Clicked: 'write post' });*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Write Post*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*)}*/}
          {allowDelete && (
            <div>
              <Tooltip title="Like" arrow>
                <button
                  onClick={() => updateLikeOutline(1)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                >
                  <ThumbUpIcon
                    className={`h-4 w-4 hover:text-green-500 ${
                      rewrite.like_status &&
                      rewrite.like_status[0]?.status === 1
                        ? 'text-green-500'
                        : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="DisLike" arrow>
                <button
                  onClick={() => updateLikeOutline(2)}
                  disabled={deleteBtnClicked}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <ThumbDownIcon
                    className={`h-4 w-4 hover:text-red-500 ${
                      rewrite.like_status &&
                      rewrite.like_status[0]?.status === 2
                        ? 'text-red-500'
                        : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Copy" arrow>
                <button
                  onClick={onClickCopy(rewrite)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                >
                  <DocumentDuplicateIcon
                    className={`h-4 w-4 text-gray-500`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  onClick={() => {
                    onDelete();
                    setDeleteBtnClicked(true);
                  }}
                  disabled={deleteBtnClicked}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <TrashIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-start justify-center gap-x-4">
        <div
          dangerouslySetInnerHTML={{ __html: rewrite.output.html }}
          className="flex-1"
        />
      </div>
    </div>
  );
}
