import React, { useEffect } from 'react';
import ChatAccordion from './ChatAccordion';
import { PlusSmIcon } from '@heroicons/react/solid';
import '../../pages/Chat.scss';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadConversations,
  loadFavoritePrompts,
  setActiveChatId,
  setChatMessage,
  setConnectToTheWeb,
  setMessages,
} from '../../store/actions/chat.actions';
import { Switch } from '@headlessui/react';
import Tooltip from '@material-ui/core/Tooltip';

const ChatLeftPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const connectToTheWeb = useSelector(state => state.chat.connectToTheWeb);
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );

  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );

  const handleNewChatClick = () => {
    history.push(`/chat`);
    dispatch(setActiveChatId(''));
    dispatch(setMessages(''));
    dispatch(setChatMessage(''));
  };

  useEffect(() => {
    dispatch(loadFavoritePrompts());
    dispatch(loadConversations());
  }, []);
  const handleFavoritePromptClick = () => {};

  return (
    <div className="chat-left-panel">
      <div className="new-chat-button p-2" onClick={handleNewChatClick}>
        <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
        <span>New Chat</span>
      </div>
      <div className="flex gap-4">
        <span className="text-white">Smart Web Navigator</span>
        <Tooltip
          title="Connect to the web, SEO, keywords, extract, analyze and more"
          arrow
        >
          <Switch
            checked={connectToTheWeb}
            disabled={
              !webAccessEnabled &&
              !smartWebNavigatorAccess &&
              !hasTrialSubscription
            }
            onChange={value => dispatch(setConnectToTheWeb(value))}
            className={`${
              connectToTheWeb ? 'bg-purple-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                connectToTheWeb ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </Tooltip>
      </div>
      <ChatAccordion
        onNewChat={handleNewChatClick}
        onFavoritePromptClick={handleFavoritePromptClick}
      />
    </div>
  );
};

export default ChatLeftPanel;
