import React, { useEffect, useRef, useState } from 'react';
import '../../pages/Chat.scss';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import {
  useSaveChatMessage,
  useSaveOrUpdateConversation,
  useSendMessage,
} from '../../utils/chatApi';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { track } from '../../services/track';
import {
  setActiveChatId,
  setMessages as setMessageList,
  setChatMessage,
  setLoading,
  loadConversations,
} from '../../store/actions/chat.actions';

const ChatInput = ({ chatId, editorRef }) => {
  const isPanelMode = useSelector(state => state.chat.isPanelMode);
  const isLoading = useSelector(state => state.chat.loading);
  const lookInTheEditor = useSelector(state => state.chat.lookInTheEditor);
  const connectToTheWeb = useSelector(state => state.chat.connectToTheWeb);
  const messages = useSelector(state => state.chat.messages);
  const chatMessage = useSelector(state => state.chat.chatMessage);

  const textareaRef = useRef(null);

  const { sendMessage } = useSendMessage();

  const { saveChatMessage } = useSaveChatMessage();

  const { saveOrUpdateConversation } = useSaveOrUpdateConversation();

  const history = useHistory();

  const [inputText, setInputText] = useState('');

  const dispatch = useDispatch();

  const setMessages = messages => {
    dispatch(setMessageList(messages));
  };

  const setIsLoading = loading => {
    dispatch(setLoading(loading));
  };

  useEffect(() => {
    setInputText(chatMessage);
  }, [chatMessage]);

  const handleInputChange = e => {
    setInputText(e.target.value);
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleSendMessage = async () => {
    if (isLoading) {
      return;
    }
    const isNewChat = !chatId;
    if (!chatId) {
      chatId = uuid();
      if (!isPanelMode) {
        history.push(`/chat/${chatId}`);
      }
      dispatch(setActiveChatId(chatId));
    }
    if (inputText.trim()) {
      const userMessage = {
        role: 'user',
        content: inputText.trim(),
        created_at: new Date().toLocaleString(),
      };
      track('Chat message', { Message: inputText.trim() });
      dispatch(setChatMessage(''));
      setIsLoading(true);
      const userMessageId = await saveChatMessage(chatId, userMessage);
      if (userMessageId) {
        let updatedMessages = [
          ...messages,
          { id: userMessageId, ...userMessage },
        ];
        setInputText('');
        setMessages(updatedMessages);

        try {
          const aiResponse = await sendMessage(
            [...updatedMessages.map(({ id, created_at, ...rest }) => rest)],
            {
              connectToTheWeb,
              lookInTheEditor,
              lookback: lookInTheEditor
                ? editorRef?.current?.editor.getContent({ format: 'text' })
                : undefined,
            }
          );

          if (aiResponse) {
            const aiMessage = {
              role: 'assistant',
              content: aiResponse,
              created_at: new Date().toLocaleString(),
            };
            const aiMessageId = await saveChatMessage(chatId, aiMessage);
            if (aiMessageId) {
              updatedMessages = [
                ...updatedMessages,
                { id: aiMessageId, ...aiMessage },
              ];
              await saveOrUpdateConversation(chatId, inputText.trim());
            }
            setIsLoading(false);
            setMessages(updatedMessages);
            if (isNewChat) {
              dispatch(loadConversations());
            }
          }
        } catch (error) {
          console.error('Error sending message to API:', error);
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="chat-input-container mt-4">
      <textarea
        className="chat-input block w-full shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
        value={inputText}
        onChange={handleInputChange}
        ref={textareaRef}
        placeholder="Write your message here…"
      />
      <div className="chat-input-actions">
        <button
          disabled={isLoading}
          className="chat-send-button text-purple-600 disabled:opacity-50 disabled:cursor-default"
          onClick={handleSendMessage}
        >
          <PaperAirplaneIcon className="h-6 w-6 m-2 " />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
