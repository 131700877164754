import React from 'react';
import './ResultItem.scss';
import classNames from 'classnames';
import { formatNumber } from '../../utils/common';
import Tip from '../../components/Tip';

export default function ResultItem({ title, complexity, value, tip }) {
  return (
    <div className="result-item-component form-card">
      <div className="item-title-row clearfix">
        <div className="item-title">{title}&nbsp;</div>
        <Tip text={tip} />
        <div className={classNames('item-complexity', complexity.color)}>
          {complexity.title}
        </div>
      </div>
      <div className="item-value">
        {value === null || 0 ? <span>0</span> : formatNumber(value)}
      </div>
    </div>
  );
}
