function isolateError(errors, defaultError) {
  if (
    errors &&
    errors.response &&
    errors.response.data &&
    typeof errors.response.data === 'object'
  ) {
    const error = Object.values(errors.response.data);
    if (error && error.length) {
      if (Array.isArray(error[0]) && error[0].length) {
        return error[0][0];
      } else {
        return error[0];
      }
    }
  }
  if (defaultError !== undefined) {
    return defaultError;
  }
  return 'Something went wrong. Try again later.';
}

function isolateSuccess(data, defaultMessage) {
  if (data && data.detail) {
    return data.detail;
  }

  if (defaultMessage !== undefined) {
    return defaultMessage;
  }
  return 'Success.';
}

export { isolateError, isolateSuccess };
