import { authActionTypes } from '../reducers/auth.reducer';
import http from '../../services/http';
import decodeToken from '../../services/decodeToken';
import { onSignIn, track } from '../../services/track';
import { forceChoosePlan } from './access.actions';
import {
  novaOnBoardingLocalStoreNames,
  novaOnBoardingSteps,
} from '../reducers/nova_onboarding.reducer';
import { setNovaOnBoardingStep } from './nova_onboarding.action';

export function authenticate(data, deactivateTokens = false) {
  return dispatch => {
    dispatch({ type: authActionTypes.AUTH_REQUEST });
    http
      .post('/api/login', data)
      .then(tokenRes => {
        const token = tokenRes.data.token;
        const decoded = decodeToken(token);
        http.defaults.headers.common.Authorization = `JWT ${token}`;
        return http.get(`/api/users/${decoded.user_id}`).then(userRes => {
          const user = userRes.data;
          if (!user.has_access) {
            dispatch(forceChoosePlan());
          }
          saveAuth(token, user);
          dispatch({ type: authActionTypes.AUTH_SUCCESS, user });
          dispatch(updateTmpUser(null));
        });
      })
      .catch(error => {
        dispatch({ type: authActionTypes.AUTH_FAILURE, error });
      });
  };
}

export function authLogout() {
  // Call /logout API to deactivate existing tokens
  return dispatch => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return dispatch({
        type: authActionTypes.AUTH_LOGOUT,
      });
    }
    http.defaults.headers.common.Authorization = `JWT ${token}`;
    http
      .get(`/api/logout`)
      .then(logoutRes => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        sessionStorage.removeItem('isOnBoardingUser');
        http.defaults.headers.common.Authorization = '';
        track('Log out');
        if (process.env.REACT_APP_INTERCOM_ID) {
          window.Intercom('shutdown', {
            app_id: process.env.REACT_APP_INTERCOM_ID,
          });
        }
      })
      .catch(err => {
        // If the in-use token is invalid (deactivated by other login)
        // => only clear the stored token
        const status = err?.response?.status;
        if (!!status) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('user');
          sessionStorage.removeItem('isOnBoardingUser');
          http.defaults.headers.common.Authorization = '';
          track('Log out');
          if (process.env.REACT_APP_INTERCOM_ID) {
            window.Intercom('shutdown', {
              app_id: process.env.REACT_APP_INTERCOM_ID,
            });
          }
        } else {
          console.error(err);
        }
      })
      .finally(() => {
        dispatch({
          type: authActionTypes.AUTH_LOGOUT,
        });
        window.location.reload();
        return;
      });
  };
}

export function authCheck() {
  let accessToken = localStorage.getItem('access_token');
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    user = null;
    accessToken = null;
  }

  const isAuthenticated = !!accessToken;
  if (isAuthenticated) {
    http.defaults.headers.common.Authorization = `JWT ${accessToken}`;
  }
  return {
    type: authActionTypes.AUTH_CHECK,
    isAuthenticated,
    user,
  };
}

export function updateUser({
  available_words,
  plagiarism_word_limit,
  full_name,
  city,
  state,
  country,
  profession,
  company,
  settings,
  avatar,
  preview,
  billing,
  subscription,
  app_sumo_subscription,
  is_intro,
  is_confirmed,
  has_trial_subscription,
  has_subscription,
  detailed_outline,
  additional_plagiarism_words,
  additional_words,
  first_draft_credits,
  addon_allowed_words,
  addon_plagiarism,
  addon_first_draft_credits,
  isSemrushConnected = false,
  has_google_analytics,
  has_google_search_console,
  smart_web_navigator_access,
  blog_booster_access,
}) {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return;
  }

  user = {
    ...user,
    available_words:
      available_words === undefined ? user.available_words : available_words,
    plagiarism_word_limit:
      plagiarism_word_limit === undefined
        ? user.plagiarism_word_limit
        : plagiarism_word_limit,
    full_name: full_name === undefined ? user.full_name : full_name,
    additional_plagiarism_words:
      additional_plagiarism_words === undefined
        ? user.additional_plagiarism_words
        : additional_plagiarism_words,
    additional_words:
      additional_words === undefined ? user.additional_words : additional_words,
    first_draft_credits:
      first_draft_credits === undefined
        ? user.first_draft_credits
        : first_draft_credits,
    addon_allowed_words:
      addon_allowed_words === undefined
        ? user.addon_allowed_words
        : addon_allowed_words,
    addon_plagiarism:
      addon_plagiarism === undefined ? user.addon_plagiarism : addon_plagiarism,
    addon_first_draft_credits:
      addon_first_draft_credits === undefined
        ? user.addon_first_draft_credits
        : addon_first_draft_credits,
    city: city === undefined ? user.city : city,
    state: state === undefined ? user.state : state,
    country: country === undefined ? user.country : country,
    profession: profession === undefined ? user.profession : profession,
    company: company === undefined ? user.company : company,
    settings: settings === undefined ? user.settings : settings,
    billing: billing === undefined ? user.billing : billing,
    subscription: subscription === undefined ? user.subscription : subscription,
    has_trial_subscription:
      has_trial_subscription === undefined
        ? subscription === undefined || subscription == null
          ? user.has_trial_subscription
          : subscription && subscription.status === 'in_trial'
          ? true
          : false
        : has_trial_subscription,
    has_subscription:
      has_subscription === undefined ? user.has_subscription : has_subscription,
    app_sumo_subscription:
      app_sumo_subscription === undefined
        ? user.app_sumo_subscription
        : app_sumo_subscription,
    avatar: avatar === undefined ? user.avatar : avatar,
    preview: preview === undefined ? user.preview : preview,
    is_intro: is_intro === undefined ? user.is_intro : is_intro,
    detailed_outline:
      detailed_outline === undefined ? user.detailed_outline : detailed_outline,
    is_confirmed: is_confirmed === undefined ? user.is_confirmed : is_confirmed,
    isSemrushConnected: isSemrushConnected,
    has_google_analytics:
      has_google_analytics === undefined
        ? user.has_google_analytics
        : has_google_analytics,
    has_google_search_console:
      has_google_search_console === undefined
        ? user.has_google_search_console
        : has_google_search_console,
    smart_web_navigator_access:
      smart_web_navigator_access === undefined
        ? user.smart_web_navigator_access
        : smart_web_navigator_access,
    blog_booster_access:
      blog_booster_access === undefined
        ? user.blog_booster_access
        : blog_booster_access,
  };

  localStorage.setItem('user', JSON.stringify(user));

  return {
    type: authActionTypes.UPDATE_USER,
    user,
  };
}

export const updateTmpUser = tmpUser => {
  return dispatch => {
    dispatch({
      type: authActionTypes.UPDATE_TMP_USER,
      tmpUser,
    });
  };
};

export const setIsOnBoardingUser = isOnBoardingUser => {
  return dispatch => {
    localStorage.removeItem(
      novaOnBoardingLocalStoreNames.NOVA_GUIDE_CHECK_LIST
    );
    dispatch(setNovaOnBoardingStep(novaOnBoardingSteps.WRITE_A_FIRST_DRAFT));
    dispatch({
      type: authActionTypes.SET_IS_ONBOARDING_USER,
      isOnBoardingUser,
    });
  };
};

function saveAuth(token, user) {
  onSignIn(user);
  localStorage.setItem('access_token', token);
  localStorage.setItem('user', JSON.stringify(user));
}
