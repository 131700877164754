import React from 'react';
import classNames from 'classnames';
import './FormInput.scss';

export default function FormInput({
  id,
  type = 'text',
  label,
  value,
  className,
  onChange,
  onBlur,
  onKeyUp,
  isValid,
  disabled,
  inputProps = null,
  inputClassName = '',
}) {
  return (
    <div className={classNames('form-input-wrapper', className)}>
      <label htmlFor={id}>{label}</label>
      <input
        {...inputProps}
        id={id}
        name={id}
        type={type}
        value={value}
        onKeyUp={e => onKeyUp && onKeyUp(e)}
        onChange={e => onChange(e.target.value)}
        className={classNames(
          'focus:ring-purple-500 focus:border-purple-500',
          {
            'has-value': !!value,
            'is-valid': isValid,
          },
          inputClassName
        )}
        onBlur={onBlur}
        disabled={disabled}
        data-hj-allow
      />
    </div>
  );
}
