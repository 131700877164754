const menuActionTypes = {
  SHOW_MENU: 'SHOW_MENU',
  HIDE_MENU: 'HIDE_MENU',
};

const initialState = {
  isCollapsed: false
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case menuActionTypes.SHOW_MENU:
      return {
        isCollapsed: false
      };
    case menuActionTypes.HIDE_MENU:
      return {
        isCollapsed: true
      };
    default:
      return state;
  }
};

export default menuReducer;
export { menuActionTypes };
