import {
  ClipboardCopyIcon,
  TrashIcon,
  XIcon,
  PlusIcon,
  PlusSmIcon,
  PencilIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import Modal from 'react-modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import FormInput from '../form/FormInput';
import { Disclosure } from '@headlessui/react';
import { isolateError } from '../../utils/api';

function Accordion({ isOpen, children }) {
  const accordionRef = useRef();

  return (
    <div className="w-full mt-4">
      <div className="w-full mx-auto bg-white rounded-2xl">
        <Disclosure defaultOpen={true}>
          {({ open, close }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full text-md font-medium text-left rounded-lg px-2">
                <b>Our favorites</b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-black`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="text-sm text-gray-500 mt-2">
                <button
                  className="hidden"
                  ref={accordionRef}
                  onClick={() => close()}
                />
                {children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

function ManageCommandModal({ isOpen, setIsOpen, editorRef }) {
  const [commands, setCommands] = useState([]);
  const [defaultCommands, setDefaultCommands] = useState([]);
  const [isShowDefaultCommand, setIsShowDefaultCommand] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState('');
  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    setIsShowDefaultCommand(true);
    fetchFavoriteCommand();
  }, []);

  const fetchFavoriteCommand = async command => {
    const url = `/api/fetch-favorite-command`;
    const method = 'get';
    return request
      .send(url, null, method)
      .then(response => {
        if (!response.isAxiosError) {
          const commands = response.data.filter(item => !item.default);
          const defaults = response.data
            .filter(item => item.default)
            .map((item, id) => ({ ...item, id }));
          setCommands(commands);
          setDefaultCommands(defaults);
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickDelete = item => {
    return () => {
      if (item.isNew) {
        const updatedList = commands.filter(value => value.id !== item.id);
        setCommands(updatedList);
        return;
      }
      const url = `/api/delete-favorite-command/${item.generation_id}`;
      const method = 'delete';
      return request
        .send(url, null, method)
        .then(response => {
          if (!response.isAxiosError) {
            const updatedList = commands.filter(
              value => value.generation_id !== item.generation_id
            );
            setCommands(updatedList);
          } else {
            alert.error(isolateError(response));
          }
        })
        .catch(err => alert.error(err.message));
    };
  };

  const onClickAdd = item => {
    return () => {
      if (editorRef?.current?.editor) {
        editorRef?.current?.editor?.insertContent(
          item.command || item.textDraft || ''
        );
      }
    };
  };

  const onClickEdit = item => {
    return () => {
      item.isEditable = true;
      item.textDraft = item.command;
      setCommands([...commands]);
    };
  };

  const onClickSave = item => {
    return () => {
      const url = item.isNew
        ? `/api/save-favorite-command`
        : `/api/edit-favorite-command`;
      const data = {
        command: item.textDraft,
        generation_id: item.generation_id,
      };
      const method = 'post';
      return request.send(url, data, method).then(response => {
        if (!response.isAxiosError) {
          item.isEditable = false;
          item.command = item.textDraft;
          item.generation_id =
            item.generation_id || response.data.generation_id;
          setCommands([...commands]);
        } else {
          alert.error(isolateError(response));
        }
      });
    };
  };

  const onChangeCommandValue = (item, value) => {
    item.textDraft = value;
    setCommands([...commands]);
  };

  const onClickCopy = item => {
    return () => {
      navigator.clipboard.writeText(item.command);
      alert.success('Successfully copied to clipboard!');
    };
  };

  const onClickAddNewCommand = () => {
    const newCommands = [...commands];
    newCommands.push({
      id: commands.length + 1,
      text: '',
      isEditable: true,
      isNew: true,
    });
    setCommands(newCommands);
  };

  const getDisplayCommands = data => {
    if (keywordSearch) {
      return data.filter(item =>
        item.command?.toLocaleLowerCase().includes(keywordSearch.toLowerCase())
      );
    }
    return data;
  };

  return (
    <Modal
      isOpen={isOpen}
      className={'manage-command-modal rounded-lg'}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="text-center font-bold text-2xl pt-5">
        Manage your commands
        <div className="px-6 py-4">
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <input
              type="text"
              placeholder="Search commands"
              onChange={e => setKeywordSearch(e.target.value)}
              className="w-full py-3 pl-12 pr-4 text-gray-500 border border-gray-300 rounded-md outline-none bg-white focus:bg-white focus:border-purple-500"
            />
          </div>
        </div>
      </div>
      <div className="modal-content px-6">
        <Accordion isOpen={isShowDefaultCommand}>
          {getDisplayCommands(defaultCommands).map(item => (
            <div
              key={item.id}
              className="flex bg-white cursor-pointer py-2 px-4 rounded-md my-2 text-sm justify-between items-center border-2 border-gray-100 shadow-sm"
            >
              <p>{item.command}</p>
              <div className="flex items-center">
                <div>
                  <Tooltip title="Insert in editor" arrow>
                    <button
                      onClick={onClickAdd(item)}
                      className="inline-flex items-center mr-3 p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <PlusSmIcon
                        className="h-4 w-4 text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Copy" arrow>
                    <button
                      onClick={onClickCopy(item)}
                      className="inline-flex mr-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                    >
                      <ClipboardCopyIcon
                        className={`h-4 w-4 text-gray-500`}
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
        </Accordion>
        <div className="w-full">
          {getDisplayCommands(commands).map((item, index) => (
            <div
              key={item.generation_id || item.id}
              className="flex bg-white cursor-pointer gap-x-4 py-2 px-4 items-center rounded-md my-1 text-sm text-gray-500 justify-between  border-2 border-gray-100 shadow-sm"
            >
              {item.isEditable ? (
                <FormInput
                  className="w-full"
                  value={item.textDraft || item.command}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      onClickSave(item)();
                    }
                  }}
                  onChange={value => onChangeCommandValue(item, value)}
                />
              ) : (
                <p>{item.command}</p>
              )}
              <div className="flex items-center">
                <div>
                  <Tooltip title="Insert in editor" arrow>
                    <button
                      disabled={request.loading}
                      onClick={onClickAdd(item)}
                      className="inline-flex items-center mr-3 p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <PlusSmIcon
                        className="h-4 w-4 text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title={item.isEditable ? 'Save' : 'Edit'} arrow>
                    <button
                      disabled={request.loading}
                      onClick={
                        item.isEditable ? onClickSave(item) : onClickEdit(item)
                      }
                      className="inline-flex items-center mr-3 p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      {item.isEditable ? (
                        <SaveIcon
                          className="h-4 w-4 text-gray-700"
                          aria-hidden="true"
                        />
                      ) : (
                        <PencilIcon
                          className="h-4 w-4 text-gray-700"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Copy" arrow>
                    <button
                      onClick={onClickCopy(item)}
                      className="inline-flex mr-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                    >
                      <ClipboardCopyIcon
                        className={`h-4 w-4 text-gray-500`}
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Delete" arrow>
                    <button
                      disabled={request.loading}
                      onClick={onClickDelete(item)}
                      className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <TrashIcon
                        className="h-4 w-4 text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={onClickAddNewCommand}
            className="w-full cursor-pointer px-6 py-2 mt-2 text-green-500 flex border border-green-500 text-md font-medium rounded-md shadow-sm bg-white hover:bg-green:200 "
          >
            <PlusIcon className="w-6 h-6 mr-2 " /> Add
          </button>
        </div>
        <button
          type="button"
          className="bg-white btn-close rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={() => setIsOpen(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </Modal>
  );
}

export default ManageCommandModal;
