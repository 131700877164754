import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authenticate } from '../../store/actions/auth.actions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import NotFound from '../../styles/images/404.jpg';

const OneSession = () => {
  const history = useHistory();
  const tmpUser = useSelector(state => state.auth.tmpUser);
  const dispatch = useDispatch();
  const authenticateUser = (value, deactivateTokens) => dispatch(authenticate(value, deactivateTokens));
  const [loading, setLoading] = useState();

  const logIn = async () => {
    setLoading(true);
    authenticateUser(
      { email: tmpUser?.email, password: tmpUser?.password },
      true);
  };

  useEffect(() => {
    if (!tmpUser) {
      setLoading(false)
      history.push('/');
    }
  }, [tmpUser]);

  return (
    <section className="h-full w-full flex items-center justify-center font-sans">
      <div className="flex items-start justify-center max-w-2xl gap-x-8 flex-col px-6 md:flex-row md:px-0">
        <div className="flex-none w-48 flex flex-col my-4">
          <img src={NotFound} className="max-w-full rounded-lg" />
        </div>
        <div className="flex-1 flex flex-col items-start gap-y-8">
          <h1 className="font-bold text-3xl">
            Too many devices logged into one account
          </h1>
          <p className="italic">
            To continue with this device, please click this button to logout of
            the previous device
          </p>
          <PrimaryButton onClick={logIn} disabled={loading}>
            {loading && (
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
            )}
            Continue</PrimaryButton>
        </div>
      </div>
    </section>
  );
};

export default OneSession;
