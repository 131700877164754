import React, { useEffect, useMemo, useState } from 'react';
import AiWritingToolsSelect from './form/AiWritingToolsSelect';
import './AIWritingToolsTab.scss';
import TitlesTab from './ai-writing-tools/TitlesTab';
import OutlinesTab from './ai-writing-tools/OutlinesTab';
import ParagraphTab from './ai-writing-tools/ParagraphTab';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PostBuilderUtils from '../utils/PostBuilderUtils';
import useSentData from '../services/useSentData';
import CommandTab from './ai-writing-tools/CommandTab';
import RephraseTab from './ai-writing-tools/RephraseTab';
import SummarizeTab from './ai-writing-tools/SummarizeTab';
import StepTab from './ai-writing-tools/StepTab';
import ListicleTab from './ai-writing-tools/ListicleTab';

const TOOLS = {
  title: TitlesTab,
  outline: OutlinesTab,
  paragraph: ParagraphTab,
  command: CommandTab,
  rephrase: RephraseTab,
  summarize: SummarizeTab,
  steps: StepTab,
  listicle: ListicleTab,
};

export default function AIWritingToolsTab({ editorRef }) {
  const request = useSentData();
  let { project_id } = useParams();
  const toolSelected = useSelector(state => state.post.aiWritingToolSelected);
  const postId = useSelector(state => state.post.id);
  const tone = useSelector(state => state.post.tone);
  const instructions = useSelector(state => state.post.instructions);
  const selectedKeywords = useSelector(state => state.post.selectedKeywords);
  const length = useSelector(state => state.post.length);
  const isPanelCollapsed = useSelector(state => state.panel.isPanelCollapsed);
  const additionalKeywordsButtonOn = useSelector(
    state => state.post.additionalKeywordsButtonOn
  );
  const instructionButtonOn = useSelector(
    state => state.post.instructionButtonOn
  );
  const useBrandVoice = useSelector(state => state.post.useBrandVoice);

  const aiGenerate = async action => {
    const { editor } = editorRef.current;
    const selection = editor.selection.getContent({
      format: 'text',
    });
    let lookback = '';
    if (action === 'generate-list') {
    } else if (action === 'generate-steps') {
    } else if (action === 'write-paragraph') {
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    } else if (action === 'rewrite-text') {
    } else if (action === 'summarize-text') {
    } else if (action === 'generate-outline') {
    } else if (action === 'ai-command') {
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    } else {
      lookback = PostBuilderUtils.getContentBeforeCursor(editor);
    }

    const response = await request.send(
      `/api/projects/${project_id}/posts/${postId}/${action}`,
      {
        selection,
        lookback,
        tone: tone.value,
        instructions: instructionButtonOn ? instructions : '',
        use_brand_voice: useBrandVoice,
        additional_keywords: {
          selected: additionalKeywordsButtonOn,
          keywords: additionalKeywordsButtonOn
            ? selectedKeywords.split(',')
            : [],
        },
        length,
      }
    );
    if (!response.isAxiosError) {
      if (response.content) {
        return { ...response.content, isNewItem: true };
      } else {
        alert(response.message);
      }
    }
  };

  useEffect(() => {
    const btnGenerates = document.getElementsByClassName('btn-generate');
    for (let i = 0; i < btnGenerates.length; i++) {
      if (!isPanelCollapsed) {
        btnGenerates[i].classList.remove('hidden');
      } else {
        btnGenerates[i].classList.add('hidden');
      }
    }
  }, [isPanelCollapsed]);

  return (
    <div className="overflow-y-auto  px-5">
      <div className="ai-writing-tab">
        <AiWritingToolsSelect toolSelected={toolSelected} />
        <div>
          {TOOLS[toolSelected] &&
            React.createElement(TOOLS[toolSelected], { aiGenerate, editorRef })}
        </div>
      </div>
    </div>
  );
}
