import { chatActionTye } from '../reducers/chat.reducer';
import http from '../../services/http';

const setChatMessage = payload => ({
  type: chatActionTye.SET_CHAT_MESSAGE,
  payload,
});

const setConnectToTheWeb = payload => ({
  type: chatActionTye.SET_CONNECT_TO_THE_WEB,
  payload,
});

const setLoading = payload => ({
  type: chatActionTye.SET_LOADING,
  payload,
});

const setLookInTheEditor = payload => ({
  type: chatActionTye.SET_LOOK_IN_THE_EDITOR,
  payload,
});

const setMessages = payload => ({
  type: chatActionTye.SET_MESSAGES,
  payload,
});

const setPanelMode = payload => ({
  type: chatActionTye.SET_PANEL_MODE,
  payload,
});

const setActiveChatId = payload => {
  return {
    type: chatActionTye.SET_ACTIVE_CHAT_ID,
    payload,
  };
};

const setShowScrollBottomButton = payload => {
  return {
    type: chatActionTye.SET_SCROLL_BOTTOM_BUTTON,
    payload,
  };
};

const loadFavoritePrompts = () => {
  return async dispatch => {
    try {
      const response = await http.post('/api/get-prompts');
      dispatch({
        type: chatActionTye.SET_FAVORITE_PROMPT,
        payload: response.data || [],
      });
    } catch (error) {}
  };
};

const loadConversations = () => {
  return async dispatch => {
    try {
      const response = await http.post('/api/get-conversations');
      dispatch({
        type: chatActionTye.SET_CONVERSATIONS,
        payload: (response.data || []).sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        ),
      });
    } catch (error) {}
  };
};

export {
  setChatMessage,
  setMessages,
  loadFavoritePrompts,
  loadConversations,
  setPanelMode,
  setActiveChatId,
  setConnectToTheWeb,
  setLookInTheEditor,
  setLoading,
  setShowScrollBottomButton,
};
