// CollapsibleSection.js
import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const CollapsibleSection = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const sectionRef = useRef(null);
  const [sectionWidth, setSectionWidth] = useState('100%');

  useEffect(() => {
    if (sectionRef.current) {
      const width = sectionRef.current.offsetWidth;
      setSectionWidth(`${width}px`);
    }
  }, []);

  return (
    <div
      ref={sectionRef}
      className="collapsible-section"
      style={{ width: isCollapsed ? sectionWidth : '100%' }}
    >
      <div
        className="section-header"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h2>{title}</h2>
        {isCollapsed ? (
          <ChevronDownIcon className="w-5 h-5 flex-shrink-0" />
        ) : (
          <ChevronUpIcon className="w-5 h-5 flex-shrink-0" />
        )}
      </div>
      {!isCollapsed && <div className="section-content">{children}</div>}
    </div>
  );
};

export default CollapsibleSection;
