import { useCallback, useState } from 'react';
import Http from './http';

export default function() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const load = useCallback((path, params) => {
    setLoading(true);
    setError(null);
    setData(null);
    return Http.get(path, { params })
      .then(res => {
        setLoading(false);
        setError(null);
        setData(res.data);
        return res.data;
      })
      .catch(error => {
        setLoading(false);
        setError(error);
        setData(null);
        return error;
      });
  }, []);

  return { data, error, loading, load };
}
