import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AccordionItem from './AccordionItem';
import { useGetFavoritePrompts } from '../../utils/chatApi';
import { useParams } from 'react-router-dom';

const ChatAccordion = ({
  onChatHistoryItemClick,
  onFavoritePromptClick,
  onDeleteChatItemClick,
}) => {
  const { chatId: activeChatId } = useParams();
  const favoritePrompts = useSelector(state => state.chat.favoritePrompts);
  const conversations = useSelector(state => state.chat.conversations);

  const accordionData = [
    {
      title: 'Chat History',
      content: conversations.map(chat => ({
        id: chat.id,
        snippet: chat.snippet.slice(0, 30) + '...',
        is_deleted: false,
      })),
    },
    {
      title: 'Favorite Prompts',
      content: favoritePrompts
        ? favoritePrompts.map(message => (message ? message.content : ''))
        : [],
    },
    {
      title: 'Suggested Prompts',
      content: [
        'Conduct in-depth research on [keyword] and provide a concise, detailed report',
        'Could you analyze this keyword [keyword]',
        'What are some related keywords for [keyword]',
        'Could you suggest keywords for [keyword]',
        'Write a blog outline for me about [keyword]',
        "Can you check if my blog post on [keyword] is SEO optimized? Here's the post: [URL]",
        'Which websites are the main competitors of this site [URL]',
        'What keywords are driving traffic to this site [URL]',
        'Extract the LSI keywords from this post: [URL]',
        'Get the highest-ranking search results for [keyword]',
        'What questions do people ask about [keyword]',
        'Please fact-check this information: [Information]',
        'Create a blog post from this YouTube Video: [URL]',
        'Can you extract the main points from this YouTube Video: [URL]',
        // 'What topics should I blog about today? My website is [URL]',
        // 'Is the topic [keyword] good for me to blog about?',
        // 'Should I write about [keyword] in my blog post?',
        // 'Tell me the keywords I should use in my blog post about [keyword]',
        // 'Write a content brief for me about [keyword] my website is [URL]',
        // 'Please check if my content is optimized for SEO, my keyword is [keyword], my blog post is [URL]',
        // 'How many people visited my website today?',
        // 'How many times did people visit my website this week?',
        // 'How many times did people visit my website this month?',
        // 'What are the top 10 search words people use to find my blog?',
        // 'Which 10 articles on my blog are the most popular?',
        // 'Which 5 articles should I share more to get more readers?',
        // 'Which 3 articles need some changes or updates?',
      ],
    },
  ];

  return (
    <div>
      {accordionData.map((item, idx) => (
        <AccordionItem
          key={idx}
          title={item.title}
          content={item.content}
          onItemClick={
            item.title === 'Favorite Prompts'
              ? favoritePrompt => onFavoritePromptClick(favoritePrompt)
              : null
          }
          onChatHistoryItemClick={
            item.title === 'Chat History' ? onChatHistoryItemClick : null
          }
          activeItemId={item.title === 'Chat History' ? activeChatId : null}
          onDeleteChatItemClick={
            item.title === 'Chat History' ? onDeleteChatItemClick : null
          }
        />
      ))}
    </div>
  );
};

export default ChatAccordion;
