function listToOption(list) {
  if (list && Array.isArray(list)) {
    return list.map(item => ({ label: item.title, value: item.id }));
  }
  return [];
}

function countryToOption(list) {
  if (list && Array.isArray(list)) {
    return list.map(item => ({ label: item.title, value: item.code }));
  }
  return [];
}

function timezonesToOption(list) {
  if (list && Array.isArray(list)) {
    return list.map(item => ({ label: item[1], value: item[0] }));
  }
  return [];
}

function chooseOption(options, element) {
  if (options && Array.isArray(options) && element) {
    return options.find(item => element === item.value);
  }
}

export { listToOption, chooseOption, timezonesToOption, countryToOption };
