import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PencilIcon,
  StarIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  ClipboardIcon,
  CheckIcon,
  XIcon,
  ArrowSmDownIcon,
} from '@heroicons/react/solid';
import defaultAvatar from '../../styles/images/default-avatar.svg';
import novaIcon from '../../styles/images/nova-onboarding.svg';
import '../../pages/Chat.scss';
import { useAlert } from 'react-alert';
import {
  useDeleteFavoritePrompt,
  useSaveFavoritePrompt,
} from '../../utils/chatApi';
import { useParams } from 'react-router-dom';
import { loadFavoritePrompts } from '../../store/actions/chat.actions';
import { PlusIcon } from '@heroicons/react/outline';

const ChatScrollButton = ({ handleScrollToBottom }) => {
  const showScrollBottomButton = useSelector(
    state => state.chat.showScrollBottomButton
  );

  return (
    <>
      {showScrollBottomButton && (
        <button
          type="button"
          className="scroll-btn text-purple-500 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-purple-300 rounded-full  p-1 text-center inline-flex items-center mr-2"
        >
          <ArrowSmDownIcon onClick={handleScrollToBottom} className="w-5 h-5" />
        </button>
      )}
    </>
  );
};

export default ChatScrollButton;
