export const projectPostStatusProp = {
  id: 'id',
  status: 'status',
  description: 'description:',
  order: 'order',
  is_active: 'is_active',
};

export const getProjectPostStatuses = async (request, projectId) => {
  if (!projectId) throw new Error('Missing projectId');
  const url = `/api/projects/${projectId}/post-status`;
  const method = 'get';
  const res = await request.send(url, null, method);
  return res || [];
};

export const updatePostStatus = async (
  request,
  projectId,
  postId,
  newStatus
) => {
  const url = `/api/projects/${projectId}/posts/${postId}/update-status`;
  const method = 'post';
  const data = {
    status: newStatus,
  };

  const res = await request.send(url, data, method);
  return res;
};

export const createProjectPostStatus = async (
  request,
  projectId,
  newStatus,
  order
) => {
  const url = `/api/projects/${projectId}/post-status`;
  const method = 'post';
  const data = {
    status: newStatus,
    order,
  };
  const res = await request.send(url, data, method);
  return res;
};

export const deleteProjectPostStatus = async (request, projectId, statusId) => {
  const url = `/api/projects/${projectId}/post-status/${statusId}`;
  const method = 'delete';
  const res = await request.send(url, null, method);
  return res;
};

export const updateProjectPostStatus = async (
  request,
  projectId,
  statusId,
  updatedData
) => {
  const url = `/api/projects/${projectId}/post-status/${statusId}`;
  const method = 'put';
  const res = await request.send(url, updatedData, method);
  return res;
};

export const updateProjectPostStatusesOrder = async (
  request,
  projectId,
  updatedData
) => {
  const url = `/api/projects/${projectId}/post-status/update-status-order`;
  const method = 'post';
  const res = await request.send(url, updatedData, method);
  return res;
};
