import React, { useState } from 'react';
import classNames from 'classnames';
import './FormPassword.scss';
import eye from '../../styles/images/eye.svg';
import crossedEye from '../../styles/images/eye-slash.svg';

export default function FormPassword({
  id,
  label,
  value,
  className,
  onChange,
  onBlur,
  isValid,
}) {
  const [visible, show] = useState(false);

  return (
    <div className={classNames(className, 'form-password-wrapper')}>
      <label htmlFor={id}>{label}</label>
      {visible ? (
        <div className="inner-wrapper">
          <input
            id={id}
            name={id}
            type="text"
            value={value}
            onChange={e => onChange(e.target.value)}
            className={classNames(
              'focus:ring-purple-500 focus:border-purple-500',
              {
                'has-value': !!value,
                'is-valid': isValid,
              }
            )}
            onBlur={onBlur}
          />
          <div className="img-wrapper">
            <img
              src={crossedEye}
              alt=""
              onClick={() => {
                show(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="inner-wrapper">
          <input
            id={id}
            name={id}
            type="password"
            value={value}
            onChange={e => onChange(e.target.value)}
            className={classNames(
              'focus:ring-purple-500 focus:border-purple-500',
              {
                'has-value': !!value,
                'is-valid': isValid,
              }
            )}
            onBlur={onBlur}
          />
          <div className="img-wrapper">
            <img
              src={eye}
              alt=""
              onClick={() => {
                show(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
