import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { track } from '../../services/track';
import TabItems from './TabItems';
import { processHTML } from '../../utils/processHTML';
import { sortListByDate } from '../../utils/common';
import HighLightTip from './HighLightTip';
import Tip from '../ComponentTip';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';
import { addGeneratedCommand } from '../../store/actions/post_builder.actions';
import ManageCommandModal from './ManageCommandModal';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import { setNovaOnBoardingCheckList } from '../../store/actions/nova_onboarding.action';

function CommandTab({ aiGenerate, editorRef }) {
  const request = useSentData();
  const [commands, setCommands] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showManageCommandModal, setShowManageCommandModal] = useState(false);
  const [commandsLoading, setCommandsLoading] = useState(false);
  const [isShowVideoGuide, setIsShowVideoGuide] = useState(true);
  const alert = useAlert();
  const dispatch = useDispatch();
  const highLightWords = useSelector(state => state.post.highlightWords);
  const generatedCommands = useSelector(state => state.post.generatedCommands);
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );

  const minCharacter = 20;
  const maxCharacter = 300;

  useEffect(() => {
    if (generatedCommands) {
      sortListByDate(generatedCommands, 'created_at');
      setCommands(generatedCommands);
    }
  }, [generatedCommands]);

  const generateCommands = async () => {
    setExpanded(!expanded);
    track('Blog post creator', { Clicked: 'generate command' });
    setCommandsLoading(true);
    const data = await aiGenerate('ai-command');
    if (!data) {
      setCommandsLoading(false);
      return;
    }
    commands.push(data);
    setCommandsLoading(false);
    sortListByDate(commands, 'created_at');
    setCommands([...commands]);
    document.getElementsByClassName('tab-content')[0]?.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(
        addGeneratedCommand(
          commands.map(item => ({ ...item, isNewItem: false }))
        )
      );
    }, 4000);
  };

  const updateLikeCommand = (status, command) => {
    const method = 'post';
    if (command?.like_status && command?.like_status[0]?.status === status) {
      status = null;
    }
    const url = status ? `/api/like-command` : '/api/like-command-remove';
    const updatedData = { generation_id: command.generation_id, status };
    if (command.like_status) {
      command.like_status[0].status = status;
    } else {
      command.like_status = [{ status }];
    }

    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onDeleteCommand = ({ generation_id }, index) => {
    const url = `/api/delete-command`;
    const method = 'post';
    const updatedData = { generation_id };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newCommands = [...commands];
          newCommands.splice(index, 1);
          setCommands(newCommands);
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onClickAdd = command => {
    if (editorRef?.current?.editor) {
      if (
        command.output.html.startsWith('<p>') &&
        command.output.html.endsWith('</p>')
      ) {
        editorRef?.current?.editor?.insertContent(
          processHTML(command.output.html)
        );
      } else {
        editorRef?.current?.editor?.insertContent(command.output.html);
      }
      if (stepNovaOnBoardingGuide === novaOnBoardingSteps.COMPLETE_CHECK_LIST) {
        dispatch(
          setNovaOnBoardingCheckList(
            novaOnBoardingCheckLists.EDIT_WITH_REPHRASE_OR_COMMAND
          )
        );
      }
    }
  };

  const onClickCopy = command => {
    navigator.clipboard.writeText(command.output.text);
    alert.success('Successfully copied to clipboard!');
  };

  return (
    <div className="tool-tab">
      <Modal
        className={'command-modal'}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
      >
        <iframe
          src="https://www.youtube.com/embed/2ozo7EQqp-o"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div className="btn-close" onClick={() => setIsOpen(false)}>
          <XIcon className="w-6 h-6 md:w-10 md:h-10 text-white" />
        </div>
      </Modal>
      <ManageCommandModal
        isOpen={showManageCommandModal}
        editorRef={editorRef}
        setIsOpen={setShowManageCommandModal}
      />
      <div className="overflow-y-auto tab-content">
        <HighLightTip
          customMessage={
            'Type a command in the editor and then highlight at least 20 characters to generate copy.'
          }
        />
        <button
          onClick={() => setShowManageCommandModal(true)}
          className="p-2 mt-2 text-sm w-full  border border-transparent rounded-md shadow-sm font-medium text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none"
        >
          Manage your commands
        </button>
        <div className="flow-root divide-y divide-gray-200">
          <div className="mt-4" />
          <TabItems
            dataList={commands}
            onClickCopy={onClickCopy}
            onClickAdd={onClickAdd}
            onClickLike={command => updateLikeCommand(1, command)}
            onClickDislike={command => updateLikeCommand(2, command)}
            onClickDelete={onDeleteCommand}
          >
            {command => (
              <div
                className="w-80 sm:w-116 text-sm font-medium text-gray-900"
                dangerouslySetInnerHTML={{ __html: command.output.html }}
              />
            )}
          </TabItems>
        </div>
        {isShowVideoGuide && !commands.length ? (
          <div className="mt-4 text-center tell-the-ai">
            <div
              className="btn-close"
              onClick={() => setIsShowVideoGuide(false)}
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_982_29005)">
                  <rect
                    x="10.7188"
                    y="5"
                    width="15.5614"
                    height="15.5587"
                    rx="7.77937"
                    fill="white"
                    shape-rendering="crispEdges"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.9991 9.21967C15.7062 8.92678 15.2313 8.92678 14.9384 9.21967C14.6455 9.51256 14.6455 9.98744 14.9384 10.2803L17.4388 12.7807L14.9411 15.2784C14.6482 15.5713 14.6482 16.0462 14.9411 16.3391C15.234 16.632 15.7088 16.632 16.0017 16.3391L18.4994 13.8414L20.9972 16.3391C21.2901 16.632 21.7649 16.632 22.0578 16.3391C22.3507 16.0462 22.3507 15.5713 22.0578 15.2784L19.5601 12.7807L22.0605 10.2803C22.3534 9.98744 22.3534 9.51256 22.0605 9.21967C21.7676 8.92678 21.2927 8.92678 20.9998 9.21967L18.4994 11.72L15.9991 9.21967Z"
                    fill="#7429C6"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_982_29005"
                    x="0.71875"
                    y="0"
                    width="35.5605"
                    height="35.5586"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feMorphology
                      radius="5"
                      operator="erode"
                      in="SourceAlpha"
                      result="effect1_dropShadow_982_29005"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="7.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0948662 0 0 0 0 0.105575 0 0 0 0 0.112715 0 0 0 0.22 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_982_29005"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_982_29005"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div className="btn-play" onClick={() => setIsOpen(true)}>
              <svg
                width="101"
                height="100"
                viewBox="0 0 101 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_bd_982_28773)">
                  <rect
                    x="26.7188"
                    y="20"
                    width="48"
                    height="48"
                    rx="24"
                    fill="white"
                    fill-opacity="0.77"
                    shape-rendering="crispEdges"
                  />
                  <path
                    d="M50.7184 60.6668C59.9232 60.6668 67.3851 53.2049 67.3851 44.0002C67.3851 34.7954 59.9232 27.3335 50.7184 27.3335C41.5137 27.3335 34.0518 34.7954 34.0518 44.0002C34.0518 53.2049 41.5137 60.6668 50.7184 60.6668Z"
                    stroke="#881CFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M46.5518 38.9424C46.5518 38.1469 46.5518 37.7492 46.718 37.5271C46.8629 37.3336 47.0846 37.2126 47.3257 37.1953C47.6024 37.1756 47.937 37.3907 48.6061 37.8208L56.4738 42.8786C57.0544 43.2519 57.3447 43.4385 57.445 43.6758C57.5326 43.8832 57.5326 44.1172 57.445 44.3245C57.3447 44.5618 57.0544 44.7485 56.4738 45.1217L48.6061 50.1795C47.937 50.6097 47.6024 50.8248 47.3257 50.805C47.0846 50.7878 46.8629 50.6667 46.718 50.4732C46.5518 50.2512 46.5518 49.8534 46.5518 49.0579V38.9424Z"
                    stroke="#881CFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_bd_982_28773"
                    x="0.71875"
                    y="0"
                    width="100"
                    height="100"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="3.5"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_982_28773"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="13" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.154382 0 0 0 0 0.154382 0 0 0 0 0.154382 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect1_backgroundBlur_982_28773"
                      result="effect2_dropShadow_982_28773"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect2_dropShadow_982_28773"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="mt-6 mb-6 btn-generate">
        <ReactTooltip
          id={'command-tab'}
          place={'bottom'}
          type="dark"
          effect="float"
          border={false}
        />
        <Tip
          data-for={'command-tab'}
          text={
            minCharacter > highLightWords.length
              ? 'Type a command then highlight at least 20 characters.'
              : ''
          }
        >
          <button
            className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700
          hover:bg-purple-500 focus:command-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            onClick={generateCommands}
            disabled={minCharacter > highLightWords.length || commandsLoading}
          >
            {commandsLoading && (
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
            )}
            {commands.length > 0 ? 'Generate more' : 'Generate'}
          </button>
        </Tip>
      </div>
    </div>
  );
}

export default CommandTab;
