import React from 'react';
import './Header.scss';
import defaultAvatar from '../styles/images/default-avatar.svg';
import arrowDown from '../styles/images/arrow-down.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authLogout } from '../store/actions/auth.actions';
import { destroyProjects } from '../store/actions/project.action';
import { destroyTeam } from '../store/actions/team.actions';
import 'bootstrap';

function Header({ title, logout, destroyProjects, destroyTeam, name, avatar }) {
  return (
    <header className="header-wrapper">
      <h1>{title}</h1>
      <div className="selector-wrapper">
        <div className="dropdown">
          <button
            className="header-button clearfix"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={avatar} alt="" className="avatar" />
            <span>{name}</span>
            <img src={arrowDown} alt="" className="arrowDown" />
          </button>
          <div
            className="dropdown-menu menu-wrapper"
            aria-labelledby="dropdownMenuButton"
          >
            <Link to="/profile">My Profile</Link>
            <Link to="/account">My Account</Link>
            <Link to="/settings">Settings</Link>
            <a href="https://www.bramework.com/support/" target="_blank">
              Help
            </a>
            <Link
              to=""
              onClick={() => {
                destroyProjects();
                destroyTeam();
                // logout();
              }}
            >
              Log out efewf
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  const user = state.auth.user || {};
  return {
    name: user.full_name || user.email,
    avatar: user.preview || defaultAvatar,
  };
}

export default connect(mapStateToProps, {
  logout: authLogout,
  destroyProjects,
  destroyTeam,
})(Header);
