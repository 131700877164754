import React from 'react';

const ImprovementSummary = ({ summary }) => {
  if (!summary) return null;

  return (
    <div className="improvement-summary">
      <h3>Improvement Summary</h3>
      <div className="summary-item">
        <h4>Readability Score</h4>
        <p>Original: {summary.readability.original.toFixed(2)}</p>
        <p>Improved: {summary.readability.improved.toFixed(2)}</p>
        <p
          className={
            summary.readability.difference >= 0 ? 'positive' : 'negative'
          }
        >
          Difference: {summary.readability.difference.toFixed(2)}
        </p>
      </div>
      <div className="summary-item">
        <h4>SEO Score</h4>
        <p>Original: {summary.seo_score.original.toFixed(2)}</p>
        <p>Improved: {summary.seo_score.improved.toFixed(2)}</p>
        <p
          className={
            summary.seo_score.difference >= 0 ? 'positive' : 'negative'
          }
        >
          Difference: {summary.seo_score.difference.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default ImprovementSummary;
