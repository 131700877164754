import { useEffect, useState, useRef } from 'react';
import { Popover } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';

import PrimaryButton from '../buttons/PrimaryButton';
import {
  setUpdatedContentPopup,
  setSelectionBoundingClientRect,
} from '../../store/actions/post_builder.actions';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import { setNovaOnBoardingCheckList } from '../../store/actions/nova_onboarding.action';

function UpdatedContentPopup({ editorRef = null }) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const ref = useRef();

  const selectionBoundingClientRect = useSelector(
    state => state.post.selectionBoundingClientRect
  );
  const opened = useSelector(state => state.post.updatedContentPopup.opened);
  const content = useSelector(state => state.post.updatedContentPopup.content);
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const dispatch = useDispatch();
  let suggestedContent = content;
  const needPStart = suggestedContent.includes('</p>');
  if (needPStart) {
    suggestedContent = `<p>${content}`;
  }

  useEffect(() => {
    if (!!selectionBoundingClientRect) {
      setTop(Math.round(selectionBoundingClientRect?.bottom + 190) || 0);
      setLeft(Math.round(selectionBoundingClientRect?.left) || 0);
    } else {
      dispatch(setUpdatedContentPopup({ opened: false, content: '' }));
    }
  }, [selectionBoundingClientRect]);

  useEffect(() => {
    if (opened && !!ref) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [opened, ref]);

  return (
    <Popover as={'div'} className={`absolute`} style={{ top, left }}>
      <Popover.Panel static={opened} ref={ref}>
        <section className="relative z-20 bg-white min-h-20 w-full sm:w-120 rounded-lg border-gray-200 border-2 overflow-hidden">
          <XIcon
            className="w-6 absolute top-2 right-2 cursor-pointer"
            onClick={() => {
              dispatch(setUpdatedContentPopup({ opened: false, content: '' }));
              dispatch(setSelectionBoundingClientRect(null));
            }}
          />
          <h1 className="p-2 bg-purple-100 text-purple-700">
            The selected texts will be replaced with:
          </h1>
          <div
            className="px-2 py-5"
            dangerouslySetInnerHTML={{ __html: suggestedContent }}
          />
          <PrimaryButton
            className="m-2"
            onClick={() => {
              const editor = editorRef?.current?.editor;
              if (!!editor) {
                editor.insertContent(content);
                dispatch(
                  setUpdatedContentPopup({ opened: false, content: '' })
                );
                if (
                  stepNovaOnBoardingGuide ===
                  novaOnBoardingSteps.COMPLETE_CHECK_LIST
                ) {
                  dispatch(
                    setNovaOnBoardingCheckList(
                      novaOnBoardingCheckLists.EDIT_WITH_REPHRASE_OR_COMMAND
                    )
                  );
                }
              }
            }}
          >
            Replace
          </PrimaryButton>
        </section>
      </Popover.Panel>
    </Popover>
  );
}

export default UpdatedContentPopup;
