import { Fragment, useCallback, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  StatusOnlineIcon,
  CheckIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';

import ComponentTip from '../../ComponentTip';
import useSentData from '../../../services/useSentData';
import { setStatus } from '../../../store/actions/post_builder.actions';
import {
  getProjectPostStatuses,
  updatePostStatus,
} from '../../../utils/post-status';

const PostStatusMenu = ({ onSave = () => {} }) => {
  const [postStatuses, setPostStatuses] = useState([]);

  const { project_id, id } = useParams();
  const status = useSelector(state => state.post.status);
  const dispatch = useDispatch();
  const onStatusChange = value => dispatch(setStatus(value));
  const request = useSentData();
  const alert = useAlert();

  const updateStatus = useCallback(
    async newStatusId => {
      const res = await updatePostStatus(request, project_id, id, newStatusId);
      if (!res.isAxiosError) {
        onStatusChange(newStatusId);
      } else {
        alert.error('Failed to update post status');
      }
    },
    [project_id, id]
  );

  useEffect(() => {
    if (!!project_id) {
      getProjectPostStatuses(request, project_id)
        .then(data => {
          const activeStatuses = data?.filter(stat => stat.is_active);
          setPostStatuses(activeStatuses);
        })
        .catch(err => {
          alert.error(err.message);
        });
    }
  }, [project_id]);

  return (
    <ComponentTip text="Save">
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="text-gray-300 w-full hover:bg-gray-700 hover:text-white flex items-center rounded-lg">
            <SaveIcon className="h-6 w-6" aria-hidden="true" />
            <span className="sr-only">Status</span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 origin-top-right absolute w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 mt-2 overflow-y-auto max-h-60">
              {postStatuses.map(stat => (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      key={stat.status}
                      className={cx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm select-none flex gap-x-1 items-center cursor-pointer'
                      )}
                      onClick={async () => {
                        await updateStatus(stat.id);
                        onSave(stat.status);
                      }}
                    >
                      <p className="flex-1">{stat.status}</p>
                      {status === stat.id && (
                        <CheckIcon className="inline w-6" />
                      )}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </ComponentTip>
  );
};

export default PostStatusMenu;
