import React, { useEffect, useState, Fragment } from 'react';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import TeamMemberList from './Team/TeamMemberList';
import InviteMemberList from './Team/InviteMemberList';
import RoleFormSelect from '../components/form/RoleFormSelect';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  fetchTeamList,
  startLoading,
  deleteTeamMember,
} from '../store/actions/team.actions';
import { fetchRoleList } from '../store/actions/roles.actions';
import { useDispatch, useSelector } from 'react-redux';
import { isolateError } from '../utils/api';
import { useAlert } from 'react-alert';
import RemovedMemberList from './Team/RemovedMemberList';

function Team(props) {
  const alert = useAlert();
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [openTab, setOpenTab] = useState(0);
  const request = useSentData();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [removedMembers, setRemovedMembers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const tabs = [{ name: 'Members', count: props.team.length }];
  
  if (props.user.is_owner || props.user.is_manager) {
    tabs.push({ name: 'Invites', count: invitedMembers.length }, 
    { name: 'Removed members', count: removedMembers.length });
  }

  useEffect(() => {
    dispatch(props.startLoading());
    props.fetchRoleList();
    fetchInvitation();
    fetchRemovedMembers();
  }, []);

  useEffect(() => {
    if (props.roles.length > 0) {
      props.fetchTeamList();
    }
  }, [props.roles]);

  useEffect(() => {
    if (props.teamError) {
      alert.error(isolateError(props.teamError));
    }
  }, [props.teamError]);

  const fetchInvitation = () => {
    setLoading(true);
    request.send(`/api/invitation`, null, 'get').then(response => {
      if (!response.isAxiosError) {
        setInvitedMembers(response);
      } else {
        alert.error(isolateError(response));
      }
      setLoading(false);
    });
  };
  
  const fetchRemovedMembers = () => {
    setLoading(true);
    request.send(`/api/get-removed-members`, null, 'get').then(response => {
      if (!response.isAxiosError) {
        setRemovedMembers(response);
      } else {
        alert.error(isolateError(response));
      }
      setLoading(false);
    });
  };
  
   const sendRejoinLink = (memberId) => {
    if (request.loading) {
      return;
    }
    request
      .send(`/api/send-rejoin-link `, {
        member_id : memberId
      })
      .then(response => {
        if (!response.isAxiosError) {
          alert.success('Send rejoin link successfully!')
        } else {
          alert.error(isolateError(response));
        }
      });
  };

  const sendInvitation = e => {
    e.preventDefault();
    request
      .send(`/api/send-invitation`, {
        email: email,
        role: selectedRole,
      })
      .then(response => {
        if (!response.isAxiosError) {
          alert.success('Invitation sent successfully!')
          fetchInvitation();
        } else {
          alert.error(isolateError(response));
        }
      });
  };
  
  const onChangeTab = (i) => () => {
      setOpenTab(i);
       switch (i) {
         case 0 : props.fetchRoleList(); break;
         case 1 : fetchInvitation(); break;
         case 2 : fetchRemovedMembers(); break;
       }
  }

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const renderTabContent = () => {
    if (loading) {
      return <Loading />;
    }
    switch (openTab) {
      case 0:
        return props.team.length && props.roles.length ? (
          <TeamMemberList
            team={props.team}
            roles={props.roles}
            canDelete={props.user.is_owner || props.user.is_manager}
            deleteTeamMember={props.deleteTeamMember}
            user={props.user}
            fetchRoleList={props.fetchRoleList}
          />
        ) : (
          <div className="posts-list">
            <h1 className="empty-message">
              You haven’t added any team members to your account.
            </h1>
          </div>
        );
      case 1:
        return invitedMembers.length ? (
          <InviteMemberList
            invites={invitedMembers}
            fetchInvitation={fetchInvitation}
          />
        ) : (
          <div className="posts-list">
            <h1 className="empty-message">You have no pending invites.</h1>
          </div>
        );
     case 2:
        return removedMembers.length ? (
          <RemovedMemberList 
            roles={props.roles} 
            sendRejoinLink={sendRejoinLink}
            removedMembers={removedMembers}/>
        ) : (
          <div className="posts-list">
            <h1 className="empty-message">You have no removed members.</h1>
          </div>
        );   
    }
  };

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="Team" category="team" />}
    >
      {(props.user.is_owner || props.user.is_manager) && (
        <div>
          <form className="mt-6 sm:flex sm:items-center" action="#">
            <label htmlFor="emails" className="sr-only">
              Email addresses
            </label>
            <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
              <input
                type="text"
                name="emails"
                id="emails"
                className="focus:ring-purple-500 focus:border-purple-500 block w-full pr-32 sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter an email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <span className="h-4 w-px bg-gray-200" aria-hidden="true" />
                <label htmlFor="role" className="sr-only">
                  Role
                </label>
                {props.roles.length > 0 && (
                  <RoleFormSelect
                    roles={props.roles.filter(role => role.title !== 'Owner')}
                    setSelectedRole={setSelectedRole}
                  />
                )}
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
              <button
                onClick={sendInvitation}
                type="submit"
                className="block w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Send invite
              </button>
            </div>
          </form>
          <p className="mt-5 ml-1 md:-mb-3 max-w-4xl text-sm text-gray-500">
            Before inviting a team member, please make sure their email address
            is not associated with a Bramework account.
          </p>
        </div>
      )}
      {props.user.is_owner || props.user.is_manager || props.user.is_editor ? (
        <>
          <div className="md:mt-5">
            <div className="sm:hidden mt-3">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                defaultValue={0}
              >
                {tabs.map(tab => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, i) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        openTab === i
                          ? 'border-purple-500 text-purple-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={openTab === i ? 'page' : undefined}
                      onClick={onChangeTab(i)}
                    >
                      {tab.name}
                      {tab.count ? (
                        <span
                          className={classNames(
                            openTab === i
                              ? 'bg-purple-100 text-purple-600'
                              : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="posts-list">
          <h1 className="empty-message">
            You are not authorized to view team members.
          </h1>
        </div>
      )}
      {renderTabContent()}
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    team: state.team.team,
    teamError: state.team.error,
    teamErrorMsg: state.team.errorMsg,
    loading: state.team.loading,
    roles: state.role.roles,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, {
  fetchTeamList,
  startLoading,
  fetchRoleList,
  deleteTeamMember,
})(Team);
