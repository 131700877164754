import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import ReactTooltip from 'react-tooltip';
import Tip from './ComponentTip';
import { isolateError } from '../utils/api';
import useSentData from '../services/useSentData';
import { factCheck, setFacts } from '../store/actions/fact-checker.actions';
import TabItems from './ai-writing-tools/TabItems';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from './accordion/Accordion';
import { useAlert } from 'react-alert';

const FactCheckerPanel = React.forwardRef((props, ref) => {
  const request = useSentData();
  const { sidePanelValue, isPanelCollapsed } = useSelector(
    state => state.panel
  );
  const highLightWords = useSelector(state => state.post.highlightWords);
  const facts = useSelector(state => state.factChecker.facts);
  const postFacts = useSelector(state => state.post.facts);
  const loading = useSelector(state => state.factChecker.loading);
  const alert = useAlert();
  const factCheckRef = useRef();
  const dispatch = useDispatch();
  const minCharacter = 20;
  const maxCharacter = 1000;

  useEffect(() => {}, [postFacts]);

  useEffect(() => {
    if (factCheckRef.current) {
      factCheckRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [facts]);

  const updateLikeFactChecker = (status, fact) => {
    const method = 'post';
    if (fact?.like_status && fact?.like_status[0]?.status === status) {
      status = null;
    }
    const url = status ? `/api/like-fact` : '/api/like-fact-remove';
    const updatedData = { generation_id: fact.generation_id, status };
    if (fact.like_status) {
      fact.like_status[0].status = status;
    } else {
      fact.like_status = [{ status }];
    }

    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const handleDeleteFactChecker = ({ generation_id }, index) => {
    const url = `/api/delete-fact`;
    const method = 'post';
    const updatedData = { generation_id };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newFacts = [...facts];
          newFacts.splice(index, 1);
          dispatch(setFacts(newFacts));
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const handleCopy = fact => {
    navigator.clipboard.writeText(fact.output.correction || fact.output.claim);
    alert.success('Successfully copied to clipboard!');
  };

  const handleClickAdd = fact => {
    const { editorRef } = props;
    if (editorRef?.current?.editor) {
      editorRef?.current?.editor?.insertContent(
        fact.output.correction || fact.output.claim
      );
    }
  };

  const handleFactCheck = () => {
    dispatch(factCheck(highLightWords));
  };

  const getEvidences = fact => {
    const evidences = [];
    fact.output.evidence.forEach(item => {
      evidences.push(...item?.evidence);
    });
    return evidences;
  };

  const getSources = fact => {
    const sources = [];
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    fact.output.evidence.forEach(item => {
      sources.push(...item?.source.filter(item => pattern.test(item)));
    });
    return Array.from(new Set(sources));
  };

  return (
    <div className={isPanelCollapsed ? '' : 'side-bar-open'} id="side-panel">
      <div className="fixed w-max h-full flex flex-col bg-white shadow-xl overflow-y-auto">
        <div className="border-b border-gray-200 pb-4 w-full md:w-128">
          <div className="p-6">
            <div className="flex items-center md:items-start justify-between">
              <div className="text-sm sm:text-lg font-medium text-gray-900">
                Fact Check
              </div>
              <div className="md:absolute sm:ml-112 h-1 sm:h-4 flex items-center">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-500"
                  onClick={() => {
                    const panel =
                      isPanelCollapsed === false &&
                      sidePanelValue === sidePanelTypes.PLAGIARISM
                        ? false
                        : !isPanelCollapsed;
                    dispatch(panelCollapse(sidePanelTypes.FACT_CHECKER, panel));
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-700 mt-2 mr-10 sm:mr-0">
              To fact-check, highlight 20-1000 characters.
            </p>
            <p className="text-sm text-gray-700 mt-2 mr-10 sm:mr-0">
              The process may take up a minute depending on the information{' '}
              <br /> being checked.
            </p>
            <p className="text-sm text-gray-700 mt-2 mr-10 sm:mr-0">
              Each run will reduce your AI words based on the information
              searched.
            </p>
          </div>
        </div>
        <div className="p-5 overflow-y-auto" style={{ height: '73vh' }}>
          <div ref={factCheckRef}></div>
          <TabItems
            dataList={facts}
            onClickCopy={handleCopy}
            onClickAdd={handleClickAdd}
            onClickLike={fact => updateLikeFactChecker(1, fact)}
            onClickDislike={fact => updateLikeFactChecker(2, fact)}
            onClickDelete={handleDeleteFactChecker}
          >
            {item => (
              <div key={item.generation_id} className="flex flex-col w-116">
                <Tooltip arrow title={item.output.claim}>
                  <div className="font-bold truncate">{item.output.claim}</div>
                </Tooltip>
                <div className="text-sm mt-3 flex flex-col gap-3">
                  <div className="flex gap-5">
                    <div className="w-2/12">
                      <b>Claim</b>
                    </div>
                    <div className="w-10/12">{item.output.claim}</div>
                  </div>
                  <div className="flex gap-5">
                    <div className="w-2/12">
                      <b>Correction</b>
                    </div>
                    <div className="w-10/12">{item.output.correction}</div>
                  </div>
                  <div className="flex gap-5">
                    <div className="w-2/12">
                      <b>Factuality</b>
                    </div>
                    <div className="w-10/12">
                      {item.output.factuality ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="w-2/12">
                      <b>Reasoning</b>
                    </div>
                    <div className="w-10/12">{item.output.reasoning}</div>
                  </div>
                  <div className="flex gap-5">
                    <div className="w-2/12">
                      <b>Error</b>
                    </div>
                    <div className="w-10/12">{item.output.error}</div>
                  </div>
                  <div>
                    <Accordion title="Evidences">
                      <div className="flex flex-col gap-3 mx-2">
                        {getEvidences(item).map(item => (
                          <div dangerouslySetInnerHTML={{ __html: item }}></div>
                        ))}
                      </div>
                    </Accordion>
                  </div>
                  <div>
                    <Accordion title="Sources">
                      <div className="flex flex-col gap-3 mx-2">
                        {getSources(item).map(item => (
                          <a
                            className="text-purple-500 hover:text-purple-700 text-sm font-medium"
                            href={item}
                            target="_blank"
                          >
                            {item}
                          </a>
                        ))}
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
          </TabItems>
          <div className="mt-6 mb-6 btn-generate">
            <ReactTooltip
              id={'fact-check-panel'}
              type="dark"
              effect="float"
              border={false}
            />
            <Tip
              data-for="fact-check-panel"
              text={
                minCharacter > highLightWords.length ||
                highLightWords.length > maxCharacter
                  ? 'Highlight 20-300 characters.'
                  : ''
              }
            >
              <button
                className="w-64 sm:w-96 mx-10 sm:mx-16 flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md border border-transparent text-white bg-purple-700
          hover:bg-purple-500 focus:listicle-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={handleFactCheck}
                disabled={
                  minCharacter > highLightWords.length ||
                  highLightWords.length > maxCharacter ||
                  loading
                }
              >
                {loading && (
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mr-2 animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                  </svg>
                )}
                Check for fact
              </button>
            </Tip>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FactCheckerPanel;
