import React, { useEffect, useRef, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {addSecondaryKeywords, addSelectedKeywords} from '../store/actions/post_builder.actions';
import useSentData from '../services/useSentData'

export const ADDITION_KEYWORDS_KEY = 'additionKeywords';

export default function KeywordsTagInput({postId, projectId}) {
  const [keywords, setKeywords] = useState([]);
  const secondaryKeywords = useSelector(state => state.post.secondaryKeywords) || [];   
  const [textContent, setTextContent] = useState('');
  const inputRef = useRef();
  const dispatch = useDispatch();
  const request = useSentData();
  
  const sessionStorageKey = `${ADDITION_KEYWORDS_KEY}_${postId}`

  useEffect(() => {
    const additionKeywords = sessionStorage.getItem(sessionStorageKey);
    const keys = additionKeywords ? JSON.parse(additionKeywords) : []
    setKeywords([...keys]);
  }, [postId, secondaryKeywords]);

  const onInputChange = e => {
    setTextContent(e.target.value.trim());
  };

  const onAddKeyword = async () => {
    if (!textContent) {
      return;
    }
    const newKeywords = [
      ...keywords,
      { id: new Date().getTime(), text: textContent.trim(), selected: false },
    ];
    setKeywords(newKeywords);
    setTextContent('');
    inputRef.current.value = '';
    const topics = newKeywords.map(item => item.text);
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newKeywords));
    await request.send(`/api/projects/${projectId}/posts/${postId}`, { topics}, 'put')
    dispatch(addSecondaryKeywords(newKeywords.map(item => item.text)));
  };

  const onClickDeleteKeyWord = async (e, index) => {
    e.stopPropagation();
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
    dispatch(
      addSelectedKeywords(
        newKeywords
          .filter(item => item.selected)
          .map(item => item.text)
          .join()
      )
    );
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newKeywords));
    const topics = newKeywords.map(item => item.text);
    await request.send(`/api/projects/${projectId}/posts/${postId}`, {topics}, 'put')
    dispatch(addSecondaryKeywords(topics));
  };

  const onClickSelectWords = item => () => {
    item.selected = item.selected
      ? false
      : keywords.filter(item => item.selected).length < 3;
    setKeywords([...keywords]);
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(keywords));
    dispatch(
      addSelectedKeywords(
        keywords
          .filter(item => item.selected)
          .map(item => item.text)
          .join()
      )
    );
  };

  return (
    <div
      style={{ minHeight: '100px' }}
      className="border rounded-lg border-gray-300 focus:ring-purple-500 focus:border-purple-500 p-3"
      onClick={() => inputRef?.current?.focus()}
    >
      <div className=" flex flex-wrap">
        {keywords &&
          keywords.map((item, index) => (
            <div
              onClick={onClickSelectWords(item)}
              className={classnames(
                'cursor-pointer rounded-full flex items-center gap-1 text-sm  mr-2 py-1 px-2 mb-2',
                item.selected
                  ? 'text-purple-700 bg-purple-100'
                  : 'text-gray-700 bg-gray-200'
              )}
              key={item.id}
            >
              <p>{item.text}</p>
              <XIcon
                onClick={e => onClickDeleteKeyWord(e, index)}
                className="cursor-pointer w-4 h-4"
              />
            </div>
          ))}
        <input
          ref={inputRef}
          className="border-none outline-none mb-2"
          onKeyUp={e => {
            if (e.key === 'Enter') {
              onAddKeyword(e);
            }
          }}
          placeholder={!textContent && !keywords.length ? '' : ''}
          onInput={onInputChange}
        />
      </div>
    </div>
  );
}
