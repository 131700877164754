import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from '@headlessui/react';

import { setWriteMoreButtonOn } from '../../store/actions/post_builder.actions';

const AiWriteMoreSwitch = () => {
  const dispatch = useDispatch();
  const aiWriteMoreButtonOn = useSelector(
    state => state.post.aiWriteMoreButtonOn
  );

  return (
    <div className="px-5 mb-8">
      <Switch.Group as="div" className="flex items-center justify-between">
        <span className="flex-grow flex flex-col">
          <Switch.Label
            as="span"
            className="font-semibold text-gray-900"
            passive
          >
            Write more
          </Switch.Label>
          <Switch.Description as="span" className="text-xs text-gray-500">
            Hides write more button in cursor.
          </Switch.Description>
        </span>{' '}
        <Switch
          checked={aiWriteMoreButtonOn}
          onChange={value => {
            dispatch(setWriteMoreButtonOn(value));
          }}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span
            aria-hidden="true"
            className={cx(
              aiWriteMoreButtonOn ? 'bg-purple-600' : 'bg-gray-200',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
            )}
          />
          <span
            aria-hidden="true"
            className={cx(
              aiWriteMoreButtonOn ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default AiWriteMoreSwitch;
