import React from 'react';
import Modal from 'react-modal';
import '../Account/DeactivateConfirmationModal.scss';
import cancelIcon from '../../styles/images/cancel-black.svg';

export default function ComingSoonModal({
  name,
  detail,
  opened,
  close,
  action,
}) {
  return (
    <Modal
      className="deactivate-confirmation-modal"
      isOpen={opened}
      ariaHideApp={false}
    >
      <button
        className="EmailChangeModalView-button clear-button"
        onClick={() => {
          close();
        }}
      >
        <img src={cancelIcon} alt="" className="cancel-button" />
      </button>
      <div>
        <h1>{name}</h1>
        <p>{detail}</p>
        <div className="buttons-wrapper">
          <button
            style={{ margin: 'auto' }}
            className="btn btn-white"
            onClick={() => {
              action();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}
