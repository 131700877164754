import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import http from '../services/http';
import Loading from '../components/Loading';
import { isolateError, isolateSuccess } from '../utils/api';
import { connect } from 'react-redux';
import { authLogout } from '../store/actions/auth.actions';
import { useAlert } from 'react-alert';

function ConfirmChangeEmail({ logout }) {
  const [error, setError] = useState(false);
  let { token } = useParams();
  let history = useHistory();
  let alert = useAlert();

  useEffect(() => {
    http
      .post('/api/confirm-change-email', {
        token,
      })
      .then(response => {
        const data = response && response.data;
        logout();
        history.push({
          pathname: '/',
          state: {
            from: 'confirm-change-email',
            message: isolateSuccess(data, null),
          },
        });
      })
      .catch(e => {
        alert.error(isolateError(e, null));
        history.push({
          pathname: '/',
        });
      });
  }, []);

  // TODO we have very basic layout in this page may be we should update it
  return (
    <div className="justify-center pt-96">
      <Loading />
      <div className="font-bold text-center w-full">Loading your info...</div>
    </div>
  );
}

export default connect(null, { logout: authLogout })(ConfirmChangeEmail);
