import React, { useEffect, useState } from 'react';
import './Invoices.scss';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import useSentData from '../services/useSentData';
import { Link } from 'react-router-dom';
import pdfIcon from '../styles/images/pdf.png';
import Moment from 'react-moment';

export default function Invoices() {
  const request = useSentData();
  const [invoices, setInvoices] = useState([]);

  function loadInvoices() {
    request.send('/api/invoices', null, 'get').then(data => {
      if (!data.isAxiosError) {
        setInvoices(data);
      }
    });
  }

  function getAmount(amount) {
    const price = amount / 100;
    return '$' + price.toFixed(2);
  }

  function downloadPDF(id) {
    request.send(`/api/invoices/${id}/download`, null, 'get').then(data => {
      window.open(data.url, '_blank');
    });
  }

  function renderTableData() {
    return invoices.map((invoice, index) => {
      const { id, created_at, paid_at, amount, url } = invoice;
      return (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {id}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Moment date={created_at} format="MMM. D, YYYY" />
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {paid_at ? (
              <Moment date={paid_at} format="MMM. D, YYYY" />
            ) : (
              <div>-</div>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {getAmount(amount)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {/* <a className="" onClick={() => downloadPDF(id)}>
              PDF
            </a> */}
            <button
              type="button"
              onClick={() => downloadPDF(id)}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium  hover:text-gray-900 text-gray-700 focus:outline-none"
            >
              <img
                src={pdfIcon}
                alt=""
                className="-ml-0.5 mr-1 h-4 w-4"
                aria-hidden="true"
              />
              PDF
            </button>
          </td>
        </tr>
      );
    });
  }

  // function renderTableData() {
  //   return invoices.map((invoice, index) => {
  //     const { id, created_at, paid_at, amount, url } = invoice;
  //     return (
  //       <tr key={index}>
  //         <td>Invoice #</td>
  //         <td>{id}</td>
  //         <td>Created Date</td>
  //         <td>
  //           <Moment date={created_at} format="MMM. D, YYYY" />
  //         </td>
  //         <td>Paid Date</td>
  //         <td>
  //           {paid_at ? (
  //             <Moment date={paid_at} format="MMM. D, YYYY" />
  //           ) : (
  //             <div>-</div>
  //           )}
  //         </td>
  //         <td>Amount</td>
  //         <td className="amount">{getAmount(amount)}</td>
  //         <td>Download</td>
  //         <td>
  //           <a className="pdf-btn" onClick={() => downloadPDF(id)}>
  //             PDF
  //           </a>
  //         </td>
  //       </tr>
  //     );
  //   });
  // }

  useEffect(() => {
    loadInvoices();
  }, []);

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={<PageHeading title="Invoices" />}
    >
      <div className="posts-list invoices-wrapper mt-8">
        {/* <div className="cards-wrapper">
          <div className="form-card">
            <h4>Invoices</h4>
            <div className="info-text">
              Please allow up to 24 hours for your invoices to be available.
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Invoice #</th>
                  <th>Created Date</th>
                  <th>Paid Date</th>
                  <th>Amount</th>
                  <th className="download">Download</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
            <div className="button-wrapper">
              <Link
                to="/account"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div> */}
        <p className="max-w-4xl text-sm text-gray-500">
          Please allow up to 24 hours for your invoices to be available.
        </p>
        {invoices.length ? (
          <>
            <div className="flex flex-col">
              <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Invoice #
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Created Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Paid Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Download
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {renderTableData()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Link
                to="/account"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Cancel
              </Link>
            </div>
          </>
        ) : (
          <h1 className="empty-message">There are no invoices available.</h1>
        )}
      </div>
    </NewWrapper>
  );
}
