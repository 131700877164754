import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { setAIWritingToolSelected } from '../../store/actions/post_builder.actions';
import { novaOnBoardingSteps } from '../../store/reducers/nova_onboarding.reducer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const OPTIONS = [
  {
    id: 'title',
    name: 'Title',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M19.1817 11.2317C18.8913 11.2317 18.6504 10.9909 18.6504 10.7004V9.63085C18.6504 9.20585 18.3033 8.85168 17.8713 8.85168H8.71251C8.28751 8.85168 7.93335 9.19877 7.93335 9.63085V10.7004C7.93335 10.9909 7.69251 11.2317 7.4021 11.2317C7.11168 11.2317 6.87793 10.9909 6.87793 10.7004V9.63085C6.87793 8.61793 7.70668 7.78918 8.7196 7.78918H17.8713C18.8842 7.78918 19.7129 8.61085 19.7129 9.63085V10.7004C19.7129 10.9909 19.4721 11.2317 19.1817 11.2317Z"
          fill="#7429C6"
        />
        <path
          d="M13.2959 19.8521C13.0055 19.8521 12.7646 19.6113 12.7646 19.3209V8.85168C12.7646 8.56127 13.0055 8.32043 13.2959 8.32043C13.5863 8.32043 13.8271 8.56127 13.8271 8.85168V19.3209C13.8271 19.6184 13.5863 19.8521 13.2959 19.8521Z"
          fill="#7429C6"
        />
        <path
          d="M14.8402 19.8521H10.8877C10.5973 19.8521 10.3564 19.6112 10.3564 19.3208C10.3564 19.0304 10.5973 18.7896 10.8877 18.7896H14.8402C15.1306 18.7896 15.3714 19.0304 15.3714 19.3208C15.3714 19.6112 15.1306 19.8521 14.8402 19.8521Z"
          fill="#7429C6"
        />
        <path
          d="M21.5908 15.8501C21.3004 15.8501 21.0596 15.6092 21.0596 15.3188V14.7521C21.0596 14.5326 20.8754 14.3484 20.6558 14.3484H15.6904C15.4 14.3484 15.1592 14.1076 15.1592 13.8171C15.1592 13.5267 15.4 13.2859 15.6904 13.2859H20.6558C21.4633 13.2859 22.1221 13.9446 22.1221 14.7521V15.3188C22.1221 15.6163 21.8883 15.8501 21.5908 15.8501Z"
          fill="#7429C6"
        />
        <path
          d="M17.3896 22.2108C17.0992 22.2108 16.8584 21.97 16.8584 21.6796V14.1996C16.8584 13.9092 17.0992 13.6683 17.3896 13.6683C17.6801 13.6683 17.9209 13.9092 17.9209 14.1996V21.6796C17.9209 21.97 17.6801 22.2108 17.3896 22.2108Z"
          fill="#7429C6"
        />
        <path
          d="M18.9057 22.2108H15.874C15.5836 22.2108 15.3428 21.97 15.3428 21.6796C15.3428 21.3891 15.5836 21.1483 15.874 21.1483H18.9057C19.1961 21.1483 19.4369 21.3891 19.4369 21.6796C19.4369 21.97 19.1961 22.2108 18.9057 22.2108Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'outline',
    name: 'Intro & Outline',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M20.8753 20.8438H13.792C13.5016 20.8438 13.2607 20.6029 13.2607 20.3125C13.2607 20.0221 13.5016 19.7812 13.792 19.7812H20.8753C21.1657 19.7812 21.4066 20.0221 21.4066 20.3125C21.4066 20.6029 21.1657 20.8438 20.8753 20.8438Z"
          fill="#7429C6"
        />
        <path
          d="M20.8753 15.8854H13.792C13.5016 15.8854 13.2607 15.6446 13.2607 15.3542C13.2607 15.0638 13.5016 14.8229 13.792 14.8229H20.8753C21.1657 14.8229 21.4066 15.0638 21.4066 15.3542C21.4066 15.6446 21.1657 15.8854 20.8753 15.8854Z"
          fill="#7429C6"
        />
        <path
          d="M20.8753 10.9271H13.792C13.5016 10.9271 13.2607 10.6862 13.2607 10.3958C13.2607 10.1054 13.5016 9.86456 13.792 9.86456H20.8753C21.1657 9.86456 21.4066 10.1054 21.4066 10.3958C21.4066 10.6862 21.1657 10.9271 20.8753 10.9271Z"
          fill="#7429C6"
        />
        <path
          d="M8.83352 11.6354C8.69893 11.6354 8.56435 11.5859 8.4581 11.4796L7.74977 10.7713C7.54435 10.5659 7.54435 10.2259 7.74977 10.0204C7.95518 9.81502 8.29518 9.81502 8.5006 10.0204L8.83352 10.3534L10.5831 8.60377C10.7885 8.39835 11.1285 8.39835 11.3339 8.60377C11.5393 8.80919 11.5393 9.14919 11.3339 9.3546L9.20893 11.4796C9.10268 11.5859 8.9681 11.6354 8.83352 11.6354Z"
          fill="#7429C6"
        />
        <path
          d="M8.83352 16.5937C8.69893 16.5937 8.56435 16.5442 8.4581 16.4379L7.74977 15.7296C7.54435 15.5242 7.54435 15.1842 7.74977 14.9787C7.95518 14.7733 8.29518 14.7733 8.5006 14.9787L8.83352 15.3117L10.5831 13.5621C10.7885 13.3567 11.1285 13.3567 11.3339 13.5621C11.5393 13.7675 11.5393 14.1075 11.3339 14.3129L9.20893 16.4379C9.10268 16.5442 8.9681 16.5937 8.83352 16.5937Z"
          fill="#7429C6"
        />
        <path
          d="M8.83352 21.5521C8.69893 21.5521 8.56435 21.5025 8.4581 21.3962L7.74977 20.6879C7.54435 20.4825 7.54435 20.1425 7.74977 19.9371C7.95518 19.7316 8.29518 19.7316 8.5006 19.9371L8.83352 20.27L10.5831 18.5204C10.7885 18.315 11.1285 18.315 11.3339 18.5204C11.5393 18.7258 11.5393 19.0658 11.3339 19.2712L9.20893 21.3962C9.10268 21.5025 8.9681 21.5521 8.83352 21.5521Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'paragraph',
    name: 'Paragraph',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M16.6253 15.6729H11.667C11.3766 15.6729 11.1357 15.432 11.1357 15.1416C11.1357 14.8512 11.3766 14.6104 11.667 14.6104H16.6253C16.9157 14.6104 17.1566 14.8512 17.1566 15.1416C17.1566 15.432 16.9157 15.6729 16.6253 15.6729Z"
          fill="#7429C6"
        />
        <path
          d="M14.7695 18.5063H11.667C11.3766 18.5063 11.1357 18.2655 11.1357 17.9751C11.1357 17.6847 11.3766 17.4438 11.667 17.4438H14.7695C15.0599 17.4438 15.3007 17.6847 15.3007 17.9751C15.3007 18.2655 15.0599 18.5063 14.7695 18.5063Z"
          fill="#7429C6"
        />
        <path
          d="M15.917 11.2813H13.0837C12.4037 11.2813 11.1357 11.2813 11.1357 9.33341C11.1357 7.3855 12.4037 7.3855 13.0837 7.3855H15.917C16.597 7.3855 17.8649 7.3855 17.8649 9.33341C17.8649 10.0134 17.8649 11.2813 15.917 11.2813ZM13.0837 8.448C12.3824 8.448 12.1982 8.448 12.1982 9.33341C12.1982 10.2188 12.3824 10.2188 13.0837 10.2188H15.917C16.8024 10.2188 16.8024 10.0347 16.8024 9.33341C16.8024 8.448 16.6182 8.448 15.917 8.448H13.0837Z"
          fill="#7429C6"
        />
        <path
          d="M16.625 22.6146H12.375C8.39417 22.6146 7.59375 20.7871 7.59375 17.8334V13.5834C7.59375 10.3534 8.7625 8.97214 11.6383 8.82339C11.9217 8.80922 12.1837 9.0288 12.1979 9.3263C12.2121 9.6238 11.9854 9.86464 11.695 9.8788C9.68333 9.99214 8.65625 10.5942 8.65625 13.5834V17.8334C8.65625 20.4542 9.17333 21.5521 12.375 21.5521H16.625C19.8267 21.5521 20.3438 20.4542 20.3438 17.8334V13.5834C20.3438 10.5942 19.3167 9.99214 17.305 9.8788C17.0146 9.86464 16.7879 9.60964 16.8021 9.31922C16.8162 9.0288 17.0712 8.80214 17.3617 8.8163C20.2375 8.97214 21.4062 10.3534 21.4062 13.5763V17.8263C21.4062 20.7871 20.6058 22.6146 16.625 22.6146Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'command',
    name: 'Command',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M14.4788 22.6144C10.2855 22.6144 6.86426 19.2003 6.86426 14.9998C6.86426 10.7994 10.2855 7.38525 14.4788 7.38525C18.6722 7.38525 22.0934 10.7994 22.0934 14.9998C22.0934 19.2003 18.6793 22.6144 14.4788 22.6144ZM14.4788 8.44775C10.8663 8.44775 7.92676 11.3873 7.92676 14.9998C7.92676 18.6123 10.8663 21.5519 14.4788 21.5519C18.0913 21.5519 21.0309 18.6123 21.0309 14.9998C21.0309 11.3873 18.0913 8.44775 14.4788 8.44775Z"
          fill="#7429C6"
        />
        <path
          d="M14.4998 20.1355C12.9768 20.1355 11.546 19.3917 10.6748 18.138C10.5048 17.8971 10.5685 17.5642 10.8093 17.4013C11.0502 17.2313 11.3831 17.2951 11.546 17.5359C12.2189 18.4992 13.3239 19.0801 14.4998 19.0801C15.6756 19.0801 16.7806 18.5063 17.4535 17.5359C17.6235 17.2951 17.9493 17.2384 18.1902 17.4013C18.431 17.5713 18.4877 17.8971 18.3248 18.138C17.4535 19.3917 16.0227 20.1355 14.4998 20.1355Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'rephrase',
    name: 'Rewrite',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M20.4645 15.1553C20.1741 15.1553 19.9332 14.9145 19.9332 14.6241V12.2725C19.9332 11.3941 19.2178 10.6787 18.3395 10.6787H8.53613C8.24572 10.6787 8.00488 10.4379 8.00488 10.1475C8.00488 9.85704 8.24572 9.61621 8.53613 9.61621H18.3395C19.8057 9.61621 20.9957 10.8062 20.9957 12.2725V14.6241C20.9957 14.9216 20.7549 15.1553 20.4645 15.1553Z"
          fill="#7429C6"
        />
        <path
          d="M10.7745 12.9241C10.6399 12.9241 10.5053 12.8745 10.3991 12.7683L8.16072 10.53C8.06155 10.4308 8.00488 10.2962 8.00488 10.1545C8.00488 10.0129 8.06155 9.87831 8.16072 9.77914L10.3991 7.54078C10.6045 7.33536 10.9445 7.33536 11.1499 7.54078C11.3553 7.7462 11.3553 8.08624 11.1499 8.29166L9.28699 10.1545L11.1499 12.0175C11.3553 12.2229 11.3553 12.5629 11.1499 12.7683C11.0436 12.8675 10.9091 12.9241 10.7745 12.9241Z"
          fill="#7429C6"
        />
        <path
          d="M20.4645 20.3761H10.6611C9.19488 20.3761 8.00488 19.1861 8.00488 17.7198V15.3682C8.00488 15.0777 8.24572 14.8369 8.53613 14.8369C8.82655 14.8369 9.06738 15.0777 9.06738 15.3682V17.7198C9.06738 18.5982 9.7828 19.3136 10.6611 19.3136H20.4645C20.7549 19.3136 20.9957 19.5544 20.9957 19.8448C20.9957 20.1353 20.7549 20.3761 20.4645 20.3761Z"
          fill="#7429C6"
        />
        <path
          d="M18.2258 22.6145C18.0912 22.6145 17.9566 22.565 17.8504 22.4587C17.6449 22.2533 17.6449 21.9133 17.8504 21.7078L19.7133 19.845L17.8504 17.982C17.6449 17.7766 17.6449 17.4366 17.8504 17.2312C18.0558 17.0258 18.3958 17.0258 18.6012 17.2312L20.8395 19.4695C20.9387 19.5687 20.9954 19.7033 20.9954 19.845C20.9954 19.9866 20.9387 20.1212 20.8395 20.2204L18.6012 22.4587C18.502 22.565 18.3674 22.6145 18.2258 22.6145Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'summarize',
    name: 'Summarize',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M9.54171 21.991C9.01754 21.991 8.50046 21.7926 8.10379 21.396C7.72129 21.0135 7.50879 20.5035 7.50879 19.958C7.50879 19.4126 7.72129 18.9026 8.10379 18.5201L17.3121 9.3118C18.1055 8.51846 19.3946 8.51846 20.188 9.3118C20.5705 9.6943 20.783 10.2043 20.783 10.7497C20.783 11.2951 20.5705 11.8051 20.188 12.1876L10.9796 21.396C10.583 21.7926 10.0659 21.991 9.54171 21.991ZM18.75 9.7793C18.5021 9.7793 18.2542 9.87138 18.063 10.0626L8.85462 19.271C8.67046 19.4551 8.57129 19.696 8.57129 19.958C8.57129 20.2201 8.67046 20.461 8.85462 20.6451C9.23004 21.0205 9.85337 21.0205 10.2288 20.6451L19.4371 11.4368C19.6213 11.2526 19.7205 11.0118 19.7205 10.7497C19.7205 10.4876 19.6213 10.2468 19.4371 10.0626C19.2459 9.87138 18.998 9.7793 18.75 9.7793Z"
          fill="#7429C6"
        />
        <path
          d="M18.757 13.3996C18.6224 13.3996 18.4879 13.35 18.3816 13.2438L16.2566 11.1188C16.0512 10.9133 16.0512 10.5733 16.2566 10.3679C16.462 10.1625 16.802 10.1625 17.0074 10.3679L19.1324 12.4929C19.3379 12.6983 19.3379 13.0383 19.1324 13.2438C19.0262 13.35 18.8916 13.3996 18.757 13.3996Z"
          fill="#7429C6"
        />
        <path
          d="M13.083 10.3962C13.0476 10.3962 13.0193 10.3891 12.9838 10.382L12.0205 10.0987L11.0572 10.382C10.9297 10.4174 10.7951 10.382 10.7101 10.2899C10.618 10.1978 10.5826 10.0703 10.618 9.94282L10.9013 8.97949L10.618 8.01615C10.5826 7.88865 10.618 7.75407 10.7101 7.66907C10.8022 7.57699 10.9368 7.54157 11.0572 7.57699L12.0205 7.86032L12.9838 7.57699C13.1113 7.54157 13.2388 7.57699 13.3309 7.66907C13.423 7.76115 13.4584 7.89574 13.423 8.01615L13.1397 8.97949L13.423 9.94282C13.4584 10.0703 13.423 10.2049 13.3309 10.2899C13.2672 10.3607 13.1751 10.3962 13.083 10.3962ZM12.0205 9.37615C12.0559 9.37615 12.0843 9.38324 12.1197 9.39032L12.5588 9.51782L12.4313 9.07865C12.4101 9.0149 12.4101 8.94407 12.4313 8.88032L12.5588 8.44115L12.1197 8.56865C12.0559 8.5899 11.9851 8.5899 11.9213 8.56865L11.4822 8.44115L11.6097 8.88032C11.6309 8.94407 11.6309 9.0149 11.6097 9.07865L11.4822 9.51782L11.9213 9.39032C11.9568 9.38324 11.9851 9.37615 12.0205 9.37615Z"
          fill="#7429C6"
        />
        <path
          d="M10.25 14.6462C10.2146 14.6462 10.1862 14.6391 10.1508 14.632L9.1875 14.3487L8.22416 14.632C8.09666 14.6674 7.96916 14.632 7.87708 14.5399C7.785 14.4478 7.74958 14.3132 7.785 14.1928L8.06833 13.2295L7.785 12.2662C7.74958 12.1387 7.785 12.0041 7.87708 11.9191C7.96916 11.8341 8.10375 11.7916 8.22416 11.827L9.1875 12.1103L10.1508 11.827C10.2712 11.7916 10.4058 11.827 10.4979 11.9191C10.59 12.0112 10.6254 12.1457 10.59 12.2662L10.3067 13.2295L10.59 14.1928C10.6254 14.3203 10.59 14.4549 10.4979 14.5399C10.4342 14.6107 10.3421 14.6462 10.25 14.6462ZM9.1875 13.6262C9.22291 13.6262 9.25125 13.6332 9.28666 13.6403L9.72583 13.7678L9.59833 13.3287C9.57708 13.2649 9.57708 13.1941 9.59833 13.1303L9.72583 12.6912L9.28666 12.8187C9.22291 12.8399 9.15208 12.8399 9.08833 12.8187L8.64916 12.6912L8.77666 13.1303C8.79791 13.1941 8.79791 13.2649 8.77666 13.3287L8.64916 13.7678L9.08833 13.6403C9.12375 13.6332 9.15208 13.6262 9.1875 13.6262Z"
          fill="#7429C6"
        />
        <path
          d="M20.875 18.1872C20.8396 18.1872 20.8112 18.1801 20.7758 18.173L19.8125 17.8897L18.8492 18.173C18.7217 18.2084 18.5942 18.173 18.5021 18.0809C18.41 17.9888 18.3746 17.8543 18.41 17.7338L18.6933 16.7705L18.41 15.8072C18.3746 15.6797 18.41 15.5451 18.5021 15.4601C18.5942 15.3751 18.7287 15.3326 18.8492 15.368L19.8125 15.6513L20.7758 15.368C20.8962 15.3326 21.0308 15.368 21.1229 15.4601C21.215 15.5522 21.2504 15.6868 21.215 15.8072L20.9317 16.7705L21.215 17.7338C21.2504 17.8613 21.215 17.9959 21.1229 18.0809C21.0592 18.1518 20.9671 18.1872 20.875 18.1872ZM19.8125 17.1672C19.8479 17.1672 19.8762 17.1743 19.9117 17.1813L20.3508 17.3088L20.2233 16.8697C20.2021 16.8059 20.2021 16.7351 20.2233 16.6713L20.3508 16.2322L19.9117 16.3597C19.8479 16.3809 19.7771 16.3809 19.7133 16.3597L19.2742 16.2322L19.4017 16.6713C19.4229 16.7351 19.4229 16.8059 19.4017 16.8697L19.2742 17.3088L19.7133 17.1813C19.7487 17.1743 19.7771 17.1672 19.8125 17.1672Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'steps',
    name: 'Steps',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M19.4587 18.3646C19.1682 18.3646 18.9274 18.1237 18.9274 17.8333V11.1042C18.9274 10.6154 18.5307 10.2188 18.042 10.2188H14.8545C14.5641 10.2188 14.3232 9.97792 14.3232 9.6875C14.3232 9.39708 14.5641 9.15625 14.8545 9.15625H18.042C19.1187 9.15625 19.9899 10.0275 19.9899 11.1042V17.8333C19.9899 18.1237 19.7491 18.3646 19.4587 18.3646Z"
          fill="#7429C6"
        />
        <path
          d="M16.625 11.9894C16.5046 11.9894 16.3842 11.9469 16.285 11.869L14.16 10.0982C14.0396 9.999 13.9688 9.85024 13.9688 9.68733C13.9688 9.52441 14.0396 9.38274 14.16 9.27649L16.285 7.50566C16.5117 7.31441 16.8446 7.34983 17.0358 7.5765C17.2271 7.80316 17.1917 8.13608 16.965 8.32733L15.3287 9.68733L16.965 11.0473C17.1917 11.2386 17.22 11.5715 17.0358 11.7982C16.9296 11.9257 16.7737 11.9894 16.625 11.9894Z"
          fill="#7429C6"
        />
        <path
          d="M9.54199 18.3646C9.25158 18.3646 9.01074 18.1237 9.01074 17.8333V12.875C9.01074 12.5846 9.25158 12.3438 9.54199 12.3438C9.83241 12.3438 10.0732 12.5846 10.0732 12.875V17.8333C10.0732 18.1237 9.83241 18.3646 9.54199 18.3646Z"
          fill="#7429C6"
        />
        <path
          d="M9.71908 13.0524C8.15366 13.0524 6.88574 11.7845 6.88574 10.2191C6.88574 8.65366 8.15366 7.38574 9.71908 7.38574C11.2845 7.38574 12.5524 8.65366 12.5524 10.2191C12.5524 11.7845 11.2845 13.0524 9.71908 13.0524ZM9.71908 8.44824C8.74158 8.44824 7.94824 9.24158 7.94824 10.2191C7.94824 11.1966 8.74158 11.9899 9.71908 11.9899C10.6966 11.9899 11.4899 11.1966 11.4899 10.2191C11.4899 9.24158 10.6966 8.44824 9.71908 8.44824Z"
          fill="#7429C6"
        />
        <path
          d="M9.54199 22.6143C8.07574 22.6143 6.88574 21.4243 6.88574 19.958C6.88574 18.4918 8.07574 17.3018 9.54199 17.3018C11.0082 17.3018 12.1982 18.4918 12.1982 19.958C12.1982 21.4243 11.0082 22.6143 9.54199 22.6143ZM9.54199 18.3643C8.66366 18.3643 7.94824 19.0797 7.94824 19.958C7.94824 20.8363 8.66366 21.5518 9.54199 21.5518C10.4203 21.5518 11.1357 20.8363 11.1357 19.958C11.1357 19.0797 10.4203 18.3643 9.54199 18.3643Z"
          fill="#7429C6"
        />
        <path
          d="M19.458 22.6143C17.9918 22.6143 16.8018 21.4243 16.8018 19.958C16.8018 18.4918 17.9918 17.3018 19.458 17.3018C20.9243 17.3018 22.1143 18.4918 22.1143 19.958C22.1143 21.4243 20.9243 22.6143 19.458 22.6143ZM19.458 18.3643C18.5797 18.3643 17.8643 19.0797 17.8643 19.958C17.8643 20.8363 18.5797 21.5518 19.458 21.5518C20.3363 21.5518 21.0518 20.8363 21.0518 19.958C21.0518 19.0797 20.3363 18.3643 19.458 18.3643Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
  {
    id: 'listicle',
    name: 'Listicle',
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="29" height="29" rx="14.5" fill="#F8F2FF" />
        <path
          d="M18.4805 13.3145H14.7617C14.4713 13.3145 14.2305 13.0736 14.2305 12.7832C14.2305 12.4928 14.4713 12.252 14.7617 12.252H18.4805C18.7709 12.252 19.0117 12.4928 19.0117 12.7832C19.0117 13.0736 18.778 13.3145 18.4805 13.3145Z"
          fill="#7429C6"
        />
        <path
          d="M11.0432 13.8517C10.9086 13.8517 10.774 13.8022 10.6677 13.6959L10.1365 13.1647C9.93107 12.9592 9.93107 12.6192 10.1365 12.4138C10.3419 12.2084 10.6819 12.2084 10.8873 12.4138L11.0432 12.5697L12.2615 11.3513C12.4669 11.1459 12.8069 11.1459 13.0123 11.3513C13.2177 11.5567 13.2177 11.8967 13.0123 12.1022L11.4186 13.6959C11.3194 13.7951 11.1848 13.8517 11.0432 13.8517Z"
          fill="#7429C6"
        />
        <path
          d="M18.4805 18.2734H14.7617C14.4713 18.2734 14.2305 18.0326 14.2305 17.7422C14.2305 17.4518 14.4713 17.2109 14.7617 17.2109H18.4805C18.7709 17.2109 19.0117 17.4518 19.0117 17.7422C19.0117 18.0326 18.778 18.2734 18.4805 18.2734Z"
          fill="#7429C6"
        />
        <path
          d="M11.0432 18.8107C10.9086 18.8107 10.774 18.7611 10.6677 18.6549L10.1365 18.1236C9.93107 17.9182 9.93107 17.5782 10.1365 17.3728C10.3419 17.1674 10.6819 17.1674 10.8873 17.3728L11.0432 17.5286L12.2615 16.3103C12.4669 16.1049 12.8069 16.1049 13.0123 16.3103C13.2177 16.5157 13.2177 16.8557 13.0123 17.0611L11.4186 18.6549C11.3194 18.7541 11.1848 18.8107 11.0432 18.8107Z"
          fill="#7429C6"
        />
        <path
          d="M16.6253 22.6139H12.3753C8.52908 22.6139 6.88574 20.9706 6.88574 17.1243V12.8743C6.88574 9.0281 8.52908 7.38477 12.3753 7.38477H16.6253C20.4716 7.38477 22.1149 9.0281 22.1149 12.8743V17.1243C22.1149 20.9706 20.4716 22.6139 16.6253 22.6139ZM12.3753 8.44727C9.10991 8.44727 7.94824 9.60893 7.94824 12.8743V17.1243C7.94824 20.3898 9.10991 21.5514 12.3753 21.5514H16.6253C19.8907 21.5514 21.0524 20.3898 21.0524 17.1243V12.8743C21.0524 9.60893 19.8907 8.44727 16.6253 8.44727H12.3753Z"
          fill="#7429C6"
        />
      </svg>
    ),
  },
];

export default function AiWritingToolsSelect({ toolSelected }) {
  const [selected, setSelected] = useState({});
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const dispatch = useDispatch();
  const listBoxRef = useRef();

  useEffect(() => {
    setSelected(OPTIONS.find(item => item.id === toolSelected));
  }, [toolSelected]);

  useEffect(() => {
    if (stepNovaOnBoardingGuide === novaOnBoardingSteps.GENERATE_AN_OUTLINE) {
      dispatch(setAIWritingToolSelected('outline'));
      listBoxRef.current?.click();
    }
  }, [stepNovaOnBoardingGuide]);

  const renderOptionLabel = option => (
    <div className="flex items-center">
      <div className="mr-3">{option && option.icon}</div>
      <div>{option && option.name}</div>
    </div>
  );

  const onChangeTool = value => {
    dispatch(setAIWritingToolSelected(value));
  };

  return (
    <Listbox value={selected.id} onChange={onChangeTool}>
      {({ open }) => (
        <>
          <Listbox.Label className="mt-6 block font-medium  text-gray-700 font-weight-bold">
            <b>Select what you want the AI to write</b>
          </Listbox.Label>
          <div className="mt-3 relative ">
            <Listbox.Button
              ref={listBoxRef}
              className={`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 sm:text-sm`}
            >
              <span className="block truncate">
                {renderOptionLabel(selected)}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {OPTIONS.map(option => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-purple-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        {renderOptionLabel(option)}
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-purple-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
